import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Button3items from "../button/Button3items";
import {
  getStaffList,
  getStaffById,
  removeStaff,
  getDataStaffFilter,
} from "../../api/staff_api";
import Swal from "sweetalert2";
import moment from "moment";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import {
  getPermissionList,
  getPermissionMobileList,
} from "../../api/permission_api";
import Loading from "./Loading";

function Datastaffs() {
  const enableButton = { view: true, edit: false, delete: true };
  const [spanloading, setspanLoading] = useState(false);
  const [DataStaff, setDatastaff] = useState([]);

  const columnsname = [
    "id",
    "firstname",
    "mobileNo",
    "email",
    "permissionName",
    "updatedAt",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ชื่อเจ้าหน้าที่",
    "เบอร์โทรศัพท์",
    "อีเมล",
    "สิทธิ์",
    // "สิทธิ์",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];

  const [preName, setPrename] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [call, setCall] = useState(null);
  const [email, setEmail] = useState(null);
  const [camp, setCamp] = useState(null);
  const [permission, setPermission] = useState(null);
  // const [nameSort, setNameSort] = useState("")
  const [dataPer, setDataPer] = useState([]);
  const [permissionDropdown, setPermissionDropdown] = useState([]);

  const DataDropdownPermission = [
    { value: "", text: "ทั้งหมด" },
    { value: "เจ้าหน้าที่ดูแลสนาม", text: "เจ้าหน้าที่ดูแลสนาม" },
    { value: "นักกีฬา", text: "นักกีฬา" },
    { value: "โค้ช", text: "โค้ช" },
    { value: "ร้านค้า / ร้านอาหาร", text: "ร้านค้า / ร้านอาหาร" },
    { value: "บุคคลทั่วไป", text: "บุคคลทั่วไป" },
    { value: "VIP", text: "VIP" },
    { value: "เจ้าหน้าที่ดูแลที่พัก", text: "เจ้าหน้าที่ดูแลที่พัก" },
  ];

  const getPermissionDropDown = async () => {
    try {
      const query = { isAdmin: true };
      const response = await getPermissionMobileList(query);
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.permissionAccountId,
            text: item.permissionName,
            subType: [],
          });
        });

        setPermissionDropdown(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStaffPermiss = async () => {
    const response = await getPermissionMobileList();
  };

  const getStaff = async () => {
    setspanLoading(true);
    try {
      const response = await getStaffList();
      if (response) {
        const dataStaff = response.data.data;

        const results = dataStaff.map((row, i) => {
          const rowData = {
            id: { value: i + 1, dataID: row?.staffID, type: "text" },
            firstname: {
              value:
                row?.firstname === null && row?.lastanme === null
                  ? "-"
                  : row?.firstname + " " + row?.lastanme,
              type: "text",
            },
            mobileNo: {
              value: row?.mobileNo === null ? "-" : row?.mobileNo,
              type: "text",
            },
            email: {
              value: row?.email === null ? "-" : row?.email,
              type: "text",
            },
            permissionName: {
              value: row?.permissionAccountMoble?.permissionName
                ? row?.permissionAccountMoble?.permissionName
                : "-",
              type: "text",
            },
            updatedAt: {
              value:
                row.updatedAt === null
                  ? moment(new Date(row.createdAt)).format("YYYY-MM-DD")
                  : moment(new Date(row.updatedAt)).format("YYYY-MM-DD"),
              type: "text",
            },
            action: { value: "1", type: "action" },
          };
          return rowData;
        });
        await setspanLoading(false);
        setDatastaff(results);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    // getStaffPermiss();
    getStaff();
    getPermissionDropDown();
    // getPermission()
  }, []);

  //   const getFilterDropdown = async (data) => {
  //   var dataArr = data.map((item) => {
  //     return [item.text, item];
  //   }); // creates array of array
  //   var maparr = new Map(dataArr); // create key value pair from array of array
  //   var result = [...maparr.values()]; //converting back to array from mapobject
  //   setDataPer(result);
  // };

  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/AddRecommendatt`)
        break;
      case "edit":
        // handle edit button
        // navigate(`/AddRecommendatt?id=${id}`)
        break;
      case "view":
        // handle view button
        viewModal(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const viewModal = async (id) => {
    try {
      const modal = await getStaffById(id);

      await setPrename(modal.data.data.title);
      await setName(modal.data.data.firstname);
      await setSurname(modal.data.data.lastanme);
      await setEmail(modal.data.data.email);
      await setCall(modal.data.data.mobileNo);
      await setCamp(modal.data.data.org);
      await setPermission(
        modal.data.data.permissionAccountMoble.permissionName || "-"
      );
    } catch (error) {}
  };

  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();
      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await removeStaff(id);
            if (response.status === 200) {
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
              getStaff();
            } else {
              swalWithBootstrapButtons.fire({
                title: "เกิดข้อผิดพลาด!",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const clearData = async (event) => {
    navigate("/staff");
    // window.location.reload();
  };

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ชื่อเจ้าหน้าที่",
      "เบอร์โทรศัพท์",
      "อีเมล",
      "สิทธิ์",
      "อัปเดตล่าสุด",
    ];

    let Data = DataStaff.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.firstname.value;
      dt[2] = elemento.mobileNo.value;
      dt[3] = elemento.email.value;
      dt[4] = elemento.permissionName.value;
      dt[5] = elemento.updatedAt.value;
      return dt;
    });

    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");

    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานเจ้าหน้าที่ Application",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const [inputForm, setInputForm] = useState({
    name: "",
    permissionID: "",
  });

  const getDataFilter = async () => {
    setspanLoading(true);
    // if(inputForm.permissionID === "เจ้าหน้าที่ดูแลสนาม"){
    //   inputForm.permissionID = 1
    // }
    // else if (inputForm.permissionID === "นักกีฬา"){
    //   inputForm.permissionID = 2
    // }
    // else if (inputForm.permissionID === "โค้ช"){
    //   inputForm.permissionID = 3
    // }
    // else if (inputForm.permissionID === "ร้านค้า / ร้านอาหาร"){
    //   inputForm.permissionID = 4
    // }
    // else if (inputForm.permissionID === "บุคคลทั่วไป"){
    //   inputForm.permissionID = 5
    // }
    // else if (inputForm.permissionID === "VIP"){
    //   inputForm.permissionID = 6
    // }
    // else if (inputForm.permissionID === "เจ้าหน้าที่ดูแลที่พัก"){
    //   inputForm.permissionID = 7
    // }else { inputForm.permissionID = undefined }

    let respon;
    let rowsdata = [];
    if (inputForm.name === "" && selectedValue1 === "") {
      getStaff();
    } else {
      let DropdownForm = {
        name: inputForm.name,
        permissionID: selectedValue1,
      };
      respon = await getDataStaffFilter(DropdownForm);
      respon.data.data.forEach((row, i) => {
        return rowsdata.push({
          id: { value: row?.staffID, dataID: row?.staffID, type: "text" },
          firstname: {
            value:
              row?.firstname === null && row?.lastanme === null
                ? "-"
                : row?.firstname + " " + row?.lastanme,
            type: "text",
          },
          mobileNo: {
            value: row?.mobileNo === null ? "-" : row?.mobileNo,
            type: "text",
          },
          email: {
            value: row?.email === null ? "-" : row?.email,
            type: "text",
          },
          permissionName: {
            value: row?.permissionAccountMoble?.permissionName
              ? row?.permissionAccountMoble?.permissionName
              : "-",
            type: "text",
          },
          updatedAt: {
            value:
              row.updatedAt === null
                ? moment(new Date(row.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(row.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      await setspanLoading(false);
      setDatastaff(rowsdata);
    }
  };

  function setinput(inputname, id) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: id,
      };
    });
  }

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [selectedValue1, setSelectedValue1] = useState("");
  const [sortname, setNameSort] = useState("");

  function resetDropdown() {
    setNameSort("");
    inputForm.name = "";
    setSelectedValue1("");
    setValuefromparent1("ทั้งหมด");
    getStaff();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setNameSort(e.target.value);
    }
  };

  const handleRegisChange = (value) => {
    setSelectedValue1(value);
  };

  return (
    <>
      {/* <Loading /> */}
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"เจ้าหน้าที่"} />
      <Menu parentmenu={"staffData"} activemenu={"staff"} />
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="container-fluid p-2 p-md-3">
            <div className="row mb-4">
              <div className="col-7 mt-2">
                <img
                  src="assets/icons/im-staff-info.png"
                  alt="Home Icon"
                  im-staff-info
                  className="im-tiltle img-circle"
                />
                <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                  ข้อมูลเจ้าหน้าที่
                </font>
                <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                  | ข้อมูลเจ้าหน้าที่ / เจ้าหน้าที่
                </font>
              </div>
              <div className="col-5 d-flex justify-content-end align-items-center">
                <Button3items
                  navigateToAddEdit={navigateToAddEdit}
                  pageAddEditName={"/AddStaff"}
                  onButtonClicked={handleButtonClicked}
                  onExportClicked={handleExport}
                />
              </div>
            </div>
            <div className="card-white">
              <div className="row">
                <div className="col-12">
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>ค้นหา
                  </p>
                </div>
              </div>
              <div className="row justify-content-start">
                <div className="col-6 col-sm-4 col-xl-3 pr-0">
                  <input
                    className="inp-data01 fontReg font-size03"
                    placeholder="ค้นหาข้อมูล ชื่อจริง นามสกุล"
                    value={sortname}
                    onChange={onChangeInputName}
                  />
                </div>
                <div className="col-6 col-sm-4 col-xl-3 pr-0">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      สิทธิ์
                    </p>
                    <Dropdown
                      options={permissionDropdown}
                      setinput={setinput}
                      inputname={"permissionID"}
                      id="permissionID"
                      ChangesetValuefromparent={ChangesetValuefromparent1}
                      valuefromparent={valuefromparent1}
                      onChange={handleRegisChange}
                    />
                  </div>
                </div>
                <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                  <button
                    className="btn-blue01"
                    onClick={(e) => getDataFilter()}
                  >
                    <img
                      src="assets/icons/ic-search.png"
                      alt="ExportFiles Icon"
                      className="ic-search"
                    />
                    <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                      ค้นหา
                    </span>
                  </button>
                </div>
                <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                  <button className="btn-white01" onClick={resetDropdown}>
                    <img
                      src="assets/icons/ic-reset.png"
                      alt="ExportFiles Icon"
                      className="ic-search"
                    />
                    <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                      ล้างค่า
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-white mt-3">
              <div className="row">
                <div className="col-12">
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>รายการเจ้าหน้าที่
                  </p>
                </div>
              </div>
              <TableData
                actionsBtn={enableButton}
                columnsname={columnsname}
                columnsText={columnsText}
                columnsType={columnsType}
                rowsdata={DataStaff}
                isSearchEnable={false}
                onButtonClicked={handleButtonClicked}
              />
            </div>
            {/* Modal */}
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                      id="exampleModalLabel"
                    >
                      ดูรายละเอียด
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="col-12">
                      <p className="fontReg font-weight-bold font-size03 colorGray01">
                        <span className="border-left01"></span>ข้อมูลเจ้าหน้าที่
                      </p>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        คำนำหน้า : {preName}
                      </p>
                    </div>
                    <div className="row ml-2">
                      <div className="col-6">
                        <p className="fontReg font-size03 colorGray01">
                          ชื่อจริง : {name}
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="fontReg font-size03 colorGray01">
                          นามสกุล : {surname}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        เบอร์โทรศัพท์ : {call}
                      </p>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        อีเมล : {email}
                      </p>
                    </div>
                    {/* <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        สังกัด : {camp}
                      </p>
                    </div> */}
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        สิทธิ์ : {permission}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Datastaffs;
