import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Table from "../Tables/TableAddBackOffice";
import DataMockAddBackOffice from "../dataMockup/DataMockAddBackOffice";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "../pages/Mastersporttype.css";
import TableData from "../Tables/TableData";
// TableNp
import TableDataNp from "../Tables/TableNopaig";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { getPermissionMenu, creatPermission } from "../../api/permission_api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import Tables from "react-bootstrap/Table";
import TableNoPagination from "../Tables/TableNoPagination";
const mapPermissionConfig = {
  dashboard_main: "Dashboard หลัก",
  dashboard_votesport: "ผลโหวตกีฬาน่าสนใจ",
  dashboard_arenacheckin: "ปริมาณผู้เข้าใช้สนาม",
  dashboard_applicationusage: "จำนวนผู้ใช้แอปพลิเคชัน",
  master_sport: "ชนิดกีฬา",
  master_subsport: "ประเภทการแข่งขัน",
  master_arena: "สนามกีฬา",
  master_subarena: "สนามกีฬาย่อย",
  master_affliation: "สังกัด",
  master_camp: "ที่พักนักกีฬา",
  member_coach: "โค้ช",
  member_athletes: "นักกีฬา",
  match: "สถิติการแข่งขัน",
  member_general: "บุคคลทั่วไป",
  shop_acc_pending: "Account ที่รออนุมัติ",
  shop_acc: "รายชื่อร้านค้า ร้านอาหาร",
  shop_promotion_pending: "โปรโมชัน ที่รออนุมัติ",
  shop_promotion_history: "ประวัติการใช้โปรโมชัน",
  promotion: "รายชื่อโปรโมชัน",
  staff: "เจ้าหน้าที่ Application",
  member_delete: "บัญชีผู้ใช้ที่ถูกลบ",
  campaign_campaign: "แคมเปญ",
  campaign_history: "ประวัติการใช้แคมเปญ",
  pr_supportbanner: "จัดการแบนเนอร์ผู้สนับสนุน",
  pr_attraction: "จัดการข้อมูลท่องเที่ยวจังหวัด",
  busbooing: "ข้อมูลการจองรถรับ-ส่ง",
  sleeppack: "ข้อมูลเครื่องนอน",
  sleeppack_borrow: "ข้อมูลการยืม-คืน",
  sleeppack_camp: "ข้อมูลเครื่องนอนที่พัก",
  notification: "รายการแจ้งเตือน",
  privacy: "นโยบายความเป็นส่วนตัว",
  policy: "ข้อกำหนดและเงื่อนไข",
  manual: "คู่มือการใช้งาน",
  backoffice_permission: "จัดการ Permission Back Office",
  mobile_permission: "จัดการ Permission Application",
  admin_list: "รายชื่อ Admin",
  theme_color: "จัดการ Theme Color",
  web_view_manage: "จัดการ Link Web View",
  busbooking: "ข้อมูลการจองรถรับ-ส่ง",
  promotion_history: "ประวัติการใช้โปรโมชัน",
};

function AddBackOffice() {
  const enableButton = { view: true, edit: true, delete: true, check: true };
  const [namePermission, setNamePermission] = useState();
  const [permission, setPermission] = useState("ระบบจัดการหลังบ้าน");
  const [permissionMenu, setPermissionMenu] = useState([]);
  const [permissionMenuChecked, setPermissionMenuChecked] = useState([]);
  const [rowsdata, setRowsdata] = useState([]);
  const [rowsdataTB, setRowsdataTB] = useState([]);

  // const columnsname = ["id", "menu", "checkboxPreview", "checkboxAdd", "checkboxEdit", "checkboxDelete"];
  const columnsname = ["id", "menu", "checkboxAdd"];
  const columnsText = ["No", "เมนู", "Action", "Add", "Edit", "Delete"];
  const columnsType = [
    "text",
    "text",
    "checkbox",
    "checkbox",
    "checkbox",
    "checkbox",
  ];

  const enableCheckbox = { checkbox: true };

  const navigate = useNavigate();

  function submitForm() {
    if (
      namePermission === "" ||
      namePermission === null ||
      namePermission === undefined ||
      permissionMenuChecked.length < 1
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          creatPermission({
            permissionAccountName: namePermission,
            permissionAccountMenu: permissionMenuChecked,
          }).then((rs) => {
            if (rs) {
              Swal.fire({
                title: "บันทึกข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ยืนยัน",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToBack("/BackOfficePermission");
                }
              });
            } else {
              toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: css({
                  background: "#ed5565 !important",
                }),
              });
            }
          });
        }
      });
      const menuSelect = permissionMenu.map((item) => {
        return { menuId: item, action: [1] };
      });
    }
  }
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/BackOfficePermission");
      }
    });
  }

  function setCheckBoxState(ev) {
    if (ev.target.checked === true) {
      // setPermissionMenuChecked((prev) => [
      //   ...prev,
      //   { menuId: parseInt(ev.target.value), actionId: 1 },
      // ]);
      setRowsdata((prev) => {
        const newState = prev.map((item) => {
          if (item.id.dataID == parseInt(ev.target.value)) {
            item.checkboxAdd.checked = true;
          }
          return item;
        });
        return newState;
      });
      setPermissionMenuChecked((prev) => [
        ...prev,
        { menuId: parseInt(ev.target.value), actionId: 1 },
      ]);
    } else {
      // setPermissionMenuChecked((prev) =>
      //   prev.filter((item) => item.menuId != parseInt(ev.target.value))
      // );
      setRowsdata((prev) => {
        const newState = prev.map((item) => {
          if (item.id.dataID == parseInt(ev.target.value)) {
            item.checkboxAdd.checked = false;
          }
          return item;
        });
        return newState;
      });
    }
  }

  const getPermissionMenuList = async () => {
    try {
      const response = await getPermissionMenu();
      if (response.status === 200) {
        const menuList = response.data.data;
        let dt = [];
        for (let i = 0; i < menuList.length; i++) {
          dt.push({
            id: menuList[i].permissionMenuID,
            value: mapPermissionConfig[menuList[i].permissionMenuName],
            status: false,
          });
        }

        setRowsdataTB(dt);
        const result = menuList.map((item, i) => {
          const data = {
            id: { value: i + 1, dataID: item.permissionMenuID, type: "text" },
            menu: {
              value: mapPermissionConfig[item.permissionMenuName],
              type: "text",
            },
            checkboxAdd: {
              value: item.permissionMenuID,
              type: "checkbox",
              eventHandler: (ev) => setCheckBoxState(ev),
            },
          };
          return data;
        });
        setRowsdata(result);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPermissionMenuList();
  }, []);

  return (
    <>
      <Header parentmenu={"ตั้งค่าแอดมิน"} />
      <Menu parentmenu={"adminSetting"} activemenu={"BackOfficePermission"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-settingAdmin.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ตั้งค่าแอดมิน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ตั้งค่าแอดมิน / Permission เพิ่มข้อมูล
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>Permission
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-2 text-header">ชื่อ Permission</div>
              <div className="col-6 col-sm-4 col-xl-4 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อ Permission"
                  onChange={(e) => {
                    setNamePermission(e.target.value);
                  }}
                  value={namePermission}
                />
              </div>
            </div>
            <div className="row justify-content-start mt-4">
              <div className="col-2 text-header">ประเภท Permission</div>
              <div className="col-6 col-sm-4 col-xl-4 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อ Permission"
                  value={permission}
                  disabled
                />
              </div>

              <div className="col-12 justify-content-start mt-5">
                {/* <TableData
                  addCheckbox={enableCheckbox}
                  actionsBtn={enableButton}
                  columnsname={columnsname}
                  columnsText={columnsText}
                  columnsType={columnsType}
                  rowsdata={rowsdata}
                  isSearchEnable={false}
                  showPaginationBottom={false}
                /> */}
                <TableData
                  addCheckbox={enableCheckbox}
                  actionsBtn={enableButton}
                  columnsname={columnsname}
                  columnsText={columnsText}
                  columnsType={columnsType}
                  rowsdata={rowsdata}
                  isSearchEnable={false}
                  showPaginationBottom={false}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button onClick={() => submitForm()} className="btn-save">
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddBackOffice;
