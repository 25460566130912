export const DataMockChartIntsSportRank = [
    {
      id: 1,
      rank: "อันดับที่ 1",
      sport: "กรีฑา",
      quantity: 100,
    },
    {
      id: 2,
      rank: "อันดับที่ 2",
      sport: "แบดมินตัน",
      quantity: 50,
    },
    {
      id: 3,
      rank: "อันดับที่ 3",
      sport: "ว่ายน้ำ",
      quantity: 20,
    }
  ];