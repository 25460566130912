import React from 'react'

function CheckboxSubType(props) {
    const { value, text,id,handleClick,isChecked } = props;
  return (
    <>
      <label className="checkbox-item01 fontReg font-size03 colorGray01">
        {text}
        <input type="checkbox" 
          value={value} id={id} 
          onChange={handleClick}
          checked={isChecked}
        />
        <span className="checkmark" />
      </label>
    </>
  );
}

export default CheckboxSubType