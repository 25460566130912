import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
const dataToken = localStorage.getItem("token");
// sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const verifyImport = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(URL + `/matchstat/verifyimport`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const importData = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(URL + `/matchstat/importdata`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getManual = async (query = {}) => {
  try {
    //example of query
    // const query = {
    //     name: 'John',
    //     type: 'Match'
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/manual`, {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getManualId = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(`/manual/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getMatchStatById = async (id, query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/matchstat/${id}`, {
      headers: headers,
      // params: id,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const createManual = async (body) => {
  try {
    const headers = {
      // "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    };
    //

    const formdata = new FormData();
    formdata.append("manualName", body.manualName);
    formdata.append("file", body.file);
    const response = await instace.post(URL + `/manual`, formdata, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const updateMatchStat = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.put(URL + `/matchstat/${id}`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const deleteManual = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.delete(URL + `/manual/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
