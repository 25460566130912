import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import Buttonactions from "../button/Buttonactions";
import CheckboxSubType from "../checkbox/CheckboxSubType";
import DataMockCompetScheduleAthlete from "../dataMockup/DataMockCompetScheduleAthlete";

function TableNoPagination(props) {
  const { pageAddEditName, actionsBtn } = props;
  const item = props.item;
  $.DataTable = require("datatables.net-bs4");
  const tableRef = useRef();

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [addlist, setAddlist] = useState([]);
  const [isLoadDone, setIsLoadDone] = useState(false);

  useEffect(() => {
    setList(item.datas);
  }, [item.datas]);

  useEffect(() => {
    if (props.isClear) {
      setIsCheck([]);
      setAddlist([]);
      props.setClear(false);
      setIsCheckAll(false);
    }
  }, [props.isClear]);

  useEffect(() => {
    if (props.currentGroupCheck) {
      setIsCheck(props.currentGroupCheck);
    }
  }, [props.currentGroupCheck]);
  useEffect(() => {
    if (props.addTemp) {
      props.addTemp(isCheck);
    }
  }, [isCheck]);
  const handleSelectAll = (e) => {
    let isCurrentCheckall = !isCheckAll;
    setIsCheckAll(isCurrentCheckall);

    if (isCurrentCheckall) {
      setIsCheck(list.map((li) => parseInt(li.id)));
      // setAddlist(list);
    } else {
      setIsCheck([]);
      // setAddlist([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;

    if (!checked) {
      setIsCheck([...isCheck.filter((item) => item !== parseInt(id))]);
      // setAddlist([...addlist.filter((item) => item.id !== parseInt(id))]);
    } else {
      setIsCheck([...isCheck, parseInt(id)]);
      // setAddlist([
      //   ...addlist,
      //   list.filter((item) => item.id == parseInt(id))[0],
      // ]);
      //([...addlist, list.filter(item => item.id == parseInt(id))[0]])
    }
  };
  // useEffect(()=>{
  //   if(props.addTempTableDataId)
  //   {
  //     props.addTempTableDataId(props.group, addlist)
  //   }
  // },[props.group])

  const [itemHeaderListState, setItemHeaderListState] = useState([]);
  const [itemRowsState, setItemRowsState] = useState([]);
  // console.log("itemRowsState : ", itemRowsState);
  useEffect(() => {
    let itemHeaderList = [];
    let itemRows = [];
    let headIndex = 0;
    for (let data of item.columns) {
      if (
        data.title === " " ||
        data.title === "เครื่องมือ" ||
        data.title === "บันทึกผล"
      ) {
        itemHeaderList.push(
          <th className="no-sort" key={headIndex}>
            {data.title}
          </th>
        );
      } else if (data.title === "checkbox") {
        itemHeaderList.push(
          <th className="no-sort" key={headIndex}>
            <CheckboxSubType
              handleClick={handleSelectAll}
              isChecked={isCheckAll}
            />
          </th>
        );
      } else itemHeaderList.push(<th key={headIndex}>{data.title}</th>);
      headIndex++;
    }
    let detailIndex = 0;
    for (let data of item.datas) {
      let itemDetails = [];

      let rowIndex = 0;
      for (let details of data.row) {
        if (details.type === "image") {
          itemDetails.push(
            <td key={rowIndex}>
              <img
                src={details.value}
                alt="Type Sport"
                className="im-sports-type"
              />
            </td>
          );
        } else if (details.type === "actions") {
          itemDetails.push(
            <td key={rowIndex}>
              <Buttonactions
                pageAddEditName={pageAddEditName}
                actionsBtn={actionsBtn}
                id={data.id}
              />
            </td>
          );
        } else if (details.type === "checkbox") {
          itemDetails.push(
            <td key={rowIndex}>
              <CheckboxSubType
                key={data.id}
                id={data.id}
                handleClick={handleClick}
                isChecked={isCheck.includes(parseInt(data.id))}
              />
            </td>
          );
        } else {
          itemDetails.push(<td key={rowIndex}>{details.value}</td>);
        }
        rowIndex++;
      }
      itemRows.push(<tr key={detailIndex}>{itemDetails}</tr>);
      detailIndex++;
    }

    setItemHeaderListState(itemHeaderList);
    setItemRowsState(itemRows);
  }, [props.item, isCheck, isCheckAll, props.currentGroupCheck]);
  useEffect(() => {
    if (itemRowsState.length > 0) {
      const table = $(tableRef.current).DataTable({
        info: false,
        searching: props.searching,
        paging: false,
        dom:
          "<'row m-0'<'col-12'f>>" + "<'row'<'col-sm-12 of-scroll-condi'tr>>",
        columnDefs: [
          {
            targets: "no-sort",
            orderable: false,
          },
        ],
        aaSorting: [],
      });
      return function () {
        table.destroy();
      };
    }
  }, [itemRowsState]);

  return (
    <div>
      <table
        id="tb_sport_type"
        className="table fontReg font-size03 colorGray01"
        ref={tableRef}
      >
        <thead>
          <tr>{itemHeaderListState}</tr>
        </thead>
        <tbody>{itemRowsState}</tbody>
      </table>
    </div>
  );
}

export default TableNoPagination;
