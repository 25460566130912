import React from "react";

function ButtonNoti(props) {
  const { id, navigateToAddEdit, pageAddEditName, notiBtn } = props;
  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-center ${
          notiBtn.noti ? "d-flex" : "d-none"
        } `}
      >
        <button
          className={`btn-blue02`}
          // onClick={() => props.notiBtn(id)}
          onClick={() => props.onAddClick(id)}
        >
          <span class="nav-icon icon icon-ic-notification mr-0 mr-sm-1 colorWhite"></span>
          <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
            ส่งการแจ้งเตือน
          </span>
        </button>
      </div>
    </>
  );
}

export default ButtonNoti;
