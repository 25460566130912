import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "./Calendarpicker.css";

function Calendarpicker(props) {
  const { setDatePicker, inputname, inputDate, ChangesetValueDate } = props;
  const [startDate, setStartDate] = useState(null);
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    console.log("inputDate : ", inputDate);
    if (inputDate == "") {
      setStartDate("");
    }
  }, [inputDate]);

  const onSelected = (date) => {
    setStartDate(date);
    setDatePicker(inputname, date);
    ChangesetValueDate(date);
  };
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input-date"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      onFocus={(e) => {
        e.target.blur();
      }}
      // value={props.dataDate !== null ? props.dataDate : "-"}
      placeholder={props.dataDate !== null ? props.dataDate : "dd/mm/yyyy"}
    ></input>
  ));

  return (
    <DatePicker
      className="inp-data01 fontReg font-size03 colorGray01"
      customInput={<CustomInput />}
      dateFormat="dd/MM/yyyy"
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      placeholderText="dd/mm/yyyy"
      selected={startDate}
      onChange={(date) => onSelected(date)}
    />
  );
}

export default Calendarpicker;
