import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
const dataToken = localStorage.getItem("token");
// sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://172.21.220.235:3000",
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const verifyImport = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(`/attraction/verifyimport`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const submitImportData = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(`/attraction/importdata`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    // toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
    Swal.fire({
      title: "กรุณาตรวจสอบข้อมูล ไม่สามารถทำรายการได้",
      // text: error.response.data.message,
      icon: "error",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
      showCancelButton: true,
    });
  }
};

export const getDataAttraction = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get("/attraction", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getDataAttractionByID = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })

    const response = await instace.get(`/attraction/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const deleteAttractionByID = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.delete(`/attraction/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const CreateAttraction = async (body) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    };
    const formData = new FormData();
    formData.append("attractionName", body.attractionName);
    formData.append("categoryId", body.categoryId);
    formData.append("mobileNo", body.mobileNo);
    formData.append("openDay", body.openDay);
    formData.append("openTime", body.openTime);
    formData.append("closeTime", body.closeTime);
    formData.append("address", body.address);
    formData.append("attractionDetail", body.attractionDetail);
    formData.append("latitude", body.latitude);
    formData.append("longtitude", body.longtitude);
    formData.append("isRecommend", body.isRecommend);
    formData.append("file", body.file);
    const response = await instace.post(`/attraction`, formData, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const UpdateAttraction = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    };
    const formData = new FormData();
    formData.append("attractionName", body.attractionName);
    formData.append("categoryId", body.categoryId);
    formData.append("mobileNo", body.mobileNo);
    formData.append("openDay", body.openDay);
    formData.append("openTime", body.openTime);
    formData.append("closeTime", body.closeTime);
    formData.append("address", body.address);
    formData.append("attractionDetail", body.attractionDetail);
    formData.append("latitude", body.latitude);
    formData.append("longtitude", body.longtitude);
    formData.append("isRecommend", body.isRecommend);
    formData.append("file", body.file);

    const response = await instace.put(`/attraction/${id}`, formData, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getDataAtt = async (data) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/attraction",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา
    params: data,
  };
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};

export const getAttractionCategory = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get("/attraction/category", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
