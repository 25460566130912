import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Button3items from '../button/Button3items'
import { useNavigate } from 'react-router-dom'
import Table from '../Tables/TableShop'
import DataMocShop from '../dataMockup/DataMockShop'

function Advrecommendstore() {

  const enableButton = {view: true, edit: true, delete: true}
  const navigate = useNavigate();
  function navigateToAddEdit(page){
    navigate(page);
  }

  return (
    <>
    <Header parentmenu={'จัดการข้อมูลร้านค้า'}/>
    <Menu parentmenu={'advertise'} activemenu={'recommendStore'}/>
    <div className="content-wrapper">
    <div className="container-fluid p-2 p-md-3">
      <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-pr.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประชาสัมพันธ์
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                |  ประชาสัมพันธ์ / จัดการข้อมูลร้านค้า
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
            <Button3items navigateToAddEdit={navigateToAddEdit} pageAddEditName={'/AddShop'}/>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
              <input className="inp-data01 fontReg font-size03" placeholder='ค้นหาข้อมูลชื่อร้านค้า'/>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-blue01">
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01">
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    รีเซท
                  </span>
                </button>
              </div>
            </div>
          </div>
            <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการข้อมูลร้านค้า
                </p>
              </div>
            </div>
            <Table item={DataMocShop} pageAddEditName={'/EditShop'}actionsBtn={enableButton} />
          </div>
          </div>
    </div>
    <div
              className="modal fade"
              id="exampleModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                      id="exampleModalLabel"
                    >
                      ดูรายละเอียด
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="col-12">
                      <p className="fontReg font-weight-bold font-size03 colorGray01">
                        <span className="border-left01"></span>ข้อมูลร้านค้า
                      </p>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ชื่อร้านค้า: ชินจัง Shoping
                      </p>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        เบอร์โทรศัพท์: 092-3234124
                      </p>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        เวลาทำการ: 08:00-19:00 น.
                      </p>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ที่อยู่ร้านค้า: เขตดินแดน แขวงดินแดน จังหวัดกรุงเทพมหานคร 74012
                      </p>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        รายละเอียดร้านค้า: ร้านอาหาร ขายของทั่วไป คาเฟ่
                      </p>
                    </div>
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ละติจูด: 5 ํ 37 เหนือ กับ 20 ํ 27 เหนือ
                      </p>
                      </div>
                      <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ลองติจูด: 97 ํ 22 ตะวันออก กับ 105 ํ 37 ตะวันออก
                      </p>
                      </div>
                       <div className="col-12 ml-2 mb-3">
                      <span className="fontReg font-size03 colorGray01 d-flex">รูปภาพร้านค้า: </span>
                      <img
                        src="assets/images/wat.jpg"
                        alt="Type Sport"
                        className="im-sports-type-dt"/>
                    </div>
                  </div>
                </div>
              </div>
          </div>
    <Footer/>
    </>
  )
}

export default Advrecommendstore