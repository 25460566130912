import React, { useEffect, useState } from "react";
import moment from "moment";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button3items from "../button/Button3items";
import { useNavigate } from "react-router-dom";
import Dropdown from "../dropdowns/Dropdown";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getSport } from "../../api/sport_api";
import { getAffliation } from "../../api/affliation_api";
import Loading from "./Loading";
import {
  DataDropdownAffiliation,
  DataDropdownSportType,
  DataDropdownSexs,
} from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import { getDataUser } from "../config/configApi-User";
import {
  geteAthlete,
  getAthleteById,
  deleteAthlete,
  getDataAthleteFilter,
} from "../../api/athlete_api";
import Swal from "sweetalert2";

function Dataathlete() {
  const DataDropdownSexs = [
    { value: "", text: "ทั้งหมด" },
    { value: "หญิง", text: "หญิง" },
    { value: "ชาย", text: "ชาย" },
    { value: "อื่นๆ", text: "อื่นๆ" },
  ];
  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };
  const navigate = useNavigate();
  const [spanloding, setspanloding] = useState(false);
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const getAffliationDropDown = async () => {
    try {
      const response = await getAffliation();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];

        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.affliationID,
            text: item.affliationName,
            subType: [],
          });
        });
        // const dropdown = response.data.data
        // const Affliation = dropdown.map(item => {
        //   const Aff = {
        //     value: item.affliationID,
        //     text: item.affliationName,
        //     subType: []
        //   }
        //   return Aff
        // })

        setAffirmationDropdown(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSportDropDown = async () => {
    try {
      const response = await getSport();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];

        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.sportID,
            text: item.sportName,
            subType: [],
          });
        });

        // const dropdown = response.data.data

        // const sport = dropdown.map(item => {
        //   const Spt = {
        //     value: item.sportID,
        //     text: item.sportName,
        //     subType: []
        //   }
        //   return Spt
        // })

        setDataSportType(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [rowsdata, setRowsdata] = useState([]);
  const [dataGetID, setDataGetID] = useState([]);
  const [query, setQuery] = useState({
    name: "",
    affliation: "",
    gender: "",
    sport: "",
  });

  const [email, setEmail] = useState(null);
  const [affliationDropdown, setAffirmationDropdown] = useState([]);
  const [dataSportType, setDataSportType] = useState([]);
  let sportType = [{ value: "0", text: "ทั้งหมด" }];
  let affType = [{ value: "0", text: "ทั้งหมด" }];
  const onChange = (e) => {
    const { name, value } = e.target;
    setQuery((prevs) => ({
      ...prevs,
      [name]: value,
    }));
  };

  const getListAthlete = async () => {
    setspanloding(true);
    try {
      const response = await geteAthlete();
      if (response) {
        const athleteData = response.data.data;
        const results = athleteData.map((row, i) => {
          const rowData = {
            id: { value: i + 1, dataID: row.memberID, type: "text" },
            // image: { value: "assets/images/im-taekwondo?.png", type: "image" },
            name: { value: row?.firstname + " " + row?.lastname, type: "text" },
            sex: {
              value: row.gender === null ? "-" : row.gender,
              type: "text",
            },
            sportType: {
              value: row.sport === null ? "-" : row.sport,
              type: "text",
            },
            affiliation: {
              // value: row?.affliation,
              value: row.affliation === null ? "-" : row.affliation,
              type: "text",
            },
            // status: { value: row?.active, type: "text" },
            updateddate: {
              value:
                row.updatedAt === null
                  ? moment(new Date(row.createdAt)).format("YYYY-MM-DD")
                  : moment(new Date(row.updatedAt)).format("YYYY-MM-DD"),
              type: "text",
            },
            action: { value: "1", type: "action" },
          };
          return rowData;
        });
        await setspanloding(false);
        setRowsdata(results);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getByID = async (id) => {
    try {
      const response = await getAthleteById(id);
      if (response) {
        const athlete = response.data.data;

        return athlete;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await deleteAthlete(id);
            if (response.status === 200) {
              getListAthlete();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const viewModal = async (id) => {
    try {
      const data = await getByID(id);

      const rowData = {
        title: data.title === null ? "-" : data.title,
        firstname: data.firstname === null ? "-" : data.firstname,
        lastname: data.lastname === null ? "-" : data.lastname,
        id: data.memberID,
        // idcard: data.idcard === null ? "-" : data.idcard,
        // tel: data.mobileNo === null ? "-" : data.mobileNo,
        image: data.img ? data.img : null,
        name: null,
        gender: data.gender === null ? "-" : data.gender,
        affliation: data.affliation === null ? "-" : data.affliation,
        // coach: "-",

        email: data.email === null ? "-" : data.email,
        socialType: data.socialType === null ? "-" : data.socialType,
        idcard:
          data.idcard === null || data.idcard === ""
            ? "-"
            : `${data.idcard.slice(0, 1)}-${data.idcard.slice(
                1,
                10
              )}-${data.idcard.slice(10, 12)}-${data.idcard.slice(12, 13)}`,
        tel:
          data.mobileNo === null
            ? "-"
            : `${data.mobileNo.slice(0, 3)}-${data.mobileNo.slice(
                3,
                6
              )}-${data.mobileNo.slice(6, 10)}
        `,
      };

      setDataGetID(rowData);
      //set data
      // await setEmail(data.email);
    } catch (error) {}
  };

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditathlete`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditathlete?id=${id}`);
        break;
      case "view":
        // handle view button
        viewModal(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const handleExport = async (type) => {
    let Header = ["No", "ชื่อ", "เพศ", "ชนิดกีฬา", "สังกัด", "อัปเดตล่าสุด"];
    let Data = rowsdata.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.name.value;
      dt[2] = elemento.sex.value;
      dt[3] = elemento.sportType.value;
      dt[4] = elemento.affiliation.value;
      dt[5] = elemento.updateddate.value;
      return dt;
    });

    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานนักกีฬา",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  useEffect(() => {
    getListAthlete();
    getAffliationDropDown();
    getSportDropDown();
    // getDataSort();
  }, []);

  const columnsname = [
    "id",
    "name",
    "sex",
    "sportType",
    "affiliation",
    // "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อ",
    "เพศ",
    "ชนิดกีฬา",
    "สังกัด",
    // "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    // "text",
    "text",
    "action",
  ];

  const getDataFilter = async () => {
    setspanloding(true);
    let respon;
    let rowsdata = [];
    if (
      inputForm.name === "" &&
      selectedValue1 === "" &&
      selectedValue2 === "" &&
      selectedValue3 === ""
    ) {
      await setspanloding(false);
      getListAthlete(null);
    } else {
      let DropdownForm = {
        name: inputForm.name,
        affiliation: selectedValue1,
        gender: selectedValue2,
        sportType: selectedValue3,
      };
      respon = await getDataAthleteFilter(DropdownForm);

      respon.data.data.forEach((row, i) => {
        return rowsdata.push({
          id: { value: i + 1, dataID: row.memberID, type: "text" },
          // image: { value: "assets/images/im-taekwondo?.png", type: "image" },
          name: { value: row?.firstname + " " + row?.lastname, type: "text" },
          sex: {
            value: row.gender === null ? "-" : row.gender,
            type: "text",
          },
          sportType: {
            value: row.sport === null ? "-" : row.sport,
            type: "text",
          },
          affiliation: {
            // value: "-",
            value: row.affliation === null ? "-" : row.affliation,
            type: "text",
          },
          // status: { value: row?.active, type: "text" },
          // createdAt
          updateddate: {
            value:
              row.updatedAt === null
                ? moment(new Date(row.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(row.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      await setspanloding(false);
      setRowsdata(rowsdata);
    }
  };

  const [inputForm, setInputForm] = useState({
    name: "",
    affiliation: "",
    gender: "",
    sportType: "",
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [valuefromparent3, setValuefromparent3] = useState("ทั้งหมด");
  const [selectedValue1, setSelectedValue1] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");
  const [selectedValue3, setSelectedValue3] = useState("");
  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.name = "";
    setSelectedValue1("");
    setSelectedValue2("");
    setSelectedValue3("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setValuefromparent3("ทั้งหมด");
    getListAthlete();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValuefromparent3(text) {
    setValuefromparent3(text);
  }

  const handleAffiliationChange = (value) => {
    setSelectedValue1(value);
  };

  const handleGenderChange = (value) => {
    setSelectedValue2(value);
  };

  const handleSportTypeChange = (value) => {
    setSelectedValue3(value);
  };

  return (
    <>
      {spanloding === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"นักกีฬา"} />
      <Menu parentmenu={"athleteCoach"} activemenu={"athlete"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-coach.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                นักกีฬา
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลนักกีฬาและโค้ช / นักกีฬา
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditathlete"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-6 pr-2 pr-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อจริง นามสกุล"
                  onChange={onChangeInputName}
                  value={name}
                />
              </div>
            </div>
            <div className="row justify-content-start mt-3">
              <div className="col-12 col-sm-3 pr-sm-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">สังกัด</p>
                  <Dropdown
                    options={affliationDropdown}
                    setinput={setinput}
                    inputname={"affiliation"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleAffiliationChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">เพศ</p>
                  <Dropdown
                    options={DataDropdownSexs}
                    setinput={setinput}
                    inputname={"gender"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleGenderChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชนิดกีฬา
                  </p>
                  <Dropdown
                    options={dataSportType}
                    setinput={setinput}
                    inputname={"sportType"}
                    ChangesetValuefromparent={ChangesetValuefromparent3}
                    valuefromparent={valuefromparent3}
                    onChange={handleSportTypeChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    getDataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-sm-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการนักกีฬา
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Addeditathlete"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowsdata}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="col-12 ml-2 mt-3">
                  <p className="fontReg font-size03 colorGray01">
                    คำนำหน้า: {dataGetID.title}
                  </p>
                </div>
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    ชื่อจริง: {dataGetID.firstname}
                  </p>
                </div>
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    นามสกุล: {dataGetID.lastname}
                  </p>
                </div>
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    เลขบัตรประชาชน: {dataGetID.idcard}
                  </p>
                </div>
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    เพศ: {dataGetID.gender}
                  </p>
                </div>
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    สังกัด: {dataGetID.affliation}
                  </p>
                </div>
                <div className="col-12 mb-3 text-start ml-2">
                  <p
                    className={
                      dataGetID.image
                        ? "fontReg font-size03 colorGray01"
                        : "d-none"
                    }
                  >
                    {" "}
                    รูปภาพ:{" "}
                  </p>
                  <img
                    width={"150px"}
                    src={dataGetID.image}
                    className={dataGetID.image ? "" : "d-none"}
                  />
                </div>
              </div>

              {/* <div className="card-white mt-3"> */}
              {/* <div className="row">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>รายการนักกีฬา
                    </p>
                  </div>
                </div> */}
              {/* <TableData
                  pageAddEditName="/Addeditathlete"
                  actionsBtn={enableButton}
                  columnsname={columnsname}
                  columnsText={columnsText}
                  columnsType={columnsType}
                  rowsdata={rowsdata}
                  isSearchEnable={false}
                  onButtonClicked={handleButtonClicked}
                /> */}
              {/* </div> */}
              {/* Modal */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Dataathlete;
