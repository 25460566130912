import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button3items from "../button/Button3items";
import { useNavigate } from "react-router-dom";
import Dropdown from "../dropdowns/Dropdown";
import {
  getSubSport,
  getfilterSubsport,
  getSubSportList,
  getSubSportById,
  getSubSportFind,
} from "../../api/subsport_api";
import { getSport } from "../../api/sport_api";
import TableData from "../Tables/TableData";
import moment from "moment";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import Loading from "./Loading";
function Mastermatchtype() {
  const [subTypes, setSubTypes] = useState([]);
  const [dataSubSport, setDataSubSport] = useState([]);
  const [dataDropsport, setDataDropsport] = useState([]);
  const [dataDropSubSport, setDataDropSubSport] = useState([
    { value: "", text: "ทั้งหมด" },
  ]);
  let DataDropdownSportType = [{ value: "0", text: "ทั้งหมด" }];
  let DataDropdownSubSport = [{ value: "0", text: "ทั้งหมด" }];
  const DataDropdownStatus = [
    { value: "", text: "ทั้งหมด" },
    { value: true, text: "ใช้งาน" },
    { value: false, text: "ไม่ใช้งาน" },
  ];
  const setsubtype_data = (data) => {
    //console.log(data); // LOGS DATA FROM CHILD
    setSubTypes(data);
  };
  let subTypeoption = [];
  const [dataSubsport, setDtSubsport] = useState([]);
  const [dataStatus, setDtStatus] = useState([]);
  useEffect(() => {
    // getSubSportDropDown();
    getDataSubSport();
    getSportDropDown();
  }, []);

  const [inputForm, setInputForm] = useState({
    inp1: "", //ชนิดกีฬา
    inp2: "", //ประเภทการแข่งขัน
    inp3: "", //สถานะ
  });
  const [dtDetial, setDtDetial] = useState([]);
  const [spanloader, setSpanLoader] = useState(false);
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }
  const getDataMatchID = async (id) => {
    let getData = [];
    const respon = await getSubSportById(id);
    let objData = respon.data.data;
    getData.push({
      subSportName: objData.sport.sportName,
      typeSub: objData.subSportName,
      active: objData.active === false ? "ไม่ใช้งาน" : "ใช้งาน",
    });
    // setDtStatus(objData.active);
    setDtDetial(getData[0]);
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditmatchtype`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditmatchtype?id=${id}`);
        break;
      case "view":
        // handle view button
        getDataMatchID(id);

        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const enableButton = {
    view: true,
    edit: true,
    delete: false,
    add: false,
    approve: false,
  };

  const columnsname = [
    "id",
    "sportType",
    "matchType",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ชนิดกีฬา",
    "ประเภทการแข่งขัน",
    "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];
  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   sportType: { value: "เทควันโด", type: "text" },
    //   matchType: { value: "หญิงเดี่ยว", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];
  const getDataSubSport = async () => {
    await setSpanLoader(true);

    const respon = await getSubSport();
    await setSpanLoader(false);
    respon.data.data.forEach((objectData, i) => {
      return rowsdata.push({
        //
        id: { value: i + 1, type: "text", dataID: objectData.subSportID },
        sportType: {
          value: objectData.sport === null ? "-" : objectData.sport.sportName,
          type: "text",
        },
        matchType: { value: objectData.subSportName, type: "text" },
        status: {
          value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
          active: objectData.active,
          type: "text",
        },
        // createdAt
        updateddate: {
          value:
            objectData.updatedAt !== null
              ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
              : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
          type: "text",
        },
        action: { value: "1", type: "action" },
      });
    });
    setDataSubSport(rowsdata);

    let dataType = [{ value: "", text: "ทั้งหมด" }];
    respon.data.data.forEach((item, i) => {
      if (item.subSportName) {
        return dataType.push({
          value: item.subSportID,
          text: item.subSportName,
        });
      }
    });
    setDtSubsport(dataType);
  };

  const getDataFilter = async () => {
    // const [inputForm, setInputForm] = useState({
    //   inp1: "", //ชนิดกีฬา
    //   inp2: "", //ประเภทการแข่งขัน
    //   inp3: "", //สถานะ
    // });
    await setSpanLoader(true);
    console.log("inputForm dt  : ", inputForm);
    if (
      inputForm.inp1 === "" &&
      inputForm.inp2 === "" &&
      inputForm.inp3 === ""
    ) {
      await setSpanLoader(false);
      getDataSubSport();
    } else {
      let bodyFilter = {
        // inp1: selectedSubSport,
        // inp2: selectedSport,
        // inp3: selectedStatus,
        inp1: inputForm.inp2,
        inp2: inputForm.inp1,
        inp3: inputForm.inp3,
      };
      const response = await getfilterSubsport(bodyFilter);
      await setSpanLoader(false);
      response.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          //
          id: { value: i + 1, type: "text", dataID: objectData.subSportID },
          sportType: {
            value: objectData.sport === null ? "-" : objectData.sport.sportName,
            type: "text",
          },
          matchType: { value: objectData.subSportName, type: "text" },
          status: {
            value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            active: objectData.active,
            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt !== null
                ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
                : "-",
            type: "text",
          },
          action: { value: "1", type: "action" },
          //

          //
        });
      });
      setSpanLoader(false);
      setDataSubSport(rowsdata);
    }
  };

  const getSportDropDown = async () => {
    try {
      const response = await getSport();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.sportID,
            text: item.sportName,
            subType: [],
          });
        });

        setDataDropsport(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSubSportDropDown = async () => {
    try {
      const response = await getSubSport();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.subSportID,
            text: item.subSportName,
          });
        });

        setDtSubsport(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let subsport = [{ value: "", text: "ทั้งหมด" }];

  const [selectedSport, setSelecteSport] = useState("");
  const [selectedSubSport, setSelecteSubSport] = useState("");
  const [selectedStatus, setSelecteStatus] = useState("");

  const handleSportChange = async (value) => {
    await setSpanLoader(true);
    const respon = await getSubSportFind(value);
    await setSpanLoader(false);
    let dataType = [{ value: "", text: "ทั้งหมด" }];
    respon.data.data.forEach((item, i) => {
      return dataType.push({
        value: item.subSportID,
        text: item.subSportName,
      });
    });
    setDtSubsport(dataType);
    // const response = await getSubSportList(bodyFilter);
    // response.data.data.forEach((item, i) => {
    //   return subsport.push({
    //     value: item.subSportID,
    //     text: item.subSportName,
    //     subType: [],
    //   });
    // });
    // setDataDropSubSport(subsport);
    setSelecteSport(value);
  };

  const handleSubSportChange = (value) => {
    setSelecteSubSport(value);
  };
  const handleStatusChange = (value) => {
    setSelecteStatus(value);
  };

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [valuefromparent3, setValuefromparent3] = useState("ทั้งหมด");

  function resetDropdown() {
    inputForm.inp1 = "";
    inputForm.inp2 = "";
    inputForm.inp3 = "";
    setSelecteSport("");
    setSelecteStatus("");
    setSelecteSubSport("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setValuefromparent3("ทั้งหมด");
    getDataSubSport();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValuefromparent3(text) {
    setValuefromparent3(text);
  }

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ชนิดกีฬา",
      "ประเภทการแข่งขัน",
      "สถานะ",
      "อัปเดตล่าสุด",
    ];
    let Data = dataSubSport.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.sportType.value;
      dt[2] = elemento.matchType.value;
      dt[3] = elemento.status.value;
      dt[4] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานประเภทการแข่งขัน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  return (
    <>
      {spanloader === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"ประเภทการแข่งขัน"} />
      <Menu parentmenu={"masterData"} activemenu={"matchType"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประเภทการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / ประเภทการแข่งขัน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName="/Addeditmatchtype"
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-sm-3 pr-sm-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชนิดกีฬา
                  </p>
                  <Dropdown
                    options={dataDropsport}
                    func={setsubtype_data}
                    setinput={setinput}
                    inputname={"inp1"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleSportChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ประเภทการแข่งขัน
                  </p>
                  <Dropdown
                    options={dataSubsport}
                    setinput={setinput}
                    inputname={"inp2"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleSubSportChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สถานะ</p>
                  <Dropdown
                    options={DataDropdownStatus}
                    setinput={setinput}
                    inputname={"inp3"}
                    ChangesetValuefromparent={ChangesetValuefromparent3}
                    valuefromparent={valuefromparent3}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    getDataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-sm-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการประเภทการแข่งขัน
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Editmatchtype"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataSubSport}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      ข้อมูลชนิดกีฬา
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชนิดกีฬา : {dtDetial.subSportName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ : {dtDetial.active}
                    </p>
                  </div>
                  <div className="col-12 mt-2">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      ข้อมูลประเภทการแข่งขัน
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ประเภทการแข่งขัน : {dtDetial.typeSub}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Mastermatchtype;
