import React, { useEffect, useState } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownStoreType } from "../dataMockup/DataMockDropdown";
import UploadIMG from "../uploads/UploadIMG";
import Calendarpicker from "../calendars/Calendarpicker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import UploadFileXLS from "../uploads/UploadFileXLS";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { createCampaign } from "../../api/campaign_api";
import moment from "moment";
import Loading from "../pages/Loading";
function Addeditcampaign() {
  const [status, setStatus] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [DataDetial, setDataDetial] = useState(null);
  let [checked, setChecked] = useState(false);
  const [inputForm, setInputForm] = useState({
    campaignName: "", //ชื่อแคมเปญ
    campaignAmount: "", //จำนวนสิทธิ์
    campaignType: 0, //ประเภทร้านค้า
    campaignImage: null, //รูปแคมเปญ
    campaignDetail: null, //รายละเอียดเเคมเปญ
    startTime: null, //วันที่เริ่มต้น
    endTime: null, //วันที่สิ้นสุด
    inp7: null, //Upload File
    activeStatus: false,
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [groupShow, setGroupShow] = useState(false);
  const [dataImage, setDataImage] = useState(null);
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/campaign");
      }
    });
  }

  const handleSelectedImages = (images) => {
    setDataImage(images);
  };
  const [selectedShop, setSelecteShop] = useState("");
  const handleShopChange = (value) => {
    setSelecteShop(value);
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numericInput = inputValue.replace(/\D/g, ""); // Regular expression to allow only digits
    // setInputValue(numericInput);
    setinput("campaignAmount", numericInput);
  };
  const handleDetail = (dt) => {
    setDataDetial(dt);
    // const data = editor.getData();
  };
  const postCampaigncreate = async () => {
    const swalWithBootstrapButtons = Swal.mixin();

    if (
      inputForm.campaignName == "" ||
      inputForm.campaignName == null ||
      inputForm.startTime == null ||
      inputForm.endTime == null ||
      inputForm.campaignAmount == "" ||
      inputForm.campaignAmount == null ||
      dataImage[0] == undefined
    ) {
      swalWithBootstrapButtons.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      });
    } else {
      let typeSh = null;
      if (selectedShop === "ร้านค้า") {
        typeSh = "store";
      } else if (selectedShop === "ร้านอาหาร") {
        typeSh = "food";
      } else {
        typeSh = "all";
      }
      const body = {
        campaignName: inputForm.campaignName,
        campaignDetail: DataDetial,
        campaignAmount: inputForm.campaignAmount,
        activeStatus: checked,
        campaignType: typeSh,
        startTime: inputForm.startTime,
        endTime: inputForm.endTime,
        // startTime: moment(new Date(inputForm.startTime)).format("DD-MM-YYYY"),
        // endTime: moment(new Date(inputForm.endTime)).format("DD-MM-YYYY"),
        file: dataImage[0] ? dataImage[0].file : "",
      };
      console.log("body : ", body);
      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        // confirmButtonText: "บันทึก",
        // cancelButtonText: `ยกเลิก`,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            setLoading(true);
            // const respon = null;
            const respon = await createCampaign(body);
            setLoading(false);
            // setLoading(1);
            if (respon.data.status === "success") {
              Swal.fire({
                title: "บันทึกสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                navigate("/campaign");
              });
            } else {
              Swal.fire({
                title: "เกิดข้อผิดพลาด",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: `ยกเลิก`,
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/campaign");
                }
              });
            }
          })();
        }
      });
      // const response = await createCampaign(body);
      // if (response && response.data.status === "success") {
      //   swalWithBootstrapButtons
      //     .fire({
      //       title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
      //       icon: "warning",
      //       showCancelButton: true,
      //       confirmButtonText: "ยืนยัน",
      //       cancelButtonText: "ยกเลิก",
      //       reverseButtons: true,
      //     })
      //     .then(async (result) => {
      //       if (result.isConfirmed) {
      //         await Swal.fire({
      //           title: "บันทึกข้อมูลสำเร็จ!",
      //           icon: "success",
      //           confirmButtonText: "ตกลง",
      //         }).then((result) => {
      //           if (result.isConfirmed) {
      //             navigate("/campaign");
      //           }})
      //       }
      //     });

      // } else {
      //   Swal.fire({
      //     title: "เกิดข้อผิดพลาด",
      //     icon : "error",
      //     showCancelButton: true,
      //     confirmButtonText: "ยืนยัน",
      //     cancelButtonText: `ยกเลิก`,
      //     reverseButtons: true,
      //   }).then((result) => {if(result.isConfirmed){
      //     navigate("/campaign");
      //   }});
      // }
    }
  };

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"แคมเปญ"} />
      <Menu parentmenu={"campaignData"} activemenu={"campaign"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-campaign.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                แคมเปญ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลแคมเปญ / แคมเปญ
              </font>
            </div>
            {/* <div className={`col-4 col-sm-3 justify-content-end align-items-center ${!groupShow ? "d-flex" : "d-none"} `}>
              <button className="btn-blue03 mr-2 mr-xl-3 w-100" onClick={()=>setGroupShow(true)}>
                <img
                  src="assets/icons/ic-add.png"
                  alt="ExportFiles Icon"
                  className="ic-add"
                />
                <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                  เพิ่มข้อมูลหลายรายการ
                </span>
              </button>
            </div> */}
            <div
              className={`col-4 col-sm-3 justify-content-end align-items-center ${
                groupShow ? "d-flex" : "d-none"
              } `}
            >
              <button
                className="btn-blue03 mr-2 mr-xl-3 w-100"
                onClick={() => setGroupShow(false)}
              >
                <img
                  src="assets/icons/ic-add.png"
                  alt="ExportFiles Icon"
                  className="ic-add"
                />
                <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                  เพิ่มข้อมูลรายการเดียว
                </span>
              </button>
            </div>
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>เพิ่มข้อมูลทีละรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อแคมเปญ"
                  value={inputForm.campaignName}
                  onChange={(e) => setinput("campaignName", e.target.value)}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0">
                <input
                  type={"text"}
                  className="inp-data01 fontReg font-size03"
                  placeholder="จำนวนสิทธิ์"
                  value={inputForm.campaignAmount}
                  onChange={handleInputChange}
                  // onChange={(e) => setinput("campaignAmount", e.target.value)}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ประเภทร้านค้า
                  </p>
                  {/* <Dropdown
                    options={DataDropdownStoreType}
                    setinput={setinput}
                    inputname={"campaignType"}
                  /> */}
                  <Dropdown
                    options={DataDropdownStoreType}
                    setinput={setinput}
                    inputname={"campaignType"}
                    // ChangesetValuefromparent={ChangesetValuefromparent2}
                    // valuefromparent={valuefromparent2}
                    onChange={handleShopChange}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-5">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  รูปแคมเปญ
                </p>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <UploadIMG
                  limit={1}
                  setImages={setinput}
                  inputname={"campaignImage"}
                  onSelectedImages={handleSelectedImages}
                />
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  วันที่เริ่มต้น-วันที่สิ้นสุด
                </p>
              </div>
              <div className="col-12 col-lg-4 pr-2 mt-lg-0 mt-2">
                <Calendarpicker
                  setDatePicker={setinput}
                  inputname={"startTime"}
                  dataDate={null}
                />
              </div>
              <label className="align-items-center d-none d-lg-flex"> - </label>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-lg-0 mt-2">
                <Calendarpicker
                  setDatePicker={setinput}
                  inputname={"endTime"}
                  dataDate={null}
                />
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  รายละเอียดแคมเปญ
                </p>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <CKEditor
                  inputname={"campaignDetail"}
                  editor={Editor}
                  config={{
                    fontSize: {
                      options: [
                        9,
                        11,
                        13,
                        "default",
                        17,
                        19,
                        21,
                        24,
                        28,
                        32,
                        36,
                        38,
                        42,
                      ],
                    },
                  }}
                  data="<p>รายละเอียดแคมเปญ</p>"
                  setDatePicker={setinput}
                  // onReady={(editor) => {
                  //   const data = editor.getData();
                  //   // handleDetail(data);
                  //   // setDataDetial(data);

                  //   // You can store the "editor" and use when it is needed.

                  // }}
                  // onChange={(event, editor) => {}}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleDetail(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row ml-0 mt-3">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  checked={checked}
                  value={checked}
                  onChange={() => setChecked((prev) => !prev)}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save" onClick={postCampaigncreate}>
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/013-campaign_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <UploadFileXLS
                  limit={1}
                  setSelectFiles={setinput}
                  inputname={"inp7"}
                />
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save">บันทึก</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditcampaign;
