import { DataMockChartPromotionstore } from "../dataMockup/DadaMockChartPromotionstore";
import ChartBar from "../charts/ChartBar";
import { Link } from "react-router-dom";
import "./cardPromotionalUse.css";
import React, { useState, useEffect } from "react";
import { Chart } from "chart.js";
Chart.defaults.font.family = "'db_heaventregular'";

function CardPromotionalUse(props) {
  const [dataCal, setDataCal] = useState([]);
  const [allShop, setallShop] = useState([]);
  const [allFood, setallFood] = useState([]);
  const [promotionStoreUseData] = useState({
    labels: props.dataCalGH.food.map((data) => data.label),
    datasets: [
      {
        label: "ร้านค้า",
        data: props.dataCalGH.store.map((data) => data.value),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        hoverBackgroundColor: "rgba(53, 162, 235, 1)",
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  });

  const [promotionRestaurantUseData] = useState({
    labels: props.dataCalGH.store.map((data) => data.label),
    datasets: [
      {
        label: "ร้านอาหาร",
        data: props.dataCalGH.food.map((data) => data.value),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        hoverBackgroundColor: "rgba(53, 162, 235, 1)",
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  });
  useEffect(() => {
    getDataGH();
  }, []);
  const getDataGH = async () => {
    let rowsdata = [];

    setallFood(props.dataCalGH.totalFoodAmount);
    setallShop(props.dataCalGH.totalStoreAmount);
  };
  return (
    <>
      <div className="card-white mt-3">
        <p className="fontReg font-weight-bold font-size03 colorGray01">
          <span className="border-left01"></span>
          ปริมาณการใช้โปรโมชัน
        </p>
        <div className="row m-0 m-xl-1">
          <div className="col-12 col-md-6">
            <div className="box-chartBar">
              <div className="row align-items-end mb-3">
                <div className="col-5 col-sm-4 col-lg-5 col-xl-4">
                  <p className="fontReg font-weight-bold font-size03 colorGray01 m-0">
                    ผู้ใช้โปรโมชันร้านค้า
                  </p>
                </div>
                <div className="col-4 col-sm-4 col-lg-4 col-xl-4 justify-content-start justify-content-sm-center d-flex p-0">
                  <span className="fontReg">
                    <span className="font-weight-bold font-size04 colorBlue01">
                      {allShop}
                    </span>
                    <span className="font-weight-light font-size03 colorGray01 ml-1">
                      ร้านค้า
                    </span>
                  </span>
                </div>
                <div className="col-3 col-sm-4 col-lg-3 col-xl-4 justify-content-end d-flex">
                  <Link
                    className="fontReg font-size03 colorGray01"
                    to={"/promotionHist"}
                  >
                    ดูทั้งหมด
                    <span>
                      <i className="fas fa-angle-right ml-1" />
                    </span>
                  </Link>
                </div>
              </div>
              <ChartBar chartPromotionData={promotionStoreUseData} />
              <p className="fontReg font-size03 font-weight-light colorGray01 d-flex justify-content-end text-right mt-2">
                Last Update {props.dataTime}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <div className="box-chartBar">
              <div className="row align-items-end mb-3">
                <div className="col-5 col-sm-4 col-lg-5 col-xl-4">
                  <p className="fontReg font-weight-bold font-size03 colorGray01 m-0">
                    ผู้ใช้โปรโมชันร้านอาหาร
                  </p>
                </div>
                <div className="col-4 col-sm-4 col-lg-4 col-xl-4 justify-content-start justify-content-sm-center d-flex p-0">
                  <span className="fontReg">
                    <span className="font-weight-bold font-size04 colorBlue01">
                      {allFood}
                    </span>
                    <span className="font-weight-light font-size03 colorGray01 ml-1">
                      ร้านค้า
                    </span>
                  </span>
                </div>
                <div className="col-3 col-sm-4 col-lg-3 col-xl-4 justify-content-end d-flex">
                  <Link
                    className="fontReg font-size03 colorGray01"
                    to={"/promotionHist"}
                  >
                    ดูทั้งหมด
                    <span>
                      <i className="fas fa-angle-right ml-1" />
                    </span>
                  </Link>
                </div>
              </div>
              <ChartBar chartPromotionData={promotionRestaurantUseData} />
              <p className="fontReg font-size03 font-weight-light colorGray01 d-flex justify-content-end text-right mt-2">
                Last Update {props.dataTime}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardPromotionalUse;
