import React, { useState } from "react";
import "./Forgotpassword.css";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { postForgotpassword } from "../../api/forgotpassword_api";
import { getDataUser } from "../config/configApi-User";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "./Loading";
function Forgotpassword() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(null);
  const [typeNewPass, setTypeNewPass] = useState("password");
  const [iconNewPass, setIconNewPass] = useState(eyeOff);
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errRepassword, setErrRepassword] = useState("");
  const [passwordColor, setPasswordColor] = useState("");
  const [repasswordColor, setRepasswordColor] = useState("");
  const [emailDt, setEmail] = useState("");
  const handleToggleNewPass = () => {
    if (typeNewPass === "password") {
      setIconNewPass(eye);
      setTypeNewPass("text");
    } else {
      setIconNewPass(eyeOff);
      setTypeNewPass("password");
    }
  };

  const [typeCFPass, setTypeCFPass] = useState("password");
  const [iconCFPass, setIconCFPass] = useState(eyeOff);
  const handleToggleCFPass = () => {
    if (typeCFPass === "password") {
      setIconCFPass(eye);
      setTypeCFPass("text");
    } else {
      setIconCFPass(eyeOff);
      setTypeCFPass("password");
    }
  };
  const getApiUser = async () => {
    const respon = await getDataUser();
    if (respon.data.status === "success") {
      // getEmail(respon.data.data.email);
      let data = { email: respon.data.data.email };
      const rs = await postForgotpassword(data);
      if (rs.data.status === "success") {
    
      }
    }
  };
  function navigateToBack(page) {
    navigate(page);
  }
  const postForgotpaaword = async (e) => {
    let data = { email: emailDt };
 
    if (emailDt === "") {
      Swal.fire({
        icon: "error",
        title: "กรุณากรอกอีเมลที่ถูกต้อง",
        showCancelButton: false,
        confirmButtonText: "รับทราบ",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            setLoading(true);
            const respon = await postForgotpassword(data);
            setLoading(false);
            if (respon && respon.data.status === "success") {
              // navigate("/forgotPassword");
              Swal.fire({
                icon: "success",
                title: "ระบบจะส่งข้อมูลการแก้ไขรหัสผ่านใหม่ให้คุณทางอีเมล ",
                showCancelButton: false,
                confirmButtonText: "รับทราบ",
                cancelButtonText: `ยกเลิก`,
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToBack("/login");
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "กรุณากรอกอีเมลที่ถูกต้อง",
                showCancelButton: false,
                confirmButtonText: "รับทราบ",
                cancelButtonText: `ยกเลิก`,
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToBack("/Chpassword");
                }
              });
            }
          })();
        }
      });
    }
  };
  const validateForm = (e) => {
    e.preventDefault();

    // if (password.length >= 8) {
    //   setErrPassword("");
    //   setPasswordColor("#8cac4b");
    // } else {
    //   setErrPassword("รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร");
    //   setPasswordColor("#dc3545");
    // }

    // if (repassword !== "" && repassword === password) {
    //   setErrRepassword("");
    //   setRepasswordColor("#8cac4b");
    // } else {
    //   setErrRepassword("รหัสผ่านไม่ตรงกัน");
    //   setRepasswordColor("#dc3545");
    // }
  };
  return (
    <>
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <nav className=" navbar navbar-expand navbar-white navbar-light">
        <div className="container">
          <img
            src="assets/images/im-logoNW.png"
            alt="NW-Sports Logo"
            className="logoNW-fp img-circle"
          />
          <span className="fontReg font-weight-bold font-size01 line-height01 colorViolet02 d-none d-sm-inline-block">
            ระบบจัดการข้อมูลงานกีฬานครสวรรค์
          </span>
          <span className="fontReg font-weight-bold font-size02 line-height01 ml-2 colorGray01">
            ตั้งรหัสผ่านใหม่
          </span>
        </div>
      </nav>
      <div className="containerFluid">
        <div className="d-flex align-items-center justify-content-center">
          <div className="card-fp">
            <div className="col-12 d-flex justify-content-center">
              <span className="fontReg font-weight-bold font-size01 line-height01 colorGray01">
                ตั้งรหัสผ่านใหม่
              </span>
            </div>
            {/*  */}
            <form className="form" onSubmit={validateForm}>
              <div className="row mt-3">
                <div className="col-12 col-sm-3 d-flex align-items-center">
                  <span className="fontReg font-size03 line-height01 colorGray01">
                    อีเมล
                  </span>
                </div>
                <div className="col-12 col-sm-9">
                  <div
                    className="inp-field"
                    // style={{ borderColor: passwordColor }}
                  >
                    <input
                      //   type={text}
                      //   type={typeNewPass}
                      // className="inp-login fontLight font-size03"
                      // id="new_password"
                      //   placeholder="ต้องมีทั้งตัวเลขและตัวอักษร อย่างน้อง 8 ตัว"
                      // value={text}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* <span className="mr-2" onClick={handleToggleNewPass}>
                      <Icon icon={iconNewPass} size={15} />
                    </span> */}
                  </div>
                  <small style={{ color: passwordColor }}>{errPassword}</small>
                </div>
              </div>
              <div className="col-12 p-0 mt-3">
                <button
                  onClick={(event) => postForgotpaaword()}
                  type="submit"
                  className="btn-blue fontReg font-weight-bold font-size03"
                >
                  ยืนยัน
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgotpassword;
