import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import UploadFileXLS from "../uploads/UploadFileXLS";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Exceldata from "../uploads/EXLS";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import ImportExcel from "../uploads/ExcelRender";
import {
  getAffliation,
  getAffliationById,
  deleteAffliation,
  updateAffliation,
  createAffliation,
  verifyImport,
  submitImportData,
} from "../../api/affliation_api";
import { active } from "glamor";
function Addeditaffiliation() {
  const [isLoading, setLoading] = useState(null);
  const [groupShow, setGroupShow] = useState(false);
  const [dataAffID, setDataAffID] = useState([]);
  const [dataAffName, setDataaffName] = useState(null);
  const [dataSector, setDataSector] = useState(null);
  const navigate = useNavigate();
  let [checked, setChecked] = useState(null);
  function navigateToBack(page) {
    navigate(page);
  }
  const handleChangeAffname = async (event) => {
    setDataaffName(null);
    if (event) {
      setDataaffName(event.target.value);
    }
  };
  // handleChangeSector
  const handleChangeSector = async (event) => {
    setDataSector(null);
    if (event) {
      setDataSector(event.target.value);
    }
  };
  const getAffiliationtID = async (Id) => {
    let getData = [];
    const respon = await getAffliationById(Id);

    getData.push({
      affliationID: respon.data.data.affliationID,
      affliationName: respon.data.data.affliationName,
      sector: respon.data.data.sector,
      status: respon.data.data.active,
    });

    setDataAffID(getData[0]);
    setDataaffName(getData[0].affliationName);
    setDataSector(getData[0].sector);
    setChecked(getData[0].status);
    // setDataImage(getData[0].image);
    // setDatasubArea(getData[0].subArea);
  };
  const postAffiliation = async () => {
    // const [dataAffName, setDataaffName] = useState([]);
    // const [dataSector,

    if (id !== null) {
      let body = {
        affliationName: dataAffName,
        sector: dataSector,
        active: checked,
      };

      // postDataUpdateArea
      if (
        dataAffName === null ||
        dataAffName === "" ||
        dataSector === "" ||
        dataSector === null
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        Swal.fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              const respon = await updateAffliation(id, body);
              // setLoading(1);
              if (respon.data.status === "success") {
                setLoading(1);
                await Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigateToBack("/affiliation");
                  }
                });
              }
            })();
          }
        });
      }
      // }
    } else {
      let body = {
        affliationName: dataAffName,
        sector: dataSector,
        active: checked === null ? false : checked,
      };
      // postDataUpdateArea
      if (
        dataAffName === null ||
        dataAffName === "" ||
        dataSector === "" ||
        dataSector === null
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              const respon = await createAffliation(body);

              // setLoading(1);
              if (respon.data.status === "success") {
                setLoading(1);
                Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigateToBack("/affiliation");
                  }
                });
              }
            })();
          }
        });
      }
    }
  };

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/affiliation");
      }
    });
  }
  let id = new URLSearchParams(window.location.search).get("id");
  useEffect(() => {
    if (id) {
      getAffiliationtID(id);
    }
  }, []);

  // *********************** Take Import DATA ************************************
  // const [importData, setImportData] = useState([]);
  // const [isVerify, setIsVerify] = useState(true);
  // const [verifySuccess, setVerifySuccess] = useState(0);
  // const [verifySkip, setVerifySkip] = useState(0);
  // const [totalRow, setTotalRow] = useState(0);

  // const handleImport = async (rows) => {
  //   await setImportData(rows);
  // };

  // const verifyData = async () => {
  //   try {
  //     let success = 0;
  //     let skip = 0;
  //     let error = 0;
  //     const batchSize = 10;
  //     setTotalRow(importData.length - 1);
  //     for (let i = 1; i < importData.length; i += batchSize) {
  //       const batch = importData.slice(i, i + batchSize);
  //       const data = batch.map((item) => {
  //         const sport = {
  //           affliationName: item[0],
  //           sector: item[1],
  //           active: item[2],
  //         };
  //         return sport;
  //       });
  //       const response = await verifyImport(data);
  //       if (response) {
  //         success += response.data.success;
  //         skip += response.data.skip;
  //         setVerifySuccess(success);
  //         setVerifySkip(skip);
  //       } else {
  //         error++;
  //         Swal.fire({
  //           title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
  //           icon: "error",
  //           confirmButtonText: "ตกลง",
  //         });
  //         return;
  //       }
  //     }
  //     if (error === 0) {
  //       Swal.fire({
  //         title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
  //         icon: "success",
  //         confirmButtonText: "ตกลง",
  //       });
  //       setIsVerify(false);
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       title: "เกิดข้อผิดพลาด",
  //       icon: "error",
  //       showCancelButton: true,
  //       confirmButtonText: "ยืนยัน",
  //       cancelButtonText: `ยกเลิก`,
  //       reverseButtons: true,
  //     });
  //     console.log(error);
  //   }
  // };

  // const importSport = async () => {
  //   try {
  //     setVerifySuccess(0);
  //     let success = 0;
  //     let skip = 0;
  //     let error = 0;
  //     const batchSize = 10;
  //     setTotalRow(importData.length - 1);

  //     await Swal.fire({
  //       title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "ยืนยัน",
  //       cancelButtonText: "ยกเลิก",
  //       reverseButtons: true,
  //     }).then(async (result) => {
  //       error = result.isConfirmed;
  //       if (result.isConfirmed) {
  //         for (let i = 1; i < importData.length; i += batchSize) {
  //           const batch = importData.slice(i, i + batchSize);
  //           const data = batch.map((item) => {
  //             const sport = {
  //               affliationName: item[0],
  //               sector: item[1],
  //               active: item[2],
  //             };
  //             return sport;
  //           });
  //           const response = await submitImportData(data);
  //           if (response) {
  //             success += response.data.success;
  //             skip += response.data.skip;
  //             setVerifySuccess(success);
  //             setVerifySkip(skip);
  //           } else {
  //             error++;
  //             Swal.fire({
  //               title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
  //               icon: "error",
  //               confirmButtonText: "ตกลง",
  //             });
  //           }
  //         }
  //       } else {
  //         return;
  //       }
  //     });

  //     if (error === true) {
  //       Swal.fire({
  //         title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
  //         icon: "success",
  //         confirmButtonText: "ตกลง",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           setIsVerify(false);
  //           navigateToBack("/affiliation");
  //         }
  //       });
  //     } else if (error === false) {
  //       Swal.fire({
  //         title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonText: "ยืนยัน",
  //         cancelButtonText: "ยกเลิก",
  //         reverseButtons: true,
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           navigateToBack("/affiliation");
  //         }
  //       });
  //     }
  //     // else {
  //     //   Swal.fire({
  //     //     title: "เกิดข้อผิดพลาด",
  //     //     icon: "error",
  //     //     confirmButtonText: "ตกลง",
  //     //   });
  //     // }
  //   } catch (error) {
  //     Swal.fire({
  //       title: "เกิดข้อผิดพลาด",
  //       icon: "error",
  //       showCancelButton: true,
  //       confirmButtonText: "ยืนยัน",
  //       cancelButtonText: `ยกเลิก`,
  //       reverseButtons: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         navigateToBack("/affiliation");
  //       }
  //     });
  //     console.log(error);
  //   }
  // };

  // *********************** after ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);

  const handleImport = async (rows) => {
    // console.log("rows data : ", rows);
    // console.log("rows.length - 1 preview : ", rows.length - 1);
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);
    // console.log("rows.slice(1) preview  ", rows.slice(1));
    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          affliationName: item[0],
          sector: item[1],
          active: item[2],
        });
      }
    });
    await setImportData(row);
    // console.log("row data preview : ", row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length - 1);
      for (let i = 1; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          importData: data,
        };
        const response = await verifyImport(dataToVerify);
        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      if (error === 0) {
        Swal.fire({
          title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
          icon: "success",
          confirmButtonText: "ตกลง",
        });
        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importSport = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length - 1);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        if (result.isConfirmed) {
          for (let i = 1; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              // memberType: 2,
              importData: data,
            };
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      if (error === true) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/affiliation");
          }
        });
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/affiliation");
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/affiliation");
        }
      });
    }
  };

  return (
    <>
      {/* {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()} */}
      <Header parentmenu={"สังกัด"} />
      <Menu parentmenu={"masterData"} activemenu={"affiliation"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                สังกัด
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / สังกัด
              </font>
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อภาค"
                  value={dataSector}
                  onChange={handleChangeSector}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-2 mt-lg-0">
                <input
                  onChange={handleChangeAffname}
                  value={dataAffName}
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อสังกัด"
                />
              </div>
            </div>
            <div className="row ml-0 mt-3">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  // defaultChecked={checked}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  onClick={(event) => postAffiliation()}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/005-masterdata-affiliation_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                {/* <UploadFileXLS limit={1} onChange={handleFileChange}/> */}
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                {/* <span className='mx-2'>{verifySuccess}/{totalRow}</span> */}
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0 ? true : false}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditaffiliation;
