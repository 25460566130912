import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Dropdown from '../dropdowns/Dropdown';
import { useNavigate } from 'react-router-dom';
import '../pages/Mastersporttype.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
import UploadIMG from '../uploads/UploadIMG'

function EditAdmin() {

  const [nameAdmin, setnameAdmin] = useState('ศิรภูมิ')
  const [surAdmin, setsurAdmin] = useState('สุทธิสาร')
  const [imgAdmin, setImgAdmin] = useState(null)

  return (
    <>
    <Header parentmenu={'ตั้งค่าแอดมิน'}/>
    <Menu parentmenu={'adminSetting'} activemenu={'adminList'}/>
    <div className="content-wrapper">
    <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-settingAdmin.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ตั้งค่าแอดมิน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ตั้งค่าแอดมิน / รายชื่อ Admin แก้ไขข้อมูล
              </font>
            </div>
          </div>
          <Col className='card-white'>
          <Row className='mx-1'>
              <Col xs={12} md={12} lg={12}> 
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> รายละเอียดแอดมิน</span>
              </p>
              </Col>
            </Row>
            <Row className='mt-3'>
                <Col lg={2} md={2} xs={4}className='text-header text-end'>ชื่อ</Col>
                <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setnameAdmin(e.target.value)}} value={nameAdmin} /></Col>
          </Row>
          <Row className='mt-4 '>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>นามสกุล</Col>
            <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setsurAdmin(e.target.value)}} value={surAdmin} /></Col>
        </Row>
        <Row className='mt-4 mx-1'>
            <Col lg={2}></Col>
            <Col lg={10} className='drpdw-status'>
            <p className="fontReg font-size03 colorGray02 mb-1">Permission</p>
            <Dropdown />
            </Col>
        </Row>
        <Row className='mt-5'>
              <Col xs={4} md={2} lg={2} className='text-input text-end'>รูปภาพ</Col>
              <Col xs={8} md={10} lg={8} >
              <UploadIMG limit={1} value={imgAdmin} />
              </Col>
            </Row>
        </Col>
        <Row className='my-5'>
            <Col xs={2}></Col>
            <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-blue02'>บันทึก</button></Col>
            <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-cancle'>ยกเลิก</button></Col>
            <Col xs={2}></Col>
          </Row>
          </div>
          </div>
    <Footer/>
    </>
  )
}

export default EditAdmin