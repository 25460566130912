import React, {
  useEffect,
  useState,
  createRef,
  useCallback,
  useRef,
} from "react";
import { useDropzone } from "react-dropzone";
import Thumbnail from "./Thumbnail";
import "./UploadIMG.css";
import "../swalCustom/customStyle.css";

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

function UploadIMG(props) {
  const Swal = require("sweetalert2");
  const imgCount = useRef(0);
  const [images, setImages] = useState([]);
  const [isImageFull, setIsImageFull] = useState(false);

  let imgBoxs = useRef([]);
  let isboxInit = useRef(false);
  if (!isboxInit.current) {
    for (let i = 0; i < props.limit; i++) {
      imgBoxs.current.push("box");
      isboxInit.current = true;
    }
  }
  const [errorMessage, setErrorMessage] = useState("");
  var imgindex = 0;
  const onDrop = useCallback((acceptedFiles) => {
    //console.log(acceptedFiles);
    handleFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (id) => {
    const removeimageIndex = images.findIndex((e) => e.id === id);
    if (removeimageIndex !== -1) {
      imgCount.current--;
      images.splice(removeimageIndex, 1);
      // update unsupportedFiles array
      setImages([...images]);

      imgBoxs.current.push("box");
      setIsImageFull(false);
    }
  };

  const thumbs = images.map((file, index) => (
    <Thumbnail key={index} file={file} removeFile={removeFile} />
  ));

  const handleFiles = (files) => {
    if (!props.limit) {
      props.limit = 1;
    }

    const filesArray = [...images];
    let errormsg = "";
    let slots = 0;
    slots = props.limit - imgCount.current;
    if (files.length <= slots) {
      slots = files.length;
    }

    if (slots + imgCount.current >= props.limit) {
      setIsImageFull(true);
    } else {
      setIsImageFull(false);
    }

    for (let i = 0; i < slots; i++) {
      console.log("validateFile(files[i]) data : ", validateFile(files[i]));
      if (validateFile(files[i]) && files[i].size <= 1024 * 1024 * 3) {
        imgCount.current++;

        filesArray.push(files[i]);
        imgBoxs.current.pop();
      } else {
        // add a new property called invalid
        setIsImageFull(false);
        files[i]["invalid"] = true;
        if (files[i].size > 1024 * 1024 * 3) {
          errormsg += files[i].name + " ขนาดภาพไม่เกิน 3MB ";
          setErrorMessage(files[i].name + " ขนาดภาพไม่เกิน 3MB");
        } else {
          errormsg +=
            // files[i].name +
            "รองรับไฟล์ PNG, JPG ขนาดภาพไม่เกิน 3MB ";
          setErrorMessage(
            // files[i].name +
            "รองรับไฟล์ PNG, JPG ขนาดภาพไม่เกิน 3MB"
          );
        }
      }
      if (errormsg !== "") {
        Swal.fire({
          title: errormsg,
          // text: errormsg,
          icon: "error",
          confirmButtonText: "ยืนยัน",
        });
      }
    }
    console.log(errormsg);
    filesArray.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        //console.log(file)
        setImages((prevState) => [
          ...prevState,
          {
            id: imgindex++,
            src: e.target.result,
            file: file,
          },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
    console.log("filesArray : ", filesArray);
    return filesArray.length;
  };

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (validTypes.indexOf(file.type) === -1) {
      console.log("validTypes data : ", validTypes);
      return false;
    }

    return true;
  };

  useEffect(() => {
    props.onSelectedImages(images);
    return () => images.forEach((file) => URL.revokeObjectURL(file.src));
  }, [images, props.onSelectedImages]);

  return (
    <section className="upload fontReg font-size03">
      <span className="remark-dt fontReg font-size03 colorGray02">
        รองรับไฟล์ PNG, JPG ขนาดภาพไม่เกิน 3MB
      </span>
      <div className="row w-100">
        <div
          className={`col-12 col-md-7 ${isImageFull ? "d-none" : "d-flex"} `}
        >
          <div
            {...getRootProps({ className: `'dropzone' card-upload` })}
            //onDrop={fileDrop}
          >
            <input {...getInputProps()} />
            <p className="mb-0">ลากและวางไฟล์ที่นี่หรือ</p>
            <button type="button" className="btn-upload" onClick={openDialog}>
              เรียกดูไฟล์
            </button>
          </div>
        </div>
        <div className="col-12 col-md-5 mt-4 mt-md-0">
          <aside className="thumbsContainer">
            {thumbs}
            {imgBoxs.current.map((count, index) => {
              return (
                <div className="boxthumb" key={index}>
                  <span className="countimg">
                    {index + 1 + "/" + props.limit}
                    {/* {imgCount.current + "/" + props.limit} */}
                  </span>
                </div>
              );
            })}
          </aside>
        </div>
      </div>
    </section>
  );
}

export default UploadIMG;
