import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownSportType } from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import React, { useState, useEffect } from "react";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import moment from "moment";
import { getDataUser } from "../config/configApi-User";
import { getDashboard } from "../../api/dashboard_api";
import { getSport, getVoteSport } from "../../api/sport_api";
import Button3items from "../button/Button3items";
import Loading from "./Loading";
function Dashsportsvote() {
  const enableButton = {
    view: false,
    edit: false,
    delete: false,
    add: false,
    approve: false,
  };
  const [spanloading, setspanLoading] = useState(false);
  const handleExport = async (type) => {
    let dt = {
      id: { value: 1, type: "text" },
      score: { value: 95, type: "text" },
      sportName: { value: "กรีฑา", type: "text" },
    };
    let Header = ["ลำดับที่", "ชื่อกีฬา", "คะแนน"];
    let Data = dataCardArea.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.sportName.value;
      dt[2] = elemento.score.value;

      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานผลโหวตกีฬาที่สนใจ",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
      default:
    }
  };

  const columnsname = ["id", "sportName", "score"];
  const columnsText = ["ลำดับที่", "ชื่อกีฬา", "คะแนน"];
  const columnsType = ["text", "text", "text"];
  const [rowsdata, setRowsdata] = useState([]);
  // const rowsdata = [
  // {
  //   id: { value: "1", type: "text" },
  //   sportName: { value: "เทควันโด", type: "text" },
  //   score: { value: "5000", type: "text" },
  //   updateddate: { value: "7/5/2022", type: "text" },
  // },
  // ];

  function setinput(inputname, id) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: id,
      };
    });
  }

  const getVote = async () => {
    try {
      const query = {
        sportId: null,
      };
      setspanLoading(true);
      const response = await getVoteSport(query);
      await setspanLoading(false);
      if (response && response.status === 200) {
        const vote = response.data.data;

        const result = vote.map((item, index) => {
          const row = {
            id: { value: index + 1, type: "text" },
            sportName: { value: item.sportName, type: "text" },
            score: {
              value: item.voteAmount ? item.voteAmount : "-",
              type: "text",
            },
          };
          return row;
        });

        setDataCardArea(result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getVoteFilter = async () => {
    try {
      const query = {
        sportId: selectedSport || null,
      };
      setspanLoading(true);
      const response = await getVoteSport(query);
      await setspanLoading(false);
      if (response && response.status === 200) {
        const vote = response.data.data;

        const result = vote.map((item, index) => {
          const row = {
            id: { value: index + 1, type: "text" },
            sportName: { value: item.sportName, type: "text" },
            score: { value: item.voteAmount, type: "text" },
          };
          return row;
        });

        setDataCardArea(result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSportDropDown = async () => {
    try {
      const response = await getSport();
      if (response.status == 200) {
        const dropdown = response.data.data;

        const subType = dropdown.map((item) => {
          const sport = {
            value: item.sportID,
            text: item.sportName,
            subType: [],
          };
          return sport;
        });

        setDataDropSporttype(subType);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [DataDropSporttype, setDataDropSporttype] = useState([]);
  const [inputForm, setInputForm] = useState({
    inp1: undefined,
  });

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [dataCardArea, setDataCardArea] = useState([]);
  function resetDropdown() {
    setSelecteSport(null);
    setValuefromparent1("ทั้งหมด");
    getVote();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  useEffect(() => {
    // getDataDashboard();
    getSportDropDown();
    getVote();
  }, []);
  const getDataDashboard = async () => {
    setspanLoading(true);
    let getData = [];
    const respon = await getDashboard();

    if (respon.data.status === "success") {
      // setLoading(true);
      //  top_1: spObj.top_1,
      // top_2: spObj.top_2,
      // top_3: spObj.top_3,
      let spObj = respon.data.data.sportsvoteDash;
      getData.push(
        {
          id: { value: "1", type: "text" },
          sportName: { value: spObj.top_1, type: "text" },
          score: { value: "-", type: "text" },
          updateddate: { value: "-", type: "text" },
        },
        {
          id: { value: "2", type: "text" },
          sportName: { value: spObj.top_2, type: "text" },
          score: { value: "-", type: "text" },
          updateddate: { value: "-", type: "text" },
        },
        {
          id: { value: "3", type: "text" },
          sportName: { value: spObj.top_3, type: "text" },
          score: { value: "-", type: "text" },
          updateddate: { value: "-", type: "text" },
        }
      );

      // spObj.forEach((objectData, i) => {
      //   return getData.push({
      //     id: { value: objectData.ageID, type: "text" },
      //     sportName: { value: objectData.ageName, type: "text" },
      //     score: { value: objectData.ageAmount, type: "text" },
      //     updateddate: { value: "-", type: "text" },
      //   });
      // });
      await setspanLoading(false);
      setDataCardArea(getData);
    }

    // const data = [
    //   { title: "One", prix: 100 },
    //   { title: "Two", prix: 200 },
    //   { title: "Three", prix: 300 },
    // ];
  };

  let [selectedSport, setSelecteSport] = useState("");
  const handleSportChange = (value) => {
    setSelecteSport(value);
  };
  return (
    <>
      {spanloading === true ? <Loading /> : <></>}
      <Header parentmenu={"ผลโหวตกีฬาที่สนใจ"} />
      <Menu parentmenu={"Dashboard"} activemenu={"sportsvoteDash"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-home.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ผลโหวตกีฬาที่สนใจ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | Dashboard / ผลโหวตกีฬาที่สนใจ
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-sm-6 pr-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชนิดกีฬา
                  </p>
                  <Dropdown
                    options={DataDropSporttype}
                    setinput={setinput}
                    inputname={"inp1"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleSportChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-blue01" onClick={getVoteFilter}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการผลโหวตกีฬาที่สนใจ
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataCardArea}
              isSearchEnable={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashsportsvote;
