import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import TableLink from "../Tables/TableEditOnly";
import "../pages/Mastersporttype.css";
import DataMockLinkWebView from "../dataMockup/DataMockLinkWeb";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import { getDataWeblink, UpdateWeblink } from "../../api/weblink_api";
import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import Button3items from "../button/Button3items";
import { getDataUser } from "../config/configApi-User";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
function LinkWebView() {
  const [menuName, setMenuname] = useState(null);
  const [dataLinkview, setDataLinkview] = useState(null);

  const [dataAthleteUrl, setDataathleteUrl] = useState(null);
  const [dataBusbookingCoach, setDatabusbookingCoach] = useState(null);
  const [dataBusbookingStaff, setDatabusbookingStaff] = useState(null);
  const [dataMatchresultUrl, setDatamatchresultUrl] = useState(null);
  const [dataMatchscheduleUrl, setDatamatchscheduleUrl] = useState(null);
  const [dataMedalUrl, setDatamedalUrl] = useState(null);
  const [dataMedalmatchUrl, setDatamedalmatchUrl] = useState(null);
  const [Link, setLink] = useState(null);
  // isLoading
  const [isLoading, setLoading] = useState(null);
  const navigate = useNavigate();
  const enableButton = { view: false, edit: true, delete: false };
  const columnsname = ["id", "menu", "Link", "action"];
  const columnsText = ["ลำดับที่", "เมนู", "Link", "เครื่องมือ"];
  const columnsType = ["text", "text", "text", "action"];
  const rowsData = [
    {
      id: { value: "1", type: "text" },
      menu: { value: "จองรถนักกีฬา", type: "text" },
      Link: { value: "www.จองนักกีฬา.com", type: "text" },
      action: { value: "1", type: "action" },
    },
  ];
  useEffect(() => {
    getDataLink();
  }, []);
  const getDataLink = async () => {
    let rowsdata = [];
    try {
      const respon = await getDataWeblink();
      let rs = respon.data.data.weblink;
      rowsdata.push({
        athleteUrl: rs.athleteUrl === null ? "-" : rs.athleteUrl,
        busbookingCoach: rs.busbookingCoach === null ? "-" : rs.busbookingCoach,
        busbookingStaff: rs.busbookingStaff === null ? "-" : rs.busbookingStaff,
        matchresultUrl: rs.matchresultUrl === null ? "-" : rs.matchresultUrl,
        matchscheduleUrl:
          rs.matchscheduleUrl === null ? "-" : rs.matchscheduleUrl,
        medalUrl: rs.medalUrl === null ? "-" : rs.medalUrl,
        medalmatchUrl: rs.medalmatchUrl === null ? "-" : rs.medalmatchUrl,
      });
      setDataLinkview([rowsdata[0].athleteUrl]);
      setDataathleteUrl(rowsdata[0].athleteUrl);
      setDatabusbookingCoach(rowsdata[0].busbookingCoach);
      setDatabusbookingStaff(rowsdata[0].busbookingStaff);
      setDatamatchresultUrl(rowsdata[0].matchresultUrl);
      setDatamatchscheduleUrl(rowsdata[0].matchscheduleUrl);
      setDatamedalUrl(rowsdata[0].medalUrl);
      setDatamedalmatchUrl(rowsdata[0].medalmatchUrl);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditathlete`);
        break;
      case "edit":
        // handle edit button
        // navigate(`/Addeditathlete/?id=${id}`)
        break;
      case "view":
        // handle view button
        // viewModal(id)
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        // deleteByID(id)
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const handleExport = async (type) => {
    let Header = [
      "สถิตินักกีฬา",
      "จองรถรับ - ส่ง โค้ช",
      "จองรถรับ - ส่ง กรรมการ",
      "ผลการแข่งขัน",
      "ตารางแข่งขัน",
      "สรุปเหรียญ",
      "ข้อมูลชิงเหรียญ",
    ];

    let dataArr = [];
    dataArr.push(
      dataAthleteUrl,
      dataBusbookingCoach,
      dataBusbookingStaff,
      dataMedalUrl,
      dataMatchscheduleUrl,
      dataMedalUrl,
      dataMedalmatchUrl
    );
    const dt = Object.values(1);
    dt[0] = dataArr;
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");

    const respon = await getDataUser();
    let dataSend = {
      body: dt,
      data: "รายงาน Link Web view",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };
  const postUpdateLinkweb = async () => {
    setLoading(true);

    // if (
    //   dataAthleteUrl === null ||
    //   dataAthleteUrl === "" ||
    //   dataBusbookingCoach === null ||
    //   dataBusbookingCoach === "" ||
    //   dataBusbookingStaff === null ||
    //   dataBusbookingStaff === "" ||
    //   dataMatchresultUrl === null ||
    //   dataMatchresultUrl === "" ||
    //   dataMatchscheduleUrl === null ||
    //   dataMatchscheduleUrl === "" ||
    //   dataMedalUrl === null ||
    //   dataMedalUrl === "" ||
    //   dataMedalmatchUrl === null ||
    //   dataMedalmatchUrl === ""
    // ) {
    //   setLoading(2);
    // } else {
    let body = {
      busbookingCoach: dataBusbookingCoach,
      busbookingStaff: dataBusbookingStaff,
      matchscheduleUrl: dataMatchscheduleUrl,
      matchresultUrl: dataMatchresultUrl,
      medalmatchUrl: dataMedalmatchUrl,
      medalUrl: dataMedalUrl,
      athleteUrl: dataAthleteUrl,
    };

    Swal.fire({
      title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          const respon = await UpdateWeblink(body);
          // setLoading(1);
          if (respon.data.status === "success") {
            Swal.fire({
              title: "บันทึกข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: "ยืนยัน",
            }).then((result) => {
              if (result.isConfirmed) {
                getDataLink();
                setLoading(1);
              }
            });
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "ยืนยัน",
              cancelButtonText: `ยกเลิก`,
              reverseButtons: true,
            }).then((result) => {
              getDataLink();
              setLoading(1);
            });
          }
        })();
      }
    });
    // }
  };
  return (
    <>
      {/* {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()} */}

      {/* <ToastSuccess />
      <ToastWarning />
      <ToastError /> */}
      <Header parentmenu={"จัดการ Link Web View"} />

      <Menu parentmenu={"manageTheme"} activemenu={"LinkWeb"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-link.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                Link Web view
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | จัดการ Link Web view
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              {/* <Button3items
                onButtonClicked={handleButtonClicked}
                onExportClicked={handleExport}
              /> */}
              {/* <Buttonexport onExportClicked={handleExport} /> */}
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              {/* <Button3 navigateToAddEdit={navigateToAddEdit} pageAddEditName={'/Addeditsporttype'}/> */}
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>Link Web View
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12  mt-2">
                <p className="font-weight-bold ">สถิตินักกีฬา</p>
                <input
                  className="form-control"
                  value={dataAthleteUrl}
                  onChange={(e) => {
                    setDataathleteUrl(e.target.value);
                  }}
                />
              </div>
              <div className="col-12  mt-2">
                <p className="font-weight-bold ">จองรถรับ - ส่ง โค้ช</p>
                <input
                  className="form-control"
                  value={dataBusbookingCoach}
                  onChange={(e) => {
                    setDatabusbookingCoach(e.target.value);
                  }}
                />
              </div>
              <div className="col-12  mt-2">
                <p className="font-weight-bold ">จองรถรับ - ส่ง กรรมการ</p>
                <input
                  className="form-control"
                  value={dataBusbookingStaff}
                  onChange={(e) => {
                    setDatabusbookingStaff(e.target.value);
                  }}
                />
              </div>
              <div className="col-12  mt-2">
                <p className="font-weight-bold ">ผลการแข่งขัน</p>
                <input
                  className="form-control"
                  value={dataMatchresultUrl}
                  onChange={(e) => {
                    setDatamatchresultUrl(e.target.value);
                  }}
                />
              </div>
              <div className="col-12  mt-2">
                <p className="font-weight-bold ">ตารางแข่งขัน</p>
                <input
                  className="form-control"
                  value={dataMatchscheduleUrl}
                  onChange={(e) => {
                    setDatamatchscheduleUrl(e.target.value);
                  }}
                />
              </div>
              <div className="col-12  mt-2">
                <p className="font-weight-bold ">สรุปเหรียญ</p>
                <input
                  className="form-control"
                  value={dataMedalUrl}
                  onChange={(e) => {
                    setDatamedalUrl(e.target.value);
                  }}
                />
              </div>
              <div className="col-12  mt-2">
                <p className="font-weight-bold ">รายการชิงเหรียญ</p>
                <input
                  className="form-control"
                  value={dataMedalmatchUrl}
                  onChange={(e) => {
                    setDatamedalmatchUrl(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {/* Modal */}

          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-save"
                onClick={() => {
                  postUpdateLinkweb();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LinkWebView;
