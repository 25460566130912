import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
import UploadIMG from '../uploads/UploadIMG'
import { Link, useNavigate } from 'react-router-dom';
import UploadFileXLS from '../uploads/UploadFileXLS';
import Swal from 'sweetalert2';

function AddShop() {

    const [shopName, setShopName] = useState(null)
    const [shopCall, setShopCall] = useState(null)
    const [shopTime, setShopTime] = useState(null)
    const [shopAddress, setShopAddress] = useState(null)
    const [shopDetails, setShopDetails] = useState(null)
    const [latitude, setLatitude] = useState(null)
    const [longtitude, setLongitude] = useState(null)
    const [shopImg, setShopImg] = useState(null)
    const [files, setFiles] = useState(null)

    const [groupShow,setGroupShow] = useState(false)
    const navigate = useNavigate();
    function navigateToBack(page){
      navigate(page);
    }

    function cancelData(){      
      Swal.fire({
        title: 'คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true
      })
      .then((result) => {      
        if (result.isConfirmed) {
            navigateToBack('/recommendStore')
        } 
      });      
    }

    const handleSelectedImages = (images) => {
      // console.log(images); // do something with the selected images
    }

    return(
        <>
          <Header parentmenu={'จัดการข้อมูลร้านค้า'}/>
    <Menu parentmenu={'advertise'} activemenu={'recommendStore'}/>
    <div className="content-wrapper">
    <div className="container-fluid p-2 p-md-3">
      <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-pr.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประชาสัมพันธ์
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                |  ประชาสัมพันธ์ / จัดการข้อมูลร้านค้า เพิ่มข้อมูล 
              </font>
            </div>
            <div className={`col-4 col-sm-3 justify-content-end align-items-center ${!groupShow ? "d-flex" : "d-none"} `}>
                <button
                  className="btn-blue03 mr-2 mr-xl-3 w-100"
                  onClick={() => setGroupShow(true)}
                >
                  <img
                    src="assets/icons/ic-add.png"
                    alt="ExportFiles Icon"
                    className="ic-add"
                  />
                  <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                    เพิ่มข้อมูลหลายรายการ
                  </span>
                </button>
              </div>
              <div className={`col-4 col-sm-3 justify-content-end align-items-center ${groupShow ? "d-flex" : "d-none"} `}>
                <button
                  className="btn-blue03 mr-2 mr-xl-3 w-100"
                  onClick={() => setGroupShow(false)}
                >
                  <img
                    src="assets/icons/ic-add.png"
                    alt="ExportFiles Icon"
                    className="ic-add"
                  />
                  <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                    เพิ่มข้อมูลรายการเดียว
                  </span>
                </button>
              </div>
          </div>
          
          <Col className={`card-white ${!groupShow ? "" : "d-none"} `}>
          <Row className='mx-1'>
              <Col xs={12} md={12} lg={12}> 
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> เพิ่มข้อมูลทีละรายการ</span>
              </p>
              </Col>
            </Row>
            <Row className='mt-3'>
                <Col lg={2} md={2} xs={4}className='text-header text-end'>ชื่อร้านค้า</Col>
                <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setShopName(e.target.value)}}/></Col>
          </Row>
          <Row className='mt-4'>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>เบอร์โทรศัพท์</Col>
            <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setShopCall(e.target.value)}} /></Col>
        </Row>
        <Row className='mt-3'>
                <Col lg={2} md={2} xs={4}className='text-header text-end'>เวลาทำการ</Col>
                <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setShopTime(e.target.value)}}/></Col>
          </Row>
        <Row className='mt-4'>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>ที่อยู่ร้านค้า</Col>
            <Col lg={10} md={10} xs={8}><textarea className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setShopAddress(e.target.value)}} /></Col>
        </Row>
        <Row className='mt-4'>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>รายละเอียดร้านค้า</Col>
            <Col lg={10} md={10} xs={8}><textarea className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setShopDetails(e.target.value)}} /></Col>
        </Row>
        <Row className='mt-4' >
            <Col lg={2} md={2} xs={2}className='text-header text-end'>ละติจูต</Col>
            <Col lg={4} md={4} xs={4}><input onChange={(e) => {setLatitude(e.target.value)}} className='inp-data01 fontReg font-size03'/></Col>
            <Col lg={2} md={2} xs={2}className='text-header text-end'>ลองติจูต</Col>
            <Col lg={4} md={4} xs={4}><input onChange={(e) => {setLongitude(e.target.value)}} className='inp-data01 fontReg font-size03'/></Col>
        </Row>
        <Row className='mx-3 mt-5'>
              <Col xs={4} md={2} lg={2} className='text-input text-end'>รูปภาพร้านค้า</Col>
              <Col xs={8} md={10} lg={8} >
              <UploadIMG limit={1} onSelectedImages={handleSelectedImages}/>
              </Col>
            </Row>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
                <div className="col-3 col-sm-2 col-xl-1">
                  <button className="btn-cancel" onClick={() => {cancelData()}}>ยกเลิก</button>
                </div>
                <div className="col-3 col-sm-2 col-xl-1">
                  <button className="btn-save">บันทึก</button>
                </div>
            </div>
          </Col>
          
          <Col className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
          <Row className='mx-1'>
              <Col xs={12} md={12} lg={12}> 
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> เพิ่มข้อมูลทีละหลายรายการ</span>
              </p>
              </Col>
            </Row>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link as={Link} to="/files/Template-Form-Affiliation.xlsx" target="_blank" className="btn-blue01 fontReg font-size03 colorWhite p-3 d-inline-block h-auto" id="downloadBtn" value="download">
                  <span className="nav-icon icon icon-ic-import mr-2"></span>Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-5">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <UploadFileXLS limit={1} />
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
                <div className="col-3 col-sm-2 col-xl-1">
                  <button className="btn-cancel" onClick={() => {cancelData()}}>ยกเลิก</button>
                </div>
                <div className="col-3 col-sm-2 col-xl-1">
                  <button className="btn-save">บันทึก</button>
                </div>
            </div>
          </Col>
          </div>
          </div>
    <Footer/>
        </>
    )
}

export default AddShop