import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import moment from "moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { getShopById, postShopApprove } from "../../api/shop_api";
function Addapproveshop() {
  const [inputForm, setInputForm] = useState({
    inp1: "นาง", //คำนำหน้า
    inp2: "น้ำใส", //ชื่อ
    inp3: "ใจเย็น", //นามสกุล
    inp4: "0678954543", //เบอร์โทร
    inp5: "email@mail.com", //อีเมล
    inp6: "App", //สมัครด้วย
    inp7: "ร้านน้องน้ำ", //ชื่อร้าน
    inp8: "ร้านอาหาร", //ประเภทร้าน
    inp9: "email@mail.com", //อีเมลร้าน
    inp10: "11:30 AM - 18:00 PM", //เวลาทำการ
    inp11: "https://nongnum.co.th", //web
    inp12: "ที่อยู่ร้าน", //ที่อยู่ร้าน
    inp13: "latitude", //latitude
    inp14: "longitude", //longitude
    inp15: null, //radio อนุมัติ
    inp16: null, //radio ไม่อนุมัติ
    inp17: "", //comment ไม่อนุมัติ
    inp18: "https://nongnum.co.th", //web
    inp19: "https://nongnum.co.th", //web
    inp20: "https://nongnum.co.th", //web
    inp21: "https://nongnum.co.th", //web
    inp22: "https://nongnum.co.th", //web
  });
  const [dataArr, setDataArr] = useState([]);
  let id = new URLSearchParams(window.location.search).get("id");
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  let selectDt = false;
  const [isLoading, setLoading] = useState(null);
  const [commentShow, setCommentShow] = useState(false);
  const [commentShowNo, setCommentShowNo] = useState(false);
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }
  console.log("commentShow preview : ", commentShow);
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/approveAcc");
      }
    });
  }
  useEffect(() => {
    getDatashopID();
  }, []);
  const getDatashopID = async () => {
    const res = await getShopById(id);
    let getData = [];
    getData.push({
      title:
        res.data.data.member.title === null ? "-" : res.data.data.member.title,
      firstName:
        res.data.data.firstName === null ? "-" : res.data.data.firstName,
      lastName: res.data.data.lastName === null ? "-" : res.data.data.lastName,
      tel: "-",
      email: res.data.data.member.email,
      social: res.data.data.member.registerType.registerTypeName,
      closeTime:
        res.data.data.closeTime === null ? "-" : res.data.data.closeTime,
      shopName: res.data.data.shopName === null ? "-" : res.data.data.shopName,
      shopType: res.data.data.shopType === null ? "-" : res.data.data.shopType,
      shopID: res.data.data.shopID,
      openTime: res.data.data.openTime === null ? "-" : res.data.data.openTime,
      openDays: res.data.data.openDays === null ? "-" : res.data.data.openDays,
      web: res.data.data.web === null ? "-" : res.data.data.web,
      ig: res.data.data.web === null ? "-" : res.data.data.ig,
      fb: res.data.data.web === null ? "-" : res.data.data.facebook,
      img: res.data.data.img === null ? "-" : res.data.data.img,
      line: res.data.data.img === null ? "-" : res.data.data.line,
      address: res.data.data.address === null ? "-" : res.data.data.address,
      approveStatus:
        res.data.data.approveStatus === null
          ? "-"
          : res.data.data.approveStatus,
      latitude: res.data.data.latitude === null ? "-" : res.data.data.latitude,
      longtitude:
        res.data.data.longtitude === null ? "-" : res.data.data.longtitude,
      mobileNo: res.data.data.mobileNo === null ? "-" : res.data.data.mobileNo,
      summaryDescription:
        res.data.data.summaryDescription === null
          ? "-"
          : res.data.data.summaryDescription,
      fullDescription:
        res.data.data.fullDescription === null
          ? "-"
          : res.data.data.fullDescription,
    });
    selectDt = res.data.data.approveStatus;
    setCommentShow(selectDt);
    // if (getData[0].approveStatus === true) {
    //   setCommentShow(selectDt);
    // } else {
    //   setCommentShowNo(getData[0].approveStatus);
    // }
    setDataArr(getData[0]);
  };
  const postApproveShop = async () => {
    console.log("inputForm.inp17 : ", inputForm.inp17);
    if (
      inputForm.inp17 === null ||
      (inputForm.inp17 === "" && commentShow === false)
    ) {
      // if (commentShow === false || dataArr.approveStatus === false) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
      // }
    } else {
      let body = {
        // approveStatus: null,
        approveStatus:
          commentShow === null ? dataArr.approveStatus : commentShow,
        remark: commentShow === true ? "" : inputForm.inp17,
      };
      // console.log("body pre : ", body);
      // if (inputForm.inp15 === true) {
      //   body.approveStatus = inputForm.inp15;
      // } else {
      //   body.approveStatus = inputForm.inp16;
      // }
      // let body = {
      //   approveStatus: commentShow === null ? "-" : commentShow,
      //   remark: inputForm.inp17 === null ? "" : inputForm.inp17,
      // };
      await Swal.fire({
        icon: "warning",
        title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่ ",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            // const respon = null;
            const respon = await postShopApprove(dataArr.shopID, body);
            if (respon.data.status === "success") {
              setLoading(1);
              navigateToBack("/approveAcc");
            } else {
              Swal.fire({
                title: "เกิดข้อผิดพลาด",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: `ยกเลิก`,
                reverseButtons: true,
              }).then((result) => {});
            }
          })();
        }
      });
    }
  };
  return (
    <>
      <Header parentmenu={"Account ที่รออนุมัติ"} />
      <Menu parentmenu={"shopData"} activemenu={"approveAcc"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-12 mt-2">
              <img
                src="assets/icons/im-shop.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                Account ที่รออนุมัติ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลร้านค้า ร้านอาหาร / Account ที่รออนุมัติ
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูลบุคคล
                </p>
              </div>
            </div>
            {/* address: "-", approveStatus: false, email: "-", firstName: "kkkk",
            img: "-", lastName: "kkkk", latitude: "asdasd" longtitude: "asdasd"
            openTime: "-" shopName: "ShopTest" shopType: "-" social: "-" tel:
            "-" title: "-",mobileNo */}
            <div className="row">
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-0 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  คำนำหน้าชื่อ
                </div>
                <input
                  id="inputName"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.title}
                  // onChange={(e) => setinput("inp2", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-0 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  ชื่อ
                </div>
                <input
                  id="inputName"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.firstName}
                  onChange={(e) => setinput("inp2", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-0 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  นามสกุล
                </div>
                <input
                  id="inputLastname"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.lastName}
                  onChange={(e) => setinput("inp3", e.target.value)}
                  disabled
                />
              </div>
              {/* <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-0 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  เบอร์โทรศัพท์
                </div>
                <input
                  id="inputTel"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.mobileNo}
                  onChange={(e) => setinput("inp4", e.target.value)}
                  disabled
                />
              </div> */}
            </div>
            <div className="row">
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  เบอร์โทรศัพท์
                </div>
                <input
                  id="inputTel"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.mobileNo}
                  onChange={(e) => setinput("inp4", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  อีเมล
                </div>
                <input
                  id="inputEmail"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.email ? dataArr.email : "-"}
                  onChange={(e) => setinput("inp5", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  สมัครโดย
                </div>
                <input
                  id="inputEmail"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.social ? dataArr.social : "-"}
                  // onChange={(e) => setinput("inp5", e.target.value)}
                  disabled
                />
              </div>
              {/* <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  สถานะ
                </div>
                <input
                  id="inputRegister"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.social}
                  onChange={(e) => setinput("inp6", e.target.value)}
                  disabled
                />
              </div> */}
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูลร้านค้า
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ml-2 mb-3">
                <img
                  src={dataArr.img}
                  alt="Type Sport"
                  className={dataArr.img === "-" ? "d-none" : "im-profile"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 pr-2 pr-md-0 mt-sm-0 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  ชื่อร้าน
                </div>
                <input
                  id="inputStoreName"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.shopName ? dataArr.shopName : "-"}
                  onChange={(e) => setinput("inp7", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 pr-2 pr-md-0 mt-sm-0 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  ประเภทร้าน
                </div>
                <input
                  id="inputStoreType"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.shopType ? dataArr.shopType : "-"}
                  onChange={(e) => setinput("inp8", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 pr-2 pr-md-0 mt-sm-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  ช่องทางออนไลน์
                </div>
                <input
                  id="inputEmailStore"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.email ? dataArr.email : "-"}
                  onChange={(e) => setinput("inp9", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-2 pr-2 pr-md-0 mt-sm-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  วันเปิดทำการ
                </div>
                <input
                  id="inputTime"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.openDays ? dataArr.openDays : "-"}
                  onChange={(e) => setinput("inp10", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-2 pr-2 pr-md-0 mt-sm-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  เวลาเปิดทำการ
                </div>
                <input
                  id="inputTime"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.openTime ? dataArr.openTime : "-"}
                  // onChange={(e) => setinput("inp10", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-2 pr-2 pr-md-0 mt-sm-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  เวลาปิดทำการ
                </div>
                <input
                  id="inputTime"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.closeTime ? dataArr.closeTime : "-"}
                  // onChange={(e) => setinput("inp10", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  Website
                </div>
                <input
                  id="inputWeb"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.web ? dataArr.web : "-"}
                  onChange={(e) => setinput("inp11", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-6 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  Twitter
                </div>
                <input
                  id="inputWeb"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.tw ? dataArr.tw : "-"}
                  onChange={(e) => setinput("inp18", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  Facebook
                </div>
                <input
                  id="inputWeb"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.fb ? dataArr.fb : "-"}
                  onChange={(e) => setinput("inp19", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  Instagram
                </div>
                <input
                  id="inputWeb"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.ig ? dataArr.ig : "-"}
                  onChange={(e) => setinput("inp20", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  Line
                </div>
                <input
                  id="inputWeb"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.line ? dataArr.line : "-"}
                  onChange={(e) => setinput("inp21", e.target.value)}
                  disabled
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  Facebook
                </div>
                <input
                  id="inputFacebook"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.facebook}
                  // onChange={(e) => setinput("inp2", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  Instagram
                </div>
                <input
                  id="inputInstagram"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.ig}
                  // onChange={(e) => setinput("inp3", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-md-4 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  Line
                </div>
                <input
                  id="inputLine"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.line}
                  // onChange={(e) => setinput("inp4", e.target.value)}
                  disabled
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-12 pr-2 pr-md-0 mt-md-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  ที่อยู่ร้าน
                </div>
                <input
                  id="inputAddress"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.address ? dataArr.address : "-"}
                  onChange={(e) => setinput("inp12", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 pr-2 pr-md-0 mt-sm-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  ละติจูด
                </div>
                <input
                  id="inputlat"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.latitude ? dataArr.latitude : "-"}
                  onChange={(e) => setinput("inp13", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 pr-2 pr-md-0 mt-sm-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  ลองจิจูด
                </div>
                <input
                  id="inputlong"
                  className="inp-data01 fontReg font-size03"
                  value={dataArr.longtitude ? dataArr.longtitude : "-"}
                  onChange={(e) => setinput("inp14", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 pr-2 pr-md-0 mt-sm-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  อธิบายร้านค้า (แบบย่อ)
                </div>
                <input
                  id="inputSummaryDescription"
                  className="inp-data01 fontReg font-size03"
                  value={
                    dataArr.summaryDescription
                      ? dataArr.summaryDescription
                      : "-"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 pr-2 pr-md-0 mt-sm-3 mt-2">
                <div className="fontReg font-size03 colorGray01 d-md-inline-block">
                  อธิบายร้านค้า (แบบเต็ม)
                </div>
                <input
                  // id="inputFullDescription"
                  className="inp-data01 fontReg font-size03"
                  value={
                    dataArr.fullDescription ? dataArr.fullDescription : "-"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="fontReg font-size03 colorGray01 mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radios"
                  id="radios1"
                  defaultValue="option1"
                  defaultChecked={commentShow === true}
                  onChange={(e) => {
                    setinput("inp15", true);
                    setCommentShow(true);
                  }}
                />
                <label className="form-check-label" htmlFor="radios1">
                  อนุมัติ
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radios"
                  id="radios2"
                  defaultValue="option2"
                  defaultChecked={commentShow === false}
                  onChange={(e) => {
                    setinput("inp15", false);
                    setCommentShow(false);
                  }}
                />
                <label className="form-check-label" htmlFor="radios2">
                  ไม่อนุมัติ
                </label>
              </div>

              <div className="col-12 col-sm-6 p-0 mt-sm-3 mt-2">
                <textarea
                  row="3"
                  // id="inputReject"
                  className={`inp-area fontReg font-size03  ${
                    commentShow === true || dataArr.approveStatus === true
                      ? "d-none"
                      : ""
                    // inputForm.inp15 === false ||
                    // dataArr.approveStatus === false ||
                    // commentShow === false
                    //   ? ""
                    //   : "d-none"
                  } `}
                  placeholder="กรุณาใส่เหตุผลในการไม่อนุมัติ"
                  value={inputForm.inp17}
                  onChange={(e) => setinput("inp17", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-save"
                onClick={(event) => postApproveShop()}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addapproveshop;
