import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Button3items from "../button/Button3items";
import Dropdown from "../dropdowns/Dropdown";
import { getCampaign } from "../../api/campaign_api";
import {
  DataDropdownStoreType,
  DataDropdownCampaign,
} from "../dataMockup/DataMockDropdown";
import Calendarpicker from "../calendars/Calendarpicker";
import TableData from "../Tables/TableData";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import { useNavigate } from "react-router-dom";
import {
  getPromotion,
  getPromotionById,
  deletePromotion,
  getPromotionFilter,
} from "../../api/promotion_api";
import Loading from "./Loading";
function Shoppromotion() {
  const DataDropdownShopBy = [
    { value: "", text: "ทั้งหมด" },
    { value: "1", text: "ร้านค้า" },
    { value: "2", text: "ร้านอาหาร" },
    { value: "3", text: "ร้านค้า/ร้านอาหาร" },
  ];
  const [inputDate, setInputDate] = useState("");
  const [dataCampaign, setDataCampaign] = useState([]);
  const [spanloading, setspanLoading] = useState(false);
  const [inputForm, setInputForm] = useState({
    shopName: undefined, //ชื่อร้าน
    shopType: undefined, //ประเภทร้านค้า
    campaignName: undefined, //แคมเปญ
    inp4: "", //Calendarpicker
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const handleExport = async (type) => {
    let Header = [
      "No",
      "ชื่อร้าน",
      "ประเภทร้านค้า",
      "promotionID",
      "ชื่อโปรโมชัน",
      "เเคมเปญ",
      "จํานวนสิทธิ์",
      // "สถานะ",
      "อัปเดตล่าสุด",
    ];

    let Data = dataPromotion.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.storeName.value;
      dt[2] = elemento.storeType.value;
      dt[3] = elemento.promotionID.value;
      dt[4] = elemento.promotionName.value;
      dt[5] = elemento.campaignName.value;
      dt[6] = elemento.amount.value;
      // dt[7] = elemento.status.value;
      dt[7] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานรายชื่อโปรโมชัน",
      // "รายงานโปรโมชัน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };
  const navigate = useNavigate();

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/Addeditcoach`)
        break;
      case "edit":
        // handle edit button
        // navigate(`/Addeditcoach?id=${id}`)
        break;
      case "view":
        // handle view button
        getPromotionID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        deletePromotionByID(id);
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const enableButton = {
    view: true,
    edit: false,
    delete: true,
    add: false,
    approve: false,
  };

  const columnsname = [
    "id",
    "storeName",
    "storeType",
    "promotionID",
    "promotionName",
    "campaignName",
    "amount",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อร้าน",
    "ประเภทร้านค้า",
    "promotionID",
    "ชื่อโปรโมชัน",
    "แคมเปญ",
    "จำนวนสิทธิ์",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];
  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   storeName: { value: "ร้านน้องน้ำ", type: "text" },
    //   storeType: { value: "ร้านอาหาร", type: "text" },
    //   promotionID: { value: "PO001", type: "text" },
    //   promotionName: { value: "โปรโมชัน01", type: "text" },
    //   campaignName: { value: "แคมเปญ01", type: "text" },
    //   amount: { value: "0/10", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];
  const [dataPromotion, setDataPromotion] = useState([]);
  const [getDataPromotionID, setDataPromotionID] = useState([]);
  // setDataPromotionID;
  useEffect(() => {
    getDataPromotion();
    getCampaginDropDown();
  }, []);

  const getDataFilter = async () => {
    setspanLoading(true);
    if (
      inputForm.shopName !== "" ||
      selectedShop !== "" ||
      selectedCampagin !== ""
    ) {
      let bodyFilter = {
        promotionStatus: "approve",
        inp1: inputForm.shopName,
        inp2: selectedShop,
        inp3: selectedCampagin,
        dateTime: inputForm.inp4
          ? moment(new Date(inputForm.inp4)).format("YYYY-MM-DD")
          : "",
      };
      console.log("bodyFilter : ", bodyFilter);
      const response = await getPromotionFilter(bodyFilter);

      response.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          // -----
          id: { value: i + 1, type: "text", dataID: objectData.promotionID },
          storeName: {
            value:
              objectData.joinCampaign.shop.shopName === null
                ? "-"
                : objectData.joinCampaign.shop.shopName,
            type: "text",
          },
          storeType: {
            value:
              objectData.joinCampaign.shop.shopType === 0
                ? "ทั้งหมด"
                : objectData.joinCampaign.shop.shopType === 1
                ? "ร้านค้า"
                : objectData.joinCampaign.shop.shopType === 2
                ? "ร้านอาหาร"
                : "ร้านค้า ร้านอาหาร",
            type: "text",
          },
          promotionID: {
            value:
              objectData.promotionID === null ? "-" : objectData.promotionID,
            type: "text",
          },
          promotionName: {
            value:
              objectData.promotionDetail === null ||
              objectData.promotionDetail === ""
                ? ""
                : objectData.promotionDetail,
            type: "text",
          },
          campaignName: {
            value:
              objectData.joinCampaign.campaign.campaignName === null
                ? "-"
                : objectData.joinCampaign.campaign.campaignName,
            type: "text",
          },
          amount: {
            value:
              objectData.isLimit === false
                ? "ไม่จำกัด"
                : objectData.promotionAmount,
            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: { value: "1", type: "action" },
          // ------
        });
      });
      await setspanLoading(false);
      setDataPromotion(rowsdata);
    } else {
      getDataPromotion();
    }
  };
  function ChangesetValueDate(value) {
    setInputDate(value);
  }
  const getDataPromotion = async () => {
    setspanLoading(true);
    try {
      let data = { promotionStatus: "approve" };
      const respon = await getPromotion(data);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          // -----
          id: { value: i + 1, type: "text", dataID: objectData.promotionID },
          storeName: {
            value:
              objectData.joinCampaign.shop.shopName === null
                ? "-"
                : objectData.joinCampaign.shop.shopName,
            type: "text",
          },
          storeType: {
            value:
              // objectData.joinCampaign.shop.shopType === 0
              //   ? "ทั้งหมด"
              objectData.joinCampaign.shop.shopType === 1
                ? "ร้านค้า"
                : objectData.joinCampaign.shop.shopType === 2
                ? "ร้านอาหาร"
                : objectData.joinCampaign.shop.shopType === 3
                ? "ร้านค้าและร้านอาหาร"
                : "ทั้งหมด",
            type: "text",
          },
          promotionID: {
            value:
              objectData.promotionID === null ? "-" : objectData.promotionID,
            type: "text",
          },
          promotionName: {
            value:
              objectData.promotionDetail === null ||
              objectData.promotionDetail === ""
                ? "-"
                : objectData.promotionDetail,
            type: "text",
          },
          campaignName: {
            value:
              objectData.joinCampaign.campaign.campaignName === null
                ? "-"
                : objectData.joinCampaign.campaign.campaignName,
            type: "text",
          },
          amount: {
            value:
              objectData.isLimit === false
                ? "ไม่จำกัด"
                : objectData.promotionAmount,
            type: "text",
          },
          // createdAt
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: { value: "1", type: "action" },
          // ------
        });
      });
      await setspanLoading(false);
      setDataPromotion(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getPromotionID = async (Id) => {
    let getData = [];
    let objectData = null;
    const respon = await getPromotionById(Id);
    objectData = respon.data.data;
    getData.push({
      id: objectData.promotionID,
      storeName:
        objectData.joinCampaign.shop.shopName === null
          ? "-"
          : objectData.joinCampaign.shop.shopName,
      // objectData.promotionName === null
      //   ? "-"
      //   : objectData.promotionName,

      storeType: objectData.type === null ? "-" : objectData.type,

      promotionID:
        objectData.promotionID === null ? "-" : objectData.promotionID,

      promotionName:
        objectData.promotionDetail === null || objectData.promotionDetail === ""
          ? "-"
          : objectData.promotionDetail,

      campaignName: objectData.joinCampaign.campaign.campaignName,
      // objectData.promotionName === null
      //   ? "-"
      //   : objectData.promotionName,
      detailCompaign:
        objectData.joinCampaign.campaign.campaignDetail === null
          ? "-"
          : objectData.joinCampaign.campaign.campaignDetail,
      joinCampaign:
        objectData.joinCampaign.length > 0 ? objectData.joinCampaign : "-",
      amount:
        objectData.isLimit === false ? "ไม่จำกัด" : objectData.promotionAmount,

      updateddate:
        objectData.updatedAt === null
          ? "-"
          : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
    });

    setDataPromotionID(getData[0]);
  };
  const deletePromotionByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deletePromotion(id);

            if (response.data.status === "success") {
              getDataPromotion();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const [name, setName] = useState("");
  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [valuefromparent3, setValuefromparent3] = useState("ทั้งหมด");
  function resetDropdown() {
    setName("");
    setInputDate("");
    inputForm.shopName = undefined;
    setSelecteCampagin("");
    setSelecteShop("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setValuefromparent3("ทั้งหมด");
    getDataPromotion();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValuefromparent3(text) {
    setValuefromparent3(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("shopName", e.target.value);
      setName(e.target.value);
    }
  };

  const getCampaginDropDown = async () => {
    try {
      const response = await getCampaign();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.campaignID,
            text: item.campaignName,
            subType: [],
          });
        });

        setDataCampaign(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedShop, setSelecteShop] = useState("");
  const handleShopChange = (value) => {
    setSelecteShop(value);
  };

  const [selectedCampagin, setSelecteCampagin] = useState("");
  const handleCampaginChange = (value) => {
    setSelecteCampagin(value);
  };

  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"รายชื่อโปรโมชัน"} />
      <Menu parentmenu={"shopData"} activemenu={"promotion"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-shop.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                รายชื่อโปรโมชัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลร้านค้า ร้านอาหาร / รายชื่อโปรโมชัน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อร้าน"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-12 col-lg-4 pr-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ประเภทร้านค้า
                  </p>
                  <Dropdown
                    options={DataDropdownShopBy}
                    setinput={setinput}
                    inputname={"shopType"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleShopChange}
                  />
                </div>
              </div>

              <div className="col-12 col-lg-4 pr-0 mt-lg-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">แคมเปญ</p>
                  <Dropdown
                    options={dataCampaign}
                    setinput={setinput}
                    inputname={"campaignName"}
                    ChangesetValuefromparent={ChangesetValuefromparent3}
                    valuefromparent={valuefromparent3}
                    onChange={handleCampaginChange}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start mt-2">
              <div className="col-12 col-lg-4 pr-0">
                <Calendarpicker
                  dataDate={null}
                  setDatePicker={setinput}
                  inputname={"inp4"}
                  ChangesetValueDate={ChangesetValueDate}
                  inputDate={inputDate}
                />
              </div>
              <div className="col-6 col-lg-2 pr-0 ">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-2 pr-0  ">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
            <div className="row justify-content-start">
              {/* <div className="col-12 col-lg-4 pr-0 mt-lg-3 mt-2">
                <Calendarpicker setDatePicker={setinput} inputname={"inp4"} />
              </div> */}
              {/* <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-3">
                <button className="btn-blue01">
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-3">
                <button className="btn-white01">
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    รีเซท
                  </span>
                </button>
              </div> */}
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการโปรโมชัน
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataPromotion}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลร้านค้า
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    ชื่อร้านค้า: {getDataPromotionID.storeName}
                  </div>
                  <div className="col-12 ml-2 mt-3">
                    <p className="fontReg font-size03 colorGray01">
                      ประเภทร้านค้า: {getDataPromotionID.storeType}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      PromotionID: {getDataPromotionID.promotionID}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อโปรโมชัน: {getDataPromotionID.promotionName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      แคมเปญ: {getDataPromotionID.campaignName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      รายการแคมเปญ:{" "}
                      <p
                        className="fontReg font-size03 colorGray01"
                        dangerouslySetInnerHTML={{
                          __html: getDataPromotionID.detailCompaign,
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      จำนวนสิทธิ์: {getDataPromotionID.amount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Shoppromotion;
