import React, {
  useEffect,
  useState,
  createRef,
  useCallback,
  useRef,
} from "react";
import { useDropzone } from "react-dropzone";
import ShowTextFile from "./ShowTextFile";
import "./UploadFile.css";
import "../swalCustom/customStyle.css";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

function UploadFileXLS(props) {
  const [fileObject, setFileObject] = useState("");
  const Swal = require("sweetalert2");
  const fileCount = useRef(0);
  const [selectFiles, setSelectFiles] = useState([]);
  const [isFileFull, setIsFileFull] = useState(false);

  let fileBoxs = useRef([]);
  let isboxInit = useRef(false);
  if (!isboxInit.current) {
    for (let i = 0; i < props.limit; i++) {
      fileBoxs.current.push("box");
      isboxInit.current = true;
    }
  }
  const [errorMessage, setErrorMessage] = useState("");
  var fileindex = 0;
  const onDrop = useCallback((acceptedFiles) => {
    //console.log(acceptedFiles);
    handleFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (id) => {
    const removeimageIndex = selectFiles.findIndex((e) => e.id === id);
    if (removeimageIndex !== -1) {
      fileCount.current--;
      selectFiles.splice(removeimageIndex, 1);
      // update unsupportedFiles array
      setSelectFiles([...selectFiles]);
      console.log("fileCount.current-- : " + fileCount.current);
      fileBoxs.current.push("box");
      setIsFileFull(false);
    }
  };

  const thumbs = selectFiles.map((file, index) => (
    <ShowTextFile key={index} file={file} removeFile={removeFile} />
  ));

  const handleFiles = (files) => {
    if (!props.limit) {
      props.limit = 1;
    }

    const filesArray = [...selectFiles];
    let errormsg = "";
    let slots = 0;
    slots = props.limit - fileCount.current;
    if (files.length <= slots) {
      slots = files.length;
    }

    if (slots + fileCount.current >= props.limit) {
      setIsFileFull(true);
    } else {
      setIsFileFull(false);
    }

    for (let i = 0; i < slots; i++) {
      //console.log(files[i]);
      if (validateFile(files[i]) && files[i].size <= 1024 * 1024 * 3) {
        fileCount.current++;
        console.log("fileCount.current++ : " + fileCount.current);
        filesArray.push(files[i]);
        fileBoxs.current.pop();
      } else {
        // add a new property called invalid
        setIsFileFull(false);
        files[i]["invalid"] = true;
        if (files[i].size > 1024 * 1024 * 3) {
          errormsg += files[i].name + " File Size over than 3Mb ";
          setErrorMessage(files[i].name + " File Size over than 3Mb");
        } else {
          errormsg += files[i].name + " File type not permitted ";
          setErrorMessage(files[i].name + " File type not permitted");
        }
      }
      if (errormsg !== "") {
        Swal.fire({
          title: "Error!",
          text: errormsg,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    }
    console.log(errormsg);
    console.log("fileObject 1 : ", fileObject);
    filesArray.map((file) => {
      console.log("e.target : ", file);
      // setFileObject(file);
      const reader = new FileReader();
      reader.onload = function (e) {
        //console.log(file)
        setSelectFiles((prevState) => [
          ...prevState,
          { id: fileindex++, src: e.target.result, name: file.name },
        ]);
      };

      reader.readAsDataURL(file);
      return file;
    });
    // return filesArray.length;

    console.log("filesArray : ", filesArray[0]);
  };

  const validateFile = (file) => {
    console.log(file);
    const validTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (fileObject) {
      ExcelRenderer(fileObject, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          const modifyData = resp.rows?.slice(1)?.map((itm, index) => ({
            subsport: itm[0] || "",
          }));
          console.log("modifyData : ", modifyData);
        }
      });
    }
    return () => selectFiles.forEach((file) => URL.revokeObjectURL(file.src));
  }, [fileObject]);

  return (
    <section className="upload fontReg font-size03">
      <span className="remark-dt fontReg font-size03 colorGray02">
        {/* รองรับไฟล์ xls */}
      </span>
      <div className="row w-100">
        {/* <input
          type="file"
          onChange={(e) => {
            setFileObject(e.target.files[0]);
          }}
        /> */}
        <div className={`col-12 col-md-7 ${isFileFull ? "d-none" : "d-flex"} `}>
          <div
            {...getRootProps({ className: `'dropzone' card-upload` })}
            //onDrop={fileDrop}
          >
            <input
              {...getInputProps()}
              type="file"
              onChange={(e) => {
                // setFileObject(e.target.files[0]);
              }}
            />
            <p className="mb-0">ลากและวางไฟล์ที่นี่หรือ</p>
            <button type="button" className="btn-upload" onClick={openDialog}>
              เรียกดูไฟล์
            </button>
          </div>
        </div>
        <div className="col-12 col-md-5 mt-4 mt-md-0">
          <aside className="thumbsContainer">
            {thumbs}
            {fileBoxs.current.map((count, index) => {
              return (
                <div
                  className="boxthumb-file d-flex align-items-center"
                  key={index}
                >
                  <font className="fontReg font-size03 colorGray01">
                    file name
                  </font>
                  <span className="counting">
                    {fileCount.current + "/" + props.limit}
                  </span>
                </div>
              );
            })}
          </aside>
        </div>
      </div>
    </section>
  );
}

export default UploadFileXLS;
