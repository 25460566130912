import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import TableData from "../Tables/TableData";
import TableMobile from "../Tables/TableAddMobile";
import { Link, useNavigate } from "react-router-dom";
import "../pages/Mastersporttype.css";
import DataMockAddMobile from "../dataMockup/DataMockAddMobile";
import Button3 from "../button/ButtonAdd";
import Swal from "sweetalert2";
import moment from "moment";
import { getPermissionList } from "../../api/permission_api";
import Loading from "./Loading";

import {
  getMobilePermissionList,
  removeMobilePermission,
  getMobilePermissionById,
  dataFilterPermission,
} from "../../api/mobile_permission_api";

const mapPermissionConfig = {
  match: "ตารางการแข่งขัน",
  match_stat: "ผลการแข่งขัน",
  arena: "ข้อมูลสนามแข่งขัน",
  arena_permission: "สนามแข่งขันที่รับผิดชอบ",
  arena_checkin: "สแกนเข้าสนาม",
  attraction: "ข้อมูลท่องเที่ยวจังหวัด",
  shop_promotion: "โปรโมชัน",
  shop_joincampaign: "ร่วมแคมเปญ",
  shop_createpromotion: "สร้างโปรโมชัน",
  busbooking: "บริการรถรับส่ง",
  camp_info: "ที่พักนักกีฬา",
  athletes_stat: "สถิตินักกีฬา",
};

function MobilePermission() {
  const enableButton = { view: true, edit: true, delete: false, cancel: false };
  const enableButtonModal = {
    view: false,
    edit: false,
    delete: false,
    cancel: false,
  };
  const columnsName = [
    "id",
    "PermissionID",
    "namePer",
    "typePer",
    "update",
    "action",
  ];
  const columnsModal = ["id", "name"];
  const columnsText = [
    "ลำดับที่",
    "PermissionID",
    "ชื่อ Permission",
    "ประเภท Permission",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsTextHead = ["No", "เมนู"];
  const columnsType = ["text", "text", "text", "text", "text", "action"];
  const columnsTypeModal = ["text", "text"];
  const [permissionMobile, setPermissonMobile] = useState([]);
  const [searchPermissionName, setSearchPermissionName] = useState(null);
  const [permissionPreviewName, setPermissionPreviewName] = useState(null);
  const [spanloading, setSpanLoading] = useState(false);
  const [permissionPreviewTable, setPermissionPreviewTable] = useState(
    []
    // datas: [
    //   {
    //     id: 1,
    //     row: [
    //       { detail: "", type: "" },
    //       { detail: "", type: "text" },
    //     ],
    //   },
    // ],
    // columns: [{ title: "No" }, { title: "เมนู" }],
  );
  console.log("permissionPreviewTable preview data : ", permissionPreviewTable);

  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  function onButtonSearch() {
    const queryParam = {};
    if (searchPermissionName) {
      // queryParam['name'] =
      // searchPermissionName
    }
  }

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/Addeditathlete`)
        break;
      case "edit":
        // handle edit button
        // navigate(`/Addeditsporttype?id=${id}`);
        navigate(`/EditMobilePermission?id=${id}`);
        break;
      case "view":
        let counter = 0;
        getMobilePermissionById(id).then((rs) => {
          const DataTablePreview = [];
          // {
          //   datas: [],
          //   columns: [{ title: "No" }, { title: "เมนู" }],
          // };

          setPermissionPreviewName(rs.data.data.permissionName);
          // console.log(" rs.data.data : ", rs.data.data);
          rs.data.data.permission.map((item, i) => {
            counter++;
            const obj = {
              id: { value: counter, type: "text" },
              // action: {
              //   data: "นักทดสอบแอป",
              //   checkPermission: "show",
              //   type: "action",
              //   value: "1",
              // },
              name: { value: mapPermissionConfig[item], type: "text" },
            };
            //   const obj = {
            //     id: i + 1,
            //     row: [
            //       { detail: i + 1, type: "text" },
            //       { detail: mapPermissionConfig[item], type: "text" },
            //     ],
            //   };
            //   DataTablePreview.datas.push(obj);
            DataTablePreview.push(obj);
          });
          setPermissionPreviewTable(DataTablePreview);
        });
        // ----comment
        // setPermissionPreviewTable(id)
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        // deleteSpTypeByID(id);
        Swal.fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // navigateToBack('/MobilePermission')
            removeMobilePermission(id).then((rs) => {
              if (rs) {
                getMobilePermission().then((data) => setPermissonMobile(data));
              } else {
              }
            });
          }
        });

        break;
      default:
        console.log("Invalid button type");
    }
  };

  async function getMobilePermission() {
    setSpanLoading(true);
    return new Promise((resolve, reject) => {
      getMobilePermissionList().then((rs) => {
        const permissionListArr = rs.data.data.map((item, i) => {
          setSpanLoading(false);
          const obj = {
            id: {
              dataID: item.permissionAccountId,
              type: "text",
              value: i + 1,
            },
            PermissionID: { type: "text", value: item.permissionAccountId },
            namePer: { type: "text", value: item.permissionName },
            typePer: { type: "text", value: "แอปพลิเคชัน" },
            update: {
              type: "text",
              value: item.updatedAt
                ? moment(item.updatedAt).format("YYYY-MM-DD")
                : moment(item.createdAt).format("YYYY-MM-DD"),
            },
            action: {
              data: item.permissionName,
              checkPermission:
                item.permissionName !== "เจ้าหน้าที่สนาม" &&
                item.permissionName !== "นักกีฬา" &&
                item.permissionName !== "โค้ช" &&
                item.permissionName !== "ร้านค้า/ร้านอาหาร" &&
                item.permissionName !== "บุคคลทั่วไป" &&
                item.permissionName !== "เจ้าหน้าที่ดูแลที่พัก" &&
                item.permissionName !== "กรรมการ"
                  ? "show"
                  : "hide",
              type: "action",
              value: "1",
            },
          };

          return obj;
        });

        resolve(permissionListArr);
      });
    });
  }
  useEffect(() => {
    getMobilePermission().then((data) => setPermissonMobile(data));
    getPermissionDropDown();
  }, []);

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    inp1: "",
  });

  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.inp1 = "";
    getMobilePermission().then((data) => setPermissonMobile(data));
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };

  const dataFilter = async () => {
    if (inputForm.inp1 === "") {
      getMobilePermission().then((data) => setPermissonMobile(data));
    } else {
      let bodyFilter = {
        inp1: inputForm.inp1,
      };
      let result = [];
      setSpanLoading(true);
      const respon = await dataFilterPermission(bodyFilter);
      setSpanLoading(false);
      respon.data.data.forEach((item, i) => {
        return result.push({
          id: {
            dataID: item.permissionAccountId,
            type: "text",
            value: i + 1,
          },
          PermissionID: { type: "text", value: item.permissionAccountId },
          namePer: { type: "text", value: item.permissionName },
          typePer: { type: "text", value: "แอปพลิเคชัน" },
          update: {
            type: "text",
            value: item.updatedAt
              ? moment(item.updatedAt).format("YYYY-MM-DD")
              : moment(item.createdAt).format("YYYY-MM-DD"),
          },
          // action: { type: "action", value: "1" },
          action: {
            data: item.permissionName,
            checkPermission:
              item.permissionName !== "เจ้าหน้าที่สนาม" &&
              item.permissionName !== "นักกีฬา" &&
              item.permissionName !== "โค้ช" &&
              item.permissionName !== "ร้านค้า/ร้านอาหาร" &&
              item.permissionName !== "บุคคลทั่วไป" &&
              item.permissionName !== "เจ้าหน้าที่ดูแลที่พัก" &&
              item.permissionName !== "กรรมการ"
                ? "show"
                : "hide",
            type: "action",
            value: "1",
          },
        });
      });
      setPermissonMobile(result);
    }
  };

  const [permissionDropdown, setPermissionDropdown] = useState([]);
  const getPermissionDropDown = async () => {
    try {
      setSpanLoading(true);
      const response = await getPermissionList();
      setSpanLoading(false);
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.permissionAccountId,
            text: item.permissionName,
            subType: [],
          });
        });

        setPermissionDropdown(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {spanloading === true ? <Loading /> : <></>}
      <Header parentmenu={"ตั้งค่าแอดมิน"} />
      <Menu parentmenu={"adminSetting"} activemenu={"MobilePermission"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-settingAdmin.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ตั้งค่าแอดมิน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ตั้งค่าแอดมิน / Permission Application
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/AddMobile"}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูลชื่อ Permission"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    dataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการ Permission
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/AddRecommendatt"
              actionsBtn={enableButton}
              columnsname={columnsName}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={permissionMobile}
              onButtonClicked={handleButtonClicked}
              isSearchEnable={false}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                id="exampleModalLabel"
              >
                ดูรายละเอียด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>Permission Application
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ชื่อ Permission: {permissionPreviewName}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ประเภท Permission: แอปพลิเคชัน
                </p>
              </div>
              <div className="row justify-content-start fontReg font-size03 mt-3">
                <div className="col-12">
                  {/* columnsModal,columnsTextHead */}
                  <TableData
                    pageAddEditName="/"
                    actionsBtn={enableButtonModal}
                    columnsname={columnsModal}
                    columnsText={columnsTextHead}
                    columnsType={columnsTypeModal}
                    rowsdata={permissionPreviewTable}
                    isSearchEnable={false}
                  />
                  {/* <TableMobile item={permissionPreviewTable} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MobilePermission;
