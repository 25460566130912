import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import { flattenOptionGroups } from "@mui/base";
const dataToken = localStorage.getItem("token");
let dataObject = null;

export const getAllArea = async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/arena",
    headers: {
      Authorization: dataToken,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};

const getDataArea = async (data) => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/arena",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    params: {
      arenaId: data.inp1 === "ทั้งหมด" || data.inp1 === "" ? "" : data.inp1,
      active: data.inp2 === "ทั้งหมด" || data.inp2 === "" ? "" : data.inp2,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
const getDataAreaDt = async () => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/arena",
    headers: {
      Authorization: dataToken,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
const getDataAreaByID = async (taxId) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/arena/" + taxId,
    headers: {
      Authorization: dataToken,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
const postDataUpdateArea = async (taxId, body) => {
  console.log("body data preview : ", body.files[0]);
  const formData = new FormData();
  formData.append("arenaName", body.arenaName);
  formData.append("active", body.active);
  formData.append("description", body.description);
  formData.append("latitude", body.latitude);
  formData.append("longitude", body.longitude);
  formData.append("remark", body.remark);

  if (body.files[0]) {
    formData.append("img1", body.files[0] ? body.files[0].file : null);
  }
  // else if (body.files[0] === "") {
  //   formData.append("img1", null);
  // }
  if (body.files[1]) {
    formData.append("img2", body.files[1] ? body.files[1].file : null);
  }
  // else if (body.files[1] === "") {
  //   formData.append("img2", null);
  // }
  if (body.files[2]) {
    formData.append("img3", body.files[2] ? body.files[2].file : null);
  }
  // else if (body.files[2] === "") {
  //   formData.append("img3", null);
  // }
  if (body.files[3]) {
    formData.append("img4", body.files[3] ? body.files[3].file : null);
  }
  // else if (body.files[3] === "") {
  //   formData.append("img4", null);
  // }
  if (body.files[4]) {
    formData.append("img5", body.files[4] ? body.files[4].file : null);
  }
  //  else if (body.files[4] === "") {
  //   formData.append("img5", null);
  // }
  // formData.append(
  //   "img1",
  //   body.files[0] === undefined || body.files[0] === "" ? null : body.files[0]
  // );
  // formData.append(
  //   "img2",
  //   body.files[1] === undefined || body.files[1] === "" ? null : body.files[1]
  // );
  // formData.append(
  //   "img3",
  //   body.files[2] === undefined || body.files[2] === "" ? null : body.files[2]
  // );
  // formData.append(
  //   "img4",
  //   body.files[3] === undefined || body.files[3] === "" ? null : body.files[3]
  // );
  // formData.append(
  //   "img5",
  //   body.files[4] === undefined || body.files[4] === "" ? null : body.files[4]
  // );
  const config = {
    method: "PUT",
    url: process.env.REACT_APP_BASE_URL + "/arena/" + taxId,
    headers: {
      Authorization: dataToken,
    },
    data: formData,
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
//  create subarena
const postCreateSubarena = async (data) => {
  const config = {
    method: "POST",
    url: process.env.REACT_APP_BASE_URL + "/subarena",
    headers: {
      Authorization: dataToken,
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
  // const rs = [];
  // const responsList = [];

  // for (let i = 0; i < data.length; i++) {
  //   const respon = axios.post(
  //     process.env.REACT_APP_BASE_URL + "/subarena",
  //     data[i],

  //     {
  //       headers: {
  //         Authorization: dataToken,
  //       },
  //     }
  //   );

  //   responsList.push(respon);
  // }

  // Promise.all(responsList).then((rs) => {
  //   rs.forEach((response) => {
  //     if (response.status === 200) {

  //       dataObject = response.data;
  //       return dataObject;
  //       // response.data;
  //     }
  //   });
  //   // .catch((error) => {
  //   //   toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
  //   //     position: "top-right",
  //   //     autoClose: 125000,
  //   //     hideProgressBar: false,
  //   //     closeOnClick: true,
  //   //     pauseOnHover: true,
  //   //     draggable: true,
  //   //     className: css({
  //   //       background: "#ed5565 !important",
  //   //     }),
  //   //   });
  //   // });
  // });
  // //

  // //   return axios(config)
  // //     .then((response) => {
  // //       if (response.status === 200) {
  // //         return response;
  // //       } else {
  // //         return null;
  // //       }
  // //     })
};
const postDataUpdateSubArea = async (taxId, data) => {
  const config = {
    method: "PUT",
    url: process.env.REACT_APP_BASE_URL + "/subarena/" + taxId,
    headers: {
      Authorization: dataToken,
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
// function Loading() {
//   return (
//     <div id="login">
//       <ToastContainer />
//     </div>
//   );
// }
// postDeletArea
const postDeletArea = async (id) => {
  const config = {
    method: "DELETE",
    url: process.env.REACT_APP_BASE_URL + "/arena/" + id,
    headers: {
      Authorization: dataToken,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
// postCreateArea
const postCreateArea = async (body) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: dataToken,
  };

  const formData = new FormData();
  formData.append("arenaName", body.arenaName);
  formData.append("active", body.active);
  formData.append("description", body.description);
  formData.append("latitude", body.latitude);
  formData.append("longitude", body.longitude);
  formData.append("img1", body.files[0] === undefined ? null : body.files[0]);
  formData.append("img2", body.files[1] === undefined ? null : body.files[1]);
  formData.append("img3", body.files[2] === undefined ? null : body.files[2]);
  formData.append("img4", body.files[3] === undefined ? null : body.files[3]);
  formData.append("img5", body.files[4] === undefined ? null : body.files[4]);
  formData.append("remark", body.remark);
  const config = {
    method: "POST",
    url: process.env.REACT_APP_BASE_URL + "/arena",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    },
    data: formData,
  };

  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
const getDataAreaFilter = async (data) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/arena?name=" + data,
    headers: {
      Authorization: dataToken,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
const getDataSubArea = async () => {
  // data
  // if (data.inp2 === "ใช้งาน") {
  //   data.inp2 = true;
  // } else if (data.inp2 === "ไม่ใช้งาน") {
  //   data.inp2 = false;
  // }

  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/subarena",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    // params: {
    //   name:
    //     data.inp1 === "ทั้งหมด" || data.inp1 === undefined
    //       ? undefined
    //       : data.inp1,
    //   active:
    //     data.inp2 === "ทั้งหมด" || data.inp2 === undefined
    //       ? undefined
    //       : data.inp2,
    // },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
const getDataSubAreaByID = async (taxId) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/subarena/" + taxId,
    headers: {
      Authorization: dataToken,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
export {
  postCreateArea,
  getDataArea,
  getDataAreaByID,
  dataObject,
  postDataUpdateArea,
  postCreateSubarena,
  postDeletArea,
  getDataAreaFilter,
  getDataSubArea,
  getDataSubAreaByID,
  postDataUpdateSubArea,
  getDataAreaDt,
};
