import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
// const dataToken = sessionStorage.getItem("token");
const dataToken = localStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const getCampaignHistory = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(`/campaign/joincampaignhistory`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getCampaignHistoryFilter = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(`/campaign/joincampaignhistory`, {
      headers: headers,
      params: data,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const verifyImport = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(URL + `/campaign/verifyimport`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const importData = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(URL + `/campaign/importdata`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getCampaign = async (query = {}) => {
  try {
    //example of query
    // const query = {
    //     name: 'John',
    //     type: 'Match'
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/campaign`, {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getCampaignById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/campaign/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getCampaignFilter = async (data) => {
  try {
    //example of query
    // const query = {
    //     name: 'John',
    //     type: 'Match'
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await instace.get(URL + `/campaign`, {
      headers: headers,
      params: {
        campaignName: data.inp1 === "" ? "" : data.inp1,
        active: data.inp2 === "" || data.inp2 === "0" ? "" : data.inp2,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const createCampaign = async (body) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    };
    //
    // campaignName: inputForm.campaignName,
    // campaignDetail: DataDetial,
    // activeStatus: true,
    // campaignType: typeSh,
    // startTime: moment(new Date(inputForm.startTime)).format("DD-MM-YYYY"),
    // endTime: moment(new Date(inputForm.endTime)).format("DD-MM-YYYY"),
    // file: dataImage[0].file,
    //
    const formdata = new FormData();
    formdata.append("campaignName", body.campaignName);
    formdata.append("campaignDetail", body.campaignDetail);
    formdata.append("campaignAmount", body.campaignAmount);
    formdata.append("activeStatus", body.activeStatus);
    formdata.append("campaignType", body.campaignType);
    formdata.append("startTime", body.startTime);
    formdata.append("endTime", body.endTime);
    formdata.append("file", body.file);
    const response = await instace.post(URL + `/campaign`, formdata, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const updateCampaign = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.put(URL + `/campaign/${id}`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const deleteCampaign = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.delete(URL + `/campaign/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
