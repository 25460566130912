import * as React from 'react';
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Buttonexport from '../button/Buttonexports';
import '../CSS/EditLinkWebView.css'
import { useState } from 'react';

function EditLinkWebView () {

    const [Link, setLink] = useState('www.จองรถนักกีฬา.com')
    const [nameLink, setNameLink] = useState('จองรถนักกีฬา')

    return(
        <>
        <Header parentmenu={'จัดการ Link Web View'}/>
    <Menu parentmenu={'manageTheme'} activemenu={'LinkWeb'}/>
    <div className="content-wrapper">
    <div className="container-fluid p-2 p-md-3">
        <div className="row mb-4">
            <div className="col-7 mt-2">
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                  Edit Link
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | จัดการ Link Web view / {nameLink}
              </font>
            </div>
          </div>
          <Col className='card-white'>
            <Row className='mx-1'>
              <Col>
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>แก้ไขข้อมูล Link
              </p></Col>
            </Row>
            <Row className='mt-3 mx-3'>
                <Col xs={4} md={3} lg={2}className='text-header text-end'>จองรถนักกีฬา</Col>
                <Col xs={8} md={9} lg={10}><input className='inp-data01 fontReg font-size03' value={Link} 
                onChange={(e) => {setLink(e.target.value)}}></input></Col>
            </Row>
            </Col>
            <Row className='my-5'>
              <Col xs={2} md={3}></Col>
              <Col className='text-center mx-3' xs={4} md={4} lg={3}><button className='btn-blue04'>บันทึก</button></Col>
              <Col className='text-start' xs={4} md={4} lg={3}><button className='btn-cancle'>ยกเลิก</button></Col>
            </Row>
        </div>
    </div>
        <Footer/>
        </>
    )
}

export default EditLinkWebView