import React from "react";
import "./ChartCard.css";

function ChartCard(props) {
  const { cardData } = props;

  return (
    <div className="m-2">
      <div className="chart-card mt-2">
        <div className="row mr-0">
          <div className="col-1">
            <div className="chart-card-color"></div>
          </div>
          <div className="col-6 p-2">
            <p className="fontReg font-weight-bold font-size03 colorGray01 m-0">
              {cardData.arenaName}
            </p>
            <p className="fontReg font-size03 colorGray01">
              จำนวนคนเข้าใช้ (คน)
            </p>
          </div>
          <div className="col-5 p-2">
            <p className="fontReg font-weight-bold font-size04 colorBlue01 m-0 d-flex justify-content-end">
              {cardData.userCount}
            </p>
            <p className="fontReg font-size03 font-weight-light colorGray01 d-flex justify-content-end text-right">
              Last Update {props.dateTime}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartCard;
