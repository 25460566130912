import React, { useState, useEffect } from "react";
import { DataMockChartCampaignParticipants } from "../dataMockup/DataMockChartCampaignParticipants";
import ChartDoughnut from "../charts/ChartDoughnut";
import { Link } from "react-router-dom";

function CardCampaignParticipants(props) {
  // {totalAllAmount: 8, totalFoodAmount: 6, totalStoreAmount: 3}
  let sumAll =
    props.dataFood.totalAllAmount +
    props.dataFood.totalFoodAmount +
    props.dataFood.totalStoreAmount;
  const [dateAll, setDataAll] = useState([]);
  const [campaignParticipantsData, setCampaignParticipantsData] = useState({
    labels: props.dataCal.map((data) => data.store),
    datasets: [
      {
        label: "จำนวน",
        data: props.dataCal.map((data) => data.quantity),
        backgroundColor: ["#90CD8D", "#33B5B3", "#0698FF"],
        hoverBackgroundColor: ["#90CD8D", "#33B5B3", "#0698FF"],
      },
    ],
  });
  const [userDataFood, setUserDataFood] = useState(props.dataFood);
  const [dateTime, setDatetime] = useState(null);

  useEffect(() => {
    // let data = [];
    // data.push(
    //   {
    //     amount: "125",
    //     id: 1,
    //     quantity: 100,
    //     store: "ร้านค้า",
    //   },
    //   {
    //     amount: "125",
    //     id: 1,
    //     quantity: 100,
    //     store: "ร้านค้า",
    //   }
    // );
    // setDataAll(data);

    if (props) {
      setUserDataFood(props.dataFood);
      setDatetime(props.dataTime);
    }
  }, []);
  return (
    <>
      <div className="card-white">
        <div className="row">
          <div className="col-8 pr-0">
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              <span className="border-left01"></span>ปริมาณผู้เข้าร่วมแคมเปญ
            </p>
          </div>
          <div className="col-4 d-flex justify-content-end p-md-0">
            <Link
              to="/campaignHist"
              className="fontReg font-size03 colorGray01"
            >
              ดูทั้งหมด{" "}
              <span>
                <i className="fas fa-angle-right" />
              </span>
            </Link>
          </div>
        </div>
        <div className="position-relative">
          <ChartDoughnut chartData={campaignParticipantsData} />
          <div className="absolute-center text-center">
            <p className="fontReg font-weight-bold font-size03 colorGray01 mb-0">
              จำนวนทั้งหมด
            </p>
            <p className="fontReg font-weight-bold font-size04 colorBlue01 mb-0">
              {sumAll} <span className="font-size03 colorGray01">ร้าน</span>
            </p>
            <p className="fontReg font-size03 colorGray01 mb-0">Last Update</p>
            <p className="fontReg font-size03 colorGray01 mb-0">{dateTime}</p>
          </div>
        </div>
        <p className="fontReg font-weight-bold font-size03 colorGray01">
          แยกตามประเภทร้าน
        </p>
        <div className="row">
          <div className="col-6 col-lg-6 col-xxl-6 border-right02 br-3">
            <div className="row">
              <div className="col-2 col-sm-1">
                <span className="icon icon-Vector colorGreen01"></span>
              </div>
              <div className="col-10 fontReg font-size03 colorGray01">
                ร้านอาหาร
                <br />
                <span className="fontReg">
                  <span className="font-weight-bold font-size04 colorGreen01">
                    {userDataFood.totalFoodAmount}{" "}
                  </span>
                  <span className="font-size03 colorGray01">ร้าน</span>
                </span>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-6 col-xxl-6">
            <div className="row bd-lf">
              <div className="col-2 col-sm-1">
                <span className="icon icon-Vector colorGreen02"></span>
              </div>
              <div className="col-10 fontReg font-size03 colorGray01">
                ร้านค้า
                <br />
                <span className="fontReg">
                  <span className="font-weight-bold font-size04 colorGreen02">
                    {userDataFood.totalStoreAmount}{" "}
                  </span>
                  <span className="font-size03 colorGray01">ร้าน</span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-12 col-xxl-12 border-right02 mt-3">
            <div className="row">
              <div className="col-10 fontReg font-size03 colorGray01 px-4">
                ร้านค้าและร้านอาหาร
                <br />
                <span className="fontReg">
                  <span className="font-weight-bold font-size04 colorBlue01">
                    {userDataFood.totalAllAmount}{" "}
                  </span>
                  <span className="font-size03 colorGray01">ร้าน</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardCampaignParticipants;
