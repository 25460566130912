import React, { useState, useEffect } from "react";
import Button3items from "../button/Button3items";
import moment from "moment";
import Swal from "sweetalert2";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownRegisBy } from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { useNavigate } from "react-router-dom";
import {
  getShopList,
  getGuestById,
  deleteGuest,
  getDataShopListFilter,
} from "../../api/guest_api";
import { getDataUser } from "../config/configApi-User";
import Loading from "./Loading";

function Shoplist() {
  const [inputForm, setInputForm] = useState({
    shopName: "",
    register: "",
    id: 5,
    shopIDtype: "",
  });
  const DataDropdownRegisBy = [
    { value: "", text: "ทั้งหมด" },
    { value: "1", text: "App" },
    { value: "2", text: "Facebook" },
    { value: "3", text: "Google" },
    { value: "4", text: "Apple ID" },
    { value: "5", text: "Line" },
  ];
  const DataDropdownShopBy = [
    { value: "", text: "ทั้งหมด" },
    { value: "1", text: "ร้านค้า" },
    { value: "2", text: "ร้านอาหาร" },
    { value: "3", text: "ร้านค้า/ร้านอาหาร" },
  ];

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const navigate = useNavigate();
  const [dtRegisType, setRegisType] = useState([]);
  const [dataShoplist, setDataShoplist] = useState([]);
  const [dataShoplistgetID, setDataShoplistgetID] = useState([]);
  const [spanloading, setspanLoading] = useState(false);

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/Addeditcoach`)
        break;
      case "edit":
        // handle edit button
        // navigate(`/Addeditcoach?id=${id}`)
        break;
      case "view":
        getShoplistID(id);

        // handle view button
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        deleteShoplisByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const handleExport = async (type) => {
    let Header = [
      "No",
      "ชื่อร้าน",
      "ชื่อ",
      "เบอร์โทรศัพท์",
      "อีเมล",
      "สมัครโดย",
      "สถานะ",
      "อัปเดตล่าสุด",
    ];

    let Data = dataShoplist.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.storeName.value;
      dt[2] = elemento.name.value;
      dt[3] = elemento.tel.value;
      dt[4] = elemento.email.value;
      dt[5] = elemento.register.value;
      dt[6] = elemento.status.value;
      dt[7] = elemento.updateddate.value;
      return dt;
    });

    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานรายชื่อร้านค้า ร้านอาหาร",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  useEffect(() => {
    GetShoplist();
  }, []);

  const GetShoplist = async () => {
    setspanLoading(true);
    try {
      const respon = await getShopList(5);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.memberID },
          storeName: {
            value: objectData.shopName === null ? "-" : objectData.shopName,
            type: "text",
          },
          name: {
            value:
              objectData.firstname === null && objectData.lastname === null
                ? "-"
                : objectData.firstname + "  " + objectData.lastname,
            type: "text",
          },
          tel: {
            value:
              objectData.mobileNo === null
                ? "-"
                : `${objectData.mobileNo.slice(
                    0,
                    3
                  )}-${objectData.mobileNo.slice(
                    3,
                    6
                  )}-${objectData.mobileNo.slice(6, 10)}
              `,
            type: "text",
          },
          email: {
            value:
              objectData.email === null || objectData.email === ""
                ? "-"
                : objectData.email,
            type: "text",
          },

          register: {
            value: objectData?.registerType,
            type: "text",
          },
          type: {
            value: objectData?.shopType,
            type: "text",
          },
          status: {
            value: objectData.active === 1 ? "ใช้งาน" : "ไม่ใช้งาน",
            active: true,

            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: { value: "1", type: "action" },
          //
        });
      });
      await setspanLoading(false);
      setDataShoplist(rowsdata);
      let dataType = [{ value: "", text: "ทั้งหมด" }];
      respon.data.data.forEach((item, i) => {
        return dataType.push({
          value: item.memberID,
          text: item.shopType,
          subType: [],
        });
      });
      // getFilterdropdown(dataType);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getFilterdropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setRegisType(result);
  };

  const getDataFilter = async () => {
    setspanLoading(true);
    let respon;
    let rowsdata = [];
    if (
      inputForm.name !== "" ||
      selectedRegis !== "" ||
      selectedTypeShop !== ""
    ) {
      let bodyfilter = {
        id: 5,
        inp1: inputForm.name,
        inp2: selectedRegis,
        inp3: selectedTypeShop,
      };
      respon = await getDataShopListFilter(bodyfilter);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.memberID },
          storeName: {
            value: objectData.shopName === null ? "-" : objectData.shopName,
            type: "text",
          },
          name: {
            value:
              objectData.firstname === null && objectData.lastname === null
                ? "-"
                : objectData.firstname + "  " + objectData.lastname,
            type: "text",
          },
          tel: {
            value:
              objectData.mobileNo === null
                ? "-"
                : `${objectData.mobileNo.slice(
                    0,
                    3
                  )}-${objectData.mobileNo.slice(
                    3,
                    6
                  )}-${objectData.mobileNo.slice(6, 10)}
              `,
            type: "text",
          },
          email: {
            value:
              objectData.email === null || objectData.email === ""
                ? "-"
                : objectData.email,
            type: "text",
          },

          register: {
            value: objectData?.registerType,
            type: "text",
          },
          type: {
            value: objectData?.shopType,
            type: "text",
          },
          // email: {
          //   value: objectData.email === null ? "-" : objectData.email,
          //   type: "text",
          // },
          // register: {
          //   value: objectData?.shopType,
          //   type: "text",
          // },
          status: {
            value: objectData.active === 1 ? "ใช้งาน" : "ไม่ใช้งาน",
            active: true,

            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: { value: "1", type: "action" },
          //
        });
      });
      await setspanLoading(false);
      setDataShoplist(rowsdata);
    } else {
      GetShoplist(5);
    }
  };

  const getShoplistID = async (Id) => {
    let getData = [];
    const respon = await getGuestById(Id);

    getData.push({
      id: respon.data.data.idcard === null ? "-" : respon.data.data.idcard,
      title: respon.data.data.title !== null ? respon.data.data.title : "-",
      shopName:
        respon.data.data.shopName === null ? "-" : respon.data.data.shopName,
      address:
        respon.data.data.address !== null ? respon.data.data.address : "-",
      firstname:
        respon.data.data.firstname === null ? "-" : respon.data.data.firstname,
      lastname:
        respon.data.data.lastname === null || respon.data.data.lastname === ""
          ? "-"
          : respon.data.data.lastname,
      img: respon.data.data.img !== null ? respon.data.data.img : null,
      tel:
        respon.data.data.mobileNo === null || respon.data.data.mobileNo === ""
          ? "-"
          : `${respon.data.data.mobileNo.slice(
              0,
              3
            )}-${respon.data.data.mobileNo.slice(
              3,
              6
            )}-${respon.data.data.mobileNo.slice(6, 10)}
  `,
      shopMobileNo:
        respon.data.data.shopMobileNo === null ||
        respon.data.data.shopMobileNo === ""
          ? "-"
          : `${respon.data.data.shopMobileNo.slice(
              0,
              3
            )}-${respon.data.data.shopMobileNo.slice(
              3,
              6
            )}-${respon.data.data.shopMobileNo.slice(6, 10)}
  `,
      email: respon.data.data.email !== null ? respon.data.data.email : "-",
      socialType:
        respon.data.data.registerType === null
          ? "-"
          : respon.data.data.registerType,
      deviceType:
        respon.data.data.shopType === null ? "-" : respon.data.data.shopType,
      status: respon.data.data.approveStatus === false ? "ไม่ใช้งาน" : "ใช้งาน",
      updateddate:
        respon.data.data.updatedAt === null
          ? "-"
          : moment(new Date(respon.data.data.updatedAt)).format("YYYY-MM-DD"),
      time:
        respon.data.data.openTime !== null &&
        respon.data.data.closeTime !== null
          ? respon.data.data.openTime + " - " + respon.data.data.closeTime
          : null,
      web: respon.data.data.web === "" ? "-" : respon.data.data.web,
      tw: respon.data.data.twitter === "" ? "-" : respon.data.data.twitter,
      line: respon.data.data.line === "" ? "-" : respon.data.data.line,
      ig: respon.data.data.ig === "" ? "-" : respon.data.data.ig,
      facebook:
        respon.data.data.facebook === "" ? "-" : respon.data.data.facebook,
      lat:
        respon.data.data.latitude === null || respon.data.data.latitude === ""
          ? "-"
          : respon.data.data.latitude,
      long:
        respon.data.data.longtitude === null ||
        respon.data.data.longtitude === ""
          ? "-"
          : respon.data.data.longtitude,
    });

    setDataShoplistgetID(getData[0]);
  };
  const deleteShoplisByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteGuest(id);
            if (response.data.status === "success") {
              GetShoplist();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const enableButton = {
    view: true,
    edit: false,
    delete: true,
    add: false,
    approve: false,
  };

  const columnsname = [
    "id",
    "storeName",
    "name",
    "tel",
    "email",
    "register",
    "type",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อร้าน",
    "ชื่อ",
    "เบอร์โทรศัพท์",
    "อีเมล",
    "สมัครโดย",
    "ประเภท",
    "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];
  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   storeName: { value: "น้องน้ำ", type: "text" },
    //   name: { value: "นางสาวกันยา สุดสมาน", type: "text" },
    //   tel: { value: "0989876789", type: "text" },
    //   email: { value: "mail@mail.com", type: "text" },
    //   register: { value: "App", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.name = "";
    setSelecteRegis("");
    setValuefromparent1("ทั้งหมด");
    GetShoplist();
    setValuefromparent2("ทั้งหมด");
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  const [selectedRegis, setSelecteRegis] = useState("");
  const [selectedTypeShop, setSelectTypeShop] = useState("");
  const handleRegisChange = (value) => {
    setSelecteRegis(value);
  };
  const handleShopTypeChange = (value) => {
    setSelectTypeShop(value);
  };
  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"รายชื่อร้านค้า ร้านอาหาร"} />
      <Menu parentmenu={"shopData"} activemenu={"shopList"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-shop.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                รายชื่อร้านค้า ร้านอาหาร
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลร้านค้า ร้านอาหาร / รายชื่อร้านค้า ร้านอาหาร
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-md-5 col-xl-4 pr-0">
                <input
                  onChange={onChangeInputName}
                  value={name}
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อร้าน ชื่อจริง นามสกุล"
                />
              </div>
              <div className="col-12 col-md-5 col-xl-3 pr-0 mt-2 mt-md-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">ประเภท</p>
                  <Dropdown
                    options={DataDropdownShopBy}
                    setinput={setinput}
                    inputname={"shopType"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleRegisChange}
                  />
                </div>
              </div>
              <div className="col-12 col-md-5 col-xl-3 pr-0 mt-2 mt-md-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สมัครโดย
                  </p>
                  <Dropdown
                    options={DataDropdownRegisBy}
                    setinput={setinput}
                    inputname={"shopIDtype"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleShopTypeChange}
                  />
                </div>
              </div>
              <div className="col-6 col-md-1 pr-0 mt-md-0 mt-2">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-md-none d-xl-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-md-1 pr-0 mt-md-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-md-none d-xl-inline">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการชื่อร้านค้า
                  ร้านอาหาร
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataShoplist}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลบุคคล
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      คำนำหน้าชื่อ: {dataShoplistgetID.title}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อจริง: {dataShoplistgetID.firstname}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      นามสกุล: {dataShoplistgetID.lastname}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เบอร์โทรศัพท์: {dataShoplistgetID.tel}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      อีเมล: {dataShoplistgetID.email}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ: {dataShoplistgetID.status}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สมัครผ่าน: {dataShoplistgetID.socialType}
                    </p>
                  </div>
                  <div className="col-12 mt-4">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      ข้อมูลร้านค้า
                    </p>
                  </div>
                  <div className="col-12 ml-2 mb-3">
                    <img
                      src={dataShoplistgetID.img}
                      alt="Type Sport"
                      className={
                        dataShoplistgetID.img === null
                          ? "d-none"
                          : "im-sports-type-dt"
                      }
                    />
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อร้านค้า: {dataShoplistgetID.shopName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ประเภทร้านค้า: {dataShoplistgetID.deviceType}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ที่อยู่: {dataShoplistgetID.address}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เบอร์โทรศัพท์: {dataShoplistgetID.shopMobileNo}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เวลาทำการ: {dataShoplistgetID.time}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01 wrap-word">
                      Website: {dataShoplistgetID.web}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01 wrap-word">
                      Facebook: {dataShoplistgetID.facebook}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01 wrap-word">
                      Instagram: {dataShoplistgetID.ig}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01 wrap-word">
                      Line: {dataShoplistgetID.line}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01 wrap-word">
                      Twitter: {dataShoplistgetID.tw}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ละติจูด: {dataShoplistgetID.lat}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ลองจิจูด: {dataShoplistgetID.long}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Shoplist;
