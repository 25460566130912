import React, { useEffect, useState } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownStoreType } from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
// import { getCampaign ,getCampaignById, deleteCampaign} from '../../api/campaign_api';

import { useNavigate } from "react-router-dom";

function Dataapprcampaign() {
  const enableButton = {
    view: false,
    edit: false,
    delete: false,
    add: false,
    approve: true,
  };
  // const [rowsdata, setRowsdata ] = useState([])

  const [inputForm, setInputForm] = useState({
    inp1: "", //ชื่อแคมเปญ
    inp2: 0, //ประเภทร้านค้า
  });

  const getList = async () => {
    try {
      // const response = await getCampaign()
      // if(response){
      //   const campaignData = response.data.data
      //   const results = campaignData.map((row,i) => {
      //       const rowData = {
      //         "id":{ value: i+1,dataID:row?., type: "text" },
      //         "storeName":{ value: row?., type: "text" },
      //         "storeType":{ value: row?. , type: "text" },
      //         "campName":{ value: row?., type: "text" },
      //         "amount":{ value: row?., type: "text" },
      //         "status":{ value: row?., type: "text" },
      //         "updateddate":{ value: row?.updatedAt, type: "text" },
      //         "action":{ value: "1", type: "action" }
      //       }
      //       return rowData
      //   })
      //   setRowsdata(results)
      // }
    } catch (error) {
      console.log(error.message);
    }
  };

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const navigate = useNavigate();
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        break;
      case "edit":
        // handle edit button
        break;
      case "view":
        // handle view button
        break;
      case "approve":
        // handle approve button
        navigate(`/Addapprovecampaign?id=${id}`);
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const handleExport = async (type) => {
    // console.log(type);
  };

  const columnsname = [
    "id",
    "storeName",
    "storeType",
    "campName",
    "amount",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อร้านค้า",
    "ประเภทร้านค้า",
    "ชื่อแคมเปญ",
    "จำนวนสิทธิ์",
    "สถานะ",
    "อัปเดตล่าสุด",
    "อนุมัติ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];
  const rowsdata = [
    {
      id: { value: "1", type: "text" },
      storeName: { value: "ร้านน้องน้ำ", type: "text" },
      storeType: { value: "ร้านอาหาร", type: "text" },
      campName: { value: "แคมเปญ01", type: "text" },
      amount: { value: "10", type: "text" },
      status: { value: "รออนุมัติ", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "2", type: "text" },
      storeName: { value: "ร้านสปอร์ต", type: "text" },
      storeType: { value: "ร้านค้า", type: "text" },
      campName: { value: "แคมเปญ02", type: "text" },
      amount: { value: "10", type: "text" },
      status: { value: "ไม่อนุมัติ", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "3", type: "text" },
      storeName: { value: "ร้านครัวอร่อย", type: "text" },
      storeType: { value: "ร้านอาหาร", type: "text" },
      campName: { value: "แคมเปญ03", type: "text" },
      amount: { value: "10", type: "text" },
      status: { value: "อนุมัติ", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "4", type: "text" },
      storeName: { value: "ร้านผ้าไหม", type: "text" },
      storeType: { value: "ร้านค้า", type: "text" },
      campName: { value: "แคมเปญ04", type: "text" },
      amount: { value: "10", type: "text" },
      status: { value: "อนุมัติ", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "5", type: "text" },
      storeName: { value: "ร้านปังเย็นๆ", type: "text" },
      storeType: { value: "ร้านอาหาร", type: "text" },
      campName: { value: "แคมเปญ05", type: "text" },
      amount: { value: "10", type: "text" },
      status: { value: "อนุมัติ", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
      action: { value: "1", type: "action" },
    },
  ];

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Header parentmenu={"อนุมัติการเข้าร่วมแคมเปญ"} />
      <Menu parentmenu={"campaignData"} activemenu={"approveCampaign"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-campaign.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                อนุมัติการเข้าร่วมแคมเปญ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลแคมเปญ / อนุมัติการเข้าร่วมแคมเปญ
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Buttonexport onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-5 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อแคมเปญ"
                  value={inputForm.inp1}
                  onChange={(e) => setinput("inp1", e.target.value)}
                />
              </div>
              <div className="col-12 col-lg-5 pr-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ประเภทร้านค้า
                  </p>
                  <Dropdown
                    options={DataDropdownStoreType}
                    setinput={setinput}
                    inputname={"inp2"}
                  />
                </div>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-blue01">
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-white01">
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    รีเซท
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการแคมเปญ
                </p>
              </div>
            </div>
            <TableData
              pageApproveName={"/Addapprovecampaign"}
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowsdata}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลแคมเปญ
                    </p>
                  </div>
                  <div className="col-12 ml-2 mb-3">
                    <img
                      src="assets/images/im-taekwondo.png"
                      alt="Type Sport"
                      className="im-sports-type-dt"
                    />
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อแคมเปญ: แคมเปญ01
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      จำนวนสิทธิ์: 10
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ประเภทร้านค้า: ร้านอาหาร
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      วันที่เริ่มต้น: 12/05/2023
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      วันที่สิ้นสุด: 13/05/2023
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      รายละเอียดแคมเปญ: ทดสอบ
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ: ใช้งาน
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dataapprcampaign;
