import React from "react";
import Buttonexport from "./Buttonexports";

function Button3items(props) {
  const { navigateToAddEdit, pageAddEditName, onExportClicked } = props;
  const header = [];
  return (
    <>
      <button
        className={
          window.location.pathname === "/LinkWebView" ||
          window.location.pathname === "/guest" ||
          window.location.pathname === "/shopList" ||
          window.location.pathname === "/approveAcc" ||
          window.location.pathname === "/shopList" ||
          window.location.pathname === "/promotion" ||
          window.location.pathname === "/approvePromotion" ||
          window.location.pathname === "/appUsers" ||
          window.location.pathname === "/arenaUsage" ||
          window.location.pathname === "/campaignHist" ||
          window.location.pathname === "/promotionHist" ||
          window.location.pathname === "/deletedUserAccount" ||
          window.location.pathname === "/brTableBedding" ||
          window.location.pathname === "/sportsvoteDash" ||
          window.location.pathname === "/BorrowStation" ||
          window.location.pathname === "/competitionResults"
            ? "d-none"
            : "btn-blue02 mr-2 mr-xl-3"
        }
        onClick={() => navigateToAddEdit(pageAddEditName)}
      >
        <img
          src="assets/icons/ic-add.png"
          alt="ExportFiles Icon"
          className="ic-add"
        />
        <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
          เพิ่มข้อมูล
        </span>
      </button>

      <Buttonexport
        onPDFClick={() => onExportClicked("pdf")}
        onExelClick={() => onExportClicked("excel")}
      />
    </>
  );
}

export default Button3items;
