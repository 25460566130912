import React, { useEffect, useState } from "react";
import moment from "moment";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Loading from "../pages/Loading";
import Button3items from "../button/Button3items";
import { useNavigate } from "react-router-dom";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownStatus } from "../dataMockup/DataMockDropdown";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import TableData from "../Tables/TableData";

import {
  getCampaign,
  getCampaignById,
  deleteCampaign,
  getCampaignFilter,
} from "../../api/campaign_api";
import Swal from "sweetalert2";
import parse from "html-react-parser";

function Datacampaign() {
  const enableButton = {
    view: true,
    edit: false,
    delete: true,
    add: false,
    approve: false,
  };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const [rowsdata, setRowsdata] = useState([]);
  const [campaignName, setCampaignName] = useState(null);
  const [image, setImage] = useState(null);
  const [campaignAmount, setCampaignAmount] = useState(null);
  const [campaignDetail, setCampaignDetail] = useState(null);
  const [activeStatus, setActiveStatus] = useState(null);
  const [campaignType, setCampaignType] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [inputForm, setInputForm] = useState({
    campaignName: undefined, //ชื่อร้าน
    activeStatus: undefined, //ประเภทร้านค้า
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const getList = async () => {
    setLoading(true);
    try {
      const response = await getCampaign();
      console.log(
        "🚀 ~ file: Datacampaign.js:21 ~ getList ~ response:",
        response
      );
      if (response) {
        const campaignData = response.data.data;
        const results = campaignData.map((row, i) => {
          const rowData = {
            id: { value: i + 1, dataID: row?.campaignID, type: "text" },
            nameCamp: { value: row?.campaignName, type: "text" },
            amount: { value: row?.campaignAmount || 0, type: "text" },
            status: {
              value: row?.activeStatus == true ? "ใช้งาน" : "ไม่ใช้งาน",
              type: "text",
            },
            updateddate: {
              value:
                row.updatedAt !== null
                  ? moment(new Date(row.updatedAt)).format("YYYY-MM-DD")
                  : moment(new Date(row.createdAt)).format("YYYY-MM-DD"),
              type: "text",
            },
            action: { value: "1", type: "action" },
          };
          return rowData;
        });
        await setLoading(false);
        setRowsdata(results);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getByID = async (id) => {
    try {
      const response = await getCampaignById(id);
      if (response) {
        const campaign = response.data.data;
        return campaign;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await deleteCampaign(id);
            if (response.status === 200) {
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
              getList();
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const viewModal = async (id) => {
    try {
      const data = await getByID(id);
      //set data
      const afterChange = parse(`${data.campaignDetail}`);
      await setCampaignName(data.campaignName);
      await setImage(data.image);
      await setCampaignAmount(data.campaignAmount);
      await setCampaignDetail(afterChange);
      await setActiveStatus(data.activeStatus == true ? "ใช้งาน" : "ไม่ใช้งาน");
      // moment(new Date(endTime)).format("YYYY-MM-DD")
      if (data.campaignType === "all") {
        await setCampaignType("ร้านค้าและร้านอาหาร");
      } else {
        await setCampaignType(
          data.campaignType === "food" ? "ร้านอาหาร" : "ร้านค้า"
        );
      }
      await setStartTime(moment(new Date(data.startTime)).format("YYYY-MM-DD"));
      await setEndTime(moment(new Date(data.endTime)).format("YYYY-MM-DD"));
    } catch (error) {}
  };
  // render html
  // const beforeChange = (`${campaignDetail}`)
  // const afterChange = parse(beforeChange)

  const getDataFilter = async () => {
    setLoading(true);
    let respon;
    let rowsdata = [];

    if (inputForm.campaignName === "" && selectedStatus === "") {
      getList();
      // getGuestList(5);
    } else {
      let bodyFilter = {
        inp1: inputForm.campaignName,
        inp2: selectedStatus,
      };

      respon = await getCampaignFilter(bodyFilter);

      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, dataID: objectData?.campaignID, type: "text" },
          nameCamp: {
            value:
              objectData?.campaignName === null ? "-" : objectData.campaignName,
            type: "text",
          },
          amount: {
            value:
              objectData.campaignAmount === null ||
              objectData.campaignAmount === ""
                ? 0
                : objectData.campaignAmount,
            type: "text",
          },
          status: {
            value: objectData?.activeStatus == true ? "ใช้งาน" : "ไม่ใช้งาน",
            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt !== null
                ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      await setLoading(false);
      setRowsdata(rowsdata);
    }
  };

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditathlete`);
        break;
      case "edit":
        // handle edit button
        // navigate(`/Addeditcampaign?id=${id}`)
        break;
      case "view":
        // handle view button
        viewModal(id);
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handleExport = async (type) => {
    let Header = ["No", "ชื่อเเคมเปญ", "สถานะ", "อัปเดตล่าสุด"];

    let data = {
      action: { value: "1", type: "action" },
      id: { value: 1, dataID: 1, type: "text" },
      nameCamp: { value: "โครงการคนละครึ่งเฟส 1", type: "text" },
      status: { value: "ไม่ใช้งาน", type: "text" },
      updateddate: { value: "24-02-2023", type: "text" },
    };
    let Data = rowsdata.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.nameCamp.value;
      // dt[2] = elemento.amount.value;
      dt[2] = elemento.status.value;
      dt[3] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    const resultDate = date.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานเเคมเปญ",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const columnsname = [
    "id",
    "nameCamp",
    "amount",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อแคมเปญ",
    "จำนวนสิทธิ์",
    "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.campaignName = "";
    setSelecteStatus("");
    setValuefromparent1("ทั้งหมด");
    getList();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("campaignName", e.target.value);
      setName(e.target.value);
    }
  };
  const [selectedStatus, setSelecteStatus] = useState("");
  const handleStatusChange = (value) => {
    setSelecteStatus(value);
  };

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"แคมเปญ"} />
      <Menu parentmenu={"campaignData"} activemenu={"campaign"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-campaign.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                แคมเปญ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลแคมเปญ / แคมเปญ
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditcampaign"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-5 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อแคมเปญ"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-12 col-lg-5 pr-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สถานะ</p>
                  <Dropdown
                    options={DataDropdownStatus}
                    setinput={setinput}
                    inputname={"activeStatus"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการแคมเปญ
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Addeditcampaign"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowsdata}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลแคมเปญ
                    </p>
                  </div>
                  <div className="col-12 ml-2 mb-3">
                    <img
                      src={image}
                      alt="Type Sport"
                      className="im-sports-type-dt"
                    />
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อแคมเปญ: {campaignName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      จำนวนสิทธิ์: {campaignAmount ? campaignAmount : 0}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ประเภทร้านค้า: {campaignType}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      {/* moment(new Date(row.updatedAt)).format("YYYY-MM-DD") */}
                      วันที่เริ่มต้น: {startTime}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      วันที่สิ้นสุด: {endTime}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      รายละเอียดแคมเปญ: {campaignDetail}
                    </p>
                  </div>
                  {/* <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ: {activeStatus}
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Datacampaign;
