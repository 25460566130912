import React, { useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import UploadIMG from "../uploads/UploadIMG";
import UploadIMGV2 from "../uploads/UploadIMGv2";
import { Link, useNavigate } from "react-router-dom";
import UploadFileXLS from "../uploads/UploadFileXLS";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Dropdown from "../dropdowns/Dropdown";
import {
  getDataAttractionByID,
  CreateAttraction,
  UpdateAttraction,
  getAttractionCategory,
  verifyImport,
  submitImportData,
} from "../../api/attraction_api";
import "../CSS/DropdownCheckbox.css";
import "../CSS/Addedit.css";
import Select from "react-select";
import ImportExcel from "../uploads/ExcelRender";

function AddRecommendatt() {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [AttName, setAttName] = useState("");
  const [AttType, setAttType] = useState("กรุณาเลือกข้อมูล");
  const [AttCall, setAttCall] = useState("");
  const [AttTime, setAttTime] = useState([]);
  const [AttTimeOpenAll, setAttTimeOpenAll] = useState("");
  const [AttTimeCloseAll, setAttTimeCloseAll] = useState("");
  const [AttTimeOpenCustom, setAttTimeOpenCustom] = useState("");
  const [AttTimeCloseCustom, setAttTimeCloseCustom] = useState("");
  const [AttAddress, setAttAddress] = useState("");
  const [AttDetails, setAttDetails] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longtitude, setLongitude] = useState("");
  const [AttImg, setAttImg] = useState([]);
  const [AttRecomment, setAttRecomment] = useState(false);
  const [dataDay, setDataDay] = useState([]);
  const [dataTypeDropdown, setDataTypeDropdown] = useState([]);
  const [openTime, setOpenTime] = useState();
  const [closeTime, setCloseTime] = useState();

  let data = [
    { value: "mon", label: "Mon" },
    { value: "tue", label: "Tue" },
    { value: "wed", label: "Wed" },
    { value: "thu", label: "Thu" },
    { value: "fri", label: "Fri" },
    { value: "sat", label: "Sat" },
    { value: "sun", label: "Sun" },
  ];

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    categoryId: 0,
    day: [],
  });

  const GetAttractionCategory = async () => {
    const response = await getAttractionCategory();
    let dataType = [{ value: "0", text: "" }];
    response.data.data.forEach((element, i) => {
      return dataType.push({
        id: element.categoryID,
        value: element.categoryID,
        text: element === null ? "-" : element.categoryName,
      });
    });
    getFilterDropdown(dataType);
  };

  const getFilterDropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setDataTypeDropdown(result);
  };

  const [groupShow, setGroupShow] = useState(false);
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/recommendAtt");
      }
    });
  }

  const handleSelectedImages = (images) => {
    setAttImg(images);
  };

  const timeSet = () => {
    setAttTime(!AttTime);
    setIsDayChecked(!isDayChecked);
    setAttTimeOpenCustom("");
    setAttTimeCloseCustom("");
    if (isDayChecked === false) {
      setAttTime(null);
    }
  };
  const [currentTime, setCurrentTime] = useState("");
  const convertToDate = (dateStr) => {
    const [hours, minutes] = dateStr.split(".");
    const currentDate = new Date();
    currentDate.setHours(Number(hours));
    currentDate.setMinutes(Number(minutes));
    console.log("currentDate preview : ", currentDate);
    // return currentDate;

    const hoursDt = String(currentDate.getHours()).padStart(2, "0");
    const minutesDt = String(currentDate.getMinutes()).padStart(2, "0");
    setCurrentTime(`${hoursDt}:${minutesDt}`);
  };
  console.log("currentTime : ", currentTime);
  const originalTargetTime = "08.22";
  const [inputString, setInputString] = useState("");
  const [processedString, setProcessedString] = useState("");
  const [dotsIndices, setDotsIndices] = useState([]);

  // const findDotsIndices = (input) => {

  // };
  console.log("dotsIndices : ", dotsIndices);
  const replaceTargetTimeFormat = (input) => {
    const modifiedString = input.replace(/(\d{2})\.(\d{2})/g, "$1:$2");
    setProcessedString(modifiedString);
  };
  console.log("processedString data : ", processedString);
  const getListDataByID = async () => {
    const response = await getDataAttractionByID(id);
    // || attData.openDay !== "ทุกวัน"
    console.log("response : ", response);
    if (response.status === 200) {
      const attData = response.data.data;
      console.log("attData.openDay : ", attData.openDay);
      if (attData.openDay === "all" || attData.openDay === "ทุกวัน") {
        setAttTimeOpenAll(
          attData.openTime.replace(/(\d{2})\.(\d{2})/g, "$1:$2")
        );
        setAttTimeCloseAll(
          attData.closeTime.replace(/(\d{2})\.(\d{2})/g, "$1:$2")
        );
        setAttTime(!AttTime);
        setIsDayChecked(!isDayChecked);

        if (isDayChecked === false) {
          setAttTime(null);
        }
      } else {
        console.log("A1 : ", attData.openTime);
        console.log("A2 : ", attData.closeTime);

        const options = [
          { label: "วันจันทร์", value: "mon", th: "จันทร์" },
          { label: "วันอังคาร", value: "tue", th: "อังคาร" },
          { label: "วันพุธ", value: "wed", th: "พุธ" },
          { label: "วันพฤหัสบดี", value: "thu", th: "พฤหัส" },
          { label: "วันศุกร์", value: "fri", th: "ศุกร์" },
          { label: "วันเสาร์", value: "sat", th: "เสาร์" },
          { label: "วันอาทิตย์", value: "sun", th: "อาทิตย์" },
        ];
        const days = options.filter((option) =>
          attData.openDay.includes(option.value)
        );
        if (days.length > 0) {
          setSelectedDay(days);
        } else {
          const daysTH = options.filter((option) =>
            attData.openDay.includes(option.th)
          );
          setSelectedDay(daysTH);
        }

        setAttTimeOpenCustom(attData.openTime);
        setAttTimeCloseCustom(attData.closeTime);
      }

      setInputForm({
        categoryId: attData.category.categoryID,
      });
      setAttName(attData.attractionName);
      setAttType(attData.category.categoryName);
      setAttCall(attData.mobileNo);
      setAttAddress(attData.address);
      setAttDetails(attData.attractionDeatil ? attData.attractionDeatil : "-");
      setLatitude(attData.latitude);
      setLongitude(attData.longtitude);
      // attData.openDay !== "all"
      setAttRecomment(attData.isRecommend === 1 ? true : false);
      // setAttRecomment(
      //   attData.openDay === "all" || attData.openDay === "ทุกวัน" ? true : false
      // );
      setOpenTime(attData.openTime);
      setCloseTime(attData.closeTime);
      setAttTimeOpenCustom(attData.openTime);
      setAttTimeCloseCustom(attData.closeTime);
      const image = [
        {
          id: 0,
          status: false,
          src: attData.image ? attData.image : null,
          file: "null",
        },
      ];
      if (attData.image !== "") {
        setAttImg(image);
      }
    } else {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/recommendAtt");
        }
      });
    }
  };

  useEffect(() => {
    GetAttractionCategory();
    if (id) {
      getListDataByID();
    }
  }, []);

  const handleCheck = (event) => {
    var updatedList = [...AttTime];
    if (event.target.value) {
      updatedList = [...AttTime, event.target.value];
    } else {
      updatedList.splice(AttTime.indexOf(event.target.value), 1);
    }
    setAttTime(updatedList);
  };

  const handleCheckRecommended = (event) => {
    setAttRecomment(!AttRecomment);
  };

  const addEditAttraction = async () => {
    const swalWithBootstrapButtons = Swal.mixin();

    // if (AttImg.length === 0) {
    //   swalWithBootstrapButtons.fire({
    //     title: "กรุณาเพิ่มรูปภาพ",
    //     icon: "warning",
    //     confirmButtonText: "ตกลง",
    //   });
    //   return;
    // }

    let openday = "";
    let openT = "";
    let closeT = "";

    if (isDayChecked === true) {
      openday = "all";
      openT = AttTimeOpenAll;
      closeT = AttTimeCloseAll;
    } else if (isDayChecked === false) {
      const selectedval = selectedDay.map((option) => option.value).join(",");
      openday = selectedval;
      openT = AttTimeOpenCustom;
      closeT = AttTimeCloseCustom;
    }
    const body = {
      attractionName: AttName,
      categoryId: inputForm.categoryId || AttType,
      mobileNo: AttCall,
      openDay: openday ? openday : "",
      openTime: openT ? openT : "",
      closeTime: closeT ? closeT : "",
      address: AttAddress,
      attractionDetail: AttDetails,
      latitude: latitude,
      longtitude: longtitude,
      isRecommend: AttRecomment === true ? 1 : 0,
      file: AttImg[0]?.file || AttImg,
    };
    // categoryId
    // console.log("body : ", body);
    if (body.categoryId === null && id) {
      swalWithBootstrapButtons.fire({
        title: "กรุณาเลือกข้อมูลประเภท",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
      return;
    } else {
      if (
        body.categoryId === null ||
        body.attractionName === "" ||
        body.mobileNo === "" ||
        body.openDay === "" ||
        body.openTime === "" ||
        body.closeTime === "" ||
        body.address === "" ||
        body.attractionDetail === "" ||
        body.latitude === "" ||
        body.longtitude === "" ||
        body.isRecommend === "" ||
        body.categoryId === "กรุณาเลือกข้อมูล" ||
        body.file.length === 0
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        if (!id) {
          await Swal.fire({
            title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            reverseButtons: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              const response = await CreateAttraction(body);
              if (response && response.status === 200) {
                Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/recommendAtt");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/recommendAtt");
                  }
                });
              }
            }
          });
        } else {
          await Swal.fire({
            title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            reverseButtons: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              const response = await UpdateAttraction(id, body);
              if (response && response.status === 200) {
                Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/recommendAtt");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/recommendAtt");
                  }
                });
              }
            }
          });
          //  ***********************************************
        }
      }
    }
  };

  const [selectedDay, setSelectedDay] = useState([]);
  const handleChangeDay = (selectedOptions) => {
    setSelectedDay(selectedOptions);
  };

  // *********************** after ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);

  const handleImport = async (rows) => {
    // console.log("rows data : ", rows);
    // console.log("rows.length - 1 preview : ", rows.length - 1);
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);
    // console.log("rows.slice(1) preview  ", rows.slice(1));
    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          attractionName: item[0],
          latitude: item[1] ? item[1] : null,
          longtitude: item[2] ? item[2] : null,
          mobileNo: item[3] ? item[3] : null,
          address: item[4] ? item[4] : null,
          openDay: item[5] ? item[5] : null,
          openTime: item[6] ? item[6] : null,
          closeTime: item[7] ? item[7] : null,
          category: item[8] ? item[8] : null,
          isRecommend: item[9] ? item[9] : null,
        });
      }
    });
    await setImportData(row);
    console.log("row data preview : ", row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          importData: data,
        };
        const response = await verifyImport(dataToVerify);
        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      if (error === 0) {
        // Swal.fire({
        //   title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
        //   icon: "success",
        //   confirmButtonText: "ตกลง",
        // });
        if (failedArr.length > 0) {
          Swal.fire({
            title:
              "ตรวจสอบข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง ",
            icon: "error",
            confirmButtonText: "ยืนยัน",
          });
        } else {
          Swal.fire({
            title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "success",
            confirmButtonText: "ยืนยัน",
          });
        }
        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importSport = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              // memberType: 2,
              importData: data,
            };
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      if (error === true) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/recommendAtt");
          }
        });
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/recommendAtt");
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/recommendAtt");
        }
      });
    }
  };

  // *********************** Take Add Time ************************************
  const options = [
    { label: "วันจันทร์", value: "mon" },
    { label: "วันอังคาร", value: "tue" },
    { label: "วันพุธ", value: "wed" },
    { label: "วันพฤหัสบดี", value: "thu" },
    { label: "วันศุกร์", value: "fri" },
    { label: "วันเสาร์", value: "sat" },
    { label: "วันอาทิตย์", value: "sun" },
  ];

  const [isDayChecked, setIsDayChecked] = useState(false);

  // *********************** Take Add Time ************************************

  return (
    <>
      <Header parentmenu={"จัดการข้อมูลท่องเที่ยวจังหวัด"} />
      <Menu parentmenu={"advertise"} activemenu={"recommendAtt"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-pr.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประชาสัมพันธ์
              </font>
              {!id ? (
                <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                  | ประชาสัมพันธ์ / เพิ่มข้อมูลท่องเที่ยวจังหวัด
                </font>
              ) : (
                <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                  | ประชาสัมพันธ์ / แก้ไขข้อมูลท่องเที่ยวจังหวัด
                </font>
              )}
            </div>
            {!id ? (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <Col className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <Row className="mx-1">
              <Col xs={12} md={12} lg={12}>
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1">
                    {" "}
                    {id ? <>แก้ไขข้อมูล</> : <> เพิ่มข้อมูลทีละรายการ</>}
                  </span>
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                ชื่อ
              </Col>
              <Col lg={10} md={10} xs={8}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setAttName(e.target.value);
                  }}
                  value={AttName}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col x2={2} lg={2} md={1}></Col>
              <Col xs={4} md={5} lg={4} className="mx-2 drpdw-status">
                <p className="fontReg font-size03 colorGray02 mb-1">ประเภท</p>
                <Dropdown
                  setinput={setinput}
                  inputname={"categoryId"}
                  options={dataTypeDropdown}
                  valuefromparent={AttType}
                  id="categoryId"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                เบอร์โทรศัพท์
              </Col>
              <Col lg={10} md={10} xs={8}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setAttCall(e.target.value);
                  }}
                  value={AttCall}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                เวลาเปิดทำการ
              </Col>
              <div className="col-md-auto  ml-2 align-self-center">
                <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                  ทุกวัน
                  <input
                    type="radio"
                    name="option"
                    onClick={timeSet}
                    defaultChecked={isDayChecked}
                    checked={isDayChecked}
                    id="allTime"
                  />
                  <span className="checkmark" />
                </label>
              </div>

              {AttTime === null || AttTime === "" ? (
                <>
                  <div className="col-md-3 ml-0 mt-3 text-center">
                    <span className="mx-2">เวลาเปิด </span>
                    <input
                      className="time-input"
                      type="time"
                      value={AttTimeOpenAll}
                      onChange={(e) => {
                        setAttTimeOpenAll(e.target.value);
                      }}
                      id="allOpen"
                      // ----------
                    />{" "}
                    น.
                  </div>
                  <div className="col-md-3 ml-0 mt-3 text-center">
                    <span className="mx-2">เวลาปิด</span>
                    <input
                      className="time-input"
                      type="time"
                      value={AttTimeCloseAll}
                      id="allClose"
                      onChange={(e) => {
                        setAttTimeCloseAll(e.target.value);
                      }}
                    />{" "}
                    น.
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-auto ml-0 mt-3 text-center">
                    <span className="mx-2">เวลาเปิด</span>
                    <input
                      className="time-input"
                      type="time"
                      value={AttTimeOpenCustom}
                      onChange={(e) => {
                        setAttTimeOpenCustom(e.target.value);
                      }}
                      id="customOpen"
                    />{" "}
                    น.
                  </div>
                  <div className="col-md-auto ml-0 mt-3 text-center">
                    <span className="mx-2">เวลาปิด</span>
                    <input
                      className="time-input"
                      type="time"
                      value={AttTimeCloseCustom}
                      onChange={(e) => {
                        setAttTimeCloseCustom(e.target.value);
                      }}
                      id="customClose"
                    />{" "}
                    น.
                  </div>

                  <div
                    className={`col-md-4 ml-3 mt-3 ${
                      AttTime === null ? "d-none" : "d-block"
                    }`}
                  >
                    <div className="selected-dpdw">
                      <p className="fontReg font-size03 colorGray02 mb-1">
                        เลือกวัน
                      </p>
                      <Select
                        isMulti
                        name="day"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleChangeDay}
                        value={selectedDay}
                      />
                    </div>
                  </div>
                </>
              )}
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                ที่อยู่
              </Col>
              <Col lg={10} md={10} xs={8}>
                <textarea
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setAttAddress(e.target.value);
                  }}
                  value={AttAddress}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                รายละเอียด
              </Col>
              <Col lg={10} md={10} xs={8}>
                <textarea
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setAttDetails(e.target.value);
                  }}
                  value={AttDetails}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={2} xs={2} className="text-header text-end">
                ละติจูด
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  onChange={(e) => {
                    setLatitude(e.target.value);
                  }}
                  className="inp-data01 fontReg font-size03"
                  value={latitude}
                />
              </Col>
              <Col lg={2} md={2} xs={2} className="text-header text-end">
                ลองจิจูด
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                  className="inp-data01 fontReg font-size03"
                  value={longtitude}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={4} md={2} lg={2} className="text-input text-end">
                รูปภาพ
              </Col>
              <Col xs={8} md={10} lg={8}>
                {(() => {
                  if (AttImg.length > 0) {
                    return (
                      <UploadIMGV2
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={AttImg}
                      ></UploadIMGV2>
                    );
                  } else {
                    return (
                      <UploadIMG
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={AttImg}
                      ></UploadIMG>
                    );
                  }
                })()}
                {/* UploadIMGV2 */}
              </Col>
            </Row>
            <div className="row ml-0 mt-4">
              <div className="col-md-2 ml-0 mt-3"></div>
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                รายการแนะนำ
                <input
                  type="checkbox"
                  id="recommend"
                  onClick={handleCheckRecommended}
                  value={AttRecomment}
                  defaultChecked={AttRecomment}
                  checked={AttRecomment}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save" onClick={addEditAttraction}>
                  บันทึก
                </button>
              </div>
            </div>
          </Col>

          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/011-province-info_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                {/* <UploadFileXLS limit={1} onChange={handleFileChange}/> */}
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                {/* <span className='mx-2'>{verifySuccess}/{totalRow}</span> */}
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0 ? true : false}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddRecommendatt;
