import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/pages/Login";
import "./App.css";
import "./components/CSS/spinner.css";
import Home from "./components/pages/Home";
import Dashappuser from "./components/pages/Dashappuser";
import Mastersporttype from "./components/pages/Mastersporttype";
import Masterarena from "./components/pages/Masterarena";
import Masteraffiliation from "./components/pages/Masteraffiliation";
import Masteraccommodation from "./components/pages/Masteraccommodation";
import Datacoach from "./components/pages/Datacoach";
import Dataathlete from "./components/pages/Dataathlete";
import Dataguest from "./components/pages/Dataguest";
import Shopapproveacc from "./components/pages/Shopapproveacc";
import Shoplist from "./components/pages/Shoplist";
import Shopapprpromotion from "./components/pages/Shopapprpromotion";
import Shoppromotion from "./components/pages/Shoppromotion";
import Shoppromotionhist from "./components/pages/Shoppromotionhist";
import Datacampaign from "./components/pages/Datacampaign";
import Dataapprcampaign from "./components/pages/Dataapprcampaign";
import DatacampaignHist from "./components/pages/DatacampaignHist";
import Advrecommendatt from "./components/pages/Advrecommendatt";
import Advrecommendrtr from "./components/pages/Advrecommendrtr";
import Advrecommendstore from "./components/pages/Advrecommendstore";
import Shuttlebusbooking from "./components/pages/Shuttlebusbooking";
import Addeditbbusbooking from "./components/addedits/Addeditbusbooking";
import Borrowbedding from "./components/pages/Borrowbedding";
import Borrowtablebedding from "./components/pages/Borrowtablebedding";
import NotificationPage from "./components/pages/Notification";
import Policyprivacy from "./components/pages/Policyprivacy";
import Termsconditions from "./components/pages/Termsconditions";
import Guide from "./components/pages/Guide";
import BackOfficePermission from "./components/pages/BackOfficePermission";
import Adminlist from "./components/pages/Adminlist";
import Addeditsporttype from "./components/addedits/Addeditsporttype";
import Addeditarena from "./components/addedits/Addeditarena";
import Deleteuseracc from "./components/pages/Deleteuseracc";
import Datastaffs from "./components/pages/Datastaffs";
import Addeditaffiliation from "./components/addedits/Addeditaffiliation";
import AddeditathleteAmt from "./components/addedits/AddeditathleteAmt";
import Datamatchstatistics from "./components/pages/Datamatchstatistics";
import Competitionschedule from "./components/pages/Competitionschedule";
import CompetitionResults from "./components/pages/CompetitionResults";
import Dashsportsvote from "./components/pages/Dashsportsvote";
import Dasharenausage from "./components/pages/Dasharenausage";
import Managebanner from "./components/pages/Managebanner";
import AddeditmanageBanner from "./components/pages/Advmanagebanner";
import Advmanagelandmark from "./components/pages/Advmanagelandmark";
import Thememanagecolor from "./components/pages/Thememanagecolor";
import LinkWebView from "./components/pages/LinkWebView";
import EditLinkWebView from "./components/pages/EditLinkWebView";
import AddStaff from "./components/pages/AddStaffs";
import Thememanage from "./components/pages/Thememanage";
import Addeditcoach from "./components/addedits/Addeditcoach";
import Addeditathlete from "./components/addedits/Addeditathlete";
import Addeditmatchstatistics from "./components/addedits/Addeditmatchstatistics";
import Forgotpassword from "./components/pages/Forgotpassword";
import Chpassword from "./components/pages/ChangePass";
import Addeditcompetschedule from "./components/addedits/Addeditcompetschedule";
import AddBus from "./components/pages/AddBus";
import EditBus from "./components/pages/EditBus";
import CancelBus from "./components/pages/CancelBus";
import EditBorrowbeding from "./components/pages/Editbedding";
import AddBorrowbedding from "./components/pages/Addbedding";
import Borrowstation from "./components/pages/Borrowstation";
import EditStation from "./components/pages/EditStation";
import AddStation from "./components/pages/AddStation";
import AddNotifications from "./components/pages/AddNotification";
import EditNotification from "./components/pages/EditNotifications";
import Addatt from "./components/pages/AddAtt";
import EmailForgotpassword from "./components/pages/Linkemail";
import EditAtt from "./components/pages/EditAtt";
import AddShop from "./components/pages/AddShop";
import EditShop from "./components/pages/EditShop";
import AddRecommendatt from "./components/pages/AddRecommendatt";
import EditRes from "./components/pages/EditRes";
import AddLandmark from "./components/pages/AddLandmark";
import EditLandmark from "./components/pages/EditLandmark";
import AddBackOffice from "./components/pages/AddBackOffice";
import AddMobile from "./components/pages/AddMobile";
import EditAdmin from "./components/pages/EditAdmin";
import AddAdmin from "./components/pages/AddAdmin";
import MobilePermission from "./components/pages/MobilePermission";
import AddcompetresultsSolo from "./components/add/AddcompetresultsSolo";
import EditcompetresultsSolo from "./components/edit/EditcompetresultsSolo";
import AddcompetresultsTeams from "./components/add/AddcompetresultsTeams";
import EditcompetresultsTeams from "./components/edit/EditcompetresultsTeams";
import Addeditcampaign from "./components/addedits/Addeditcampaign";
import Addapprovecampaign from "./components/add/Addapprovecampaign";
import Addapproveshop from "./components/add/Addapproveshop";
import Addapprovepromotion from "./components/add/Addapprovepromotion";
import Addeditguide from "./components/addedits/Addeditguide";
import Mastermatchtype from "./components/pages/Mastermatchtype";
import Addeditmatchtype from "./components/addedits/Addeditmatchtype";
import Editmatchtype from "./components/edit/Editmatchtype";
import Masterarenasub from "./components/pages/Masterarenasub";
import Addeditarenasub from "./components/addedits/Addeditarenasub";
import Editarenasub from "./components/edit/Editarenasub";
import EditMobilePermission from "./components/pages/EditMobile";
import EditBackoffice from "./components/pages/EditBackoffice";

function App() {
  // const ref= getDataUser();
  // localStorage.clear();
  return (
    <div className="wrapper">
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace={true} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/matchStatistics" element={<Datamatchstatistics />} />
          <Route path="/sportsvoteDash" element={<Dashsportsvote />} />
          <Route path="/arenaUsage" element={<Dasharenausage />} />
          <Route path="/appUsers" element={<Dashappuser />} />
          <Route path="/sportType" element={<Mastersporttype />} />
          <Route path="/arena" element={<Masterarena />} />
          <Route path="/affiliation" element={<Masteraffiliation />} />
          <Route path="/athleteAmt" element={<Masteraccommodation />} />
          <Route path="/coach" element={<Datacoach />} />
          <Route path="/athlete" element={<Dataathlete />} />
          <Route path="/guest" element={<Dataguest />} />
          <Route path="/staff" element={<Datastaffs />} />
          <Route path="/approveAcc" element={<Shopapproveacc />} />
          <Route path="/shopList" element={<Shoplist />} />
          <Route path="/approvePromotion" element={<Shopapprpromotion />} />
          <Route path="/promotion" element={<Shoppromotion />} />
          <Route path="/promotionHist" element={<Shoppromotionhist />} />
          <Route path="/campaign" element={<Datacampaign />} />
          <Route path="/approveCampaign" element={<Dataapprcampaign />} />
          <Route path="/campaignHist" element={<DatacampaignHist />} />
          <Route path="/Managebanner" element={<Managebanner />} />
          <Route
            path="/AddeditmanageBanner"
            element={<AddeditmanageBanner />}
          />
          <Route path="/recommendAtt" element={<Advrecommendatt />} />
          <Route path="/recommendRtr" element={<Advrecommendrtr />} />
          <Route path="/recommendStore" element={<Advrecommendstore />} />
          <Route path="/manageLandmark" element={<Advmanagelandmark />} />
          <Route path="/shuttlebusBooking" element={<Shuttlebusbooking />} />
          <Route path="/Addeditbusbooking" element={<Addeditbbusbooking />} />
          <Route path="/brBedding" element={<Borrowbedding />} />
          <Route path="/brTableBedding" element={<Borrowtablebedding />} />
          <Route path="/notiList" element={<NotificationPage />} />
          <Route path="/privacy" element={<Policyprivacy />} />
          <Route path="/termsConditions" element={<Termsconditions />} />
          <Route path="/guide" element={<Guide />} />
          <Route
            path="/BackOfficePermission"
            element={<BackOfficePermission />}
          />
          <Route path="/MobilePermission" element={<MobilePermission />} />
          <Route path="/adminList" element={<Adminlist />} />
          <Route path="/deletedUserAccount" element={<Deleteuseracc />} />
          <Route path="/Addeditsporttype" element={<Addeditsporttype />} />
          <Route path="/Addeditarena" element={<Addeditarena />} />
          <Route path="/Addeditaffiliation" element={<Addeditaffiliation />} />
          <Route path="/AddeditathleteAmt" element={<AddeditathleteAmt />} />
          <Route
            path="/competitionSchedule"
            element={<Competitionschedule />}
          />
          <Route path="/competitionResults" element={<CompetitionResults />} />
          <Route path="/manageThemeColor" element={<Thememanagecolor />} />
          <Route path="/LinkWebView" element={<LinkWebView />} />
          <Route path="/EditlinkwebView" element={<EditLinkWebView />} />
          <Route path="/AddStaff" element={<AddStaff />} />
          <Route path="/manageTheme" element={<Thememanage />} />
          <Route path="/Addeditcoach" element={<Addeditcoach />} />
          <Route path="/Addeditathlete" element={<Addeditathlete />} />
          <Route
            path="/Addeditmatchstatistics"
            element={<Addeditmatchstatistics />}
          />
          <Route path="/forgotPassword" element={<Forgotpassword />} />
          <Route path="/Chpassword" element={<Chpassword />} />
          <Route
            path="/Addeditcompetschedule"
            element={<Addeditcompetschedule />}
          />
          <Route
            path="/EmailforgotPassword"
            element={<EmailForgotpassword />}
          />
          <Route path="/AddshuttlebusBooking" element={<AddBus />} />
          <Route path="/EditshuttlebusBooking" element={<EditBus />} />
          <Route path="/CancelshuttlebusBooking" element={<CancelBus />} />
          <Route path="/Editborrowbedding" element={<EditBorrowbeding />} />
          <Route path="/Addborrowbedding" element={<AddBorrowbedding />} />
          <Route path="/BorrowStation" element={<Borrowstation />} />
          <Route path="/EditStation" element={<EditStation />} />
          <Route path="/AddStations" element={<AddStation />} />
          <Route path="/AddNotifications" element={<AddNotifications />} />
          <Route path="/EditNotifications" element={<EditNotification />} />
          <Route path="/Addatt" element={<Addatt />} />
          <Route path="/EditAtt" element={<EditAtt />} />
          <Route path="/EditShop" element={<EditShop />} />
          <Route path="/AddShop" element={<AddShop />} />
          <Route path="/EditRes" element={<EditRes />} />
          <Route path="/AddRecommendatt" element={<AddRecommendatt />} />
          <Route path="/EditLandmark" element={<EditLandmark />} />
          <Route path="/AddLandmark" element={<AddLandmark />} />
          <Route path="/AddBackOffice" element={<AddBackOffice />} />
          <Route path="/EditBackoffice" element={<EditBackoffice />} />
          <Route path="/AddMobile" element={<AddMobile />} />
          <Route
            path="/EditMobilePermission"
            element={<EditMobilePermission />}
          />
          <Route path="/AddAdmin" element={<AddAdmin />} />
          <Route path="/EditAdmin" element={<EditAdmin />} />
          <Route
            path="/AddcompetresultsSolo"
            element={<AddcompetresultsSolo />}
          />
          <Route
            path="/EditcompetresultsSolo"
            element={<EditcompetresultsSolo />}
          />
          <Route
            path="/AddcompetresultsTeams"
            element={<AddcompetresultsTeams />}
          />
          <Route
            path="/EditcompetresultsTeams"
            element={<EditcompetresultsTeams />}
          />
          <Route path="/Addeditcampaign" element={<Addeditcampaign />} />
          <Route path="/Addapprovecampaign" element={<Addapprovecampaign />} />
          <Route path="/Addapproveshop" element={<Addapproveshop />} />
          <Route
            path="/Addapprovepromotion"
            element={<Addapprovepromotion />}
          />
          <Route path="/Addeditguide" element={<Addeditguide />} />
          <Route path="/matchType" element={<Mastermatchtype />} />
          <Route path="/Addeditmatchtype" element={<Addeditmatchtype />} />
          <Route path="/Editmatchtype" element={<Editmatchtype />} />
          <Route path="/arenaSub" element={<Masterarenasub />} />
          <Route path="/Addeditarenasub" element={<Addeditarenasub />} />
          <Route path="/Editarenasub" element={<Editarenasub />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
