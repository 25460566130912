import * as React from 'react';
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../CSS/Addedit.css'
import {useState , useRef, useEffect} from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Buttonexport from '../button/Buttonexports';
import Dropdown from '../dropdowns/Dropdown';
import Swal from 'sweetalert2'
import { DataDropdownPerson, DataDropdowBooking } from '../dataMockup/DataMockDropdown'
function AddBus() {

    const navigate = useNavigate()

    const [name, setName] = useState(null)
    const [cardNum, setCardNum] = useState(null)
    const [carShuttle, setcarShuttle] = useState(false)
    const [Way, setWay] = useState(false)
    const [typeBooking, settypeBooking] = useState(null)
    const [startGo, setStartGo] = useState(null)
    const [startBack, setStartBack] = useState(null)
    const [stopGo, setStopGo] = useState(null)
    const [stopBack, setStopBack] = useState(null)
    const [timestartGO, setTimeStartGo] = useState(null)
    const [timestartBack, setTimeStartBack] = useState(null)
    const [timeStopGO, setTimeStopGo] = useState(null)
    const [timeStopBack, setTimeStopBack] = useState(null)
    const [nameDriver, setNameDriver] = useState(null)
    const [callDriver, setCallDriver] = useState(null)
    const [carNum, setCarNum] = useState(null)
    const [carColor, setcarColor] = useState(null)
    const [typeCar, setTypeCar] = useState(null)

    const alertStaff = () => {
          Swal.fire({
            title: 'ต้องการส่งแจ้งเตือนไปยังผู้จองหรือไม่',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#0698FF',
            confirmButtonText: 'ยืนยัน',
            cancelButtonColor: '#D1D3D4',
            cancelButtonText: 'ยกเลิก',
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: 'ส่งการแจ้งเตือนไปยังผู้จองเรียบร้อย',
                confirmButtonColor: '#0698FF',
                confirmButtonText: 'ตกลง',
               }
              )
            }
            navigate('/shuttlebusBooking')
          })
    }

    const carShuttleSet = () => setcarShuttle(!carShuttle);
    const watSet = () => setWay(!Way);

    return(
        <>
         <Header parentmenu={'ข้อมูลการจองรถรับ-ส่ง'}/>
    <Menu parentmenu={'shuttlebusData'} activemenu={'shuttlebusBooking'}/>
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-schedule.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ข้อมูลการจองรถรับ-ส่ง
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการจองรถรับ-ส่ง / ข้อมูลการจองรถรับ-ส่ง เพิ่มข้อมูล
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
                <Buttonexport/>
            </div>
          </div>
          <Col className='card-white'>
          <Row className='mx-1'>
              <Col xs={12} md={12} lg={12}> 
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> เพิ่มข้อมูลการจองรถรับ-ส่ง</span>
              </p>
              </Col>
            </Row>
          <Row className=' mt-1'>
                <Col x2={2} lg={2} md={1}></Col>
                <Col xs={4} md={5} lg={4} className="drpdw-status h-100">
                  <p className="fontReg font-size03 colorGray02 mb-1">ประเภทผู้จอง</p><Dropdown options={DataDropdownPerson}/>
                </Col>
                <Col xs={2} lg={2} md={1}></Col>
                <Col xs={4} md={5} lg={4} className="drpdw-status h-100">
                  <p className="fontReg font-size03 colorGray02 mb-1">ช่องทางการจอง</p><Dropdown options={DataDropdowBooking}/>
                </Col>
          </Row>
          <Row className='mt-3'>
            <Col lg={2} md={2} xs={3}className='text-header text-center'>ชื่อผู้จอง</Col>
            <Col lg={4} md={4} xs={3}><input className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setName(e.target.value)}}/></Col>
            <Col lg={2} md={3} xs={3}className='text-header text-center'>เลขบัตรประชาชน</Col>
            <Col lg={4} md={3} xs={3}><input className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setCardNum(e.target.value)}}/></Col>
          </Row>
          <Row className='mt-3'>
                <Col lg={2} md={2} xs={4}className='text-chekbox text-center'>ประเภทการจอง</Col>
                <Col lg={2} md={2} xs={4}className='text-format'>
                <label className="checkbox-item01 ">รถรับส่ง
                <input type="radio" name="option1" onClick={carShuttleSet} value={true} />
                <span className="checkmark" /></label>
                </Col>
                <Col lg={2} md={2} xs={4} className='text-format'><label className="checkbox-item01 ">รถฉุกเฉิน
                <input type="radio" name="option1" onClick={carShuttleSet} value={true} />
                <span className="checkmark" /></label></Col>
          </Row>
          <Row className='mt-3'>
                <Col lg={2} md={2} xs={4}className='text-chekbox text-center'>รูปแบบการจอง</Col>
                <Col lg={2} md={2} xs={4}className='text-format'><label className="checkbox-item01 ">เที่ยวเดียว
               <input type="radio" name="option2" onClick={watSet} value={true}/>
                <span className="checkmark" /></label></Col>
                <Col lg={2} md={2} xs={4}className='text-format'><label className="checkbox-item01 ">ไป - กลับ
               <input type="radio" name="option2" onClick={watSet} value={true}/>
                <span className="checkmark" /></label></Col>
          </Row>
          <Row className='mt-3'>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>ต้นทาง - ขาไป</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setStartGo(e.target.value)}}/></Col>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>ต้นทาง - ขากลับ</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setStartBack(e.target.value)}}/></Col>
          </Row>
          <Row className='mt-3'>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>เวลา-ขาไป</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setTimeStartGo(e.target.value)}} /></Col>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>เวลา-ขากลับ</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setTimeStartBack(e.target.value)}} /></Col>
          </Row>
          <Row className='mt-3'>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>ปลายทาง - ขาไป</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setStopGo(e.target.value)}} /></Col>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>ปลายทาง - ขากลับ</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setStopBack(e.target.value)}} /></Col>
          </Row>
          <Row className='mt-3'>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>ชื่อผู้ขับ</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setNameDriver(e.target.value)}}/></Col>
          </Row>
          <Row className='mt-3'>
          <Col lg={2} md={2} xs={2}className='text-header text-center'>เบอร์โทรศัพท์</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setCallDriver(e.target.value)}}/></Col>
          </Row>
          <Row className='mt-3'>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>เลขทะเบียน</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setCarNum(e.target.value)}} /></Col>
          </Row>
          <Row className='mt-3'>
          <Col lg={2} md={2} xs={2}className='text-header text-center'>สีรถ</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setcarColor(e.target.value)}} /></Col>
          </Row>
          <Row className='mt-3'>
                <Col lg={2} md={2} xs={2}className='text-header text-center'>ประเภทรถ</Col>
                <Col lg={4} md={4} xs={4}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setTypeCar(e.target.value)}}/></Col>
          </Row>
          </Col>
          <Row className='my-5'>
              <Col xs={2}></Col>
              <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-blue02' onClick={alertStaff}>บันทึก</button></Col>
              <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-cancle'>ยกเลิก</button></Col>
              <Col xs={2}></Col>
            </Row>
          </div>
          </div>
           <Footer/>
        </>
    )
}

export default AddBus