import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
// const dataToken = sessionStorage.getItem("token");
const dataToken = localStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const getPromotionHistory = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(`/promotion/promotionhistory`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getPromotionFilterHistory = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(`/promotion/promotionhistory`, {
      headers: headers,
      params: data,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const verifyImport = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(URL + `/promotion/verifyimport`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const importData = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(URL + `/promotion/importdata`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getPromotion = async (data) => {
  try {
    //example of query
    // const query = {
    //     name: 'John',
    //     type: 'Match'
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/promotion`, {
      headers: headers,
      params: data,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

// export const getPromotionFilter = async (data, body) => {
//   try {
//     //example of query
//     // const query = {
//     //     name: 'John',
//     //     type: 'Match'
//     // }
//     // shopName: 'ffff', shopType: '', campaignName: ''
//     data.shopName = body?.shopName;
//     data.shopType = body?.shopType;
//     data.campaignName = body?.campaignName;
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: dataToken,
//     };
//     const response = await instace.get(URL + `/promotion`, {
//       headers: headers,
//       params: data,
//       // data: body,
//     });

//     if (response.status === 200) {
//       return response;
//     } else {
//       return null;
//     }
//   } catch (error) {
//     toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
//   }
// };

export const getPromotionFilter = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/promotion`, {
      headers: headers,
      params: {
        promotionStatus: data.promotionStatus,
        shopName: data.inp1 === "" ? "" : data.inp1,
        shopType: data.inp2 === "" ? "" : data.inp2,
        campaignName: data.inp3 === "" ? "" : data.inp3,
        dateTime: data.dateTime === "" ? "" : data.dateTime,
      },
      // data: body,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getPromotionById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/promotion/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const createPromotion = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(URL + `/promotion`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const updatePromotion = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.put(URL + `/promotion/${id}`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const approvePromotion = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.put(URL + `/promotion/approve/${id}`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const deletePromotion = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.delete(URL + `/promotion/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
