import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/ButtonAdd";
// import Buttonexport from "../button/Button3items";
import { useNavigate } from "react-router-dom";
import Table from "../Tables/TableNoti";
import DataMockNotification from "../dataMockup/DataMockNotification";
import TableData from "../Tables/TableData";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Loading from "./Loading";

import {
  getNotificationlist,
  getNotificationById,
  removeNotification,
  broadcastNotification,
  getNotiFilter,
} from "../../api/notification_api";
function Notification() {
  const [isLoading, setLoading] = useState(false);
  const [checked, setChecked] = useState(null);
  const columnsname = ["id", "name", "status", "noti", "action"];
  // const columnsname = ["id", "name", "status", "noti", "action"];
  const columnsText = [
    "No",
    "ชื่อการแจ้งเตือน",
    "สถานะ",
    "แจ้งเตือน",
    "เครื่องมือ",
  ];
  // "text",
  const columnsType = ["text", "text", "text", "noti", "action"];

  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   name: { value: "นางสาวกันยา สุดสมาน", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   noti: { value: "1", type: "noti" },
    //   action: { value: "1", type: "action" },
    // },
    // {
    //   id: { value: 2, type: "text" },
    //   name: { value: "นางสาย มานา", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   noti: { value: "1", type: "noti" },
    //   action: { value: "1", type: "action" },
    // },
    // {
    //   id: { value: 3, type: "text" },
    //   name: { value: "นายจิต บุญดี", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   noti: { value: "1", type: "noti" },
    //   action: { value: "1", type: "action" },
    // },
  ];

  const [spanloading, setspanloading] = useState(false);
  const [dataNoti, getDatanotification] = useState([]);
  const [dataNotiByID, getDatanotificationByID] = useState([]);
  const [dataNotiName, getDatanotiName] = useState([]);
  const [dataPermission, getDatapermission] = useState([]);
  // notificationDetail
  const [dataNotiDetail, getDatanotiDetail] = useState([]);
  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };
  const enableNotiButton = { noti: true };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        break;
      case "edit":
        // handle edit button
        navigate(`/EditNotifications?id=${id}`);
        break;
      case "view":
        // handle view button
        getDataNotiID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "noti":
        broadcastByID(id);
        // handle cancel button
        break;
      case "delete":
        deleteByID(id);
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const getDataNotiID = async (id) => {
    // let data = {
    //   active: 0,
    //   createdAt: null,
    //   deletedAt: null,
    //   isSend: 0,
    //   notificationDetail: "bbb",
    //   notificationID: 7,
    //   notificationName: "bbb",
    //   permissionAccountMobile: "[1]",
    //   updatedAt: null,
    // };
    setLoading(true);
    const respon = await getNotificationById(id);
    setLoading(false);

    let getData = [];
    getData.push({
      active: respon.data.data.active === 0 ? false : true,
      isSend: respon.data.data.isSend,
      notificationDetail: respon.data.data.notificationDetail,
      notificationID: respon.data.data.notificationID,
      notificationName: respon.data.data.notificationName,
      permissionAccountMobile: respon.data.data.permissionAccountMobile,
    });

    getDatanotiName(getData[0].notificationName);
    getDatanotiDetail(getData[0].notificationDetail);
    // getDatanotificationByID(getData[0]);
    getDatapermission(getData[0].permissionAccountMobile);
    console.log("getData[0].active  : ", getData[0].active);
    setChecked(getData[0].active);
  };

  const handleExport = async (type) => {
    // console.log(type);
  };
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    inp1: undefined,
  });

  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.inp1 = undefined;
    getDataNoti();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };
  const getDataNoti = async () => {
    //
    setLoading(true);
    const respon = await getNotificationlist();
    setLoading(false);
    respon.data.data.forEach((objectData, i) => {
      return rowsdata.push({
        id: { value: i + 1, dataID: objectData.notificationID, type: "text" },
        name: { value: objectData.notificationName, type: "text" },
        status: {
          value: objectData.active !== 1 ? "ไม่ใช้งาน" : "ใช้งาน",
          type: "text",
        },
        noti: { value: "1", type: "noti" },
        action: { value: "1", type: "action" },
      });
    });

    getDatanotification(rowsdata);
  };

  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            const response = await removeNotification(id);
            setLoading(false);
            // const response = await removeStaff(id);
            swalWithBootstrapButtons.fire({
              title: "ลบรายการสำเร็จ!",
              icon: "success",
              confirmButtonText: "ตกลง",
            });
            if (response.data.status === "success") {
              // getDataSleeppack();
              getDataNoti();
            } else {
              swalWithBootstrapButtons.fire({
                title: "เกิดข้อผิดพลาด!",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const broadcastByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะ broadcast รายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            const response = await broadcastNotification(id);
            setLoading(true);
            // const response = await removeStaff(id);

            if (response.data.status === "success") {
              // getDataSleeppack();
              swalWithBootstrapButtons.fire({
                title: "broadcast รายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
              getDataNoti();
            } else {
              swalWithBootstrapButtons.fire({
                title: "เกิดข้อผิดพลาด!",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const getAffFilter = async () => {
    if (inputForm.inp1 === undefined) {
      getDataNoti();
    } else {
      let bodyfilter = {
        name: inputForm.inp1,
      };
      setLoading(true);
      const respon = await getNotiFilter(bodyfilter);
      setLoading(false);
      // const respon = await getNotificationlist();
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, dataID: objectData.notificationID, type: "text" },
          name: { value: objectData.notificationName, type: "text" },
          status: {
            value: objectData.active !== 1 ? "ไม่ใช้งาน" : "ใช้งาน",
            type: "text",
          },
          noti: { value: "1", type: "noti" },
          action: { value: "1", type: "action" },
        });
      });
      getDatanotification(rowsdata);
      // setDataMockArena(rowsdata);
    }
  };
  useEffect(() => {
    getDataNoti();
  }, []);
  return (
    <>
      {(() => {
        if (isLoading === true) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"รายการแจ้งเตือน"} />
      <Menu parentmenu={"notification"} activemenu={"notiList"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-notification.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                รายการแจ้งเตือน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | การแจ้งเตือน / รายการแจ้งเตือน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Buttonexport
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/AddNotifications"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-sm-6 col-xl-8 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาชื่อการแจ้งเตือน"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(event) => getAffFilter()}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการแจ้งเตือน
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              notiBtn={enableNotiButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataNoti}
              // rowsdata={rowsdata}
              isSearchEnable={false}
              pageAddEditName={"/EditNotifications"}
              pageAddDeleteName={"/DeleteNotifications"}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>
      <Footer />
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                id="exampleModalLabel"
              >
                ดูรายละเอียด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {/* dataNotiByID */}
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูล
                </p>
              </div>
              <div className="row">
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    ชื่อการแจ้งเตือน: {dataNotiName}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    ข้อความการแจ้งเตือน: {dataNotiDetail}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ml-2">
                  {dataPermission.map((data, index) => {
                    return (
                      <p className="fontReg font-size03 colorGray01">
                        ประเภทผู้ใช้งานที่ต้องการแจ้งเตือน : {data.value}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="row ml-2">
                <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                  ใช้งาน
                  <input
                    type="checkbox"
                    defaultChecked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
