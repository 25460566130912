import React, { useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import TableMobile from "../Tables/TableAddMobile";
import DataMockAddMobile from "../dataMockup/DataMockAddMobile";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "../pages/Mastersporttype.css";
import TableData from "../Tables/TableData";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";

import {
  getMobilePermissionMenu,
  updateMobilePermission,
  getMobilePermissionById,
} from "../../api/mobile_permission_api";

const mapPermissionConfig = {
  match: "ตารางการแข่งขัน",
  match_stat: "ผลการแข่งขัน",
  arena: "ข้อมูลสนามแข่งขัน",
  arena_permission: "สนามแข่งขันที่รับผิดชอบ",
  arena_checkin: "สแกนเข้าสนาม",
  attraction: "ข้อมูลท่องเที่ยวจังหวัด",
  shop_promotion: "โปรโมชัน",
  shop_joincampaign: "ร่วมแคมเปญ",
  shop_createpromotion: "สร้างโปรโมชัน",
  busbooking: "บริการรถรับส่ง",
  camp_info: "ที่พักนักกีฬา",
  athletes_stat: "สถิตินักกีฬา",
};

function EditMobile() {
  const enableButton = { view: true, edit: true, delete: true, check: true };
  const [namePermission, setNamePermission] = useState();
  const [permission, setPermission] = useState("แอปพลิเคชัน");
  const [idParam, setIdParam] = useState(
    new URLSearchParams(window.location.search).get("id")
  );

  const columnsname = ["id", "menu", "checkboxAdd"];
  const columnsText = ["No", "เมนู", "เครื่องมือ"];
  const columnsType = ["text", "text", "checkbox"];
  const [menuMobile, setMenuMobile] = useState([]);
  const [menuMobileChecked, setMenuMobileChecked] = useState([]);

  function submitForm() {
    const menuSelect = menuMobileChecked.map((item) => {
      return { menuId: item };
    });
    if (
      namePermission === "" ||
      namePermission === null ||
      menuSelect.length < 1
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      Swal.fire({
        icon: "warning",
        title: "คุณต้องการที่จะแก้ไขข้อมูลหรือไม่",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          updateMobilePermission(idParam, {
            permissionAccountName: namePermission,
            permissionAccountMenu: menuSelect,
          }).then((rs) => {
            if (rs) {
              Swal.fire({
                title: "แก้ไขข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ยืนยัน",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToBack("/MobilePermission");
                }
              });
            } else {
              toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: css({
                  background: "#ed5565 !important",
                }),
              });
            }
          });
        }
      });
    }
  }
  const enableCheckbox = { checkbox: true };

  const navigate = useNavigate();

  function setCheckBoxState(ev) {
    if (ev.target.checked === true) {
      setMenuMobile((prev) => {
        const newState = prev.map((item) => {
          if (item.id.dataID == parseInt(ev.target.value)) {
            item.checkboxAdd.checked = true;
          }
          return item;
        });
        return newState;
      });
      setMenuMobileChecked((prev) => [...prev, parseInt(ev.target.value)]);
      // setMenuMobileChecked()
    } else {
      setMenuMobile((prev) => {
        const newState = prev.map((item) => {
          if (item.id.dataID == parseInt(ev.target.value)) {
            item.checkboxAdd.checked = false;
          }
          return item;
        });
        return newState;
      });

      setMenuMobileChecked((prev) =>
        prev.filter((item) => item != ev.target.value)
      );
    }
  }

  useEffect(() => {
    if (idParam) {
      getMobilePermissionMenu()
        .then((rs) => {
          getMobilePermissionById(idParam).then((resMobilePerm) => {
            setNamePermission(resMobilePerm.data.data.permissionName);
            const menu = rs.data.data.map((item, i) => {
              const filterSelMobile = resMobilePerm.data.data.permission.filter(
                (perItem) => perItem == item.permissionMenuName
              );
              if (filterSelMobile.length > 0) {
                setMenuMobileChecked((prev) => [
                  ...prev,
                  parseInt(item.permissionMenuMobileID),
                ]);
              } else {
              }
              const obj = {
                // id: { value: item.permissionMenuMobileID.toString(), type: "text" },
                id: {
                  dataID: item.permissionMenuMobileID,
                  value: i + 1,
                  type: "number",
                },
                menu: {
                  value: mapPermissionConfig[item.permissionMenuName],
                  type: "text",
                },
                checkboxAdd: {
                  checked: filterSelMobile.length > 0 ? true : false,
                  value: item.permissionMenuMobileID.toString(),
                  type: "checkbox",
                  eventHandler: (ev) => setCheckBoxState(ev),
                },
                // checkboxAdd: {value: item.permissionMenuMobileID.toString(), type: "checkbox", eventHandler: (ev) => setCheckBoxState(ev) },
              };

              return obj;
            });
            setMenuMobile(menu);
            // setMenuMobile(prev => (prev.map(item => )))
          });
        })
        .then(() => {
          // load get by id data
        });
    }
  }, []);

  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/MobilePermission");
      }
    });
  }

  return (
    <>
      <Header parentmenu={"ตั้งค่าแอดมิน"} />
      <Menu parentmenu={"adminSetting"} activemenu={"MobilePermission"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-settingAdmin.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ตั้งค่าแอดมิน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ตั้งค่าแอดมิน / Permission เพิ่มข้อมูล
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>Permission
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-2 text-header">ชื่อ Permission</div>
              <div className="col-6 col-sm-4 col-xl-4 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อ Permission"
                  onChange={(e) => {
                    setNamePermission(e.target.value);
                  }}
                  value={namePermission}
                />
              </div>
            </div>
            <div className="row justify-content-start mt-4">
              <div className="col-2 text-header">ประเภท Permission</div>
              <div className="col-6 col-sm-4 col-xl-4 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อ Permission"
                  value={permission}
                  disabled
                />
              </div>
              <div className="col-12 justify-content-start mt-5">
                <TableData
                  pageApproveName={"/Addapprovecampaign"}
                  addCheckbox={enableCheckbox}
                  actionsBtn={enableButton}
                  columnsname={columnsname}
                  columnsText={columnsText}
                  columnsType={columnsType}
                  rowsdata={menuMobile}
                  isSearchEnable={false}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button onClick={() => submitForm()} className="btn-save">
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditMobile;
