import Dropdown from "../dropdowns/Dropdown";
import { SliderCardArenaUsers } from "../sliders/SliderCardArenaUsers";
import { DataDropdownPeriod } from "../dataMockup/DataMockDropdown";
import { Link } from "react-router-dom";
import { getDashboard } from "../../api/dashboard_api";
import React, { useState, useEffect } from "react";
function CardArenaUsers(props) {
  return (
    <>
      <div className="card-white mt-3 pb-5">
        <div className="row">
          <div className="col-6 col-xl-8">
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              <span className="border-left01"></span>
              ปริมาณผู้เข้าใช้สนามกีฬา
            </p>
          </div>
          <div className="col-6 col-xl-4 d-flex justify-content-end pl-0">
            {/* row w-100 */}
            <div className="">
              {/* <div className="col-7 col-md-9 drpdw-style">
                <p className="fontReg font-size03 colorGray02 mb-1">
                  เลือกช่วงเวลา
                </p>
                <Dropdown options={DataDropdownPeriod} />
              </div> */}
              {/* <div className="col-5 col-md-3 d-flex justify-content-end align-items-center pl-0 p-md-0">
               */}
              {/* <div className="col-7 col-md-9 drpdw-style"> */}
              <Link
                className="fontReg font-size03 colorGray01"
                to={"/arenaUsage"}
              >
                ดูทั้งหมด{" "}
                <span>
                  <i className="fas fa-angle-right" />
                </span>
              </Link>
              {/* </div> */}
            </div>
          </div>
        </div>
        <SliderCardArenaUsers
          dataTime={props.dataTime}
          dataArena={props.dataArena}
        />
      </div>
    </>
  );
}

export default CardArenaUsers;
