import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { getPermissionMobileList } from "../../api/permission_api";
import { logDOM } from "@testing-library/react";
import { creatNotification } from "../../api/notification_api";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Loading from "./Loading";
function AddNotifications() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [nameNoti, setNameNoti] = useState(null);
  const [detail, setDetail] = useState(null);
  const [dataPermem, getDataPermem] = useState([]);
  const [checked, setChecked] = useState(null);
  useEffect(() => {
    getDataPermission();
  }, []);
  const getDataPermission = async () => {
    setLoading(true);
    const res = await getPermissionMobileList();
    setLoading(false);
    let getPer = [];
    let dataArr = res.data.data;
    for (let i = 0; i < dataArr.length; i++) {
      getPer.push({
        permissionAccountId: dataArr[i].permissionAccountId,
        permissionName: dataArr[i].permissionName,
        status: false,
      });
    }
    getDataPermem(getPer);
  };
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/notiList");
      }
    });
  }
  const createNoti = async () => {
    let dataArr = [];
    for (let i = 0; i < dataPermem.length; i++) {
      if (dataPermem[i].status === true) {
        dataArr.push(dataPermem[i].permissionAccountId);
      }
    }

    if (
      nameNoti === null ||
      detail === null ||
      nameNoti === "" ||
      detail === ""
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      });
    } else {
      let body = {
        notificationName: nameNoti,
        notificationDetail: detail,
        active: checked === true ? 1 : 0,
        permissionMobileId: dataArr,
      };

      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            setLoading(true);
            const respon = await creatNotification(body);
            setLoading(false);
            // setLoading(1);
            if (respon.data.status === "success") {
              Swal.fire({
                title: "บันทึกข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ยืนยัน",
              }).then((result) => {
                if (result.isConfirmed) {
                  // setLoading(1);
                  navigate("/notiList");
                }
              });
            }
          })();
        }
      });
    }
  };
  const dataGetCheck = async (data) => {
    let getArr = [];
    for (let i = 0; i < dataPermem.length; i++) {
      getArr.push({
        permissionAccountId: dataPermem[i].permissionAccountId,
        permissionName: dataPermem[i].permissionName,
        status:
          data.permissionAccountId === dataPermem[i].permissionAccountId
            ? true
            : dataPermem[i].status,
      });

      // permissionAccountID
    }

    getDataPermem(getArr);
  };
  return (
    <>
      {(() => {
        if (isLoading === true) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"รายการแจ้งเตือน"} />
      <Menu parentmenu={"notification"} activemenu={"notiList"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-notification.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                การแจ้งเตือน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | การแจ้งเตือน / รายการแจ้งเตือน เพิ่มข้อมูล
              </font>
            </div>
          </div>
          <Col className="card-white">
            <Row className="mx-1">
              <Col xs={12} md={12} lg={12}>
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1">
                    {" "}
                    เพิ่มข้อมูลรายการแจ้งเตือน
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={3} xs={3} className="text-header text-start mx-3">
                ชื่อการแจ้งเตือน
              </Col>
              <Col lg={9} md={8} xs={7}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setNameNoti(e.target.value);
                  }}
                  value={nameNoti}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={3} xs={3} className="text-header text-start mx-3">
                ข้อความการแจ้งเตือน
              </Col>
              <Col lg={9} md={8} xs={7}>
                <textarea
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setDetail(e.target.value);
                  }}
                  value={detail}
                />
              </Col>
            </Row>
            <div className="row mx-2 mt-4">
              <div className="col-2 text-header mx-2">
                ผู้ใช้งานที่ต้องการแจ้ง
              </div>
              {dataPermem.map((data, index) => {
                return (
                  <div className="col-2 mt-4">
                    <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                      {data.permissionName}
                      <input
                        className="mr-1"
                        type="checkbox"
                        defaultChecked={data.status}
                        onChange={(e) => {
                          dataGetCheck(data);
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                );
              })}
            </div>
            {/* <div className="row ml-0 mt-4 mx-2">
              <div className="col-2 mx-2"></div>
              <div className="col-2">
                <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                  เจ้าหน้าที่ดูแลสนาม
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </div>
            </div> */}

            {/* <div className="row ml-0 mt-5 mx-2">
              <div className="col-2 mx-2"></div>
              <div className="col ">
                <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                  ใช้งาน
                  <input
                    type="checkbox"
                    onChange={() => setChecked(!checked)}
                    defaultChecked={checked}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div> */}
          </Col>
          <Row className="my-5">
            <Col xs={2}></Col>
            <Col className="text-center" xs={4} md={4} lg={4}>
              <button className="btn-blue02" onClick={(event) => createNoti()}>
                บัททึก
              </button>
            </Col>
            <Col className="text-center" xs={4} md={4} lg={4}>
              <button
                className="btn-cancle"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddNotifications;
