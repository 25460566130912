import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import moment from "moment";
import { verifyImport, submitImportData } from "../../api/match_api";
import {
  DataDropdownAffiliation,
  DataDropdownSportType,
  DataDropdownCompetType,
  DataDropdownArenas,
} from "../dataMockup/DataMockDropdown";
import Calendarpicker from "../calendars/Calendarpicker";
import Timepicker from "../times/Timepicker";
import DataMockCompetScheduleAddAthlete from "../dataMockup/DataMockCompetScheduleAddAthlete";
import { getAffliation } from "../../api/affliation_api";
import "./Addeditcompetschedule.css";
import TableNoPagination from "../Tables/TableNoPagination";
// import TableDataCheckbox from "../Tables/TableBootstrap";
import TableData from "../Tables/TableData";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import UploadFileXLS from "../uploads/UploadFileXLS";
import { getSport } from "../../api/sport_api";
import { getDataAreaDt } from "../config/configApi-Area";
import { getSubSport, getSubSportFind } from "../../api/subsport_api";
import { geteAthlete, geteAthleteSearch } from "../../api/athlete_api";
import { getSubArena, getSubArenaSearch } from "../../api/subarena_api";
import { createMatch, updateMatch } from "../../api/match_api";
import { getMatchById } from "../../api/match_api";
import { getArena } from "../../api/arena_api";
import Loading from "../../components/pages/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImportExcel from "../uploads/ExcelRender";
function Addeditcompetschedule() {
  let id = new URLSearchParams(window.location.search).get("id");
  const Swal = require("sweetalert2");
  const [isSolo, setIsSolo] = useState(true);
  const [valuefromparent, setValuefromparent] = useState("เดี่ยว");
  const [subTypes, setSubTypes] = useState([]);
  let currentGroup = useRef(0);
  const prevType = useRef("ทั้งหมด");
  const [isClear, setIsClear] = useState(false);
  const prevTypeValue = useRef(0);
  const [forceRerender, setforceRerender] = useState(false);
  const [dtSport, setDtsport] = useState([]);
  const [dtSubArena, setDtSubArena] = useState([]);
  const [getDataArena, setDataArena] = useState([]);
  const [dtSub, setDtSubsp] = useState([]);
  const [dtSubsport, setDtSubsport] = useState([]);
  const [dtAff, setDtAff] = useState([]);
  let [groupDt, setGroupDt] = useState(0);
  let DataAffl = [];
  const [selectId, setSelectId] = useState(null);

  const [selectedSport, setSelectedSport] = useState([
    {
      value: null,
      label: "ทั้งหมด",
    },
  ]);
  const [selectedMatchHeat, setSelectMatchHeat] = useState("รอบชิงชนะเลิศ");
  const [selectedSubarena, setSelectSubarena] = useState("กรุณาเลือกสนามกีฬา");
  const [selecteArena, setSelectArena] = useState("ทั้งหมด");
  const [selectedMatchtype, setSelectMatchtype] = useState("เดี่ยว");
  const DataDropdownMatch = [
    { value: 1, text: "ทั้งหมด" },
    { value: "single", text: "เดี่ยว" },
    { value: "team", text: "ทีม" },
  ];
  const [selectedMatch, setSelectMatch] = useState([
    {
      value: null,
      label: "ทั้งหมด",
    },
  ]);
  const [selectDt, setSelectDt] = useState(false);
  let inputDate = "";
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  let DataScheduleAthlete = {
    datas: [
      // {
      //   id: 0,
      //   row: [
      //     { detail: "1", type: "checkbox" },
      //     { detail: "1", type: "text" },
      //     { detail: "นางสาวเทควันโด ดีงาม", type: "text" },
      //     { detail: "1049658696667", type: "text" },
      //     { detail: "กรุงเทพมหานคร", type: "text" },
      //     { detail: "7/5/2022", type: "text" },
      //   ],
      // },
    ],
    columns: [
      { title: "checkbox" },
      { title: "No" },
      { title: "ชื่อ" },
      { title: "เลขบัตรประชาชน" },
      { title: "สังกัด" },
      { title: "อัปเดตล่าสุด" },
    ],
  };

  const [dtAthe, setDtAthe] = useState(DataScheduleAthlete);
  const [failedRow, setFailedRow] = useState([]);
  const setsubtype_data = (data) => {
    setSubTypes(data);
  };
  let subTypeoption = [];
  useEffect(() => {
    getDtAffliation();
    getAreaSub(null);
    getAreaData();
    getDataSport();
    //
    // getSubsport(null);

    subTypeoption = DataDropdownSportType[0].subType;
    setSubTypes(subTypeoption);

    // getDtAthlete();
    if (id) {
      getMatchDataById();
      getSubsportDt();
    }
  }, []);
  const DataDropdownCompetitionRound = [
    { value: 1, values: "final", text: "รอบชิงชนะเลิศ" },
    { value: 2, values: "semi", text: "รอบรองชนะเลิศ" },
    { value: 3, values: "qualify", text: "รอบคัดเลือก" },
  ];
  const initialData = [
    {
      group: 0,
      tabaledata: [
        { id: 1, name: 1, idCard: 1, affiliation: 1, updateddate: 1 },
      ],
    },
    {
      group: 2,
      tabaledata: [
        { id: 2, name: 2, idCard: 2, affiliation: 2, updateddate: 2 },
        { id: 21, name: 21, idCard: 21, affiliation: 21, updateddate: 21 },
      ],
    },
  ];
  const initialData1 = [
    {
      group: 0,
      tabaledata: [
        { id: 1, name: 1, idCard: 1, affiliation: 1, updateddate: 1 },
        { id: 2, name: 2, idCard: 2, affiliation: 2, updateddate: 2 },
        { id: 21, name: 21, idCard: 21, affiliation: 21, updateddate: 21 },
      ],
    },
  ];
  const [data, setData] = useState([]);
  // console.log("data preview test : ", data);
  const getMatchDataById = async () => {
    // test

    // ----
    const respon = await getMatchById(id);
    let objectData = respon.data.data.affiliationMatch;
    let getData = [];
    let couter = 0;
    // console.log("competitionType :", respon.data.data.competitionType);
    //  competSchedule[0].tabaledata
    // competSchedule
    //  group: competCount.current,

    if (respon.data.data.competitionType === "single") {
      let dataArray = [];
      let tableDt = {
        group: 0,
        tabaledata: dataArray,
      };
      for (let i = 0; i < objectData.length; i++) {
        // let tabaledata = {
        //   group: i,
        //   tabaledata: [],
        // };
        for (let j = 0; j < objectData[i].athletes.length; j++) {
          couter++;
          dataArray.push({
            id: {
              value: couter,
              type: "text",
              dataID: objectData[i].athletes[j].atheleteID,
            },
            name: {
              value:
                objectData[i].athletes[j].firstname +
                " " +
                objectData[i].athletes[j].lastname,
              type: "text",
            },
            idCard: {
              value: objectData[i].athletes[j].idcard,
              type: "text",
            },
            affiliation: {
              value: objectData[i].affliationName,
              dataAffID: objectData[i].affliationID,
              type: "text",
            },
            updateddate: {
              value: moment(new Date()).format("YYYY-MM-DD"),
              type: "text",
            },
            action: { value: "1", type: "action" },
          });
          // tabaledata.tabaledata.push({
          //   id: {
          //     value: couter,
          //     type: "text",
          //     dataID: objectData[i].athletes[j].atheleteID,
          //   },
          //   name: {
          //     value:
          //       objectData[i].athletes[j].firstname +
          //       " " +
          //       objectData[i].athletes[j].lastname,
          //     type: "text",
          //   },
          //   idCard: {
          //     value: objectData[i].athletes[j].idcard,
          //     type: "text",
          //   },
          //   affiliation: {
          //     value: objectData[i].affliationName,
          //     dataAffID: objectData[i].affliationID,
          //     type: "text",
          //   },
          //   updateddate: {
          //     value: moment(new Date()).format("YYYY-MM-DD"),
          //     type: "text",
          //   },
          //   action: { value: "1", type: "action" },
          // });
          // console.log("objectData[i].athletes data : ", objectData[i].athletes);
        }

        // if (i == 0) {
        //   competSchedule[0] = tabaledata;
        // } else {
        //   competSchedule.push(tabaledata);
        //   console.log(" competSchedule preview : ", competSchedule);
        // }
      }
      competSchedule[0] = tableDt;
      // competSchedule.push(tableDt);
      // console.log("tableDt preview test : ", tableDt);
    } else {
      for (let i = 0; i < objectData.length; i++) {
        let tabaledata = {
          group: i,
          tabaledata: [],
        };
        for (let j = 0; j < objectData[i].athletes.length; j++) {
          // console.log("objectData[i].athletes data : ", objectData[i].athletes);
          couter++;
          tabaledata.tabaledata.push({
            id: {
              value: couter,
              type: "text",
              dataID: objectData[i].athletes[j].atheleteID,
            },
            name: {
              value:
                objectData[i].athletes[j].firstname +
                " " +
                objectData[i].athletes[j].lastname,
              type: "text",
            },
            idCard: {
              value: objectData[i].athletes[j].idcard,
              type: "text",
            },
            affiliation: {
              value: objectData[i].affliationName,
              dataAffID: objectData[i].affliationID,
              type: "text",
            },
            updateddate: {
              value: moment(new Date()).format("YYYY-MM-DD"),
              type: "text",
            },
            action: { value: "1", type: "action" },
          });
        }
        if (i == 0) {
          competSchedule[0] = tabaledata;
        } else {
          competSchedule.push(tabaledata);
        }
      }
    }

    let arena = null;
    if (respon.data.data) {
      arena = respon.data.data.subArena.subArenaName;
    }
    setSelectArena(respon.data.data.subArena.arena.arenaName);
    setSelectSubarena(respon.data.data.subArena.subArenaName);

    selectedSport[0].label = respon.data.data.sportName;
    getDtAthlete(respon.data.data.sportName);
    // inputForm.inp1 = respon.data.data.sportName;
    // setSelectedSport( respon.data.data.sportName);
    // let dtSports = { value: null, label: respon.data.data.sportName };

    // inputForm.inp5 === 1 || inputForm.inp5 === "รอบชิง"
    //             ? "final"
    //             : inputForm.inp5 === 2 || inputForm.inp5 === "รอง"
    //             ? "semi"
    //             : inputForm.inp5 === 3 || inputForm.inp5 === "คัดเลือก"
    //             ? "qualify"
    //             : "",
    setSelectMatchHeat(
      respon.data.data.matchHeat === "final" ||
        respon.data.data.matchHeat === "รอบชิง"
        ? "รอบชิงชนะเลิศ"
        : respon.data.data.matchHeat === "semi" ||
          respon.data.data.matchHeat === "รอง"
        ? "รอบรองชนะเลิศ"
        : respon.data.data.matchHeat === "qualify" ||
          respon.data.data.matchHeat === "คัดเลือก"
        ? "รอบคัดเลือก"
        : ""
    );

    setSelectMatchtype(
      respon.data.data.competitionType == "single" ? "เดี่ยว" : "ทีม"
    );
    selectedMatch[0].label = respon.data.data.subsportName;
    // inputForm.inp2 = respon.data.data.subsportName;
    // setSelectMatch(selectedMatch);
    inputForm.inp1 = respon.data.data.sportName;
    inputForm.inp2 = respon.data.data.subsportName;
    inputForm.inp3 = respon.data.data.subArena.subArenaName;
    inputForm.inp6 = respon.data.data.competitionType;
    inputForm.inp4 = respon.data.data.matchName;
    inputForm.inp5 = respon.data.data.matchHeat;
    inputForm.inp7 = moment(new Date(respon.data.data.startTime)).format(
      "YYYY-MM-DD"
    );

    inputForm.inp8 = moment(respon.data.data.startTime).format("HH:mm");
    // .add(-7, "hours")

    setInputForm(inputForm);
  };
  const getDtAthlete = async (labelSport) => {
    // console.log("Athhh test : ", inputForm.inp1);
    const respon = await geteAthlete();
    respon.data.data.forEach((objectData, i) => {
      // console.log("objectData : ", objectData);
      // console.log("inputForm.inp1 : ", inputForm.inp1);
      if (objectData.sport === labelSport) {
        return DataScheduleAthlete.datas.push({
          id: objectData.atheleteID,
          row: [
            {
              type: "checkbox",
              value: objectData.atheleteID,
            },
            { value: i + 1, type: "text" },
            {
              value: objectData.firstname + "   " + objectData.lastname,
              type: "text",
            },
            { value: objectData.idcard, type: "text" },
            {
              value: objectData.affliation,
              dataAffID: objectData.affliationID,
              type: "text",
            },

            {
              value: objectData.updatedAt
                ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
              type: "text",
            },
          ],
        });
      }
    });
    masterData.current = [];

    respon.data.data.forEach((objectData, i) => {
      if (objectData.sport === labelSport) {
        masterData.current.push({
          //
          id: { value: i + 1, type: "text", dataID: objectData.atheleteID },
          name: {
            value: objectData.firstname + "   " + objectData.lastname,
            type: "text",
          },
          idCard: {
            value: objectData.idcard,
            type: "text",
          },
          affiliation: {
            value: objectData.affliation,
            type: "text",
            dataAffID: objectData.affliationID,
          },
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "action" },
          //
        });
      }
    });
    DataScheduleAthlete.datas.splice(0, 0);
    setDtAthe({ ...DataScheduleAthlete });
  };

  const ChangesetValueDate = async (value) => {};

  const getDtAffliation = async () => {
    const respon = await getAffliation();
    let dataAff = [];
    respon.data.data.forEach((item, i) => {
      if (item.affliationName !== null) {
        return dataAff.push({
          value: item.affliationID,
          text: item.affliationName,
        });
      }
    });
    setDtAff(dataAff);
  };
  const getSubsportDt = async () => {
    const respon = await getSubSport();
    let dtSubData = [];
    respon.data.data.forEach((item, i) => {
      if (item.subSportName !== null && item.active !== false) {
        return dtSubData.push({
          value: item.subSportID,
          label: item.subSportName,
          // text: item.subSportName,
        });
      }
    });
    setDtSubsport(dtSubData);
  };
  // getSubsportDtSearch
  const getSubsportDtSearch = async () => {
    const respon = await getSubSport();
    let dtSubData = [];
    respon.data.data.forEach((item, i) => {
      if (item.subSportName !== null && item.active !== false) {
        return dtSubData.push({
          value: item.subSportID,
          label: item.subSportName,
          // text: item.subSportName,
        });
      }
    });
    setDtSubsport(dtSubData);
  };
  const getSubsport = async (id) => {
    const respon = await getSubSportFind(id);
    let dtSubData = [];
    let dtSubDataArr = [];
    //
    // const respon = await getSubSport();
    // let dtSubData = [];

    // after

    if (id) {
      respon.data.data.forEach((item, i) => {
        if (item.subSportName !== null && item.active !== false) {
          return dtSubData.push({
            value: item.subSportID,
            label: item.subSportName,
          });
        }
      });
      setDtSubsport(dtSubData);
    }
    respon.data.data.forEach((item, i) => {
      if (item.subSportName !== null) {
        return dtSubDataArr.push({
          value: item.subSportID,
          text: item.subSportName,
        });
      }
    });
    setDtSubsp(dtSubData);
  };

  const getAreaSub = async (data) => {
    // const swalWithBootstrapButtons = Swal.mixin();
    // swalWithBootstrapButtons
    //   .fire({
    //     title: "กรุณาเลือกสนามกีฬา",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "ยืนยัน",
    //     cancelButtonText: "ยกเลิก",
    //     reverseButtons: true,
    //   })
    //   .then((result) => {
    //     if (result.isConfirmed) {
    //       getAreaSearchData(null);
    //     }
    //   });
    if (id) {
      let dt = [{ value: "กรุณาเลือกสนามกีฬา", text: "กรุณาเลือกสนามกีฬา" }];
      const respon = await getSubArena();
      respon.data.data.forEach((item, i) => {
        return dt.push({
          value: item.subArenaID,
          text: item.subArenaName,
        });
      });
      setDtSubArena(dt);
    }
  };
  const getAreaData = async () => {
    let dt = [];
    const respon = await getArena();
    respon.data.data.forEach((item, i) => {
      return dt.push({
        value: item.arenaID,
        text: item.arenaName,
      });
    });
    setDataArena(dt);
  };
  const getAreaSearchData = async (id) => {
    let dt = [];
    setSelectSubarena("ทั้งหมด");
    const respon = await getSubArenaSearch(id);
    if (respon.data.data.length > 0) {
      respon.data.data.forEach((item, i) => {
        return dt.push({
          value: item.subArenaID,
          text: item.subArenaName,
        });
      });
    } else {
      dt.push({
        value: "ไม่พบข้อมูล",
        text: "ไม่พบข้อมูล",
      });
    }

    setDtSubArena(dt);
    // setDtSubArena(dt);
  };
  const getDataSport = async () => {
    const respon = await getSport();
    let getData = [];
    // before
    // respon.data.data.forEach((item, i) => {
    //   return getData.push({
    //     value: item.sportID,
    //     text: item.sportName,
    //   });
    // });
    // after
    respon.data.data.forEach((item, i) => {
      return getData.push({
        value: item.sportID,
        label: item.sportName,
      });
    });
    setDtsport(getData);

    // setDtsport
  };

  let [inputForm, setInputForm] = useState({
    inp1: 0, //ชนิดกีฬา
    inp2: 0, //ประเภทกีฬา
    inp3: 0, //สนามกีฬาย่อย
    inp4: null, //ชื่อรายการแข่งขัน
    inp5: 1, //รอบการแข่งขัน
    inp6: 1, //ประเภท
    inp7: null, //Calendarpicker
    inp8: null, //Timepicker
    inp9: 0, //สังกัด
    inp10: null, //สนามกีฬา
  });
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditcompetschedule`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditcompetschedule?id=${id}`);
        break;
      case "view":
        // handle view button
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
      // console.log("Invalid button type");
    }
  };
  const deleteByID = (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (id) {
              setCompetSchedule((prev) => {
                const newSt = [...prev];

                for (let i = 0; i < newSt.length; i++) {
                  // const index = newSt[i].tabaledata.findIndex(
                  //   (item) => item.id.dataID !== id
                  // );
                  // if (index != -1) {
                  const newSt2 = newSt[i].tabaledata.filter(
                    (item) => item.id.dataID !== id
                  );

                  newSt[i].tabaledata = newSt2;
                }

                return newSt;
                // }
              });
            } else {
              let indexofgroup = competSchedule.findIndex(
                (x) => x.group == currentGroup.current
              );
              competSchedule[indexofgroup].tableselected.splice(
                competSchedule[indexofgroup].tableselected.indexOf(id),
                1
              );
              let columndatarow = masterData.current.filter(
                (item) =>
                  competSchedule[indexofgroup].tableselected.indexOf(
                    item.id.dataID
                  ) > -1
              );
              competSchedule[indexofgroup].tabaledata = columndatarow;
              setCompetSchedule([...competSchedule]);
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  function setCurrentGroup(group) {
    currentGroup.current = group;
  }
  function ChangesetValuefromparent(text) {
    setValuefromparent(text);
  }

  //ฟังก์ชันสลับเดี่ยว/ทีม
  function setinputsolo(value, text) {
    //debugger
    if (prevType.current == "ทั้งหมด") {
      //กรณี default ของ dropdown text จะเป็น ทั้งหมด ให้เปลี่ยนเป็น เดี่ยวแทน
      prevType.current = "เดี่ยว";
    }

    // if(value==1)
    //   {
    //     setIsSolo(true)
    //   }
    //   else{
    //     setIsSolo(false)
    //   }
    if (competCount.current > 0) {
      //alert บอกว่าข้อมูลจะหายทั้งหมด
      Swal.fire({
        title: "ข้อมูลที่เลือกจะหายทั้งหมดคุณต้องการที่จะเปลี่ยนใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ไม่ใช่",
        reverseButtons: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //clear all data

          competCount.current = 0;
          setCompetSchedule([]);
          if (value == 1) {
            //check เดี่ยวไหม
            setIsSolo(true);
          } else {
            setIsSolo(false);
          }
          prevType.current = text;
          prevTypeValue.current = value;
        } else {
          setforceRerender(!forceRerender);
          setValuefromparent(prevType.current); //เปลี่ยนค่าใน dropdown ให้กลับเป็นค่าก่อนหน้า
          setInputForm((prev) => {
            return {
              ...prev,
              inp6: prevTypeValue.current,
            };
          });

          //Swal.fire('Changes are not saved', '', 'info')
        }
      });
    } else {
      prevType.current = text;
      prevTypeValue.current = value;
      if (value == 1) {
        //เช็คว่าเป็นเดี่ยวไหม
        setIsSolo(true);
      } else {
        setIsSolo(false);
      }
    }
  }

  function setClear(data) {
    setIsClear(data);
  }

  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/competitionSchedule");
      }
    });
  }

  const enableButton = {
    view: false,
    edit: false,
    delete: true,
    add: false,
    approve: false,
  };

  const tempSelectedData = useRef([]);
  const masterData = useRef([]);
  const tempcompetSchedule = useRef([]);
  const lastestGrpSelected = useRef();
  //main data
  let competCount = useRef(1);
  const [competSchedule, setCompetSchedule] = useState([
    {
      group: competCount.current,
      formdata: {
        inp1: inputForm.inp1,
        inp2: inputForm.inp2,
        inp3: inputForm.inp3,
        inp4: inputForm.inp4,
        inp5: inputForm.inp5,
        inp6: inputForm.inp6,
        inp7: inputForm.inp7,
        inp8: inputForm.inp8,
        inp9: inputForm.inp9,
      },
      //tabledata:{datas:[],columns:[]}
      tabaledata: [], //add ข้อมูลรูปแบบเดียวกันกับ rowsdata ตรงนี้เพื่อให้แสดงผลในตาราง
      tableselected: [],
    },
  ]);
  const [currentGroupCheck, setCurrentGroupCheck] = useState([]);

  const handleAddBoxClick = (event) => {
    competCount.current++;
    let temp = {
      group: competCount.current,
      formdata: {
        inp1: inputForm.inp1,
        inp2: inputForm.inp2,
        inp3: inputForm.inp3,
        inp4: inputForm.inp4,
        inp5: inputForm.inp5,
        inp6: inputForm.inp6,
        inp7: inputForm.inp7,
        inp8: inputForm.inp8,
        inp9: inputForm.inp9,
      },
      //tabledata:{datas:[],columns:[]}
      tabaledata: [], //add ข้อมูลรูปแบบเดียวกันกับ rowsdata ตรงนี้เพื่อให้แสดงผลในตาราง
      tableselected: [],
    };

    setCompetSchedule([...competSchedule, temp]);
  };

  function addTemp(data) {
    tempSelectedData.current = data;
  }

  function confirmAddTemp(group) {
    const dtGroup = group;
    if (id && competSchedule[group].tabaledata.length > 0) {
      group = competSchedule[group].tabaledata.length + 1;
      let columndatarow = masterData.current.filter(
        (item) => tempSelectedData.current.indexOf(item.id.dataID) > -1
      );

      let getData = [...competSchedule];
      const values = getData[dtGroup].tabaledata.map((obj) => obj.id.value);
      let couter = Math.max(...values);
      for (let i = 0; i < columndatarow.length; i++) {
        const index = getData[dtGroup].tabaledata.findIndex((item) => {
          if (item.id.dataID === columndatarow[i].id.dataID) {
            return item;
          }
        });

        // dtGroup &&
        // dtGroup === i &&s
        if (dtGroup === i && index === -1) {
          couter++;
          getData[dtGroup].tabaledata.push({
            id: {
              value: couter,
              type: "text",
              dataID: columndatarow[i].id.dataID,
            },
            name: {
              value: columndatarow[i].name.value,
              type: "text",
            },
            idCard: {
              value: columndatarow[i].idCard.value,
              type: "text",
            },
            affiliation: {
              value: columndatarow[i].affiliation.value,
              dataAffID: columndatarow[i].affiliation.dataAffID,
              type: "text",
            },

            updateddate: {
              value: columndatarow[i].updateddate.value,
              type: "text",
            },
            action: { value: "1", type: "action" },
          });
        }
      }

      const seen = new Set();
      const filtered = [];

      setCompetSchedule(getData);
    } else {
      let columndatarow = masterData.current.filter(
        (item) => tempSelectedData.current.indexOf(item.id.dataID) > -1
      );
      let indexofgroup = competSchedule.findIndex((x) => x.group == group);
      competSchedule[indexofgroup].tableselected = tempSelectedData.current;
      competSchedule[indexofgroup].tabaledata = columndatarow;
      competSchedule[indexofgroup].formdata = {
        inp1: inputForm.inp1,
        inp2: inputForm.inp2,
        inp3: inputForm.inp3,
        inp4: inputForm.inp4,
        inp5: inputForm.inp5,
        inp6: inputForm.inp6,
        inp7: inputForm.inp7,
        inp8: inputForm.inp8,
        inp9: inputForm.inp9,
      };

      setCompetSchedule([...competSchedule]);
    }
  }

  function deleteRow(grpid, dataid) {
    //find data from rowsdata
    const temprowsdata = [];
    let dataindex = rowsdata.findIndex((data) => data.id.value == dataid);
    if (dataindex > -1) {
      //copy groupstate into temp
      let tempgroup = competSchedule;
      const grpindex = tempgroup.findIndex((grp) => grp.group == grpid);
      if (grpindex > -1) {
        tempgroup[grpindex].tabaledata.splice(dataindex, 1);
        setCompetSchedule([...tempgroup]);
      }
    }
  }
  // selectSport
  const selectSport = (id) => {
    getSubsport(id.value);
    setSelectDt(true);
    // getSubsportDtSearch();
    inputForm.inp1 = id.value;
    getDtAthlete(id.label);
    setInputForm(inputForm);
  };

  const selectSportSub = (id) => {
    inputForm.inp2 = id.value;
    setInputForm(inputForm);
  };
  const selectAff = async (id) => {
    setSelectId(id);
    const respon = await geteAthleteSearch(id);

    // let objectData = respon.data.data;
    // for (let i = 0; i < objectData.length; i++) {}
    let couter = 0;
    respon.data.data.forEach((objectData, i) => {
      couter++;
      return DataScheduleAthlete.datas.push({
        id: objectData.atheleteID,
        row: [
          {
            type: "checkbox",
            value: objectData.atheleteID,
          },
          {
            value: couter,
            // objectData.atheleteID,
            type: "text",
          },
          {
            value: objectData.firstname + "  " + objectData.lastname,
            type: "text",
          },
          { value: objectData.idcard, type: "text" },
          {
            value: objectData.affliation,
            dataAffID: objectData.affliationID,
            type: "text",
          },

          {
            value: objectData.updatedAt
              ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
              : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
            type: "text",
          },
        ],
      });
    });

    masterData.current = [];

    respon.data.data.forEach((objectData, i) => {
      couter++;
      masterData.current.push({
        id: { value: couter, type: "text", dataID: objectData.atheleteID },
        name: {
          value: objectData.firstname + objectData.lastname,
          type: "text",
        },
        idCard: {
          value: objectData.idcard,
          type: "text",
        },
        affiliation: {
          value: objectData.affliation,
          type: "text",
          dataAffID: objectData.affliationID,
        },
        updateddate: {
          value:
            objectData.updatedAt === null
              ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
              : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
          type: "text",
        },
        action: { value: "1", type: "action" },
      });
    });
    DataScheduleAthlete.datas.splice(0, 0);
    // setDtAtheTest({ ...dtAthetest });
    setDtAthe({ ...DataScheduleAthlete });
    // getDtAthlete();
  };

  const removeGroup = (group) => {
    const removeIndex = competSchedule.findIndex((e) => e.group === group);
    if (removeIndex !== -1) {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ไม่ใช่",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            swalWithBootstrapButtons.fire({
              title: "ลบรายการสำเร็จ!",
              icon: "success",
              confirmButtonText: "ตกลง",
            });
            if (result.isConfirmed) {
              competSchedule.splice(removeIndex, 1);
              setCompetSchedule([...competSchedule]);
              if (isSolo) {
                competCount.current = 0;
              }
            }
          }
        });
    }
  };

  const [groupShow, setGroupShow] = useState(false);
  const [isLoading, setLoading] = useState(null);
  function navigateToBack(page) {
    navigate(page);
  }
  // submitData
  const submitData = async () => {
    let dataArray = [];
    if (competSchedule[0] === undefined) {
      Swal.fire({
        // title: "กรุณากรอกข้อมูลนักกีฬาให้ครบถ้วน",
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "บันทึก",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    } else {
      let respon_data = competSchedule[0].tabaledata;

      if (id) {
        let spDta = dtSport.filter((item) => item.label === inputForm.inp1);
        let subSpDta = dtSubsport.filter(
          (item) => item.label === inputForm.inp2
        );

        let arenaDta = dtSubArena.filter(
          (item) => item.text === inputForm.inp3
        );
        for (let i = 0; i < competSchedule.length; i++) {
          for (let j = 0; j < competSchedule[i].tabaledata.length; j++) {
            dataArray.push({
              affliationId:
                competSchedule[i].tabaledata[j].affiliation.dataAffID,
              athlete: [
                { athletesId: competSchedule[i].tabaledata[j].id.dataID },
              ],
            });
          }
        }

        let bodydata = {};

        if (selectDt === true && typeof inputForm.inp2 === "string") {
          Swal.fire({
            title: "กรุณากรอกข้อมูลประเภทการแข่งขันให้ถูกต้อง",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "บันทึก",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          });
        } else {
          bodydata = {
            matchName:
              inputForm.inp4 !== "" || inputForm.inp4 !== null
                ? inputForm.inp4
                : null,
            // startTime:
            //   typeof inputForm.inp7 === "string"
            //     ? moment(new Date(inputForm.inp7)).format("YYYY-MM-DD 00:00:00")
            //     : moment(new Date(inputForm.inp7)).format(
            //         "YYYY-MM-DD HH:mm:ss"
            //       ),
            startTime:
              typeof inputForm.inp7 === "string"
                ? moment(new Date(inputForm.inp7)).format("YYYY-MM-DD ") +
                  moment(inputForm.inp8, "HH:mm").format("HH:mm:ss")
                : typeof inputForm.inp8 === "string"
                ? moment(new Date(inputForm.inp7)).format("YYYY-MM-DD ") +
                  moment(inputForm.inp8, "HH:mm").format("HH:mm:ss")
                : moment(new Date(inputForm.inp7)).format("YYYY-MM-DD ") +
                  moment(new Date(inputForm.inp8)).format("HH:mm:ss"),
            endTime:
              typeof inputForm.inp8 === "string"
                ? moment(new Date(inputForm.inp7)).format("YYYY-MM-DD ") +
                  moment(inputForm.inp8, "HH:mm")
                    .add(1, "hours")
                    .format("HH:mm:ss")
                : // inputForm.inp8
                  moment(new Date(inputForm.inp7)).format("YYYY-MM-DD ") +
                  moment(new Date(inputForm.inp8))
                    .add(1, "hours")
                    .format("HH:mm:ss"),
            sportId:
              typeof inputForm.inp1 === "number"
                ? inputForm.inp1
                : spDta[0].value,
            subsportId:
              typeof inputForm.inp2 === "number"
                ? inputForm.inp2
                : subSpDta[0].value,
            subarenaId:
              typeof inputForm.inp3 === "number"
                ? inputForm.inp3
                : arenaDta[0].value,
            competitionType:
              inputForm.inp6 === 2 || inputForm.inp6 === "team"
                ? "team"
                : "single",

            matchHeat:
              inputForm.inp5 === 1 ||
              inputForm.inp5 === "รอบชิง" ||
              inputForm.inp5 === "final"
                ? "final"
                : inputForm.inp5 === 2 ||
                  inputForm.inp5 === "รอง" ||
                  inputForm.inp5 === "semi"
                ? "semi"
                : inputForm.inp5 === 3 ||
                  inputForm.inp5 === "คัดเลือก" ||
                  inputForm.inp5 === "qualify"
                ? "qualify"
                : "",
            affliations: dataArray,
          };
        }

        if (
          bodydata.matchName === "" ||
          bodydata.startTime === "1970-01-01 07:00:00" ||
          bodydata.endTime === "1970-01-01 07:00:00" ||
          bodydata.sportId === 0 ||
          bodydata.subsportId === 0 ||
          bodydata.matchHeat === "" ||
          bodydata.affliations.length === 0
        ) {
          Swal.fire({
            title: "กรุณากรอกข้อมูลให้ครบถ้วน",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "บันทึก",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          });
        } else {
          Swal.fire({
            title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "บันทึก",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              (async () => {
                setLoading(true);
                setSelectDt(false);
                // const respon = null;
                const respon = await updateMatch(id, bodydata);
                setLoading(false);
                if (respon.data.status === "success") {
                  Swal.fire({
                    title: "แก้ไขข้อมูลสำเร็จ",
                    icon: "success",
                    confirmButtonText: "ตกลง",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/competitionSchedule");
                    }
                  });
                }
              })();
            }
          });
        }
      } else {
        for (let i = 0; i < competSchedule.length; i++) {
          for (let j = 0; j < competSchedule[i].tabaledata.length; j++) {
            for (let k = 0; k < dtAff.length; k++) {
              if (
                dtAff[k].text ===
                competSchedule[i].tabaledata[j].affiliation.value
              ) {
                dataArray.push({
                  affliationId: dtAff[k].value,
                  athlete: [
                    { athletesId: competSchedule[i].tabaledata[j].id.dataID },
                  ],
                });
              }
            }
          }
        }

        let bodydata = {
          matchName: inputForm.inp4,
          startTime:
            moment(new Date(inputForm.inp7)).format("YYYY-MM-DD") +
            moment(new Date(inputForm.inp8)).format(" HH:mm:ss"),

          endTime:
            moment(new Date(inputForm.inp7)).format("YYYY-MM-DD") +
            moment(new Date(inputForm.inp8))
              .add(1, "hours")
              .format(" HH:mm:ss"),
          sportId: inputForm.inp1,
          subsportId: inputForm.inp2,
          subarenaId: inputForm.inp3,
          competitionType:
            inputForm.inp6 === 2 || inputForm.inp6 === "team"
              ? "team"
              : "single",

          matchHeat:
            inputForm.inp5 === 1
              ? "final"
              : inputForm.inp5 === 2
              ? "semi"
              : inputForm.inp5 === 3
              ? "qualify"
              : "",
          // inputForm.inp5,
          affliations: dataArray,
        };

        if (
          bodydata.matchName === "" ||
          bodydata.startTime === "1970-01-01 07:00:00" ||
          bodydata.endTime === "1970-01-01 07:00:00" ||
          bodydata.sportId === 0 ||
          bodydata.subarenaId === 0 ||
          bodydata.subsportId === 0 ||
          bodydata.matchHeat === "" ||
          bodydata.affliations.length < 1
        ) {
          Swal.fire({
            title: "กรุณากรอกข้อมูลให้ครบถ้วน",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "บันทึก",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          });
        } else {
          Swal.fire({
            title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "บันทึก",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              (async () => {
                setLoading(true);
                // const respon = null;
                const respon = await createMatch(bodydata);
                setLoading(false);
                if (respon.data.status === "success") {
                  Swal.fire({
                    title: "บันทึกข้อมูลสำเร็จ",
                    icon: "success",
                    confirmButtonText: "ตกลง",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/competitionSchedule");
                    }
                  });
                }
              })();
            }
          });
        }
      }
      //
    }
  };
  const [selectedTime, setSelectedTime] = useState("00:00");

  function handleTimeChange(event) {
    setSelectedTime(event.target.value);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/competitionSchedule");
      }
    });
  }
  // *********************** Take Import DATA ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const handleImport = async (rows) => {
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);

    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          sportName: item[0],
          subsportName: item[1],
          arenaName: item[2],
          subArenaName: item[3],
          matchName: item[4],
          matchHeat: item[5],
          competitionType: item[6],
          date: item[7],
          time: item[8],
          firstname: item[9],
          lastname: item[10],
          idcard: item[11],
          affliationName: item[12],
          sector: item[13],
        });
      }
    });
    await setImportData(row);
  };
  let dt = {
    matchName: "4x100 หญิง",
    date: "2023-04-17",
    time: "08:30-16:30",
    sportName: "กระบี่กระบอง",
    subsportName: "test",
    arenaName: "นอนอน",
    subArenaName: "test03",
    matchHeat: "รอบชิงชนะเลิศ",
    affliationName: "กรุงเทพมหานคร",
    competitionType: "เดี่ยว",
    sector: "ภาค 1",
    firstname: "ธนวัฒน์",
    lastname: "ยะกิจ",
    idcard: "1111",
  };
  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length - 1);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          importData: data,
        };
        const response = await verifyImport(dataToVerify);

        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.failed;
          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      if (error === 0) {
        Swal.fire({
          title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
          icon: "success",
          confirmButtonText: "ตกลง",
        });
        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      } else {
        error++;
        Swal.fire({
          title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
        return;
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importMatch = async () => {
    try {
      setVerifySuccess(0);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length - 1);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              importData: data,
            };
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      if (error === true) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/competitionSchedule");
          }
        });
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/competitionSchedule");
          }
        });
      }
      // if (error === 0) {
      //   Swal.fire({
      //     title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
      //     icon: "success",
      //     confirmButtonText: "ตกลง",
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       setIsVerify(false);
      //       navigateToBack("/competitionSchedule");
      //     }
      //   });
      // } else {
      //   Swal.fire({
      //     title: "เกิดข้อผิดพลาด",
      //     icon: "error",
      //     confirmButtonText: "ตกลง",
      //   });
      // }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/competitionSchedule");
        }
      });
    }
  };
  // *********************** Take Import DATA ************************************
  const columnsname = [
    "id",
    "name",
    "idCard",
    "affiliation",
    // "matchDay",
    // "matchTime",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อ",
    "เลขบัตรประชาชน",
    "สังกัด",
    // "วันที่แข่งขัน",
    // "เวลาที่แข่งขัน",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    // "text",
    // "text",
    "text",
    "action",
  ];
  const rowsdata = [
    {
      id: { value: 1, type: "text" },
      name: { value: "นางสาวเทควันโด ดีงาม", type: "text" },
      idCard: { value: "1049658696667", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      matchDay: { value: "7/5/2022", type: "text" },
      matchTime: { value: "13:30 PM", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
      action: { value: "1", type: "action" },
    },
  ];

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ตารางการแข่งขัน"} />
      <Menu parentmenu={"competitionData"} activemenu={"competitionSchedule"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-competition.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ตารางการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการแข่งขัน / ตารางการแข่งขัน
              </font>
            </div>
            <div
              className={`col-4 col-sm-3 justify-content-end align-items-center ${
                !groupShow ? "d-flex" : "d-none"
              } `}
            >
              <button
                className="btn-blue03 mr-2 mr-xl-3 w-100"
                onClick={() => setGroupShow(true)}
              >
                <img
                  src="assets/icons/ic-add.png"
                  alt="ExportFiles Icon"
                  className="ic-add"
                />
                <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                  เพิ่มข้อมูลหลายรายการ
                </span>
              </button>
            </div>
            <div
              className={`col-4 col-sm-3 justify-content-end align-items-center ${
                groupShow ? "d-flex" : "d-none"
              } `}
            >
              <button
                className="btn-blue03 mr-2 mr-xl-3 w-100"
                onClick={() => setGroupShow(false)}
              >
                <img
                  src="assets/icons/ic-add.png"
                  alt="ExportFiles Icon"
                  className="ic-add"
                />
                <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                  เพิ่มข้อมูลรายการเดียว
                </span>
              </button>
            </div>
          </div>
          <div className={`${!groupShow ? "" : "d-none"} `}>
            <div className={`card-white`}>
              <div className="row">
                <div className="col-12">
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>เพิ่มข้อมูลทีละรายการ
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-0">
                      ชนิดกีฬา
                    </p>

                    <Select
                      defaultValue={selectedSport[0]}
                      options={dtSport}
                      func={setsubtype_data}
                      setinput={setinput}
                      inputname={"inp1"}
                      onChange={(e) => selectSport(e)}
                    />
                    {/* <Dropdown
                      valuefromparent={selectedSport}
                      options={dtSport}
                      func={setsubtype_data}
                      setinput={setinput}
                      inputname={"inp1"}
                      onChange={(e) => selectSport(e)}
                      // onChange={selectSport}
                    /> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-0">
                      ประเภทการแข่งขัน
                    </p>
                    {/* <Dropdown
                      valuefromparent={selectedMatch}
                      options={dtSubsport}
                      setinput={setinput}
                      inputname={"inp2"}
                    /> */}
                    <Select
                      defaultValue={selectedMatch[0]}
                      options={dtSubsport}
                      setinput={setinput}
                      inputname={"inp2"}
                      onChange={(e) => selectSportSub(e)}
                    />
                  </div>
                </div>
                {/* <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      ประเภท
                    </p>
                    <Dropdown
                      options={DataDropdownCompetType}
                      setinput={setinput}
                      inputname={"inp6"}
                      setissolo={setinputsolo}
                      ChangesetValuefromparent={ChangesetValuefromparent}
                      valuefromparent={selectedMatchtype}
                    />
                  </div>
                </div> */}
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      สนามกีฬา
                    </p>

                    <Dropdown
                      onChange={(e) => getAreaSearchData(e)}
                      valuefromparent={selecteArena}
                      options={getDataArena}
                      setinput={setinput}
                      inputname={"inp10"}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      สนามกีฬาย่อย
                    </p>

                    <Dropdown
                      onChange={(e) => getAreaSub(e)}
                      valuefromparent={selectedSubarena}
                      options={dtSubArena}
                      setinput={setinput}
                      inputname={"inp3"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                  <input
                    className="inp-data01 fontReg font-size03"
                    placeholder="ชื่อรายการแข่งขัน"
                    defaultValue={inputForm.inp4}
                    onBlur={(e) => setinput("inp4", e.target.value)}
                  />
                </div>
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      รอบการแข่งขัน
                    </p>
                    <Dropdown
                      options={DataDropdownCompetitionRound}
                      setinput={setinput}
                      inputname={"inp5"}
                      valuefromparent={selectedMatchHeat}

                      // setissolo={setinputsolo}
                      // ChangesetValuefromparent={ChangesetValuefromparent}
                      // valuefromparent={selectedMatchtype}
                    />
                  </div>
                  {/* <input
                    className="inp-data01 fontReg font-size03"
                    placeholder="รอบการแข่งขัน"
                    defaultValue={inputForm.inp5}
                    onBlur={(e) => setinput("inp5", e.target.value)}
                  /> */}
                </div>
                {/* <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      ประเภท
                    </p>
                    <Dropdown
                      options={DataDropdownCompetType}
                      setinput={setinput}
                      inputname={"inp6"}
                      setissolo={setinputsolo}
                      ChangesetValuefromparent={ChangesetValuefromparent}
                      valuefromparent={selectedMatchtype}
                    />
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                  <div className="drpdw-status">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      ประเภท
                    </p>
                    <Dropdown
                      options={DataDropdownCompetType}
                      setinput={setinput}
                      inputname={"inp6"}
                      setissolo={setinputsolo}
                      ChangesetValuefromparent={ChangesetValuefromparent}
                      valuefromparent={selectedMatchtype}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                  <Calendarpicker
                    setDatePicker={setinput}
                    ChangesetValueDate={ChangesetValueDate}
                    inputname={"inp7"}
                    inputDate={inputDate}
                    dataDate={inputForm.inp7}
                  />
                </div>
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                  <Timepicker
                    setTimePicker={setinput}
                    inputname={"inp8"}
                    onChange={handleTimeChange}
                    dataTime={inputForm.inp8}
                    ChangesetValueDate={ChangesetValueDate}
                  />
                </div>
                <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                  <button
                    className={`btn-blue06 pt-3 pb-3 ${
                      isSolo && competCount.current > 0 ? "d-none" : ""
                    } `}
                    onClick={() => {
                      setIsClear(true);
                      handleAddBoxClick();
                    }}
                  >
                    <span className="fontReg font-size03 colorWhite">
                      เพิ่มกลุ่มการแข่งขัน
                    </span>
                  </button>
                  {/* <button
                    className="btn-blue06 pt-3 pb-3"
                    onClick={()=>console.log(competSchedule)}
                  ></button> */}
                </div>
              </div>
            </div>

            {competSchedule.map((data, index) => {
              return (
                <div className="card-white mt-3" key={data.group}>
                  <div className="row">
                    <div className="col-12">
                      <p className="fontReg font-weight-bold font-size03 colorGray01">
                        <span className="border-left01"></span>
                        รายการนักกีฬาที่เลือก
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-end mb-3">
                    <div className="col-4 col-sm-3 col-lg-2 d-flex justify-content-end">
                      <button
                        className="btn-blue06 pt-2 pb-2"
                        data-toggle="modal"
                        data-target="#addAthleteModal"
                        onClick={() => {
                          currentGroup.current = data.group;
                          let indexofgroup = competSchedule.findIndex(
                            (x) => x.group == currentGroup.current
                          );
                          setCurrentGroupCheck(
                            competSchedule[indexofgroup].tableselected
                          );
                          // addDataTable();
                          //setIsClear(true);
                        }}
                      >
                        <span className="fontReg font-size03 colorWhite">
                          เพิ่มนักกีฬา
                        </span>
                      </button>
                    </div>
                    <div className="col-4 col-sm-3 col-lg-2 d-flex justify-content-end">
                      <button
                        className="btn-red01 pt-2 pb-2"
                        onClick={(e) => {
                          removeGroup(data.group);
                        }}
                      >
                        <span className="fontReg font-size03 colorWhite">
                          ลบรายการ
                        </span>
                      </button>
                    </div>
                  </div>
                  <TableData
                    actionsBtn={enableButton}
                    columnsname={columnsname}
                    columnsText={columnsText}
                    columnsType={columnsType}
                    rowsdata={data.tabaledata}
                    isSearchEnable={false}
                    deleteRow={deleteRow}
                    group={data.group}
                    setCurrentGroup={setCurrentGroup}
                    onButtonClicked={handleButtonClicked}
                  />
                </div>
              );
            })}
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  onClick={() => {
                    submitData();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/014-match_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                {/* <UploadFileXLS limit={1} onChange={handleFileChange}/> */}
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                {/* <span className='mx-2'>{verifySuccess}/{totalRow}</span> */}
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0 ? true : false}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            {/* <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <ImportExcel onImport={handleImport} />
              </div>
            </div> */}
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importMatch}
                >
                  บันทึก
                </button>
              </div>
            </div>
            <div
              className={`card-white mt-3  ${
                failedRow.length > 0 ? "d-block" : "d-none"
              } `}
            >
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
              </p>

              <p
                style={{ fontSize: `20px`, marginBottom: `30px` }}
                className="fontReg font-weight-bold font-size03 colorRed"
              >
                ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
              </p>
              {failedRow.map((item) => (
                <div className="row">
                  <div className="col-auto justify-content-start align-items-center m-0">
                    <span
                      style={{ fontSize: `18px` }}
                      className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                    >
                      ข้อมูลรายการที่: {item.key + 1}{" "}
                    </span>
                  </div>
                  <div className="col-9 justify-content-start align-items-center m-0">
                    <span
                      style={{ fontSize: `18px` }}
                      className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                    >
                      กรุณาตรวจสอบคอลัมน์ {item.result}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="addAthleteModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="addAthleteModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ค้นหาข้อมูลนักกีฬา
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {/* <div className="row">
                    <div className="col-12">
                      <div className="drpdw-sportName">
                        <p className="fontReg font-size03 colorGray02 mb-1">
                          สังกัด
                        </p>
                        <Dropdown
                          onChange={(e) => selectAff(e)}
                          options={dtAff}
                          setinput={setinput}
                          inputname={"inp9"}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="row mt-3">
                    {/* selectId: {selectId}
                    currentGroup.current :{currentGroup.current}
                    currentGroupCheck : {currentGroupCheck} */}
                    <div className="col-12">
                      {/* <TableDataCheckbox item={dtAthe} /> */}
                      <TableNoPagination
                        id="tableAthlete"
                        item={dtAthe}
                        actionsBtn={enableButton}
                        group={currentGroup.current}
                        isClear={isClear}
                        setClear={setClear}
                        searching={true}
                        addTemp={addTemp}
                        currentGroupCheck={currentGroupCheck}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer fontReg font-size03 colorGray01">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    ยกเลิก
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => confirmAddTemp(currentGroup.current)}
                  >
                    ยืนยัน
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditcompetschedule;
