import React, { useState } from 'react'
import './Forgotpassword.css';

function EmailForgotpassword() {

    const [email, setEmail] = useState(null)

  return (
    <>
      <nav className=" navbar navbar-expand navbar-white navbar-light">
        <div className="container">
          <img
            src="assets/images/im-logoNW.png"
            alt="NW-Sports Logo"
            className="logoNW-fp img-circle"
          />
          <span className="fontReg font-weight-bold font-size01 line-height01 colorViolet02 d-none d-sm-inline-block">
            ระบบจัดการข้อมูลงานกีฬานครสวรรค์
          </span>
          <span className="fontReg font-weight-bold font-size02 line-height01 ml-2 colorGray01">
            ตั้งรหัสผ่านใหม่
          </span>
        </div>
      </nav>
      <div className="containerFluid">
        <div className="d-flex align-items-center justify-content-center">
          <div className="card-fp">
            <div className="col-12 d-flex justify-content-center">
              <span className="fontReg font-weight-bold font-size01 line-height01 colorGray01">
                ตั้งรหัสผ่านใหม่
              </span>
            </div>
            <form className="form">
              <div className="row mt-3">
                <div className="col-12 col-sm-2  d-flex align-items-center">
                  <span className="fontReg font-size03 line-height01 colorGray01">
                    อีเมล
                  </span>
                </div>
                <div className="col-12 col-sm-10">
                  <div className="inp-field">
                    <input
                      className="inp-login fontLight font-size03"
                      placeholder="abcd@gmail.com"
                      onChange={(e) => {setEmail(e.target.value)}}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className='col-5 mx-2'></div>
              <div className="col-3 col-sm-3 p-0 mt-3 align-items-center">
                <button type="submit" className="btn-blue fontReg font-weight-bold font-size03">ยืนยัน</button>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailForgotpassword