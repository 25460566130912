import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button3items from "../button/Button3items";
import { useNavigate } from "react-router-dom";
import Dropdown from "../dropdowns/Dropdown";
import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import Loading from "./Loading";
import { getDataUser } from "../config/configApi-User";
// import {
//   DataDropdownAffiliation,
//   DataDropdownStatus,
//   DataDropdownZone,
// } from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import {
  getAffliation,
  getAffliationById,
  deleteAffliation,
  getAffliationFilter,
} from "../../api/affliation_api";

function Masteraffiliation() {
  let DataDropdownAffiliation = [{ value: "0", text: "ทั้งหมด" }];
  const [dataDropAff, setDataDropAff] = useState([]);
  const [dataDropZone, setDataDropZone] = useState([]);
  const [spanloding, setSpanLoding] = useState(false);
  let DataDropdownZone = [{ value: "", text: "ทั้งหมด" }];
  let DataDropdownStatus = [
    { value: "", text: "ทั้งหมด" },
    { value: true, text: "ใช้งาน" },
    { value: false, text: "ไม่ใช้งาน" },
  ];
  const [inputForm, setInputForm] = useState({
    inp1: "", //ภาค
    inp2: "", //สังกัด
    inp3: "", //สถานะ
  });
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const columnsname = [
    "id",
    "zone",
    "affiliation",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ภาค",
    "สังกัด",
    "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];
  const [dataAffliation, setDataAffliation] = useState([]);
  const [dataAffID, setDataAffID] = useState([]);
  const rowsdata = [
    // {
    //   id: { value: "1", type: "text" },
    //   zone: { value: "ภาค 1", type: "text" },
    //   affiliation: { value: "กรุงเทพมหานคร", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];

  const getAffFilter = async () => {
    setSpanLoding(true);
    if (selectedZone === "" && selectedAff === "" && selectedStatus === "") {
      await setSpanLoding(false);
      getDataAffliation();
    } else {
      let bodyfilter = {
        inp1: selectedZone,
        inp2: selectedAff,
        inp3: selectedStatus,
      };
      const respon = await getAffliationFilter(bodyfilter);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: {
            value: i + 1,
            type: "text",
            dataID: objectData.affliationID,
          },
          affiliation: {
            value:
              objectData.affliationName === null
                ? "-"
                : objectData.affliationName,
            type: "text",
          },
          zone: {
            value: objectData.sector === null ? "-" : objectData.sector,
            type: "text",
          },
          status: {
            value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            // value: "-",
            active: true,
            // active: objectData.active,
            type: "text",
          },

          updateddate: {
            value:
              objectData.updatedAt !== null
                ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
                : objectData.createdAt === null && objectData.updatedAt === null
                ? "-"
                : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });
      setSpanLoding(false);
      setDataAffliation(rowsdata);

      // setDataMockArena(rowsdata);
    }
  };
  const getDataAffliation = async () => {
    setSpanLoding(true);
    try {
      const respon = await getAffliation();

      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: {
            value: i + 1,
            type: "text",
            dataID: objectData.affliationID,
          },
          affiliation: {
            value:
              objectData.affliationName === null
                ? "-"
                : objectData.affliationName,
            type: "text",
          },
          zone: {
            value: objectData.sector === null ? "-" : objectData.sector,
            type: "text",
          },
          status: {
            // value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            value: objectData.active === false ? "ไม่ใช้งาน" : "ใช้งาน",
            active: true,
            // active: objectData.active,
            type: "text",
          },

          updateddate: {
            // value: moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
            value:
              objectData.updatedAt !== null
                ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
                : objectData.createdAt === null && objectData.updatedAt === null
                ? "-"
                : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });
      // respon.data.data.forEach((data, i) => {
      //   return DataDropdownAffiliation.push({
      //     value: i + 1,
      //     text: data.sector,
      //   });
      // });
      // respon.data.data.forEach((data, i) => {
      //   return DataDropdownZone.push({
      //     value: i + 1,
      //     text: data.affliationName,
      //   });
      // });

      // getFilterdropdownZone(DataDropdownZone);
      // getFilterdropdownAff(DataDropdownAffiliation);
      await setSpanLoding(false);
      setDataAffliation(rowsdata);
      //
      let dataType = [{ value: "", text: "ทั้งหมด" }];
      respon.data.data.forEach((item, i) => {
        return dataType.push({
          value: item.affliationID,
          text: item.affliationName,
          subType: [],
        });
      });
      setDataDropAff(dataType);
      // dropdown
      const uniqueSectors = [];
      const result = [];

      respon.data.data.forEach(function (item) {
        if (!uniqueSectors.includes(item.sector)) {
          uniqueSectors.push(item.sector);
          result.push(item);
        }
      });

      let dataTypeDr = [{ value: "", text: "ทั้งหมด" }];
      result.forEach((item, i) => {
        return dataTypeDr.push({
          value: item.sector,
          text: item.sector,
          subType: [],
        });
      });
      setDataDropZone(dataTypeDr);
    } catch (error) {
      console.log(error.message);
    }
  };
  // const getFilterdropdownAff = async (data) => {
  //   var dataArr = data.map((item) => {
  //     return [item.text, item];
  //   }); // creates array of array
  //   var maparr = new Map(dataArr); // create key value pair from array of array
  //   var result = [...maparr.values()]; //converting back to array from mapobject
  //   setDataDropZone(result);
  // };

  const handleExport = async (type) => {
    let Header = ["ลำดับที่", "ภาค", "สังกัด", "สถานะ", "อัปเดตล่าสุด"];
    let Data = dataAffliation.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.affiliation.value;
      dt[2] = elemento.zone.value;
      dt[3] = elemento.status.value;
      dt[4] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานสังกัด",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
      default:
    }
  };
  // const getFilterdropdownZone = async (data) => {
  //   var dataArr = data.map((item) => {
  //     return [item.text, item];
  //   }); // creates array of array
  //   var maparr = new Map(dataArr); // create key value pair from array of array
  //   var result = [...maparr.values()]; //converting back to array from mapobject
  //   setDataDropAff(result);
  // };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/Addeditaffiliation`)
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditaffiliation?id=${id}`);
        // navigate(`/Addeditathlete/?id=${id}`)
        break;
      case "view":
        // handle view button
        viewModal(id);
        getAffiliationtID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteAffByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const viewModal = async (id) => {
    try {
    } catch (error) {}
  };

  const getAffiliationtID = async (Id) => {
    let getData = [];
    const respon = await getAffliationById(Id);

    getData.push({
      id: respon.data.data.affliationID,

      affiliation:
        respon.data.data.affliationName === null
          ? "-"
          : respon.data.data.affliationName,
      zone: respon.data.data.sector === null ? "-" : respon.data.data.sector,

      status: respon.data.data.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
      // {
      //   // value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",

      //   active: true,
      //   // active: objectData.active,
      //   type: "text",
      // },

      updateddate: "-",
      // value: moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
    });

    setDataAffID(getData[0]);
    // setDataImage(getData[0].image);
    // setDatasubArea(getData[0].subArea);
  };

  const deleteAffByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteAffliation(id);

            if (response.data.status === "success") {
              getDataAffliation();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getDataAffliation();
    // affiliationDropdown();
    // zoneDropdown();
  }, []);

  const affiliationDropdown = async () => {
    try {
      const response = await getAffliation();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.affliationID,
            text: item.affliationName,
            subType: [],
          });
        });
        setDataDropAff(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const zoneDropdown = async () => {
    try {
      const response = await getAffliation();
      if (response.status == 200) {
        const uniqueSectors = [];
        const result = [];

        response.data.data.forEach(function (item) {
          if (!uniqueSectors.includes(item.sector)) {
            uniqueSectors.push(item.sector);
            result.push(item);
          }
        });

        let dataType = [{ value: "", text: "ทั้งหมด" }];
        result.forEach((item, i) => {
          return dataType.push({
            value: item.sector,
            text: item.sector,
            subType: [],
          });
        });
        setDataDropZone(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedAff, setSelecteAff] = useState("");
  const [selectedZone, setSelecteZone] = useState("");
  const [selectedStatus, setSelecteStatus] = useState("");

  const handleAffChange = (value) => {
    setSelecteAff(value);
  };

  const handleZoneChange = (value) => {
    setSelecteZone(value);
  };

  const handleStatusChange = (value) => {
    setSelecteStatus(value);
  };

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [valuefromparent3, setValuefromparent3] = useState("ทั้งหมด");

  function resetDropdown() {
    setSelecteAff("");
    setSelecteZone("");
    setSelecteStatus("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setValuefromparent3("ทั้งหมด");
    getDataAffliation();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValuefromparent3(text) {
    setValuefromparent3(text);
  }
  return (
    <>
      {spanloding === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"สังกัด"} />
      <Menu parentmenu={"masterData"} activemenu={"affiliation"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                สังกัด
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / สังกัด
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditaffiliation"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-sm-3 pr-sm-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">ภาค</p>
                  <Dropdown
                    options={dataDropZone}
                    setinput={setinput}
                    inputname={"inp1"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleZoneChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สังกัด</p>
                  <Dropdown
                    options={dataDropAff}
                    setinput={setinput}
                    inputname={"inp2"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleAffChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สถานะ</p>
                  <Dropdown
                    options={DataDropdownStatus}
                    setinput={setinput}
                    inputname={"inp3"}
                    ChangesetValuefromparent={ChangesetValuefromparent3}
                    valuefromparent={valuefromparent3}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(event) => getAffFilter()}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-sm-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการสังกัด
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Addeditaffiliation"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataAffliation}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูล
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ภาค: {dataAffID.zone}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สังกัด: {dataAffID.affiliation}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ : {dataAffID.status}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Masteraffiliation;
