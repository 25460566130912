import React from 'react'
import './ShowTextFile.css'

function ShowTextFile(props) {
    const { file, removeFile } = props;
  return (
    <div className="thumb-file">
        <div className="row center-box">
          <div className="col-11">
            <div className="thumbInner">
              <span>{file.name}</span>
            </div>
          </div>
          <div className="col-1">
            <button className="remove-file" onClick={() => removeFile(file.id)}>
              <img
                src="assets/icons/ic-add.png"
                alt="ExportFiles Icon"
                className="ic-remove-img"
              />
            </button>
          </div>
        </div>
      
    </div>
  );
}

export default ShowTextFile