import React, { useEffect, useState } from "react";
import moment from "moment";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import { DataDropdownStoreType } from "../dataMockup/DataMockDropdown";
import Calendarpicker from "../calendars/Calendarpicker";
import TableData from "../Tables/TableData";
// import { getCampaign ,getCampaignById, deleteCampaign} from '../../api/campaign_api';
import {
  getCampaignHistory,
  getCampaignHistoryFilter,
} from "../../api/campaign_api";
import Loading from "../pages/Loading";

import { useNavigate } from "react-router-dom";
import Button3items from "../button/Button3items";
import { logDOM } from "@testing-library/react";

function DatacampaignHist() {
  // const [rowsdata, setRowsdata ] = useState([])
  let [inputForm, setInputForm] = useState({
    inp1: "", //ชื่อแคมเปญ
    inp2: "", //ประเภทร้านค้า
    inp3: "", //Calendarpicker
  });
  const [selectCampaign, setSelecteCampaign] = useState(null);
  // handleCampaignChange
  const handleCampaignChange = (value) => {
    setSelecteCampaign(value);
  };
  const getList = async () => {
    try {
      // const response = await getCampaign()
      // if(response){
      //   const campaignData = response.data.data
      //   const results = campaignData.map((row,i) => {
      //       const rowData = {
      //         "id":{ value: i+1,dataID:row?., type: "text" },
      //         "storeName":{ value: row?., type: "text" },
      //         "storeType":{ value: row?. , type: "text" },
      //         "campName":{ value: row?., type: "text" },
      //         "promotionID":{ value: row?., type: "text" }
      //       }
      //       return rowData
      //   })
      //   setRowsdata(results)
      // }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [rowsdata, setRowdata] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const DataDropdownShopBy = [
    { value: "", text: "ทั้งหมด" },
    { value: "1", text: "ร้านค้า" },
    { value: "2", text: "ร้านอาหาร" },
    { value: "3", text: "ร้านค้า/ร้านอาหาร" },
  ];
  async function getHis() {
    setLoading(true);
    try {
      const respon = await getCampaignHistory();

      if (respon.status === 200) {
        const his = respon.data.data;
        const data = [];
        for (let index = 0; index < his.length; index++) {
          const element = his[index];
          // const dateTime = moment(element.updatedAt).format("DD/MM/YY HH:mm");
          const rowdata = {
            id: { value: index + 1, type: "text" },
            storeName: { value: element.shopName, type: "text" },
            storeType: { value: element.shopType, type: "text" },
            campName: { value: element.campaignName, type: "text" },
            updateddate: {
              value:
                element.updatedAt !== null
                  ? moment(new Date(element.updatedAt)).format("YYYY-MM-DD")
                  : moment(new Date(element.createdAt)).format("YYYY-MM-DD"),
              type: "text",
            },
          };
          data.push(rowdata);
        }
        await setLoading(false);
        setRowdata(data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getHisFilter() {
    setLoading(true);
    try {
      let dateTime = inputForm.inp3
        ? moment(inputForm.inp3).format("YYYY-MM-DD")
        : "";
      let campaignName = inputForm.inp1;
      let shopType = selectCampaign;
      // inputForm.inp2;

      const query = {
        dateTime: dateTime,
        campaignName: campaignName,
        shopType: shopType,
      };
      const respon = await getCampaignHistoryFilter(query);
      if (respon.status === 200) {
        const his = respon.data.data;

        const data = [];
        for (let index = 0; index < his.length; index++) {
          const element = his[index];
          // const dateTime = moment(element.updatedAt).format("DD/MM/YY HH:mm");
          const rowdata = {
            id: { value: index + 1, type: "text" },
            storeName: { value: element.shopName, type: "text" },
            storeType: { value: element.shopType, type: "text" },
            campName: { value: element.campaignName, type: "text" },
            updateddate: {
              value:
                element.updatedAt !== null
                  ? moment(new Date(element.updatedAt)).format("YYYY-MM-DD")
                  : moment(new Date(element.createdAt)).format("YYYY-MM-DD"),
              type: "text",
            },
          };
          data.push(rowdata);
        }
        await setLoading(false);
        setRowdata(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const navigate = useNavigate();

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/Addapprovecampaign`)
        break;
      case "edit":
        // handle edit button
        // navigate(`/Addapprovecampaign?id=${id}`)
        break;
      case "view":
        // handle view button
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const handleExport = async (type) => {
    let Header = [
      "No",
      "ชื่อร้านค้า",
      "ประเภทร้านค้า",
      "ชื่อเเคมเปญ",
      "อัปเดตล่าสุด",
    ];

    let Data = rowsdata.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.storeName.value;
      dt[2] = elemento.storeType.value;
      dt[3] = elemento.campName.value;
      dt[4] = elemento.updateddate.value;
      return dt;
    });

    const date = new Date();
    const dateTime = moment(date).format("DD/MM/YY HH:mm");

    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานประวัติการใช้แคมเปญ",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const columnsname = [
    "id",
    "storeName",
    "storeType",
    "campName",
    "updateddate",
  ];
  const columnsText = [
    "No",
    "ชื่อร้านค้า",
    "ประเภทร้านค้า",
    "ชื่อแคมเปญ",
    "อัปเดตล่าสุด",
  ];
  const columnsType = ["text", "text", "text", "text", "text"];
  // const rowsdata = [
  //   {
  //     id: { value: "1", type: "text" },
  //     storeName: { value: "ร้านน้องน้ำ", type: "text" },
  //     storeType: { value: "ร้านอาหาร", type: "text" },
  //     campName: { value: "แคมเปญ01", type: "text" },
  //     updateddate: { value: "7/5/2022", type: "text" },
  //   },
  // ];

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [name, setName] = useState("");
  const [inputDate, setInputDate] = useState("");

  function resetDropdown() {
    setName("");
    setInputDate("");
    inputForm.inp1 = null;
    inputForm.inp2 = null;
    inputForm.inp3 = null;
    // const [inputForm, setInputForm] = useState({
    //   inp1: "", //ชื่อแคมเปญ
    //   inp2: "", //ประเภทร้านค้า
    //   inp3: "", //Calendarpicker
    // });

    setInputForm(inputForm);
    setValuefromparent1("ทั้งหมด");
    setSelecteCampaign("");
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
    getHis();
  }

  function searchData() {
    var dateTime = moment(inputForm.inp3).format("DD-MM-YYYY");
    const campaignName = inputForm.inp1;
    const shopType = inputForm.inp2;
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };

  function ChangesetValueDate(value) {
    setInputDate(value);
  }

  useEffect(() => {
    getHis();
  }, []);

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ประวัติการใช้แคมเปญ"} />
      <Menu parentmenu={"campaignData"} activemenu={"campaignHist"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-campaign.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประวัติการใช้แคมเปญ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลแคมเปญ / ประวัติการใช้แคมเปญ
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อแคมเปญ"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-12 col-lg-3 pr-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ประเภทร้านค้า
                  </p>
                  <Dropdown
                    options={DataDropdownShopBy}
                    setinput={setinput}
                    inputname={"inp2"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleCampaignChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-3 pr-0 mt-lg-0 mt-2">
                <Calendarpicker
                  dataDate={null}
                  setDatePicker={setinput}
                  inputname={"inp3"}
                  ChangesetValueDate={ChangesetValueDate}
                  inputDate={inputDate}
                />
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-blue01" onClick={getHisFilter}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการประวัติการใช้แคมเปญ
                </p>
              </div>
            </div>
            <TableData
              pageApproveName={"/Addapprovecampaign"}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowsdata}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DatacampaignHist;
