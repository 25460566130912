import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
import '../CSS/Addedit.css'

function EditBorrowbedding() {

    const [nameBed, setnameBed] = useState("หมอน")
    const [countBed, setcountBed] = useState("500")
    const [priceBed, setpriceBed] = useState("122,332.02")

  return (
    <>
    <Header parentmenu={'ข้อมูลเครื่องนอน'}/>
    <Menu parentmenu={'borrowReturn'} activemenu={'brBedding'}/>
    <div className="content-wrapper">
    <div className="container-fluid p-2 p-md-3">
    <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-borrow.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ยืม-คืนชุดเครื่องนอน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                |  ยืม-คืนชุดเครื่องนอน / ข้อมูลเครื่องนอน Edit
              </font>
            </div>
        </div>
        <Col className='card-white'>
            <Row className='mx-1'>
              <Col xs={12} md={12} lg={12}> 
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> แก้ไขข้อมูลเครื่องนอน</span>
              </p>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={2} md={3} xs={4}className='text-header text-start'>ชื่อเครื่องนอน</Col>
              <Col lg={10} md={9} xs={8}><input className="inp-data01 fontReg font-size03"
              onChange={(e) => {setnameBed(e.target.value)}} value={nameBed}/></Col>
        </Row>    <Row className='mt-3'>
              <Col lg={2} md={3} xs={4}className='text-header text-start'>จำนวนเครื่องนอน</Col>
              <Col lg={10} md={9} xs={8}><input className="inp-data01 fontReg font-size03"
              onChange={(e) => {setcountBed(e.target.value)}} value={countBed}/></Col>
        </Row>    <Row className='mt-3'>
              <Col lg={2} md={3} xs={4}className='text-header text-start'>ค่าปรับ</Col>
              <Col lg={10} md={9} xs={8}><input className="inp-data01 fontReg font-size03"
              onChange={(e) => {setpriceBed(e.target.value)}} value={priceBed}/></Col>
        </Row>
        </Col>
        <Row className='my-5'>
              <Col xs={2}></Col>
              <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-blue02'>ยืนยัน</button></Col>
              <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-cancle'>ยกเลิก</button></Col>
              <Col xs={2}></Col>
            </Row>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default EditBorrowbedding