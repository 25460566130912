import Footer from "../template/Footer";
import Button3items from "../button/Button3items";
// import DataMockArena from "../dataMockup/DataMockArena";
import {
  getDataArea,
  getDataAreaByID,
  postDeletArea,
  getDataAreaFilter,
  getAllArea,
} from "../config/configApi-Area";
import React, { useState, useEffect } from "react";
import moment from "moment";
import Header from "../template/Header";
import Menu from "../template/Menu";
import { useNavigate } from "react-router-dom";
// import {
//   DataDropdownArenas,
//   DataDropdownStatus,
// } from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import Dropdown from "../dropdowns/Dropdown";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import Swal from "sweetalert2";
import Loading from "./Loading";
import { getDataUser } from "../config/configApi-User";
// import {} from '../../api/'
import { logDOM } from "@testing-library/react";
import Buttonexport from "../button/Buttonexports";
import { FlareSharp } from "@mui/icons-material";
function Masterarena(props) {
  let DataDropdownArenas = [{ value: "0", text: "ทั้งหมด" }];
  const DataDropdownStatus = [
    { value: "", text: "ทั้งหมด" },
    { value: true, text: "ใช้งาน" },
    { value: false, text: "ไม่ใช้งาน" },
  ];
  const [inputForm, setInputForm] = useState({
    inp1: "",
    inp2: "",
    // inp3: 0,
  });

  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };
  const idAction = {
    data: null,
  };
  const navigate = useNavigate();

  let rowsdata = [];
  const [DataArena, setDataMockArena] = useState([]);
  const [DataDropArenas, setDataDropArenas] = useState([]);
  const [getDatasubArea, setDatasubArea] = useState([]);
  const [getDataimage, setDataImage] = useState([]);
  const [rowMasterData, setRowMasterData] = useState([]);
  const [DataArenaID, setDataMockArenaID] = useState([]);
  const [spanloding, setspanloding] = useState(false);

  function navigateToAddEdit(page) {
    navigate(page);
  }
  const columnsname = [
    "id",
    "arenaName",
    "arenaAmount",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อสนามกีฬา",
    "จำนวนสนาม",
    "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];

  function deleteRow(grpid, dataid) {
    //find data from rowsdata
    let dataindex = rowsdata.findIndex((data) => data.id.value == dataid);
    if (dataindex > -1) {
      rowsdata.splice(dataindex, 1);
      setRowMasterData([...rowsdata]);
    }
  }

  // const getAreaFilterType = async () => {
  //   let result = [];
  //   let respon;

  //   if (sortDt === "ทั้งหมด") {
  //     getArea();
  //   } else {
  //     if (sortDt === "ใช้งาน") {
  //       sortDt = true;
  //     } else if (sortDt === "ไม่ใช้งาน") {
  //       sortDt = false;
  //     }
  //     respon = await getDataAreaFilter(sortDt);
  //     respon.data.data.map((objectData, i) => {
  //       return rowsdata.push({
  //         arenaID: objectData.arenaID,
  //         id: {
  //           value: i + 1,
  //           type: "text",
  //           dataID: objectData.arenaID,
  //         },
  //         arenaName: {
  //           value: objectData.arenaName,
  //           type: "text",
  //         },
  //         arenaAmount: { value: objectData.subArea.length, type: "text" },
  //         // status: { value: "ใช้งาน", type: "text" },
  //         status: {
  //           value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
  //           active: objectData.active,
  //           type: "text",
  //         },

  //         updateddate: {
  //           value: moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
  //           type: "text",
  //         },
  //         action: {
  //           value: "1",
  //           type: "action",
  //           data: objectData.arenaID,
  //         },
  //       });
  //     });
  //     if (typeof sortDt === "boolean" || sortDt instanceof Boolean) {
  //       result = rowsdata.filter((data) => {
  //         return sortDt === data.status.active;
  //       });
  //       setDataMockArena(result);
  //     } else {
  //       result = rowsdata.filter((data) => {
  //         return sortDt === data.arenaName.value;
  //       });

  //       setDataMockArena(result);
  //     }
  //   }
  // };

  const getAreaFilter = async () => {
    setspanloding(true);
    let respon;
    if (selectedArena === "" && selectedStatus === "") {
      await setspanloding(false);
      getArea();
    } else {
      let bodyFilter = {
        inp1: selectedArena,
        inp2: selectedStatus,
      };
      respon = await getDataArea(bodyFilter);

      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          arenaID: objectData.arenaID,
          id: {
            value: i + 1,
            type: "text",
            dataID: objectData.arenaID,
          },
          // { value: i + 1, type: "text" },
          arenaName: {
            value: objectData.arenaName,
            type: "text",
          },
          arenaAmount: { value: objectData.subArea.length, type: "text" },
          status: {
            value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            active: objectData.active,
            type: "text",
          },

          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
            data: objectData.arenaID,
          },
        });
      });
      setspanloding(false);
      setDataMockArena(rowsdata);
    }
  };

  // filter dropdown
  // const getFilterdropdown = async (data) => {
  //   var dataArr = data.map((item) => {
  //     return [item.text, item];
  //   }); // creates array of array
  //   var maparr = new Map(dataArr); // create key value pair from array of array
  //   var result = [...maparr.values()]; //converting back to array from mapobject
  //   setDataDropArenas(result);
  // };

  const getArea = async () => {
    await setspanloding(true);
    const respon = await getAllArea();
    // setTimeout(async () => {

    // }, 1000);
    await setspanloding(false);
    respon.data.data.forEach((objectData, i) => {
      return rowsdata.push({
        arenaID: objectData.arenaID,
        id: {
          value: i + 1,
          type: "text",
          dataID: objectData.arenaID,
        },
        // { value: i + 1, type: "text" },
        arenaName: {
          value: objectData.arenaName,
          type: "text",
        },
        arenaAmount: { value: objectData.subArea.length, type: "text" },
        status: {
          value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
          active: objectData.active,
          type: "text",
        },

        updateddate: {
          value:
            objectData.updatedAt === null
              ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
              : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
          type: "text",
        },
        action: {
          value: "1",
          type: "action",
          data: objectData.arenaID,
        },

        subArea: objectData.subArea,
      });
    });

    setDataMockArena(rowsdata);
    let dataType = [{ value: "", text: "ทั้งหมด" }];
    respon.data.data.forEach((item, i) => {
      return dataType.push({
        value: item.arenaID,
        text: item.arenaName,
        subType: [],
      });
    });

    setDataDropArenas(dataType);
    // return rowsdata;
  };
  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await postDeletArea(id);
            if (response.status === "success") {
              getArea();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const getAreaID = async (Id) => {
    let getData = [];
    const respon = await getDataAreaByID(Id);
    getData.push({
      arenaID: respon.data.data.arenaID,
      arenaName: respon.data.data.arenaName,
      createdAt: respon.data.data.createdAt,
      deletedAt: respon.data.data.deletedAt,
      description: respon.data.data.description,
      active: respon.data.data.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
      image: [
        {
          // assets/images/ex-img-arena.jpg
          img:
            respon.data.data.img0 === null || respon.data.data.img0 === ""
              ? ""
              : respon.data.data.img0,
        },
        {
          img:
            respon.data.data.img1 === null || respon.data.data.img1 === ""
              ? ""
              : respon.data.data.img1,
        },
        {
          img:
            respon.data.data.img2 === null || respon.data.data.img2 === ""
              ? ""
              : respon.data.data.img2,
        },
        {
          img:
            respon.data.data.img3 === null || respon.data.data.img3 === ""
              ? ""
              : respon.data.data.img3,
        },
        {
          img:
            respon.data.data.img4 === null || respon.data.data.img4 === ""
              ? ""
              : respon.data.data.img4,
        },
      ],
      latitude: respon.data.data.latitude,
      longitude: respon.data.data.longitude,
      subArea: respon.data.data.subArea,
      updatedAt: respon.data.data.updatedAt,
      remark: respon.data.data.remark,
    });
    setDataMockArenaID(getData[0]);
    setDataImage(getData[0].image);

    setDatasubArea(getData[0].subArea);
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditarena`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditarena?id=${id}`);
        break;
      case "view":
        // handle view button

        viewModal(id);
        getAreaID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const viewModal = async (id) => {
    try {
    } catch (error) {}
  };

  // if (sortDt !== null) {
  //   getAreaFilter();
  // }
  const handleExport = async (type) => {
    let Header = ["No", "ชื่อสนามกีฬา", "จำนวนสนาม", "สถานะ", "อัปเดตล่าสุด"];
    let Data = DataArena.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.arenaName.value;
      dt[2] = elemento.subArea.length;
      dt[3] = elemento.status.value;
      dt[4] = elemento.updateddate.value;
      return dt;
    });

    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานสนามแข่งขัน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const areaDropdown = async () => {
    try {
      const response = await getAllArea();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.arenaID,
            text: item.arenaName,
            subType: [],
          });
        });

        setDataDropArenas(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getArea();
    // areaDropdown();
  }, []);
  let sortDt = new URLSearchParams(window.location.search).get("sortby");
  let st1 = sortDt;

  const [selectedArena, setSelecteArena] = useState("");
  const [selectedStatus, setSelecteStatus] = useState("");

  const handleArenaChange = (value) => {
    setSelecteArena(value);
  };
  const handleStatusChange = (value) => {
    setSelecteStatus(value);
  };

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");

  function resetDropdown() {
    inputForm.inp1 = "";
    inputForm.inp2 = "";
    setSelecteArena("");
    setSelecteStatus("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    getArea();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }

  return (
    <>
      {/* <Loading /> */}
      {spanloding === true ? <Loading /> : <></>}
      <Header parentmenu={"สนามกีฬา"} />
      <Menu parentmenu={"masterData"} activemenu={"arena"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                สนามกีฬา
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / สนามกีฬา
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                onButtonClicked={handleButtonClicked}
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditarena"}
                onExportClicked={handleExport}
              />
              {/* <Buttonexport rowsdata={DataArena} /> */}
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-5 pr-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชื่อสนามกีฬา
                  </p>
                  <Dropdown
                    setinput={setinput}
                    inputname={"inp1"}
                    options={DataDropArenas}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleArenaChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5 pr-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สถานะ</p>
                  <Dropdown
                    setinput={setinput}
                    inputname={"inp2"}
                    options={DataDropdownStatus}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button
                  className="btn-blue01"
                  onClick={(event) => getAreaFilter()}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการสนามกีฬา
                </p>
              </div>
            </div>
            {/* <TableData
              pageAddEditName="/Addeditarena"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowMasterData}
              isSearchEnable={false}
              deleteRow={deleteRow}
            /> */}
            {/* <Table
              onclick={testClick}
              item={DataMockArena}
              pageAddEditName="/Addeditarena"
              actionsBtn={enableButton}
            /> */}
            <TableData
              pageAddEditName="/Addeditarena"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={DataArena}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
            {/* <TableData
              pageAddEditName="/Addeditarena?data"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={DataArena}
              isSearchEnable={false}
            /> */}
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูล
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อสนามกีฬา:
                      {DataArenaID.arenaName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ละติจูด: {DataArenaID.latitude}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ลองจิจูด: {DataArenaID.longitude}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      รายละเอียดสนาม: {DataArenaID.description}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      หมายเหตุ: {DataArenaID.remark || "-"}
                      {/* {DataArenaID.description} */}
                    </p>
                  </div>

                  <div className="col-12 ml-2 mb-3">
                    <span className="fontReg font-size03 colorGray01 d-flex">
                      รูปภาพ:{" "}
                    </span>
                    {/* <img
                      src="assets/images/ex-img-arena.jpg"
                      alt="Arena"
                      className="im-sports-type-dt"
                    /> */}
                    {getDataimage.map((data, i) => (
                      <img
                        width={100}
                        height={100}
                        src={data.img}
                        // src="assets/images/ex-img-arena.jpg"
                        alt="Arena"
                        className={
                          data.img !== "" ? "im-sports-type-dt" : "d-none"
                        }
                      />
                    ))}{" "}
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ: {DataArenaID.active}
                    </p>
                  </div>
                  <div
                    className={
                      getDatasubArea.length > 0 ? "col-12 mt-4" : "d-none"
                    }
                  >
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>รายชื่อสนามกีฬาย่อย
                    </p>
                  </div>
                  <div
                    className={
                      getDatasubArea.length > 0 ? "col-12 ml-2" : "d-none"
                    }
                  >
                    {getDatasubArea.map((data, i) => (
                      <p className="fontReg font-size03 colorGray01">
                        ชื่อสนามกีฬาย่อย: {data.subArenaName}
                      </p>
                    ))}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Masterarena;
