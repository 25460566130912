import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button3items from "../button/Button3items";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import Dropdown from "../dropdowns/Dropdown";
import {
  DataDropdownAffiliation,
  DataDropdownSportType,
} from "../dataMockup/DataMockDropdown";
import { useNavigate } from "react-router-dom";
import TableData from "../Tables/TableData";
import Loading from "./Loading";
import {
  getMatchStatAthlete,
  getMatchStatAthleteById,
  deleteMatchStatAthlete,
  getDataMatchStatAthleteFilter,
} from "../../api/athlete_api";
function Datamatchstatistics() {
  const enableButton = {
    view: true,
    edit: false,
    delete: true,
    add: false,
    approve: false,
  };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const columnsname = [
    "id",
    "name",
    "sex",
    "affiliation",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ชื่อ",
    "เพศ",
    "สังกัด",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];
  const rowsdata = [
    // {
    //   id: { value: "1", type: "text" },
    //   name: { value: "นางสาวเทควันโด ดีงาม", type: "text" },
    //   sex: { value: "หญิง", type: "text" },
    //   affiliation: { value: "กรุงเทพมหานคร", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];
  const [dataMatchAth, setDataMatchAth] = useState([]);
  const [dataMatchAthID, setDataMatchAthID] = useState([]);
  const [arrMatchstat, setArrMatchstat] = useState([]);
  const [spanloding, setSpanloding] = useState(false);

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditguide`);
        break;
      case "edit":
        // handle edit button
        // navigate(`/AddeditathleteAmt?id=${id}`)
        break;
      case "view":
        getMatchStatAthID(id);
        // handle view button
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        deletegetMatchStatAthByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const getMatchStatAth = async () => {
    setSpanloding(true);
    try {
      const respon = await getMatchStatAthlete();
      setTimeout(async () => {
        await setSpanloding(false);
      }, 1000);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          // ----------
          id: { value: i + 1, type: "text", dataID: objectData.atheleteID },
          name: {
            value:
              objectData.firstname === null
                ? "-"
                : objectData.firstname + " " + objectData.lastname,
            type: "text",
          },
          sex: {
            value: objectData.gender === null ? "-" : objectData.gender,
            type: "text",
          },
          affiliation: {
            value:
              objectData.affliation.affliationName !== undefined
                ? objectData.affliation.affliationName
                : "-",
            type: "text",
          },
          updateddate: {
            value: objectData.updatedAt
              ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
              : "-",
            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      console.log("rowsdata preview  : ", rowsdata);
      setDataMatchAth(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getMatchStatAthID = async (Id) => {
    // setSpanloding(true);
    let getData = [];
    const respon = await getMatchStatAthleteById(Id);
    // setTimeout(async () => {
    //   await setSpanloding(false);
    // }, 1000);
    getData.push({
      id: respon.data.data.idcard === null ? "-" : respon.data.data.idcard,
      title: respon.data.data.title ? respon.data.data.title : "-",
      firstname:
        respon.data.data.firstname === null ? "-" : respon.data.data.firstname,
      lastname:
        respon.data.data.lastname === null ? "-" : respon.data.data.lastname,
      gender: respon.data.data.gender === null ? "-" : respon.data.data.gender,
      affliation:
        respon.data.data.affliation.affliationName === undefined
          ? "-"
          : respon.data.data.affliation.affliationName +
            " ( " +
            respon.data.data.affliation.affliationSector +
            " ) ",
      matchstat:
        respon.data.data.matchstat.length < 1 ? [] : respon.data.data.matchstat,
    });

    setArrMatchstat(getData[0].matchstat);
    setDataMatchAthID(getData[0]);
  };
  const deletegetMatchStatAthByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteMatchStatAthlete(id);

            if (response.data.status === "success") {
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
              if (result.isConfirmed) {
                getMatchStatAth();
              }
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getMatchStatAth();
  }, []);

  const getDataFilter = async () => {
    setSpanloding(true);
    let respon;
    let rowsdata = [];
    if (inputForm.name === "") {
      await setSpanloding(false);
      getMatchStatAth();
    } else {
      let DropdownForm = {
        name: inputForm.name,
      };
      respon = await getDataMatchStatAthleteFilter(DropdownForm);
      setTimeout(async () => {
        await setSpanloding(false);
      }, 1000);
      respon.data.data.forEach((row, i) => {
        return rowsdata.push({
          // ----------
          id: { value: i + 1, type: "text", dataID: row.atheleteID },
          name: {
            value:
              row.firstname === null ? "-" : row.firstname + " " + row.lastname,
            type: "text",
          },
          sex: {
            value: row.gender === null ? "-" : row.gender,
            type: "text",
          },
          affiliation: {
            value:
              row.affliation.affliationName !== undefined
                ? row.affliation.affliationName
                : "-",
            type: "text",
          },
          updateddate: {
            value: row.updatedAt
              ? moment(new Date(row.updatedAt)).format("YYYY-MM-DD")
              : "-",
            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      setDataMatchAth(rowsdata);
    }
  };

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [name, setName] = useState("");
  const [inputForm, setInputForm] = useState({
    name: "",
  });

  function clearData() {
    setName("");
    inputForm.name = "";
    getMatchStatAth();
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  const handleExport = async (type) => {
    let Header = ["ลำดับที่", "ชื่อ", "เพศ", "สังกัด", "อัปเดตล่าสุด"];

    let Data = dataMatchAth.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.name.value;
      dt[2] = elemento.sex.value;
      dt[3] = elemento.affiliation.value;
      dt[4] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานสถิตินักกีฬา",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  return (
    <>
      {spanloding === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"สถิติการแข่งขัน"} />
      <Menu parentmenu={"competitionData"} activemenu={"matchStatistics"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-coach.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                สถิติการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลนักกีฬาและโค้ช / สถิติการแข่งขัน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditmatchstatistics"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-sm-9 pr-2 pr-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อจริง นามสกุล"
                  onChange={onChangeInputName}
                  value={name}
                />
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    getDataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-sm-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={clearData}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการสถิติการแข่งขัน
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataMatchAth}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลนักกีฬา
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      คำนำหน้า: {dataMatchAthID.title}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อจริง: {dataMatchAthID.firstname}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      นามสกุล: {dataMatchAthID.lastname}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เลขบัตรประชาชน: {dataMatchAthID.id}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เพศ: {dataMatchAthID.gender}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สังกัด: {dataMatchAthID.affliation}
                    </p>
                  </div>
                  {(() => {
                    if (arrMatchstat > 0) {
                      return (
                        <div className="col-12 mt-4">
                          <p className="fontReg font-weight-bold font-size03 colorGray01">
                            <span className="border-left01"></span>
                            ข้อมูลการแข่งขัน
                          </p>
                        </div>
                      );
                    }
                  })()}

                  {arrMatchstat.map((item) => (
                    <div className="row fontReg font-size03 colorGray01 mr-0 ml-2">
                      <div className="row w-100 m-0">
                        <div className="col-12">
                          <p className="fontReg font-size03 font-weight-bold colorGray01 m-0">
                            {/* {item.sportName} */}
                            รายการแข่งขัน: {item.subSportName}{" "}
                            {dataMatchAthID.gender}{" "}
                            {item.matchHeat === "final"
                              ? "รอบชิงชนะเลิศ"
                              : item.matchHeat === "final"
                              ? "รอบรองชนะเลิศ"
                              : item.matchHeat === "qualify"
                              ? "รอบคัดเลือก"
                              : "-"}
                            {/* {item.sportName}{" "} */}
                            {/* {item.competitionType === "single"
                              ? "เดี่ยว"
                              : "ทีม"} */}
                          </p>
                        </div>
                        <div className="col-12 col-sm-6">
                          ชนิดกีฬา: {item.sportName}
                        </div>
                        <div className="col-12 col-sm-6">
                          ประเภทการแข่งขัน:{" "}
                          {item.competitionType === "single" ? "เดี่ยว" : "ทีม"}
                        </div>
                        <div className="col-12 col-sm-6">
                          เหรียญ: {item.medal ? item.medal : "-"}
                        </div>
                        <div className="col-12 col-sm-6">
                          สถิติ/ผลการแข่งขัน:{item.record ? item.record : "-"}
                        </div>
                        <div className="col-12 col-sm-6">
                          หมายเหตุ: {item.remark ? item.remark : "-"}
                        </div>
                        <div className="col-12 col-sm-6">
                          วันที่ได้รับ: {item.date ? item.date : "-"}
                        </div>

                        {/* <div className="col-12 col-sm-6">
                          ประเภทการแข่งขัน: {item.supsport}
                        </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Datamatchstatistics;
