import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
const dataToken = localStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};
// /dashboard/promotion
export const getDashboard = async (query = {}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get("/dashboard/main", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getDashAppUserFilter = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await instace.get("/dashboard/appuse", {
      headers: headers,
      params: {
        ageId: data.ageId === null ? null : data.ageId,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getDashboardAppuse = async (query = {}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get("/dashboard/appuse", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getDashboardArena = async (query = {}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get("/dashboard/arena", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getDashboardArenaFilter = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await instace.get("/dashboard/arena", {
      headers: headers,
      params: {
        subArenaId: data.subarena || null,
        date: data.date || null,
        time: data.time || null,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
