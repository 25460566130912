import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import Loading from "../pages/Loading";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import { postDataLogin, dataObject } from "../config/configApi-User";
import { login } from "../../api/login_api";
import Swal from "sweetalert2";
function Login() {
  // user superadmin04@mail.com
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [inputUser, setInputuser] = useState("");
  const [inputPassword, setInputpassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [dataError, setDataError] = useState(null);

  //   const [dataPassword, setPassword] = useState("");
  const handleInputChkThai = (event) => {
    const thaiCharacterRegex = /[\u0E00-\u0E7F]/; // Thai character Unicode range

    if (thaiCharacterRegex.test(event.target.value)) {
      event.target.value = "";
      setDataError("ไม่สามารถใช้ภาษาไทยได้");
      // Swal.fire({
      //   title: "ไม่สามารถใช้ภาษาไทยได้",
      //   icon: "warning",
      // }).then(async (result) => {});
    }
  };
  function onChangeTagInput(e) {
    // setPassword(e);

    setInputpassword(
      e.target.value.replace(/[^a-zA-Z0-9*+=_-฿%$/@!#^&\d]/gi, "")
    );
  }
  function onChangeUser(e) {
    setInputuser(e.target.value);
  }
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const save_Datauser = () => {
    if (checked) {
      let dataarray = [];
      dataarray.push({
        username: inputUser,
        password: inputPassword,
      });
      localStorage.setItem("SaveLogin", JSON.stringify(dataarray));
    }
  };
  function navigateToBack(page) {
    navigate(page);
  }
  const loginUser = async () => {
    let typeUser = "staff";
    let loginType = "web";
    var data = {
      loginType: loginType,
      memberType: typeUser,
      email: inputUser,
      password: inputPassword,
      // notificationToken: null,

      // loginType: loginType,
      // memberType: typeUser,
      // email: inputUser,
      // password: inputPassword,
    };
    try {
      setLoading(true);
      const respon = await login(data);
      await setLoading(false);
      if (respon && respon.status === 200) {
        localStorage.setItem("token", respon.data.token);

        navigate("/home");
      } else {
        Swal.fire({
          title: "ขออภัย ชื่อผู้ใช้ หรือ รหัสผ่าน ไม่ถูกต้อง",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/login");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const validateUserPass = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputUser)) {
      return false;
    }
    return true;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateUserPass()) {
      // Perform login or form submission logic
      loginUser();
    } else {
      Swal.fire({
        icon: "info",
        title: "คุณใส่รูปแบบอีเมลไม่ถูกต้อง",
        confirmButtonText: "ตกลง",
      }).then((result) => {});
    }
  };
  useEffect(() => {
    // localStorage.clear();
    if (localStorage.getItem("token")) {
      navigate("/home");
    }
    if (localStorage.getItem("SaveLogin")) {
      var saveLogindata = localStorage.getItem("SaveLogin");
      var setDatalogin = JSON.parse(saveLogindata);
      setInputuser(setDatalogin[0].username);
      setInputpassword(setDatalogin[0].password);
    }

    // save_Datauser();
  }, []);
  return (
    <div id="login">
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      {/* <ToastContainer /> */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="container-fluid height100">
          <div className="row height100">
            <div className="col-12 col-lg-6 login01">
              <div className="position-relative">
                <img
                  src="assets/images/im-login01.png"
                  alt="Login Logo"
                  className="im-login01"
                />
                <img
                  src="assets/images/im-login02.png"
                  alt="Login Logo"
                  className="im-login02 bouncing-item"
                />
                <img
                  src="assets/images/im-login03.png"
                  alt="Login Logo"
                  className="im-login03 flying-item"
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 login02">
              <div className="row area01">
                <div className="col-12 d-flex justify-content-center">
                  <img
                    src="assets/images/im-logoNW.png"
                    alt="NW Logo"
                    className="im-NWlogo"
                  />
                </div>
                <div className="col-12 p-0 d-flex justify-content-center">
                  <label className="fontReg font-size04 line-height04 colorGray01 line-bottom">
                    ระบบจัดการข้อมูลงานกีฬานครสวรรค์
                  </label>
                </div>
                <div className="col-12 mt-3 mb-3 d-flex justify-content-center">
                  <label className="fontReg font-size05 line-height03 font-weight-bold colorBlue01">
                    เข้าสู่ระบบ
                  </label>
                </div>
                <div className="col-12">
                  <input
                    onChange={(e) => onChangeUser(e)}
                    value={inputUser}
                    type="text"
                    className="inp-login fontLight font-size03"
                    id="user_name"
                    placeholder="ชื่อผู้ใช้งาน"
                  />
                </div>

                <div className="col-12 mt-3">
                  <input
                    onInput={handleInputChkThai}
                    value={inputPassword}
                    onChange={(e) => onChangeTagInput(e)}
                    maxLength={16}
                    type={passwordShown ? "text" : "password"}
                    className="inp-login fontLight font-size03"
                    id="password"
                    placeholder="รหัสผ่าน"
                  />
                </div>

                <div className="col-9"></div>
                <div className="col-3 text-right eye-password">
                  {(() => {
                    if (!passwordShown) {
                      return (
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          onClick={togglePassword}
                        ></i>
                      );
                    } else {
                      return (
                        <i
                          className="fa fa-eye-slash "
                          aria-hidden="true"
                          onClick={togglePassword}
                        ></i>
                      );
                    }
                  })()}

                  {() => {
                    if (!passwordShown) {
                      return (
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          onClick={togglePassword}
                        ></i>
                      );
                    } else {
                      return (
                        <i
                          className="fa fa-eye-slash "
                          aria-hidden="true"
                          onClick={togglePassword}
                        ></i>
                      );
                    }
                  }}
                </div>
                <div className="col-12 mt-2 ">
                  {" "}
                  <p className="text-danger mb-0 text-sm font-weight-bold">
                    {dataError ? dataError : ""}
                  </p>
                </div>
                <div className="col-6 mt-3">
                  <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                    บันทึกการเข้าระบบ
                    <input
                      type="checkbox"
                      defaultChecked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="col-6 mt-3 d-flex justify-content-end">
                  <Link
                    as={Link}
                    to="/Chpassword"
                    className="fontReg font-weight-bold font-size03 colorBlue01"
                  >
                    ลืมรหัสผ่าน?
                  </Link>
                </div>
                <div className="col-12 mt-3">
                  <button
                    onClick={() => save_Datauser()}
                    type="submit"
                    class="btn btn-lg btn-blue fontReg font-weight-bold font-size03"
                    disabled={inputPassword.length < 6}
                  >
                    ลงชื่อเข้าใช้
                  </button>
                  {/* <Link
                  as={Link}
                  to="/home"
                  className="btn-blue fontReg font-weight-bold font-size03"
                >
                  ลงชื่อเข้าใช้
                </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
