import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChartCard from "../charts/ChartCard";
import { DataMockArenaUsers } from "../dataMockup/DataMockArenaUsers";

export const SliderCardArenaUsers = (props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesPerRow: 3,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesPerRow: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesPerRow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesPerRow: 1,
        },
      },
    ],
  };

  const arenaUsersElements = props.dataArena.map((arenaUser, index) => {
    return (
      <div key={index}>
        <ChartCard cardData={arenaUser} dateTime={props.dataTime} />
      </div>
    );
  });

  return (
    <Slider dateTime={props.dataTime} {...settings}>
      {arenaUsersElements}
    </Slider>
  );
};
