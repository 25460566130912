import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Buttonactions(props) {
  const {
    id,
    pageAddEditName,
    pageAddName,
    pageApproveName,
    actionsBtn,
    deleteData,
    disabled,
  } = props;
  // console.log("actionsBtn");

  // console.log("actionsBtn", actionsBtn);
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  function navigateToAdd(page) {
    navigate(page);
  }

  function navigateToApprove(page) {
    navigate(page);
  }

  return (
    <div className="row align-items-center justify-content-center">
      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 align-items-center justify-content-center justify-content-md-end  ${
          actionsBtn.add ? "d-flex" : "d-none"
        } `}
      >
        <button
          onClick={() => props.onAddClick(id)}
          type="button"
          className="btn-add01"
        >
          <img
            src="assets/icons/ic-add.png"
            alt="Action Icon"
            className="ic-actions"
          />
        </button>
      </div>
      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 align-items-center justify-content-center ${
          actionsBtn.view ? "d-flex" : "d-none"
        } `}
      >
        <button
          className={`btn-view`}
          onClick={() => props.onViewClick(id)}
          data-toggle="modal"
          data-target="#exampleModal"
        >
          <img src="assets/icons/ic-view.png" alt="" className="ic-actions" />
        </button>
      </div>
      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
          actionsBtn.edit ? "d-flex" : "d-none"
        } `}
      >
        <button
          onClick={() => props.onEditClick(id)}
          type="button"
          disabled={disabled}
          className="btn-edit"
        >
          <img src="assets/icons/ic-edit.png" alt="" className="ic-actions" />
        </button>
      </div>
      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
          actionsBtn.delete ? "d-flex" : "d-none"
        } `}
      >
        <button className="btn-delete" onClick={() => props.onDeleteClick(id)}>
          <img src="assets/icons/ic-delete.png" alt="" className="ic-actions" />
        </button>
      </div>
      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
          actionsBtn.approve ? "d-flex" : "d-none"
        } `}
      >
        <button
          onClick={() => props.onApproveClick(id)}
          type="button"
          className="btn-approve"
        >
          <img
            src="assets/icons/ic-approve.png"
            alt="Action Icon"
            className="ic-actions"
          />
        </button>
      </div>

      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
          actionsBtn.cancel ? "d-flex" : "d-none"
        } `}
      >
        <button
          onClick={() => props.onCancelClick(id)}
          type="button"
          className="btn-delete"
        >
          <img
            src="assets/icons/ic-block.png"
            alt="Action Icon"
            className="ic-actions"
          />
        </button>
      </div>
    </div>
  );
}

export default Buttonactions;
