import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

const Toast = () => {
  const notifySuccess = () =>
    toast.success("บันทึกข้อมูลสำเร็จ !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  useEffect(() => {
    notifySuccess();
  }, []);
  return <ToastContainer />;
};

export default Toast;
