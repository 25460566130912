import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button3items from "../button/Button3items";
import Dropdown from "../dropdowns/Dropdown";
import { useNavigate } from "react-router-dom";
import {
  DataDropdownAffiliation,
  DataDropdownSexs,
} from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import {
  getGuestList,
  getGuestById,
  deleteGuest,
  getDataGuestFilter,
  getGuestListCoach,
} from "../../api/guest_api";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import { getAffliation } from "../../api/affliation_api";
import Loading from "./Loading";

function Datacoach() {
  const DataDropdownSexs = [
    { value: "", text: "ทั้งหมด" },
    { value: "หญิง", text: "หญิง" },
    { value: "ชาย", text: "ชาย" },
    { value: "อื่นๆ", text: "อื่นๆ" },
  ];
  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const getAffliationDropDown = async () => {
    try {
      const response = await getAffliation();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];

        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.affliationID,
            text: item.affliationName,
            subType: [],
          });
        });

        // const dropdown = response.data.data
        // const Affliation = dropdown.map(item => {
        //   const Aff = {
        //     value: item.affliationID,
        //     text: item.affliationName,
        //     subType: []
        //   }
        //   return Aff
        // })

        setAffirmationDropdown(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditcoach`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditcoach?id=${id}`);
        break;
      case "view":
        // handle view button
        getCoachID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        deleteCoachByID(id);
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const handleExport = async (type) => {
    let Header = ["No", "ชื่อ", "เพศ", "สังกัด", "อัปเดตล่าสุด"];
    let Data = dataCoach.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.name.value;
      dt[2] = elemento.sex.value;
      dt[3] = elemento.affliation.value;
      // dt[4] = elemento.status.value;
      dt[4] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานโค้ช",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const columnsname = [
    "id",
    "name",
    "sex",
    "affliation",
    // "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อ",
    "เพศ",
    "สังกัด",
    // "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    // "text",
    "text",
    "action",
  ];

  const [dataCoach, setDataCoach] = useState([]);
  const [dataCoachID, setDataCoachID] = useState([]);
  const [affliationDropdown, setAffirmationDropdown] = useState([]);
  const [spanloding, setSpanloding] = useState(false);

  const [inputForm, setInputForm] = useState({
    name: "",
    gender: "",
    affliation: "",
  });

  useEffect(() => {
    getAffliationDropDown();
    getDataCoach();
    // getDataSort();
  }, []);

  let rowsdata = [];
  const getDataCoach = async () => {
    setSpanloding(true);
    try {
      const respon = await getGuestListCoach();
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.memberID },
          name: {
            value:
              objectData.firstname === null && objectData.lastname === null
                ? "-"
                : objectData.firstname + "  " + objectData.lastname,
            type: "text",
          },
          sex: {
            value: objectData.gender === null ? "-" : objectData.gender,
            type: "text",
            gender: objectData.gender,
          },
          affliation: {
            value: objectData.affliation === null ? "-" : objectData.affliation,
            type: "text",
            affliation: objectData.affliation,
          },
          status: {
            value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",

            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      await setSpanloding(false);
      setDataCoach(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getCoachID = async (Id) => {
    let getData = [];
    const respon = await getGuestById(Id);

    getData.push({
      id:
        respon.data.data.idcard === null || respon.data.data.idcard === ""
          ? "-"
          : `${respon.data.data.idcard.slice(
              0,
              1
            )}-${respon.data.data.idcard.slice(
              1,
              10
            )}-${respon.data.data.idcard.slice(
              10,
              12
            )}-${respon.data.data.idcard.slice(12, 13)}
  `,
      // respon.data.data.idcard,

      title: respon.data.data.title !== null ? respon.data.data.title : "-",
      firstname: respon.data.data.firstname,
      lastname: respon.data.data.lastname,
      tel:
        respon.data.data.mobileNo === null || respon.data.data.mobileNo === ""
          ? "-"
          : `${respon.data.data.mobileNo.slice(
              0,
              3
            )}-${respon.data.data.mobileNo.slice(
              3,
              6
            )}-${respon.data.data.mobileNo.slice(6, 10)}
  `,
      email:
        respon.data.data.email === null || respon.data.data.email === ""
          ? "-"
          : respon.data.data.email,
      affliation:
        respon.data.data.affliation === null
          ? "-"
          : respon.data.data.affliation,
      status: "-",
      socialType: respon.data.data.registerType || null,
      img: respon.data.data.img || null,
      // respon.data.data.socialType === null
      //   ? "-"
      //   : respon.data.data.socialType,
      gender: respon.data.data.gender === null ? "-" : respon.data.data.gender,
    });

    setDataCoachID(getData[0]);
  };
  const deleteCoachByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteGuest(id);

            if (response.data.status === "success") {
              getDataCoach();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const getDataFilter = async () => {
    setSpanloding(true);
    // let respon;
    // if (
    //   inputForm.name === "" &&
    //   selectedValue1 === "" &&
    //   selectedValue2 === ""
    // ) {
    // await setSpanloding(false)
    // getGuestListCoach(null);
    // } else {
    let DropdownForm = {
      name: inputForm.name,
      affliation: selectedValue1,
      gender: selectedValue2,
    };
    const respon = await getDataGuestFilter(DropdownForm);

    respon.data.data.forEach((objectData, i) => {
      return rowsdata.push({
        memberID: objectData.memberID,
        id: { value: i + 1, type: "text", dataID: objectData.memberID },
        name: {
          value:
            objectData.firstname === null && objectData.lastname === null
              ? "-"
              : objectData.firstname + "  " + objectData.lastname,
          type: "text",
        },
        sex: {
          value: objectData.gender === null ? "-" : objectData.gender,
          type: "text",
          gender: objectData.gender,
        },
        affliation: {
          value: objectData.affliation === null ? "-" : objectData.affliation,
          type: "text",
          affliation: objectData.affliation,
        },
        status: {
          value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
          // value: "-",
          type: "text",
        },
        updateddate: {
          value:
            objectData.updatedAt === null
              ? "-"
              : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

          type: "text",
        },
        action: { value: "1", type: "action" },
      });
    });
    await setSpanloding(false);
    setDataCoach(rowsdata);
    // }
  };

  function setinput(inputname, id) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: id,
      };
    });
  }

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [selectedValue1, setSelectedValue1] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");
  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.name = "";
    setSelectedValue1("");
    setSelectedValue2("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    getDataCoach();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  const handleAffiliationChange = (value) => {
    setSelectedValue1(value);
  };

  const handleGenderChange = (value) => {
    setSelectedValue2(value);
  };

  return (
    <>
      {spanloding === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"โค้ช"} />
      <Menu parentmenu={"athleteCoach"} activemenu={"coach"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-coach.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                โค้ช
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลนักกีฬาและโค้ช / โค้ช
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditcoach"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-6 pr-2 pr-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อจริง นามสกุล"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
            </div>
            <div className="row justify-content-start mt-3">
              <div className="col-12 col-sm-3 pr-sm-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">สังกัด</p>
                  <Dropdown
                    options={affliationDropdown}
                    setinput={setinput}
                    inputname={"affliation"}
                    id="affiliation"
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleAffiliationChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">เพศ</p>
                  <Dropdown
                    setinput={setinput}
                    options={DataDropdownSexs}
                    inputname={"gender"}
                    id="gender"
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleGenderChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(event) => getDataFilter()}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-sm-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการโค้ช
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Addeditcoach"
              actionsBtn={enableButton}
              columnsType={columnsType}
              columnsname={columnsname}
              rowsdata={dataCoach}
              isSearchEnable={false}
              columnsText={columnsText}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลโค้ช
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      คำนำหน้า: {dataCoachID.title}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อจริง: {dataCoachID.firstname}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      นามสกุล: {dataCoachID.lastname}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เพศ: {dataCoachID.gender}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เลขบัตรประชาชน: {dataCoachID.id}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สังกัด: {dataCoachID.affliation}
                    </p>
                  </div>
                  <div
                    className={
                      dataCoachID.img === null ? "d-none" : "col-12 ml-2 mb-3"
                    }
                  >
                    <img
                      src={dataCoachID.img}
                      alt="Type Sport"
                      className={
                        dataCoachID.img === null
                          ? "d-none"
                          : "im-sports-type-dt"
                      }
                    />
                  </div>
                  {/* <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะการใช้งาน: {dataCoachID.affliation}
                    </p>
                  </div> */}
                  <div className="col-12 mt-4">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      ข้อมูลการสมัครผ่านแอปพลิเคชัน
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เบอร์โทร: {dataCoachID.tel}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      อีเมล: {dataCoachID.email}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สมัครผ่าน: {dataCoachID.socialType}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Datacoach;
