import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'

function Thememanage() {
    return (
        <>
        <Header parentmenu={'จัดการ theme'}/>
        <Menu activemenu={'manageTheme'}/>
        <div className="content-wrapper">
          <div className="container-fluid">
            <h1>จัดการ theme</h1>
          </div>
        </div>
        <Footer/>
        </>
      )
}

export default Thememanage