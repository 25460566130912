import { useExcelExport } from "./useExcelExport";
export default function App(props) {
  const { generate } = useExcelExport();
  const handleDataExport = () => {
    // generate([...props], { name: "Data" }, { ref: "A1" });
    generate([props], { name: props.data }, { ref: "A1" });
  };

  return handleDataExport();
}
