import React, { useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { useNavigate, useLocation, Await } from "react-router-dom";
import Loading from "../pages/Loading";
import TableData from "../Tables/TableData";
import Button3items from "../button/Button3items";
import { useState } from "react";
import {
  getDataAttraction,
  getDataAttractionByID,
  deleteAttractionByID,
  getDataAtt,
  getAttractionCategory,
} from "../../api/attraction_api";
import Dropdown from "../dropdowns/Dropdown";
import Swal from "sweetalert2";
import moment from "moment";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";

function Advrecommendatt() {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const enableButton = { view: true, edit: true, delete: true, cancel: false };
  const [attname, setAttName] = useState("");
  const [attType, setAttType] = useState("");
  const [attCall, setAttCall] = useState("");
  const [attTime, setAttTime] = useState("");
  const [attOpen, setAttOpen] = useState("");
  const [attClose, setAttClose] = useState("");
  const [attAddress, setAttAddress] = useState("");
  const [attDitail, setAttDitail] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longtitude, setLongitude] = useState("");
  const [images, setImages] = useState("");
  const [status, setStatus] = useState(true);
  const [dataAtt, setDataAtt] = useState([]);
  const [dataTypeDropdown, setDataTypeDropdown] = useState([]);
  const [sortType, setSortType] = useState("");
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const columnsName = ["id", "AttName", "AttType", "AttRec", "action"];
  const columnsText = [
    "ลำดับที่",
    "ชื่อ",
    "ประเภท",
    "รายการแนะนำ",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "action"];

  const [data, setData] = useState([]);

  // const GetAttractionCategory = async () => {
  //   const response = await getAttractionCategory();
  //   let dataType = [{ value: "0", text: "ทั้งหมด" }];

  //   response.data.data.forEach((element, i) => {
  //     return dataType.push({
  //       id: element.categoryID,
  //       value: element.categoryID,
  //       text: element === null ? "-" : element.categoryName,
  //     });
  //   });
  //   getFilterDropdown(dataType);
  // };

  // const getFilterDropdown = async (data) => {
  //   var dataArr = data.map((item) => {
  //     return [item.text, item];
  //   }); // creates array of array
  //   var maparr = new Map(dataArr); // create key value pair from array of array
  //   var result = [...maparr.values()]; //converting back to array from mapobject
  //   setDataTypeDropdown(result);
  // };

  const getDataList = async () => {
    setLoading(true);
    try {
      const query = {
        categoryId: null,
        attractionName: null,
      };
      const response = await getDataAttraction(query);

      if (response) {
        const Attdata = response.data.data;
        const result = Attdata.map((row, i) => {
          const rowData = {
            // id: { value: i + 1, dataID: row?.attractionID, type: "text" },
            // AttName: { value: row?.attractionName, type: "text" },
            // AttType: { value: row?.category.categoryName, type: "text" },
            // AttRec: {
            //   value: row?.isRecommend == 0 ? "-" : "แนะนำ",
            //   type: "text",
            // },
            // action: { value: "1", type: "action" },
            //
            id: { value: i + 1, dataID: row?.attractionID, type: "text" },
            AttName: { value: row?.attractionName, type: "text" },
            AttType: {
              value: row?.category === null ? "-" : row.category.categoryName,
              // row.category.categoryName === null
              //   ? "-"
              //   : row.category.categoryName,
              type: "text",
            },
            AttRec: {
              value: row?.isRecommend == 0 ? "รายการไม่แนะนำ" : "แนะนำ",
              type: "text",
            },
            action: { value: "null", type: "text" },
            //
          };
          return rowData;
        });
        await setLoading(false);
        setData(result);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getDataList();
    GetAttractionCategory();
  }, []);

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/AddRecommendatt`);
        break;
      case "edit":
        // handle edit button
        navigate(`/AddRecommendatt?id=${id}`);
        break;
      case "view":
        // handle view button
        viewModal(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const viewModal = async (id) => {
    const modal = await getDataAttractionByID(id);
    await setAttName(modal.data.data.attractionName);
    await setAttType(modal.data.data.category.categoryName);
    await setAttCall(modal.data.data.mobileNo);
    await setAttTime(
      modal.data.data.openDay === null || modal.data.data.openDay === ""
        ? "-"
        : modal.data.data.openDay
    );
    await setAttOpen(
      modal.data.data.openTime === null || modal.data.data.openTime === ""
        ? "-"
        : modal.data.data.openTime
    );
    await setAttClose(
      modal.data.data.closeTime === null || modal.data.data.closeTime === ""
        ? "-"
        : modal.data.data.closeTime
    );
    await setAttAddress(modal.data.data.address);
    await setAttDitail(modal.data.data.attractionDeatil || "-");
    await setLatitude(modal.data.data.latitude);
    await setLongitude(modal.data.data.longtitude);
    await setImages(modal.data.data.image);
    await setStatus(modal.data.data.isRecommend);
    if (modal.data.data.isRecommend === 1) {
      document.getElementById("recommen").checked = true;
    } else {
      document.getElementById("recommen").checked = false;
    }
  };

  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();
      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await deleteAttractionByID(id);
            if (response.status === 200) {
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
              getDataList();
            } else {
              swalWithBootstrapButtons.fire({
                title: "เกิดข้อผิดพลาด!",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const getAttFilter = async () => {
    setLoading(true);
    if (inputForm.categoryId === undefined) {
      getDataList();
    } else {
      let bodyFilter = {
        inp1: selectedType,
      };

      const query = {
        categoryId: selectedType,
        attractionName: sortname,
      };
      const response = await getDataAtt(query);
      let rowsdata = [];
      response.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          attractionID: objectData.attractionID,
          id: {
            value: i + 1,
            type: "text",
            dataID: objectData.attractionID,
          },
          AttName: {
            value: objectData.attractionName,
            type: "text",
          },
          AttType: { value: objectData.category.categoryName, type: "text" },
          AttRec: {
            value: objectData?.isRecommend == 0 ? "รายการไม่แนะนำ" : "แนะนำ",
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
            data: objectData.attractionID,
          },
        });
      });
      await setLoading(false);
      setData(rowsdata);
    }
  };

  const handleExport = async (type) => {
    let Header = ["ลำดับที่", "ชื่อ", "ประเภท", "รายการแนะนำ"];

    let Data = data.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.AttName.value;
      dt[2] = elemento.AttType.value;
      dt[3] = elemento.AttRec.value;
      return dt;
    });

    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานข้อมูลท่องเที่ยวจังหวัด",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const [inputForm, setInputForm] = useState({
    categoryId: "",
  });
  const [valuefromparent, setValuefromparent] = useState("ทั้งหมด");

  function resetDropdown() {
    inputForm.categoryId = "";
    setNameSort("");
    setValuefromparent("ทั้งหมด");
    getDataList();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent(text) {
    setValuefromparent(text);
  }

  const GetAttractionCategory = async () => {
    try {
      const response = await getAttractionCategory();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.categoryID,
            text: item.categoryName,
            subType: [],
          });
        });
        setDataTypeDropdown(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedType, setSelecteType] = useState("");
  const handleTypeChange = (value) => {
    setSelecteType(value);
  };

  const [sortname, setNameSort] = useState("");
  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setNameSort(e.target.value);
    }
  };

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"จัดการข้อมูลท่องเที่ยวจังหวัด"} />
      <Menu parentmenu={"advertise"} activemenu={"recommendAtt"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-pr.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประชาสัมพันธ์
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ประชาสัมพันธ์ / ข้อมูลท่องเที่ยวจังหวัด
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/AddRecommendatt"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ข้อมูลท่องเที่ยวจังหวัด"
                  value={sortname}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">ประเภท</p>
                  <Dropdown
                    options={dataTypeDropdown}
                    setinput={setinput}
                    inputname={"categoryId"}
                    ChangesetValuefromparent={ChangesetValuefromparent}
                    valuefromparent={valuefromparent}
                    onChange={handleTypeChange}
                  />
                </div>
              </div>

              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(event) => getAttFilter()}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการข้อมูลท่องเที่ยวจังหวัด
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/AddRecommendatt"
              actionsBtn={enableButton}
              columnsname={columnsName}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={data}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                id="exampleModalLabel"
              >
                ดูรายละเอียด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูล
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ชื่อ: {attname}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ประเภท: {attType}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  เบอร์โทรศัพท์: {attCall}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  วันทำการ: {attTime}
                </p>
              </div>
              <div className="row">
                <div className="col-5 ml-3">
                  <p className="fontReg font-size03 colorGray01">
                    เวลาเปิดทำการ: {attOpen}
                  </p>
                </div>
                <div className="col-5 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    เวลาปิดทำการ: {attClose}
                  </p>
                </div>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ที่อยู่: {attAddress}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  รายละเอียด: {attDitail}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ละติจูด: {latitude}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ลองจิจูด: {longtitude}
                </p>
              </div>
              <div
                className={
                  images === "https://nw-smartsports.verkoffice.com/appnull" ||
                  images === "" ||
                  images === null
                    ? "d-none"
                    : "col-12 ml-2 mb-3"
                }
              >
                <span className="fontReg font-size03 colorGray01 d-flex">
                  รูปภาพ:{" "}
                </span>
                <img
                  src={images}
                  alt="Type Sport"
                  className={
                    images ===
                      "https://nw-smartsports.verkoffice.com/appnull" ||
                    images === "" ||
                    images === null
                      ? "d-none"
                      : "im-sports-type-dt"
                  }
                />
              </div>
              <div className="col mx-2 ">
                <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                  รายการแนะนำ
                  <input
                    type="checkbox"
                    value={status}
                    id="recommen"
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Advrecommendatt;
