import React, { useEffect, useState } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../pages/Loading";
import { getPrivacy, updatePrivacy } from "../../api/privacy_api";

function Privacyprivacy() {
  const [isLoading, setLoading] = useState(false);
  const [pdpa, setPdpa] = useState("");
  const [type, settype] = useState("pdpa");

  const navigate = useNavigate();
  const swalWithBootstrapButtons = Swal.mixin();

  async function getList() {
    setLoading(true);
    const ref = await getPrivacy();
    const data = ref.data.data;
    await setLoading(false);
    setPdpa(data.pdpa);
  }

  const editPdpa = async () => {
    if (pdpa === "" || pdpa === null) {
      swalWithBootstrapButtons.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      });
    } else {
      const body = {
        pdpa: pdpa,
        type: type,
      };
      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await updatePrivacy(body);
            if (response.status == "200") {
              swalWithBootstrapButtons
                .fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                })
                .then(async (result) => {
                  if (result.isConfirmed) {
                    getList();
                  }
                });
            } else {
              swalWithBootstrapButtons.fire({
                title: "เกิดข้อผิดพลาด",
                icon: "error",
                confirmButtonText: "ยืนยัน",
              });
              // navigate('/athleteAmt')
            }
          }
        });
    }
  };

  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/privacy");
      }
    });
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"นโยบายความเป็นส่วนตัว"} />
      <Menu parentmenu={"policy"} activemenu={"privacy"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-12 mt-2">
              <img
                src="assets/icons/im-policy.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                นโยบายและเงื่อนไข
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | นโยบายความเป็นส่วนตัว
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>นโยบายความเป็นส่วนตัว
                </p>
              </div>
              <div className="col-12 mt-3 mt-lg-0">
                <CKEditor
                  editor={Editor}
                  config={{
                    // plugins: [ Paragraph, Bold, Italic, Essentials ],
                    // toolbar: [ 'bold', 'italic' ]
                    fontSize: {
                      options: [
                        9,
                        11,
                        13,
                        "default",
                        17,
                        19,
                        21,
                        24,
                        28,
                        32,
                        36,
                        38,
                        42,
                      ],
                    },
                  }}
                  data={pdpa ? pdpa : ""}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    setPdpa(data);
                    // console.log("c",data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button className="btn-save" onClick={editPdpa}>
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacyprivacy;
