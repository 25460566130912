import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownArenas } from "../dataMockup/DataMockDropdown";
import UploadFileXLS from "../uploads/UploadFileXLS";
import Select from "react-select";
import { getDataSubArea, postCreateSubarena } from "../config/configApi-Area";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import {
  verifyImport,
  submitImportData,
  getSubArenaSearch,
} from "../../api/subarena_api";
import { getArena } from "../../api/arena_api";
import ImportExcel from "../uploads/ExcelRender";
import Loading from "../pages/Loading";
import { createSubArena } from "../../api/subarena_api";

import {
  getStaffList,
  getStaffById,
  removeStaff,
  getDataStaffFilter,
} from "../../api/staff_api";
function Addeditarenasub() {
  const [isLoading, setLoading] = useState(null);
  const [inputForm, setInputForm] = useState({
    inp1: "", //สนามกีฬา
    inp2: "", //สนามกีฬาย่อย
    inp3: "", //เจ้าหน้าที่
  });

  const [dataStaff, setDatastaff] = useState([]);
  const [dataSubsp, setDataDropSubsp] = useState([]);
  const [arenaId, setArenaId] = useState(null);
  const [subArenaName, setSubArenaName] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedata, setSelectedata] = useState([]);
  const [checked, setChecked] = useState(null);
  let chData = null;
  const optionss = [
    { value: "a", label: "a" },
    { value: "b", label: "b" },
  ];
  useEffect(() => {
    // getSubArea();
    getStaff();
    getArenaDropdown();
  }, []);

  const handleChange = (options, dataValue, index) => {
    // console.log("indexDt review : ", indexDt);
    // console.log("data arena : ", data);
    const { name, value } = options;
    const list = [...arenaSubList];
    list[index]["data"] = options;
    setArenaSubList(list);
    // setSelected(options);

    // setSelectedOptions(options);
    // let dataPush = [];
    // dataPush.push({
    //   // indexDt: [
    //   // {
    //   index: index,
    //   arenaName: dataValue,
    //   data: options,
    //   // },
    //   // ],
    // });
    // console.log("dataPush reviewww : ", dataPush);
    // setSelectedata(dataPush);
    // setSelectedOptions((prev) => [...prev, options]);
  };
  // console.log("selectedata reviewww : ", selectedata);
  // console.log("selected preview test data : ", selected);
  // test -----------------
  const [transformedData, setTransformedData] = useState([]);
  const originalData = [
    {
      subarena: [
        { value: 7407, label: "test test", staffID: 7407 },
        { value: 7409, label: "test test", staffID: 7409 },
      ],
    },
  ];
  // console.log("transformedData data : ", transformedData);
  useEffect(() => {
    const groupedData = originalData.reduce((result, currentItem) => {
      const existingGroup = result.find(
        (group) => group.label === currentItem.label
      );

      if (existingGroup) {
        existingGroup.staffID.push(currentItem.staffID);
      } else {
        result.push({
          label: currentItem.label,
          staffID: [currentItem.staffID],
          value: currentItem.value,
        });
      }

      return result;
    }, []);

    setTransformedData(groupedData);
  }, []);
  // ----------------------
  const getArenaDropdown = async () => {
    const respon = await getArena();

    let rowsdata = respon.data.data.map((data, i) => {
      const obj = {
        value: data.arenaID,
        text: data.arenaName,
        subArenaID: data.subArenaID,
      };
      return obj;
    });

    getFilterdropdown(rowsdata);
  };
  const getSubarenaData = async (id) => {
    const respon = await getSubArenaSearch(id);
    let dt = [];
    let data = respon.data.data;
    console.log("arenaSubList data : ", arenaSubList);
    // const list = [...arenaSubList];

    const list = [...arenaSubList];
    // list[index][name] = value;

    setArenaSubList(list);
    if (respon.data.data.length > 0) {
      // for (let i = 0; i < data.length; i++) {
      //   list[i] = {
      //     arenaSub: data[i].subArenaName,
      //     subArenaId: data[i].subArenaID,
      //     subArenaType: data[i].subArenaType,
      //     subArenaGate: data[i].gate,
      //     status: false,
      //   };
      // }
      console.log("list data test  : ", list);

      // respon.data.data.forEach((item, i) => {
      //   return dt.push({
      //     value: item.subArenaID,
      //     text: item.subArenaName,
      //   });
      // });
    } else {
      // Swal.fire({
      //   title: "ไม่พบข้อมูลสนามกีฬาย่อย กรุณาเลือกสนามกีฬาใหม่",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "ยืนยัน",
      //   cancelButtonText: "ยกเลิก",
      //   reverseButtons: true,
      // });
    }
  };
  const getSubArea = async () => {
    const respon = await getDataSubArea();
    let rowsdata = [];
    respon.data.data.forEach((data, i) => {
      return rowsdata.push({
        value: data.subArenaID,
        text: data.subArenaName,
        subArenaID: data.subArenaID,
      });
    });
  };
  // console.log("selectedOptions preview : ", selectedOptions);
  console.log("arenaId preview : ", arenaId);
  const submitData = async () => {
    try {
      // const body = {
      //   arenaId: arenaId,
      //   subArenaName: subArenaName,
      //   staffID: selectedOptions.map((item) => item.staffID),
      //   active: checked === null ? false : checked,
      // };
      // // console.log("body preview data : ", body);

      // if (
      //   body.arenaId === null ||
      //   body.subArenaName === null ||
      //   body.staffID.length < 1
      // ) {
      // after-----
      //

      // console.log("arenaSubList preview : ", arenaSubList);

      // dataSubarena.subarena.push(transformed);
      // console.log("dataSubarena send : ", dataSubarena);
      // console.log("transformed arenaSubList preview : ", transformed);
      let foundData = false;
      for (const key in arenaSubList) {
        if (arenaSubList.hasOwnProperty(key)) {
          const dataArray = arenaSubList[key];
          console.log("dataArray : ", dataArray.arenaSub);
          if (dataArray.arenaSub === "" || dataArray.data === undefined) {
            foundData = true;
          }
          // const matchingItems = dataArray.filter(
          //   (item) => item.arenaSub === ""
          // );
        }
      }
      console.log("foundData :", foundData);
      let dataSubarena = {
        arenaId: arenaId,
        subarena: [],
      };

      if (foundData === true) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        const transformed = arenaSubList.map((group) => {
          const staffIDs = group.data.map((item) => item.staffID);
          return {
            subArenaId: group.subArenaId ? group.subArenaId : null,
            subArenaName: group.arenaSub ? group.arenaSub : null,
            subArenaGate: group.subArenaGate ? group.subArenaGate : null,
            subArenaType: group.subArenaType ? group.subArenaType : null,
            staffID: staffIDs,
            status: group.status,
          };
        });

        for (let i = 0; i < transformed.length; i++) {
          dataSubarena.subarena.push(transformed[i]);
        }
        setTransformedData(transformed);
        console.log("dataSubarena : ", dataSubarena);
        await Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              await setLoading(true);
              const respon = await createSubArena(dataSubarena);

              // const respon = await postDataUpdateArea(id, body);
              await setLoading(false);
              // const respon = null;
              if (respon.data.status === "success") {
                Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(true);
                    navigate("/arenaSub");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/arenaSub");
                  }
                });
              }
            })();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStaff = async () => {
    const rowData = [];
    try {
      const response = await getStaffList();

      if (response) {
        const dataStaff = response.data.data;

        dataStaff.forEach((dt, i) => {
          return rowData.push({
            value: dt.staffID,
            label: dt.firstname + " " + dt.lastanme,
            staffID: dt.staffID,
          });
        });
        // setDatastaff(rowData);
        getFilterdropdownstaff(rowData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getFilterdropdownstaff = async (data) => {
    var dataArr = data.map((item) => {
      return [item.label, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setDatastaff(result);
  };
  const getFilterdropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setDataDropSubsp(result);
  };
  const postSubarena = async (event) => {
    let body = [];

    const filteredSp = dataSubsp.filter((data) => inputForm.inp1 === data.text);
    const filtered = arenaSubList.filter((person) => person.arenaSub === "");
    if (filtered.length > 0) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      for (let i = 0; i < arenaSubList.length; i++) {
        for (let j = 0; j < selected.length; j++) {
          body.push({
            arenaId: filteredSp[0].subArenaID,
            subArenaName: arenaSubList[i].arenaSub,
            subArenaType: null,
            subArenaGate: null,
            staffID: j === i ? selected[j].staffID : selected[j].staffID,
            status: false,
          });
        }
      }

      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            const respon = await postCreateSubarena(body);

            setLoading(1);
            if (respon.data.status === "success") {
              Swal.fire({
                title: "บันทึกข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  setLoading(1);
                  navigate("/arenaSub");
                }
              });
            }
          })();
        }
      });
    }
  };
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [arenaSubList, setArenaSubList] = useState([
    { arenaSub: "", status: false },
  ]);
  console.log("arenaSubList preview test : ", arenaSubList);
  let sportTypeList = [];
  const handleArenaSubChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...arenaSubList];
    list[index][name] = value;

    setArenaSubList(list);
    // console.log("list data", list);
  };

  const handleArenaSubRemove = (index) => {
    const list = [...arenaSubList];
    list.splice(index, 1);
    setArenaSubList(list);
  };

  const handleArenaSubAdd = (index, chbox) => {
    console.log("index :", index);
    console.log("chbox :", chbox);
    const list = [...arenaSubList];
    console.log("list data : ", list);
    list[index]["status"] = chbox;
    setArenaSubList(list);
  };
  const [groupShow, setGroupShow] = useState(false);
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/arenaSub");
      }
    });
  }

  const options = [
    { value: 0, label: "นางสายไหม อุ่นดี" },
    { value: 1, label: "นายบุญเลิศ ขาวสะอาด" },
    { value: 2, label: "นายสมชาย ทรายขาว" },
    { value: 3, label: "นางสาวแสนดี ดีใจ" },
  ];
  const [arenaDrop, setArenaDrop] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  // *********************** Take Import DATA ************************************
  // const [importData, setImportData] = useState([]);
  // const [isVerify, setIsVerify] = useState(true);
  // const [verifySuccess, setVerifySuccess] = useState(0);
  // const [verifySkip, setVerifySkip] = useState(0);
  // const [totalRow, setTotalRow] = useState(0);
  //
  //
  // // new
  // const [failedRow, setFailedRow] = useState([]);
  // const [verifyNew, setVerifyNew] = useState(0);
  // const handleImport1 = async (rows) => {
  //   await setImportData(rows);
  // };
  // const handleImport = async (rows) => {
  //   setVerifySuccess(0);
  //   setVerifySkip(0);
  //   setVerifyNew(0);
  //   setTotalRow(rows.length - 1);

  //   const row = [];
  //   rows.slice(1).map((item, index) => {
  //     if (item.length > 0) {
  //       row.push({
  //         key: index,
  //         subarenaName: item[0],
  //         active: item[1],
  //       });
  //     }
  //   });
  //   await setImportData(row);
  // };

  // const verifyData1 = async () => {
  //   try {
  //     if (selectedValue === "") {
  //       // alert("กรุณาเลือก สนามกีฬา");
  //       Swal.fire({
  //         title: "กรุณาเลือก สนามกีฬา",
  //         icon: "error",
  //         confirmButtonText: "ตกลง",
  //       });
  //       return;
  //     }
  //     let success = 0;
  //     let skip = 0;
  //     let error = 0;
  //     const batchSize = 10;
  //     const sportId = selectedValue;
  //     setTotalRow(importData.length - 1);
  //     for (let i = 1; i < importData.length; i += batchSize) {
  //       const batch = importData.slice(i, i + batchSize);
  //       const data = batch.map((item) => {
  //         const sport = {
  //           subarenaName: item[0],
  //           active: item[1],
  //         };
  //         return sport;
  //       });

  //       const dataToVerify = {
  //         arenaId: sportId,
  //         importData: data,
  //       };
  //       const response = await verifyImport(dataToVerify);
  //       if (response) {
  //         success += response.data.success;
  //         skip += response.data.skip;
  //         setVerifySuccess(success);
  //         setVerifySkip(skip);
  //       }
  //       // else {
  //       //   error++;
  //       // }
  //       else {
  //         error++;
  //         Swal.fire({
  //           title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
  //           icon: "error",
  //           confirmButtonText: "ตกลง",
  //         });
  //         return;
  //       }
  //     }
  //     if (error === 0) {
  //       Swal.fire({
  //         title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
  //         icon: "success",
  //         confirmButtonText: "ตกลง",
  //       });
  //       setIsVerify(false);
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       title: "เกิดข้อผิดพลาด",
  //       icon: "error",
  //       showCancelButton: true,
  //       confirmButtonText: "ยืนยัน",
  //       cancelButtonText: `ยกเลิก`,
  //       reverseButtons: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         navigate("/arenaSub");
  //       }
  //     });
  //     console.log(error);
  //   }
  // };
  // const verifyData = async () => {
  //   if (selectedValue === "") {
  //     // alert("กรุณาเลือก สนามกีฬา");
  //     Swal.fire({
  //       title: "กรุณาเลือก สนามกีฬา",
  //       icon: "error",
  //       confirmButtonText: "ตกลง",
  //     });
  //     return;
  //   } else {
  //     try {
  //       setVerifySuccess(0);
  //       setVerifySkip(0);
  //       setVerifyNew(0);
  //       setFailedRow([]);
  //       let create = 0;
  //       let success = 0;
  //       let skip = 0;
  //       let error = 0;
  //       const batchSize = 10;
  //       const failedArr = [];
  //       setTotalRow(importData.length - 1);
  //       for (let i = 1; i < importData.length; i += batchSize) {
  //         const batch = importData.slice(i, i + batchSize);
  //         const data = batch.map((item) => ({ ...item }));
  //         const dataToVerify = {
  //           memberType: 2,
  //           importData: data,
  //         };
  //         const response = await verifyImport(dataToVerify);
  //         if (response) {
  //           success += response.data.success;
  //           skip += response.data.update;
  //           create += response.data.create;

  //           const failedData = response.data.verifyFailed;

  //           failedData.map((item) => {
  //             failedArr.push(item);
  //           });

  //           setVerifySuccess(success);
  //           setVerifySkip(skip);
  //           setVerifyNew(create);
  //         } else {
  //           error++;
  //           Swal.fire({
  //             title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
  //             icon: "error",
  //             confirmButtonText: "ตกลง",
  //           });
  //           return;
  //         }
  //       }

  //       setFailedRow(failedArr);

  //       if (error === 0) {
  //         Swal.fire({
  //           title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
  //           icon: "success",
  //           confirmButtonText: "ตกลง",
  //         });
  //         failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
  //         // setIsVerify(false)
  //       }
  //     } catch (error) {
  //       Swal.fire({
  //         title: "เกิดข้อผิดพลาด",
  //         icon: "error",
  //         showCancelButton: true,
  //         confirmButtonText: "ยืนยัน",
  //         cancelButtonText: `ยกเลิก`,
  //         reverseButtons: true,
  //       });
  //     }
  //   }
  // };

  // const importSport1 = async () => {
  //   try {
  //     setVerifySuccess(0);
  //     let success = 0;
  //     let skip = 0;
  //     let error = 0;
  //     const sportId = selectedValue;
  //     const batchSize = 10;
  //     setTotalRow(importData.length - 1);
  //     for (let i = 1; i < importData.length; i += batchSize) {
  //       const batch = importData.slice(i, i + batchSize);
  //       const data = batch.map((item) => {
  //         const sport = {
  //           subarenaName: item[0],
  //           active: item[1],
  //         };
  //         return sport;
  //       });

  //       const dataToVerify = {
  //         arenaId: sportId,
  //         importData: data,
  //       };
  //       await Swal.fire({
  //         title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonText: "ยืนยัน",
  //         cancelButtonText: "ยกเลิก",
  //         reverseButtons: true,
  //       }).then(async (result) => {
  //         error = result.isConfirmed;
  //         if (result.isConfirmed) {
  //           const response = await submitImportData(dataToVerify);
  //           if (response) {
  //             success += response.data.success;
  //             skip += response.data.skip;
  //             setVerifySuccess(success);
  //             setVerifySkip(skip);
  //           } else {
  //             error++;
  //           }
  //         }
  //         if (error === true) {
  //           Swal.fire({
  //             title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
  //             icon: "success",
  //             confirmButtonText: "ตกลง",
  //           }).then((result) => {
  //             if (result.isConfirmed) {
  //               navigate("/arenaSub");
  //             }
  //           });

  //           setIsVerify(false);
  //         } else if (error === false) {
  //           Swal.fire({
  //             icon: "warning",
  //             title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
  //             showCancelButton: true,
  //             confirmButtonText: "ยืนยัน",
  //             cancelButtonText: "ยกเลิก",
  //             reverseButtons: true,
  //           }).then((result) => {
  //             if (result.isConfirmed) {
  //               navigate("/arenaSub");
  //             }
  //           });
  //         } else {
  //           return;
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       title: "เกิดข้อผิดพลาด",
  //       icon: "error",
  //       showCancelButton: true,
  //       confirmButtonText: "ยืนยัน",
  //       cancelButtonText: `ยกเลิก`,
  //       reverseButtons: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         navigate("/arenaSub");
  //       }
  //     });
  //     console.log(error);
  //   }
  // };
  // const importSport = async () => {
  //   try {
  //     setVerifySuccess(0);
  //     let success = 0;
  //     let skip = 0;
  //     let error = 0;
  //     const batchSize = 10;
  //     setTotalRow(importData.length - 1);

  //     await Swal.fire({
  //       title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "ยืนยัน",
  //       cancelButtonText: "ยกเลิก",
  //       reverseButtons: true,
  //     }).then(async (result) => {
  //       error = result.isConfirmed;
  //       if (result.isConfirmed) {
  //         for (let i = 1; i < importData.length; i += batchSize) {
  //           const batch = importData.slice(i, i + batchSize);
  //           const data = batch.map((item) => ({ ...item }));
  //           const dataToVerify = {
  //             importData: data,
  //           };
  //           const response = await submitImportData(dataToVerify);
  //           if (response) {
  //             success += response.data.success;
  //             skip += response.data.skip;
  //             setVerifySuccess(success);
  //             setVerifySkip(skip);
  //           } else {
  //             error++;
  //             Swal.fire({
  //               title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
  //               icon: "error",
  //               confirmButtonText: "ตกลง",
  //             });
  //             return;
  //           }
  //         }
  //       } else {
  //         return;
  //       }
  //     });
  //     if (error === true) {
  //       Swal.fire({
  //         title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
  //         icon: "success",
  //         confirmButtonText: "ตกลง",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           setIsVerify(false);
  //           navigate("/arenaSub");
  //         }
  //       });
  //     } else if (error === false) {
  //       Swal.fire({
  //         title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonText: "ยืนยัน",
  //         cancelButtonText: `ยกเลิก`,
  //         reverseButtons: true,
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           navigate("/arenaSub");
  //         }
  //       });
  //     }
  //     // if (error === 0) {
  //     //   Swal.fire({
  //     //     title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
  //     //     icon: "success",
  //     //     confirmButtonText: "ตกลง",
  //     //   }).then((result) => {
  //     //     if (result.isConfirmed) {
  //     //       setIsVerify(false);
  //     //       navigateToBack("/competitionSchedule");
  //     //     }
  //     //   });
  //     // } else {
  //     //   Swal.fire({
  //     //     title: "เกิดข้อผิดพลาด",
  //     //     icon: "error",
  //     //     confirmButtonText: "ตกลง",
  //     //   });
  //     // }
  //   } catch (error) {
  //     Swal.fire({
  //       title: "เกิดข้อผิดพลาด",
  //       icon: "error",
  //       showCancelButton: true,
  //       confirmButtonText: "ยืนยัน",
  //       cancelButtonText: `ยกเลิก`,
  //       reverseButtons: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         navigate("/arenaSub");
  //       }
  //     });
  //   }
  //   // try {
  //   //   setVerifySuccess(0);
  //   //   let success = 0;
  //   //   let skip = 0;
  //   //   let error = 0;
  //   //   const batchSize = 10;
  //   //   setTotalRow(importData.length - 1);

  //   //   await Swal.fire({
  //   //     title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
  //   //     icon: "warning",
  //   //     showCancelButton: true,
  //   //     confirmButtonText: "ยืนยัน",
  //   //     cancelButtonText: "ยกเลิก",
  //   //     reverseButtons: true,
  //   //   }).then(async (result) => {
  //   //     error = result.isConfirmed;
  //   //     if (result.isConfirmed) {
  //   //       for (let i = 1; i < importData.length; i += batchSize) {
  //   //         const batch = importData.slice(i, i + batchSize);
  //   //         const data = batch.map((item) => {
  //   //           const sport = {
  //   //             campName: item[0],
  //   //             coordinatorName: item[1],
  //   //             coordinatorTel: item[2],
  //   //             bed: item[3],
  //   //             active: item[4],
  //   //           };
  //   //           return sport;
  //   //         });
  //   //         const response = await submitImportData(data);
  //   //         if (response) {
  //   //           success += response.data.success;
  //   //           skip += response.data.skip;
  //   //           setVerifySuccess(success);
  //   //           setVerifySkip(skip);
  //   //         } else {
  //   //           error++;
  //   //         }
  //   //       }
  //   //     } else {
  //   //       return;
  //   //     }
  //   //   });
  //   //   console.log("error :", error);
  //   //   if (error === true) {
  //   //     Swal.fire({
  //   //       title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
  //   //       icon: "success",
  //   //       confirmButtonText: "ตกลง",
  //   //     }).then((result) => {
  //   //       if (result.isConfirmed) {
  //   //         setIsVerify(false);
  //   //         navigateToBack("/athleteAmt");
  //   //       }
  //   //     });
  //   //   } else if (error === false) {
  //   //     Swal.fire({
  //   //       title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
  //   //       icon: "warning",
  //   //       showCancelButton: true,
  //   //       confirmButtonText: "ยืนยัน",
  //   //       cancelButtonText: "ยกเลิก",
  //   //       reverseButtons: true,
  //   //     }).then((result) => {
  //   //       if (result.isConfirmed) {
  //   //         navigateToBack("/athleteAmt");
  //   //       }
  //   //     });
  //   //   } else {
  //   //     Swal.fire({
  //   //       title: "เกิดข้อผิดพลาด",
  //   //       icon: "error",
  //   //       confirmButtonText: "ตกลง",
  //   //     });
  //   //   }
  //   // } catch (error) {
  //   //   Swal.fire({
  //   //     title: "เกิดข้อผิดพลาด",
  //   //     icon: "error",
  //   //     showCancelButton: true,
  //   //     confirmButtonText: "ยืนยัน",
  //   //     cancelButtonText: `ยกเลิก`,
  //   //     reverseButtons: true,
  //   //   }).then((result) => {
  //   //     if (result.isConfirmed) {
  //   //       navigateToBack("/athleteAmt");
  //   //     }
  //   //   });
  //   //   console.log(error);
  //   // }
  // };
  // *********************** after ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);

  const handleImport = async (rows) => {
    // console.log("rows data : ", rows);
    // console.log("rows.length - 1 preview : ", rows.length - 1);
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);
    // console.log("rows.slice(1) preview  ", rows.slice(1));
    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          subarenaName: item[0],
          active: item[1],
        });
      }
    });
    await setImportData(row);
    // console.log("row data preview : ", row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length);
      if (selectedValue) {
        for (let i = 0; i < importData.length; i += batchSize) {
          const batch = importData.slice(i, i + batchSize);
          const data = batch.map((item) => ({ ...item }));
          const dataToVerify = {
            arenaId: selectedValue,
            importData: data,
          };
          const response = await verifyImport(dataToVerify);
          if (response) {
            success += response.data.success;
            skip += response.data.update;
            create += response.data.create;

            const failedData = response.data.verifyFailed;

            failedData.map((item) => {
              failedArr.push(item);
            });

            setVerifySuccess(success);
            setVerifySkip(skip);
            setVerifyNew(create);
          } else {
            error++;
            Swal.fire({
              title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
              icon: "error",
              confirmButtonText: "ตกลง",
            });
            return;
          }
        }

        setFailedRow(failedArr);

        if (error === 0) {
          Swal.fire({
            title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "success",
            confirmButtonText: "ตกลง",
          });
          failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
          // setIsVerify(false)
        }
      } else {
        Swal.fire({
          title: "กรุณาเลือก สนามกีฬา",
          icon: "error",
          confirmButtonText: "ยืนยัน",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importSport = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);
      if (selectedValue) {
        await Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then(async (result) => {
          error = result.isConfirmed;
          if (result.isConfirmed) {
            for (let i = 0; i < importData.length; i += batchSize) {
              const batch = importData.slice(i, i + batchSize);
              const data = batch.map((item) => ({ ...item }));
              const dataToVerify = {
                arenaId: selectedValue,
                importData: data,
              };
              const response = await submitImportData(dataToVerify);
              if (response) {
                success += response.data.success;
                skip += response.data.skip;
                setVerifySuccess(success);
                setVerifySkip(skip);
              } else {
                error++;
                Swal.fire({
                  title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                  icon: "error",
                  confirmButtonText: "ตกลง",
                });
                return;
              }
            }
          } else {
            return;
          }
        });
        if (error === true) {
          Swal.fire({
            title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then((result) => {
            if (result.isConfirmed) {
              setIsVerify(false);
              navigate("/arenaSub");
            }
          });
        } else if (error === false) {
          Swal.fire({
            title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/arenaSub");
            }
          });
        }
      } else {
        Swal.fire({
          title: "กรุณาเลือก สนามกีฬา",
          icon: "error",
          confirmButtonText: "ยืนยัน",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/arenaSub");
        }
      });
    }
  };
  // ---------------------------------------------
  const handleArenaSubAddRow = () => {
    let dataArr = [...arenaSubList];
    dataArr.push({
      arenaSub: "",
      gate: "กรุณาเลือกข้อมูล",
      id: null,
      label: "กรุณาเลือกข้อมูล",
      staffID: null,
      subArenaType: null,
      value: "กรุณาเลือกข้อมูล",
      status: false,
    });
    console.log("dataArrv preview : ", dataArr);
    setArenaSubList(dataArr);
    // setArenaSubList([
    //   ...arenaSubList,

    //   [
    //     {
    //       arenaSub: "",
    //       gate: "กรุณาเลือกข้อมูล",
    //       id: null,
    //       label: "กรุณาเลือกข้อมูล",
    //       staffID: null,
    //       subArenaType: null,
    //       value: "กรุณาเลือกข้อมูล",
    //       status: true,
    //     },
    //   ],
    // ]);
  };
  const getArenaDropDown = async () => {
    try {
      const response = await getArena();
      if (response.status == 200) {
        const dropdown = response.data.data;

        const subType = dropdown.map((item) => {
          const arena = {
            value: item.arenaID,
            text: item.arenaName,
            subType: [],
          };
          return arena;
        });

        setArenaDrop(subType);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDropdownArenaChange = (value) => {
    setArenaId(value);
    getSubarenaData(value);
  };

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    getArenaDropDown();
  }, []);
  // *********************** Take Import DATA ************************************

  return (
    <>
      {/* {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()} */}
      {isLoading === true ? <Loading /> : <></>}
      <Header parentmenu={"สนามกีฬาย่อย"} />
      <Menu parentmenu={"masterData"} activemenu={"arenaSub"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                สนามกีฬาย่อย
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / สนามกีฬาย่อย
              </font>
            </div>
            <div
              className={`col-4 col-sm-3 justify-content-end align-items-center ${
                !groupShow ? "d-flex" : "d-none"
              } `}
            >
              <button
                className="btn-blue03 mr-2 mr-xl-3 w-100"
                onClick={() => setGroupShow(true)}
              >
                <img
                  src="assets/icons/ic-add.png"
                  alt="ExportFiles Icon"
                  className="ic-add"
                />
                <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                  เพิ่มข้อมูลหลายรายการ
                </span>
              </button>
            </div>
            <div
              className={`col-4 col-sm-3 justify-content-end align-items-center ${
                groupShow ? "d-flex" : "d-none"
              } `}
            >
              <button
                className="btn-blue03 mr-2 mr-xl-3 w-100"
                onClick={() => setGroupShow(false)}
              >
                <img
                  src="assets/icons/ic-add.png"
                  alt="ExportFiles Icon"
                  className="ic-add"
                />
                <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                  เพิ่มข้อมูลรายการเดียว
                </span>
              </button>
            </div>
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละรายการ
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg-6">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สนามกีฬา
                  </p>
                  <Dropdown
                    options={dataSubsp}
                    setinput={setinput}
                    onChange={handleDropdownArenaChange}
                    inputname={"inp1"}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>สนามกีฬาย่อย
                </p>
              </div>
            </div>
            <div className="row justify-content-start fontReg font-size03">
              <div className="col-12">
                <form className="App" autoComplete="off">
                  <div className="form-field">
                    {arenaSubList.map((singleArenaSub, index) => (
                      <div key={index} className="sports mt-3">
                        <div className="first-division w-100">
                          <div className="row w-100 m-0">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-6">
                                  <input
                                    name="arenaSub"
                                    type="text"
                                    id="arenaSub"
                                    className="inp-data02"
                                    placeholder="รายการสนามกีฬาย่อย"
                                    value={singleArenaSub.arenaSub}
                                    onChange={(e) => {
                                      setSubArenaName(e.target.value);
                                      handleArenaSubChange(e, index);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row align-items-baseline">
                                <div className="col-6">
                                  <div className="p-0 mt-2">
                                    <div className="selected-dpdw">
                                      <p className="fontReg font-size03 colorGray02 mb-1">
                                        เลือกเจ้าหน้าที่
                                      </p>
                                      <Select
                                        isMulti
                                        name="colors"
                                        options={dataStaff}
                                        setinput={setinput}
                                        inputname={"inp3"}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            arenaSubList[index].arenaSub,
                                            index
                                          )
                                        }
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="second-division">
                                  {arenaSubList.length !== 1 && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleArenaSubRemove(index)
                                      }
                                      className="btn-remove-field"
                                    >
                                      <img
                                        src="assets/icons/remove-field.png"
                                        alt="ExportFiles Icon"
                                        className="ic-add mr-0"
                                      />
                                    </button>
                                  )}
                                </div>

                                <div className="third-division">
                                  {arenaSubList.length - 1 === index && (
                                    <button
                                      type="button"
                                      onClick={() => handleArenaSubAddRow()}
                                      className="btn-add-field"
                                    >
                                      <img
                                        src="assets/icons/add-field.png"
                                        alt="ExportFiles Icon"
                                        className="ic-add mr-0"
                                      />
                                    </button>
                                  )}
                                </div>

                                <div className="ml-2 ml-sm-3">
                                  <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center mb-0">
                                    ใช้งาน
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        singleArenaSub.length > 0
                                          ? singleArenaSub[0].status
                                          : ""
                                      }
                                      onChange={() =>
                                        handleArenaSubAdd(
                                          index,
                                          !singleArenaSub[index]
                                        )
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  // onClick={(event) => postSubarena()}
                  onClick={(event) => submitData()}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg-6">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สนามกีฬา
                  </p>
                  <Dropdown
                    disabled={isVerify}
                    options={arenaDrop}
                    onChange={handleDropdownChange}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/004-masterdata-sub_areana_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={
                    importData.length == 0 && selectedValue == "" ? true : false
                  }
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Addeditarenasub;
