import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import "../pages/Mastersporttype.css";
// import Button3items from '../button/Button3items';
import { useState, useEffect } from "react";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import Button3items from "../button/Button3items";
import { getPermissionList } from "../../api/permission_api";
import {
  getAdmin,
  getAdminById,
  removeAdmin,
  getAdminFilter,
} from "../../api/admin_api";
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "./Loading";

function Adminlist() {
  const enableButton = { view: true, edit: true, delete: true };
  const [spanloading, setSpanLoading] = useState(false);
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const DataDropdownPermission = [
    { value: "", text: "ทั้งหมด" },
    { value: "เจ้าหน้าที่ดูแลสนาม", text: "เจ้าหน้าที่ดูแลสนาม" },
    { value: "นักกีฬา", text: "นักกีฬา" },
    { value: "โค้ช", text: "โค้ช" },
    { value: "ร้านค้า / ร้านอาหาร", text: "ร้านค้า / ร้านอาหาร" },
    { value: "บุคคลทั่วไป", text: "บุคคลทั่วไป" },
    { value: "VIP", text: "VIP" },
    { value: "เจ้าหน้าที่ดูแลที่พัก", text: "เจ้าหน้าที่ดูแลที่พัก" },
  ];

  const columnsname = ["id", "name", "surName", "Permission", "action"];
  const columnsText = ["ลำดับที่", "ชื่อ", "นามสกุล", "สิทธ์", "เครื่องมือ"];
  const columnsType = ["text", "text", "text", "text", "action"];
  const rowsData = [
    {
      id: { value: "1", type: "text" },
      name: { value: "ศิรภูมิ", type: "text" },
      surName: { value: "สุทธิสาร", type: "text" },
      Permission: { value: "Admin", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "2", type: "text" },
      name: { value: "ศิรภูมิ", type: "text" },
      surName: { value: "สุทธิสาร", type: "text" },
      Permission: { value: "Admin", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "3", type: "text" },
      name: { value: "ศิรภูมิ", type: "text" },
      surName: { value: "สุทธิสาร", type: "text" },
      Permission: { value: "Admin", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "4", type: "text" },
      name: { value: "ศิรภูมิ", type: "text" },
      surName: { value: "สุทธิสาร", type: "text" },
      Permission: { value: "Admin", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "5", type: "text" },
      name: { value: "ศิรภูมิ", type: "text" },
      surName: { value: "สุทธิสาร", type: "text" },
      Permission: { value: "Admin", type: "text" },
      action: { value: "1", type: "action" },
    },
  ];
  const getAdminDtById = async (id) => {
    const respon = await getAdminById(id);
    let dataph = [];
    let obDt = respon.data.data;
    dataph.push({
      firstname: obDt.firstname,
      lastanme: obDt.lastanme,
      email: obDt.email || "-",
      mobileNo: obDt.mobileNo || "-",
      img: obDt.img,
      permisionAccount:
        obDt.permisionAccount !== null
          ? obDt.permisionAccount.permissionName
          : "-",
    });
    setDataView(dataph[0]);
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/AddAdmin`);
        break;
      case "edit":
        // handle edit button
        navigate(`/AddAdmin?id=${id}`);
        break;
      case "view":
        getAdminDtById(id);
        // handle view button
        // viewModal(id)
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await removeAdmin(id);
            if (response.data.status === "success") {
              getDataAdmin();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    name: "",
    permissionId: "",
  });

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [name, setName] = useState("");
  const [dataView, setDataView] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.name = "";
    inputForm.permissionId = "";
    setValuefromparent1("ทั้งหมด");
    setSelectePermissions("");
    getDataAdmin();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  const handleExport = async (type) => {
    let Header = ["ลำดับที่", "ชื่อ", "นามสกุล", "สิทธ์"];
    let dt = {
      Permission: { value: "ผู้ดูแลระบบร้านค้า ร้านอาหาร", type: "text" },
      action: { value: "1", type: "action" },
      id: { value: 1, dataID: 7288, type: "text" },
      name: { value: "ศุภกิจ", type: "text" },
      surName: { value: "บัวมา", type: "text" },
    };
    let Data = getUserAdmin.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.name.value;
      dt[2] = elemento.surName.value;
      dt[3] = elemento.Permission.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานรายชื่อ Admin Back Office",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  useEffect(() => {
    getPermissionDropDown();
    getDataAdmin();
  }, []);

  const getDataAdmin = async () => {
    setSpanLoading(true);
    const respon = await getAdmin();
    let getData = [];
    // obDt.permisionAccount !== null
    // ? obDt.permisionAccount.permissionName
    // : "-",
    respon.data.data.forEach((objectData, i) => {
      return getData.push({
        id: {
          value: i + 1,
          dataID: objectData.staffID,
          type: "text",
        },
        name: { value: objectData?.firstname, type: "text" },
        surName: { value: objectData?.lastanme, type: "text" },
        Permission: {
          value:
            objectData.permisionAccount !== null
              ? objectData.permisionAccount.permissionName
              : "-",
          type: "text",
        },
        action: { value: "1", type: "action" },
      });
    });
    await setSpanLoading(false);
    setGetUserAdmin(getData);
  };

  const [getUserAdmin, setGetUserAdmin] = useState([]);
  const [permissionDropdown, setPermissionDropdown] = useState([]);
  const getPermissionDropDown = async () => {
    try {
      const response = await getPermissionList();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            id: {
              value: i + 1,
              dataID: item?.permissionAccountId,
              type: "text",
            },

            value: item.permissionAccountId,
            text: item.permissionName,
            subType: [],
          });
        });

        setPermissionDropdown(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedPermissions, setSelectePermissions] = useState("");
  const handlePermissionsChange = (value) => {
    setSelectePermissions(value);
  };

  const dataFilter = async () => {
    if (inputForm.name === "" && selectedPermissions === "") {
      getDataAdmin();
    } else {
      let bodyFilter = {
        inp1: inputForm.name,
        inp2: selectedPermissions,
      };
      let getData = [];
      setSpanLoading(true);
      const response = await getAdminFilter(bodyFilter);
      await setSpanLoading(false);
      response.data.data.forEach((objectData, i) => {
        return getData.push({
          id: {
            value: i + 1,
            dataID: objectData.staffID,
            type: "text",
          },
          name: { value: objectData?.firstname, type: "text" },
          surName: { value: objectData?.lastanme, type: "text" },
          Permission: {
            value:
              objectData.permisionAccount !== null
                ? objectData.permisionAccount.permissionName
                : "-",
            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      setGetUserAdmin(getData);
    }
  };

  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"ตั้งค่าแอดมิน"} />
      <Menu parentmenu={"adminSetting"} activemenu={"adminList"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-settingAdmin.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                รายชื่อ Admin Back Office
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ตั้งค่าแอดมิน Back Office / รายชื่อ Admin Back Office
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/AddAdmin"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูลชื่อ Admin "
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สิทธิ์{" "}
                  </p>
                  <Dropdown
                    options={permissionDropdown}
                    setinput={setinput}
                    inputname={"inp2"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handlePermissionsChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    dataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายชื่อ Admin
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={getUserAdmin}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                id="exampleModalLabel"
              >
                ดูรายละเอียด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูล
                </p>
              </div>
              <div className="row ml-2">
                <div className="col-6 ">
                  <p className="fontReg font-size03 colorGray01">
                    ชื่อ: {dataView.firstname}
                  </p>
                </div>
                <div className="col-6 ">
                  <p className="fontReg font-size03 colorGray01">
                    นามสกุล: {dataView.lastanme}
                  </p>
                </div>
              </div>
              <div className="row ml-2">
                <div className="col-6 ">
                  <p className="fontReg font-size03 colorGray01">
                    อีเมล : {dataView.email}
                  </p>
                </div>
                <div className="col-6">
                  <p className="fontReg font-size03 colorGray01">
                    เบอร์โทรศัพท์ : {dataView.mobileNo}
                  </p>
                </div>
              </div>
              <div className="col-12 mb-3">
                <span
                  className={
                    dataView.img === null
                      ? "d-none"
                      : "fontReg font-size03 colorGray01 d-flex"
                  }
                >
                  รูปภาพ:{" "}
                </span>
                <img
                  src={dataView.img}
                  alt="Type Sport"
                  className={
                    dataView.img === null ? "d-none" : "im-sports-type-dt"
                  }
                />
              </div>
              <div className="col-12 mt-4">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>Permission
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  {dataView.permisionAccount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Adminlist;
