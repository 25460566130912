import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";

const dataToken = localStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "172.21.220.158",
  // baseURL: "http://172.21.220.158:3000",
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const getNotificationlist = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const respon = await instace.post(instace.baseURL + ``, {
    //   headers: headers,
    //   params: query,
    // });
    const response = await instace.get("/notification", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getNotificationById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get(`/notification/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const creatNotification = async (formData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.post(`/notification`, formData, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const broadcastNotification = async (id) => {
  //
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.post(
      `/notification/broadcast/${id}`,
      {},
      {
        headers: headers,
      }
    );
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const updateNotification = async (id, formData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.put(`/notification/${id}`, formData, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const removeNotification = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.delete(`/notification/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getNotiFilter = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/notification`, {
      headers: headers,
      params: {
        // isSend: true,
        name: data.name === "" ? "" : data.name,
        // permissionMobileId: data.inp2 === "" ? "" : data.inp2,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
