import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UploadBanner from "../uploads/UploadIMG";
import {
  createBanner,
  getBannerById,
  updateBanner,
} from "../../api/banner_api";
import Swal from "sweetalert2";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import UploadIMGV2 from "../uploads/UploadIMGv2";
import { useNavigate } from "react-router-dom";
function Advmanagebanner(props) {
  let dataArr = [];
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(null);
  let id = new URLSearchParams(window.location.search).get("id");
  const [banner, setBanner] = useState([]);
  const [getDataimage, setGetDataimage] = useState([]);
  let [checked, setChecked] = useState(null);
  const handleSelectedImages = (images) => {
    setGetDataimage(images);
  };
  function navigateToBack(page) {
    navigate(page);
  }
  const handleCheck = (check) => {
    setChecked(check);
  };

  const postUpdateBanner = async () => {
    if (id) {
      if (getDataimage.length < 1 || banner.length < 1) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        let body = {
          bannerName: banner,
          bannerLink: null,
          active: checked,
          file: getDataimage[0].file,
        };

        await Swal.fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              // const respon = null;
              const respon = await updateBanner(id, body);
              if (respon.data.status === "success") {
                Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(1);
                    navigateToBack("/Managebanner");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(1);
                    navigateToBack("/Managebanner");
                  }
                });
              }
            })();
          }
        });
      }
    } else {
      if (getDataimage.length < 1 || banner.length < 1) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        let body = {
          bannerName: banner,
          bannerLink: null,
          active: checked,
          file: getDataimage[0].file,
        };

        await Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              // const respon = null;
              const respon = await createBanner(body);
              if (respon.data.status === "success") {
                Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(1);
                    navigateToBack("/Managebanner");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(1);
                    navigateToBack("/Managebanner");
                  }
                });
              }
            })();
          }
        });
      }
    }
  };
  const getBannerID = async (id) => {
    let getData = [];
    const respon = await getBannerById(id);
    setChecked(respon.data.data.active === 1 ? true : false);
    getData.push({
      active: respon.data.data.active === 1 ? "ใช้งาน" : "ไม่ใช้งาน",
      bannerID:
        respon.data.data.bannerID === null ? "-" : respon.data.data.bannerID,
      bannerName:
        respon.data.data.bannerName === null
          ? "-"
          : respon.data.data.bannerName,
      image: [
        {
          id: 0,
          status: false,
          src:
            respon.data.data.image === null || respon.data.data.image === ""
              ? "assets/images/img-notimg.png"
              : respon.data.data.image,
          file: "",
        },
      ],
    });

    setBanner(getData[0].bannerName);
    setGetDataimage(getData[0].image);
  };
  useEffect(() => {
    if (id) {
      getBannerID(id);
    } else {
    }
  }, []);

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/Managebanner");
      }
    });
  }

  return (
    <>
      {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()}
      <Header parentmenu={"จัดการแบนเนอร์ผู้สนับสนุน"} />
      <Menu parentmenu={"advertise"} activemenu={"manageBanner"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-pr.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประชาสัมพันธ์
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ประชาสัมพันธ์ / จัดการแบนเนอร์ผู้สนับสนุน
              </font>
            </div>
          </div>
          <Col className="card-white">
            <Row className="mx-1">
              <Col>
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  จัดการแบนเนอร์ผู้สนับสนุน
                </p>
              </Col>
            </Row>
            <Row className="my-3 mx-3">
              <Col xs={4} md={3} lg={2} className="text-header text-end">
                ชื่อแบนเนอร์
              </Col>
              <Col xs={8} md={9} lg={10}>
                <input
                  className="inp-data01 fontReg font-size03"
                  value={banner}
                  onChange={(e) => {
                    setBanner(e.target.value);
                  }}
                ></input>
              </Col>
            </Row>
            <Row className="mx-3 mt-5">
              <Col xs={4} md={4} lg={2} className="text-input">
                รูปแบนเนอร์
              </Col>
              <Col xs={8} md={8} lg={8}>
                {(() => {
                  if (getDataimage.length > 0) {
                    return (
                      <UploadIMGV2
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      ></UploadIMGV2>
                    );
                  } else {
                    return (
                      <UploadBanner
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      />
                    );
                  }
                })()}
                {/* img: {getDataimage.image.src} */}
                {/* {(() => {
                  if (getDataimage.length > 0) {
                    return (
                      <UploadIMGV2
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      ></UploadIMGV2>
                    );
                  } else {
                    return (
                      <UploadBanner
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      />
                    );
                  }
                })()} */}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} className="text-input mx-2"></Col>
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  onChange={() => handleCheck(!checked)}
                  // defaultChecked={checked}
                  type="checkbox"
                  checked={checked}
                />
                <span className="checkmark" />
              </label>
            </Row>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  onClick={(event) => postUpdateBanner()}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Advmanagebanner;
