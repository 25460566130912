import React from "react";
import { getDataUser } from "../config/configApi-User";
import { useState, useEffect } from "react";
import { logout } from "../../api/logout_api";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../pages/Loading";
function Header({ parentmenu }) {
  const navigate = useNavigate();
  const [userDt, setUserDt] = useState(null);
  const [userData, setUserData] = useState(null);
  const [memType, setMemtype] = useState(null);
  const [filterMenu, setFilterMenu] = useState([]);
  const [isLoading, setLoading] = useState(null);
  useEffect(() => {
    getUser();
    let getToken = localStorage.getItem("token");
    if (!getToken) {
      navigate("/login");
      // Swal.fire({
      //   title: "กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
      //   icon: "info",
      //   showCancelButton: true,
      //   confirmButtonText: "ยืนยัน",
      //   cancelButtonText: "ยกเลิก",
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.isConfirmed) {

      //   }
      // });
    }
  }, []);
  const getUser = async () => {
    const respon = await getDataUser();
    if (respon.data.status === "success") {
      let data = respon.data.data.firstname + " " + respon.data.data.lastanme;
      setUserData(respon.data.data);
      setMemtype(respon.data.data.permissionNameBackoffice);
      setUserDt(data);
      setFilterMenu(respon.data.data.permissionBackoffice);
    }
    // console.log(
    //   "permissionBackoffice : ",
    //   respon.data.data.permissionBackoffice
    // );
  };
  const logoutUser = async () => {
    let getToken = localStorage.getItem("token");

    //

    try {
      Swal.fire({
        title: "ยืนยันการออกจากระบบใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          (async () => {
            const respon = await logout(getToken);
            setLoading(false);
            if (respon.status === 200) {
              // localStorage.clear();
              localStorage.removeItem("token");
              localStorage.removeItem("permission");
              navigate("/login");
            }
          })();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            as={Link}
            className="nav-link"
            data-widget="pushmenu"
            role="button"
          >
            <i className="fas fa-bars" />
          </Link>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <p className="nav-link fontReg font-size03 colorGray01 d-flex align-items-center m-0">
            {parentmenu}
          </p>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <div className="colorGray01 font-weight-light text-right">
            <span className="fontReg font-size02 line-height03">
              {userDt}
              <br />
              <span className="fontReg font-size03">{memType}</span>
            </span>
          </div>
        </li>
        {/* Profile Dropdown Menu */}
        <li className="nav-item nav-profile">
          <div className="dropdown">
            <button
              className="nav-link count-indicator bg-transparent btn btn-primary dropdown-toggle arrowDown-pf"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="img-xs rounded-circle"
                src="assets/icons/ic-profile.png"
                alt="Profile"
              />
            </button>
            <div
              className="dropdown-menu dropdown-menu-right fontReg font-size01 line-height03"
              aria-labelledby="dropdownMenuButton"
            >
              <Link onClick={logoutUser} className="dropdown-item">
                ลงชื่อออก
              </Link>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
