import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UploadFileXLS from "../uploads/UploadFileXLS";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownSportType } from "../dataMockup/DataMockDropdown";
import {
  getSubSportById,
  createSubSport,
  getSubSportChk,
} from "../../api/subsport_api";
let id = new URLSearchParams(window.location.search).get("id");

function Editmatchtype() {
  const [sportTypeList, setsportTypeList] = useState([
    { sportType: "", status: "" },
  ]);
  const [checked, setChecked] = useState(false);
  const [dataSport, setDtsport] = useState([]);
  const getDataSubSportById = async (id) => {
    if (id) {
      const res = await getSubSportById(id);
      setDtsport(res.data.data.subSportName);
    }
  };
  const handleSportChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...sportTypeList];
    list[index][name] = value;

    setsportTypeList(list);
  };

  const handleSportRemove = (index) => {
    const list = [...sportTypeList];
    list.splice(index, 1);
    setsportTypeList(list);
  };

  const handleSportAdd = (index, chbox) => {
    const array1 = [
      { index: 1, chbox: "foo1" },
      { index: 2, chbox: "bar1" },
      { index: 3, chbox: "baz1" },
    ];

    const array2 = [{ index: 2 }, { index: 3 }];
    array2.push({
      index: index,
      chbox: chbox,
    });
    const result = array2.map((item) => {
      return array1.find((element) => element.id === item.id).chbox;
    });

    if (index) {
      setsportTypeList([
        ...sportTypeList,
        { sportType: "", status: index === 0 ? chbox : chbox },
      ]);
    } else {
    }
  };

  const [groupShow, setGroupShow] = useState(false);
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/matchType");
      }
    });
  }
  const [inputForm, setInputForm] = useState({
    inp1: 0, //ชนิดกีฬา
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  useEffect(() => {
    getDataSubSportById(id);
  });
  return (
    <>
      <Header parentmenu={"ประเภทการแข่งขัน"} />
      <Menu parentmenu={"masterData"} activemenu={"matchType"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประเภทการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / ประเภทการแข่งขัน
              </font>
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg-6">
                <input
                  id="inputStoreName"
                  class="inp-data01 fontReg font-size03"
                  disabled
                  value={dataSport}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ประเภทการเเข่งขัน
                </p>
              </div>
            </div>
            <div className="row justify-content-start fontReg font-size03">
              <div className="col-3">
                <div className="row pl-2">
                  <input
                    id="inputSubTypeName"
                    class="inp-data02 fontReg font-size03"
                    disabled
                    value={dataSport}
                  />
                  {/* <div className="d-flex align-items-center ml-2 ml-sm-3">
                    <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center mb-0">
                      ใช้งาน
                      <input
                        type="checkbox"
                        defaultChecked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div> */}
                </div>
              </div>
              <div className="col-12">
                <form className="App" autoComplete="off">
                  <div className="form-field">
                    {sportTypeList.map((singleSportType, index) => (
                      <div key={index} className="sports mt-3">
                        <div className="first-division">
                          <input
                            name="sportType"
                            type="text"
                            id="sportType"
                            className="inp-data02"
                            placeholder="รายการประเภทการแข่งขัน"
                            value={singleSportType.sportType}
                            onChange={(e) => handleSportChange(e, index)}
                          />
                          <div className="second-division">
                            {sportTypeList.length !== 1 && (
                              <button
                                type="button"
                                onClick={() => handleSportRemove(index)}
                                className="btn-remove-field"
                              >
                                <img
                                  src="assets/icons/remove-field.png"
                                  alt="ExportFiles Icon"
                                  className="ic-add mr-0"
                                />
                              </button>
                            )}
                          </div>
                          <div className="third-division">
                            {sportTypeList.length - 1 === index && (
                              <button
                                type="button"
                                onClick={handleSportAdd}
                                className="btn-add-field"
                              >
                                <img
                                  src="assets/icons/add-field.png"
                                  alt="ExportFiles Icon"
                                  className="ic-add mr-0"
                                />
                              </button>
                            )}
                          </div>
                          <div className="ml-2 ml-sm-3">
                            <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center mb-0 wh-spa">
                              ใช้งาน
                              <input
                                type="checkbox"
                                // defaultChecked={checked}
                                // onChange={() => setChecked(!checked)}
                                defaultChecked={checked}
                                onChange={() => handleSportAdd(index, !checked)}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save">บันทึก</button>
              </div>
            </div>
          </div>
          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg-6">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชนิดกีฬา
                  </p>
                  <Dropdown
                    options={DataDropdownSportType}
                    setinput={setinput}
                    inputname={"inp1"}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/002-masterdata-sub-sportType_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <UploadFileXLS limit={1} />
              </div>
              <div className="col-12 col-lg-3">
                <button className="btn-green01 fontReg font-size03 colorWhite">
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save">บันทึก</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Editmatchtype;
