import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import { logDOM } from "@testing-library/react";

const dataToken = localStorage.getItem("token");
// const dataToken = sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const getPermissionMobileList = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    // query.isAdmin = true
    const response = await instace.get("/permission/member", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getPermissionList = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get("/permission/staff", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getPermissionId = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.get(`/permission/staff/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getPermissionMenu = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.get(`/permission/staffmenu`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const updatePermission = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.put(`/permission/staff/${id}`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const creatPermission = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.post("/permission/staff", body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const removePermissionMenu = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.delete(`/permission/staff/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getDataPermissionFilter = async (data) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/permission/staff",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    params: {
      name: data.name === "" ? "" : data.name,
    },
  };
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
