import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";

const dataToken = localStorage.getItem("token");
// sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

//
export const getGuestList = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    // membertype id list
    // 1 = athletes
    // 2 = coaches
    // 3 = staff
    // 4 = guest
    // 5 = shop

    // query["memberType"] = id;
    //  + id
    // ?memberType=
    const response = await instace.get("/member?delete=true", {
      headers: headers,
      data,
      // params: query = {id} ,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getShopList = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    // membertype id list
    // 1 = athletes
    // 2 = coaches
    // 3 = staff
    // 4 = guest
    // 5 = shop

    // query["memberType"] = id;
    //  + id
    // ?memberType=
    const response = await instace.get(
      "/member?memberType=" + data + "&approveStatus=true",
      {
        headers: headers,
      }
    );
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getGuestListCoach = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    // membertype id list
    // 1 = athletes
    // 2 = coaches
    // 3 = staff
    // 4 = guest
    // 5 = shop

    // query["memberType"] = id;
    //  + id
    // ?memberType=
    const response = await instace.get("/member?memberType=2", {
      headers: headers,
      data,
      // params: query = {id} ,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getGuestListPerson = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    // membertype id list
    // 1 = athletes
    // 2 = coaches
    // 3 = staff
    // 4 = guest
    // 5 = shop

    // query["memberType"] = id;
    //  + id
    // ?memberType=
    const response = await instace.get("/member?memberType=4", {
      headers: headers,
      data,
      // params: query = {id} ,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getmemberList = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    // membertype id list
    // 1 = athletes
    // 2 = coaches
    // 3 = staff
    // 4 = guest
    // 5 = shop

    // query["memberType"] = id;

    const response = await instace.get("/member", {
      headers: headers,
      data,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
//
export const getGuestById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get(`/member/${id}`, { headers: headers });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const deleteGuest = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.delete(URL + `/member/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const CreateGuest = async (body) => {
  const formdata = new FormData();
  // title: inputForm.title,
  // firstname: firstname,
  // lastname: lastname,
  // mobileNo: call,
  // email: email,
  // idcard: idCard,
  // gender: inputForm.gender,
  // affliation: inputForm.affliation,
  // file: images[0].file,
  formdata.append("title", body.title);
  formdata.append("firstname", body.firstname);
  formdata.append("lastname", body.lastname);
  formdata.append("mobileNo", body.mobileNo);
  formdata.append("email", body.email);
  formdata.append("idcard", body.idcard);
  formdata.append("gender", body.gender);
  formdata.append("affliation", body.affliation);
  formdata.append("file", body.file);
  formdata.append("active", body.active);
  formdata.append("memberType", body.memberType);
  try {
    const headers = {
      // "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    };
    const response = await instace.post(URL + `/member`, formdata, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getDataShopListFilter = async (data) => {
  const config = {
    method: "GET",
    url:
      process.env.REACT_APP_BASE_URL +
      "/member?memberType=" +
      data.id +
      "&approveStatus=true",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    params: {
      shopName: data.inp1 === "" ? "" : data.inp1,
      shopType: data.inp2 === "" ? "" : data.inp2,
      regisType: data.inp3 === "" ? "" : data.inp3,
      // approveStatus: false,
    },
  };
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};

export const getDataGuestFilter = async (data) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/member",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    params: {
      memberType: 2,
      name: data.name === "" ? "" : data.name,
      affliation:
        data.affliation === "ทั้งหมด" || data.affliation === ""
          ? ""
          : data.affliation,
      gender:
        data.gender === "ทั้งหมด" || data.gender === "" ? "" : data.gender,
    },
  };
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};

export const getDataMemberFilter = async (data) => {
  let deleteData = { delete: true };
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/member?delete=true",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    params: {
      name: data.name === "" ? "" : data.name,
      permissionId:
        data.permissionId === "ทั้งหมด" || data.permissionId === ""
          ? ""
          : data.permissionId,
    },
  };
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};

export const getDataPersonFilter = async (data) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/member",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    params: {
      memberType: 4,
      name: data.name === "" ? "" : data.name,
      register:
        data.register === "ทั้งหมด" || data.register === ""
          ? ""
          : data.register,
    },
  };
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};

export const UpdateGuest = async (id, body) => {
  const formdata = new FormData();
  //
  // lastname: lastname,
  // firstname: firstname,
  // mobileNo: call,
  // email: email,
  // idcard: idCard,
  // file: images[0].file,
  //
  // formdata.append("title", body.title);
  formdata.append("firstname", body.firstname);
  formdata.append("lastname", body.lastname);
  formdata.append("mobileNo", body.mobileNo);
  formdata.append("email", body.email);
  formdata.append("idcard", body.idcard);
  formdata.append("title", body.title);
  formdata.append("gender", body.gender);
  formdata.append("affliation", body.affliation);
  formdata.append("file", body.file);
  formdata.append("active", body.active);
  // formdata.append("memberType", body.memberType);
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    };
    const response = await instace.put(URL + `/member/${id}`, formdata, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
