import React, { useEffect, useRef } from "react";
import $ from "jquery";
import Buttonactions from "../button/ButtonLandmark";

function TableLandmark(props) {
  const { pageAddEditName, actionsBtn, checkBut } = props;

  const item = props.item;

  $.DataTable = require("datatables.net-bs4");
  const tableRef = useRef();

  let itemHeaderList = [];
  let itemRows = [];

  item.columns.forEach((item, index) => {
    if (
      item.title === " " ||
      item.title === "เครื่องมือ" ||
      item.title === "บันทึกผล"
    )
      itemHeaderList.push(
        <th className="no-sort" key={index}>
          {item.title}
        </th>
      );
    else itemHeaderList.push(<th key={index}>{item.title}</th>);
  });

  item.datas.forEach((data, index) => {
    let itemDetails = [];

    data.row.forEach((details, index) => {
      if (details.type === "image") {
        itemDetails.push(
          <td key={index}>
            <img
              src={details.detail}
              alt="Type Sport"
              className="im-sports-type"
            />
          </td>
        );
      } else if (details.type === "actions") {
        itemDetails.push(
          <td key={index}>
            <Buttonactions
              pageAddEditName={pageAddEditName}
              actionsBtn={actionsBtn}
              id={data.id}
            />
          </td>
        );
      } else itemDetails.push(<td key={index}>{details.detail}</td>);
    });

    itemRows.push(<tr key={index}>{itemDetails}</tr>);
  });

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      dom: "rtip",
      columnDefs: [
        {
          targets: "no-sort",
          orderable: false,
        },
      ],
    });
    return function () {
      console.log("Table destroyed");
      table.destroy();
    };
  }, []);

  return (
    <div>
      <table
        id="tb_sport_type"
        className="table fontReg font-size03 colorGray01"
        ref={tableRef}
      >
        <thead>
          <tr>{itemHeaderList}</tr>
        </thead>
        <tbody>{itemRows}</tbody>
      </table>
    </div>
  );
}

export default TableLandmark;
