import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import {
  getNotificationById,
  updateNotification,
} from "../../api/notification_api";
import { getPermissionMobileList } from "../../api/permission_api";
import Swal from "sweetalert2";
import { data } from "jquery";
import { FastForward } from "@mui/icons-material";
function EditNotifications() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  let id = new URLSearchParams(window.location.search).get("id");
  const [nameNoti, setNameNoti] = useState("Alert");
  const [dataPermem, getDataPermem] = useState([]);
  const [dataPermemCh, getDataPermemCh] = useState([]);
  const [detail, setDetail] = useState(
    "ในแกรมม่าภาษาอังกฤษ เรื่องที่หลาย ๆ คนชอบสับสนและใช้ผิดอยู่บ่อย ๆ นั่นก็คือ Adverb ค่ะ ซึ่งปัญหาใหญ่ ๆ เลย คือ ไม่รู้จะวางไว้ตำแหน่งไหนของประโยค หรือแยกไม่ออกว่าคำศัพท์ตัวไหนเป็น Adverb วันนี้ Globish จะพาไปหาคำตอบเองค่ะ ว่า Adverb คืออะไร? มีกี่ประเภท? ต่างจาก Adjective อย่างไร? วางไว้ตรงไหนของประโยคดี? "
  );
  useEffect(() => {
    getDataNotiID(id);
  }, []);
  // dataGetCheck
  // updateNoti
  const updateNoti = async () => {
    let dataArr = [];

    for (let i = 0; i < dataPermem.length; i++) {
      if (dataPermem[i].status === true) {
        dataArr.push(dataPermem[i].permissionAccountId);
      }
    }

    let body = {
      notificationName: nameNoti,
      notificationDetail: detail,
      // active: 0 | 1,
      permissionMobileId: dataArr,
    };
    console.log("body: ", body);
    if (
      nameNoti === null ||
      detail === null ||
      nameNoti === "" ||
      detail === ""
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      });
    } else {
      Swal.fire({
        title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            setLoading(true);
            const respon = await updateNotification(id, body);
            setLoading(false);
            if (respon.data.status === "success") {
              Swal.fire({
                title: "แก้ไขข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ยืนยัน",
              }).then((result) => {
                if (result.isConfirmed) {
                  // setLoading(1);
                  navigate("/notiList");
                }
              });
            }
          })();
        }
      });
    }
  };
  const dataGetCheck = async (data) => {
    let getArr = [];
    let getDt = [data];

    getDt.push(data);

    for (let i = 0; i < dataPermem.length; i++) {
      getArr.push({
        permissionAccountId: dataPermem[i].permissionAccountId,
        permissionName: dataPermem[i].permissionName,
        status: data.permissionAccountId
          ? data.permissionAccountId === dataPermem[i].permissionAccountId
            ? false
            : true
          : true,
      });

      // permissionAccountID
    }
    getDataPermem(getArr);
    // getDataPermemCh(getArr);
  };
  // test
  const [array1, setArray1] = useState([
    { id: 1, name: "Object 1" },
    { id: 2, name: "Object 2" },
    { id: 3, name: "Object 3" },
    { id: 4, name: "Object 4" },
    { id: 5, name: "Object 5" },
  ]);
  const array2 = [{ id: 2 }, { id: 4 }];

  // Function to find index of object in array1 based on id attribute

  // Display data of object found in array1

  // t
  const [data, setData] = useState([
    {
      id: 1,
      name: "John",
      hobbies: [
        { id: 1, name: "Soccer" },
        { id: 2, name: "Basketball" },
      ],
    },
    {
      id: 2,
      name: "Jane",
      hobbies: [
        { id: 3, name: "Tennis" },
        { id: 4, name: "Swimming" },
      ],
    },
    {
      id: 3,
      name: "Bob",
      hobbies: [
        { id: 5, name: "Golf" },
        { id: 6, name: "Cycling" },
      ],
    },
  ]);
  const findIndexData = (id) => {
    for (let i = 0; i < data.length; i++) {
      const hobbies = data[i].hobbies;
      for (let j = 0; j < hobbies.length; j++) {
        if (hobbies[j].id === id) {
          return i;
        }
      }
    }

    return -1; // return -1 if the object is not found in the array
  };

  const handleClick = (id) => {
    const index = findIndexData(id);
  };

  // tesr
  const [array2s, setArray2] = useState([]);

  const getDataNotTest = async () => {
    const array1 = [
      { id: 1, name: "apple" },
      { id: 2, name: "banana" },
      { id: 3, name: "orange" },
    ];
    const array2 = [
      { id: 4, name: "grape" },
      { id: 5, name: "kiwi" },
      { id: 6, name: "orange" },
    ];

    const index = array2.findIndex((element) => element.name === "orange");

    if (index !== -1) {
      const array1Index = array1.findIndex(
        (element) => element.name === "orange"
      );

      if (array1Index !== -1) {
        const newDataObject = { id: 7, name: "pear" };

        setArray2([
          ...array2.slice(0, index),
          newDataObject,
          ...array2.slice(index + 1),
        ]);
      }
    }
  };
  // test

  // Function to find index of object in array1 based on id attribute
  const findIndex = (id) => {
    for (let i = 0; i < array1.length; i++) {
      if (array1[i].id === id) {
        return i;
      }
    }
    return -1;
  };

  // Function to return data of object in array1 found by id attribute in array2
  const [checkedItems, setCheckedItems] = useState([
    { id: "option1", status: true },
  ]);

  const handleChange = (event) => {
    const { name } = event.target;
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(name)) {
        return prevCheckedItems.filter((item) => item !== name);
      } else {
        return [...prevCheckedItems, name];
      }
    });
  };
  //
  const tasks = [
    { id: 1, name: "Task 1" },
    { id: 2, name: "Task 2" },
    { id: 3, name: "Task 3" },
    { id: 4, name: "Task 4" },
    // Add more tasks as needed
  ];
  const [checkboxValues1, setCheckboxValues1] = useState([]);
  const checkedTasks = dataPermem.filter(
    (task) => checkboxValues1[task.permissionAccountId]
  );

  const [checkboxValues, setCheckboxValues] = useState([
    { id: 1, label: "Checkbox 1", isChecked: false },
    { id: 2, label: "Checkbox 2", isChecked: false },
    { id: 3, label: "Checkbox 3", isChecked: false },
  ]);

  const handleCheckboxChange = (event) => {
    //   value={checkbox.permissionAccountId}
    //   checked={checkbox.status}
    //   onChange={handleCheckboxChange}
    // />
    // {checkbox.permissionName}
    const checkboxId = parseInt(event.target.value);
    const updatedCheckboxValues = dataPermem.map((checkbox) =>
      checkbox.permissionAccountId === checkboxId
        ? { ...checkbox, status: event.target.checked }
        : checkbox
    );
    getDataPermem(updatedCheckboxValues);
    setCheckboxValues(updatedCheckboxValues);
  };

  const getDataNotiID = async (id) => {
    // test
    // getDataNotTest();
    // };

    //
    const respon = await getNotificationById(id);

    let getData = [];
    getData.push({
      active: respon.data.data.active === 0 ? false : true,
      isSend: respon.data.data.isSend,
      notificationDetail: respon.data.data.notificationDetail,
      notificationID: respon.data.data.notificationID,
      notificationName: respon.data.data.notificationName,
      permissionAccountMobile: respon.data.data.permissionAccountMobile,
    });
    setDetail(getData[0].notificationDetail);
    setNameNoti(getData[0].notificationName);
    const res = await getPermissionMobileList();
    let getPer = [];
    let dataArr = res.data.data;

    const duplicates = [];

    if (getData[0].permissionAccountMobile.length > 0) {
      for (let i = 0; i < dataArr.length; i++) {
        getPer.push({
          permissionAccountId: dataArr[i].permissionAccountId,
          permissionName: dataArr[i].permissionName,
          status: false,
        });
      }

      const array1 = getPer;

      const array2 = getData[0].permissionAccountMobile;

      for (let i = 0; i < array1.length; i++) {
        const index = array2.findIndex((obj) => {
          if (obj.id === array1[i].permissionAccountId) {
            array1[i].status = true;
          }
          // return array1[i].permissionName == obj.value;
        });
      }

      getDataPermem(array1);
    } else {
      for (let i = 0; i < dataArr.length; i++) {
        getPer.push({
          permissionAccountId: dataArr[i].permissionAccountId,
          permissionName: dataArr[i].permissionName,
          status: false,
        });
      }
      getDataPermem(getPer);
    }
  };
  const getFilterChk = async (data) => {
    var dataArr = data.map((item) => {
      return [item.permissionAccountId, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    getDataPermem(result);
  };
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/notiList");
      }
    });
  }
  return (
    <>
      {(() => {
        if (isLoading === true) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"รายการแจ้งเตือน"} />
      <Menu parentmenu={"notification"} activemenu={"notiList"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-notification.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                การแจ้งเตือน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | การแจ้งเตือน / รายการแจ้งเตือน แก้ไข
              </font>
            </div>
          </div>

          {/* <div>
            {checkboxData.map((item) => (
              <div key={item}>
                <label>
                  <input
                    type="checkbox"
                    name={item}
                    checked={checkedItems.includes(item.status)}
                    onChange={handleChange}
                  />
                  {item}
                </label>
              </div>
            ))}
            <div>Selected items: {JSON.stringify(checkedItems)}</div>
          </div> */}
          {/* test */}
          <Col className="card-white">
            <Row className="mx-1">
              <Col xs={12} md={12} lg={12}>
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1">
                    {" "}
                    แก้ไขรายการแจ้งเตือน
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={3} xs={3} className="text-header text-start mx-3">
                ชื่อการแจ้งเตือน
              </Col>
              <Col lg={9} md={8} xs={7}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setNameNoti(e.target.value);
                  }}
                  value={nameNoti}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={3} xs={3} className="text-header text-start mx-3">
                ข้อความการแจ้งเตือน
              </Col>
              <Col lg={9} md={8} xs={7}>
                <textarea
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setDetail(e.target.value);
                  }}
                  value={detail}
                />
              </Col>
            </Row>
            {/* dataPermem */}

            <div className="row mx-2 mt-4">
              <div className="col-2 text-header mx-2">
                ผู้ใช้งานที่ต้องการแจ้ง
              </div>
              {/* test */}
              {/* {dataPermem.map((task, index) => (
                <div className="col-2 mt-4" key={index}>
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={task.status}
                      onChange={(e) => {
                        setCheckboxValues({
                          ...checkboxValues,
                          [index]: e.target.checked,
                        });
                      }}
                    />
                    {task.permissionName}
                  </label>
                </div>
              ))} */}

              {dataPermem.map((checkbox) => (
                <div className="col-2 mt-4">
                  <label key={checkbox.permissionAccountId}>
                    <input
                      className="mr-1"
                      type="checkbox"
                      value={checkbox.permissionAccountId}
                      defaultChecked={checkbox.status}
                      onChange={handleCheckboxChange}
                    />
                    {checkbox.permissionName}
                  </label>
                </div>
              ))}

              {/* {dataPermem.map((data, index) => {
                return (
                  <div className="col-2 mt-4" key={index}>
                    <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                      {data.permissionName}
                      <input
                        type="checkbox"
                        defaultChecked={data.status}
                        // onChange={(e) => {
                        //   dataGetCheck(data);
                        // }}
                        onChange={(e) => {
                          setCheckboxValues({
                            ...checkboxValues1,
                            [data.permissionAccountId]: e.target,
                          });
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                );
              })} */}
            </div>
          </Col>
          <Row className="my-5">
            <Col xs={2}></Col>
            <Col className="text-center" xs={4} md={4} lg={4}>
              <button className="btn-blue02" onClick={(event) => updateNoti()}>
                ยืนยัน
              </button>
            </Col>
            <Col className="text-center" xs={4} md={4} lg={4}>
              <button
                className="btn-cancle"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditNotifications;
