import { DataMockChartUsersApp } from "../dataMockup/DataMockChartUsersApp";
import ChartDoughnut from "../charts/ChartDoughnut";
import { Link } from "react-router-dom";
import { getDashboard } from "../../api/dashboard_api";
import React, { useState, useEffect } from "react";
import "../CSS/color.css";
function CardUsersApp(props) {
  const [userDataDash, setUserDataDash] = useState([]);
  const [sumAllperson, setSumAllperson] = useState([]);
  const [dateTime, setDatetime] = useState(null);

  const [userData, setUserData] = useState({
    labels: userDataDash.map((data) => data.age),
    datasets: [
      {
        label: "จำนวน",
        data: props.dataUser.map((data) => data.quantity),
        backgroundColor: [
          "#33B5B3",
          "#FFBA58",
          "#657BB1",
          "#6790FF",
          "#C298FF",
          "#FF90AB",
          "#90CD8D",
          "#5C1B6C",
          "#dde410",
        ],
        hoverBackgroundColor: [
          "#33B5B3",
          "#FFBA58",
          "#657BB1",
          "#6790FF",
          "#C298FF",
          "#FF90AB",
          "#90CD8D",
          "#5C1B6C",
          "#dde410",
        ],
      },
    ],
  });
  useEffect(() => {
    if (props) {
      setUserDataDash(props.dataUser);
      setDatetime(props.dataTime);
      setSumAllperson(props.sumData);
    }
  }, []);

  return (
    <>
      <div className="card-white">
        <div className="row">
          <div className="col-8 pr-0">
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              <span className="border-left01"></span>ปริมาณผู้ใช้งานแอปพลิเคชัน
            </p>
          </div>
          <div className="col-4 d-flex justify-content-end p-md-0">
            <Link className="fontReg font-size03 colorGray01" to={"/appUsers"}>
              ดูทั้งหมด{}
              <span>
                <i className="fas fa-angle-right" />
              </span>
            </Link>
          </div>
        </div>
        <div className="position-relative">
          <ChartDoughnut chartData={userData} />
          <div className="absolute-center text-center">
            <p className="fontReg font-weight-bold font-size03 colorGray01 mb-0">
              จำนวนทั้งหมด
            </p>
            <p className="fontReg font-weight-bold font-size04 colorBlue01 mb-0">
              {sumAllperson}
              <span className="font-size03 colorGray01"> คน</span>
            </p>
            <p className="fontReg font-size03 colorGray01 mb-0">Last Update</p>
            <p className="fontReg font-size03 colorGray01 mb-0">{dateTime}</p>
          </div>
        </div>
        <p className="fontReg font-weight-bold font-size03 colorGray01">อายุ</p>
        <div className="row">
          {(() => {
            if (userDataDash.length > 0) {
              return (
                <>
                  <div className="col-12 col-xxl-6 border-right01 br-3">
                    <div className="row">
                      <div className="col-1 align-self-center">
                        {/* <span className="icon icon-Vector colorGreen01"></span> */}
                        <div className="bgGreen01 icon icon-Vector circle-vector"></div>
                      </div>{" "}
                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[0].age}
                      </div>
                      <div className="col-4 fontReg font-size03 colorGreen01 d-flex justify-content-end">
                        {userDataDash[0].quantity} คน
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1 align-self-center">
                        {/* <span className="icon icon-Vector colorYellow01"></span> */}
                        <div className="bgYellow01 icon icon-Vector circle-vector"></div>
                      </div>

                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[1].age}
                      </div>
                      <div className="col-4 fontReg font-size03 colorYellow01 d-flex justify-content-end">
                        {userDataDash[1].quantity} คน
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <div className="bgBlue02 icon icon-Vector circle-vector"></div>
                        {/* <span className="icon icon-Vector colorBlue02"></span> */}
                      </div>
                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[2].age}
                      </div>
                      <div className="col-4 fontReg font-size03 colorBlue02 d-flex justify-content-end">
                        {userDataDash[2].quantity} คน
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xxl-6">
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <div className="bgBlue03 icon icon-Vector circle-vector"></div>
                        {/* <span className="icon icon-Vector colorBlue03"></span> */}
                      </div>
                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[3].age}
                      </div>
                      <div className="col-4 fontReg font-size03 colorBlue03 d-flex justify-content-end">
                        {userDataDash[3].quantity} คน
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <div className="bgViolet01 icon icon-Vector circle-vector"></div>
                        {/* <span className="icon icon-Vector colorViolet01"></span> */}
                      </div>
                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[4].age}
                      </div>
                      <div className="col-4 fontReg font-size03 colorViolet01 d-flex justify-content-end">
                        {userDataDash[4].quantity} คน
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <div className="bgPink01 icon icon-Vector circle-vector"></div>
                        {/* <span className="icon icon-Vector colorPink01"></span> */}
                      </div>
                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[5].age}
                      </div>
                      <div className="col-4 fontReg font-size03 colorPink01 d-flex justify-content-end">
                        {userDataDash[5].quantity} คน
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <div className="bgGreen02 icon icon-Vector circle-vector"></div>
                        {/* <span className="icon icon-Vector colorbluek"></span> */}
                      </div>
                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[6].age}
                      </div>
                      <div className="col-4 fontReg font-size03 colorGreen02 d-flex justify-content-end">
                        {userDataDash[6].quantity} คน
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <div className="bgViolet02 icon icon-Vector circle-vector"></div>
                        {/* <span className="icon icon-Vector  colorgold01"></span> */}
                      </div>
                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[7].age}
                      </div>
                      <div className="col-4 fontReg font-size03  colorViolet02 d-flex justify-content-end">
                        {userDataDash[7].quantity} คน
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <div className="bggold01 icon icon-Vector circle-vector"></div>
                        {/* <span className="icon icon-Vector  colorgold01"></span> */}
                      </div>
                      <div className="col-7 fontReg font-size03 colorGray01">
                        {userDataDash[8].age}
                      </div>
                      <div className="col-4 fontReg font-size03 colorgold01 d-flex justify-content-end">
                        {userDataDash[8].quantity} คน
                      </div>
                    </div>
                  </div>
                </>
              );
            }

            return null;
          })()}
        </div>
      </div>
    </>
  );
}

export default CardUsersApp;
