export const DataDropdownAffiliation = [
  // { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "กรุงเทพมหานคร" },
  { value: 2, text: "ชลบุรี" },
  { value: 3, text: "สมุทรสาคร" },
  { value: 4, text: "จันทบุรี" },
];
export const DataDropdownPrefix = [
  // { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "นาย" },
  { value: 2, text: "นางสาว" },
  { value: 3, text: "นาง" },
  { value: 4, text: "อื่นๆ" },
];
export const DataDropdownCoach = [
  // { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "นายสมจิตร สมใจ" },
  { value: 2, text: "นางสุดใจ สุขขี" },
  { value: 3, text: "นางสาวสดใส ใจงาม" },
  { value: 4, text: "นายกฤษ บุญมา" },
];
export const DataDropdownSportType = [
  {
    value: 0,
    text: "ทั้งหมด",
    subType: [
      { value: 1, text: "หญิงเดี่ยว" },
      { value: 2, text: "หญิงคู่" },
      { value: 3, text: "ชายเดี่ยว" },
      { value: 4, text: "ผสม" },
      { value: 5, text: "ชายหญิง" },
      { value: 6, text: "ชายคู่" },
      { value: 7, text: "ทีมหญิง" },
      { value: 8, text: "ทีมผสม" },
      { value: 9, text: "ทีมชาย" },
      { value: 10, text: "หญิงเดี่ยว4x100" },
      { value: 11, text: "ผสม4x100" },
      { value: 12, text: "ชายเดี่ยว4x100" },
      { value: 13, text: "หญิง" },
      { value: 14, text: "ผสม" },
      { value: 15, text: "ชาย" },
      { value: 16, text: "หญิง" },
      { value: 17, text: "ผสม" },
      { value: 18, text: "ชาย" },
    ],
  },
  {
    value: 1,
    text: "เทควันโด",
    subType: [
      { value: 1, text: "หญิงเดี่ยว" },
      { value: 2, text: "หญิงคู่" },
      { value: 3, text: "ชายเดี่ยว" },
      { value: 4, text: "ผสม" },
      { value: 5, text: "ชายหญิง" },
      { value: 6, text: "ชายคู่" },
    ],
  },
  {
    value: 2,
    text: "บาสเกตบอล",
    subType: [
      { value: 7, text: "ทีมหญิง" },
      { value: 8, text: "ทีมผสม" },
      { value: 9, text: "ทีมชาย" },
    ],
  },
  {
    value: 3,
    text: "ว่ายน้ำ",
    subType: [
      { value: 10, text: "หญิงเดี่ยว4x100" },
      { value: 11, text: "ผสม4x100" },
      { value: 12, text: "ชายเดี่ยว4x100" },
    ],
  },
  {
    value: 4,
    text: "มวย",
    subType: [
      { value: 13, text: "หญิง" },
      { value: 14, text: "ผสม" },
      { value: 15, text: "ชาย" },
    ],
  },
  {
    value: 5,
    text: "ยิมนาสติก",
    subType: [
      { value: 16, text: "หญิง" },
      { value: 17, text: "ผสม" },
      { value: 18, text: "ชาย" },
    ],
  },
];
export const DataDropdownSexs = [
  // { value: 0, text: "ทั้งหมด" },
  { value: 0, text: "หญิง" },
  { value: 1, text: "ชาย" },
  { value: 2, text: "อื่นๆ" },
];
export const DataDropdownAccommodation = [
  { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "ที่พัก 1" },
  { value: 2, text: "ที่พัก 2" },
  { value: 3, text: "ที่พัก 3" },
];
export const DataDropdownStatus = [
  { value: 0, text: "ทั้งหมด" },
  { value: true, text: "ใช้งาน" },
  { value: false, text: "ไม่ใช้งาน" },
];
export const DataDropdownZone = [
  { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "ภาค 1" },
  { value: 2, text: "ภาค 2" },
  { value: 3, text: "ภาค 3" },
];
export const DataDropdownArenas = [
  {
    value: 0,
    text: "ทั้งหมด",
    subType: [
      { value: 1, text: "สนามกีฬาจังหวัดนครสวรรค์1" },
      { value: 2, text: "สนามกีฬาจังหวัดนครสวรรค์2" },
      { value: 3, text: "สนามกีฬาจังหวัดนครสวรรค์3" },
      { value: 4, text: "สนามกอล์ฟจิระประวัติ1" },
      { value: 5, text: "สนามกอล์ฟจิระประวัติ2" },
      { value: 6, text: "สนามกอล์ฟจิระประวัติ3" },
      { value: 7, text: "สนามฟุตบอล1" },
      { value: 8, text: "สนามฟุตบอล2" },
      { value: 9, text: "สนามฟุตบอล3" },
    ],
  },
  {
    value: 1,
    text: "สนามกีฬาจังหวัดนครสวรรค์ พิธีเปิด-ปิด",
    subType: [
      { value: 1, text: "สนามกีฬาจังหวัดนครสวรรค์1" },
      { value: 2, text: "สนามกีฬาจังหวัดนครสวรรค์2" },
      { value: 3, text: "สนามกีฬาจังหวัดนครสวรรค์3" },
    ],
  },
  {
    value: 2,
    text: "สนามกอล์ฟจิระประวัติ",
    subType: [
      { value: 7, text: "สนามกอล์ฟจิระประวัติ1" },
      { value: 8, text: "สนามกอล์ฟจิระประวัติ2" },
      { value: 9, text: "สนามกอล์ฟจิระประวัติ3" },
    ],
  },
  {
    value: 3,
    text: "สนามฟุตบอล",
    subType: [
      { value: 10, text: "สนามฟุตบอล1" },
      { value: 11, text: "สนามฟุตบอล2" },
      { value: 12, text: "สนามฟุตบอล3" },
    ],
  },
];
export const DataDropdownCompetType = [
  // { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "เดี่ยว" },
  { value: 2, text: "ทีม" },
];
export const DataDropdownRegisBy = [
  { value: "", text: "ทั้งหมด" },
  { value: "App", text: "App" },
  { value: "FB", text: "FB" },
  { value: "Line", text: "Line" },
  { value: "mail", text: "mail" },
];
export const DataDropdownUsersType = [
  { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "ต่ำกว่า 18 ปี" },
  { value: 2, text: "อายุ 18-25 ปี" },
  { value: 3, text: "อายุ 26-35  ปี" },
  { value: 4, text: "อายุ 36-45  ปี" },
  { value: 5, text: "มากกว่า 45  ปี" },
  { value: 6, text: "บุคคลทั่วไป" },
  { value: 7, text: "นักกีฬา" },
  { value: 8, text: "โค้ช" },
];
export const DataDropdownStoreType = [
  { value: "ทั้งหมด", text: "ทั้งหมด" },
  { value: "ร้านค้า", text: "ร้านค้า" },
  { value: "ร้านอาหาร", text: "ร้านอาหาร" },
];
export const DataDropdownStatusApprove = [
  { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "รออนุมัติ" },
  { value: 2, text: "อนุมัติ" },
  { value: 3, text: "ไม่อนุมัติ" },
];
export const DataDropdownCampaign = [
  { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "แคมเปญ01" },
  { value: 2, text: "แคมเปญ02" },
  { value: 3, text: "แคมเปญ03" },
  { value: 4, text: "แคมเปญ04" },
];

export const DataDropdownUserApp = [
  { value: "0", text: "ทั้งหมด" },
  { value: "ช่วงอายุ", text: "ช่วงอายุ" },
  { value: "ประเภทผู้ใช้งาน", text: "ประเภทผู้ใช้งาน" },
];

export const DataDropdownBedding = [
  { value: "All", text: "ทั้งหมด" },
  { value: "สักกัด", text: "สักกัด" },
  { value: "ชนิดเครื่องนอน", text: "ชนิดเครื่องนอน" },
  { value: "วันที่ทำรายการ", text: "วันที่ทำรายการ" },
  { value: "ประเภทรายการ", text: "ประเภทรายการ" },
];

export const DataDropdownPerson = [
  { value: "All", text: "ทั้งหมด" },
  { value: "โค้ช", text: "โค้ช" },
  { value: "กรรมการ", text: "กรรมการ" },
];

export const DataDropdowBooking = [
  { value: "All", text: "ทั้งหมด" },
  { value: "แอปพลิเคชัน", text: "แอปพลิเคชัน" },
  { value: "Hotline", text: "Hotline" },
  { value: "LINE", text: "LINE" },
  { value: "ศุนย์ประสานงานกลาง", text: "ศุนย์ประสานงานกลาง" },
];

export const DataDropdownPeriod = [
  { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "00:01 - 04:00 น." },
  { value: 2, text: "04:01 - 08:00 น." },
  { value: 3, text: "08:01 - 12:00 น." },
  { value: 4, text: "12:01 - 16:00 น." },
  { value: 5, text: "16:01 - 20:00 น." },
  { value: 6, text: "20:01 - 24:00 น." },
];

export const DataDropdownCompetitionRound = [
  { value: 0, text: "ทั้งหมด" },
  { value: 1, text: "รอบชิงชนะเลิศ" },
  { value: 2, text: "รอบรองชนะเลิศ" },
  { value: 3, text: "รอบคัดเลือก" },
];
