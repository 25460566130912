import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { getCampById, createCamp, updateCamp } from "../../api/camp_api";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { checked } from "glamor";
import UploadFileXLS from "../uploads/UploadFileXLS";
import Select from "react-select";
import ImportExcel from "../uploads/ExcelRender";
import { verifyImport, submitImportData } from "../../api/camp_api";
import Loading from "../pages/Loading";
import {
  getStaffList,
  getStaffById,
  removeStaff,
  getDataStaffFilter,
} from "../../api/staff_api";
import {
  getAffliation,
  getAffliationById,
  deleteAffliation,
  updateAffliation,
  createAffliation,
} from "../../api/affliation_api";
function AddeditathleteAmt() {
  const [isLoading, setLoading] = useState(null);
  const [dataStaff, setDatastaff] = useState([]);
  const [groupShow, setGroupShow] = useState(false);
  const [dataDropAff, setDataDropAff] = useState();
  let [detailAmt, setdetailAmt] = useState([
    { roomNo: null, floor: "", bed: "" },
  ]);
  const [campID, setcampID] = useState("");
  const [campName, setCampName] = useState("");
  const [status, setStatus] = useState("");
  const [coordinator, setCoordinator] = useState("");
  const [coordinatorTel, setCoordinatorTel] = useState("");
  const [updatedAt, setUpdatedAt] = useState(Date.now());
  const [dataFloor, setDataFloor] = useState();
  const [dataRoom, setDataRoom] = useState();
  const [dataBed, setDataBed] = useState();
  const location = useLocation();
  // benz fix bug amount camp
  const [campDetail, setCampDetail] = useState([
    { roomNo: null, floor: null, bed: null },
  ]);
  console.log("campDetail data result : ", campDetail);
  const navigate = useNavigate();
  const swalWithBootstrapButtons = Swal.mixin();
  let id = new URLSearchParams(location.search).get("id");
  //       setCoordinatorTel(e.target.value);

  const changeCoor = async (e) => {
    setDataFloor(null);
    if (e) {
      setDataFloor(e.target.value);
    }
  };

  const getDataAffliation = async () => {
    let rowsdata = [];
    try {
      const respon = await getAffliation();

      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          value: i + 1,
          label: objectData.affliationName,
          id: objectData.affliationID,
        });
      });
      getFilterdropdownAff(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getFilterdropdownAff = async (data) => {
    var dataArr = data.map((item) => {
      return [item.label, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setDataDropAff(result);
  };
  const getStaff = async () => {
    const rowData = [];
    try {
      const response = await getStaffList();

      if (response) {
        const dataStaff = response.data.data;

        dataStaff.forEach((dt, i) => {
          return rowData.push({
            value: dt.staffID,
            label: dt.firstname + " " + dt.lastanme,
            staffID: dt.staffID,
          });
        });
        // setDatastaff(rowData);
        getFilterdropdown(rowData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getFilterdropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.label, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject

    setDatastaff(result);
  };

  const getByID = async (id) => {
    try {
      const response = await getCampById(id);

      if (response) {
        const station = response.data.data;
        setcampID(response.data.data.campID);
        setCampName(response.data.data.campName);
        setCoordinator(response.data.data.coordinator);
        setCoordinatorTel(response.data.data.coordinatorTel);

        setStatus(
          response.data.data.active == true
            ? (document.getElementById("active").checked = true)
            : false
        );
        let dtRoom = [];
        let dtFloor = [];
        let dtBed = [];
        // (dtRoom =
        //   response.data.data.roomNumber !== undefined
        //     ? response.data.data.roomNumber
        //     : 0),
        //   setDataRoom(dtRoom);
        dtRoom =
          response.data.data.roomNumber !== undefined
            ? response.data.data.roomNumber
            : 0;

        setDataRoom(dtBed);
        dtBed =
          response.data.data.guestAvailable !== undefined
            ? response.data.data.guestAvailable
            : 0;

        setDataBed(dtBed);
        dtFloor =
          response.data.data.roomAvailable !== undefined
            ? response.data.data.roomAvailable
            : 0;
        setDataFloor(dtFloor);
        const staff = response.data.data.staff.map((item) => {
          const select = {
            value: item.staffID,
            label: item.name,
            staffID: item.staffID,
          };
          return select;
        });
        setSelected(staff);
        // affial
        // value: 2, label: 'สมุทรปราการ', id: 109
        const aff = response.data.data.campAffliation.map((item) => {
          const selectaff = {
            value: item.affliationID,
            label: item.affliationName,
            id: item.affliationID,
          };
          return selectaff;
        });
        setSelectedAff(aff);
        let dataBed = [];
        response.data.data.campDetail.map((item) => {
          console.log("item i : ", item);
          dataBed.push({
            roomNo: item.roomNumber ? item.roomNumber : null,
            floor: item.roomAvailable ? item.roomAvailable : null,
            bed: item.bed ? item.bed : null,
          });
          // setCampDetail((prev) => [
          //   ...prev,
          //   {
          //     roomNo: item.roomNumber ? item.roomNumber : null,
          //     floor: item.roomAvailable ? item.roomAvailable : null,
          //     bed: item.bed ? item.bed : null,
          //   },
          // ]);
        });
        console.log("dataBed data : ", dataBed);
        setCampDetail(dataBed);
        setdetailAmt([
          {
            roomNo:
              response.data.data.roomNumber !== undefined
                ? response.data.data.roomNumber
                : "14",
            floor:
              response.data.data.roomAvailable !== undefined
                ? response.data.data.roomAvailable
                : "12",
            bed:
              response.data.data.guestAvailable !== undefined
                ? response.data.data.guestAvailable
                : "12",
          },
        ]);
        return station;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getDataAffliation();
    getStaff();
    if (id) {
      getByID(id);
    }
  }, []);

  const [inputForm, setInputForm] = useState({
    inp1: "", //สถานที่พัก
    inp2: "", //ผู้ประสานงาน
    inp3: "", //เบอร์ผู้ประสานงาน
    inp4: [], //เจ้าหน้าที่
    inp5: [], //สังกัด
  });
  const [selectAff, setSelectedAff] = useState([]);
  const [selected, setSelected] = useState([]);
  const optionss = [
    // { value: "a", label: "a" },
    // { value: "b", label: "b" },
  ];
  const handleChange = (options) => {
    setSelected(options);

    // setSelectedOptions(optionss);
  };
  const handleChangeAff = (options) => {
    setSelectedAff(options);

    // setSelectedOptions(optionss);
  };
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const handleAthleteAmtChange = (e, index) => {
    setDataRoom(null);
    setDataFloor(null);
    setDataBed(null);
    const list = [...detailAmt];
    if (id) {
      setCampDetail((prev) => {
        if (prev[index]) {
          prev[index] = {
            roomNo: null,
            floor: "",
            bed: parseInt(e.target.value),
          };
        } else {
          prev.push({ roomNo: null, floor: "", bed: parseInt(e.target.value) });
        }
        prev[index] = {
          roomNo: null,
          floor: "",
          bed: parseInt(e.target.value),
        };
        console.log("prev data : ", prev);
        return prev;
      });
      // ---------
    } else {
      const { bed, value } = e.target;
      list[index]["bed"] = value;
      console.log("list data : ", list);

      setCampDetail(list);
    }
    setdetailAmt(list);
  };
  const handleAthletChange = (e, index) => {
    setdetailAmt(e.target.value);
    const { name, value } = e.target;
    const list = [...detailAmt];
    list[index][name] = value;
    setdetailAmt(list);
  };
  const handleAthleteAmtRemove = (index) => {
    // console.log
    const list = [...campDetail];
    list.splice(index, 1);

    setCampDetail((prev) => {
      const newSt = [...prev];
      newSt.pop();

      return newSt;
    });
  };

  const handleAthleteAmtAdd = () => {
    setdetailAmt([...detailAmt, { roomNo: "", floor: "", bed: "" }]);
    setCampDetail((prev) => {
      prev.push({ roomNo: "", floor: "", bed: 0 });
      return prev;
    });
  };

  const addEditCamp = async () => {
    let arr = [];
    for (let i = 0; i < selected.length; i++) {
      arr.push(selected[i].staffID);
    }
    let arrAff = [];

    for (let i = 0; i < selectAff.length; i++) {
      arrAff.push(selectAff[i].id);
    }
    console.log("arrAff : ", arrAff);
    console.log("campDetail : ", campDetail);
    if (
      campName === null ||
      campName === "" ||
      coordinator === null ||
      coordinator === "" ||
      coordinatorTel === null ||
      coordinatorTel === "" ||
      arrAff.length < 1
      // ||campDetail.length < 1
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      const body = {
        campName: campName,
        coordinatorName: coordinator,
        coordinatorTel: coordinatorTel,
        active: status,
        roomNumber: detailAmt.roomNo,
        // roomAvailable: detailAmt.bed,
        // guestAvailable: detailAmt.floor,
        campDetail: campDetail,
        staff: arr,
        affliation: arrAff,
      };
      console.log("body : ", body);

      setUpdatedAt(updatedAt);

      if (id) {
        // const response = null;

        await Swal.fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              // const respon = await createSubArena(body);
              // const respon = null;
              const respon = await updateCamp(id, body);
              if (respon.data.status === "success") {
                Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(true);
                    navigate("/athleteAmt");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/athleteAmt");
                  }
                });
              }
            })();
          }
        });
      } else {
        // const response = null;

        // if (response.status == "200") {
        await Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              // const respon = null;
              // const respon = await createSubArena(body);
              // const response = null;
              const respon = await createCamp(body);
              if (respon.data.status === "success") {
                Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(true);
                    navigate("/athleteAmt");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/athleteAmt");
                  }
                });
              }
            })();
          }
        });
        // }
      }
    }
  };

  const setChecked = () => {
    if (status == true) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const DataDropdownStaff = [
    { value: 0, label: "นางสายไหม อุ่นดี" },
    { value: 1, label: "นายบุญเลิศ ขาวสะอาด" },
    { value: 2, label: "นายสมชาย ทรายขาว" },
    { value: 3, label: "นางสาวแสนดี ดีใจ" },
  ];

  const DataDropdownAfft = [
    { value: 0, label: "กรุงเทพมหานคร" },
    { value: 1, label: "ระยอง" },
    { value: 2, label: "ชลบุรี" },
    { value: 3, label: "สมุทรสาคร" },
    { value: 4, label: "จันทบุรี" },
  ];
  const [message, setMessage] = useState("");
  const handleChangeinput = (event) => {
    // 👇 Get input value from "event"
    setMessage(event.target.value);
  };

  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/athleteAmt");
      }
    });
  }

  // *********************** after ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);
  const handleImport = async (rows) => {
    // console.log("rows data : ", rows);
    // console.log("rows.length - 1 preview : ", rows.length - 1);
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);
    // console.log("rows.slice(1) preview  ", rows.slice(1));
    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          campName: item[0],
          coordinatorName: item[1],
          coordinatorTel: item[2],
          bed: item[3],
          active: item[4],
        });
      }
    });
    await setImportData(row);
    // console.log("row data preview : ", row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          importData: data,
        };

        const response = await verifyImport(dataToVerify);
        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });
          console.log("failedData : ", failedData);
          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }
      console.log("failedArr reudlt  : ", failedArr);

      setFailedRow(failedArr);

      if (error === 0) {
        if (failedArr.length > 0) {
          Swal.fire({
            title:
              "ตรวจสอบข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง ",
            icon: "error",
            confirmButtonText: "ยืนยัน",
          });
        } else {
          Swal.fire({
            title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "success",
            confirmButtonText: "ยืนยัน",
          });
        }

        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importSport = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              // memberType: 2,
              importData: data,
            };
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      if (error === true) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/athleteAmt");
          }
        });
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/athleteAmt");
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/athleteAmt");
        }
      });
    }
  };

  return (
    <>
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"ที่พักนักกีฬา"} />
      <Menu parentmenu={"masterData"} activemenu={"athleteAmt"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ที่พักนักกีฬา
              </font>
              {!id ? (
                <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                  | ข้อมูล Master Data / เพิ่มข้อมูลที่พักนักกีฬา
                </font>
              ) : (
                <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                  | ข้อมูล Master Data / แก้ไขข้อมูลที่พักนักกีฬา
                </font>
              )}
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                {!id ? (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>
                    เพิ่มข้อมูลที่พักนักกีฬา
                  </p>
                ) : (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>
                    แก้ไขข้อมูลที่พักนักกีฬา
                  </p>
                )}
              </div>
            </div>
            <div className="row justify-content-start mb-3">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อสถานที่พัก"
                  value={campName}
                  onChange={(e) => {
                    setCampName(e.target.value);
                    setinput("inp1", e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ผู้ประสานงาน"
                  value={coordinator}
                  onChange={(e) => {
                    setCoordinator(e.target.value);
                    setinput("inp2", e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="เบอร์ผู้ประสานงาน"
                  type="text"
                  value={coordinatorTel}
                  onChange={(e) => {
                    setCoordinatorTel(e.target.value);
                    setinput("inp3", e.target.value);
                  }}
                  maxLength={10}
                />
              </div>
            </div>
            <div className="row ml-0">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  value={status}
                  id="active"
                  onChange={(e) => {
                    setStatus(e.target.checked);
                  }}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="card-white  mt-3">
              <div className="row">
                <div className="col-12">
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>รายละเอียดที่พัก
                  </p>
                </div>
              </div>
              <div className="row justify-content-start fontReg font-size03">
                <div className="col-12">
                  <form className="App" autoComplete="off">
                    <div className="form-field">
                      {campDetail.map((detailData, index) => (
                        <div key={index} className="sports mt-2">
                          <div className="first-division">
                            <input
                              // name="athleAmtFloor"
                              type="text"
                              // id="athleAmtFloor"
                              className="inp-data02 mr-3"
                              placeholder="จำนวนผู้เข้าพัก"
                              value={detailData.bed}
                              // value={singleAthleAmtType.floor}
                              onChange={(e) => handleAthleteAmtChange(e, index)}
                            />
                            <div className="second-division">
                              {campDetail.length !== 1 && (
                                <button
                                  type="button"
                                  onClick={() => handleAthleteAmtRemove(index)}
                                  className="btn-remove-field"
                                  disabled={index === 0}
                                >
                                  <img
                                    src="assets/icons/remove-field.png"
                                    alt="ExportFiles Icon"
                                    className="ic-add mr-0"
                                  />
                                </button>
                              )}
                            </div>
                            <div>
                              {campDetail.length - 1 === index && (
                                <button
                                  type="button"
                                  onClick={handleAthleteAmtAdd}
                                  className="btn-add-field"
                                >
                                  <img
                                    src="assets/icons/add-field.png"
                                    alt="ExportFiles Icon"
                                    className="ic-add mr-0"
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </form>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>เจ้าหน้าที่
                  </p>
                </div>
              </div>
              <div className="row justify-content-start fontReg font-size03">
                <div className="col-12">
                  <div className="selected-dpdw">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      เลือกเจ้าหน้าที่
                    </p>
                    <Select
                      isMulti
                      value={selected}
                      name="staff"
                      options={dataStaff}
                      setinput={setinput}
                      inputname={"inp4"}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>สังกัด
                  </p>
                </div>
              </div>
              <div className="row justify-content-start fontReg font-size03">
                <div className="col-12">
                  <div className="selected-dpdw">
                    <p className="fontReg font-size03 colorGray02 mb-1">
                      สังกัด
                    </p>
                    <Select
                      isMulti
                      name="afft"
                      options={dataDropAff}
                      setinput={setinput}
                      inputname={"inp5"}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleChangeAff}
                      value={selectAff}
                    />
                  </div>
                </div>
              </div>
              <div className="col-11 col-sm-11 col-xl-10">
                <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
                  <div className="col-3 col-sm-2 col-xl-2">
                    <button
                      className="btn-cancel"
                      onClick={() => {
                        cancelData();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-3 col-sm-2 col-xl-2">
                    <button className="btn-save" onClick={addEditCamp}>
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/006-masterdata-accom_athleteAmt_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                {/* <UploadFileXLS limit={1} onChange={handleFileChange}/> */}
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                {/* <span className='mx-2'>{verifySuccess}/{totalRow}</span> */}
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0 ? true : false}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddeditathleteAmt;
