import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
const dataToken = localStorage.getItem("token");
// sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://127.0.0.1:3000",
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const verifyImport = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(`/subsports/verifyimport`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const submitImportData = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(`/subsports/importdata`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getSubSport = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.get(URL + `/subsports`, {
      headers: headers,
      // params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getSubSportFind = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.get(URL + `/subsports?sportId=` + id, {
      headers: headers,
      // params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getSubSportList = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.get(URL + `/subsports`, {
      headers: headers,
      params: {
        sportId: data.inp1 === "" ? "" : data.inp1,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getSubSportChk = async (data) => {
  try {
    //example of query
    // const query = {
    //     name: 'John',
    //     type: 'Match'
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.get(URL + `/subsports`, {
      headers: headers,
      params: data,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getSubSportById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.get(`/subsports/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const createSubSport = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.post(`/subsports`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response && response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    // toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
    Swal.fire({
      title: "กรุณาตรวจสอบข้อมูล ไม่สามารถทำรายการได้",
      // text: error.response.data.message,
      icon: "error",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
      showCancelButton: true,
    });
  }
};

export const updateSubSport1 = async (id, body) => {
  let responsList, dataObject;
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    for (let i = 0; i < body.length; i++) {
      const respon = axios.put(
        `/subsports/${id}`,
        body[i],

        headers
      );

      responsList.push(respon);
    }

    Promise.all(responsList).then((rs) => {
      rs.forEach((response) => {
        if (response.status === 200) {
          dataObject = response.data;

          return dataObject;
        }
      });
    });
    // const response = await instace.put(URL + `/subsports/${id}`, body, {
    //   headers: headers,
    // });

    // if (response.status === 200) {
    //   return response;
    // } else {
    //   return null;
    // }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const updateSubSport = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.put(`/subsports/${id}`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const deleteSubSport = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.delete(URL + `/subsports/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getfilterSubsport = async (data) => {
  try {
    //example of query
    // const query = {
    //     name: 'John',
    //     type: 'Match'
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await instace.get(URL + `/subsports`, {
      headers: headers,
      // params: query,
      params: {
        subSportId: data.inp1 === "" ? null : data.inp1,
        sportId: data.inp2 === "" ? null : data.inp2,
        active: data.inp3 === "" ? null : data.inp3,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
