import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
import '../CSS/Addedit.css'

function EditStation()
{
    const [stationList, setsatationList] = useState([{ stationType: "dasd", stationCount: "312321"}]);

    const handleListChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...stationList];
        list[index][name] = value;

        setsatationList(list);
      };
    
      const handleListRemove = (index) => {
        const list = [...stationList];
        list.splice(index, 1);
        setsatationList(list);
      };
    
      const handleListAdd = () => {
        setsatationList([...stationList, { stationType: "", stationCount: "" }]);
      };

    const [station, setStation] = useState("เกษตรศาสตร์")
    const [person, setPerson] = useState("นาย กิดตะหมวย นวยยวย")
    const [callPerson , setCallPerson] = useState("098-2341234")

    return (
        <>
        <Header parentmenu={'ข้อมูลเครื่องนอน'}/>
        <Menu parentmenu={'borrowReturn'} activemenu={'BorrowStation'}/>
        <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
        <div className="row mb-4">
                <div className="col-7 mt-2">
                  <img
                    src="assets/icons/im-borrow.png"
                    alt="Home Icon"
                    className="im-tiltle img-circle"
                  />
                  <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                  ข้อมูลเครื่องนอนที่พัก
                  </font>
                  <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                  |   ยืม-คืน ชุดเครื่องนอน / ข้อมูลเครื่องนอนที่พัก แก้ไข 
                  </font>
                </div>
            </div>
            <Col className='card-white'>
                <Row className='mx-1'>
                  <Col xs={12} md={12} lg={12}> 
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01 px-1"> ข้อมูลที่พักนักกีฬา</span>
                  </p>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col lg={2} md={3} xs={4}className='text-header text-start'>ชื่อสถานที่พัก</Col>
                  <Col lg={10} md={9} xs={8}><input className="inp-data01 fontReg font-size03"
                  onChange={(e) => {setStation(e.target.value)}} value={station}/></Col>
            </Row>    <Row className='mt-3'>
                  <Col lg={2} md={3} xs={4}className='text-header text-start'>ผู้ประสานงาน</Col>
                  <Col lg={10} md={9} xs={8}><input className="inp-data01 fontReg font-size03"
                  onChange={(e) => {setPerson(e.target.value)}} value={person}/></Col>
            </Row>    <Row className='mt-3'>
                  <Col lg={2} md={3} xs={4}className='text-header text-start'>เบอร์โทรผู้ประสานงาน</Col>
                  <Col lg={10} md={9} xs={8}><input className="inp-data01 fontReg font-size03"
                  onChange={(e) => {setCallPerson(e.target.value)}} value={callPerson}/></Col>
            </Row>
            </Col>
            <div className="card-white h-100vh mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายละเอียดข้อมูลเครื่องนอน
                </p>
              </div>
            </div>
                <div className='row justify-content-start fontReg font-size03 mt-3'>
                    <div className='col-1' >ลำดับ</div >
                    <div className='col-2'>ชื่อเครื่องนอน</div >
                    <div className='col-1' ></div >
                    <div className='col-2'>จำนวน</div >
                </div>
            <div className="row justify-content-start fontReg font-size03">
              <div className="col-12">
                <form className="App" autoComplete="off">
                  <div className="form-field">
                    {stationList.map((singleStationType, index) => (
                      <div key={index} className="sports mt-3">
                        <div className="first-division">
                        <div className='mx-2'>{index+1}</div>
                          <input
                            name="stationType"
                            type="text"
                            id="stationType"
                            className="inp-data02 mx-5"
                            placeholder="ชื่อเครื่องนอน"
                            value={singleStationType.stationType}
                            onChange={(e) => handleListChange(e, index)}
                          />

                            <input
                            name="stationCount"
                            type="text"
                            id="stationCount"
                            className="inp-data02"
                            placeholder="จำนวน"
                            value={singleStationType.stationCount}
                            onChange={(e) => handleListChange(e, index)}
                          />

                          <div className="second-division">
                            {stationList.length !== 1 && (
                              <button
                                type="button"
                                onClick={() => handleListRemove(index)}
                                className="btn-remove-field"
                              >
                                <img
                                  src="assets/icons/remove-field.png"
                                  alt="ExportFiles Icon"
                                  className="ic-add mr-0"
                                />
                              </button>
                            )}
                          </div>
                          <div>
                            {stationList.length - 1 === index && (
                              <button
                                type="button"
                                onClick={handleListAdd}
                                className="btn-add-field"
                              >
                                <img
                                  src="assets/icons/add-field.png"
                                  alt="ExportFiles Icon"
                                  className="ic-add mr-0"
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>
            </div>

            <Row className='my-5'>
                  <Col xs={2}></Col>
                  <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-blue02'>ยืนยัน</button></Col>
                  <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-cancle'>ยกเลิก</button></Col>
                  <Col xs={2}></Col>
            </Row>
        </div>
        </div>
        <Footer/>
        </>
      )
}

export default EditStation