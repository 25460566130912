import { React, useRef, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableData from "../Tables/TableData";
import Footer from "../template/Footer";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Swal from "sweetalert2";
import { getMatchStatById, updateMatchStat } from "../../api/matchstat_api";
import { getSubArena } from "../../api/subarena_api";
import { getSubSport, getSubSportFind } from "../../api/subsport_api";
import { getSport } from "../../api/sport_api";
import { getAffliation } from "../../api/affliation_api";
import moment from "moment";
import Loading from "../pages/Loading";
function EditcompetresultsTeams() {
  const enableButton = {
    view: false,
    edit: false,
    delete: false,
    add: false,
    approve: false,
  };
  const [inputForm, setInputForm] = useState({
    inp1: "กีฑา", //ชนิดกีฬา
    inp2: "หญิงเดี่ยว", //ประเภทการแข่งขัน
    inp3: "สนามกีฬา 1", //สนามกีฬา
    inp4: "หญิงเดี่ยว4x100เมตร", //ชื่อรายการแข่งขัน
    inp5: "รอบชิงชนะเลิศ", //รอบการแข่งขัน
    inp6: "เดี่ยว", //ประเภท
    inp7: "20/05/2023", //วันที่แข่ง
    inp8: "1:30 PM", //เวลาที่แข่ง
    inp9: "กรุงเทพมหานคร", //สังกัด
    inp10: "", //คะแนน
    inp11: "จันทบุรี", //สังกัด
    inp12: "", //คะแนน
    inp13: "", //สถิติ
    inp14: "", //หมายเหตุ
    inp15: "", //อันดับ
    inp16: "", //สถิติ
    inp17: "", //หมายเหตุ
    inp18: "", //อันดับ
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/competitionResults");
      }
    });
  }

  const columnsname = ["id", "name", "affiliation", "updateddate"];
  const columnsText = ["No", "ชื่อ", "สังกัด", "อัปเดตล่าสุด"];
  const columnsType = ["text", "text", "text", "text"];
  const rowsdata = [
    {
      id: { value: "1", type: "text" },
      name: { value: "นางสาวเทควันโด ดีงาม", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
    },
  ];
  let id = new URLSearchParams(window.location.search).get("id");
  const [getDtbyID, setGetDtbyID] = useState([]);
  const [dtSport, setDtsport] = useState([]);
  const [dtArena, setDtArena] = useState([]);
  const [dtSub, setDtSubsp] = useState([]);
  const [dtAff, setDtAff] = useState([]);
  const [isLoading, setLoading] = useState(null);
  // getDtbyID.affiliationMatch[0].affliationName
  const [getDtArena, setGetDtArena] = useState([]);
  // athletes
  let competCount = useRef(1);
  const [getDtAthletes, setGetDtathletes] = useState([]);
  const [competSchedule, setCompetSchedule] = useState([
    {
      group: competCount.current,
      formdata: {
        inp1: inputForm.inp1,
        inp2: inputForm.inp2,
        inp3: inputForm.inp3,
        inp4: inputForm.inp4,
        inp5: inputForm.inp5,
        inp6: inputForm.inp6,
        inp7: inputForm.inp7,
        inp8: inputForm.inp8,
        inp9: inputForm.inp9,
      },
      //tabledata:{datas:[],columns:[]}
      tabaledata: [], //add ข้อมูลรูปแบบเดียวกันกับ rowsdata ตรงนี้เพื่อให้แสดงผลในตาราง
      tableselected: [],
    },
  ]);
  const [getDtGroup, setGetDtGroup] = useState([]);
  const [getDtstat, setGetDtstat] = useState([]);

  useEffect(() => {
    getDtAffliation();
    getArea();
    getDataSport();
    getSubsport();
    getMatchStatID(id);
  }, []);
  const getDtAffliation = async () => {
    const respon = await getAffliation();
    let dtAff = [];
    respon.data.data.forEach((item, i) => {
      if (item.affliationName !== null) {
        return dtAff.push({
          value: item.affliationID,
          text: item.affliationName,
        });
      }
    });
    setDtAff(dtAff);
  };
  const getArea = async () => {
    let dt = [];
    const respon = await getSubArena();
    respon.data.data.forEach((item, i) => {
      return dt.push({
        value: item.subArenaID,
        text: item.subArenaName,
      });
    });
    setDtArena(dt);
  };
  const getDataSport = async () => {
    const respon = await getSport();
    let getData = [];
    respon.data.data.forEach((item, i) => {
      return getData.push({
        value: item.sportID,
        text: item.sportName,
      });
    });
    setDtsport(getData);

    // setDtsport
  };
  const getSubsport = async () => {
    const respon = await getSubSport();
    let dtSub = [];
    respon.data.data.forEach((item, i) => {
      if (item.subSportName !== null) {
        return dtSub.push({
          value: item.subSportID,
          text: item.subSportName,
        });
      }
    });
    setDtSubsp(dtSub);
  };
  const getMatchStatID = async (Id) => {
    const respon = await getMatchStatById(Id);
    inputForm.inp1 = respon.data.data.sportName;
    // inputForm.inp2 = respon.data.data.matchHeat;
    inputForm.inp3 = respon.data.data.subArena.subArenaName;
    inputForm.inp4 = respon.data.data.matchName;
    inputForm.inp5 =
      respon.data.data.matchHeat === "final"
        ? "รอบชิงชนะเลิศ"
        : inputForm.inp5 === "semi"
        ? "รอบรองชนะเลิศ"
        : inputForm.inp5 === "qualify"
        ? "รอบคัดเลือก"
        : "-";
    inputForm.inp6 =
      respon.data.data.competitionType !== "single" ? "ทีม" : "เดี่ยว";
    inputForm.inp7 =
      "(" +
      moment(new Date(respon.data.data.startTime)).format("DD/MM/YYYY") +
      // " - " +
      // moment(new Date(respon.data.data.endTime)).format("DD/MM/YYYY") +
      ")";
    inputForm.inp8 =
      "(" +
      moment(new Date(respon.data.data.startTime)).format("HH:mm:ss") +
      " - " +
      moment(new Date(respon.data.data.endTime)).format("HH:mm:ss") +
      ")";
    // (inputForm.inp5 =
    //   inputForm.inp5 === 1
    //     ? "final"
    //     : inputForm.inp5 === 2
    //     ? "semi"
    //     : inputForm.inp5 === 3
    //     ? "qualify"
    //     : ""),
    // respon.data.data.subArena.subArenaName
    setInputForm(inputForm);
    setGetDtbyID(respon.data.data);
    console.log("inputForm preview : ", inputForm);
    // setGetDtstat((pev) => {
    //   let data = { ...pev };
    //   data[competSchedule[index].dataStat[0].affliationID] = {
    //     ...data[competSchedule[index].dataStat[0].affliationID],
    //     remark: e ? e.target.value : data.dataStat[0].remark,
    //   };
    //   return data;
    // });
    let dtAth;
    if (respon.data.data.affiliationMatch.length > 0) {
      setGetDtArena(respon.data.data.affiliationMatch[0].affliationName);
      dtAth = respon.data.data.affiliationMatch[0].athletes;
    } else {
      setGetDtArena("-");

      dtAth = "";
    }

    let getData = [];
    let objectData = respon.data.data.affiliationMatch;
    let couter = 0;
    for (let i = 0; i < objectData.length; i++) {
      let tabaledata = {
        group: i,
        tabaledata: [],
        dataStat: [],
      };

      for (let j = 0; j < objectData[i].athletes.length; j++) {
        couter++;
        tabaledata.dataStat.push({
          affliationID: objectData[i].affliationID,
          no: objectData[i].no,
          record: objectData[i].record,
          remark: objectData[i].remark,
          affliationName: objectData[i].affliationName,
          dataID: objectData[i].athletes[j].atheleteID,
        });
        tabaledata.tabaledata.push({
          id: {
            value: objectData[i].athletes[j].atheleteID,
            type: "text",
            dataID: objectData[i].athletes[j].atheleteID,
          },
          name: {
            value:
              objectData[i].athletes[j].firstname +
              " " +
              objectData[i].athletes[j].lastname,
            type: "text",
          },
          affiliation: {
            value: objectData[i].affliationName,
            affliationID: objectData[i].affliationID,
            type: "text",
          },
          updateddate: {
            value:
              respon.data.data.updatedAt === null
                ? moment(new Date(respon.data.data.createdAt)).format(
                    "YYYY-MM-DD"
                  )
                : moment(new Date(respon.data.data.updatedAt)).format(
                    "YYYY-MM-DD"
                  ),
            type: "text",
          },
        });
      }
      if (i == 0) {
        competSchedule[0] = tabaledata;
      } else {
        competSchedule.push(tabaledata);
      }
    }
  };

  const submitData = async () => {
    const lengthDt = Object.keys(getDtstat).length;
    console.log("getDtstat data : ", getDtstat);
    let dataArr = [];
    if (getDtstat.length < 1 && typeof getDtstat !== "object") {
      for (let i = 0; i < competSchedule.length; i++) {
        for (let j = 0; j < competSchedule[i].dataStat.length; j++) {
          dataArr.push({
            affliationId: competSchedule[i].dataStat[j].affliationID,
            no: competSchedule[i].dataStat[j].no,
            record: competSchedule[i].dataStat[j].record,
            remark: competSchedule[i].dataStat[j].remark,
            athlete: [{ athletesId: competSchedule[i].dataStat[j].dataID }],
          });
        }
      }
    } else {
      // console.log("data.dataStat[0].no : ", data.dataStat[0].no);
      console.log("competSchedule : ", competSchedule);
      for (let i = 0; i < competSchedule.length; i++) {
        for (let j = 0; j < competSchedule[i].dataStat.length; j++) {
          for (const key in getDtstat) {
            // &&lengthDt > 1
            if (
              parseInt(key) === competSchedule[i].dataStat[j].affliationID &&
              key &&
              lengthDt > 1
            ) {
              console.log("competSchedule : test A ");
              dataArr.push({
                affliationId: competSchedule[i].dataStat[j].affliationID,
                no:
                  getDtstat[key].no === undefined
                    ? competSchedule[i].dataStat[j].no
                    : getDtstat[key].no,
                record:
                  getDtstat[key].record === undefined
                    ? competSchedule[i].dataStat[j].record
                    : getDtstat[key].record,
                remark:
                  getDtstat[key].remark === undefined
                    ? competSchedule[i].dataStat[j].remark
                    : getDtstat[key].remark,
                athlete: [{ athletesId: competSchedule[i].dataStat[j].dataID }],
              });
            }
            // parseInt(key) !== competSchedule[i].dataStat[j].affliationID &&
            else if (lengthDt === 1) {
              console.log("competSchedule : test B ");
              dataArr.push({
                affliationId: competSchedule[i].dataStat[j].affliationID,
                no:
                  parseInt(key) !== competSchedule[i].dataStat[j].affliationID
                    ? competSchedule[i].dataStat[j].no
                    : getDtstat[key].no === undefined
                    ? competSchedule[i].dataStat[j].no
                    : // ? "-"
                      getDtstat[key].no,
                record:
                  parseInt(key) !== competSchedule[i].dataStat[j].affliationID
                    ? competSchedule[i].dataStat[j].record
                    : getDtstat[key].record === undefined
                    ? competSchedule[i].dataStat[j].record
                    : getDtstat[key].record,
                remark:
                  parseInt(key) !== competSchedule[i].dataStat[j].affliationID
                    ? competSchedule[i].dataStat[j].remark
                    : getDtstat[key].remark === undefined
                    ? competSchedule[i].dataStat[j].remark
                    : getDtstat[key].remark,
                // no: "",
                // record: "",
                // remark: "",

                athlete: [{ athletesId: competSchedule[i].dataStat[j].dataID }],
              });
            }
          }
        }
      }
    }
    let spDta = dtSport.filter((item) => item.text === getDtbyID.sportName);
    let filterAff = dtAff.filter((item) => item.text === getDtArena);

    let filterSub = dtSub.filter(
      (item) => item.text === getDtbyID.subsportName
    );
    let dt = {
      matchName: getDtbyID.matchName,
      startTime: getDtbyID.startTime,
      endTime: getDtbyID.endTime,
      sportId: spDta[0].value || "-",
      subsportId: filterSub.length > 0 ? filterSub[0].value : "-",
      competitionType: getDtbyID.competitionType,
      stat: "",
      affliations: dataArr,
    };
    console.log("dt body : ", dt);
    if (getDtstat.length < 1) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "บันทึก",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    } else {
      Swal.fire({
        title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          (async () => {
            // const respon = null;
            const respon = await updateMatchStat(id, dt);
            setLoading(false);
            if (respon.data.status === "success") {
              await Swal.fire({
                title: "แก้ไขข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToBack("/competitionResults");
                }
              });
            }
          })();
        }
      });
    }
  };
  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ผลการแข่งขัน"} />
      <Menu parentmenu={"competitionData"} activemenu={"competitionResults"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-12 mt-2">
              <img
                src="assets/icons/im-competition.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ผลการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการแข่งขัน / ผลการแข่งขัน
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูลการแข่งขัน
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <input
                  id="inputSportType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp1}
                  onChange={(e) => setinput("inp1", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <input
                  id="inputSportSubType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp2}
                  onChange={(e) => setinput("inp2", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <input
                  id="inputArena"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp3}
                  onChange={(e) => setinput("inp3", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputSportType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp4}
                  onChange={(e) => setinput("inp4", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputSportSubType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp5}
                  onChange={(e) => setinput("inp5", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputArena"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp6}
                  onChange={(e) => setinput("inp6", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputSportType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp7}
                  onChange={(e) => setinput("inp7", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputSportSubType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp8}
                  onChange={(e) => setinput("inp8", e.target.value)}
                  disabled
                />
              </div>
            </div>
          </div>
          {competSchedule.map((data, index) => {
            return (
              <div className="card-white mt-3" key={data.group}>
                <div className="row">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      รายการนักกีฬา
                    </p>
                  </div>
                </div>
                <div className="row mb-4 d-flex justify-content-between">
                  <div className="col-12 col-md-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                    <input
                      id="inputAffiliation"
                      className="inp-data01 fontReg font-size03"
                      // affliationName
                      value={
                        data.dataStat ? data.dataStat[0].affliationName : ""
                      }
                      name={"inputAffiliation " + index}
                      // onChange={(e) => console.log("data : ", e.target.name)}
                      // onChange={(e) => setinput("inp9", e.target.value)}
                      disabled
                    />
                  </div>
                  {/* <div className="col-12 col-md-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                    <input
                      id="inputScore"
                      className="inp-data01 fontReg font-size03"
                      placeholder="คะแนน"
                    
                      value={data.dataStat ? data.dataStat[0].record : ""}
                      onChange={
                        (e) => {
                          setGetDtstat((pev) => {
                            let data = { ...pev };
                            data[
                              competSchedule[index].dataStat[0].affliationID
                            ] = e.target.value;
                            return data;
                          });

                   
                        }
                     
                      }
                
                    />
                  </div> */}
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-4">
                    <input
                      id="inputStatistics"
                      className="inp-data01 fontReg font-size03"
                      placeholder="สถิติ"
                      // value={inputForm.inp13}
                      // onChange={(e) => setinput("inp13", e.target.value)}
                      defaultValue={
                        data.dataStat ? data.dataStat[0].record : ""
                      }
                      // record
                      onChange={(e) => {
                        setGetDtstat((pev) => {
                          let data = { ...pev };
                          data[competSchedule[index].dataStat[0].affliationID] =
                            {
                              ...data[
                                competSchedule[index].dataStat[0].affliationID
                              ],
                              record: e
                                ? e.target.value
                                : data.dataStat[0].record,
                            };
                          return data;
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <input
                      id="inputRemark"
                      className="inp-data01 fontReg font-size03"
                      placeholder="หมายเหตุ"
                      defaultValue={
                        data.dataStat ? data.dataStat[0].remark : ""
                      }
                      onChange={(e) => {
                        setGetDtstat((pev) => {
                          let data = { ...pev };
                          data[competSchedule[index].dataStat[0].affliationID] =
                            {
                              ...data[
                                competSchedule[index].dataStat[0].affliationID
                              ],
                              remark: e
                                ? e.target.value
                                : data.dataStat[0].remark,
                            };
                          return data;
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <input
                      id="inputRating"
                      className="inp-data01 fontReg font-size03"
                      placeholder="อันดับ"
                      defaultValue={data.dataStat ? data.dataStat[0].no : ""}
                      onChange={(e) => {
                        setGetDtstat((pev) => {
                          let data = { ...pev };
                          data[competSchedule[index].dataStat[0].affliationID] =
                            {
                              ...data[
                                competSchedule[index].dataStat[0].affliationID
                              ],
                              no: e ? e.target.value : data.dataStat[0].no,
                            };
                          return data;
                        });
                      }}
                      // value={inputForm.inp15}
                      // onChange={(e) => setinput("inp15", e.target.value)}
                    />
                  </div>
                </div>
                <TableData
                  actionsBtn={enableButton}
                  columnsname={columnsname}
                  columnsText={columnsText}
                  columnsType={columnsType}
                  rowsdata={data.tabaledata}
                  isSearchEnable={false}
                />
              </div>
            );
          })}

          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-save"
                onClick={() => {
                  submitData();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditcompetresultsTeams;
