import React, { useState } from "react";
import "./Forgotpassword.css";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import {
  postForgotpassword,
  postForgotpassword_new,
} from "../../api/forgotpassword_api";
import { getDataUser } from "../config/configApi-User";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

function Forgotpassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [typeNewPass, setTypeNewPass] = useState("password");
  const [iconNewPass, setIconNewPass] = useState(eyeOff);
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errRepassword, setErrRepassword] = useState("");
  const [passwordColor, setPasswordColor] = useState("");
  const [repasswordColor, setRepasswordColor] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageCom, setErrorMessageCom] = useState("");
  const tokenDt = new URLSearchParams(location.search).get("resetToken");
  const handleToggleNewPass = () => {
    if (typeNewPass === "password") {
      setIconNewPass(eye);
      setTypeNewPass("text");
    } else {
      setIconNewPass(eyeOff);
      setTypeNewPass("password");
    }
  };

  const [typeCFPass, setTypeCFPass] = useState("password");
  const [iconCFPass, setIconCFPass] = useState(eyeOff);
  const handleToggleCFPass = () => {
    if (typeCFPass === "password") {
      setIconCFPass(eye);
      setTypeCFPass("text");
    } else {
      setIconCFPass(eyeOff);
      setTypeCFPass("password");
    }
  };

  const getApiUser = async () => {
    const respon = await getDataUser();
    if (respon.data.status === "success") {
      // getEmail(respon.data.data.email);
      let data = { email: respon.data.data.email };
      const rs = await postForgotpassword(data);
      if (rs.data.status === "success") {
      }
    }
  };
  const postForgotpaaword = async (e) => {
    let data = { password: password };
    await Swal.fire({
      title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          const respon = await postForgotpassword_new(data, tokenDt);
          // setLoading(1);
          if (respon.data.status === "success") {
            Swal.fire({
              title: "บันทึกข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/login");
              }
            });
          }
        })();
      }
    });
  };
  const validateForm = (e) => {
    e.preventDefault();

    if (password.length >= 8) {
      setErrPassword("");
      setPasswordColor("#8cac4b");
    } else {
      setErrPassword("รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร");
      setPasswordColor("#dc3545");
    }

    if (repassword !== "" && repassword === password) {
      setErrRepassword("");
      setRepasswordColor("#8cac4b");
    } else {
      setErrRepassword("รหัสผ่านไม่ตรงกัน");
      setRepasswordColor("#dc3545");
    }
  };

  function handlePassword(event) {
    let new_pass = event.target.value;
    // setPassword(new_pass);

    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (!new_pass.match(lowerCase)) {
      setErrorMessage("กรุณากรอกตัวพิมพ์เล็กอย่างน้อย 1 ตัว");
      setPasswordColor("#dc3545");
    } else if (!new_pass.match(upperCase)) {
      setErrorMessage("กรุณากรอกตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว");
      setPasswordColor("#dc3545");
    } else if (!new_pass.match(numbers)) {
      setErrorMessage("กรุณากรอกตัวเลข");
      setPasswordColor("#dc3545");
    } else if (new_pass.length < 8) {
      setErrorMessage("รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร");
      setPasswordColor("#dc3545");
    } else {
      setErrorMessage("รหัสผ่านสามารถใช้งานได้");
      setPasswordColor("#8cac4b");
      // if (repassword !== "" && repassword === password) {
      //   setErrRepassword("");

      //   setRepasswordColor("#8cac4b");
      // } else {
      //   setErrRepassword("รหัสผ่านไม่ตรงกัน");
      //   setRepasswordColor("#dc3545");
      // }
    }
    setPassword(new_pass);
  }
  function handlePasswordConfirm(event) {
    let new_pass_con = event.target.value;
    setRepassword(new_pass_con);

    // setPassword(new_pass);

    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (!new_pass_con.match(lowerCase)) {
      setErrorMessageCom("กรุณากรอกตัวพิมพ์เล็กอย่างน้อย 1 ตัว");
      setRepasswordColor("#dc3545");
    } else if (!new_pass_con.match(upperCase)) {
      setErrorMessageCom("กรุณากรอกตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว");
      setRepasswordColor("#dc3545");
    } else if (!new_pass_con.match(numbers)) {
      setErrorMessageCom("กรุณากรอกตัวเลข");
      setRepasswordColor("#dc3545");
    } else if (new_pass_con.length < 8) {
      setErrorMessageCom("รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร");
      setRepasswordColor("#dc3545");
    } else {
      setErrorMessageCom("รหัสผ่านสามารถใช้งานได้");
      setRepasswordColor("#8cac4b");

      if (new_pass_con !== "" && new_pass_con === password) {
        setErrorMessageCom("รหัสผ่านตรงกัน");

        setRepasswordColor("#8cac4b");
      } else {
        setErrorMessageCom("รหัสผ่านไม่ตรงกัน");
        setRepasswordColor("#dc3545");
      }
    }
    // setPassword(new_pass);
  }
  function handleChange(event) {}
  return (
    <>
      <nav className=" navbar navbar-expand navbar-white navbar-light">
        <div className="container">
          <img
            src="assets/images/im-logoNW.png"
            alt="NW-Sports Logo"
            className="logoNW-fp img-circle"
          />
          <span className="fontReg font-weight-bold font-size01 line-height01 colorViolet02 d-none d-sm-inline-block">
            ระบบจัดการข้อมูลงานกีฬานครสวรรค์
          </span>
          <span className="fontReg font-weight-bold font-size02 line-height01 ml-2 colorGray01">
            ตั้งรหัสผ่านใหม่
          </span>
        </div>
      </nav>
      <div className="containerFluid">
        <div className="d-flex align-items-center justify-content-center">
          <div className="card-fp">
            <div className="col-12 d-flex justify-content-center">
              <span className="fontReg font-weight-bold font-size01 line-height01 colorGray01">
                ตั้งรหัสผ่านใหม่
              </span>
            </div>
            <form className="form" onSubmit={validateForm}>
              <div className="row mt-3">
                <div className="col-12 col-sm-3 d-flex align-items-center">
                  <span className="fontReg font-size03 line-height01 colorGray01">
                    รหัสผ่านใหม่
                  </span>
                </div>
                <div className="col-12 col-sm-9">
                  {/* <label>Password Again:</label>
                  <input
                    className="inp-login fontLight font-size03"
                    type="password"
                    onChange={(e) => setPasswordAgain(e.target.value)}
                  />

                  <PasswordChecklist
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    minLength={8}
                    value={password}
                    valueAgain={passwordAgain}
                    messages={{
                      minLength: "La contraseña tiene más de 8 caracteres.",
                      specialChar: "La contraseña tiene caracteres especiales.",
                      number: "La contraseña tiene un número.",
                      capital: "La contraseña tiene una letra mayúscula.",
                      match: "Las contraseñas coinciden.",
                    }}
                  /> */}
                  {/* <input
                    type="text"
                    value={password}
                    onChange={handlePassword}
                  /> */}

                  <div
                    className="inp-field"
                    style={{ borderColor: passwordColor }}
                  >
                    <input
                      type={typeNewPass}
                      className="inp-login fontLight font-size03"
                      id="new_password"
                      placeholder="ต้องมีทั้งตัวเลขและตัวอักษร อย่างน้อง 8 ตัว"
                      value={password}
                      onChange={handlePassword}
                      // onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="mr-2" onClick={handleToggleNewPass}>
                      <Icon icon={iconNewPass} size={15} />
                    </span>
                  </div>
                  <small style={{ color: passwordColor }}>{errorMessage}</small>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 col-sm-3 d-flex align-items-center">
                  <span className="fontReg font-size03 line-height01 colorGray01">
                    ยืนยันรหัสผ่านใหม่
                  </span>
                </div>
                {/* <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                /> */}

                <div className="col-12 col-sm-9">
                  <div
                    className="inp-field"
                    style={{ borderColor: repasswordColor }}
                  >
                    <input
                      type={typeCFPass}
                      className="inp-login fontLight font-size03"
                      id="confirm_password"
                      placeholder="กรุณาระบุรหัสผ่านอีกครั้ง"
                      value={repassword}
                      // onChange={handlePasswordConfirm}
                      //  setRepassword(e.target.value)
                      onChange={handlePasswordConfirm}
                    />
                    <span className="mr-2" onClick={handleToggleCFPass}>
                      <Icon icon={iconCFPass} size={15} />
                    </span>
                  </div>
                  <small style={{ color: repasswordColor }}>
                    {errorMessageCom}
                  </small>
                  {/* <small style={{ color: passwordColor }}>{errorMessage}</small> */}
                  {/* <div
                    className="inp-field"
                    style={{ borderColor: repasswordColor }}
                  >
                    <input
                      type={typeCFPass}
                      className="inp-login fontLight font-size03"
                      id="confirm_password"
                      placeholder="กรุณาระบุรหัสผ่านอีกครั้ง"
                      value={repassword}
                      onChange={(e) => setRepassword(e.target.value)}
                    />
                    <span className="mr-2" onClick={handleToggleCFPass}>
                      <Icon icon={iconCFPass} size={15} />
                    </span>
                  </div>
                  <small style={{ color: repasswordColor }}>
                    {errRepassword}
                  </small> */}
                </div>
              </div>
              <div className="col-12 p-0 mt-3">
                <button
                  disabled={
                    repassword !== password ||
                    repassword === "" ||
                    password === ""
                  }
                  onClick={(event) => postForgotpaaword()}
                  type="submit"
                  className="btn-blue fontReg font-weight-bold font-size03"
                >
                  ยืนยัน
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgotpassword;
