import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import Loading from "../pages/Loading";
import Buttonexport from "../button/Button3items";
import "../CSS/Addedit.css";
import {
  getSleeppackList,
  getSleeppackById,
  removeStaff,
  sleeppackFilter,
} from "../../api/sleeppack_api";
// import { numberWithCommas } from "../formatNumber/NumericNumber";

import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";

function Borrowbedding() {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const enableButton = { view: true, edit: true, delete: true };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const columnsname = [
    "id",
    "bedName",
    "bedCount",
    "status",
    "bedPrice",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ชื่อเครื่องนอน",
    "จำนวนเครื่องนอน",
    "สถานะ",
    "มูลค่าปรับของเครื่องนอน",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];
  const rowsData = [
    // {
    //   id: { value: "1", type: "text" },
    //   bedName: { value: "หมอน", type: "text" },
    //   bedCount: { value: "20", type: "text" },
    //   bedPrice: { value: "30,4442.00", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];
  let rowsdata = [];
  const [dataSleeppack, setDataSleeppack] = useState([]);
  const [dataSleeppackID, setDataSleeppackID] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getDataSleeppack();
  }, []);
  const getDataSleeppack = async () => {
    setLoading(true);
    try {
      const respon = await getSleeppackList();
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          //numberWithCommas(respon.data.data.sleepPackAmount.toFixed(0))
          id: {
            value: i + 1,
            type: "text",
            dataID: objectData.sleepPackID,
          },
          bedName: {
            value:
              objectData.sleepPackName === null
                ? "-"
                : objectData.sleepPackName,
            type: "text",
          },
          bedCount: {
            value:
              objectData.sleepPackAmount === null
                ? "-"
                : numberWithCommas(objectData.sleepPackAmount.toFixed(0)),
            type: "text",
          },
          bedPrice: {
            value:
              objectData.sleepPackFee === null
                ? "-"
                : numberWithCommas(objectData.sleepPackFee.toFixed(0)),
            type: "text",
          },
          status: {
            value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",

            active: objectData.active,
            type: "text",
          },

          updateddate: {
            value: moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });
      await setLoading(false);
      setDataSleeppack(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addborrowbedding`);
        break;
      case "edit":
        // handle edit button
        // Editborrowbedding?id=1
        navigate(`/Addborrowbedding?id=${id}`);
        break;
      case "view":
        // handle view button
        // viewModal(id)
        getSleeppackID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        deleteSleeppackByID(id);
        // handle delete button
        // deleteByID(id)
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const getSleeppackID = async (Id) => {
    let getData = [];
    const respon = await getSleeppackById(Id);

    getData.push({
      active:
        respon.data.data.active == true
          ? (document.getElementById("status").checked = true)
          : (document.getElementById("status").checked = false),
      sleepPackAmount:
        respon.data.data.sleepPackAmount !== null
          ? numberWithCommas(respon.data.data.sleepPackAmount.toFixed(0))
          : "-",
      sleepPackFee:
        respon.data.data.sleepPackFee !== null
          ? numberWithCommas(respon.data.data.sleepPackFee.toFixed(0))
          : "-",
      sleepPackID:
        respon.data.data.sleepPackID !== null
          ? respon.data.data.sleepPackID
          : "-",
      sleepPackName:
        respon.data.data.sleepPackName !== null
          ? respon.data.data.sleepPackName
          : "-",
    });

    setDataSleeppackID(getData[0]);
  };
  // deleteSleeppackByID
  const deleteSleeppackByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await removeStaff(id);
            if (response.data.status === "success") {
              getDataSleeppack();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    inp1: "",
  });
  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.inp1 = undefined;
    getDataSleeppack();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ชื่อเครื่องนอน",
      "จำนวนเครื่องนอน",
      "สถานะการใช้งาน",
      "มูลค่าปรับของเครื่องนอน",
    ];

    let Data = dataSleeppack.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.bedName.value;
      dt[2] = elemento.bedCount.value;
      dt[3] = elemento.status.value;
      dt[4] = elemento.bedPrice.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานข้อมูลเครื่องนอน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const getDataFilter = async () => {
    setLoading(true);
    if (inputForm.inp1 === "") {
      getDataSleeppack();
    } else {
      let bodyFilter = {
        inp1: inputForm.inp1,
      };
      const response = await sleeppackFilter(bodyFilter);
      response.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          //
          id: {
            value: i + 1,
            type: "text",
            dataID: objectData.sleepPackID,
          },
          bedName: {
            value:
              objectData.sleepPackName === null
                ? "-"
                : objectData.sleepPackName,
            type: "text",
          },
          bedCount: {
            value:
              objectData.sleepPackAmount === null
                ? "-"
                : numberWithCommas(objectData.sleepPackAmount.toFixed(2)),
            type: "text",
          },
          bedPrice: {
            value:
              objectData.sleepPackFee === null
                ? "-"
                : numberWithCommas(objectData.sleepPackFee.toFixed(2)),
            type: "text",
          },
          status: {
            value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",

            active: objectData.active,
            type: "text",
          },

          updateddate: {
            value: moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });
      await setLoading(false);
      setDataSleeppack(rowsdata);
    }
  };

  function navigateToBack(page) {
    navigate(page);
  }

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ข้อมูลเครื่องนอน"} />
      <Menu parentmenu={"borrowReturn"} activemenu={"brBedding"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-borrow.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ยืม-คืนชุดเครื่องนอน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ยืม-คืนชุดเครื่องนอน / ข้อมูลเครื่องนอน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Buttonexport
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addborrowbedding"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาชื่อเครื่องมือเครื่องนอน"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    getDataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการข้อมูลเครื่องนอน
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataSleeppack}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                id="exampleModalLabel"
              >
                ดูรายละเอียด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูล
                </p>
              </div>
              <div className="row">
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    ชื่อเครื่องนอน: {dataSleeppackID.sleepPackName}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    จำนวนเครื่องนอน: {dataSleeppackID.sleepPackAmount}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ml-2">
                  <p className="fontReg font-size03 colorGray01">
                    ค่าปรับ: {dataSleeppackID.sleepPackFee}
                  </p>
                </div>
              </div>
              <div className="col">
                <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                  ใช้งาน
                  <input
                    type="checkbox"
                    value={dataSleeppackID.active}
                    id="status"
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Borrowbedding;
