import React from 'react';
import './CheckboxCenter.css';

function CheckboxCenter(props) {
  const { value, text, id, handleClick, isChecked, addCheckbox } = props;
  return (
    <>
      <label className="checkbox-item02 fontReg font-size03 colorGray01">
        {text}
        <input type="checkbox" 
          value={value}
          id={id} 
          onChange={(ev) => {
            handleClick(ev)
          }}
          
          checked={isChecked}
          addCheckbox={addCheckbox}
        />
        <span className="checkmark02" />
      </label>
    </>
  );
}

export default CheckboxCenter