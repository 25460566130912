import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
function ButtonShop(props) {
  const alertDelete = () => {
    Swal.fire({
      title: "ต้องการลบรายการใช่หรือไม่",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#0698FF",
      confirmButtonText: "ยืนยัน",
      cancelButtonColor: "#D1D3D4",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "ลบรายการสำเร็จแล้ว",
          confirmButtonColor: "#0698FF",
          confirmButtonText: "ตกลง",
        });
      }
      navigate("/recommendStore");
    });
  };

  const { id, pageAddEditName, actionsBtn } = props;

  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  return (
    <div className="row align-items-center justify-content-center">
      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 align-items-center justify-content-center justify-content-md-end  ${
          actionsBtn.view ? "d-flex" : "d-none"
        } `}
      >
        <button
          className={`btn-view`}
          data-toggle="modal"
          data-target="#exampleModal"
        >
          <img src="assets/icons/ic-view.png" alt="" className="ic-actions" />
        </button>
      </div>
      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
          actionsBtn.edit ? "d-flex" : "d-none"
        } `}
      >
        <button
          onClick={() => {
            let querystring = "";
            if (id) {
              querystring = "?id=" + id;
            }
            navigateToAddEdit(pageAddEditName + querystring);
          }}
          type="button"
          className="btn-edit"
        >
          <img src="assets/icons/ic-edit.png" alt="" className="ic-actions" />
        </button>
      </div>
      <div
        className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center justify-content-md-start ${
          actionsBtn.delete ? "d-flex" : "d-none"
        } `}
      >
        <button
          className="btn-delete"
          onClick={() => {
            let querystring = "";
            if (id) {
              querystring = "?id=" + id;
            }
            alertDelete();
          }}
          type="button"
        >
          <img
            src="assets/icons/ic-delete.png"
            alt=""
            className="ic-actions"
            color="white"
          />
        </button>
      </div>
    </div>
  );
}

export default ButtonShop;
