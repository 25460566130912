import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import { useNavigate } from "react-router-dom";
import Button3items from "../button/Button3items";
import moment from "moment";
import Swal from "sweetalert2";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";

import {
  getDataArea,
  getDataAreaByID,
  postDeletArea,
  getDataAreaFilter,
  getDataSubArea,
} from "../config/configApi-Area";
import // DataDropdownSportType,
// DataDropdownStatus,
"../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import {
  getSport,
  getSportById,
  deleteSport,
  getSportFilter,
} from "../../api/sport_api";
import Loading from "./Loading";

function Mastersporttype() {
  const DataDropdownStatus = [
    { value: "", text: "ทั้งหมด" },
    { value: true, text: "ใช้งาน" },
    { value: false, text: "ไม่ใช้งาน" },
  ];
  let [DataDropSporttype, setDataDropSporttype] = useState([]);
  let [datasubArea, setDatasubArea] = useState([]);
  let DataDropdownSportType = [{ value: "0", text: "ทั้งหมด" }];
  let rowsdata = [
    //   {
    //     id: { value: "1", type: "text" },
    //     image: { value: "assets/images/im-taekwondo.png", type: "image" },
    //     sportName: { value: "เทควันโด", type: "text" },
    //     sportTypeAmount: { value: "10", type: "text" },
    //     status: { value: "ใช้งาน", type: "text" },
    //     updateddate: { value: "7/5/2022", type: "text" },
    //     action: { value: "1", type: "action" },
    //   },
  ];
  const [inputForm, setInputForm] = useState({
    inp1: undefined, //ชื่อกีฬา
    inp2: undefined, //สถานะ
  });
  const [dataSport, setDataSport] = useState([]);
  const [dataSpID, setDataSportID] = useState([]);
  const [spanloader, setspanloader] = useState(false);
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };

  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }
  const getDataSport = async () => {
    setspanloader(true);
    try {
      const respon = await getSport();
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: {
            value: i + 1,
            type: "text",
            dataID: objectData.sportID,
          },
          image: {
            value:
              objectData.sportImage === null || objectData.sportImage === ""
                ? "assets/images/img-notimg.png"
                : objectData.sportImage,
            type: "image",
          },

          sportName: {
            value: objectData.sportName,
            type: "text",
          },
          sportTypeAmount: {
            value: objectData.subsport.length,
            type: "text",
          },
          status: {
            value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            // active: objectData.active,
            type: "text",
          },

          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });
      rowsdata.forEach((data, i) => {
        return DataDropdownSportType.push({
          value: i + 1,
          text: data.sportName.value,
        });
      });
      await setspanloader(false);
      setDataSport(rowsdata);
      // getFilterdropdown(DataDropdownSportType);
    } catch (error) {
      console.log(error.message);
    }
  };
  // filter dropdown
  // const getFilterdropdown = async (data) => {
  //   var dataArr = data.map((item) => {
  //     return [item.text, item];
  //   }); // creates array of array
  //   var maparr = new Map(dataArr); // create key value pair from array of array
  //   var result = [...maparr.values()]; //converting back to array from mapobject
  //   setDataDropSporttype(result);
  // };

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/Addeditathlete`)
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditsporttype?id=${id}`);
        // navigate(`/Addeditathlete/?id=${id}`)
        break;
      case "view":
        // handle view button
        viewModal(id);
        getSportID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteSpTypeByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const viewModal = async (id) => {
    try {
    } catch (error) {}
  };
  const deleteSpTypeByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteSport(id);
            if (response.data.status === "success") {
              getDataSport();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
              // swalWithBootstrapButtons.fire("ลบรายการสำเร็จ!", " ", "success");
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleExport = async (type) => {
    let Header = [
      "No",
      "ชื่อกีฬา",
      "จำนวนประเภทการแข่งขัน",
      // "สถานะ",
      "อัปเดตล่าสุด",
    ];

    let Data = dataSport.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.sportName.value;
      dt[2] = elemento.sportTypeAmount.value;
      // dt[3] = elemento.status.value;
      dt[3] = elemento.updateddate.value;
      return dt;
    });

    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานชนิดกีฬา",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };
  const getSportID = async (Id) => {
    let getData = [];
    const respon = await getSportById(Id);

    getData.push({
      // status: respon.data.data.active === true ? "ไม่ใช้งาน" : "ใช้งาน",
      // active: respon.data.data.active,
      createdAt: respon.data.data.createdAt,
      deletedAt: respon.data.data.deletedAt,
      deletedStatus: respon.data.data.deletedStatus,
      sportID: respon.data.data.sportID,
      sportImage:
        respon.data.data.sportImage === null
          ? "assets/images/img-notimg.png"
          : respon.data.data.sportImage,
      sportName: respon.data.data.sportName,
      updatedAt: moment(new Date(respon.data.data.updatedAt)).format(
        "YYYY-MM-DD"
      ),
      subsport:
        respon.data.data.subsport.length > 0 ? respon.data.data.subsport : 0,
      active: respon.data.data.active === false ? "ไม่ใช้งาน" : "ใช้งาน",
    });
    setDataSportID(getData[0]);
    // setDataImage(getData[0].image);
    setDatasubArea(getData[0].subsport);
  };

  const columnsname = [
    "id",
    "image",
    "sportName",
    "sportTypeAmount",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "",
    "ชนิดกีฬา",
    "จำนวนประเภทการแข่งขัน",
    "สถานะ",

    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "image",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];
  const getArea = async () => {
    // inputForm
    const respon = await getDataSubArea();

    respon.data.data.forEach((objectData, i) => {
      return rowsdata.push({
        //
        createdAt: "2023-01-12T14:42:42.000Z",
        deletedAt: null,
        gate: "44",
        staffInArena: [],
        subArenaID: 6,
        subArenaName: "ประตูสู่นรก",
        subArenaType: 15,
        updatedAt: null,
        //
        id: { value: i + 1, type: "text", dataID: objectData.subArenaID },
        arena: { value: objectData.subArenaName, type: "text" },
        arenaSub: { value: objectData.subArenaName, type: "text" },
        status: {
          value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
          active: objectData.active,
          type: "text",
        },
        updateddate: {
          value:
            objectData.updatedAt !== null
              ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
              : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
          type: "text",
        },
        action: { value: "1", type: "action" },
        //
      });
    });
    let dataps = [];
    respon.data.data.forEach((data, i) => {
      return dataps.push({
        value: i + 1,
        text: data.subArenaName,
      });
    });
    // getFilterdropdown(dataps);

    // setDataDropArenas(DataDropdownArenas);

    // arenaName
    // setDataSubArena(rowsdata);
    // return rowsdata;
  };
  const getDataFilter = async () => {
    setspanloader(true);
    // if (inputForm.inp1 === "ทั้งหมด" || inputForm.inp1 === null ||  inputForm.inp1 === 0) {
    //   inputForm.inp1 = null;
    // }
    // if (inputForm.inp2 === "ทั้งหมด" || inputForm.inp2 === null |  inputForm.inp2 === 0) {
    //   inputForm.inp2 = null;
    // }
    if (selectedStatus !== "" || selectedSport !== "") {
      let bodyFilter = {
        inp1: selectedSport,
        inp2: selectedStatus,
      };

      let respon;
      respon = await getSportFilter(bodyFilter);
      if (respon.data.status === "success") {
        respon.data.data.forEach((objectData, i) => {
          return rowsdata.push({
            id: {
              value: i + 1,
              type: "text",
              dataID: objectData.sportID,
            },
            image: {
              value:
                objectData.sportImage === null || objectData.sportImage === ""
                  ? "assets/images/img-notimg.png"
                  : objectData.sportImage,
              type: "image",
            },

            sportName: {
              value: objectData.sportName,
              type: "text",
            },
            sportTypeAmount: {
              value: objectData.subsport.length,
              type: "text",
            },
            status: {
              value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
              active: true,
              // active: objectData.active,
              type: "text",
            },

            updateddate: {
              value:
                objectData.updatedAt === null
                  ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                  : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
              type: "text",
            },
            action: {
              value: "1",
              type: "action",
            },
          });
        });
      } else {
        console.log("error");
      }
      setDataSport(rowsdata);
      await setspanloader(false);
    } else {
      await setspanloader(false);
      getDataSport();
    }
  };

  useEffect(() => {
    getDataSport();
    getSportDropDown();
  }, []);

  const [valuefromparent, setValuefromparent] = useState("ทั้งหมด");
  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");

  function resetDropdown() {
    setSelecteSport("");
    setSelecteStatus("");
    setValuefromparent("ทั้งหมด");
    setValuefromparent1("ทั้งหมด");
    getDataSport();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent(text) {
    setValuefromparent(text);
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const getSportDropDown = async () => {
    try {
      const response = await getSport();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.sportID,
            text: item.sportName,
            subType: [],
          });
        });

        setDataDropSporttype(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedSport, setSelecteSport] = useState("");
  const [selectedStatus, setSelecteStatus] = useState("");
  const handleSportChange = (value) => {
    setSelecteSport(value);
  };
  const handleStatusChange = (value) => {
    setSelecteStatus(value);
  };

  return (
    <>
      {spanloader === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"ชนิดกีฬา"} />
      <Menu parentmenu={"masterData"} activemenu={"sportType"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ชนิดกีฬา
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / ชนิดกีฬา
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditsporttype"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-5 pr-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชนิดกีฬา
                  </p>
                  <Dropdown
                    options={DataDropSporttype}
                    setinput={setinput}
                    inputname={"inp1"}
                    ChangesetValuefromparent={ChangesetValuefromparent}
                    valuefromparent={valuefromparent}
                    onChange={handleSportChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5 pr-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สถานะ</p>
                  <Dropdown
                    options={DataDropdownStatus}
                    setinput={setinput}
                    inputname={"inp2"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>

              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button
                  className="btn-blue01"
                  onClick={(event) => getDataFilter()}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                    {/* {spanloader === true ? (
                      <>
                        <span class="loader-search"></span>
                      </>
                    ) : (
                      <></>
                    )} */}
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการชนิดกีฬา
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Addeditsporttype"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataSport}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูล
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อกีฬา: {dataSpID.sportName}
                    </p>
                  </div>
                  <div className="col-12 ml-2 mb-3">
                    <span
                      className={
                        dataSpID.sportImage ===
                        "https://nw-smartsports.verkoffice.com/appnull"
                          ? "d-none"
                          : "fontReg font-size03 colorGray01 d-flex"
                      }
                    >
                      รูปภาพ:{" "}
                    </span>
                    <img
                      src={dataSpID.sportImage}
                      alt="Type Sport"
                      className={
                        dataSpID.sportImage ===
                        "https://nw-smartsports.verkoffice.com/appnull"
                          ? "d-none"
                          : "im-sports-type-dt mt-2"
                      }
                    />
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ: {dataSpID.active}
                    </p>
                  </div>
                  <div className="col-12 mt-4">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ประเภทการเเข่งขัน
                    </p>
                  </div>

                  <div className="col-12 ml-2">
                    {datasubArea.length > 0 ? (
                      <>
                        {datasubArea.map((data, i) => (
                          <p className="fontReg font-size03 colorGray01">
                            ประเภทการเเข่งขัน: {data.subSportName}
                          </p>
                        ))}{" "}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Mastersporttype;
