import React from 'react'

function Footer() {
  return (
    <footer className="main-footer">
      <strong>
        Copyright © 2022 <a href="https://www.adventure.co.th/home.php">Adventure.co.th</a>.
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 3.2.0
      </div>
    </footer>
  );
}

export default Footer;