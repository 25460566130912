import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import {
  DataDropdownArenas,
  DataDropdownPeriod,
} from "../dataMockup/DataMockDropdown";
import Calendarpicker from "../calendars/Calendarpicker";
import Timepicker from "../times/Timepicker";
import TableData from "../Tables/TableData";
import Button3items from "../button/Button3items";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import moment from "moment";
import Swal from "sweetalert2";
import {
  getDashboardArena,
  getDashboardArenaFilter,
} from "../../api/dashboard_api";
import { getAllArea } from "../config/configApi-Area";
import { getSubArena } from "../../api/subarena_api";
import Loading from "./Loading";
function Dasharenausage() {
  const [spanloading, setspanLoading] = useState(false);
  const enableButton = {
    view: false,
    edit: false,
    delete: false,
    add: false,
    approve: false,
  };

  const columnsname = ["id", "arenaName", "amount", "updateddate"];
  const columnsText = [
    "ลำดับที่",
    "ชื่อสนามกีฬาย่อย",
    "จำนวนผู้เข้าใช้",
    "อัปเดตล่าสุด",
  ];
  const columnsType = ["text", "text", "text", "text"];
  const rowsdata = [];
  const [inputForm, setInputForm] = useState({
    inp1: undefined, //ชื่อสนาม
    inp2: undefined, //Calendarpicker
    inp3: undefined, //Timepicker
  });
  const [dataAreaUs, setDataAreaUs] = useState([]);
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [inputDate, setInputDate] = useState("");

  function resetDropdown() {
    setInputDate("");
    inputForm.inp1 = undefined;
    inputForm.inp2 = undefined;
    inputForm.inp3 = undefined;
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    getDataDashboard();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValueDate(value) {
    setInputDate(value);
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  const getDataFilter = async () => {
    console.log("A");
    if (
      inputForm.inp1 === undefined &&
      inputForm.inp2 === undefined &&
      inputForm.inp3 === undefined
    ) {
      getDataDashboard();
    } else if (inputForm.inp3 !== undefined && inputForm.inp2 === undefined) {
      Swal.fire({
        title: "กรุณาเลือกวันที่ค้นหา",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {});
    } else {
      let bodyFilter = {
        subarena: inputForm.inp1,
        date:
          inputForm.inp2 === undefined
            ? null
            : moment(new Date(inputForm.inp2)).format("YYYY-MM-DD"),
        time: inputForm.inp3 === undefined ? null : inputForm.inp3,
      };
      let getData = [];
      setspanLoading(true);
      const response = await getDashboardArenaFilter(bodyFilter);
      await setspanLoading(false);
      let spObj = response.data.data;

      spObj.forEach((objectData, i) => {
        return getData.push({
          id: { value: i + 1, type: "text" },
          arenaName: { value: objectData.subArenaName, type: "text" },
          amount: { value: objectData.joinSubArenaAmount, type: "text" },
          updateddate: {
            value: objectData.lastCheckinDate
              ? moment(new Date(objectData.lastCheckinDate)).format(
                  "YYYY-MM-DD"
                )
              : "-",
            type: "text",
          },
        });
      });

      setDataAreaUs(getData);
    }
  };
  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ชื่อสนามกีฬาย่อย",
      "จำนวนผู้เข้าใช้",
      "อัปเดตล่าสุด",
    ];
    let Data = dataAreaUs.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.arenaName.value;
      dt[2] = elemento.amount.value;
      dt[3] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานปริมาณผู้เข้าใช้สนาม",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };
  useEffect(() => {
    getDataDashboard();
    areaDropdown();
  }, []);
  const getDataDashboard = async () => {
    console.log("B");
    setspanLoading(true);
    let getData = [];
    const respon = await getDashboardArena();

    if (respon.data.status === "success") {
      // setLoading(true);
      //  top_1: spObj.top_1,
      // top_2: spObj.top_2,
      // top_3: spObj.top_3,
      let spObj = respon.data.data;
      console.log("spObj : ", spObj);
      spObj.forEach((objectData, i) => {
        return getData.push({
          id: { value: i + 1, type: "text" },
          arenaName: {
            value: objectData.subArenaNam ? objectData.subArenaName : "-",
            type: "text",
          },
          amount: { value: objectData.joinSubArenaAmount, type: "text" },
          updateddate: {
            value: objectData.lastCheckinDate
              ? moment(new Date(objectData.lastCheckinDate)).format(
                  "YYYY-MM-DD"
                )
              : "-",

            // objectData.updatedAt === null
            //   ? moment(new Date(objectData.createdAt)).format("DD-MM-YYYY")
            //   : moment(new Date(objectData.updatedAt)).format("DD-MM-YYYY"),
            type: "text",
          },
        });
      });
    }

    await setspanLoading(false);
    setDataAreaUs(getData);
  };

  const [DataDropArenas, setDataDropArenas] = useState([]);

  const areaDropdown = async () => {
    try {
      const response = await getSubArena();
      // const response = await getAllArea();
      if (response.status == 200) {
        const dropdown = response.data.data;

        const area = dropdown.map((item) => {
          const data = {
            value: item.subArenaID,
            text: item.subArenaName,
          };
          return data;
        });

        setDataDropArenas(area);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"ปริมาณผู้เข้าใช้สนาม"} />
      <Menu parentmenu={"Dashboard"} activemenu={"arenaUsage"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-home.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ปริมาณผู้เข้าใช้สนาม
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | Dashboard / ปริมาณผู้เข้าใช้สนาม
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              {/* <Buttonexport onExportClicked={handleExport} /> */}
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-6 pr-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชื่อสนามกีฬาย่อย
                  </p>
                  <Dropdown
                    options={DataDropArenas}
                    setinput={setinput}
                    inputname={"inp1"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-2 pr-0 pr-sm-0 mt-2 mt-lg-0">
                <Calendarpicker
                  dataDate={null}
                  setDatePicker={setinput}
                  inputname={"inp2"}
                  ChangesetValueDate={ChangesetValueDate}
                  inputDate={inputDate}
                />
              </div>
              <div className="col-12 col-lg-2 pr-0 pr-sm-0 mt-2 mt-lg-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    เลือกช่วงเวลา
                  </p>
                  <Dropdown
                    options={DataDropdownPeriod}
                    setinput={setinput}
                    inputname={"inp3"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                  />
                </div>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการปริมาณผู้เข้าใช้สนาม
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataAreaUs}
              isSearchEnable={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dasharenausage;
