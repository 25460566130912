import React, { useEffect, useState, useRef } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import UploadIMG from "../uploads/UploadIMG";
import UploadIMGByID from "../uploads/UploadIMGv2";
import {
  DataDropdownAffiliation,
  DataDropdownPrefix,
  DataDropdownCoach,
  DataDropdownSportType,
  DataDropdownSexs,
} from "../dataMockup/DataMockDropdown";
import { getAffliation } from "../../api/affliation_api";
import CheckboxSubType from "../checkbox/CheckboxSubType";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UploadFileXLS from "../uploads/UploadFileXLS";
import { useLocation } from "react-router-dom";
import { getSport } from "../../api/sport_api";
import {
  getAthleteById,
  createAthlete,
  updateAthlete,
  verifyImport,
  submitImportData,
} from "../../api/athlete_api";
import ImportExcel from "../uploads/ExcelRender";
import {
  getSubSportById,
  createSubSport,
  getSubSportChk,
} from "../../api/subsport_api";
function Addeditathlete() {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const pull_checkbox_data = async (data) => {
    setSubTypes(data);
  };
  const [getDataimage, setDataImage] = useState([]);
  const addedSportTypeCount = useRef(0);
  const [addedSportType, setAddedSportType] = useState([]);
  const [subTypes, setSubTypes] = useState(DataDropdownSportType[0].subType);
  const [sportType, setDataSportType] = useState([]);
  const [affliationDropdown, setAffirmationDropdown] = useState([]);
  const [groupShow, setGroupShow] = useState(false);
  const [name, setName] = useState(null);
  const [social, setSocial] = useState(null);
  const [lastname, setlastname] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [gender, setGender] = useState("กรุณาเลือกข้อมูล");
  const [title, setTitle] = useState("กรุณาเลือกข้อมูล");
  const [img, setImages] = useState([]);
  let [checked, setChecked] = useState(false);
  const [imgByID, setImagesByID] = useState([]);
  const [tel, setTel] = useState(null);
  const [email, setEmail] = useState(null);
  const [affliation, setAffiliation] = useState(null);
  const [dropSp, setDtsport] = useState([]);
  const [dataSubSport, setDtSub] = useState([]);
  const [selectedAff, setSelectedAff] = useState(["กรุณาเลือกข้อมูล"]);
  const [getSubArr, setDataSubArr] = useState([]);

  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/athlete");
      }
    });
  }
  const getDataSport = async () => {
    const respon = await getSport();
    // let getData = [];
    // respon.data.data.forEach((item, i) => {

    //   return getData.push({
    //     value: item.sportID,
    //     text: item.sportName,
    //     subType: [],
    //   });
    // });

    // setDtsport(getData);

    // setDtsport
    const ddArr = [];
    const data = respon.data.data;
    for (let index = 0; index < data.length; index++) {
      const sport = data[index];

      const subArr = sport.subsport;
      const subtpye = [];
      for (let index = 0; index < subArr.length; index++) {
        const sub = subArr[index];
        const subarr = {
          value: sub.subSportID,
          text: sub.subSportName,
        };
        subtpye.push(subarr);
      }

      const result = {
        value: sport.sportID,
        text: sport.sportName,
        subType: subtpye,
      };
      ddArr.push(result);
    }
    // DataDropdownSportType
    setDtsport(ddArr);
    // setDtsport(DataDropdownSportType);
  };
  const handleChangeAff = (options) => {
    setSelectedAff(options);
    // setSelectedOptions(optionss);
  };
  const handleChangegender = (data) => {
    setGender(data);
    // setSelectedOptions(optionss);
  };
  const getByID = async () => {
    let getData = [];
    const response = await getAthleteById(id);
    // handleSportAddSubsp(response.data.data.sport);
    setName(response.data.data.firstname ? response.data.data.firstname : null);
    setlastname(
      response.data.data.lastname ? response.data.data.lastname : null
    );
    // setImagesByID([
    //   {
    //     id: 0,
    //     status: true,
    //     src: response.data.data.img ? response.data.data.img : null,
    //     file: null,
    //   },
    // ]);
    setImages([
      {
        id: 0,
        status: true,
        src: response.data.data.img ? response.data.data.img : null,
        file: null,
      },
    ]);
    setChecked(response.data.data.active === 1 ? true : false);
    // setImages(response.data.data.img ? response.data.data.img : null);
    setIdCard(response.data.data.idcard ? response.data.data.idcard : null);
    setGender(
      response.data.data.gender ? response.data.data.gender : "กรุณาเลือกข้อมูล"
    );
    setTel(
      response.data.data.mobileNo === "" ? "-" : response.data.data.mobileNo
    );
    setEmail(response.data.data.email === "" ? "-" : response.data.data.email);
    setSocial(
      response.data.data.registerType === ""
        ? "-"
        : response.data.data.registerType
    );
    inputForm.affliation = response.data.data.affliation
      ? response.data.data.affliation
      : "กรุณาเลือกข้อมูล";
    // inputForm.title = response.data.data.title;
    if (response.data.data.title === null) {
      setTitle("กรุณาเลือกข้อมูล");
    } else if (response.data.data.title === "เลือกข้อมูล") {
      setTitle("กรุณาเลือกข้อมูล");
    } else {
      setTitle(response.data.data.title);
    }

    handleSportAddSubsp(response.data.data.sport);

    setSelectedAff(response.data.data.affliation);
  };
  // test show handle
  function handleSportAddSubsp(data) {
    //ส่ง sport array เข้ามา
    let spArr = [];
    for (let i = 0; i < data.length; i++) {
      //loop for sport array

      spArr.push({
        sportId: data[i].sportId,
        sportType: data[i].sportName,
        sportTypeId: data[i].sportId,
        subsport: [],
        subTypesSelected: [],
      });

      for (let j = 0; j < data[i].subsportoption.length; j++) {
        //loop for supsport option array
        spArr[i].subsport.push({
          value: data[i].subsportoption[j].subsportID,
          text: data[i].subsportoption[j].subsportname,
        });
      }

      for (let k = 0; k < data[i].subsport.length; k++) {
        //loop for selectedsupsport array
        spArr[i].subTypesSelected.push({
          subsportId: data[i].subsport[k].subSportId,
        });

        let isactive = spArr[i].subsport.findIndex(
          (x) => x.value == data[i].subsport[k].subSportId
        );

        if (isactive == -1) {
          spArr[i].subsport.push({
            value: data[i].subsport[k].subSportId,
            text: data[i].subsport[k].subSportName,
          });
        }
      }
    } //end of sport loop

    setAddedSportType(spArr);
  }

  const handleSportAdd = () => {
    addedSportTypeCount.current++;
    setAddedSportType([
      ...addedSportType,
      {
        sportId: addedSportTypeCount.current,
        // sportId: "2222",
        sportType: "ทั้งหมด",
        sportTypeId: 0,
        subsport: DataDropdownSportType[0].subType,
        subTypesSelected: [],
      },
    ]);
  };
  function handleSportAddRemove(id) {
    let dataindex = addedSportType.findIndex((data) => data.sportId == id);

    if (dataindex > -1) {
      let newAddedSportType = [...addedSportType];

      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            swalWithBootstrapButtons.fire({
              title: "ลบรายการสำเร็จ!",
              icon: "success",
              confirmButtonText: "ตกลง",
            });
            if (result.isConfirmed) {
              newAddedSportType.splice(dataindex, 1);
              setAddedSportType([...newAddedSportType]);
              // //remove selectsubtype
              // let selectindex = selectedValue.findIndex(
              //   (data) => data.sportId == id
              // );
              // if (selectindex > -1) {
              //   let tempselectvalue = [...selectedValue];
              //   tempselectvalue.splice(selectindex, 1);
              //   setSelectedValue([...tempselectvalue]);
              // }
            }
          }
        });
    }
  }
  const pull_checkbox_data_added = (data, id) => {
    let dataindex = addedSportType.findIndex((data) => data.sportId == id);
    if (dataindex > -1) {
      let newAddedSportType = [...addedSportType];
      newAddedSportType[dataindex].subsport = data;
      setAddedSportType([...newAddedSportType]);
    }
  };
  const handleSelectedImagesv2 = (images) => {
    // console.log("a : ", images[0].file);
    setImagesByID(images[0]);
    setDataImage(images);
    // console.log(" ab : ", images); // do something with the selected images
  };
  const handlechange = (index, id) => {
    const newUsers = [...id];
    newUsers[index].id = "New Name";
    newUsers[index].text = "New RollNo";
    // setUsers(newUsers);
  };
  function ChangesetValuefromparentMultiple(typeid, text, id) {
    // addedSportTypeCount

    let dataindex = addedSportType.findIndex((data) => data.sportId == id);
    if (dataindex > -1) {
      let newAddedSportType = [...addedSportType];
      newAddedSportType[dataindex].sportTypeId = typeid;
      newAddedSportType[dataindex].sportType = text;
      setAddedSportType([...newAddedSportType]);
    }
  }

  const handleSelectedImages = (images) => {
    console.log("images data : ", images);
    setDataImage(images);
  };
  useEffect(() => {
    if (id) {
      getByID();
    }
    getDataAffliation();
    getDataSport();
  }, []);

  const getDataAffliation = async () => {
    let rowsdata = [];
    try {
      const respon = await getAffliation();

      respon.data.data.forEach((objectData, i) => {
        if (objectData.active === true) {
          return rowsdata.push({
            value: objectData.affliationName,
            text: objectData.affliationName,
            id: objectData.affliationID,
          });
        }
      });

      // getFilterDropdownAff(rowsdata);
      var dataArr = rowsdata.map((item) => {
        return [item.text, item];
      }); // creates array of array
      var maparr = new Map(dataArr); // create key value pair from array of array
      var result = [...maparr.values()]; //converting back to array from mapobject
      setAffirmationDropdown(result);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getFilterDropdownAff = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setAffirmationDropdown(result);
  };
  // console.log("getDataimage[0]?.file : ", getDataimage[0]?.file);
  const createMember = async () => {
    if (!id) {
      let dataTitle = DataDropdownPrefix.filter((data, i) => {
        return inputForm.title === data.value;
      });
      let dataGender = DataDropdownSexs.filter((data, i) => {
        return inputForm.gender === data.value;
      });
      let dataAff = affliationDropdown.filter((data, i) => {
        return selectedAff === data.value;
      });
      let sendDtsub = [];
      for (let i = 0; i < addedSportType.length; i++) {
        sendDtsub.push({
          sportId: addedSportType[i].sportTypeId,
          subsport: addedSportType[i].subTypesSelected,
        });
      }

      let body = {
        title: dataTitle.length > 0 ? dataTitle[0].text : null,
        firstname: name,
        lastname: lastname,
        idcard: idCard || null,
        gender: dataGender.length > 0 ? dataGender[0].text : null,
        affliation:
          selectedAff[0] !== "กรุณาเลือกข้อมูล" ? dataAff[0]?.id : null,
        email: email,
        mobileNo: tel,
        sport: sendDtsub,
        img: getDataimage.length > 0 ? getDataimage[0]?.file : null,
        active: checked === false ? 0 : 1,
      };
      console.log("body img : ", body);
      if (
        dataTitle.length < 1 ||
        name === null ||
        lastname === null ||
        inputForm.gender === null ||
        inputForm.affliation === null ||
        idCard === null ||
        dataGender.length < 1 ||
        selectedAff[0] === "กรุณาเลือกข้อมูล" ||
        // email === null ||
        // tel === null ||
        sendDtsub.length < 1 ||
        getDataimage.length < 1
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "บันทึก",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              const response = await createAthlete(body);
              // setLoading(1);
              if (response.data.status === "success") {
                Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/athlete");
                  }
                });
              }
            })();
          }
        });
      }
    } else {
      let sendDtsub = [];
      for (let i = 0; i < addedSportType.length; i++) {
        sendDtsub.push({
          sportId: addedSportType[i].sportTypeId,
          subsport: addedSportType[i].subTypesSelected,
        });
      }

      let dataTitle = [];
      let dataGender = [];
      if (inputForm.title !== null) {
        dataTitle = DataDropdownPrefix.filter((data, i) => {
          return inputForm.title === data.value;
        });
        setTitle(dataTitle[0].text);
      }

      if (inputForm.gender !== null) {
        dataGender = DataDropdownSexs.filter((data, i) => {
          return inputForm.gender === data.value;
        });
        setGender(dataGender[0].text);
      }

      let dataAff = affliationDropdown.filter((data, i) => {
        return selectedAff === data.value;
      });
      console.log("imgByID data : ", imgByID);
      console.log("getDataimage : ", getDataimage);
      let body = {
        title: inputForm.title !== null ? dataTitle[0].text : title,
        firstname: name,
        lastname: lastname,
        idCard: idCard ? idCard : null,
        gender: inputForm.gender !== null ? dataGender[0].text : gender,
        affliation: selectedAff !== null ? dataAff[0].id : null,
        email: email,
        mobileNo: tel,
        sport: sendDtsub,
        // img: getDataimage.length > 0 ? getDataimage[0].file : null,
        file: getDataimage.length > 0 ? getDataimage[0].file : null,
        // img: getDataimage.length > 0 ? getDataimage[0].file : null,
        active: checked === false ? 0 : 1,
      };
      console.log("body update preview : ", body);
      if (
        body.title === "กรุณาเลือกข้อมูล" ||
        name === null ||
        lastname === null ||
        body.gender === null ||
        body.affliation === null ||
        body.idCard === null ||
        selectedAff === null ||
        body.idCard === null ||
        sendDtsub.length < 1 ||
        imgByID.length < 1
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        Swal.fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "บันทึก",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              const response = await updateAthlete(id, body);
              // setLoading(1);
              if (response.data.status === "success") {
                Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/athlete");
                  }
                });
              }
            })();
          }
        });
      }
    }
  };
  const getDataSubSport = async (data) => {
    const respon = await getSubSportChk(data);
  };
  let data = {
    sportId: 17,
  };
  function setinput(inputname, value) {
    getDataSubSport(data);
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  function setinputSub(inputname, value) {
    getDataSubSport(data);
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    title: null,
    gender: null,
    affliation: null,
    // typeSport: null,
    // subType: 0,
    sportSub: [],
  });

  // *********************** Take Import DATA ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);
  // const [selectedValue, setSelectedValue] = useState([]);
  const handleImport = async (rows) => {
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setFailedRow([]);
    setTotalRow(rows.length - 1);

    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          title: item[0],
          firstname: item[1],
          lastname: item[2],
          idcard: item[3],
          gender: item[4],
          affliation: item[5],
          sport: item[6],
          subsport: item[7],
          mobileNo: item[8],
          email: item[9],
          registerBy: item[10],
          memberType: item[11],
          active: item[12],
        });
      }
    });
    await setImportData(row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          memberType: 1,
          importData: data,
        };
        console.log("dataToVerify : ", dataToVerify);
        const response = await verifyImport(dataToVerify);
        console.log("response data : ", response);
        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      if (error === 0) {
        // Swal.fire({
        //   title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
        //   icon: "success",
        //   confirmButtonText: "ตกลง",
        // });
        if (failedArr.length > 0) {
          Swal.fire({
            title:
              "ตรวจสอบข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง ",
            icon: "error",
            confirmButtonText: "ยืนยัน",
          });
        } else {
          Swal.fire({
            title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "success",
            confirmButtonText: "ยืนยัน",
          });
        }
        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };
  const handleDropdownChange = (value) => {
    let dtArr = dropSp;
    let dataArr = dtArr.filter((data, i) => {
      return parseInt(value) === data.value;
    });
    setDtSub(dataArr[0].subType);
  };
  const importSport = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              memberType: 1,
              importData: data,
            };
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      if (error === true) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/athlete");
          }
        });
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/athlete");
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/athlete");
        }
      });
      console.log(error);
    }
  };
  // setHandleSport
  const setHandleSport = (event) => {};
  const handleDropdownChangeSub = (id, value, typeid) => {
    let dataindex = addedSportType.findIndex((data) => data.sportId == id);

    if (dataindex > -1) {
      let tempselectvalue = [...addedSportType];
      tempselectvalue[dataindex].sportType = value;
      tempselectvalue[dataindex].sportTypeId = typeid;

      tempselectvalue[dataindex].subTypesSelected = [];
      setAddedSportType([...tempselectvalue]);
    }
  };
  function handleCheckSub(e, id, value, text) {
    let ischecked = e.target.checked;

    let dataindex = addedSportType.findIndex((data) => data.sportId == id);

    if (dataindex > -1) {
      let tempaddedSportType = [...addedSportType];
      if (ischecked) {
        //add sub
        let subindex = tempaddedSportType[dataindex].subTypesSelected.findIndex(
          (data) => data.subsportId == value
        );
        if (subindex == -1) {
          //check if already exist
          tempaddedSportType[dataindex].subTypesSelected.push({
            subsportId: value,
          });
        }
      } else {
        //delete sub
        let subindex = tempaddedSportType[dataindex].subTypesSelected.findIndex(
          (data) => data.subsportId == value
        );
        tempaddedSportType[dataindex].subTypesSelected.splice(subindex, 1);
      }

      setAddedSportType([...tempaddedSportType]);
    }
  }

  // *********************** Take Import DATA ************************************

  return (
    <>
      <Header parentmenu={"นักกีฬา"} />
      <Menu parentmenu={"athleteCoach"} activemenu={"athlete"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-coach.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                นักกีฬา
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลนักกีฬาและโค้ช / นักกีฬา
              </font>
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg">
                <div className="drpdw-status h-100">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    คำนำหน้า
                  </p>
                  <Dropdown
                    setinput={setinput}
                    inputname={"title"}
                    options={DataDropdownPrefix}
                    valuefromparent={title}
                    // onChange={(e) => {
                    //   setTitle(e.target.value);
                    // }}
                    // onChange={handleChangeTitle}
                  />
                </div>
              </div>
              <div className="col-12 col-lg mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อจริง"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value === "" ? null : e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="นามสกุล"
                  value={lastname}
                  onChange={(e) => {
                    setlastname(e.target.value === "" ? null : e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg mt-2 mt-lg-0">
                <input
                  maxLength={13}
                  className="inp-data01 fontReg font-size03"
                  placeholder="เลขบัตรประชาชน"
                  value={idCard}
                  onChange={(e) => {
                    setIdCard(e.target.value === "" ? null : e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">เพศ</p>
                  <Dropdown
                    options={DataDropdownSexs}
                    setinput={setinput}
                    inputname={"gender"}
                    // onChange={handleChangegender}
                    valuefromparent={gender}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สังกัด</p>
                  <Dropdown
                    options={affliationDropdown}
                    setinput={setinput}
                    onChange={handleChangeAff}
                    inputname={"affliation"}
                    valuefromparent={selectedAff}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <button
                  className="btn-blue06 pt-3 pb-3"
                  onClick={handleSportAdd}
                >
                  <span className="fontReg font-size03 colorWhite">
                    เพิ่มชนิดกีฬา
                  </span>
                </button>
              </div>
            </div>
            <div className="add-sportType mt-2">
              <div className="row">
                <div className="col-6 col-lg-3 mt-2 mt-lg-0"></div>
              </div>
              {addedSportType?.map((Type, index) => {
                return (
                  <>
                    <div key={Type.sportId}>
                      <div className="row">
                        <div className="col-6 col-lg-3 mt-2 mt-lg-0">
                          <div className="drpdw-status">
                            <p className="fontReg font-size03 colorGray02 mb-1">
                              ชนิดกีฬา
                            </p>
                            <Dropdown
                              isSportType={true}
                              onChange={setHandleSport}
                              handleDropdownChangeSub={handleDropdownChangeSub}
                              options={dropSp}
                              id={Type.sportId}
                              ChangesetValuefromparentMultiple={
                                ChangesetValuefromparentMultiple
                              }
                              valuefromparent={Type.sportType}
                              pull_checkbox_data_added={
                                pull_checkbox_data_added
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6 col-lg-3 mt-2 mt-lg-0">
                          <button
                            className="btn-red01 pt-3 pb-3"
                            onClick={() => handleSportAddRemove(Type.sportId)}
                          >
                            <span className="fontReg font-size03 colorWhite">
                              ลบชนิดกีฬา
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="raceType justify-content-start mt-2">
                        <div className="col-12 mt-2">
                          <p className="fontReg font-weight-bold font-size03 colorGray01">
                            ประเภทการแข่งขัน ( เลือกได้มากกว่า 1 ประเภท )
                          </p>
                        </div>
                        <div className="row ml-1">
                          {Type.subsport?.map((subType, index) => (
                            <div key={index} className="col-6 col-lg-3">
                              {/* <CheckboxSubType
                                handleCheckSub={handleCheckSub}
                                id={Type.id}
                                text={subType.text}
                                value={subType.value}
                              /> */}
                              <label className="checkbox-item01 fontReg font-size03 colorGray01">
                                {subType.text}
                                <input
                                  type="checkbox"
                                  value={subType.value}
                                  id={Type.sportId}
                                  checked={
                                    Type.subTypesSelected.findIndex(
                                      (x) => x.subsportId == subType.value
                                    ) > -1
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleCheckSub(
                                      e,
                                      Type.sportId,
                                      subType.value,
                                      subType.text
                                    )
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="row justify-content-start mt-2 mt-lg-5">
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-5 mt-lg-0">
                {id ? (
                  <UploadIMGByID
                    limit={1}
                    onSelectedImages={handleSelectedImagesv2}
                    image={img}
                  />
                ) : (
                  <UploadIMG
                    limit={1}
                    onSelectedImages={handleSelectedImages}
                    image={img}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  ข้อมูลการสมัครผ่านแอปพลิเคชัน
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <input
                  disabled
                  className="inp-data01 fontReg font-size03"
                  placeholder="เบอร์โทร"
                  value={tel}
                  onChange={(e) => {
                    setTel(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <input
                  disabled
                  className="inp-data01 fontReg font-size03"
                  placeholder="อีเมล"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <input
                  value={social}
                  disabled
                  className="inp-data01 fontReg font-size03"
                  placeholder="สมัครผ่าน"
                />
              </div>
            </div>
            <div className="row ml-0 mt-3">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  // defaultChecked={checked}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <span className="checkmark" />
              </label>
            </div>
            {/* <div className="row ml-0 mt-3">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
            </div> */}
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save" onClick={createMember}>
                  บันทึก
                </button>
              </div>
            </div>
          </div>

          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/008-athlete_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>

                {/* {verifySkip > 0 ? (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    แก้ไข ข้อมูลเดิม {verifySkip} รายการ
                  </p>
                ) : (
                  <></>
                )}
                {verifyNew > 0 ? (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    เพิ่มข้อมูลใหม่ {verifyNew} รายการ
                  </p>
                ) : (
                  <></>
                )} */}

                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  ข้อมูลทั้งหมด {verifySuccess}/{totalRow} รายการ
                </p>

                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length > 0 ? false : true}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditathlete;
