import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import { async } from "q";

const dataToken = localStorage.getItem("token");
// const dataToken = sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const getDataTheam = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get("/appconfig", {
      headers: headers,
      params: query,
    });
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const CreateTheme = async (formData) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data;",
      Authorization: dataToken,
    };
    // buttonColor: colorBut,
    // appBackgroundColor: colorBack,
    // profileBackgroundColor: colorPro,
    // mainmenuBackgroundColor: colorMenu,
    // menubarColor: colorBarMenu,
    // splashscreenColor: colorText,
    // appLogo: imgLogo[0].file,
    // appBanner: imgMain[0].file,
    const fm = new FormData();
    fm.append("buttonColor", formData.buttonColor);
    fm.append("appBackgroundColor", formData.appBackgroundColor);
    fm.append("profileBackgroundColor", formData.profileBackgroundColor);
    fm.append("mainmenuBackgroundColor", formData.mainmenuBackgroundColor);
    fm.append("menubarColor", formData.menubarColor);
    fm.append("splashscreenColor", formData.splashscreenColor);
    fm.append("appLogo", formData.appLogo);
    fm.append("appBanner", formData.appBanner);

    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.put(`/appconfig/theme`, fm, {
      headers: headers,
    });
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
