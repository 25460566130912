import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import TableData from "../Tables/TableData";
import Calendarpicker from "../calendars/Calendarpicker";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import moment from "moment";
import Button3items from "../button/Button3items";
import { useState, useEffect } from "react";
import Loading from "../pages/Loading";
import {
  getSleeppackBorrow,
  getSleeppackBorrowFilter,
} from "../../api/sleeppack_api";
import { getAffliation } from "../../api/affliation_api";

function Borrowtablebedding() {
  const enableButton = { view: true, edit: true, delete: true };
  const columnsname = [
    "id",
    "affliation",
    "detailBorrow",
    "type",
    "detailPer",
    "typeBed",
    "Count",
    "dateBorrow",
  ];
  const columnsText = [
    "ลำดับที่",
    "สังกัด",
    "รายละเอียดผู้ยืม-คืน",
    "ประเภทรายการ",
    "รายละเอียดผู้ให้ยืม-คืน",
    "ชนิดเครื่องนอน",
    "จำนวนที่ยืม-คืน",
    "วันที่ยืม-คืน",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
  ];

  const [DataBusbooking, setDataBusbooking] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const rowsData = [
    {
      id: { value: "1", type: "text" },
      detailBorrow: { value: "กฤษณา กล้าจริง", type: "text" },
      type: { value: "ยืม", type: "text" },
      detailPer: { value: "นัทกาปิ", type: "text" },
      typeBed: { value: "หมอน", type: "text" },
      Count: { value: "40", type: "text" },
      dateBorrow: { value: "16/02/2544", type: "text" },
    },
    {
      id: { value: "2", type: "text" },
      detailBorrow: { value: "กฤษณา กล้าจริง", type: "text" },
      type: { value: "ยืม", type: "text" },
      detailPer: { value: "นัทกาปิ", type: "text" },
      typeBed: { value: "หมอน", type: "text" },
      Count: { value: "40", type: "text" },
      dateBorrow: { value: "16/02/2544", type: "text" },
    },
    {
      id: { value: "3", type: "text" },
      detailBorrow: { value: "กฤษณา กล้าจริง", type: "text" },
      type: { value: "ยืม", type: "text" },
      detailPer: { value: "นัทกาปิ", type: "text" },
      typeBed: { value: "หมอน", type: "text" },
      Count: { value: "40", type: "text" },
      dateBorrow: { value: "16/02/2544", type: "text" },
    },
    {
      id: { value: "4", type: "text" },
      detailBorrow: { value: "กฤษณา กล้าจริง", type: "text" },
      type: { value: "ยืม", type: "text" },
      detailPer: { value: "นัทกาปิ", type: "text" },
      typeBed: { value: "หมอน", type: "text" },
      Count: { value: "40", type: "text" },
      dateBorrow: { value: "16/02/2544", type: "text" },
    },
  ];

  const DataDropdownBedding = [
    { value: "", text: "ทั้งหมด" },
    { value: "borrow", text: "ยืม" },
    { value: "", text: "คืน" },
  ];

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    inp1: undefined, //ชื่อสนาม
    inp2: undefined, //Calendarpicker
    inp3: undefined, //Timepicker
    inp4: undefined,
  });

  const [name, setName] = useState("");
  const [bedding, setbedding] = useState("");
  const [inputDate, setInputDate] = useState("");
  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");

  function resetDropdown() {
    setName("");
    setbedding("");
    setInputDate("");
    inputForm.inp1 = undefined;
    inputForm.inp2 = undefined;
    inputForm.inp3 = undefined;
    inputForm.inp4 = undefined;
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setInputForm(inputForm.inp1);
    setInputForm(inputForm.inp2);
    setInputForm(inputForm.inp3);
    setInputForm(inputForm.inp4);
    getlist();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };

  const onChangeInputBedding = (e) => {
    if (e) {
      setinput("inp3", e.target.value);
      setbedding(e.target.value);
    }
  };

  function ChangesetValueDate(value) {
    setInputDate(value);
  }

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "รายละเอียดผู้ยืม-คืน",
      "ประเภทรายการ",
      "รายละเอียดผู้ให้ยืม-คืน",
      "ชนิดเครื่องนอน",
      "จำนวนที่ยืม-คืน",
      "วันเวลาที่ยืม-คืน",
    ];
    let Data = DataBusbooking.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.detailBorrow.value;
      dt[2] = elemento.type.value;
      dt[3] = elemento.detailPer.value;
      dt[4] = elemento.typeBed.value;
      dt[5] = elemento.Count.value;
      dt[6] = elemento.dateBorrow.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานข้อมูลการยืม-คืน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
 
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };
  const getlist = async () => {
    setLoading(true);
    try {
      
      const response = await getSleeppackBorrow();
      if (response) {
        const busbooking = response.data.data;
        const results = busbooking.map((row, i) => {
          const rowData = {
            id: { value: i + 1, dataID: row?.busBookingID, type: "text" },
            affliation: { value: row?.affliation, type: "text" },
            detailBorrow: { value: row?.member, type: "text" },
            type: { value: row?.borrowType, type: "text" },
            detailPer: { value: row?.staff, type: "text" },
            typeBed: { value: row?.borrowDetail, type: "text" },
            Count: { value: row?.totalAmount, type: "text" },
            // row.updatedAt !== null
            // ? moment(new Date(row.updatedAt)).format("YYYY-MM-DD")
            // : moment(new Date(row.createdAt)).format("YYYY-MM-DD"),
            dateBorrow: {
              value:
                moment(new Date(row?.createdAt)).format("YYYY-MM-DD") ||
                moment(new Date(row?.updatedAt)).format("YYYY-MM-DD"),
              type: "text",
            },

            //
            //
            // id: { value: i + 1, dataID: row?.busBookingID, type: "text" },
            // coaches: { value: row?.coaches, type: "text" },
            // bookingType: { value: row?.bookingType, type: "text" },
            // staff: { value: row?.staff, type: "text" },
            // busType: { value: row?.busType, type: "text" },
            // bookingChannel: { value: row?.bookingChannel, type: "text" },

            // bookingName: { value: row?.bookingName, type: "text" },
            // bookingStatus: {
            //   value: row?.bookingStatus == true ? "ปกติ" : "ยกเลิก",
            //   type: "text",
            // },
            // staff: { value: row?.staff, type: "text" },
            // action: { value: "1", type: "actions" },
          };
          return rowData;
        });
        await setLoading(false);
        setDataBusbooking(results);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getlistFilter = async () => {
    setLoading(true);
    try {
      const query = {
        affliationId: selectedValue,
        type: valuefromparent1,
        bedding: bedding,
        inputDate:
          inputDate !== ""
            ? moment(new Date(inputDate)).format("YYYY-MM-DD")
            : null,
      };
 
      const response = await getSleeppackBorrowFilter(query);
      if (response) {
        const busbooking = response.data.data;
        const results = busbooking.map((row, i) => {
          const rowData = {
            id: { value: i + 1, dataID: row?.busBookingID, type: "text" },
            affliation: { value: row?.affliation, type: "text" },
            detailBorrow: { value: row?.member, type: "text" },
            type: { value: row?.borrowType, type: "text" },
            detailPer: { value: row?.staff, type: "text" },
            typeBed: { value: row?.borrowDetail, type: "text" },
            Count: { value: row?.totalAmount, type: "text" },
            // row.updatedAt !== null
            // ? moment(new Date(row.updatedAt)).format("YYYY-MM-DD")
            // : moment(new Date(row.createdAt)).format("YYYY-MM-DD"),
            dateBorrow: {
              value:
                moment(new Date(row?.createdAt)).format("YYYY-MM-DD") ||
                moment(new Date(row?.updatedAt)).format("YYYY-MM-DD"),
              type: "text",
            },

            //
            //
            // id: { value: i + 1, dataID: row?.busBookingID, type: "text" },
            // coaches: { value: row?.coaches, type: "text" },
            // bookingType: { value: row?.bookingType, type: "text" },
            // staff: { value: row?.staff, type: "text" },
            // busType: { value: row?.busType, type: "text" },
            // bookingChannel: { value: row?.bookingChannel, type: "text" },

            // bookingName: { value: row?.bookingName, type: "text" },
            // bookingStatus: {
            //   value: row?.bookingStatus == true ? "ปกติ" : "ยกเลิก",
            //   type: "text",
            // },
            // staff: { value: row?.staff, type: "text" },
            // action: { value: "1", type: "actions" },
          };
          return rowData;
        });
        await setLoading(false);
        setDataBusbooking(results);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getDataAffliation = async () => {
    let affType = [];
    const data2 = await getAffliation();
  
    data2.data.data.forEach((element, i) => {
   
      return affType.push({
        value: element.affliationID,
        idAff: element.affliationID,
        text: element.affliationName,
      });
    });
    getFilterDropdownAff(affType);
  };
  const [affliationDropdown, setAffirmationDropdown] = useState([]);
  const [affliation, setAffiliation] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const getFilterDropdownAff = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setAffirmationDropdown(result);
  };

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    getlist();
    getDataAffliation();

  }, []);
  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ข้อมูลการยืม-คืน"} />
      <Menu parentmenu={"borrowReturn"} activemenu={"brTableBedding"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-borrow.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ยืม-คืน สำหรับนักกีฬา / โค้ช
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ยืม-คืน สำหรับนักกีฬา / โค้ช / ข้อมูลการยืม-คืน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              {/* <Button /> */}
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สังกัด</p>
                  <Dropdown
                    options={affliationDropdown}
                    setinput={setinput}
                    inputname={"aff"}
                    onChange={handleDropdownChange}
                    id="affiliation"
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-3 col-xl-2 pr-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">ประเภท</p>
                  <Dropdown
                    options={DataDropdownBedding}
                    setinput={setinput}
                    inputname={"inp2"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start mt-3">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชนิดเครื่องนอน"
                  value={bedding}
                  onChange={onChangeInputBedding}
                />
              </div>
              <div className="col-12 col-lg-3 pr-0 pr-sm-0 mt-2 mt-lg-0">
                <Calendarpicker
                  dataDate={null}
                  setDatePicker={setinput}
                  inputname={"inp4"}
                  ChangesetValueDate={ChangesetValueDate}
                  inputDate={inputDate}
                />
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-blue01" onClick={getlistFilter}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการยืมคืน
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={DataBusbooking}
              isSearchEnable={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Borrowtablebedding;
