import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import moment from "moment";
import Button3items from "../button/Button3items";
// import { DataDropdownUsersType } from '../dataMockup/DataMockDropdown'
import {
  getDashboardAppuse,
  getDashAppUserFilter,
} from "../../api/dashboard_api";
import TableData from "../Tables/TableData";
import Loading from "./Loading";

function Dashappuser() {
  const [spanloading, setspanLoading] = useState(false);
  const [dataDrop, setDataDrop] = useState([]);
  const enableButton = {
    view: false,
    edit: false,
    delete: false,
    add: false,
    approve: false,
  };

  const columnsname = ["id", "usersType", "amount", "updateddate"];
  const columnsText = ["ลำดับที่", "รายการผู้ใช้", "จำนวน", "อัปเดตล่าสุด"];
  const columnsType = ["text", "text", "text", "text"];
  const rowsdata = [
    // {
    //   id: { value: "1", type: "text" },
    //   usersType: { value: "ต่ำกว่า 18 ปี", type: "text" },
    //   amount: { value: "5000", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    // },
    // {
    //   id: { value: "2", type: "text" },
    //   usersType: { value: "อายุ 18-25 ปี", type: "text" },
    //   amount: { value: "4500", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    // },
    // {
    //   id: { value: "3", type: "text" },
    //   usersType: { value: "มากกว่า 45  ปี", type: "text" },
    //   amount: { value: "4000", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    // },
    // {
    //   id: { value: "4", type: "text" },
    //   usersType: { value: "นักกีฬา", type: "text" },
    //   amount: { value: "3500", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    // },
    // {
    //   id: { value: "5", type: "text" },
    //   usersType: { value: "บุคคลทั่วไป", type: "text" },
    //   amount: { value: "3000", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    // },
  ];

  const DataDropdown = [
    { value: 0, text: "ทั้งหมด" },
    { value: 1, text: "ช่วงอายุ" },
    { value: 2, text: "ไม่ใช้งาน" },
  ];
  const [dataUser, setDataUser] = useState([]);

  const [inputForm, setInputForm] = useState({
    inp1: undefined,
  });
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");

  function resetDropdown() {
    inputForm.inp1 = undefined;
    setValuefromparent1("ทั้งหมด");
    // setInputForm(inputForm);
    getDataDashboard();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const handleExport = async (type) => {
    let Header = ["ลำดับที่", "รายการผู้ใช้", "จำนวน", "อัปเดตล่าสุด"];

    let Data = dataUser.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.usersType.value;
      dt[2] = elemento.amount.value;
      dt[3] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานจำนวนผู้ใช้แอปพลิเคชัน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };
  useEffect(() => {
    getDataDashboard();
  }, []);

  const getDataDashboard = async () => {
    setspanLoading(true);
    let respon;
    respon = await getDashboardAppuse();
    //

    respon.data.data.forEach((objectData, i) => {
      return rowsdata.push({
        id: { value: i + 1, type: "text" },
        usersType: { value: objectData.ageName, type: "text" },
        amount: { value: objectData.ageAmount, type: "text" },
        updateddate: {
          value: moment(new Date()).format("YYYY-MM-DD") || "-",
          type: "text",
        },
      });
    });
    await setspanLoading(false);
    setDataUser(rowsdata);
    let dtDrop = [{ value: "", text: "ทั้งหมด" }];
    const subTypeDropdown = respon.data.data.map((item) => {
      dtDrop = {
        value: item.ageID,
        text: item.ageName,
      };
      return dtDrop;
    });

    subTypeDropdown.push({ value: "", text: "ทั้งหมด" });
    setDataDrop(subTypeDropdown);
    // setDataDropSporttype(subType);
  };
  const getDataFilter = async () => {
    if (inputForm.inp1 === undefined) {
      getDataDashboard();
    } else {
      let bodyFilter = {
        ageId: inputForm.inp1 === undefined ? null : inputForm.inp1,
      };
      setspanLoading(true);
      const response = await getDashAppUserFilter(bodyFilter);
      await setspanLoading(false);
      response.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text" },
          usersType: { value: objectData.ageName, type: "text" },
          amount: { value: objectData.ageAmount, type: "text" },
          updateddate: {
            value:
              // moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD") ||
              moment(new Date()).format("YYYY-MM-DD") || "-",
            type: "text",
          },
        });
      });
      await setspanLoading(false);
      setDataUser(rowsdata);
    }
  };
  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"จำนวนผู้ใช้แอปพลิเคชัน"} />
      <Menu parentmenu={"Dashboard"} activemenu={"appUsers"} />
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-home.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                จำนวนผู้ใช้แอปพลิเคชัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | Dashboard / จำนวนผู้ใช้แอปพลิเคชัน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              {/* <Buttonexport onExportClicked={handleExport} /> */}
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-sm-6 pr-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    รายการผู้ใช้
                  </p>
                  {/* <p className="fontReg font-size03 colorGray02 mb-1">
                    รายการผู้ใช้
                  </p> */}
                  <Dropdown
                    options={dataDrop}
                    setinput={setinput}
                    inputname={"inp1"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการผู้ใช้แอปพลิเคชัน
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataUser}
              isSearchEnable={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashappuser;
