import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
// const dataToken = sessionStorage.getItem("token");
const dataToken = localStorage.getItem("token");
const URL = process.env;

// const instace = axios.create({
//    baseURL: process.env.REACT_APP_BASE_URL,
// });
const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const login = async (data) => {
  try {
    const response = await instace.post(`/auth/login`, data);

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
