import * as React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../CSS/Addedit.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../dropdowns/Dropdown";
import Swal from "sweetalert2";
import moment from "moment";
import {
  CreateBusbooking,
  getBusbookingByID,
  UpdateBusbooking,
} from "../../api/busbooking_api";
import Loading from "../pages/Loading";
function Addeditbbusbooking() {
  let id = new URLSearchParams(window.location.search).get("id");
  const [memberType, setMemberType] = useState("กรุณาเลือก");
  const [isLoading, setLoading] = useState(false);
  const [bookingChannel, setBookingChannel] = useState(null);
  const [bookingType, setBookingType] = useState(0);
  const [name, setName] = useState("");
  const [cardNum, setCardNum] = useState("");
  const [trip, settrip] = useState("");
  const [startGo, setStartGo] = useState("");
  const [startBack, setStartBack] = useState("");
  const [stopGo, setStopGo] = useState("");
  const [stopBack, setStopBack] = useState("");
  const [dateStartGo, setDateStartGo] = useState("");
  const [dateStartBack, setDateStartBack] = useState("");
  const [timestartGO, setTimeStartGo] = useState("");
  const [timestartBack, setTimeStartBack] = useState("");
  const [nameDriver, setNameDriver] = useState("");
  const [callDriver, setCallDriver] = useState("");
  const [carNum, setCarNum] = useState("");
  const [carColor, setcarColor] = useState("");
  const [typeCar, setTypeCar] = useState("");
  const [bookingTypeinfo, setBookingTypeinfo] = useState(0);
  const [tripinfo, settripinfo] = useState(0);
  const [DataBusbooking, setDataBusbooking] = useState([]);

  const DataDropdownMemberType = [
    { value: 0, text: "โค้ช" },
    { value: 1, text: "กรรมการ" },
    { value: 2, text: "อื่น ๆ" },
  ];
  const DataDropdownBookingChanel = [
    { value: 0, text: "แอปพลิเคชัน" },
    { value: 1, text: "Hotline" },
    { value: 2, text: "LINE" },
    { value: 3, text: "ศูนย์ประสานงานกลาง" },
  ];
  const [inputForm, setInputForm] = useState({
    memberType: 0,
    bookingChannel: 0,
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const handlebookingChannel = (value) => {
    setBookingChannel(value);
  };
  const navigate = useNavigate();

  const bookTypeset = (e) => {
    // setBookingTypeinfo(!bookingTypeinfo);
    if (bookingTypeinfo == true) {
      setBookingType(1);
      setBookingTypeinfo(1);
    } else {
      setBookingTypeinfo(0);
      setBookingType(0);
    }
  };

  const tripSet = () => {
    // settripinfo(!tripinfo);
    if (tripinfo == true) {
      settripinfo(1);
      settrip(1);
    } else {
      settripinfo(0);
      settrip(0);
    }
  };

  const handleBookingTypeinfo = (e, val) => {
    const booktypeVal = val;

    setBookingTypeinfo(booktypeVal);
  };

  const handleTripChange = (e, val) => {
    if (e.target == null) {
    }

    const tripVal = val;
    if (tripVal === 0) {
      setTimeStartBack((prev) => ``);
      setDateStartBack((prev) => ``);
      setStartBack(``);
      setStopBack(``);

      // setTimeStartBack(null);
      // setDateStartBack(null);
    }
    settripinfo(tripVal);
  };

  const getBusbookingID = async (id) => {
    let getData = [];
    // let id = new URLSearchParams(window.location.search).get("id");
    const response = await getBusbookingByID(id);

    const parseDateStart = moment(response.data.data.timeStart).format(
      "YYYY-MM-DD"
    );
    const parsetimeStart = moment(response.data.data.timeStart).format(
      "HH:mm:ss"
    );

    const parseDateReturn = moment(response.data.data.timeReturn).format(
      "YYYY-MM-DD"
    );
    const parsetimeReturn = moment(response.data.data.timeReturn).format(
      "HH:mm:ss"
    );
    getData.push({
      busBookingID: response.data.data.busBookingID,
      memberType: response.data.data.memberType,
      bookingChannel: response.data.data.bookingChannel,
      bookingName: response.data.data.bookingName,
      idCard: response.data.data.idCard,
      bookingType: response.data.data.bookingType === "รถรับส่ง" ? 0 : 1,
      trip: response.data.data.trip === "เที่ยวเดียว" ? 0 : 1,
      driverName: response.data.data.driverName,
      driverMobileNo: response.data.data.driverMobileNo,
      licensePlate: response.data.data.licensePlate,
      busColor: response.data.data.busColor,
      busType: response.data.data.busType,
      originStart: response.data.data.originStart,
      originReturn: response.data.data.originReturn,
      dateStart: parseDateStart,
      dateReturn: parseDateReturn,
      timeStart: parsetimeStart,
      timeReturn: parsetimeReturn,
      destinationStart: response.data.data.destinationStart,
      destinationReturn: response.data.data.destinationReturn,
    });

    setDataBusbooking(getData[0]);
    setMemberType(getData[0].memberType);
    setBookingChannel(getData[0].bookingChannel);
    setName(getData[0].bookingName);
    setCardNum(getData[0].idCard);
    setBookingType(getData[0].bookingType);
    setBookingTypeinfo(getData[0].bookingType);
    settrip(getData[0].trip);
    setStartGo(getData[0].originStart);
    setStartBack(getData[0].originReturn);
    setCarNum(getData[0].licensePlate);
    setNameDriver(getData[0].driverName);
    setCallDriver(getData[0].driverMobileNo);
    setcarColor(getData[0].busColor);
    setTypeCar(getData[0].busType);
    setDateStartGo(getData[0].dateStart);
    setDateStartBack(getData[0].dateReturn);
    setTimeStartGo(getData[0].timeStart);
    setTimeStartBack(getData[0].timeReturn);
    setStopGo(getData[0].destinationStart);
    setStopBack(getData[0].destinationReturn);
    settripinfo(getData[0].trip);

    const parseMembertype =
      getData[0].memberType == `โค้ช`
        ? 0
        : getData[0].memberType == `กรรมการ`
        ? 1
        : getData[0].memberType == `อื่นๆ`
        ? 2
        : 0;
    setinput(`memberType`, parseMembertype);

    if (response.data.data.bookingType == 0) {
      document.getElementById("bookingTypeA").checked = true;
    } else {
      document.getElementById("bookingTypeB").checked = true;
    }

    if (response.data.data.trip == 0) {
      document.getElementById("triptourA").checked = true;
    } else {
      document.getElementById("triptourB").checked = true;
    }
  };

  useEffect(() => {
    if (id) {
      getBusbookingID(id);
    }
  }, []);

  const handleChangeBookingname = async (e) => {
    setName(null);
    if (e) {
      setName(e.target.value);
    }
  };
  const handleChangeCardname = async (e) => {
    setCardNum(null);
    if (e) {
      setCardNum(e.target.value);
    }
  };

  const handleChangeStartGo = async (e) => {
    setStartGo(null);
    if (e) {
      setStartGo(e.target.value);
    }
  };
  const handleChangeStartBack = async (e) => {
    setStartBack(null);
    if (e) {
      setStartBack(e.target.value);
    }
  };
  const handleChangeCarNum = async (e) => {
    setCarNum(null);
    if (e) {
      setCarNum(e.target.value);
    }
  };
  const handleChangeNameDriver = async (e) => {
    setNameDriver(null);
    if (e) {
      setNameDriver(e.target.value);
    }
  };
  const handleChangeCallDriver = async (e) => {
    setCallDriver(null);
    if (e) {
      setCallDriver(e.target.value);
    }
  };
  const handleChangeCarColor = async (e) => {
    setcarColor(null);
    if (e) {
      setcarColor(e.target.value);
    }
  };
  const handleChangeTypeCar = async (e) => {
    setTypeCar(null);
    if (e) {
      setTypeCar(e.target.value);
    }
  };
  const handleChangeDateStartGo = async (e) => {
    setDateStartGo(null);
    if (e) {
      const parseDate = moment(e.target.value).format(`YYYY-MM-DD`);
      setDateStartGo(parseDate);
    }
  };
  const handleChangeTimeStartGo = async (e) => {
    setTimeStartGo(null);
    if (e) {
      // const parseTime = moment(e.target.value).format(`YYYY-MM-DD HH:mm:ss`)

      setTimeStartGo(e.target.value);
    }
  };
  const handleChangeDateStartBack = async (e) => {
    setDateStartBack(null);
    if (e) {
      const parseDate = moment(e.target.value).format(`YYYY-MM-DD`);
      setDateStartBack(parseDate);
    }
  };
  const handleChangeTimeStartBack = async (e) => {
    setTimeStartBack(null);
    if (e) {
      // const parseTime = moment(e.target.value).format(`YYYY-MM-DD HH:mm:ss`)

      setTimeStartBack(e.target.value);
    }
  };
  const handleChangeStopGo = async (e) => {
    setStopGo(null);
    if (e) {
      setStopGo(e.target.value);
    }
  };
  const handleChangeStopBack = async (e) => {
    setStopBack(null);
    if (e) {
      setStopBack(e.target.value);
    }
  };

  const postBusedit = async () => {
    const swalWithBootstrapButtons = Swal.mixin();
    // console.log("inputForm.memberType : ", inputForm.memberType);
    if (
      (bookingType == null || !name || name === "") &&
      inputForm.memberType != 0
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else if (
      inputForm.memberType == 0 &&
      (bookingType == null || !name || !cardNum)
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      const body = {
        memberType: inputForm.memberType,

        // memberType === "โค้ช" ? 0 : memberType === "กรรมการ" ? 1 : 2,

        // memberType: memberType === "อื่นๆ" ? 2 : inputForm.memberType,
        bookingChannel: inputForm.bookingChannel,
        bookingName: name,
        idCard: cardNum,
        bookingType: bookingTypeinfo,
        trip: tripinfo,
        driverName: nameDriver,
        driverMobileNo: callDriver,
        licensePlate: carNum,
        busColor: carColor,
        busType: typeCar,
        originStart: startGo,
        dateStart: dateStartGo,
        timeStart: timestartGO,
        destinationStart: stopGo,
      };
      console.log("body : ", body);
      if (
        name === "" ||
        cardNum === "" ||
        bookingTypeinfo === "" ||
        tripinfo === "" ||
        nameDriver === "" ||
        callDriver === "" ||
        carNum === "" ||
        carColor === "" ||
        typeCar === "" ||
        startGo === "" ||
        dateStartGo === "" ||
        timestartGO === "" ||
        stopGo === ""
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        if (!id) {
          if (tripinfo === 1) {
            body[`originReturn`] = startBack;
            body[`dateReturn`] = dateStartBack;
            body[`timeReturn`] = timestartBack;
            body[`destinationReturn`] = stopBack;
          }
          Swal.fire({
            title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "บันทึก",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              (async () => {
                setLoading(true);
                const response = await CreateBusbooking(body);
                setLoading(false);

                if (response && response.data.status === "success") {
                  swalWithBootstrapButtons
                    .fire({
                      title: "บันทึกรายการสำเร็จ",
                      icon: "success",
                      showCancelButton: false,
                      confirmButtonText: "ยืนยัน",
                      // cancelButtonText: 'ยกเลิก',
                      reverseButtons: true,
                    })
                    .then(async (result) => {
                      if (result.isConfirmed && response.data.noti == true) {
                        swalWithBootstrapButtons
                          .fire({
                            title: "ส่งการแจ้งเตือนไปยังผู้จองสำเร็จ!",
                            icon: "success",
                            confirmButtonText: "ตกลง",
                          })
                          .then((result) => {
                            navigate("/shuttlebusBooking");
                          });
                      } else {
                        navigate("/shuttlebusBooking");
                      }
                    });
                } else {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: `ยกเลิก`,
                    reverseButtons: true,
                  }).then((result) => {
                    // navigate("/shuttlebusBooking")
                    console.log("error");
                  });
                }
              })();
            }
          });
          // const response = await CreateBusbooking(body);
        } else {
          if (tripinfo === 1) {
            body[`originReturn`] = startBack;
            body[`dateReturn`] = dateStartBack;
            body[`timeReturn`] = timestartBack;
            body[`destinationReturn`] = stopBack;
          } else {
            body[`originReturn`] = ``;
            body[`dateReturn`] = ``;
            body[`timeReturn`] = ``;
            body[`destinationReturn`] = ``;
          }
          Swal.fire({
            title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "บันทึก",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              (async () => {
                setLoading(true);
                const response = await UpdateBusbooking(id, body);
                //
                setLoading(false);
                if (response && response.data.status === "success") {
                  swalWithBootstrapButtons
                    .fire({
                      title: "แก้ไขรายการสำเร็จ",
                      icon: "success",
                      showCancelButton: true,
                      confirmButtonText: "ยืนยัน",
                      cancelButtonText: "ยกเลิก",
                      reverseButtons: true,
                    })
                    .then(async (result) => {
                      if (result.isConfirmed && response.data.noti == true) {
                        swalWithBootstrapButtons.fire({
                          title: "ส่งการแจ้งเตือนไปยังผู้จองสำเร็จ",
                          icon: "success",
                          confirmButtonText: "ตกลง",
                        });
                      } else {
                        navigate("/shuttlebusBooking");
                      }
                    });
                } else {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: `ยกเลิก`,
                    reverseButtons: true,
                  }).then((result) => {
                    // navigate("/shuttlebusBooking")
                    console.log("error");
                  });
                }
              })();
            }
          });
        }
      }
    }
  };

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/shuttlebusBooking");
      }
    });
  }

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ข้อมูลการจองรถรับ-ส่ง"} />
      <Menu parentmenu={"shuttlebusData"} activemenu={"shuttlebusBooking"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-schedule.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ข้อมูลการจองรถรับ-ส่ง
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการจองรถรับ-ส่ง / ข้อมูลการจองรถรับ-ส่ง
              </font>
            </div>
          </div>
          <Col className="card-white">
            <Row className="mx-1">
              <Col xs={12} md={12} lg={12}>
                {!id ? (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01 px-1">
                      {" "}
                      เพิ่มข้อมูลการจองรถรับ-ส่ง
                    </span>
                  </p>
                ) : (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01 px-1">
                      {" "}
                      แก้ไขข้อมูลการจองรถรับ-ส่ง
                    </span>
                  </p>
                )}
              </Col>
            </Row>
            <Row className=" mt-1">
              <Col x2={2} lg={2} md={1}></Col>
              <Col xs={4} md={5} lg={4} className="drpdw-status">
                <p className="fontReg font-size03 colorGray02 mb-1">
                  ประเภทผู้จอง
                </p>
                <Dropdown
                  setinput={setinput}
                  inputname={"memberType"}
                  valuefromparent={memberType}
                  options={DataDropdownMemberType}
                  id="memberType"
                />
              </Col>
              <Col xs={2} lg={2} md={1}></Col>
              <Col xs={4} md={5} lg={4} className="drpdw-status">
                <p className="fontReg font-size03 colorGray02 mb-1">
                  ช่องทางการจอง
                </p>
                <Dropdown
                  setinput={setinput}
                  inputname={"bookingChannel"}
                  options={DataDropdownBookingChanel}
                  valuefromparent={bookingChannel}
                  id="bookingChannel"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={3} className="text-header text-center">
                ชื่อผู้จอง
              </Col>
              <Col lg={4} md={4} xs={3}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeBookingname}
                  value={name}
                />
              </Col>
              <Col lg={2} md={3} xs={3} className="text-header text-center">
                เลขบัตรประชาชน
              </Col>
              <Col lg={4} md={3} xs={3}>
                <input
                  maxLength={13}
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeCardname}
                  value={cardNum}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={4} className="text-chekbox text-center">
                ประเภทการจอง
              </Col>
              <Col lg={2} md={2} xs={4} className="text-format">
                <label className="checkbox-item01 ">
                  รถรับส่ง
                  <input
                    type="radio"
                    name="option1"
                    onClick={bookTypeset}
                    id="bookingTypeA"
                    onChange={(e) => {
                      handleBookingTypeinfo(e, 0);
                    }}
                    value={bookingTypeinfo}
                    checked={bookingTypeinfo === 0}
                  />
                  <span className="checkmark" />
                </label>
              </Col>
              <Col lg={2} md={2} xs={4} className="text-format">
                <label className="checkbox-item01 ">
                  รถฉุกเฉิน
                  <input
                    type="radio"
                    name="option1"
                    onClick={bookTypeset}
                    id="bookingTypeB"
                    onChange={(e) => {
                      handleBookingTypeinfo(e, 1);
                    }}
                    value={bookingTypeinfo}
                    checked={bookingTypeinfo === 1}
                  />
                  <span className="checkmark" />
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={4} className="text-chekbox text-center">
                รูปแบบการจอง
              </Col>
              <Col lg={2} md={2} xs={4} className="text-format">
                <label className="checkbox-item01 ">
                  เที่ยวเดียว
                  <input
                    type="radio"
                    name="option2"
                    onClick={tripSet}
                    id="triptourA"
                    value={tripinfo}
                    checked={tripinfo === 0}
                    onChange={(e) => handleTripChange(e, 0)}
                  />
                  <span className="checkmark" />
                </label>
              </Col>
              <Col lg={2} md={2} xs={4} className="text-format">
                <label className="checkbox-item01 ">
                  ไป - กลับ
                  <input
                    type="radio"
                    name="option2"
                    onClick={tripSet}
                    id="triptourB"
                    value={tripinfo}
                    checked={tripinfo === 1}
                    onChange={(e) => handleTripChange(e, 1)}
                  />
                  <span className="checkmark" />
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                ต้นทาง - ขาไป
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeStartGo}
                  value={startGo}
                />
              </Col>
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                ต้นทาง - ขากลับ
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  className="inp-data01 fontReg font-size03"
                  disabled={tripinfo === 0}
                  onChange={handleChangeStartBack}
                  value={startBack}
                />
              </Col>
            </Row>
            <Row className="my-4">
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                เวลา-ขาไป
              </Col>
              <Col lg={2} md={4} xs={4}>
                <input
                  type="date"
                  className="time-input"
                  onChange={handleChangeDateStartGo}
                  min={moment().format("YYYY-MM-DD")}
                  value={dateStartGo}
                />
              </Col>
              <Col lg={2} md={4} xs={4}>
                <input
                  type="time"
                  className="time-input"
                  onChange={handleChangeTimeStartGo}
                  value={timestartGO}
                />
              </Col>
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                เวลา-ขากลับ
              </Col>
              <Col lg={2} md={4} xs={4}>
                {tripinfo === 0 ? (
                  <input
                    type="date"
                    className="time-input"
                    style={{ backgroundColor: "#e7e9eb" }}
                    disabled
                    onChange={handleChangeDateStartBack}
                    value={dateStartBack}
                  />
                ) : (
                  <input
                    type="date"
                    className="time-input"
                    onChange={handleChangeDateStartBack}
                    min={moment(dateStartGo).format("YYYY-MM-DD")}
                    value={dateStartBack}
                  />
                )}
              </Col>
              <Col lg={2} md={4} xs={4}>
                {tripinfo === 0 ? (
                  <input
                    type="time"
                    className="time-input"
                    style={{ backgroundColor: "#e7e9eb" }}
                    onChange={handleChangeTimeStartBack}
                    disabled
                    value={timestartBack}
                  />
                ) : (
                  <input
                    type="time"
                    className="time-input"
                    onChange={handleChangeTimeStartBack}
                    value={timestartBack}
                  />
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                ปลายทาง - ขาไป
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeStopGo}
                  value={stopGo}
                />
              </Col>
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                ปลายทาง - ขากลับ
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  className="inp-data01 fontReg font-size03"
                  disabled={tripinfo === 0}
                  onChange={handleChangeStopBack}
                  value={stopBack}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                ชื่อผู้ขับ
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeNameDriver}
                  value={nameDriver}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                เบอร์โทรศัพท์
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  maxLength={10}
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeCallDriver}
                  value={callDriver}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                เลขทะเบียน
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeCarNum}
                  value={carNum}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                สีรถ
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeCarColor}
                  value={carColor}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={2} className="text-header text-center">
                ประเภทรถ
              </Col>
              <Col lg={4} md={4} xs={4}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={handleChangeTypeCar}
                  value={typeCar}
                />
              </Col>
            </Row>
            <div className="row mt-4 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save" onClick={(event) => postBusedit()}>
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditbbusbooking;
