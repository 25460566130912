import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import "./Home.css";
import CardUsersApp from "../cardDashMain/CardUsersApp";
import CardIntsSportRank from "../cardDashMain/CardIntsSportRank";
import CardCampaignParticipants from "../cardDashMain/CardCampaignParticipants";
import CardArenaUsers from "../cardDashMain/CardArenaUsers";
import CardPromotionalUse from "../cardDashMain/CardPromotionalUse";
import { getDashboard, getDashboardArena } from "../../api/dashboard_api";
import Loading from "./Loading";
import React, { useState, useEffect, useRef } from "react";
import Excelchart from "../uploads/exportChart";
import html2canvas from "html2canvas";
function Home() {
  let dt_Test = [];
  let [setckDown, setCkDown] = useState(false);
  let [isLoading, setLoading] = useState(false);
  let [userDataDash, setUserDataDash] = useState([]);
  let [userDataSport, setUserDataSport] = useState([]);
  let [userDataFood, setUserDataFood] = useState([]);
  let [sumAllperson, setSumAllperson] = useState([]);
  let [dataAllGH, setSumAllGH] = useState([]);
  let [dataAllCampa, setAllCampa] = useState([]);
  let [dataAllArena, setAllArena] = useState([]);
  let [dateTime, setDatetime] = useState(null);
  const [spanloading, setspanLoading] = useState(false);
  let [calSum, setAllSumCampa] = useState(null);
  let data = [];
  const [ref, screenshot] = useScreenshot();

  useEffect(() => {
    getDataDashboard();
    getDataDashboarArena();
  }, []);
  function exportFile() {
    setCkDown(true);
  }

  function useScreenshot() {
    const [screenshot, setScreenshot] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
      if (ref.current) {
        html2canvas(ref.current).then((canvas) => {
          const dataURL = canvas.toDataURL();
          setScreenshot(dataURL);
        });
      }
    }, [ref]);
    return [ref, screenshot];
  }
  const getDataDashboard = async () => {
    setspanLoading(true);
    let getData = [];
    let geetDataSp = [];
    let geetDataFd = [];
    const respon = await getDashboard();

    if (respon.data.status === "success") {
      setLoading(true);

      respon.data.data.appUsers.forEach((objectData, i) => {
        return getData.push({
          quantity: objectData.ageAmount,
          id: objectData.ageID,
          age: objectData.ageName,
        });
      });
      setUserDataDash(getData);
      setSumAllperson(getData.reduce((a, v) => (a = a + v.quantity), 0));
      let spObj = respon.data.data.sportsvoteDash_summary;
      geetDataSp.push(
        { rank: spObj.top_1.name, quantity: spObj.top_1.amount },

        { rank: spObj.top_2.name, quantity: spObj.top_2.amount },
        { rank: spObj.top_3.name, quantity: spObj.top_3.amount }
      );
      setUserDataSport(geetDataSp);
      setDatetime(respon.data.data.lastUpdated);
      // // food
      let fdObj = respon.data.data.campaignHist;
      geetDataFd.push({
        totalAllAmount: fdObj.totalAllAmount,
        totalFoodAmount: fdObj.totalFoodAmount,
        totalStoreAmount: fdObj.totalStoreAmount,
      });
      setUserDataFood(geetDataFd[0]);
      let pmObj = respon.data.data.promotionHist;
      setSumAllGH(pmObj);
      let dataCapmpaigCal = [];

      // promotionHist
      //       0
      // :
      // {type: "totalStoreAmount", cnt: 27}
      // 1
      // :
      // {type: "totalFoodAmount", cnt: 20}
      // 2
      // :
      // {type: "totalAllAmount", cnt: 29}
      dataCapmpaigCal.push(
        {
          amount: respon.data.data.campaignHist.totalStoreAmount,
          id: 1,
          quantity: respon.data.data.campaignHist.totalStoreAmount || 0,
          store: "ร้านค้า",
        },
        {
          amount: respon.data.data.campaignHist.totalFoodAmount,
          id: 2,
          quantity: respon.data.data.campaignHist.totalFoodAmount || 0,
          store: "ร้านอาหาร",
        },
        {
          amount: respon.data.data.campaignHist.totalAllAmount,
          id: 3,
          quantity: respon.data.data.campaignHist.totalAllAmount || 0,
          store: "ร้านอาหารและร้านค้า",
        }
      );
      await setspanLoading(false);
      let SumDt =
        dataCapmpaigCal[0].quantity +
        dataCapmpaigCal[1].quantity +
        +dataCapmpaigCal[2].quantity;
      setAllSumCampa(dataCapmpaigCal);
      setAllCampa(dataCapmpaigCal);
    }

    // const data = [
    //   { title: "One", prix: 100 },
    //   { title: "Two", prix: 200 },
    //   { title: "Three", prix: 300 },
    // ];
  };
  // getDashboardArena
  const getDataDashboarArena = async () => {
    setspanLoading(true);
    const respon = await getDashboardArena();
    let dataArr = [];

    respon.data.data.forEach((objectData, i) => {
      return dataArr.push({
        id: objectData.subArenaID,
        arenaName: objectData.subArenaName,
        userCount: objectData.joinSubArenaAmount,
        lastUpdate: null,
      });
    });
    await setspanLoading(false);
    setAllArena(dataArr);
  };
  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"Dashboard หลัก"} />
      <Menu parentmenu={"Dashboard"} activemenu={"main"} />
      <div className="content-wrapper" ref={ref}>
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-home.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                Dashboard หลัก
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | Dashboard / Dashboard หลัก
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              {/* <Excelchart /> */}
              {/* <button className="btn-expFile" onClick={() => exportFile()}>
                Export 2 PDF
              </button> */}
            </div>
          </div>
          <div className="col-auto ">
            <Excelchart
              dataUserApp={userDataDash}
              dataDashSport={userDataSport}
              dataTime={dateTime}
              sumData={sumAllperson}
              dataDashFood={userDataFood}
              dataCalDashFood={dataAllCampa}
              dataArena={dataAllArena}
              dataCalGH={dataAllGH}
              sumCampa={dataAllCampa.reduce((a, v) => (a = a + v.amount), 0)}
            />
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              {(() => {
                if (isLoading === true) {
                  return (
                    <CardUsersApp
                      dataUser={userDataDash}
                      dataTime={dateTime}
                      sumData={sumAllperson}
                    />
                  );
                }
              })()}
            </div>
            <div className="col-12 col-lg-4 mt-2 mt-lg-0">
              {(() => {
                if (isLoading === true) {
                  return (
                    <CardIntsSportRank
                      dataSport={userDataSport}
                      dataTime={dateTime}
                    />
                  );
                }
              })()}
            </div>
            <div className="col-12 col-lg-4 mt-2 mt-lg-0">
              {(() => {
                if (isLoading === true) {
                  return (
                    <CardCampaignParticipants
                      dataFood={userDataFood}
                      dataTime={dateTime}
                      dataCal={dataAllCampa}
                      // sumCal={calSum}
                    />
                  );
                }
              })()}
            </div>
          </div>

          {(() => {
            if (isLoading === true) {
              return (
                <>
                  <CardArenaUsers
                    dataTime={dateTime}
                    dataArena={dataAllArena}
                  />
                  <CardPromotionalUse
                    dataTime={dateTime}
                    dataCalGH={dataAllGH}
                  />
                </>
              );
            }
          })()}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Home;
