import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Table from "../Tables/Table";
import { useNavigate } from "react-router-dom";
import Buttonexport from "../button/Buttonexports";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import "../CSS/Addedit.css";
import { Link } from "react-router-dom";
import Dropdown from "../dropdowns/Dropdown";
import UploadFileXLS from "../uploads/UploadFileXLS";
import Swal from "sweetalert2";
import ImportExcel from "../uploads/ExcelRender";
import {
  createSleeppack,
  getSleeppackById,
  updateSleeppack,
  verifyImport,
  submitImportData,
} from "../../api/sleeppack_api";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
function AddBorrowbedding() {
  let id = new URLSearchParams(window.location.search).get("id");
  const [isLoading, setLoading] = useState(null);
  const [nameBed, setnameBed] = useState("");
  const [countBed, setcountBed] = useState("");
  const [priceBed, setpriceBed] = useState("");
  const [checked, setChecked] = useState(null);
  const [groupShow, setGroupShow] = useState(false);
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }
  useEffect(() => {
    if (id) {
      getDataSleeppackById();
    }
  }, []);

  const getDataSleeppackById = async () => {
    let data = {
      active: true,
      createdAt: "2023-02-13T15:49:53.000Z",
      deletedAt: null,
      remark: "",
      sleepPackAmount: 1000,
      sleepPackFee: 10,
      sleepPackID: 6,
      sleepPackName: "หมอน",
      updatedAt: null,
    };
    const respon = await getSleeppackById(id);

    if (respon.data.status === "success") {
      setnameBed(respon.data.data.sleepPackName);
      setcountBed(respon.data.data.sleepPackFee);
      setpriceBed(respon.data.data.sleepPackAmount);
      setChecked(respon.data.data.active);
    }
  };
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/brBedding");
      }
    });
  }

  const handleSelectedImages = (images) => {
    // console.log(images); // do something with the selected images
  };
  // benz import sleeppack

  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifyNew, setVerifyNew] = useState(0);
  const [failedRow, setFailedRow] = useState([]);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifyFailed, setVerifyFailed] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [totalRow, setTotalRow] = useState(0);

  const handleImport = async (rows) => {
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);
    // sleepPackName
    // sleepPackFee
    // sleepPackAmount
    // active
    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          sleepPackName: item[0] ? item[0] : null,
          sleepPackAmount: item[1] ? item[1] : null,
          sleepPackFee: item[2] ? item[2] : null,
          active: item[3] ? item[3] : null,
        });
      }
    });

    await setImportData(row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);

      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      let failed = 0;

      const batchSize = 10;
      const failedArr = [];

      setTotalRow(importData.length);
      //    setTotalRow(importData.length - 1);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));

        const dataToVerify = {
          importData: data,
        };

        const response = await verifyImport(dataToVerify);
        if (response.status === 200) {
          success += response.data.success;
          skip += response.data.skip;
          create += response.data.create;
          failed += response.data.failed;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
          // setVerifyFailed(failed)
        } else {
          error++;
          // alert("เกิดข้อผิดพลาด กรุณาตรวจสอบไฟล์");
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      // if (failedArr.length === 0) {
      //   Swal.fire({
      //     title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
      //     icon: "success",
      //     confirmButtonText: "ตกลง",
      //   });
      //   // alert("verify data success");
      //   // setIsVerify(false);
      //   failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
      // } else {
      //   Swal.fire({
      //     title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
      //     icon: "error",
      //     confirmButtonText: "ตกลง",
      //   });
      //   setIsVerify(true);
      //   return;
      // }
      if (failedArr.length > 0) {
        Swal.fire({
          title: "ตรวจสอบข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง ",
          icon: "error",
          confirmButtonText: "ยืนยัน",
        });
      } else {
        Swal.fire({
          title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
          icon: "success",
          confirmButtonText: "ยืนยัน",
        });
      }
      failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
      console.log(error);
    }
  };

  const importSleeppack = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);

      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        console.log("error : ", error);
        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));

            const dataToVerify = {
              importData: data,
            };

            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
        if (error === true) {
          Swal.fire({
            title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then((result) => {
            if (result.isConfirmed) {
              setIsVerify(false);
              navigateToBack("/brBedding");
            }
          });
        } else if (error === false) {
          Swal.fire({
            title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigateToBack("/brBedding");
            }
          });
        }
      });

      // if (error === 0) {
      //   Swal.fire({
      //     title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
      //     icon: "success",
      //     confirmButtonText: "ตกลง",
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       setIsVerify(false);
      //       navigateToBack("/sportType");
      //     }
      //   });
      // } else {
      //   Swal.fire({
      //     title: "เกิดข้อผิดพลาด",
      //     icon: "error",
      //     confirmButtonText: "ตกลง",
      //   });
      // }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/sportType");
        }
      });
      console.log(error);
    }
  };
  const postDataBedding = async () => {
    let data = {
      sleepPackName: nameBed,
      sleepPackFee: countBed,
      sleepPackAmount: priceBed,
      active: checked,
    };

    if (id) {
      if (
        nameBed === null ||
        countBed === null ||
        priceBed === null ||
        nameBed === "" ||
        countBed === "" ||
        priceBed === ""
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        Swal.fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              const respon = await updateSleeppack(id, data);

              // setLoading(1);
              if (respon.data.status === "success") {
                Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(1);
                    navigate("/brBedding");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  setLoading(1);
                  navigate("/brBedding");
                });
              }
            })();
          }
        });
      }
    } else {
      if (nameBed === "" || countBed === "" || priceBed === "") {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              // const respon = [];
              const respon = await createSleeppack(data);
              // setLoading(1);
              if (respon.data.status === "success") {
                Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(1);
                    navigate("/brBedding");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ตกลง",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  setLoading(1);
                  navigate("/brBedding");
                });
              }
            })();
          }
        });
      }
    }
  };
  const [inputForm, setInputForm] = useState({
    inp1: "", //ชื่อรายการแข่งขัน
    inp2: null, //Calendarpicker
    inp3: 0, //สังกัด
    inp4: 0, //ชนิดกีฬา
    inp5: 0, //ประเภทการแข่งขัน
  });
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  return (
    <>
      {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()}
      <Header parentmenu={"ข้อมูลเครื่องนอน"} />
      <Menu parentmenu={"borrowReturn"} activemenu={"brBedding"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-borrow.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ยืม-คืนชุดเครื่องนอน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ยืม-คืนชุดเครื่องนอน / ข้อมูลเครื่องนอน
                {/* Add */}
              </font>
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <Col className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <Row className="mx-1">
              <Col xs={12} md={12} lg={12}>
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={3} xs={4} className="text-header text-start">
                ชื่อเครื่องนอน
              </Col>
              <Col lg={10} md={9} xs={8}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setnameBed(e.target.value);
                  }}
                  value={nameBed}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={3} xs={4} className="text-header text-start">
                จำนวนเครื่องนอน
              </Col>
              <Col lg={10} md={9} xs={8}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setpriceBed(e.target.value);
                  }}
                  value={priceBed}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={3} xs={4} className="text-header text-start">
                ค่าปรับ
              </Col>
              <Col lg={10} md={9} xs={8}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setcountBed(e.target.value);
                  }}
                  value={countBed}
                />
              </Col>
            </Row>
            <div className="row ml-0 mt-4">
              <div className="col-md-2 ml-0 mt-3"></div>
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  id="recommend"
                  // defaultChecked={checked}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  onClick={() => {
                    postDataBedding();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Col>

          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/012-Addborrowbedding_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                {/* <UploadFileXLS limit={1} onChange={handleFileChange}/> */}
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                {/* <span className='mx-2'>{verifySuccess}/{totalRow}</span> */}
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0 ? true : false}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSleeppack}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddBorrowbedding;
