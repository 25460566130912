import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import Table from "../Tables/Table";
import TableData from "../Tables/TableData";
import { json, useNavigate } from "react-router-dom";
import "../pages/Mastersporttype.css";
import moment from "moment";
import Buttonexport from "../button/Buttonexports";
import Button3items from "../button/Button3items";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import {
  getGuestList,
  getGuestById,
  deleteGuest,
  getmemberList,
  getDataMemberFilter,
} from "../../api/guest_api";
import {
  getPermissionList,
  getPermissionMobileList,
} from "../../api/permission_api";
import Loading from "../pages/Loading";

function Deleteuseracc() {
  const [staffName, setstaffName] = useState(null);
  const [staffCall, setstaffCall] = useState(null);
  const [staffEmail, setstaffEmail] = useState(null);
  const [staffPer, setstaffPer] = useState(null);
  const [dateDelete, setdateDelete] = useState(null);
  let [dataMember, setDataMember] = useState([]);
  // const [dataPer, setDataPer] = useState([])
  const [permissionDropdown, setPermissionDropdown] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const columnsname = [
    "id",
    "staffName",
    "staffCall",
    "staffEmail",
    "staffPer",
    "deleteAt",
  ];
  const columnsText = [
    "ลำดับที่",
    "ชื่อ-นามสกุล",
    "เบอร์โทรศัพท์",
    "อีเมล",
    "สิทธิ์",
    "วันที่ลบ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "text"];
  const rowsData = [];

  const DataDropdownPermission = [
    { value: 0, text: "ทั้งหมด" },
    { value: 1, text: "Superadmin" },
    { value: 2, text: "Admin" },
    { value: 3, text: "ขนส่ง" },
    { value: 4, text: "โรงเรียนกีฬา" },
    { value: 5, text: "บุคคลทั่วไป" },
    { value: 6, text: "ร้านค้า / ร้านอาหาร" },
    { value: 7, text: "ผู้ฝึกสอน" },
    { value: 8, text: "นักกีฬา" },
    { value: 9, text: "เจ้าหน้าที่ดูแลที่พัก" },
    { value: 10, text: "เจ้าหน้าที่ดูแลสนาม" },
    { value: 11, text: "VIP" },
    { value: 12, text: "กรรมการ" },
    { value: 13, text: "เจ้าหน้าที่" },
  ];

  const getPermissionDropDown = async () => {
    setLoading(true);
    try {
      let AllData = [];
      let dataType = [{ value: "", text: "ทั้งหมด" }];
      let dataTypeStaff = [];
      // const response = await getPermissionList();
      // getPermissionMobileList
      const response = await getPermissionMobileList();
      await setLoading(false);
      if (response.status == 200) {
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.permissionAccountId,
            text: item.permissionName,
            // subType: [],
          });
        });
        await setLoading(true);
        const responseStaff = await getPermissionList();
        await setLoading(false);
        if (responseStaff.status == 200) {
          responseStaff.data.data.forEach((item, i) => {
            return dataTypeStaff.push({
              value: item.permissionAccountId,
              text: item.permissionName,
            });
          });

          // setPermissionDropdown(dataType);
        }

        AllData = [...dataType, ...dataTypeStaff];

        setPermissionDropdown(AllData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataMember = async () => {
    setLoading(true);
    let data = { delete: true };
    try {
      const respon = await getGuestList(data);
      await setLoading(false);
      respon.data.data.map((objectData, i) => {
        return rowsData.push({
          //
          id: { value: i + 1, type: "text", dataID: objectData.memberID },
          staffName: {
            value:
              objectData.firstname && objectData.lastname
                ? objectData.firstname + "  " + objectData.lastname
                : "-",
            type: "text",
          },
          staffCall: {
            value:
              objectData.mobileNo === null || objectData.mobileNo === ""
                ? "-"
                : objectData.mobileNo,
            type: "text",
          },
          staffEmail: {
            value:
              objectData.email === null || objectData.email === ""
                ? "-"
                : objectData.email,
            type: "text",
          },
          staffPer: {
            value: objectData.permissionAccount
              ? objectData.permissionAccount
              : "-",
            type: "text",
          },
          deleteAt: {
            value:
              objectData.deletedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.deletedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
        });
      });

      setDataMember(rowsData);
      // setDataCoach(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getDataMember();
    getPermissionDropDown();
    // getPermission()
  }, []);

  const [inputForm, setInputForm] = useState({
    name: "",
    permission: "",
  });

  const getDataFilter = async () => {
    setLoading(true);
    let respon;
    let rowsdata = [];
    if (inputForm.name === "" && inputForm.permission === "") {
      getDataMember(null);
    } else {
      let DropdownForm = {
        name: inputForm.name,
        permissionId: selectedValue1,
      };
      respon = await getDataMemberFilter(DropdownForm);
      await setLoading(false);
      respon.data.data.forEach((objectData, i) => {
        return rowsData.push({
          //
          id: { value: i + 1, type: "text", dataID: objectData.memberID },
          staffName: {
            value:
              objectData.firstname && objectData.lastname
                ? objectData.firstname + "  " + objectData.lastname
                : "-",
            type: "text",
          },
          staffCall: {
            value:
              objectData.mobileNo === null || objectData.mobileNo === ""
                ? "-"
                : objectData.mobileNo,
            type: "text",
          },
          staffEmail: {
            value:
              objectData.email === null || objectData.email === ""
                ? "-"
                : objectData.email,
            type: "text",
          },
          staffPer: {
            value: objectData.permissionAccount
              ? objectData.permissionAccount
              : "-",
            type: "text",
          },
          deleteAt: {
            value:
              objectData.deletedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.deletedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
        });
      });

      setDataMember(rowsData);
    }
  };

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ชื่อ-นามสกุล",
      "เบอร์โทรศัพท์",
      "อีเมล",
      "สิทธิ์",
      "วันที่ลบ",
    ];
    let Data = dataMember.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.staffName.value;
      dt[2] = elemento.staffCall.value;
      dt[3] = elemento.staffEmail.value;
      dt[4] = elemento.staffPer.value;
      dt[5] = elemento.deleteAt.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานบัญชีผู้ใช้ที่ถูกลบ",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [selectedValue1, setSelectedValue1] = useState("");
  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.name = "";
    setSelectedValue1("");
    setValuefromparent1("ทั้งหมด");
    getDataMember();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  const handleRegisChange = (value) => {
    setSelectedValue1(value);
  };

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"บัญชีผู้ใช้ที่ถูกลบ"} />
      <Menu activemenu={"deletedUserAccount"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-del-user.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                บัญชีผู้ใช้ที่ถูกลบ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | บัญชีผู้ใช้ที่ถูกลบ
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items onExportClicked={handleExport} />
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              {/* <Button3 navigateToAddEdit={navigateToAddEdit} pageAddEditName={'/Addeditsporttype'}/> */}
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูลชื่อจริง, นามสกุล"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สิทธิ์</p>
                  <Dropdown
                    options={permissionDropdown}
                    setinput={setinput}
                    inputname={"permission"}
                    id="permission"
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleRegisChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการบัญชีที่ถูกลบ
                </p>
              </div>
            </div>
            {/* {JSON.stringify(dataMember)} */}
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataMember}
              isSearchEnable={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Deleteuseracc;
