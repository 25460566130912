import React from "react";

function ButtonAdd(props) {
  const { navigateToAddEdit, pageAddEditName } = props;
  return (
    <>
      <button
        className="btn-blue02 mr-2 mr-xl-3"
        onClick={() => navigateToAddEdit(pageAddEditName)}
      >
        <img
          src="assets/icons/ic-add.png"
          alt="ExportFiles Icon"
          className="ic-add"
        />
        <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
          เพิ่มข้อมูล
        </span>
      </button>
    </>
  );
}

export default ButtonAdd;
