import React, { useEffect, useState } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import Button3items from "../button/Button3items";
import Dropdown from "../dropdowns/Dropdown";
import TableData from "../Tables/TableData";
import { getAffliation } from "../../api/affliation_api";
import {
  getCampList,
  getCampById,
  removeCamp,
  getDataCampFilter,
  getCampDetail,
} from "../../api/camp_api";
import Swal from "sweetalert2";
import moment from "moment";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import Loading from "./Loading";
function Masteraccommodation(props) {
  let dataA = [];
  let DataDropdownCamp = [{ value: 0, text: "ทั้งหมด" }];
  const [rowsdata, setRowsdata] = useState([]);
  const [campName, setCampName] = useState(null);
  const [foolr, setFoolr] = useState(null);
  const [guestAvailable, setGuestAvailable] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [status, setStatus] = useState(null);
  const [coordinator, setCoordinator] = useState(null);
  const [coordinatorTel, setCoordinatorTel] = useState(null);
  const [dataDropCampname, setDataDropCampname] = useState([]);
  const [dataDropAff, setDataDropAff] = useState([]);
  const [spanloading, setSpanLoading] = useState(false);
  const location = useLocation();

  const DataDropdownStatus = [
    { value: "", text: "ทั้งหมด" },
    { value: true, text: "ใช้งาน" },
    { value: false, text: "ไม่ใช้งาน" },
  ];
  const [inputForm, setInputForm] = useState({
    inp1: undefined,
    inp2: undefined,
    inp3: undefined,
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const DataDropdownAffiliation = [
    { value: 0, text: "ทั้งหมด" },
    { value: 1, text: "กรุงเทพมหานคร" },
    { value: 2, text: "ชลบุรี" },
    { value: 3, text: "สมุทรสาคร" },
    { value: 4, text: "จันทบุรี" },
  ];

  let id = new URLSearchParams(location.search).get("id");

  async function getCamp() {
    setSpanLoading(true);
    const ref = await getCampList();
    setSpanLoading(false);

    let temprowsdata = [];

    ref.data.data.forEach((value, i) => {
      let tempdata = {
        id: { value: i + 1, dataID: value.campID, type: "number" },
        accommodation: { value: value.campName, type: "text" },

        amountRoom: { value: value.roomAvailable, type: "text" },
        amountGuest: { value: value.guestAvailable, type: "text" },
        coordinator: { value: value.coordinator, type: "text" },
        coordinatorTel: { value: value.coordinatorTel, type: "text" },
        status: {
          value: value.active == true ? "ใช้งาน" : "ไม่ใช้งาน",
          type: "text",
        },
        updateddate: {
          value:
            value.updatedAt !== null
              ? moment(new Date(value.updatedAt)).format("YYYY-MM-DD")
              : moment(new Date(value.createdAt)).format("YYYY-MM-DD"),
          type: "text",
        },
        action: { value: "1", type: "actions" },
      };
      temprowsdata.push(tempdata);
    });

    setRowsdata(temprowsdata);
    let dataType = [{ value: "", text: "ทั้งหมด" }];
    ref.data.data.forEach((item, i) => {
      return dataType.push({
        value: item.campID,
        text: item.campName,
        subType: [],
      });
    });

    setDataDropCampname(dataType);
  }

  useEffect(() => {
    getCamp();
    affiliationDropdown();
    // campDropdown();
  }, []);

  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/AddeditathleteAmt`);
        break;
      case "edit":
        // handle edit button
        navigate(`/AddeditathleteAmt?id=${id}`);
        break;
      case "view":
        // handle view button
        viewModal(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const viewModal = async (id) => {
    const modal = await getCampById(id);
    await setCampName(modal.data.data.campName);
    await setCoordinator(modal.data.data.coordinator);
    await setCoordinatorTel(modal.data.data.coordinatorTel);
    await setRoomId(modal.data.data.roomAvailable);
    await setStatus(modal.data.data.active == true ? "ใช้งาน" : "ไม่ใช้งาน");
    await setFoolr();
    await setGuestAvailable(modal.data.data.guestAvailable);
  };

  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await removeCamp(id);
            if (response.status === 200) {
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
              getCamp();
            } else {
              swalWithBootstrapButtons.fire({
                title: "เกิดข้อผิดพลาด!",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  let sortDt = new URLSearchParams(window.location.search).get("sortby");

  // const getFilterdropdown = async (data) => {
  //   var dataArr = data.map((item) => {
  //     return [item.text, item];
  //   }); // creates array of array
  //   var maparr = new Map(dataArr); // create key value pair from array of array
  //   var result = [...maparr.values()]; //converting back to array from mapobject
  //   setDataDropCampname(result);
  // };

  const getCampFilterType = async () => {
    setSpanLoading(true);
    if (selectedAff === "" && selectedCamp === "" && selectedStatus === "") {
      getCamp();
    } else {
      let bodyfilter = {
        inp1: selectedCamp,
        inp2: selectedAff,
        inp3: selectedStatus,
      };
      const response = await getDataCampFilter(bodyfilter);

      let temprowsdata = [];
      response.data.data.forEach((value, i) => {
        let rowsdata = {
          id: { value: i + 1, dataID: value.campID, type: "number" },
          accommodation: { value: value.campName, type: "text" },
          amountRoom: { value: value.roomAvailable, type: "text" },
          amountGuest: { value: value.guestAvailable, type: "text" },
          coordinator: { value: value.coordinator, type: "text" },
          coordinatorTel: { value: value.coordinatorTel, type: "text" },
          status: {
            value: value.active == true ? "ใช้งาน" : "ไม่ใช้งาน",
            type: "text",
          },
          updateddate: {
            value:
              value.updatedAt !== null
                ? moment(new Date(value.updatedAt)).format("YYYY-MM-DD")
                : moment(new Date(value.createdAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "actions" },
        };
        temprowsdata.push(rowsdata);
      });
      await setSpanLoading(false);
      setRowsdata(temprowsdata);
    }
  };

  const handleExport = async (type) => {
    let Header = [
      "No",
      "สถานที่พัก",
      "จำนวนผู้เข้าพัก",
      // "จำนวนห้อง",
      "ผู้ประสานงาน",
      "เบอร์ผู้ประสานงาน",
      "สถานะ",
      "อัปเดตล่าสุด",
      // "เครื่องมือ",
    ];
    let Data = rowsdata.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.accommodation.value;
      dt[2] = elemento.amountRoom.value;
      // dt[3] = elemento.amountGuest.value;
      dt[3] = elemento.coordinator.value;
      dt[4] = elemento.coordinatorTel.value;
      dt[5] = elemento.status.value;
      dt[6] = elemento.updateddate.value;
      // dt[8] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานที่พักนักกีฬา",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const columnsname = [
    "id",
    "accommodation",
    "amountRoom",
    "amountGuest",
    "coordinator",
    "coordinatorTel",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "สถานที่พัก",
    "จำนวนห้อง",
    "จำนวนผู้เข้าพัก",
    "ผู้ประสานงาน",
    "เบอร์ผู้ประสานงาน",
    "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];

  const affiliationDropdown = async () => {
    try {
      const response = await getAffliation();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.affliationID,
            text: item.affliationName,
            subType: [],
          });
        });
        setDataDropAff(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const campDropdown = async () => {
    try {
      const response = await getCampList();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.campID,
            text: item.campName,
            subType: [],
          });
        });

        setDataDropCampname(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedCamp, setSelecteCamp] = useState("");
  const [selectedAff, setSelecteAff] = useState("");
  const [selectedStatus, setSelecteStatus] = useState("");

  const handleCampChange = (value) => {
    setSelecteCamp(value);
  };

  const handleAffChange = (value) => {
    setSelecteAff(value);
  };

  const handleStatusChange = (value) => {
    setSelecteStatus(value);
  };

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [valuefromparent3, setValuefromparent3] = useState("ทั้งหมด");

  function resetDropdown() {
    setSelecteCamp("");
    setSelecteAff("");
    setSelecteStatus("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setValuefromparent3("ทั้งหมด");
    getCamp();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValuefromparent3(text) {
    setValuefromparent3(text);
  }

  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"ที่พักนักกีฬา"} />
      <Menu parentmenu={"masterData"} activemenu={"athleteAmt"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ที่พักนักกีฬา
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / ที่พักนักกีฬา
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/AddeditathleteAmt"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-sm-3 pr-sm-0">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สถานที่พัก
                  </p>
                  <Dropdown
                    options={dataDropCampname}
                    setinput={setinput}
                    inputname={"inp1"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleCampChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สังกัด</p>
                  <Dropdown
                    options={dataDropAff}
                    setinput={setinput}
                    inputname={"inp2"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleAffChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สถานะ</p>
                  <Dropdown
                    options={DataDropdownStatus}
                    setinput={setinput}
                    inputname={"inp3"}
                    ChangesetValuefromparent={ChangesetValuefromparent3}
                    valuefromparent={valuefromparent3}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(event) => getCampFilterType()}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-sm-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการที่พักนักกีฬา
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/AddeditathleteAmt"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowsdata}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลที่พักนักกีฬา
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อสถานที่พัก: {campName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ผู้ประสานงาน: {coordinator}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เบอร์ผู้ประสานงาน: {coordinatorTel}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ: {status}
                    </p>
                  </div>
                  <div className="col-12 mt-4">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>รายละเอียดที่พัก
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      จำนวนผู้เข้าพักที่รองรับ : {roomId}
                    </p>
                  </div>
                  {/* <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชั้น: {foolr}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      จำนวนผู้เข้าพัก/ห้อง: {guestAvailable} คน
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Masteraccommodation;
