import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import Button3 from "../button/Button3items";
import Dropdown from "../dropdowns/Dropdown";
import Loading from "../pages/Loading";
import Calendarpicker from "../calendars/Calendarpicker";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  getBusbooking,
  getBusbookingByID,
  getBusbookingFilter,
  CancleBusbooking,
} from "../../api/busbooking_api";
import moment from "moment";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";

function Scheduleamt() {
  const enableButton = {
    view: true,
    edit: true,
    approve: false,
    delete: false,
    cancel: true,
  };
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }
  const [isLoading, setLoading] = useState(false);
  const [memberType, setMemberType] = useState(null);
  const [bookingChannel, setBookingChannel] = useState(null);
  const [bookingType, setBookingType] = useState(false);
  const [name, setName] = useState(null);
  const [cardNum, setCardNum] = useState(null);
  const [trip, settrip] = useState(null);
  const [startGo, setStartGo] = useState(null);
  const [startBack, setStartBack] = useState(null);
  const [stopGo, setStopGo] = useState(null);
  const [stopBack, setStopBack] = useState(null);
  const [dateStartGo, setDateStartGo] = useState(null);
  const [dateStartBack, setDateStartBack] = useState(null);
  const [timestartGO, setTimeStartGo] = useState(null);
  const [timestartBack, setTimeStartBack] = useState(null);
  const [nameDriver, setNameDriver] = useState(null);
  const [callDriver, setCallDriver] = useState(null);
  const [carNum, setCarNum] = useState(null);
  const [carColor, setcarColor] = useState(null);
  const [typeCar, setTypeCar] = useState(null);
  const [bookingTypeinfo, setBookingTypeinfo] = useState(null);
  const [tripinfo, settripinfo] = useState(null);

  const [DataBusbooking, setDataBusbooking] = useState([]);
  const [bookingStatus, setBookingStatus] = useState(null);
  let [chkSt, setchkSt] = useState(false);

  const DataDropdownMemberType = [
    { value: "", text: "ทั้งหมด" },
    { value: "0", text: "โค้ช" },
    { value: "1", text: "กรรมการ" },
    { value: "2", text: "อื่น ๆ" },
  ];
  const DataDropdownBookingChanel = [
    { value: "", text: "ทั้งหมด" },
    { value: "0", text: "แอปพลิเคชัน" },
    { value: "1", text: "Hotline" },
    { value: "2", text: "LINE" },
    { value: "3", text: "ศูนย์ประสานงานกลาง" },
  ];
  const clearData = async (event) => {
    window.location.reload();
  };
  const getlist = async () => {
    setLoading(true);
    try {
      const query = {
        memberType: shQuery === false ? selectedMember : null,
        bookingChannel: shQuery === false ? selectedType : null,
      };
      // if (chkSt === false) {

      // } else {
      //   query = { memberType: null, bookingChannel: null };
      // }
      // query = {
      //   memberType: selectedMember,
      //   bookingChannel: selectedType,
      // };
      const response = await getBusbooking(query);
      if (response) {
        // await
        setLoading(false);
        const busbooking = response.data.data;
        const results = busbooking.map((row, i) => {
          const rowData = {
            id: {
              value: i + 1,
              dataID: row?.busBookingID || "-",
              type: "number",
            },
            memberType: { value: row?.memberType || "-", type: "text" },
            bookingChannel: { value: row?.bookingChannel || "-", type: "text" },
            bookingType: { value: row?.bookingType || "-", type: "text" },
            bookingName: { value: row?.bookingName || "-", type: "text" },
            bookingStatus: {
              value: row?.bookingStatus == true ? "ใช้งาน" : "ไม่ใช้งาน",
              type: "text",
            },
            staff: { value: row?.staff || "-", type: "text" },
            action: { value: "1", type: "actions" },
          };
          return rowData;
        });

        setDataBusbooking(results);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getlist();
  }, []);

  const columnsName = [
    "id",
    "memberType",
    "bookingChannel",
    "bookingType",
    "bookingName",
    "bookingStatus",
    "staff",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ประเภทผู้จอง",
    "ช่องทางการจอง",
    "ประเภทการจอง",
    "ชื่อผู้จอง",
    "สถานะ",
    "ผู้บันทึกรายการ",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "number",
    "text",
    "action",
  ];

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditbusbooking`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditbusbooking?id=${id}`);
        break;
      case "view":
        // handle view button

        viewModal(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        postCancleBusbooking(id);
        break;
      case "delete":
        // handle delete button
        // deleteByID(id)
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const viewModal = async (id) => {
    try {
      const modal = await getBusbookingByID(id);

      await setMemberType(modal.data.data.memberType);
      await setBookingChannel(modal.data.data.bookingChannel);
      await setName(modal.data.data.bookingName);
      await setCardNum(modal.data.data.idCard);
      await setBookingType(modal.data.data.bookingType);
      await settrip(modal.data.data.trip);
      await setStartGo(modal.data.data.originStart);
      await setStartBack(modal.data.data.originReturn || "-");
      await setCarNum(modal.data.data.licensePlate);
      await setNameDriver(modal.data.data.driverName);
      await setCallDriver(modal.data.data.driverMobileNo);
      await setcarColor(modal.data.data.busColor);
      await setTypeCar(modal.data.data.busType);
      await setDateStartGo(modal.data.data.dateStart);
      await setDateStartBack(modal.data.data.dateReturn);
      await setTimeStartGo(modal.data.data.timeStart);
      await setTimeStartBack(modal.data.data.timeReturn || "-");
      await setStopGo(modal.data.data.destinationStart);
      await setStopBack(modal.data.data.destinationReturn || "-");
    } catch (error) {}
  };

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ประเภทผู้จอง",
      "ช่องทางการจอง",
      "ประเภทการจอง",
      "ชื่อผู้จอง",
      "สถานะ",
      "ผู้บันทึกรายการ",
    ];

    let Data = DataBusbooking.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.memberType.value;
      dt[2] = elemento.bookingChannel.value;
      dt[3] = elemento.bookingType.value;
      dt[4] = elemento.bookingName.value;
      dt[5] = elemento.bookingStatus.value;
      dt[6] = elemento.staff.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานข้อมูลการจองรถรับ-ส่ง",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const [inputForm, setInputForm] = useState({
    memberType: undefined, //membertype
    bookingChannel: undefined, //bookingtype
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const postCancleBusbooking = async (id) => {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setLoading(true);
          const respon = await CancleBusbooking(id);
          setLoading(false);
          // setLoading(1);

          if (respon && respon.data.status === "success") {
            Swal.fire({
              title: "ยกเลิกสำเร็จ!",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then((result) => {
              getlist();
            });
          } else {
            Swal.fire({
              title: "ไม่สามารถยกเลิกได้ เนื่องจากรายการถูกยกเลิกไปแล้ว",
              icon: "error",
              confirmButtonText: "ตกลง",
            }).then((result) => {
              getlist();
            });
          }
        })();
      }
    });
  };
  const postBusbookingFilter = async () => {
    setLoading(true);
    if (selectedType === "" || selectedMember === "") {
      getlist(null);
    } else {
      const query = {
        memberType: selectedMember,
        bookingChannel: selectedType,
      };
      const response = await getBusbookingFilter(query);
      let rowsdata = [];
      response.data.data.forEach((row, i) => {
        return rowsdata.push({
          id: { value: i + 1, dataID: row?.busBookingID, type: "text" },
          memberType: { value: row?.memberType, type: "text" },
          bookingChannel: { value: row?.bookingChannel, type: "text" },
          bookingType: { value: row?.bookingType, type: "text" },
          bookingName: { value: row?.bookingName, type: "text" },
          bookingStatus: {
            value: row?.bookingStatus == true ? "ปกติ" : "ยกเลิก",
            type: "text",
          },
          staff: { value: row?.staff, type: "text" },
          action: { value: "1", type: "actions" },
        });
      });
      await setLoading(false);
      setDataBusbooking(rowsdata);
    }
  };

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");

  const [selectedType, setSelecteType] = useState("");
  const [selectedMember, setSelecteMember] = useState("");

  const handleTypeChange = (value) => {
    setSelecteType(value);
  };
  const handleMemberChange = (value) => {
    setSelecteMember(value);
  };
  let shQuery = false;
  function resetDropdown() {
    //   selectedType, setSelecteType] = useState("");
    // const [selectedMember, setSelecteMember
    inputForm.memberType = undefined;
    inputForm.bookingChannel = undefined;
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setSelecteType("");
    setSelecteMember("");
    // setchkSt(true);
    shQuery = true;
    getlist();

    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ข้อมูลการจองรถรับ-ส่ง"} />
      <Menu parentmenu={"shuttlebusData"} activemenu={"shuttlebusBooking"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-schedule.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ข้อมูลการจองรถรับ-ส่ง
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการจองรถรับ-ส่ง
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditbusbooking"}
                onButtonClicked={handleButtonClicked}
                onExportClicked={handleExport}
              />
            </div>
          </div>

          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ประเภทผู้จอง
                  </p>
                  <Dropdown
                    options={DataDropdownMemberType}
                    setinput={setinput}
                    inputname={"memberType"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleMemberChange}
                  />
                </div>
              </div>
              {/* <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <Calendarpicker />
              </div> */}
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ช่องทางการจอง
                  </p>
                  <Dropdown
                    options={DataDropdownBookingChanel}
                    setinput={setinput}
                    inputname={"bookingChannel"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleTypeChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-1  col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => postBusbookingFilter()}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-1 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการข้อมูลการจองรถรับ-ส่ง
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Addeditbusbooking"
              actionsBtn={enableButton}
              columnsname={columnsName}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={DataBusbooking}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-sm" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      ข้อมูลการจองรถรับ-ส่ง
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ประเภทผู้จอง: {memberType}
                      </p>
                    </div>
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ช่องทางการจอง: {bookingChannel}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ชื่อผู้จอง: {name}
                      </p>
                    </div>
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        เลขบัตรประชาชน: {cardNum}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ประเภทการจอง:
                      </p>
                    </div>
                    <div className="col-3 ml-2">
                      <label className="checkbox-item01 ">
                        รถรับส่ง
                        <input
                          type="radio"
                          name="option1"
                          id="bookingTypeA"
                          checked={bookingType === "รถรับส่ง"}
                          disabled
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="col-3 ml-2">
                      <label className="checkbox-item01 ">
                        รถฉุกเฉิน
                        <input
                          type="radio"
                          name="option1"
                          id="bookingTypeB"
                          checked={bookingType === "รถฉุกเฉิน"}
                          disabled
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        รูปแบบการจอง:
                      </p>
                    </div>
                    <div className="col-3 ml-2">
                      <label className="checkbox-item01 ">
                        เที่ยวเดียว
                        <input
                          type="radio"
                          name="option2"
                          id="triptourA"
                          checked={trip === "เที่ยวเดียว"}
                          disabled
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="col-3 ml-2">
                      <label className="checkbox-item01 ">
                        ไป - กลับ
                        <input
                          type="radio"
                          name="option2"
                          id="triptourB"
                          checked={trip === "ไปกลับ"}
                          disabled
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ต้นทาง - ขาไป: {startGo}
                      </p>
                    </div>
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ต้นทาง - ขากลับ: {startBack}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        เวลา-ขาไป: {timestartGO} {dateStartGo}
                      </p>
                    </div>
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        เวลา-ขากลับ: {timestartBack} {dateStartBack}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ปลายทาง - ขาไป: {stopGo}
                      </p>
                    </div>
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ปลายทาง - ขากลับ: {stopBack}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ชื่อผู้ขับ: {nameDriver}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        เบอร์โทรศัพท์: {callDriver}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        เลขทะเบียน: {carNum}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        สีรถ: {carColor}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ml-2">
                      <p className="fontReg font-size03 colorGray01">
                        ประเภทรถ: {typeCar}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Scheduleamt;
