import React, { useState, useEffect } from "react";

import moment from "moment";
import Swal from "sweetalert2";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button3items from "../button/Button3items";
import { useNavigate } from "react-router-dom";
import Dropdown from "../dropdowns/Dropdown";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import { getAllArea } from "../config/configApi-Area";
import { getSubArena } from "../../api/subarena_api";
import {
  DataDropdownCompetitionRound,
  DataDropdownSportType,
  DataDropdownArenas,
} from "../dataMockup/DataMockDropdown";
import Calendarpicker from "../calendars/Calendarpicker";
import TableData from "../Tables/TableData";

import {
  geteMatch,
  getMatchById,
  deleteMatch,
  getMatchFilter,
} from "../../api/match_api";
import { Search } from "@mui/icons-material";
import Loading from "./Loading";
function Competitionschedule() {
  const [DataDropArenas, setDataDropArenas] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [detialSche, setDetialSche] = useState([]);
  const [subDetialSche, setSubDetialSche] = useState([]);
  const [isLoading, setLoading] = useState(null);
  let subTypeoption = [];
  useEffect(() => {
    areaDropdown();
    getDataMatch();
    subTypeoption = DataDropdownSportType[0].subType;

    setSubTypes(subTypeoption);
  }, []);

  const [inputForm, setInputForm] = useState({
    inp1: undefined, //ชื่อรายการแข่งขัน
    inp2: undefined, //Calendarpicker
    inp3: undefined, //สังกัด
    inp4: undefined, //ชนิดกีฬา
    inp5: undefined, //ประเภทการแข่งขัน
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const setsubtype_data = (data) => {
    setSubTypes(data);
  };
  const enableButton = {
    view: true,
    edit: true,
    delete: true,
    add: false,
    approve: false,
  };
  const navigate = useNavigate();
  const navigateToAddEdit = (page) => {
    navigate(page);
  };

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ชื่อรายการแข่งขัน",
      "รอบการแข่งขัน",
      "วันที่แข่งขัน",
      "เวลาที่แข่งขัน",
      "สนามกีฬา",
      "อัปเดตล่าสุด",
    ];
    let Data = DataSchedule.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.matchName.value;
      dt[2] = elemento.matchRound.value;
      dt[3] = elemento.matchDay.value;
      dt[4] = elemento.matchTime.value;
      dt[5] = elemento.arena.value;
      dt[6] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานการแข่งขัน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const columnsname = [
    "id",
    "matchName",
    "matchRound",
    "matchDay",
    "matchTime",
    "arena",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ชื่อรายการแข่งขัน",
    "รอบการแข่งขัน",
    "วันที่แข่งขัน",
    "เวลาที่แข่งขัน",
    "สนามกีฬา",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];
  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   matchName: { value: "หญิงเดี่ยว4x100เมตร", type: "text" },
    //   matchRound: { value: "รอบชิงชนะเลิศ", type: "text" },
    //   matchDay: { value: "20/05/2023", type: "text" },
    //   matchTime: { value: "1:30 PM", type: "text" },
    //   arena: { value: "สนามกีฬา 1", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];
  const [DataSchedule, setDataSchedule] = useState([]);
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditcompetschedule`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditcompetschedule?id=${id}`);
        break;
      case "view":
        // handle view button
        getMatchID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        deleteMatchByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const getDataMatch = async () => {
    try {
      setLoading(true);
      const respon = await geteMatch();
      setLoading(false);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          //
          id: { value: i + 1, type: "text", dataID: objectData.matchID },
          matchName: {
            value: objectData.matchName === null ? "-" : objectData.matchName,
            type: "text",
          },
          // final[1]=รอบชิง semi[2]=รอบรอง qualify[3]=รอบคัดเลือก
          matchRound: {
            value:
              objectData.matchHeat === "final"
                ? "รอบชิงชนะเลิศ"
                : objectData.matchHeat === "semi"
                ? "รอบรองชนะเลิศ"
                : objectData.matchHeat === "qualify"
                ? "รอบคัดเลือก"
                : "-",
            // ? objectData.matchHeat === "final"
            //   ? null
            //   : objectData.matchHeat
            // : "-",
            type: "text",
          },
          matchDay: {
            value:
              objectData.startTime === null
                ? "-"
                : moment(new Date(objectData.startTime)).format("DD/MM/YY") +
                  " - " +
                  moment(new Date(objectData.endTime)).format("DD/MM/YY"),
            type: "text",
          },
          matchTime: {
            value:
              objectData.startTime === null
                ? "-"
                : moment(new Date(objectData.startTime))
                    // .add(-7, "hours")
                    .format("HH:mm") +
                  " - " +
                  moment(new Date(objectData.endTime))
                    // .add(-7, "hours")
                    .format("HH:mm"),
            type: "text",
          },
          arena: {
            value:
              objectData.subArena !== null
                ? objectData.subArena.subArenaName
                : "-",
            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "action" },
          //
        });
      });
      setDataSchedule(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getMatchID = async (Id) => {
    let getData = [];
    const respon = await getMatchById(Id);
    const dt = respon.data.data;
    getData.push({
      // competitionType: dt?.competitionType === "team" ? "ทีม" : "เดี่ยว",
      // after
      competitionType: dt?.subsportName,
      createdAt: dt?.createdAt,
      deletedAt: dt?.deletedAt,
      endTime: dt?.endTime,
      matchHeat: dt?.matchHeat,
      matchID: dt?.matchID,
      matchName: dt?.matchName,
      sportName: dt?.sportName,
      startTime: moment(new Date(dt?.startTime)).format("YYYY-MM-DD"),
      subArena: dt?.subArena.subArenaName,
      Times:
        "(" +
        moment(new Date(dt?.startTime))
          // .add(-7, "hours")
          .format("HH:mm:ss") +
        " - " +
        moment(new Date(dt?.endTime))
          // .add(-7, "hours")
          .format("HH:mm:ss") +
        ")",
      subsportName: dt?.subsportName,
      updatedAt: moment(new Date(dt?.updatedAt)).format("YYYY-MM-DD"),
      affiliationMatch: dt?.affiliationMatch,
    });
    setDetialSche(getData[0]);
    let gtDt = [];
    for (let i = 0; i < getData[0].affiliationMatch.length; i++) {
      // gtDt.push({
      //   affliationName: getData[0].affiliationMatch[i].affliationName,
      // });
      for (let j = 0; j < getData[0].affiliationMatch[i].athletes.length; j++) {
        gtDt.push({
          affliationName: getData[0].affiliationMatch[i].affliationName,
          atheleteID: getData[0].affiliationMatch[i].athletes[j].atheleteID,
          email: getData[0].affiliationMatch[i].athletes[j].email,
          firstname: getData[0].affiliationMatch[i].athletes[j].firstname,
          idcard: getData[0].affiliationMatch[i].athletes[j].idcard,
          lastname: getData[0].affiliationMatch[i].athletes[j].lastname,
          memberID: getData[0].affiliationMatch[i].athletes[j].memberID,
          mobileNo: getData[0].affiliationMatch[i].athletes[j].mobileNo,
        });
      }
    }
    setSubDetialSche(gtDt);
    // setDataGuestID(getData[0]);
  };
  const deleteMatchByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteMatch(id);
            if (response.data.status === "success") {
              getDataMatch();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const areaDropdown = async () => {
    try {
      const response = await getSubArena();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.subArenaID,
            text: item.subArenaName,
            subType: [],
          });
        });

        setDataDropArenas(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // SearchData
  const SearchData = async () => {
    // setSpanLoader(true);
    // export const DataDropdownCompetitionRound = [
    //   { value: 0, text: "ทั้งหมด" },
    //   { value: 1, text: "รอบชิงชนะเลิศ" },
    //   { value: 2, text: "รอบรองชนะเลิศ" },
    //   { value: 3, text: "รอบคัดเลือก" },
    // ];

    // final[1]=รอบชิง semi[2]=รอบรอง qualify[3]=รอบคัดเลือก
    let dt = {
      keyword: name || "",
      matchHeat:
        inputForm.inp3 === 1
          ? "final"
          : inputForm.inp3 === 2
          ? "semi"
          : inputForm.inp3 === 3
          ? "qualify"
          : "",
      subArenaId: inputForm.inp4 || "",
      date: inputForm.inp2
        ? moment(new Date(inputForm.inp2)).format("YYYY-MM-DD")
        : "",
    };

    if (
      dt.matchHeat === undefined &&
      dt.keyword === "" &&
      dt.subArenaId === undefined &&
      dt.dateTime === "Invalid date"
    ) {
      getDataMatch();
    } else {
      setLoading(true);
      const response = await getMatchFilter(dt);
      setLoading(false);
      response.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          //
          id: { value: i + 1, type: "text", dataID: objectData.matchID },
          matchName: {
            value: objectData.matchName === null ? "-" : objectData.matchName,
            type: "text",
          },
          matchRound: {
            value:
              objectData.matchHeat === "final"
                ? "รอบชิงชนะเลิศ"
                : objectData.matchHeat === "semi"
                ? "รอบรองชนะเลิศ"
                : objectData.matchHeat === "qualify"
                ? "รอบคัดเลือก"
                : "-",
            type: "text",
          },
          matchDay: {
            value:
              objectData.startTime === null
                ? "-"
                : moment(new Date(objectData.startTime)).format("DD/MM/YY") +
                  " - " +
                  moment(new Date(objectData.endTime)).format("DD/MM/YY"),
            type: "text",
          },
          matchTime: {
            // .add(-7, "hours")
            value:
              objectData.startTime === null
                ? "-"
                : moment(new Date(objectData.startTime)).format("HH:mm:ss") +
                  " - " +
                  moment(new Date(objectData.endTime)).format("HH:mm:ss"),
            //  moment(new Date(objectData.startTime)).format("HH:mm") +
            //   " - " +
            //   moment(new Date(objectData.endTime)).format("HH:mm"),

            type: "text",
          },
          arena: {
            value:
              objectData.subArena !== null
                ? objectData.subArena.subArenaName
                : "-",
            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "action" },
          //
        });
      });

      setDataSchedule(rowsdata);
      // response.data.data.forEach((objectData, i) => {
      //   return rowsdata.push({
      //     id: { value: i + 1, type: "text", dataID: objectData.subSportID },
      //     sportType: {
      //       value: objectData.sport === null ? "-" : objectData.sport.sportName,
      //       type: "text",
      //     },
      //     matchType: { value: objectData.subSportName, type: "text" },
      //     status: {
      //       value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
      //       active: objectData.active,
      //       type: "text",
      //     },
      //     updateddate: {
      //       value:
      //         objectData.updatedAt !== null
      //           ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
      //           : "-",
      //       type: "text",
      //     },
      //     action: { value: "1", type: "action" },
      //   });
      // });
      // setSpanLoader(false);
      // setDataSubSport(rowsdata);
    }
  };

  const [name, setName] = useState("");
  const [inputDate, setInputDate] = useState("");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [valuefromparent3, setValuefromparent3] = useState("ทั้งหมด");

  function resetDropdown() {
    setName("");
    setInputDate("");
    inputForm.inp1 = undefined;
    inputForm.inp2 = undefined;
    inputForm.inp3 = undefined;
    inputForm.inp4 = undefined;
    setValuefromparent2("ทั้งหมด");
    setValuefromparent3("ทั้งหมด");
    getDataMatch();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValuefromparent3(text) {
    setValuefromparent3(text);
  }

  function ChangesetValueDate(value) {
    setInputDate(value);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ตารางการแข่งขัน"} />
      <Menu parentmenu={"competitionData"} activemenu={"competitionSchedule"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-competition.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ตารางการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการแข่งขัน / ตารางการแข่งขัน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/Addeditcompetschedule"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อรายการแข่งขัน"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-lg-0 mt-2">
                <Calendarpicker
                  dataDate={null}
                  setDatePicker={setinput}
                  inputname={"inp2"}
                  ChangesetValueDate={ChangesetValueDate}
                  inputDate={inputDate}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-lg-0 mt-2">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    รอบการแข่งขัน
                  </p>
                  <Dropdown
                    options={DataDropdownCompetitionRound}
                    setinput={setinput}
                    inputname={"inp3"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                  />
                </div>
              </div>
              {/* <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-lg-0 mt-2">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    รอบการแข่งขัน
                  </p>
                  <Dropdown
                    options={DataDropdownCompetitionRound}
                    setinput={setinput}
                    inputname={"inp3"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                  />
                </div>
              </div> */}
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-5 pr-2 pr-lg-0 mt-lg-3 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สนามกีฬาย่อย
                  </p>
                  <Dropdown
                    options={DataDropArenas}
                    func={setsubtype_data}
                    setinput={setinput}
                    inputname={"inp4"}
                    ChangesetValuefromparent={ChangesetValuefromparent3}
                    valuefromparent={valuefromparent3}
                  />
                </div>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-lg-3 mt-2">
                <button className="btn-blue01" onClick={() => SearchData()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-2 pr-lg-0 mt-lg-3 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
            <div className="row justify-content-start mt-3"></div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ตารางการแข่งขัน
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Addeditcompetschedule"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={DataSchedule}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      ข้อมูลตารางการแข่งขัน
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชนิดกีฬา: {detialSche.sportName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ประเภทการแข่งขัน: {detialSche.competitionType}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สนามกีฬา: {detialSche.subArena}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อรายการแข่งขัน: {detialSche.matchName}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      รอบการแข่งขัน: ชิงชนะเลิศ
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      วันที่แข่งขัน: {detialSche.startTime}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เวลาที่แข่งขัน: {detialSche.Times}
                    </p>
                  </div>
                  <div className="col-12 mt-4">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      รายการนักกีฬา
                    </p>
                  </div>
                  {/* subDetialSche */}
                  {subDetialSche.map((data, index) => {
                    return (
                      <>
                        <div className="row fontReg font-size03 colorGray01 mr-0 ml-2">
                          <div className="row w-100 m-0">
                            <div className="col-12">
                              ชื่อ: {data.firstname} {data.lastanme}
                            </div>
                          </div>
                          <div className="row w-100 m-0">
                            <div className="col-12">
                              เลขบัตรประชาชน:{data.idcard}
                            </div>
                          </div>
                          <div className="row w-100 m-0">
                            <div className="col-12">
                              สังกัด: {data.affliationName}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Competitionschedule;
