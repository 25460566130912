import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
const dataToken = localStorage.getItem("token");
// sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const sleeppackFilter = async (data) => {
  try {
    //example of query
    // const query = {
    //     name: 'John',
    //     type: 'Match'
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/sleeppack`, {
      headers: headers,
      // params: query,
      params: {
        name: data.inp1 === "" ? "" : data.inp1,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const verifyImport = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.post("/sleeppack/verifyimport", data, {
      headers: headers,
    });
    if (response.status === 200) {
      return response;
    } else {
      toast.error(" อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const submitImportData = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.post("/sleeppack/importdata", data, {
      headers: headers,
    });
    if (response.status === 200) {
      return response;
    } else {
      toast.error(" !", toastErrorConfig);
      // อยู่ระหว่างการตรวจสอบข้อมูล
    }
  } catch (error) {
    // toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
    Swal.fire({
      title: "กรุณาตรวจสอบข้อมูล ไม่สามารถทำรายการได้",
      // text: error.response.data.message,
      icon: "error",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
      showCancelButton: true,
    });
  }
};

export const getSleeppackList = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get("/sleeppack", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getSleeppackById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get(`/sleeppack/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getSleeppackBorrow = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get(`/sleeppack/borrowlog`, {
      headers: headers,
      // params:query
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getSleeppackBorrowFilter = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.get(`/sleeppack/borrowlog`, {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const createStaff = async (formData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.post(`/sleeppack`, formData, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const createSleeppack = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.post(`/sleeppack`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const updateStaff = async (id, formData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.put(`/sleepack/${id}`, formData, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const removeStaff = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.delete(`/sleeppack/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const updateSleeppack = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.put(`/sleeppack/${id}`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
