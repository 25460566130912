import { DataMockChartIntsSportRank } from "../dataMockup/DataMockChartIntsSportRank";
import ChartDoughnut from "../charts/ChartDoughnut";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
function CardIntsSportRank(props) {
  let [isLoading, setLoading] = useState(false);
  const [userDataSport, setUserDataSport] = useState([props.dataSport]);
  const [dateTime, setDatetime] = useState(props.dataTime);

  const [rankData, setRankData] = useState({
    labels: DataMockChartIntsSportRank.map((data) => data.rank),
    datasets: [
      {
        label: "จำนวน",
        data: props.dataSport.map((data) => data.quantity),
        backgroundColor: ["#6790FF", "#657BB1", "#0698FF"],
        hoverBackgroundColor: ["#6790FF", "#657BB1", "#0698FF"],
      },
    ],
  });
  useEffect(() => {
    if (props) {
      setDatetime(props.dataTime);
      setUserDataSport(props.dataSport[0]);
    }
  }, []);
  return (
    <>
      <div className="card-white">
        <div className="row">
          <div className="col-8 pr-0">
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              <span className="border-left01"></span>ผลโหวตกีฬาที่สนใจ
            </p>
          </div>
          <div className="col-4 d-flex justify-content-end p-md-0">
            <Link
              className="fontReg font-size03 colorGray01"
              to={"/sportsvoteDash"}
            >
              ดูทั้งหมด{" "}
              <span>
                <i className="fas fa-angle-right" />
              </span>
            </Link>
          </div>
        </div>
        {(() => {
          if (userDataSport) {
            return (
              <>
                <div className="position-relative">
                  <ChartDoughnut chartData={rankData} />
                  <div className="absolute-center text-center">
                    <p className="fontReg font-weight-bold font-size03 colorGray01 mb-0">
                      อันดับผลโหวต
                    </p>
                    <p className="fontReg font-weight-bold font-size03 colorGray01 mb-0">
                      กีฬาที่สนใจ
                    </p>
                    <p className="fontReg font-size03 colorGray01 mb-0">
                      Last Update
                    </p>
                    <p className="fontReg font-size03 colorGray01 mb-0">
                      {dateTime}
                    </p>
                  </div>
                </div>
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  กีฬา
                </p>
                <div className="row">
                  <div className="col-4 col-lg-4 col-xxl-4 border-right02 br-3">
                    <div className="row">
                      <div className="col-2 ">
                        <div className="bgBlue03 icon icon-Vector circle-vector mt-1"></div>
                        {/* <span className="icon icon-Vector colorBlue03"></span> */}
                      </div>
                      <div className="col-10 fontReg font-size03 colorGray01">
                        อันดับที่ 1<br />
                        <span className="fontReg font-size02 colorBlue03">
                          {props.dataSport ? (
                            <>{props.dataSport[0].rank}</>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 col-lg-4 col-xxl-4 border-right02 br-3">
                    <div className="row bd-lf">
                      <div className="col-2">
                        <div className="bgBlue02 icon icon-Vector circle-vector mt-1"></div>
                        {/* <span className="icon icon-Vector colorBlue02"></span> */}
                      </div>
                      <div className="col-10 fontReg font-size03 colorGray01">
                        อันดับที่ 2<br />
                        <span className="fontReg font-size02 colorBlue02">
                          {props.dataSport ? (
                            <>{props.dataSport[1].rank}</>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 col-lg-4 col-xxl-4">
                    <div className="row bd-lf">
                      <div className="col-2">
                        <div className="bgBlue01 icon icon-Vector circle-vector mt-1"></div>
                        {/* <span className="icon icon-Vector colorBlue01"></span> */}
                      </div>
                      <div className="col-10 fontReg font-size03 colorGray01">
                        อันดับที่ 3<br />
                        <span className="fontReg font-size02 colorBlue01">
                          {props.dataSport ? (
                            <>{props.dataSport[2].rank}</>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }

          return null;
        })()}
      </div>
    </>
  );
}

export default CardIntsSportRank;
