import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Buttonactions from "../button/Buttonactions";
import "./TableData.css";
import Pagination from "../pagination/Pagination";
import ButtonNoti from "../button/ButtonNoti";
import CheckboxSubType from "../checkbox/CheckboxSubType";
import CheckboxCenter from "../checkbox/CheckboxCenter";
import moment from "moment";
// import TableDataCtx from '../../context/tableDataContext'
//ฟังก์ชั่นสำหรับการ sort

function dynamicsort(property, order) {
  let sort_order = 1;
  if (order === "desc") {
    sort_order = -1;
  }
  return function (a, b) {
    if (
      typeof b[property].value === "string" ||
      typeof a[property].value === "string"
    ) {
      return order === "desc"
        ? b[property].value
            .toString()
            .localeCompare(a[property].value.toString())
        : a[property].value
            .toString()
            .localeCompare(b[property].value.toString());
    } else if (a[property].value < b[property].value) {
      return -1 * sort_order;

      // a should come after b in the sorted order
    } else if (a[property].value > b[property].value) {
      return 1 * sort_order;
      // a and b are the same
    } else {
      return 0 * sort_order;
    }
  };
}

//UI
const HeaderCell = ({
  column,
  columnsname,
  columnsText,
  columnsType,
  sorting,
  sortTable,
}) => {
  const isDescSorting = sorting.column === column && sorting.order === "desc";
  const isAscSorting = sorting.column === column && sorting.order === "asc";
  const futureSortingOrder = isDescSorting ? "asc" : "desc";
  if (
    columnsType != "checkbox" &&
    columnsType != "action" &&
    columnsType != "image"
  ) {
    return (
      <th
        key={column}
        className={`users-table-cell 
          ${
            columnsname == "id" ||
            columnsname == "status" ||
            columnsType == "checkbox"
              ? "th-width"
              : ""
          } 
          ${columnsType == "action" ? "th-width01" : ""}
          ${columnsname == "name" ? "th-width02" : ""}`}
        onClick={() => sortTable({ column, order: futureSortingOrder })}
      >
        {columnsText}
        {isDescSorting && (
          <span>
            <img src="assets/icons/sort03.png" alt="" className="ic-sort" />
          </span>
        )}
        {isAscSorting && (
          <span>
            <img src="assets/icons/sort02.png" alt="" className="ic-sort" />
          </span>
        )}
        {!isDescSorting && !isAscSorting && (
          <span>
            <img src="assets/icons/sort01.png" alt="" className="ic-sort" />
          </span>
        )}
      </th>
    );
  } else {
    return (
      <th
        key={column}
        className={`users-table-cell ${
          columnsname == "id" ||
          columnsname == "status" ||
          columnsType == "checkbox"
            ? "th-width"
            : ""
        } 
      ${columnsType == "action" ? "th-width01" : ""}`}
      >
        {columnsText}
      </th>
    );
  }
};

const Header = ({
  columns,
  columnsname,
  columnsText,
  columnsType,
  sorting,
  sortTable,
}) => {
  return (
    <thead>
      <tr className="fontReg font-weight-bold font-size03 colorGray01">
        {columns.map((column, index) => (
          <HeaderCell
            column={column}
            columnsname={columnsname[index]}
            sorting={sorting}
            key={column}
            sortTable={sortTable}
            columnsText={columnsText[index]}
            columnsType={columnsType[index]}
          />
        ))}
      </tr>
    </thead>
  );
};

const Content = ({
  entries,
  columns,
  actionsBtn,
  notiBtn,
  pageAddEditName,
  deleteData,
  pageAddName,
  pageApproveName,
  editData,
  onButtonClicked,
  addCheckbox,
}) => {
  return (
    <tbody className="fontReg font-size03 colorGray01">
      {entries &&
        entries.map((entry, index) => (
          <tr key={entry.id.value}>
            {columns.map((column) => {
              if (column == "image") {
                return (
                  <td className="users-table-cell" key={column}>
                    <img
                      src={entry[column].value}
                      alt="Type Sport"
                      className="im-sports-type"
                    />
                  </td>
                );
              } else if (entry[column].isinput) {
                return (
                  <td key={column} className="users-table-cell">
                    <input
                      id={entry[column].dataID}
                      value={entry[column].value}
                      onChange={(e) => {
                        // console.log(`found oonchange`);
                        // console.log(entry[column]);
                        // entry[column].eventHandler(e);
                        editData(entry.id.value, column, e.target.value);
                      }}
                    />
                  </td>
                );
              } else if (column == "action") {
                if (entry.action.checkPermission) {
                  //check action in permission mobile page
                  // view: true, edit: true, delete: false, cancel: false

                  if (entry.action.checkPermission == "hide") {
                    actionsBtn.view = true;
                    actionsBtn.edit = false;
                  } else if (entry.action.checkPermission == "show") {
                    actionsBtn.view = true;
                    actionsBtn.edit = true;
                  }
                } else {
                  if (entry.status) {
                    //check action in borrowstation page
                    if (entry.status?.chleng == 0) {
                      actionsBtn.add = true;
                      actionsBtn.edit = false;
                    } else if (entry.status?.chleng > 0) {
                      actionsBtn.add = false;
                      actionsBtn.edit = true;
                    }
                  }
                }

                return (
                  <td key={column} className="users-table-cell">
                    <React.Fragment>
                      <div className="row align-items-center justify-content-center">
                        <div
                          className={`col-12 col-md-4 pl-md-1 pr-md-1 align-items-center justify-content-center justify-content-md-end  ${
                            actionsBtn.add ? "d-flex" : "d-none"
                          } `}
                        >
                          <button
                            onClick={() =>
                              onButtonClicked("add", entry.id.dataID)
                            }
                            type="button"
                            className="btn-add01"
                          >
                            <img
                              src="assets/icons/ic-add.png"
                              alt="Action Icon"
                              className="ic-actions"
                            />
                          </button>
                        </div>
                        <div
                          className={`col-12 col-md-4 pl-md-1 pr-md-1 align-items-center justify-content-center ${
                            actionsBtn.view ? "d-flex" : "d-none"
                          } `}
                        >
                          <button
                            className={`btn-view`}
                            onClick={() =>
                              onButtonClicked("view", entry.id.dataID)
                            }
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            <img
                              src="assets/icons/ic-view.png"
                              alt=""
                              className="ic-actions"
                            />
                          </button>
                        </div>
                        <div
                          className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
                            actionsBtn.edit ? "d-flex" : "d-none"
                          } `}
                        >
                          {(() => {
                            if (
                              window.location.pathname !== "/competitionResults"
                            ) {
                              return (
                                <button
                                  onClick={() =>
                                    onButtonClicked("edit", entry.id.dataID)
                                  }
                                  type="button"
                                  className="btn-edit"
                                >
                                  <img
                                    src="assets/icons/ic-edit.png"
                                    alt=""
                                    className="ic-actions"
                                  />
                                </button>
                              );
                            } else {
                              return (
                                <button
                                  onClick={() =>
                                    onButtonClicked(
                                      "edit",
                                      entry.id.dataID,
                                      entry.action.competitionType
                                    )
                                  }
                                  type="button"
                                  className="btn-edit"
                                >
                                  <img
                                    src="assets/icons/ic-edit.png"
                                    alt=""
                                    className="ic-actions"
                                  />
                                </button>
                              );
                            }
                          })()}
                        </div>
                        <div
                          className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
                            actionsBtn.delete ? "d-flex" : "d-none"
                          } `}
                        >
                          <button
                            className="btn-delete"
                            onClick={() =>
                              onButtonClicked("delete", entry.id.dataID)
                            }
                          >
                            <img
                              src="assets/icons/ic-delete.png"
                              alt=""
                              className="ic-actions"
                            />
                          </button>
                        </div>
                        <div
                          className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
                            actionsBtn.approve ? "d-flex" : "d-none"
                          } `}
                        >
                          <button
                            onClick={() =>
                              onButtonClicked("approve", entry.id.dataID)
                            }
                            type="button"
                            className="btn-approve"
                          >
                            <img
                              src="assets/icons/ic-approve.png"
                              alt="Action Icon"
                              className="ic-actions"
                            />
                          </button>
                        </div>

                        <div
                          className={`col-12 col-md-4 pl-md-1 pr-md-1 mt-1 mt-md-0 align-items-center justify-content-center ${
                            actionsBtn.cancel ? "d-flex" : "d-none"
                          } `}
                        >
                          <button
                            onClick={() =>
                              onButtonClicked("cancel", entry.id.dataID)
                            }
                            type="button"
                            className="btn-delete"
                          >
                            <img
                              src="assets/icons/ic-block.png"
                              alt="Action Icon"
                              className="ic-actions"
                            />
                          </button>
                        </div>
                      </div>
                      {/* {tmpactionsBtn.view +' '+ tmpactionsBtn.edit} */}
                      {/* <Buttonactions
                      pageAddEditName={pageAddEditName}
                      pageAddName={pageAddName}
                      pageApproveName={pageApproveName}
                      actionsBtn={entry.action.enableButton}
                      id={entry.id.value}
                      deleteData={deleteData}
                      onViewClick={() =>
                        onButtonClicked("view", entry.id.dataID)
                      }
                      onAddClick={() => onButtonClicked("add", entry.id.dataID)}
                      onEditClick={() =>
                        onButtonClicked("edit", entry.id.dataID)
                      }
                      onApproveClick={() =>
                        onButtonClicked("approve", entry.id.dataID)
                      }
                      onCancelClick={() =>
                        onButtonClicked("cancel", entry.id.dataID)
                      }
                      onDeleteClick={() =>
                        onButtonClicked("delete", entry.id.dataID)
                      }
                    /> */}
                    </React.Fragment>
                  </td>
                );
              } else if (column == "noti") {
                return (
                  <td key={column} className="users-table-cell">
                    {/*  onCancelClick={() =>
                        onButtonClicked("noti", entry.id.dataID)
                      } */}
                    <ButtonNoti
                      onAddClick={() =>
                        onButtonClicked("noti", entry.id.dataID)
                      }
                      notiBtn={notiBtn}
                    />
                  </td>
                );
              } else if (column == "checkboxPreview") {
                return (
                  <td key={column} className="users-table-cell">
                    <CheckboxCenter addCheckbox={addCheckbox} />
                  </td>
                );
              } else if (column == "checkboxAdd") {
                return (
                  <td key={column} className="users-table-cell">
                    <CheckboxCenter
                      isChecked={entry.checkboxAdd.checked}
                      value={entry.checkboxAdd.value}
                      id={entry.checkboxAdd.id}
                      handleClick={entry.checkboxAdd.eventHandler}
                      addCheckbox={addCheckbox}
                    />
                  </td>
                );
              } else if (column == "checkboxEdit") {
                return (
                  <td key={column} className="users-table-cell">
                    <CheckboxCenter addCheckbox={addCheckbox} />
                  </td>
                );
              } else if (column == "checkboxDelete") {
                return (
                  <td key={column} className="users-table-cell">
                    <CheckboxCenter addCheckbox={addCheckbox} />
                  </td>
                );
              } else {
                return (
                  <td key={column} className="users-table-cell">
                    {entry[column].value}
                  </td>
                );
              }
            })}
          </tr>
        ))}
    </tbody>
  );
};

const SearchBar = ({ searchTable, isSearchable }) => {
  const [searchValue, setSearchValue] = useState(""); //ค่าในช่อง input

  return (
    <div className={`search-bar ${isSearchable ? "" : "d-none"} `}>
      <div className="row m-0 d-flex justify-content-end">
        <input
          className="inp-search"
          type="text"
          placeholder="Search..."
          value={searchValue}
          onInput={(e) => setSearchValue(e.target.value)}
        />
        <button
          className="btn-search fontReg font-size03 colorGray01"
          onClick={() => searchTable(searchValue)}
        >
          ค้นหา
        </button>
      </div>
    </div>
  );
};
//UI

//component table หลัก
const TableData = (props) => {
  const [rowMasterdata, setRowMasterdata] = useState([]); //ข้อมูล master data ที่ใช้จริง(ใช้ส่ง api ได้) โดยเอามาจาก props.rowsdata ที่ส่งมาจากหน้า parent
  const [rowdata, setRowdata] = useState([]); //ข้อมูลที่ได้จากการ search/sort จะอัพเดทตลอดเมื่อมีการ search/sort
  const [sorting, setSorting] = useState({ column: "id", order: "asc" }); //กำหนด column เริ่มต้นในการ sort
  const [searchValue, setSearchValue] = useState(""); //ค่าที่ได้จาก search ของ table
  const [isSearchable, setIsSearchable] = useState(false); //state ว่า table search ได้ไหม
  //const columns = ["id","name","tel","email","register","status","updateddate","action"]
  const [columns, setColumns] = useState([]); //ใช้กำหนดจำนวน column ที่จะแสดงใน table โดยข้อมูลจะตรงกันกับ columnsname
  const [columnsText, setColumnsText] = useState([]); //text ของ column ที่จะแสดง
  const [columnsType, setColumnsType] = useState([]); //ประเภทของ column
  const [columnsname, setColumnsname] = useState([]); //ชื่อของ column ตาม database/ชื่อ object column ของmock update

  //use for new pagination
  let PageSize = 10;

  if (
    window.location.pathname === "/AddBackOffice" ||
    window.location.pathname === "AddMobile" ||
    window.location.pathname === "/EditBackoffice" ||
    window.location.pathname === "/EditMobilePermission"
    // ||
    // window.location.pathname === "/AddStations"
  ) {
    PageSize = 100;
    // if (rowdata) {
    //   PageSize = rowdata.length;
    // }
  }

  const [currentPage, setCurrentPage] = useState(1); //เก็บหน้าปัจจุบัน
  let dtPage;
  // console.log("currentPage data : ", currentPage);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return rowdata?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, rowdata]); // ทำเมื่อมีการเปลี่ยนหน้าหรือตัวข้อมูลมีการเปลี่ยนแปลง
  //use for new pagination

  const sortTable = (newSorting) => {
    // console.log('newSorting preview : ');
    setSorting(newSorting);
  };
  const searchTable = (newSearchValue) => {
    setSearchValue(newSearchValue);
  };

  function deleteData(id) {
    const swalWithBootstrapButtons = Swal.mixin();

    swalWithBootstrapButtons
      .fire({
        title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ไม่ใช่",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire("ลบรายการสำเร็จ!", "", "success");
          if (props.deleteRow) {
            props.deleteRow(props.group, id);
          }
        }
      });
  }

  function editData(id, columnname, value) {
    let tempMasterdata = rowMasterdata;
    let tempMasterdataIndex = tempMasterdata.findIndex((x) => x.id.value == id);

    tempMasterdata[tempMasterdataIndex][columnname].value = value;
    setRowMasterdata([...tempMasterdata]);
  }

  useEffect(() => {
    setColumns(props.columnsname);
  }, [props.columnsname]);

  useEffect(() => {
    setColumnsText(props.columnsText);
  }, [props.columnsText]);

  useEffect(() => {
    setColumnsType(props.columnsType);
  }, [props.columnsType]);

  useEffect(() => {
    setColumnsname(props.columnsname);
  }, [props.columnsname]);

  useEffect(() => {
    setIsSearchable(props.isSearchEnable);
  }, [props.isSearchEnable]);

  //update master data ลง rowMasterdata
  useEffect(() => {
    setRowMasterdata(props.rowsdata);
  }, [props.rowsdata]);

  //ฟังก์ชั่นหลักในการแสดงผล data ทำงานใหม่ทุกครั้งเมื่อ sort/search
  useEffect(() => {
    //filter construction
    const buildFilter = (filter) => {
      let query = {};
      for (let keys in filter) {
        if (
          filter[keys].constructor === Object ||
          (filter[keys].constructor === Array && filter[keys].length > 0)
        ) {
          query[keys] = filter[keys];
        }
      }
      return query;
    };
    //new filter function
    const filterData = (data, query) => {
      const filteredData = data?.filter((item) => {
        let found = false;
        for (let key in query) {
          try {
            if (typeof item[key].value == "string") {
              if (item[key].value.includes(query[key])) {
                found = true;
              }
            } else {
              if (query[key] == item[key].value) {
                found = true;
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
        return found;
      });
      return filteredData;
    };
    //วนสร้าง construction การ filter ให้ search หาทุก column
    let filter = {};
    columns.forEach((column, index) => {
      filter[column] = [searchValue];
    });
    //การเอามาใช้
    let query = buildFilter(filter);
    let result = filterData(rowMasterdata, query); //ค่าที่ได้จากการ search

    //let test = 'name'
    //let test = rowdata.filter(x=>x[test].value.includes(searchValue) || x.id.value == searchValue)
    result?.sort(dynamicsort(sorting.column, sorting.order)); //ต้องส่งชื่อ column กับ desc หรือ asc เข้าไป sort

    setRowdata(result);
    dtPage = currentPage;
    // before
    //setCurrentPage(1); //set page to 1 ทุกครั้งที่มีการ search/sort
    // after
    setCurrentPage(dtPage); //set page to 1 ทุกครั้งที่มีการ search/sort
  }, [sorting, searchValue, columns, rowMasterdata]);

  return (
    <div>
      <SearchBar searchTable={searchTable} isSearchable={isSearchable} />
      <div
        className="scroll-x"
        onClick={() => {
          if (props.setCurrentGroup) {
            props.setCurrentGroup(props.group);
          }
        }}
      >
        <table className="users-table">
          <Header
            columns={columns}
            columnsname={columnsname}
            columnsText={columnsText}
            columnsType={columnsType}
            sorting={sorting}
            sortTable={sortTable}
          />
          <Content
            entries={currentTableData}
            columns={columns}
            pageAddEditName={props.pageAddEditName}
            actionsBtn={props.actionsBtn}
            notiBtn={props.notiBtn}
            deleteData={deleteData}
            pageAddName={props.pageAddName}
            editData={editData}
            pageApproveName={props.pageApproveName}
            onButtonClicked={props.onButtonClicked}
          />
        </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={rowdata.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default TableData;
