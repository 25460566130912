import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import { useNavigate } from "react-router-dom";
import TableData from "../Tables/TableData";
import { DataDropdownStatus } from "../dataMockup/DataMockDropdown";
import Button3items from "../button/Button3items";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import {
  getSleepcampList,
  getSleepcampById,
  datacampFilter,
} from "../../api/sleepcamp_api";
import Loading from "../pages/Loading";
import React, { useState, useEffect } from "react";
import moment from "moment";
function Borrowstation() {
  const enableButton = {
    view: true,
    edit: true,
    delete: false,
    add: true,
    approve: false,
  };
  const enableButton_add = {
    view: true,
    edit: true,
    delete: false,
    add: false,
    approve: false,
  };
  const columnsName = ["id", "nameStation", "name", "call", "status", "action"];

  const columnsText = [
    "ลำดับที่",
    "สถานที่พัก",
    "ผู้ประสานงาน",
    "เบอร์โทรผู้ประสานงาน",
    "สถานะ",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];

  const columnsNamePre = ["id", "nameBed", "countBed", "update"];
  const columnsTextPre = [
    "ลำดับที่",
    "ชื่อเครื่องนอน",
    "จำนวน",
    "อัปเดตล่าสุด",
  ];
  const columnsTypePre = ["text", "text", "text", "text"];
  const [dataTbview, setdataTbview] = useState([]);
  const [dataDetail, setDtTable] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const rowdataPre = [
    {
      id: { value: "1", type: "text" },
      nameBed: { value: "หมอน", type: "text" },
      countBed: { value: "20 ชิ้น", type: "text" },
      update: { value: "Siraphoom.S 20-02-2023", type: "text" },
    },
  ];

  const rowdata = [
    // {
    //   id: { value: "1", type: "text" },
    //   nameStation: { value: "โรงแรม", type: "text" },
    //   name: { value: "Siraphoom.S", type: "text" },
    //   call: { value: "099-23231234", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];

  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }
  const getSleepcampID = async (id) => {
    const respon = await getSleepcampById(id);
    let getDt = [];
    getDt.push({
      campName: respon.data.data.campName,
      coordinatorName: respon.data.data.coordinatorName,
      coordinatorTel: respon.data.data.coordinatorTel,
      sleeppack: respon.data.data.sleeppack,
      update:
        respon.data.data.updatedAt === null
          ? moment(new Date(respon.data.data.createdAt)).format("YYYY-MM-DD")
          : moment(new Date(respon.data.data.updatedAt)).format("YYYY-MM-DD"),
    });
    setdataTbview(getDt[0]);
    let arrTb = [];
    respon.data.data.sleeppack.forEach((objectData, i) => {
      return arrTb.push({
        id: { value: i + 1, type: "text" },
        nameBed: {
          value:
            objectData.sleepPackName === null ? "-" : objectData.sleepPackName,
          type: "text",
        },
        countBed: {
          value:
            objectData.sleepCampAmount === null
              ? 0
              : objectData.sleepCampAmount,
          type: "text",
        },
        update: {
          value: objectData.updatedAt ? objectData.updatedAt : "-",
          type: "text",
        },
      });
    });

    setDtTable(arrTb);
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/AddStations?id=${id}`);
        break;
      case "edit":
        // handle edit button
        navigate(`/AddStations?id=${id}`);
        break;
      case "view":
        getSleepcampID(id);
        // handle view button
        // viewModal(id)
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        // deleteByID(id)
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "สถานที่พัก",
      "ผู้ประสานงาน",
      "เบอร์โทรผู้ประสานงาน",
      "สถานะ",
    ];

    let Data = dataSleepcamp.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.nameStation.value;
      dt[2] = elemento.name.value;
      dt[3] = elemento.call.value;
      dt[4] = elemento.status.value;

      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานยืม-คืน ชุดเครื่องนอน ข้อมูลเครื่องนอนที่พัก",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };
  const [inputForm, setInputForm] = useState({
    inp1: undefined,
    inp2: undefined,
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [name, setName] = useState("");
  const [dataSleepcamp, setDataSleepcamp] = useState([]);
  // setStateAction
  const [dataAction, setStateAction] = useState({});
  function resetDropdown() {
    setName("");
    inputForm.inp1 = undefined;
    setSelecteStatus("");
    setValuefromparent1("ทั้งหมด");
    getDataSleepcamp();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  useEffect(() => {
    getDataSleepcamp();
  }, []);
  const getDataSleepcamp = async () => {
    setLoading(true);
    const respon = await getSleepcampList();
    let enableButton_Ch = {
      view: true,
      edit: true,
      delete: false,
      add: false,
      approve: false,
    };
    if (respon.data.status === "success") {
      respon.data.data.forEach((objectData, i) => {
        return rowdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.campID },
          nameStation: {
            value: objectData.campName ? objectData.campName : "-",
            type: "text",
          },
          name: {
            value: objectData.coordinatorName
              ? objectData.coordinatorName
              : "-",
            type: "text",
          },
          call: {
            value: objectData.coordinatorTel ? objectData.coordinatorTel : "-",
            type: "text",
          },
          status: {
            chleng: objectData.sleepCamp.length,
            value: objectData.active === false ? "ไม่ใช้งาน" : "ใช้งาน",
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });

      await setLoading(false);
      setDataSleepcamp(rowdata);
      // setStateAction(rowdata[0].stActive);
    }
  };

  const [selectedStatus, setSelecteStatus] = useState("");
  const handleStatusChange = (value) => {
    setSelecteStatus(value);
  };

  const dataFilter = async () => {
    setLoading(true);
    if (inputForm.inp1 === "" && selectedStatus === "") {
      getDataSleepcamp();
    } else {
      let bodyFilter = {
        inp1: inputForm.inp1,
        inp2: selectedStatus,
      };
      const response = await datacampFilter(bodyFilter);
      response.data.data.forEach((objectData, i) => {
        return rowdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.campID },
          nameStation: {
            value: objectData.campName ? objectData.campName : "-",
            type: "text",
          },
          name: {
            value: objectData.coordinatorName
              ? objectData.coordinatorName
              : "-",
            type: "text",
          },
          call: {
            value: objectData.coordinatorTel ? objectData.coordinatorTel : "-",
            type: "text",
          },
          status: {
            value: objectData.active === false ? "ไม่ใช้งาน" : "ใช้งาน",
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });
      await setLoading(false);
      setDataSleepcamp(rowdata);
    }
  };

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ข้อมูลเครื่องนอนที่พัก"} />
      <Menu parentmenu={"borrowReturn"} activemenu={"BorrowStation"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-borrow.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ข้อมูลเครื่องนอนที่พัก
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ยืม-คืน ชุดเครื่องนอน / ข้อมูลเครื่องนอนที่พัก
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              {/* <Button
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/AddStations"}
              /> */}
              <Button3items
                // navigateToAddEdit={"/AddStations"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          {/* <div>
            <label htmlFor="sort-select">Sort by:</label>
            <select
              id="sort-select"
              value={sortOption}
              onChange={handleSortChange}
            >
              <option value="A-Z">A-Z (A-ฮ)</option>
              <option value="Z-A">Z-A (ฮ-A)</option>
            </select>
            <ul>
              {sortedData.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div> */}
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="สถานที่พัก"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-3 col-xl-2 pr-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สถานะ</p>
                  <Dropdown
                    options={DataDropdownStatus}
                    inputname={"inp2"}
                    setinput={setinput}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    dataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการข้อมูลเครื่องนอนที่พัก
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/AddRecommendatt"
              actionsBtn={enableButton}
              columnsname={columnsName}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataSleepcamp}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                id="exampleModalLabel"
              >
                ดูรายละเอียด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูลที่พักนักกีฬา
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ชื่อสถานที่พัก: {dataTbview.campName}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ผู้ประสานงาน: {dataTbview.coordinatorName}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  เบอร์โทรผู้ประสานงาน: {dataTbview.coordinatorTel}
                </p>
              </div>
              {/* <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  อัปเดตล่าสุด: {dataTbview.update}
                </p>
              </div> */}
            </div>
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายละเอียดข้อมูลเครื่องนอน
                </p>
              </div>
              <div className="row justify-content-start fontReg font-size03 mt-3">
                <div className="col-12">
                  <TableData
                    pageAddEditName="/AddRecommendatt"
                    actionsBtn={enableButton}
                    columnsname={columnsNamePre}
                    columnsText={columnsTextPre}
                    columnsType={columnsTypePre}
                    rowsdata={dataDetail}
                    isSearchEnable={false}
                    onButtonClicked={handleButtonClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Borrowstation;
