import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

function Addapprovecampaign() {
  const [inputForm, setInputForm] = useState({
    inp1:'ร้านน้องน้ำ',//ชื่อร้านค้า
    inp2:'ร้านอาหาร',//ประเภทร้าน
    inp3:'แคมเปญ01',//ชื่อแคมเปญ
    inp4:'รายละเอียดแคมเปญ',//รายละเอียดแคมเปญ
    inp5: null,//radio อนุมัติ
    inp6: null,//radio ไม่อนุมัติ
    inp7: '',//comment ไม่อนุมัติ
  })

  function setinput(inputname,value){
 
    setInputForm((prev)=>{
      return{
        ...prev,
        [inputname]:value
      }
    })
  }
  const [commentShow,setCommentShow] = useState(false)

  const navigate = useNavigate();
  function navigateToBack(page){
    navigate(page);
  }

  function cancelData(){      
    Swal.fire({
      title: 'คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: `ยกเลิก`,

      reverseButtons: true
    })
    .then((result) => {      
      if (result.isConfirmed) {
          navigateToBack('/approveCampaign')
      } 
    });      
  }

  return (
    <>
      <Header parentmenu={"อนุมัติการเข้าร่วมแคมเปญ"} />
      <Menu parentmenu={"campaignData"} activemenu={"approveCampaign"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-12 mt-2">
              <img
                src="assets/icons/im-campaign.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                อนุมัติการเข้าร่วมแคมเปญ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลแคมเปญ / อนุมัติการเข้าร่วมแคมเปญ
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูลร้านค้า
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <input
                  id="inputStoreName"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp1}
                  onChange={(e) => setinput("inp1", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <input
                  id="inputStoreType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp2}
                  onChange={(e) => setinput("inp2", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputCamp"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp3}
                  onChange={(e) => setinput("inp3", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputDetailCamp"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp4}
                  onChange={(e) => setinput("inp4", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="fontReg font-size03 colorGray01 mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radios"
                  id="radios1"
                  defaultValue="option1"
                  defaultChecked
                  //value={true}
                  onChange={(e) => {
                    setinput("inp5", true)
                    setCommentShow(false)
                  }}
                />
                <label className="form-check-label" htmlFor="radios1">
                  อนุมัติ
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radios"
                  id="radios2"
                  defaultValue="option2"
                  //value={false}
                  onChange={(e) => {
                    setinput("inp6", false)
                    setCommentShow(true)
                  }}
                />
                <label className="form-check-label" htmlFor="radios2">
                  ไม่อนุมัติ
                </label>
              </div>
              <div className="col-12 col-sm-6 p-0 mt-sm-3 mt-2">
                <textarea
                  row="3"
                  id="inputReject"
                  className={`inp-area fontReg font-size03  ${commentShow  ?'' : 'd-none'} `}
                  placeholder="กรุณาใส่เหตุผลในการไม่อนุมัติ"
                  value={inputForm.inp7}
                  onChange={(e) => setinput("inp7", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button className="btn-cancel" onClick={() => {cancelData()}}>ยกเลิก</button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button className="btn-save">บันทึก</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addapprovecampaign