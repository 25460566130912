import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import React, { useEffect, useState, useRef } from "react";
import "../addedits/Addeditcompetschedule.css";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getMatchStatById, updateMatchStat } from "../../api/matchstat_api";
import { geteAthlete } from "../../api/athlete_api";
import { getSubArena } from "../../api/subarena_api";
import { getSubSport, getSubSportFind } from "../../api/subsport_api";
import { getSport } from "../../api/sport_api";
import { getAffliation } from "../../api/affliation_api";
import moment from "moment";
import Loading from "../pages/Loading";
import { logDOM } from "@testing-library/react";
function EditcompetresultsSolo() {
  let id = new URLSearchParams(window.location.search).get("id");
  const [getDtbyID, setGetDtbyID] = useState([]);
  const [dtSport, setDtsport] = useState([]);
  const [dtArena, setDtArena] = useState([]);
  const [dtSub, setDtSubsp] = useState([]);
  const [dtAff, setDtAff] = useState([]);
  const [isLoading, setLoading] = useState(null);
  // getDtbyID.affiliationMatch[0].affliationName
  const [getDtArena, setGetDtArena] = useState([]);
  // athletes
  const [getDtAthletes, setGetDtathletes] = useState([]);
  const enableButton = {
    view: false,
    edit: true,
    delete: false,
    add: false,
    approve: false,
  };
  let [inputForm, setInputForm] = useState({
    inp1: "กีฑา", //ชนิดกีฬา
    inp2: "หญิงเดี่ยว", //ประเภทการแข่งขัน
    inp3: "สนามกีฬา 1", //สนามกีฬา
    inp4: "หญิงเดี่ยว4x100เมตร", //ชื่อรายการแข่งขัน
    inp5: "รอบชิงชนะเลิศ", //รอบการแข่งขัน
    inp6: "เดี่ยว", //ประเภท
    inp7: "20/05/2023", //วันที่แข่ง
    inp8: "1:30 PM", //เวลาที่แข่ง
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }
  const submitData = async () => {
    let dtArr = [];
    let dataArr = [];
    for (let i = 0; i < competSchedule.length; i++) {
      // dataArr = [...competSchedule[i].tabaledata];
      for (let j = 0; j < competSchedule[i].tabaledata.length; j++) {
        dataArr.push({
          affliationId:
            competSchedule[i].tabaledata[j].affiliation.affliationID,
          no: competSchedule[i].tabaledata[j].rating.value,
          record: competSchedule[i].tabaledata[j].statistics.value,
          remark: competSchedule[i].tabaledata[j].remark.value,
          athlete: [{ athletesId: competSchedule[i].tabaledata[j].id.value }],
        });
      }
    }

    for (let i = 0; i < getDtAthletes.length; i++) {
      dtArr.push({
        affliationId: getDtAthletes[i].affiliation.affliationID,
        no: getDtAthletes[i].rating.value,
        record: getDtAthletes[i].statistics.value,
        remark: getDtAthletes[i].remark.value,
        athlete: [{ athletesId: getDtAthletes[i].id.value }],
      });
    }

    let spDta = dtSport.filter((item) => item.text === getDtbyID.sportName);
    let filterAff = dtAff.filter((item) => item.text === getDtArena);

    let filterSub = dtSub.filter(
      (item) => item.text === getDtbyID.subsportName
    );

    let dt = {
      matchName: getDtbyID.matchName,
      startTime: getDtbyID.startTime,
      endTime: getDtbyID.endTime,
      sportId: spDta.length === 0 ? "-" : spDta[0].value,
      subsportId: filterSub.length > 0 ? filterSub[0].value : "-",
      // subarenaId: filterAff[0].value || "-",
      competitionType: getDtbyID.competitionType,
      stat: "",
      affliations: dataArr,
    };

    Swal.fire({
      title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          // const respon = null;
          const respon = await updateMatchStat(id, dt);
          setLoading(false);
          if (respon.data.status === "success") {
            await Swal.fire({
              title: "แก้ไขข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then((result) => {
              if (result.isConfirmed) {
                navigateToBack("/competitionResults");
              }
            });
          }
        })();
      }
    });
  };
  let competCount = useRef(1);
  const [competSchedule, setCompetSchedule] = useState([
    {
      group: competCount.current,
      formdata: {
        inp1: inputForm.inp1,
        inp2: inputForm.inp2,
        inp3: inputForm.inp3,
        inp4: inputForm.inp4,
        inp5: inputForm.inp5,
        inp6: inputForm.inp6,
        inp7: inputForm.inp7,
        inp8: inputForm.inp8,
        inp9: inputForm.inp9,
      },
      //tabledata:{datas:[],columns:[]}
      tabaledata: [], //add ข้อมูลรูปแบบเดียวกันกับ rowsdata ตรงนี้เพื่อให้แสดงผลในตาราง
      tableselected: [],
    },
  ]);

  const getDtAffliation = async () => {
    const respon = await getAffliation();
    let dtAff = [];
    respon.data.data.forEach((item, i) => {
      if (item.affliationName !== null) {
        return dtAff.push({
          value: item.affliationID,
          text: item.affliationName,
        });
      }
    });
    setDtAff(dtAff);
  };
  const getMatchStatID = async (Id) => {
    const respon = await getMatchStatById(Id);
    setGetDtbyID(respon.data.data);
    let dtAth;
    if (respon.data.data.affiliationMatch.length > 0) {
      setGetDtArena(respon.data.data.affiliationMatch[0].affliationName);
      dtAth = respon.data.data.affiliationMatch[0].athletes;
    } else {
      setGetDtArena("-");

      dtAth = "";
    }

    let getData = [];
    let objectData = respon.data.data.affiliationMatch;

    let couter = 0;
    // let objectData = respon.data.data.affiliationMatch;
    // for (let i = 0; i < objectData.length; i++) {
    //   let tabaledt = {
    //     group: i,
    //     tabaledata: [],
    //   };

    //   for (let j = 0; j < objectData[i].athletes.length; j++) {
    //     getData.push({
    //       id: {
    //         value: objectData[i].athletes[j].atheleteID,
    //         type: "text",
    //         dataID: objectData[i].athletes[j].atheleteID,
    //       },
    //       name: {
    //         value:
    //           objectData[i].athletes[j].firstname +
    //           " " +
    //           objectData[i].athletes[j].lastname,
    //         type: "text",
    //       },
    //       idCard: {
    //         value: objectData[i].athletes[j].idcard,
    //         type: "text",
    //       },
    //       affiliation: {
    //         value: objectData[i].affliationName,
    //         affliationID: objectData[i].affliationID,
    //         type: "text",
    //       },
    //       statistics: {
    //         value: objectData[i].athletes[j].record,
    //         type: "text",
    //         isinput: true,
    //       },
    //       remark: {
    //         value: objectData[i].athletes[j].remark,
    //         type: "text",
    //         isinput: true,
    //       },
    //       rating: {
    //         value: objectData[i].athletes[j].no,
    //         type: "text",
    //         isinput: true,
    //       },
    //       updateddate: {
    //         value:
    //           respon.data.data.updatedAt === null
    //             ? moment(new Date(respon.data.data.createdAt)).format(
    //                 "YYYY-MM-DD"
    //               )
    //             : moment(new Date(respon.data.data.updatedAt)).format(
    //                 "YYYY-MM-DD"
    //               ),
    //         type: "text",
    //       },
    //       action: { value: "1", type: "action" },
    //     });
    //   }
    // }
    //  competSchedule[0].tabaledata
    // competSchedule
    //  group: competCount.current,

    for (let i = 0; i < objectData.length; i++) {
      let tabaledata = {
        group: i,
        tabaledata: [],
      };
      let test = [
        "No",
        "ชื่อ",
        "สังกัด",
        "สถิติ",
        "หมายเหตุ",
        "อันดับ",
        "อัปเดตล่าสุด",
        "เครื่องมือ",
      ];
      for (let j = 0; j < objectData[i].athletes.length; j++) {
        couter++;
        tabaledata.tabaledata.push({
          id: {
            value: objectData[i].athletes[j].atheleteID,
            type: "text",
            dataID: objectData[i].athletes[j].atheleteID,
          },
          name: {
            value:
              objectData[i].athletes[j].firstname +
              " " +
              objectData[i].athletes[j].lastname,
            type: "text",
          },
          idCard: {
            value: objectData[i].athletes[j].idcard,
            type: "text",
          },
          affiliation: {
            value: objectData[i].affliationName,
            affliationID: objectData[i].affliationID,
            type: "text",
          },
          statistics: {
            value: objectData[i].athletes[j].record,
            type: "text",
            isinput: true,
          },
          remark: {
            value: objectData[i].athletes[j].remark,
            type: "text",
            isinput: true,
          },
          rating: {
            value: objectData[i].athletes[j].no,
            type: "text",
            isinput: true,
          },
          updateddate: {
            value:
              respon.data.data.updatedAt === null
                ? moment(new Date(respon.data.data.createdAt)).format(
                    "YYYY-MM-DD"
                  )
                : moment(new Date(respon.data.data.updatedAt)).format(
                    "YYYY-MM-DD"
                  ),
            type: "text",
          },
          // action: { value: "1", type: "action" },
        });
      }
      if (i == 0) {
        competSchedule[0] = tabaledata;
      } else {
        competSchedule.push(tabaledata);
      }
    }
    // setGetDtathletes(getData);
  };
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/competitionResults");
      }
    });
  }

  const columnsname = [
    "id",
    "name",
    "affiliation",
    "statistics",
    "remark",
    "rating",
    "updateddate",
    // "action",
  ];
  const columnsText = [
    "No",
    "ชื่อ",
    "สังกัด",
    "สถิติ",
    "หมายเหตุ",
    "อันดับ",
    "อัปเดตล่าสุด",
    // "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "input",
    "text",
    "text",
    // "action",
  ];
  const rowsdata = [
    {
      id: { value: "1", type: "text" },
      name: { value: "นางสาวเทควันโด ดีงาม", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      statistics: { value: "test ", type: "text", isinput: true },
      remark: { value: "ทดสอบ", type: "text", isinput: true },
      rating: { value: "1", type: "text", isinput: true },
      updateddate: { value: "7/5/2022", type: "text" },
      action: { value: "1", type: "action" },
    },
    {
      id: { value: "2", type: "text" },
      name: { value: "นายบาสบอล สุดเขต", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      statistics: { value: "test ", type: "text", isinput: false },
      remark: { value: "ทดสอบ", type: "text", isinput: false },
      rating: { value: "2", type: "text", isinput: false },
      updateddate: { value: "7/5/2022", type: "text" },
      action: { value: "1", type: "action" },
    },
  ];
  useEffect(() => {
    getDtAffliation();
    getArea();
    getDataSport();
    getSubsport();
    getMatchStatID(id);
  }, []);
  const getSubsport = async () => {
    const respon = await getSubSport();
    let dtSub = [];
    respon.data.data.forEach((item, i) => {
      if (item.subSportName !== null) {
        return dtSub.push({
          value: item.subSportID,
          text: item.subSportName,
        });
      }
    });
    setDtSubsp(dtSub);
  };
  const getArea = async () => {
    let dt = [];
    const respon = await getSubArena();
    respon.data.data.forEach((item, i) => {
      return dt.push({
        value: item.subArenaID,
        text: item.subArenaName,
      });
    });
    setDtArena(dt);
  };
  const getDataSport = async () => {
    const respon = await getSport();
    let getData = [];
    respon.data.data.forEach((item, i) => {
      return getData.push({
        value: item.sportID,
        text: item.sportName,
      });
    });
    setDtsport(getData);

    // setDtsport
  };
  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ผลการแข่งขัน"} />
      <Menu parentmenu={"competitionData"} activemenu={"competitionResults"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-12 mt-2">
              <img
                src="assets/icons/im-competition.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ผลการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการแข่งขัน / ผลการแข่งขัน
              </font>
            </div>
          </div>
          <div className="card-white">
            {getDtbyID ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลการแข่งขัน
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                    <input
                      id="inputSportType"
                      className="inp-data01 fontReg font-size03"
                      value={getDtbyID.sportName}
                      onChange={(e) => setinput("inp1", e.target.value)}
                      disabled
                    />
                  </div>
                  <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                    <input
                      id="inputSportSubType"
                      className="inp-data01 fontReg font-size03"
                      value={getDtbyID.subsportName}
                      onChange={(e) => setinput("inp2", e.target.value)}
                      disabled
                    />
                  </div>
                  <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                    <input
                      id="inputArena"
                      className="inp-data01 fontReg font-size03"
                      value={
                        getDtbyID.subArena
                          ? getDtbyID.subArena.subArenaName
                          : "-"
                      }
                      onChange={(e) => setinput("inp3", e.target.value)}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                    <input
                      id="inputSportType"
                      className="inp-data01 fontReg font-size03"
                      value={getDtbyID.matchName ? getDtbyID.matchName : "-"}
                      onChange={(e) => setinput("inp4", e.target.value)}
                      disabled
                    />
                  </div>
                  <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                    <input
                      id="inputSportSubType"
                      className="inp-data01 fontReg font-size03"
                      value={getDtbyID.record ? getDtbyID.record : "-"}
                      onChange={(e) => setinput("inp5", e.target.value)}
                      disabled
                    />
                  </div>
                  <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                    <input
                      id="inputArena"
                      className="inp-data01 fontReg font-size03"
                      value={
                        getDtbyID.competitionType === "single"
                          ? "เดี่ยว"
                          : "ทีม"
                      }
                      onChange={(e) => setinput("inp6", e.target.value)}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                    <input
                      id="inputSportType"
                      className="inp-data01 fontReg font-size03"
                      value={
                        "(" +
                        moment(new Date(getDtbyID.startTime)).format(
                          "DD/MM/YYYY"
                        ) +
                        // " - " +
                        // moment(new Date(getDtbyID.endTime)).format(
                        //   "DD/MM/YYYY"
                        // ) +
                        ")"
                      }
                      onChange={(e) => setinput("inp7", e.target.value)}
                      disabled
                    />
                  </div>
                  <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                    <input
                      id="inputSportSubType"
                      className="inp-data01 fontReg font-size03"
                      value={
                        "(" +
                        moment(new Date(getDtbyID.startTime)).format(
                          "HH:mm:ss"
                        ) +
                        " - " +
                        moment(new Date(getDtbyID.endTime)).format("HH:mm:ss") +
                        ")"
                      }
                      onChange={(e) => setinput("inp8", e.target.value)}
                      disabled
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {competSchedule.map((data, index) => {
            return (
              <div className="card-white mt-3" key={data.group}>
                <div className="row">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      รายการนักกีฬา
                    </p>
                  </div>
                </div>

                <TableData
                  actionsBtn={enableButton}
                  columnsname={columnsname}
                  columnsText={columnsText}
                  columnsType={columnsType}
                  rowsdata={data.tabaledata}
                  isSearchEnable={false}
                />
              </div>
            );
          })}
          {/* <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการนักกีฬา
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={getDtAthletes}
              isSearchEnable={false}
            />
          </div> */}
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-save"
                onClick={() => {
                  submitData();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditcompetresultsSolo;
