import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UploadFileXLS from "../uploads/UploadFileXLS";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownArenas } from "../dataMockup/DataMockDropdown";
import Select from "react-select";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import Loading from "../pages/Loading";
import {
  getStaffList,
  getStaffById,
  removeStaff,
  getDataStaffFilter,
} from "../../api/staff_api";

import { getSubArenaId, update } from "../../api/subarena_api";

import {
  dataObject,
  getDataAreaByID,
  postDataUpdateArea,
  postCreateSubarena,
  postDataUpdateSubArea,
  postCreateArea,
} from "../config/configApi-Area";
function Editarenasub() {
  const [isLoading, setLoading] = useState(null);
  const [lnSub, setlnSub] = useState(null);
  let chData = true;
  const [arenaSubList, setArenaSubList] = useState([
    { arenaSub: "", status: true },
  ]);
  // test select
  // Initialize state for selected options
  const [selectedOptionsTest1, setSelectedOptionsTest1] = useState([]);
  const [selectedOptions11, setSelectedOptions11] = useState([
    // Initialize with your default data
    // { value: "option1", label: "Option 1" },
    // { value: "option2", label: "Option 2" },
  ]);
  console.log("selectedOptions11 data preview test test : ", selectedOptions11);

  const [options11, setOptions11] = useState([
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ]);

  const handleSelectChange11 = (selectedValues) => {
    setSelectedOptions11(selectedValues);
  };

  // Handle selection change
  const handleSelectChangeTest = (selectedValues) => {
    setSelectedOptionsTest1(selectedValues);
  };
  // ----------
  const [dataStaff, setDatastaff] = useState([]);
  const [dataActive, setActive] = useState([]);
  const [subArenaName, setSubArenaName] = useState(null);
  let id = new URLSearchParams(window.location.search).get("id");
  // before
  const handleArenaSubChange = (e, index, id) => {
    const { name, value } = e.target;
    const list = [...arenaSubList];
    list[index]["subArenaId"] = id;
    list[index]["arenaSub"] = value;
    setArenaSubList(list);
  };
  // after
  const handleArenaSubChange1 = (
    option,
    indexArena,
    ArenaName,
    gate,
    type,
    id
  ) => {
    let newDataArena = [];
    for (let index = 0; index < option.length; index++) {
      if (option[index].label !== "กรุณาเลือกข้อมูล") {
        newDataArena.push({
          id: id,
          staffID: option[index].staffID,
          label: option[index].label,
          value: option[index].value,
          arenaSub: option[index],
          gate: gate,
          subArenaType: type,
          subArenaID: id,
        });
      }
    }
  };

  const getStaff = async () => {
    const rowData = [];
    try {
      const response = await getStaffList();

      if (response) {
        const dataStaff = response.data.data;
        let counter = 0;
        dataStaff.forEach((dt, i) => {
          counter++;
          return rowData.push({
            id: counter,
            value: dt.staffID,
            label: dt.firstname + " " + dt.lastanme,
            staffID: dt.staffID,
            active: true,
          });
        });
        // setDatastaff(rowData);

        getFilterdropdown(rowData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // test
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [dataSport, setdataSport] = useState([]);
  // const optionss = [
  //   { value: "a", label: "a" },
  //   { value: "b", label: "b" },
  // ];
  // test ---------
  const options = [
    { value: "item1", label: "Item 1", isMulti: true },
    { value: "item2", label: "Item 2", isMulti: false },
    { value: "item3", label: "Item 3", isMulti: true },
    // ... other options
  ];

  // State to store selected options
  const [selectedOptionsTest, setSelectedOptionsTest] = useState(
    { id: 1, objectName: "Obj1" }
    //   [
    //   { value: "item1", label: "Item 1", isMulti: true },
    // ]
  );
  // Handle the change event
  const handleSelectChange = (selected, { action, removedValue }) => {
    if (action === "remove") {
      // Item was deselected, use removedValue to determine the deselected item
      const deselectedIndex = options.findIndex(
        (option) => option.value === removedValue.value
      );
    }

    setSelectedOptionsTest(selected);
  };
  // end test --------------
  const handleChangeStaff = (
    option,
    indexArena,
    ArenaName,
    gate,
    type,
    id,
    status
  ) => {
    let newDataArena = [];
    let listData = [...arenaSubList];
    for (let i = 0; i < listData.length; i++) {
      if (indexArena === i) {
        for (let j = 0; j < option.length; j++) {
          if (option[j].active) {
            listData[indexArena].label.push({
              label: option[j].label,
              value: option[j].value,
            });
            listData[indexArena].staffID.push(option[j].value);
            listData[indexArena].value.push(option[j].value);
          }
        }
      }
    }
    console.log("listData data : ", listData);
    for (let index = 0; index < option.length; index++) {
      if (option[index].label !== "กรุณาเลือกข้อมูล") {
        // newDataArena.push({
        //   id: id,
        //   staffID: option[index].staffID,
        //   label: option[index].label,
        //   value: option[index].value,
        //   arenaSub: ArenaName,
        //   gate: gate,
        //   subArenaType: type,
        //   subArenaID: id,
        //   status: status,
        // });
      }
    }

    setSelected(newDataArena);
    // setSelectedOptions(optionss);
  };

  const getFilterdropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.label, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setDatastaff(result);
  };

  useEffect(() => {
    getStaff();
    if (id) {
      getsubArena(id);
    }
  }, []);
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const handleArenaSubRemove = (index) => {
    const list = [...arenaSubList];
    list.splice(index, 1);
    setArenaSubList(list);
  };
  const postSubarena = async (event) => {
    let body = [];
    const filtered = arenaSubList.filter((person) => person.arenaSub === "");

    if (filtered.length > 0) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      for (let i = 0; i < arenaSubList.length; i++) {
        for (let j = 0; j < selected.length; j++) {
          body.push({
            arenaId: parseInt(id),
            subArenaName: arenaSubList[i].arenaSub,
            subArenaType: null,
            subArenaGate: null,
            subArenaId: arenaSubList[i].subArenaId,
            staffID: j === i ? selected[j].staffID : selected[j].staffID,
            status: true,
          });
        }
      }
      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          (async () => {
            const respon = await postDataUpdateSubArea(parseInt(id), body);

            if (respon.data.status === "success") {
              setLoading(false);
              navigate("/arenaSub");
            }
          })();
        }
      });
    }
  };
  // handleUserChange
  const handleArenaSubAdd = (index, chbox) => {
    const list = [...arenaSubList];
    // list[index]["status"] = index ? true : false;
    list[index]["status"] = !chbox.status;
    setArenaSubList(list);
    // if (index === 0) {
    //   chbox = true;
    // }
    // setArenaSubList([...arenaSubList, { arenaSub: "", status: false }]);
  };
  const handleArenaSubAddRow = (index, chbox) => {
    setArenaSubList([
      ...arenaSubList,
      [
        {
          arenaSub: "",
          gate: "กรุณาเลือกข้อมูล",
          id: null,
          label: "กรุณาเลือกข้อมูล",
          staffID: null,
          subArenaType: null,
          value: "กรุณาเลือกข้อมูล",
          status: true,
        },
      ],
    ]);
  };

  const [groupShow, setGroupShow] = useState(false);
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/arenaSub");
      }
    });
  }
  const [inputForm, setInputForm] = useState({
    inp1: 0, //สนามกีฬา
    inp2: [], //เจ้าหน้าที่
    inp3: [], //สังกัด
  });
  const [checked, setChecked] = useState(null);
  const [dataSubArea, setdataSubArea] = useState("");
  const [arena, setArena] = useState("");
  const [arenaId, setArenaId] = useState("");
  const [subArenaType, setSubArenaType] = useState("");
  const [subArenaGate, setSubArenaGate] = useState("");
  const getsubArena = async (id) => {
    try {
      const response = await getSubArenaId(id);
      if (response.status === 200) {
        const data = response.data.data;
        setChecked(data.active);
        let dtSubAreana = [];
        let dtStaff = [];
        let counter = 0;
        //  test data
        // "กรุณาเลือกข้อมูล"
        const newDataStaff = [];
        const apiData = response.data.data;
        const transformed = apiData.subArena.map((item, i) => ({
          id: item.subArenaID,
          staffID: item.staffInArena.map((staff) => staff.staffId),
          label: item.staffInArena.map((staff) => ({
            label: `${staff.firstName} ${staff.lastName}`,
            value: staff.staffId,
          })),
          arenaSub: item.subArenaName,
          value: item.staffInArena.map((staff) => staff.staffId),
          gate: item.gate,
          subArenaType: item.subArenaType,
          status: item.active,
        }));
        let dataArr = [];
        let counterIndex = 0;
        // console.log(" apiData.subArena data  : ", apiData.subArena);
        for (let i = 0; i < apiData.subArena.length; i++) {
          counterIndex++;
          for (let j = 0; j < apiData.subArena[i].staffInArena.length; j++) {
            dataArr.push({
              value: apiData.subArena[i].staffInArena[j].staffId,
              label:
                apiData.subArena[i].staffInArena[j].firstName +
                " " +
                apiData.subArena[i].staffInArena[j].lastName,
            });
          }
        }

        const initialData = [
          {
            active: false,
            createdAt: "2023-09-12T06:18:47.433Z",
            deletedAt: null,
            gate: null,
            staffInArena: [
              { staffId: 7432, firstName: "สุวิท", lastName: "สำราญ" },
              { staffId: 7425, firstName: "ewwe222", lastName: "wewe" },
              { staffId: 7427, firstName: "สมโภช", lastName: "โชคชัยดี" },
            ],
          },
          {
            active: false,
            createdAt: "2023-09-12T06:18:47.433Z",
            deletedAt: null,
            gate: null,
            staffInArena: [
              { staffId: 7425, firstName: "ewwe222", lastName: "wewe" },
              { staffId: 7427, firstName: "สมโภช", lastName: "โชคชัยดี" },
            ],
          },
        ];
        const newDataAnswer = apiData.subArena.map((item) => {
          return item.staffInArena.map((staff) => ({
            label: `${staff.firstName} ${staff.lastName}`,
            value: staff.staffId,
          }));
        });
        setSelectedOptions11(newDataAnswer);
        console.log("newDataAnswer preview : ", newDataAnswer);
        // console.log(" apiData.subArena data : ", apiData.subArena);
        // const transformedArrayUser = apiData.subArena.map((item, i) => ({
        //   i: item.staffInArena.map((staff) => ({
        //     label: `${staff.firstName} ${staff.lastName}`,
        //     value: staff.staffId,
        //   })),
        // }));
        // console.log("transformedArrayUser preview  : ", transformedArrayUser);
        // setArenaSubList(newDataArena);
        // const [selectedOptions11, setSelectedOptions11] = useState([
        //   // Initialize with your default data
        //   { value: "option1", label: "Option 1" },
        //   { value: "option2", label: "Option 2" },
        // ]);

        console.log("transformed data : ", transformed);
        setArenaSubList(transformed);
        setdataSubArea(data.subArena[0].subArenaName);
        setArena(data.arenaName);
        setArenaId(data.arenaID);
        setSubArenaType(data.subArenaType);
        setSubArenaGate(data.gate);
        setSelected(newDataStaff);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updatesubArena = async () => {
    const dataNew = [
      {
        id: 607,
        staffID: [7431],
        label: [
          {
            label: "สุริน สำราญ",
            value: 7431,
          },
        ],
        arenaSub: "มหาวิทยาลัยราชภัฏนครสวรรค์ สนาม 4 สนามย่อย 1",
        value: [7431],
        gate: null,
        subArenaType: null,
        status: false,
      },
      {
        id: 609,
        staffID: [7431, 7431, 7432],
        label: [
          {
            label: "สุริน สำราญ",
            value: 7431,
          },
          {
            label: "สุริน สำราญ",
            value: 7431,
          },
          {
            label: "สุวิท สำราญ",
            value: 7432,
          },
        ],
        arenaSub: "มหาวิทยาลัยราชภัฏนครสวรรค์ สนาม 4 สนามย่อย 3",
        value: [7431, 7431, 7432],
        gate: null,
        subArenaType: null,
        status: false,
      },
    ];

    let foundData = [];

    // for (const key in arenaSubList) {
    //   if (arenaSubList.hasOwnProperty(key)) {
    //     const dataArray = arenaSubList[key];
    //     const matchingItems = dataArray.filter(
    //       (item) => item.label === "กรุณาเลือกข้อมูล"
    //     );
    //     foundData.push(...matchingItems);
    //   }
    // }
    const filteredData = arenaSubList.filter((item) => item.label.length === 0);
    console.log("filteredData data : ", filteredData);

    try {
      const staffID = selected.map((item) => {
        return item.value;
      });

      let dataSubarena = {
        subarena: [],
      };

      const subArenaMap = new Map();

      if (filteredData.length > 0) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          icon: "warning",
          reverseButtons: true,
        }).then((result) => {});
      } else {
        // for (const subArray of arenaSubList) {
        //   console.log("subArray data : ", subArray);
        //   for (const item of subArray) {
        //     console.log("item : ", item);
        //     const { id, staffID, arenaSub, gate, subArenaType, status } = item;
        //     console.log("id : ", id);
        //     if (!subArenaMap.has(id)) {
        //       subArenaMap.set(id, {
        //         subArenaId: id,
        //         subArenaName: arenaSub,
        //         subArenaType: subArenaType,
        //         subArenaGate: gate,
        //         staffID: [staffID],
        //         active: status,
        //       });
        //     } else {
        //       subArenaMap.get(id).staffID.push(staffID);
        //     }
        //   }
        // }

        // dataSubarena.subarena = Array.from(subArenaMap.values());
        const transformedData = arenaSubList.map((item) => ({
          subArenaId: item.id,
          subArenaName: item.arenaSub,
          subArenaType: item.subArenaType,
          subArenaGate: item.gate,
          staffID: [...new Set(item.staffID)], // Remove duplicates from staffID
        }));
        let dataSubarena = {
          // arenaId: arenaId,
          subarena: transformedData,
        };

        const data = {
          arenaId: arenaId,
          subArenaName: dataSubArea,
          subArenaType: subArenaType,
          subArenaGate: subArenaGate,
          staffID: staffID,
          active: checked,
        };
        await Swal.fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              // const respon = null;
              const respon = await update(id, dataSubarena);
              // const respon = await postDataUpdateArea(id, body);
              // setLoading(1);
              if (respon.data.status === "success") {
                Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ยืนยัน",
                }).then((result) => {
                  if (result.isConfirmed) {
                    // setLoading(1);
                    navigate("/arenaSub");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/arenaSub");
                  }
                });
              }
            })();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"สนามกีฬาย่อย"} />
      <Menu parentmenu={"masterData"} activemenu={"arenaSub"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                สนามกีฬาย่อย
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / สนามกีฬาย่อย
              </font>
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg-6">
                <input
                  id="inputStoreName"
                  className="inp-data01 fontReg font-size03"
                  disabled
                  value={arena}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>สนามกีฬาย่อย
                </p>
              </div>
            </div>
            <div className="row justify-content-start fontReg font-size03">
              <div className="col-12">
                <form className="App" autoComplete="off">
                  <div className="form-field">
                    <div>
                      <div>
                        <h3>Selected Options:</h3>
                        <ul>
                          {selectedOptions11.map((option) => (
                            <li key={option.value}>{option.label}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div>
                        Selected Options:
                        <ul>
                          {selectedOptionsTest1.map((option) => (
                            <li key={option.value}>{option.label}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {arenaSubList.map((singleArenaSub, index) => (
                      <div key={index} className="sports mt-3">
                        <div className="first-division w-100">
                          <div className="row w-100 m-0">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-6">
                                  <input
                                    name="arenaSub"
                                    type="text"
                                    id="arenaSub"
                                    className="inp-data02"
                                    placeholder="รายการสนามกีฬาย่อย"
                                    // value={arenaSubList[0][0].arenaName}
                                    // value={dataSubArea}
                                    defaultValue={
                                      singleArenaSub
                                        ? singleArenaSub.arenaSub
                                        : ""
                                    }
                                    onChange={(e) => {
                                      // setSubArenaName(e.target.value);
                                      // handleArenaSubChange(e, index);
                                      handleArenaSubChange(
                                        e,
                                        index,
                                        singleArenaSub?.id
                                      );
                                    }}
                                    // disabled
                                  />
                                </div>
                              </div>

                              <div className="row align-items-baseline">
                                <div className="col-6">
                                  <div className="p-0 mt-2">
                                    {/* </div>  */}
                                    <div className="selected-dpdw">
                                      <p className="fontReg font-size03 colorGray02 mb-1">
                                        เลือกเจ้าหน้าที่
                                      </p>
                                      <Select
                                        options={dataStaff}
                                        isMulti
                                        value={selectedOptions11[index]}
                                        onChange={(e)=>handleSelectChange11(e,index)}
                                      />

                                      {/* <Select
                                        isMulti
                                        name="colors"
                                        options={dataStaff}
                                        defaultValue={arenaSubList[index].label}
                                        setinput={setinput}
                                        inputname={"inp2"}
                                        onChange={(e) =>
                                          handleChangeStaff(
                                            e,
                                            index,
                                            singleArenaSub
                                              ? singleArenaSub.arenaSub
                                              : "",
                                            singleArenaSub?.gate,
                                            singleArenaSub?.subArenaType,
                                            singleArenaSub?.id,
                                            singleArenaSub?.status
                                          )
                                        }
                                        // onChange={(e) =>
                                        //   handleUserChange(dataStaff, index)
                                        // }
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                      /> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="second-division">
                                  {arenaSubList.length !== 1 && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleArenaSubRemove(index)
                                      }
                                      className="btn-remove-field"
                                    >
                                      <img
                                        src="assets/icons/remove-field.png"
                                        alt="ExportFiles Icon"
                                        className="ic-add mr-0"
                                      />
                                    </button>
                                  )}
                                </div>

                                <div className="third-division">
                                  {arenaSubList.length - 1 === index && (
                                    <button
                                      type="button"
                                      onClick={() => handleArenaSubAddRow()}
                                      className="btn-add-field"
                                    >
                                      <img
                                        src="assets/icons/add-field.png"
                                        alt="ExportFiles Icon"
                                        className="ic-add mr-0"
                                      />
                                    </button>
                                  )}
                                </div>

                                <div className="ml-2 ml-sm-3">
                                  <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center mb-0">
                                    ใช้งาน
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        singleArenaSub.length > 0
                                          ? singleArenaSub[0].status
                                          : ""
                                      }
                                      // defaultChecked={chData}
                                      // defaultChecked={
                                      //   singleArenaSub
                                      //     ? singleArenaSub[0].status
                                      //     : false
                                      // }
                                      onChange={() =>
                                        handleArenaSubAdd(index, singleArenaSub)
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save" onClick={updatesubArena}>
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          {/* <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg-6">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สนามกีฬา
                  </p>
                  <Dropdown
                    options={DataDropdownArenas}
                    setinput={setinput}
                    inputname={"inp1"}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/004-masterdata-sub_areana_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <UploadFileXLS limit={1} />
              </div>
              <div className="col-12 col-lg-3">
                <button className="btn-green01 fontReg font-size03 colorWhite">
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save">บันทึก</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Editarenasub;
