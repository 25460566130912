import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableData from "../Tables/TableData";
import "../CSS/Addedit.css";
import { getSleepcampById, updateSleepcamp } from "../../api/sleepcamp_api";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Loading from "./Loading";
function AddStation() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(null);
  const [station, setStation] = useState("โรงแรม");
  const [person, setPerson] = useState("Siraphoom.S	");
  const [callPerson, setCallPerson] = useState("099-23231234");
  let id = new URLSearchParams(window.location.search).get("id");
  const columnsname = ["id", "bedname", "count"];
  const columnsText = ["ลำดับ", "ชื่อเครื่องนอน", "จำนวน"];
  const columnsType = ["text", "text", "input"];
  const [dataAthe, setDataAthe] = useState([]);
  const [dataStaff, setDataStaff] = useState([]);
  const [dataTel, setDataTell] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const rowsdata = [
    // {
    //   id: { value: "1", type: "text" },
    //   bedname: { value: "หมอน", type: "text" },
    //   count: { value: "", type: "text", isinput: true },
    // },
  ];
  //
  const [numData, setNum] = useState([]);
  useEffect(() => {
    if (id) {
      getDataSleepcampById();
    }
  }, []);
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/BorrowStation");
      }
    });
  }
  const getDataSleepcampById = async () => {
    let data = {
      active: false,
      campID: 145,
      campName: "รร.เทศบาลวัดปากน้ำโพใต้ (ท.2)",
      coordinatorName: "นายวัชระ พังยะ",
      coordinatorTel: "-",
      createdAt: "2023-03-01T19:28:36.000Z",
      deletedAt: null,
      latitude: null,
      longitude: null,
      remark: "0",
    };
    const respon = await getSleepcampById(id);
    if (respon.data.status === "success") {
      let objectData = respon.data.data;
      setDataAthe(objectData.campName);
      setDataStaff(objectData.coordinatorName);
      setDataTell(objectData.coordinatorTel);
      objectData.sleeppack.forEach((object, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text", dataID: object.sleepPackID },
          bedname: {
            value: object.sleepPackName === null ? "-" : object.sleepPackName,
            type: "text",
          },
          count: {
            dataID: object.sleepPackID,
            value: object.sleepCampAmount || 0,
            type: "text",
            isinput: true,
            eventHandler: (ev) => setCheckInputState(ev),
          },
          //      checkboxAdd: { value: item.permissionMenuMobileID.toString(), type: "checkbox", eventHandler: (ev) => setCheckBoxState(ev) },
        });
      });

      setDataDetail(rowsdata);
    }
  };
  let [dataSt, setDataSt] = useState(rowsdata);
  let [dataArr, setDataArr] = useState(rowsdata);
  function setCheckInputState(ev) {
    setDataSt((prev) => {
      return {
        ...prev,
        id: ev.target.id,
        value: ev.target.value,
      };
    });
    setDataArr(dataSt);
  }
  // postSleeppack
  const postSleeppack = async () => {
    let dtArr = {
      campID: id,
      sleepPack: [],
    };
    for (let i = 0; i < dataDetail.length; i++) {
      dtArr.sleepPack.push({
        sleeppackId: parseInt(dataDetail[i].id.dataID),
        amount:
          dataDetail[i].count.value === null
            ? null
            : parseInt(dataDetail[i].count.value),
      });
    }
    Swal.fire({
      title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setLoading(true);
          const respon = await updateSleepcamp(id, dtArr);
          // setLoading(1);
          if (respon.data.status === "success") {
            setLoading(false);
            // if (response.data.status === "success") {
            Swal.fire({
              title: "แก้ไขข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: "ยืนยัน",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/BorrowStation");
              }
            });
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              showCancelButton: true,
              confirmButtonText: "ยืนยัน",
              cancelButtonText: `ยกเลิก`,
              reverseButtons: true,
            }).then((result) => {});
          }
          // }
        })();
      }
    });
  };
  return (
    <>
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"ข้อมูลการยืม-คืน"} />
      <Menu parentmenu={"borrowReturn"} activemenu={"BorrowStation"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-borrow.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ข้อมูลเครื่องนอนที่พัก
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ยืม-คืน ชุดเครื่องนอน / ข้อมูลเครื่องนอนที่พัก
              </font>
            </div>
          </div>
          <Col className="card-white">
            <Row className="mx-1">
              <Col xs={12} md={12} lg={12}>
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1">
                    {" "}
                    ข้อมูลที่พักนักกีฬา
                  </span>
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={3} xs={4} className="text-header text-start">
                ชื่อสถานที่พัก
              </Col>
              <Col lg={10} md={9} xs={8}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setStation(e.target.value);
                  }}
                  value={dataAthe}
                  disabled
                />
              </Col>
            </Row>{" "}
            <Row className="mt-3">
              <Col lg={2} md={3} xs={4} className="text-header text-start">
                ผู้ประสานงาน
              </Col>
              <Col lg={10} md={9} xs={8}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setPerson(e.target.value);
                  }}
                  value={dataStaff}
                  disabled
                />
              </Col>
            </Row>{" "}
            <Row className="mt-3">
              <Col lg={2} md={3} xs={4} className="text-header text-start">
                เบอร์โทรผู้ประสานงาน
              </Col>
              <Col lg={10} md={9} xs={8}>
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setCallPerson(e.target.value);
                  }}
                  value={dataTel}
                  disabled
                />
              </Col>
            </Row>
          </Col>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายละเอียดข้อมูลเครื่องนอน
                </p>
              </div>
            </div>
            <TableData
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataDetail}
              isSearchEnable={false}
            />
          </div>
          <Row className="my-5">
            <Col xs={2}></Col>
            <Col className="text-center" xs={4} md={4} lg={4}>
              <button
                className="btn-blue02"
                onClick={() => {
                  postSleeppack();
                }}
              >
                บันทึก
              </button>
            </Col>
            <Col className="text-center" xs={4} md={4} lg={4}>
              <button
                className="btn-cancle"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddStation;
