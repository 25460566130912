import React, { useState } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import UploadFilePDF from "../uploads/UploadFilePDF";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { createManual } from "../../api/manual_api";

function Addeditguide() {
  const [isLoading, setLoading] = useState(null);
  const navigate = useNavigate();

  function navigateToBack(page) {
    navigate(page);
  }
  const [dataNameguide, setNameguide] = useState(null);
  const [getFile, setgetFile] = useState(null);
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/guide");
      }
    });
  }
  const handleSelectedImages = (images) => {
    setgetFile(images);
    // setDataImage(mapOnlyFile)
  };
  // postCreateGuide
  const postCreateGuide = async () => {
    let body = {
      manualName: null,
      file: null,
    };
    if (
      dataNameguide !== "" &&
      dataNameguide !== null &&
      getFile[0] !== undefined
    ) {
      body.manualName = dataNameguide;
      body.file = getFile[0].file;

      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            const respon = await createManual(body);
            // setLoading(1);
            if (respon.data.status === "success") {
              Swal.fire({
                title: "บันทึกข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  setLoading(1);
                  navigate("/guide");
                }
              });
            }
          })();
        }
      });
    } else {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      });
    }
  };
  return (
    <>
      <Header parentmenu={"คู่มือการใช้งาน"} />
      <Menu parentmenu={"guide"} activemenu={"guide"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-12 mt-2">
              <img
                src="assets/icons/im-guide.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                คู่มือการใช้งาน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | คู่มือการใช้งาน
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>เพิ่มข้อมูลคู่มือ
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อคู่มือ"
                  onChange={(e) => {
                    setNameguide(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-5">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <UploadFilePDF
                  limit={1}
                  onSelectedImages={handleSelectedImages}
                  selectFiles={getFile}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-save"
                onClick={() => {
                  postCreateGuide();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditguide;
