import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
import UploadIMG from '../uploads/UploadIMG'
import { Link } from 'react-router-dom';
import UploadFileXLS from '../uploads/UploadFileXLS';

function EditShop() {

    const [shopName, setShopName] = useState('ชินจัง Shoping')
    const [shopCall, setShopCall] = useState('092-3234124')
    const [shopTime, setShopTime] = useState('08:00-19:00 น.')
    const [shopAddress, setShopAddress] = useState('เขตดินแดน แขวงดินแดน จังหวัดกรุงเทพมหานคร 74012')
    const [shopDetails, setShopDetails] = useState('ร้านอาหาร ขายของทั่วไป คาเฟ่')
    const [latitude, setLatitude] = useState('5 ํ 37 เหนือ กับ 20 ํ 27 เหนือ')
    const [longtitude, setLongitude] = useState('97 ํ 22 ตะวันออก กับ 105 ํ 37 ตะวันออก')
    const [shopImg, setShopImg] = useState(null)
    const [files, setFiles] = useState(null)

    return(
        <>
          <Header parentmenu={'จัดการข้อมูลร้านค้า'}/>
    <Menu parentmenu={'advertise'} activemenu={'recommendStore'}/>
    <div className="content-wrapper">
    <div className="container-fluid p-2 p-md-3">
      <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-pr.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประชาสัมพันธ์
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                |  ประชาสัมพันธ์ / จัดการข้อมูลร้านค้า แก้ไขข้อมูล 
              </font>
            </div>
          </div>
          
          <Col className='card-white'>
          <Row className='mx-1'>
              <Col xs={12} md={12} lg={12}> 
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> เพิ่มข้อมูลทีละรายการ</span>
              </p>
              </Col>
            </Row>
            <Row className='mt-3'>
                <Col lg={2} md={2} xs={4}className='text-header text-end'>ชื่อร้านค้า</Col>
                <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setShopName(e.target.value)}} value={shopName}/></Col>
          </Row>
          <Row className='mt-4'>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>เบอร์โทรศัพท์</Col>
            <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setShopCall(e.target.value)}} value={shopCall}/></Col>
        </Row>
        <Row className='mt-4'>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>เวลาทำการ</Col>
            <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setShopTime(e.target.value)}} value={shopTime}/></Col>
        </Row>
        <Row className='mt-4'>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>ที่อยู่ร้านค้า</Col>
            <Col lg={10} md={10} xs={8}><textarea className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setShopAddress(e.target.value)}} value={shopAddress}/></Col>
        </Row>
        <Row className='mt-4'>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>รายละเอียดร้านค้า</Col>
            <Col lg={10} md={10} xs={8}><textarea className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setShopDetails(e.target.value)}} value={shopDetails}/></Col>
        </Row>
        <Row className='mt-4' >
            <Col lg={2} md={2} xs={2}className='text-header text-end'>ละติจูต</Col>
            <Col lg={4} md={4} xs={4}><input onChange={(e) => {setLatitude(e.target.value)}} className='inp-data01 fontReg font-size03'
            value={latitude}/></Col>
            <Col lg={2} md={2} xs={2}className='text-header text-end'>ลองติจูต</Col>
            <Col lg={4} md={4} xs={4}><input onChange={(e) => {setLongitude(e.target.value)}} className='inp-data01 fontReg font-size03'
            value={longtitude}/></Col>
        </Row>
        <Row className='mx-3 mt-5'>
              <Col xs={4} md={2} lg={2} className='text-input text-end'>รูปภาพสถานที่ท่องเที่ยว</Col>
              <Col xs={8} md={10} lg={8} >
              <UploadIMG limit={1} />
              </Col>
            </Row>
          </Col>
          
          <Col className='card-white mt-4'>
          <Row className='mx-1'>
              <Col xs={12} md={12} lg={12}> 
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> เพิ่มข้อมูลทีละหลายรายการ</span>
              </p>
              </Col>
            </Row>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link as={Link} to="/files/Template-Form-Affiliation.xlsx" target="_blank" className="btn-blue01 fontReg font-size03 colorWhite p-3 d-inline-block h-auto" id="downloadBtn" value="download">
                  <span className="nav-icon icon icon-ic-import mr-2"></span>Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-5">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <UploadFileXLS limit={1} />
              </div>
            </div>
          </Col>
          <Row className='my-5'>
            <Col xs={2}></Col>
            <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-blue02'>ยืนยัน</button></Col>
            <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-cancle'>ยกเลิก</button></Col>
            <Col xs={2}></Col>
          </Row>
          </div>
          </div>
    <Footer/>
        </>
    )
}

export default EditShop