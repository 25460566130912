import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Button3items from "../button/Button3items";
import { useNavigate } from "react-router-dom";
import Dropdown from "../dropdowns/Dropdown";
import React, { useState, useEffect } from "react";
import moment from "moment";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import {
  DataDropdownStatus,
  // DataDropdownArenas,
} from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
// import {
//   getDataArea,
//   getDataAreaByID,
//   postDeletArea,
//   getDataAreaFilter,
//   getDataSubArea,
// } from "../config/configApi-Area";
import {
  getSubArena,
  subarenaFilter,
  getSubArenaId,
  getSubArenaSearch,
} from "../../api/subarena_api";
import { getAllArea } from "../config/configApi-Area";
import Loading from "./Loading";
function Masterarenasub() {
  const [DataSubArena, setDataSubArena] = useState([]);
  const [DataDropSubArena, setDataDropSubArena] = useState([]);
  const [DataDropArenas, setDataDropArenas] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [dtSub, setDtSubarena] = useState([]);
  const [subArenaName, setSubArenaName] = useState([]);
  const [subArena, setSubArena] = useState([]);
  const [subArenaStatus, setSubArenaStatus] = useState(null);
  // setSpanLoading
  const [spanloading, setSpanLoading] = useState([]);
  const setsubtype_data = (data) => {
    //console.log(data); // LOGS DATA FROM CHILD
    setSubTypes(data);
  };
  let subTypeoption = [];

  useEffect(() => {
    getArea();
    // setSubTypes();
    areaDropdown();
    // subarenaDropdown();
  }, []);

  const [inputForm, setInputForm] = useState({
    inp1: undefined, //ชนิดกีฬา
    inp2: undefined, //ประเภทการแข่งขัน
    inp3: undefined,
    // inp3: 0, //สถานะ
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }
  const getArea = async () => {
    setSpanLoading(true);
    // inputForm
    // const respon = await getDataSubArea();
    // const respon = await getDataArea();
    const respon = await getSubArena();
    let dataSub = [{ value: "", text: "ทั้งหมด" }];
    respon.data.data.forEach((item, i) => {
      if (item.subArenaName !== null) {
        return dataSub.push({
          value: item.arenaId,
          text: item.subArenaName,
          subType: [],
        });
      }
    });

    getFilterDropdownSub(dataSub);
    let dataps = [];
    const arr = respon.data.data.map((data, i) => {
      dataps.push({
        value: i + 1,
        text: data.subArenaName,
      });
      const obj = {
        arena: { value: data.arena ? data.arena : "-", type: "text" },
        arenaSub: { value: data.subArenaName, type: "text" },
        id: { value: i + 1, type: "text", dataID: data.arenaId },
        status: {
          value: data.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
          active: data.active,
          type: "text",
        },
        updateddate: {
          value:
            data.updatedAt !== null
              ? moment(new Date(data.updatedAt)).format("YYYY-MM-DD")
              : moment(new Date(data.createdAt)).format("YYYY-MM-DD"),
          type: "text",
        },
        action: { value: "1", type: "action" },
      };

      return obj;
    });
    await setSpanLoading(false);
    setDataSubArena(arr);
  };
  // getDataAreaByID
  const getDataAreaByID = async (id) => {
    const respon = await getSubArenaId(id);
    setSubArenaName(respon.data.data.arenaName);
    let obData = respon.data.data.subArena;
    let dataArr = [];
    for (let i = 0; i < obData.length; i++) {
      dataArr.push({
        label: obData[i].subArenaName,
      });
    }
    console.log("dataArr : ", dataArr);
    setSubArena(respon.data.data.subArenaName);
    setSubArenaStatus(
      respon.data.data.active === false ? "ไม่ใช้งาน" : "ใช้งาน"
    );
    getFilterSub(dataArr);
  };

  const getFilterSub = async (data) => {
    var dataArr = data.map((item) => {
      return [item.label, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    console.log("result data : ", result);
    setDtSubarena(result);
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditarenasub`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Editarenasub?id=${id}`);
        break;
      case "view":
        // handle view button
        getDataAreaByID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };
  // const getFilterdropdown = async (data) => {
  //   var dataArr = data.map((item) => {
  //     return [item.text, item];
  //   }); // creates array of array
  //   var maparr = new Map(dataArr); // create key value pair from array of array
  //   var result = [...maparr.values()]; //converting back to array from mapobject
  //   setDataDropSubArena(result);
  //   setDataDropSubArenaop(result);
  // };

  const enableButton = {
    view: true,
    edit: true,
    delete: false,
    add: false,
    approve: false,
  };

  const columnsname = [
    "id",
    "arena",
    "arenaSub",
    "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ชื่อสนามกีฬา",
    "สนามกีฬาย่อย",
    "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];
  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   arena: { value: "สนามกีฬาจังหวัดนครสวรรค์", type: "text" },
    //   arenaSub: { value: "สนามกีฬาจังหวัดนครสวรรค์1", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [valuefromparent3, setValuefromparent3] = useState("ทั้งหมด");

  function resetDropdown() {
    setSelecteArena("");
    setSelectesubArena("");
    setSelecteStatus("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setValuefromparent3("ทั้งหมด");
    getArea();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValuefromparent3(text) {
    setValuefromparent3(text);
  }

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ชื่อสนามกีฬา",
      "สนามกีฬาย่อย",
      "สถานะ",
      "อัปเดตล่าสุด",
    ];
    let Data = DataSubArena.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.arena.value;
      dt[2] = elemento.arenaSub.value;
      dt[3] = elemento.status.value;
      dt[4] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานสนามแข่งขันย่อย",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
      default:
    }
  };

  const getFilterDropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setDataDropArenas(result);
  };
  const getFilterDropdownSub = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    // setDataDropSubArena(result);
  };
  const areaDropdown = async () => {
    try {
      const response = await getAllArea();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.arenaID,
            text: item.arenaName,
            subType: [],
          });
        });
        getFilterDropdown(dataType);
        // let dataType = [{ value: "", text: "ทั้งหมด" }];
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subarenaDropdown = async () => {
    try {
      const response = await getSubArena();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.arenaId,
            text: item.subArenaName,
            subType: [],
          });
        });

        setDataDropSubArena(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedArena, setSelecteArena] = useState("");
  const [selectedsubArena, setSelectesubArena] = useState("");
  const [selectedStatus, setSelecteStatus] = useState("");
  const handleArenaChange = async (value) => {
    // getSubArenaSearch
    const response = await getSubArenaSearch(value);
    let dataType = [{ value: "", text: "ทั้งหมด" }];
    response.data.data.forEach((item, i) => {
      return dataType.push({
        value: item.arenaId,
        text: item.subArenaName,
        subType: [],
      });
    });

    setDataDropSubArena(dataType);
    setSelecteArena(value);
  };
  const handlesubArenaChange = (value) => {
    setSelectesubArena(value);
  };
  const handleStatusChange = (value) => {
    setSelecteStatus(value);
  };

  const getDataFilter = async () => {
    setSpanLoading(true);
    if (
      selectedArena === "" &&
      selectedsubArena === "" &&
      selectedStatus === ""
    ) {
      getArea();
    } else {
      let bodyFilter = {
        inp1: selectedsubArena !== "" ? "" : selectedArena,
        inp2: selectedsubArena,
        inp3: selectedStatus,
      };
      const response = await subarenaFilter(bodyFilter);
      response.data.data.forEach((data, i) => {
        return rowsdata.push({
          arena: { value: data.arena, type: "text" },
          arenaSub: { value: data.subArenaName, type: "text" },
          id: { value: i + 1, type: "text", dataID: data.subArenaID },
          status: {
            value: data.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            active: data.active,
            type: "text",
          },
          updateddate: {
            value: data.updatedAt
              ? moment(new Date(data.updatedAt)).format("YYYY-MM-DD")
              : "-",
            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      await setSpanLoading(false);
      setDataSubArena(rowsdata);
    }
  };

  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"สนามกีฬาย่อย"} />
      <Menu parentmenu={"masterData"} activemenu={"arenaSub"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                สนามกีฬาย่อย
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / สนามกีฬาย่อย
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName="/Addeditarenasub"
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-sm-5 pr-sm-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชื่อสนามกีฬา
                  </p>
                  <Dropdown
                    options={DataDropArenas}
                    func={setsubtype_data}
                    setinput={setinput}
                    inputname={"inp1"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleArenaChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-5 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สนามกีฬาย่อย
                  </p>
                  <Dropdown
                    options={DataDropSubArena}
                    setinput={setinput}
                    inputname={"inp2"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handlesubArenaChange}
                  />
                </div>
              </div>
              {/* <div className="col-12 col-sm-3 pr-sm-0 mt-sm-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สถานะ</p>
                  <Dropdown
                    options={DataDropdownStatus}
                    setinput={setinput}
                    inputname={"inp3"}
                    ChangesetValuefromparent={ChangesetValuefromparent3}
                    valuefromparent={valuefromparent3}
                    onChange={handleStatusChange}
                  />
                </div>
              </div> */}
              <div className="col-6 col-sm-auto col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    getDataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-auto col-xl-1 pr-sm-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการสนามกีฬาย่อย
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/Editarenasub"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={DataSubArena}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      ข้อมูลสนามกีฬา
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สนามกีฬา: {subArenaName}
                    </p>
                  </div>
                  {/* {dtSub.length > 0 ? ( */}
                  <div className="col-12 mt-2">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>
                      ข้อมูลสนามกีฬาย่อย
                    </p>
                  </div>
                  {/* ) : null} */}
                  <div className="col-12 ml-2">
                    {/* <p>สนามกีฬาย่อย : {subArena}</p> */}
                    {dtSub.map((data, i) => (
                      <p className="fontReg font-size03 colorGray01">
                        สนามกีฬาย่อย: {data.label}
                      </p>
                    ))}{" "}
                  </div>
                  <div className="col-12 ml-2">
                    <p>สถานะ : {subArenaStatus}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Masterarenasub;
