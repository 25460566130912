import React from 'react'
import './Thumbnail.css'

function Thumbnail(props) {
    const { file, removeFile } = props;
  return (
    <div className="thumb" >
      <div className="thumbInner">
        <img src={file.src} className="img" alt=""/>
      </div>
      <button className="remove-img" onClick={() => removeFile(file.id)}>
        <img
          src="assets/icons/ic-add.png"
          alt="ExportFiles Icon"
          className="ic-remove-img"
        />
      </button>
    </div>
  );
}

export default Thumbnail;