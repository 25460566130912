import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Table from "../Tables/TableBackOffice";
import TableBackOffice from "../Tables/TableAddBackOffice";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import "../pages/Mastersporttype.css";
import DataMockPermissions from "../dataMockup/DataPermission";
import DataMockAddBackOffice from "../dataMockup/DataMockAddBackOffice";
import Button3 from "../button/ButtonAdd";
import Loading from "./Loading";
import {
  getPermissionList,
  getPermissionId,
  removePermissionMenu,
  getPermissionMenu,
  getMobilePermission,
  getDataPermissionFilter,
} from "../../api/permission_api";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";

const mapPermissionConfig = {
  dashboard_main: "Dashboard หลัก",
  dashboard_votesport: "ผลโหวตกีฬาน่าสนใจ",
  dashboard_arenacheckin: "ปริมาณผู้เข้าใช้สนาม",
  dashboard_applicationusage: "จำนวนผู้ใช้แอปพลิเคชัน",
  master_sport: "ชนิดกีฬา",
  master_subsport: "ประเภทการแข่งขัน",
  master_arena: "สนามกีฬา",
  master_subarena: "สนามกีฬาย่อย",
  master_affliation: "สังกัด",
  master_camp: "ที่พักนักกีฬา",
  member_coach: "โค้ช",
  member_athletes: "นักกีฬา",
  member_general: "บุคคลทั่วไป",
  shop_acc_pending: "Account ที่รออนุมัติ",
  shop_acc: "รายชื่อร้านค้า ร้านอาหาร",
  shop_promotion_pending: "โปรโมชันนน ที่รออนุมัติ",
  promotion: "รายชื่อโปรโมชัน",
  staff: "เจ้าหน้าที่",
  member_delete: "บัญชีผู้ใช้ที่ถูกลบ",
  campaign_campaign: "แคมเปญ",
  campaign_history: "ประวัติการใช้แคมเปญ",
  pr_supportbanner: "จัดการแบนเนอร์ผู้สนับสนุน",
  pr_attraction: "จัดการข้อมูลท่องเที่ยวจังหวัด",
  busbooking: "ข้อมูลการจองรถรับ-ส่ง",
  match: "สถิติการแข่งขัน",
  promotion_history: "ประวัติการใช้โปรโมชัน",
  sleeppack: "ข้อมูลเครื่องนอน",
  sleeppack_borrow: "ข้อมูลการยืม-คืน",
  sleeppack_camp: "ข้อมูลเครื่องนอนที่พัก",
  notification: "รายการแจ้งเตือน",
  privacy: "นโยบายความเป็นส่วนตัว",
  policy: "ข้อกำหนดและเงื่อนไข",
  manual: "คู่มือการใช้งาน",
  backoffice_permission: "จัดการ Permission Back Office",
  mobile_permission: "จัดการ Permission Application",
  admin_list: "รายชื่อ Admin",
  theme_color: "จัดการ Theme Color",
  web_view_manage: "จัดการ Link Web View",
};

function BackOfficePermission() {
  const enableButton = { view: true, edit: true, delete: false, cancel: false };
  const columnsName = [
    "id",
    "PermissionId",
    "namePer",
    "typePer",
    "update",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "PermissionID",
    "ชื่อ Permission",
    "ประเภท Permission",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "text", "text", "text", "text", "action"];
  const columnsModal = ["id", "name"];
  const columnsTextHead = ["No", "เมนู"];
  const columnsTypeModal = ["text", "text"];
  const enableButtonModal = {
    view: false,
    edit: false,
    delete: false,
    cancel: false,
  };
  const [rowdata, setRowData] = useState([]);
  const [permisson, setPermisson] = useState([]);
  const [searchPermissionName, setSearchPermissionName] = useState(null);
  const [permissionPreviewName, setPermissionPreviewName] = useState(null);
  const [permissionMenuChecked, setPermissionMenuChecked] = useState([]);
  const [spanloading, setSpanLoading] = useState(false);
  // const [permissionPreviewTable, setPermissionPreviewTable] = useState({
  //   datas: [
  //     {
  //       id: 1,
  //       row: [
  //         { detail: "", type: "" },
  //         { detail: "", type: "text" },
  //       ],
  //     },
  //   ],
  //   columns: [{ title: "No" }, { title: "เมนู" }],
  // });
  const [permissionPreviewTable, setPermissionPreviewTable] = useState([]);

  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  function handleChange(value) {
    navigate(`${value}`);
    value = "";
  }
  let result = [];
  const getBackofficePermission = async () => {
    setSpanLoading(true);
    try {
      // const response = await getPermissionList()

      // if(response.status == 200){
      //   const permissionList = response.data.data

      //    result = permissionList.map((item,i) => {
      //     const permission = {
      //       id: { value: i + 1, dataID: item.permissionAccountId, type: "text" },
      //       namePer: {value: item.permissionName, type: "text"},
      //       typePer: {value:"ระบบจัดการหลังบ้าน", type: "text"},
      //       update: {value: item.updatedAt ? moment(item.updatedAt).format('DD-MM-YYYY') : moment(item.createdAt).format('DD-MM-YYYY'), type: "text"},
      //       action: {value:"1", type: "action"},
      //     }

      //     return permission
      //   })
      //   setRowData(result)
      // }

      // *****************************************
      const respon = await getPermissionList();
      await setSpanLoading(false);
      respon.data.data.forEach((objectData, i) => {
        return result.push({
          id: {
            value: i + 1,
            dataID: objectData.permissionAccountId,
            type: "text",
          },
          PermissionId: {
            value: objectData.permissionAccountId,
            type: "text",
          },
          namePer: {
            value: objectData.permissionName,
            type: "text",
          },
          typePer: {
            value: "ระบบจัดการหลังบ้าน",
            type: "text",
          },
          update: {
            value: objectData.updatedAt
              ? moment(objectData.updatedAt).format("YYYY-MM-DD")
              : moment(objectData.createdAt).format("YYYY-MM-DD"),
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });

      setRowData(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getBackofficePermissionById = async (id) => {
    try {
      getPermissionId(id).then((rs) => {
        if (rs) {
          const DataTablePreview = [];
          setPermissionPreviewName(rs.data.data.permissionName);
          // before
          // permissionPreviewTable
          // const objArr = rs.data.data.permission.map((item, i) => {
          //   const obj = {
          //     id: i + 1,
          //     row: [
          //       { detail: i + 1, type: "text" },
          //       { detail: mapPermissionConfig[item.name], type: "text" },
          //     ],
          //   };

          //   DataTablePreview.datas.push(obj);
          // });
          // after
          let counter = 0;
          rs.data.data.permission.map((item, i) => {
            counter++;
            const obj = {
              id: { value: counter, type: "text" },
              name: { value: mapPermissionConfig[item.name], type: "text" },
            };
            DataTablePreview.push(obj);
          });

          setPermissionPreviewTable(DataTablePreview);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const viewModal = async (id) => {
    try {
      const response = await getPermissionId(id);
      if (response.status == 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();
      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            setSpanLoading(true);
            const response = await removePermissionMenu(id);
            await setSpanLoading(false);
            if (response.status === 200) {
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
              getBackofficePermission();
            } else {
              swalWithBootstrapButtons.fire({
                title: "เกิดข้อผิดพลาด!",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/Addeditathlete`);
        break;
      case "edit":
        // handle edit button

        navigate(`/EditBackoffice?id=${id}`);
        break;
      case "view":
        // handle view button

        getBackofficePermissionById(id);
        // viewModal(id);

        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  useEffect(() => {
    getBackofficePermission();
  }, []);

  const getDataFilter = async () => {
    let respon;
    if (inputForm.name === "") {
      getPermissionList();
    } else {
      let DropdownForm = {
        name: inputForm.name,
      };
      setSpanLoading(true);
      respon = await getDataPermissionFilter(DropdownForm);
      await setSpanLoading(false);
      respon.data.data.forEach((objectData, i) => {
        return result.push({
          id: {
            value: i + 1,
            dataID: objectData.permissionAccountId,
            type: "text",
          },
          PermissionId: {
            value: objectData.permissionAccountId,
            type: "text",
          },
          namePer: { value: objectData.permissionName, type: "text" },
          typePer: { value: "ระบบจัดการหลังบ้าน", type: "text" },
          update: {
            value: objectData.updatedAt
              ? moment(objectData.updatedAt).format("YYYY-MM-DD")
              : moment(objectData.createdAt).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      setRowData(result);
    }
  };

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    name: "",
  });

  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.name = "";
    getBackofficePermission();
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  return (
    <>
      {spanloading === true ? <Loading /> : <></>}
      <Header parentmenu={"ตั้งค่าแอดมิน"} />
      <Menu parentmenu={"adminSetting"} activemenu={"BackOfficePermission"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-settingAdmin.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ตั้งค่าแอดมิน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ตั้งค่าแอดมิน / Permission Back Office
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/AddBackOffice"}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูลชื่อ Permission"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button
                  className="btn-blue01"
                  onClick={(e) => {
                    getDataFilter();
                  }}
                >
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการ Permission
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/AddRecommendatt"
              actionsBtn={enableButton}
              columnsname={columnsName}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowdata}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                id="exampleModalLabel"
              >
                ดูรายละเอียด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>Permission Back Office
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ชื่อ Permission: {permissionPreviewName}
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ประเภท Permission: ระบบจัดการหลังบ้าน
                </p>
              </div>
              <div className="row justify-content-start fontReg font-size03 mt-3">
                <div className="col-12">
                  {/* <TableBackOffice
                    item={permissionPreviewTable}
                    actionsBtn={enableButton}
                  /> */}
                  <TableData
                    pageAddEditName="/"
                    actionsBtn={enableButtonModal}
                    columnsname={columnsModal}
                    columnsText={columnsTextHead}
                    columnsType={columnsTypeModal}
                    rowsdata={permissionPreviewTable}
                    isSearchEnable={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BackOfficePermission;
