import React from 'react'
import Header from '../template/Header'
import Menu from '../template/Menu'
import Footer from '../template/Footer'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
import UploadIMG from '../uploads/UploadIMG'
import { useNavigate } from 'react-router-dom';

function EditAtt() {
    
    const [att, setAtt] = useState('วัดหนองแขม')
    const [detailAtt, setDetailatt] = useState('เป็นวัดในกรุงเทพ')
    const [latitude, setLatitude] = useState('5 ํ 37 เหนือ กับ 20 ํ 27 เหนือ')
    const [longtitude, setLongitude] = useState('97 ํ 22 ตะวันออก กับ 105 ํ 37 ตะวันออก')
    const [imgAtt, setImgAtt] = useState(null)
    const navigate = useNavigate()

    return (
        <>
          <Header parentmenu={'จัดการข้อมูลสถานที่ท่องเที่ยว'}/>
    <Menu parentmenu={'advertise'} activemenu={'recommendAtt'}/>
    <div className="content-wrapper">
    <div className="container-fluid p-2 p-md-3">
      <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-pr.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประชาสัมพันธ์
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                |  ประชาสัมพันธ์ / จัดการข้อมูลสถานที่ท่องเที่ยว แก้ไขข้อมูล 
              </font>
            </div>
          </div>
          
          <Col className='card-white'>
          <Row className='mx-1'>
              <Col xs={12} md={12} lg={12}> 
              <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> ข้อมูลสถานที่ท่องเที่ยว</span>
              </p>
              </Col>
            </Row>
            <Row className='mt-3'>
                <Col lg={2} md={2} xs={4}className='text-header text-end'>ชื่อสถานที่ท่องเที่ยว</Col>
                <Col lg={10} md={10} xs={8}><input className="inp-data01 fontReg font-size03"
                onChange={(e) => {setAtt(e.target.value)}} value={att} /></Col>
          </Row>
          <Row className='mt-4'>
            <Col lg={2} md={2} xs={4} className='text-header text-end'>รายละเอียดการท่องเที่ยว</Col>
            <Col lg={10} md={10} xs={8}><textarea className="inp-data01 fontReg font-size03" 
            onChange={(e) => {setDetailatt(e.target.value)}} value={detailAtt} /></Col>
        </Row>
        <Row className='mt-4' >
            <Col lg={2} md={2} xs={2}className='text-header text-end'>ละติจูต</Col>
            <Col lg={4} md={4} xs={4}><input onChange={(e) => {setLatitude(e.target.value)}} className='inp-data01 fontReg font-size03'
            value={latitude}  /></Col>
            <Col lg={2} md={2} xs={2}className='text-header text-end'>ลองติจูต</Col>
            <Col lg={4} md={4} xs={4}><input onChange={(e) => {setLongitude(e.target.value)}} className='inp-data01 fontReg font-size03'
            value={longtitude}  /></Col>
        </Row>
        <Row className='mx-3 mt-5'>
              <Col xs={4} md={2} lg={2} className='text-input text-end'>รูปภาพสถานที่ท่องเที่ยว</Col>
              <Col xs={8} md={10} lg={8} >
              <UploadIMG limit={1} value={imgAtt} />
              </Col>
            </Row>
          </Col>
          
          <Row className='my-5'>
            <Col xs={2}></Col>
            <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-blue02'>ยืนยัน</button></Col>
            <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-cancle'>ยกเลิก</button></Col>
            <Col xs={2}></Col>
          </Row>
          </div>
          </div>
    <Footer/>
        </>
    )
}

export default EditAtt