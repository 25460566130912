import React, { useEffect, useState } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import UploadIMG from "../uploads/UploadIMG";
import UploadIMGV2 from "../uploads/UploadIMGv2";
import { getAffliation } from "../../api/affliation_api";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import {
  DataDropdownAffiliation,
  DataDropdownPrefix,
  DataDropdownSexs,
} from "../dataMockup/DataMockDropdown";
import { Link, useNavigate } from "react-router-dom";
import UploadFileXLS from "../uploads/UploadFileXLS";
import Swal from "sweetalert2";
import { getGuestById, CreateGuest, UpdateGuest } from "../../api/guest_api";
import { verifyImport, submitImportData } from "../../api/coach_api";
import ImportExcel from "../uploads/ExcelRender";
import { disabled } from "glamor";
import { logDOM } from "@testing-library/react";

function Addeditcoach() {
  const [getDataimage, setDataImage] = useState([]);
  const [isLoading, setLoading] = useState(null);
  const [groupShow, setGroupShow] = useState(false);
  const [dataCoachID, setDataCoachID] = useState([]);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [idCard, setIdCard] = useState("");
  const [email, setEmail] = useState("");
  const [images, setImages] = useState([]);
  const [call, setCall] = useState("");
  const [social, setSocial] = useState("");
  const [gender, setGender] = useState(null);
  const [affliation, setAffiliation] = useState(null);
  const [register, setRegister] = useState("");
  const [title, setTitle] = useState(null);
  const [status, setStatus] = useState(false);
  const swalWithBootstrapButtons = Swal.mixin();

  let [inputForm, setInputForm] = useState({
    title: 0,
    gender: 0,
    affliation: 0,
    aff: 0,
  });

  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/coach");
      }
    });
  }

  const handleSelectedImages = (images) => {
    setImages(images);
  };

  let id = new URLSearchParams(window.location.search).get("id");
  const getCoachID = async () => {
    let getData = [];
    const response = await getGuestById(id);

    getData.push({
      oriId: response.data.data.idcard,
      id:
        response.data.data.idcard !== null || response.data.data.idcard !== ""
          ? response.data.data.idcard
          : //       `${response.data.data.idcard.slice(
            //           0,
            //           1
            //         )}-${response.data.data.idcard.slice(
            //           1,
            //           10
            //         )}-${response.data.data.idcard.slice(
            //           10,
            //           12
            //         )}-${response.data.data.idcard.slice(12, 13)}
            // `
            "-",
      // response.data.data.idcard,

      title: response.data.data.title !== null ? response.data.data.title : "-",
      firstname: response.data.data.firstname,
      lastname: response.data.data.lastname,
      tel:
        response.data.data.mobileNo === "" ||
        response.data.data.mobileNo === null
          ? "-"
          : `${response.data.data.mobileNo.slice(
              0,
              3
            )}-${response.data.data.mobileNo.slice(
              3,
              6
            )}-${response.data.data.mobileNo.slice(6, 10)}
  `,
      email: response.data.data.email !== null ? response.data.data.email : "-",
      affliation:
        response.data.data.affliation === null
          ? "-"
          : response.data.data.affliation,
      status: response.data.data.active === 1 ? true : false,
      registerType: response.data.data.registerType,
      gender:
        response.data.data.gender === null ? "-" : response.data.data.gender,
      img: [
        {
          id: 0,
          status: false,
          src:
            response.data.data.img === null || response.data.data.img === ""
              ? "assets/images/img-notimg.png"
              : response.data.data.img,
          file: "null",
        },
      ],
    });
    setStatus(getData[0].status);
    setSocial(getData[0].registerType || "-");
    setDataCoachID(getData[0]);
    setFirstname(getData[0].firstname);
    setAffiliation(getData[0].affliation);

    setLastname(getData[0].lastname);
    //  getData[0].tel.trim()

    setCall(getData[0].tel);
    setEmail(getData[0].email || "-");
    setIdCard(getData[0].id.trim());
    setGender(getData[0].gender);
    setTitle(getData[0].title);
    // setImages(getData[0].img);
    setDataImage(getData[0].img);
  };
  const getDataAffliation = async () => {
    // active
    let affType = [];
    const data2 = await getAffliation();
    data2.data.data.forEach((element, i) => {
      if (element.active === true) {
        return affType.push({
          value: i + 1,
          idAff: element.affliationID,
          text: element.affliationName,
        });
      }
    });
    getFilterDropdownAff(affType);
  };
  const [affliationDropdown, setAffirmationDropdown] = useState([]);
  const getFilterDropdownAff = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setAffirmationDropdown(result);
  };
  useEffect(() => {
    getDataAffliation();
    if (id) {
      getCoachID();
    }
  }, []);

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const createGuestData = async () => {
    let idAff = "";
    let bodyUpdate;
    let body;

    if (inputForm.aff) {
      let dataAff = affliationDropdown.filter((data, i) => {
        return data.value === inputForm.aff;
      });
      idAff = dataAff[0].idAff;
    }
    const DataSexs = [
      { value: 0, text: "หญิง" },
      { value: 1, text: "ชาย" },
      { value: 2, text: "อื่นๆ" },
    ];
    const filterGen = DataSexs.filter((data) => {
      return inputForm.gender === data.value;
    });
    const filterTitle = DataDropdownPrefix.filter((data) => {
      return inputForm.title === data.value;
    });

    if (!id) {
      body = {
        title: filterTitle.length > 0 ? filterTitle[0].text : null,
        firstname: firstname,
        lastname: lastname,
        mobileNo: call.replace(/[\s-]+/g, "").trim(),
        email: email,
        memberType: 2,
        gender: filterGen[0].text,
        file: images.length > 0 ? images[0].file : null,
        active: status === false ? 0 : 1,
        idcard: idCard.replace(/[\s-]+/g, "").trim(),
        affliation: idAff,
      };

      if (
        firstname === 0 ||
        lastname === 0 ||
        firstname === "" ||
        lastname === "" ||
        idCard === null ||
        idCard === "" ||
        // inputForm.gender == 0 ||
        // inputForm.gender == null ||
        // inputForm.gender == "" ||
        inputForm.aff == 0 ||
        inputForm.aff == null ||
        inputForm.aff == ""
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          (async () => {
            if (result.isConfirmed) {
              const response = await CreateGuest(body);
              if (response.data.status === "success") {
                Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigateToBack("/coach");
                  }
                });
              }
            } else {
              // cancelData();
            }
          })();
        });
      }
    } else {
      bodyUpdate = {
        lastname: lastname,
        firstname: firstname,
        gender: filterGen[0].text,
        mobileNo: call.replace(/[\s-]+/g, "").trim(),
        email: email,
        idcard: idCard.replace(/[\s-]+/g, "").trim(),
        title: inputForm.title === 0 ? title : filterTitle[0].text,
        file: images.length > 0 ? images[0].file : null,
        affliation: idAff,
        active: status === false ? 0 : 1,
      };
      console.log("bodyUpdate : ", bodyUpdate);
      if (
        bodyUpdate.firstname === "" ||
        bodyUpdate.lastname === "" ||
        bodyUpdate.idCard === "" ||
        idCard === "" ||
        images.length < 1 ||
        filterGen.length < 1
        // dataAff.length < 1
        // bodyUpdate.gender.length < 1 ||
        // bodyUpdate.affliation.length < 1
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        Swal.fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          (async () => {
            if (result.isConfirmed) {
              const response = await UpdateGuest(id, bodyUpdate);
              if (response.data.status === "success") {
                Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigateToBack("/coach");
                  }
                });
              }
            } else {
              // cancelData();
            }
          })();
        });
      }
      //
    }
    // }
  };

  // *********************** Take Import DATA ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);

  const handleImport = async (rows) => {
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);

    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          title: item[0],
          firstname: item[1],
          lastname: item[2],
          idcard: item[3],
          gender: item[4],
          affliation: item[5],
          mobileNo: item[6],
          email: item[7],
          registerBy: item[8],
          memberType: item[9],
          active: item[10],
        });
      }
    });
    await setImportData(row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          memberType: 2,
          importData: data,
        };
        const response = await verifyImport(dataToVerify);
        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      if (error === 0) {
        // Swal.fire({
        //   title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
        //   icon: "success",
        //   confirmButtonText: "ตกลง",
        // });
        if (failedArr.length > 0) {
          Swal.fire({
            title:
              "ตรวจสอบข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง ",
            icon: "error",
            confirmButtonText: "ยืนยัน",
          });
        } else {
          Swal.fire({
            title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "success",
            confirmButtonText: "ยืนยัน",
          });
        }
        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importSport = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length - 1);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              memberType: 2,
              importData: data,
            };
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      if (error === true) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/coach");
          }
        });
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/coach");
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/coach");
        }
      });
    }
  };

  // *********************** Take Import DATA ************************************

  return (
    <>
      {/* {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()} */}
      <Header parentmenu={"โค้ช"} />
      <Menu parentmenu={"athleteCoach"} activemenu={"coach"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-coach.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                โค้ช
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลนักกีฬาและโค้ช / โค้ช
              </font>
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg">
                <div className="drpdw-status h-100">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    คำนำหน้า
                  </p>
                  {/* <Dropdown options={DataDropdownPrefix} /> */}
                  <Dropdown
                    setinput={setinput}
                    id="title"
                    valuefromparent={title}
                    options={DataDropdownPrefix}
                    inputname={"title"}
                  />
                </div>
              </div>
              <div className="col-12 col-lg mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อจริง"
                  value={firstname}
                  onChange={(e) => {
                    setFirstname(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="นามสกุล"
                  value={lastname}
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="เลขบัตรประชาชน"
                  value={idCard}
                  onChange={(e) => {
                    setIdCard(e.target.value);
                  }}
                  maxLength={13}
                />
              </div>
            </div>
            <div className="row justify-content-start mt-2 mt-lg-5">
              <div className="col-12 col-lg-3">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">เพศ</p>
                  <Dropdown
                    setinput={setinput}
                    options={DataDropdownSexs}
                    inputname={"gender"}
                    valuefromparent={gender}
                    id="gender"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">สังกัด</p>
                  <Dropdown
                    valuefromparent={affliation}
                    options={affliationDropdown}
                    setinput={setinput}
                    inputname={"aff"}
                    // inputname={"affiliation"}
                    id="affiliation"
                  />
                </div>
              </div>
              <div className="col-12 col-lg pr-2 pr-lg-0 mt-5 mt-lg-0">
                {(() => {
                  if (getDataimage.length > 0) {
                    return (
                      <UploadIMGV2
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      ></UploadIMGV2>
                    );
                  } else {
                    return (
                      <UploadIMG
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={images}
                      />
                    );
                  }
                })()}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  ข้อมูลการสมัครผ่านแอปพลิเคชัน
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <input
                  disabled
                  // disabled={call}
                  className="inp-data01 fontReg font-size03"
                  placeholder="เบอร์โทร"
                  value={call}
                  onChange={(e) => {
                    setCall(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <input
                  disabled
                  // disabled={email}
                  className="inp-data01 fontReg font-size03"
                  placeholder="อีเมล"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                <input
                  disabled
                  // disabled={social}
                  className="inp-data01 fontReg font-size03"
                  placeholder="สมัครผ่าน"
                  value={social}
                  onChange={(e) => {
                    setRegister(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row ml-0 mt-3">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  value={status}
                  checked={status}
                  onChange={() => setStatus(!status)}
                  // onChange={() => handleCheckStatus()}
                  id="status"
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save" onClick={createGuestData}>
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/007-coach_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>

                {/* {verifySkip > 0 ? (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    แก้ไข ข้อมูลเดิม {verifySkip} รายการ
                  </p>
                ) : (
                  <></>
                )}
                {verifyNew > 0 ? (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    เพิ่มข้อมูลใหม่ {verifyNew} รายการ
                  </p>
                ) : (
                  <></>
                )} */}

                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  ข้อมูลทั้งหมด {verifySuccess}/{totalRow} รายการ
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0 ? true : false}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditcoach;
