import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
const dataToken = localStorage.getItem("token");
// sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //   baseURL: "http://127.0.0.1:3000",
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const verifyImport = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(`/member/verifyimport`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const submitImportData = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(`/member/importdata`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    // toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
    Swal.fire({
      title: "กรุณาตรวจสอบข้อมูล ไม่สามารถทำรายการได้",
      // text: error.response.data.message,
      icon: "error",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
      showCancelButton: true,
    });
  }
};
