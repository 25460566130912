import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
const URL = process.env.REACT_APP_BASE_URL;
// const dataToken = localStorage.getItem("token");

// const URL = process.env.REACT_APP_BASE_URL
const dataToken = localStorage.getItem("token");
const instace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

// export const createAdmin = async (data) => {
//   try {
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: dataToken,
//     };

//     const response = await instace.post(`/user`, {
//       headers: headers,
//       data: data,
//     });
//     if (response) {
//       return response;
//     } else {
//       return null;
//     }
//   } catch (error) {
//     toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
//   }
// };
export const createAdmin = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.post("/user", data, {
      headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response && response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    Swal.fire({
      title: "อีเมลของคุณถูกใช้งานไปแล้ว",
      // text: error.response.data.message,
      icon: "error",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
      showCancelButton: true,
    });
  }
};
export const getAdmin = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };

    const response = await instace.get(URL + `/user`, {
      headers: headers,
      params: {
        isadmin: true,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getAdminById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/user/${id}`, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const removeAdmin = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.delete(`/user/${id}`, { headers: headers });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const updateAdmin = async (id, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.put(`/user/${id}`, body, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getAdminFilter = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/user`, {
      headers: headers,
      params: {
        isadmin: true,
        name: data.inp1 === "" ? "" : data.inp1,
        permissionId: data.inp2 === "" ? "" : data.inp2,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
