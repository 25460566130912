import React, { useEffect, useRef } from 'react'
import $ from 'jquery'
import Buttonactions from '../button/ButtonRes'
import CheckBut from '../button/CheckBut';

function TableRes(props) {
  const {pageAddEditName,actionsBtn, checkBut} = props;
  console.log('actionsBtn tablejs')
  console.log(actionsBtn)
  console.log('item')
  const item = props.item
  console.log(item)
  $.DataTable = require('datatables.net-bs4')
  const tableRef = useRef()

  let itemHeaderList=[];
  let itemRows=[];
  
  item.columns.forEach((item,index) => {
    
    if(item.title === " " || item.title === "เครื่องมือ" || item.title === "บันทึกผล")
      itemHeaderList.push( <th className="no-sort" key={index}>{item.title}</th>)
    else
      itemHeaderList.push( <th key={index}>{item.title}</th>)
    
  })

  item.datas.forEach((data,index) => {
    let itemDetails = []
    // console.log(data)
    
    data.row.forEach((details,index) => {
      //console.log(detail)
      if(details.type === "image")
      {
        itemDetails.push(
          <td key={index}>
            <img
              src={details.detail}
              alt="Type Sport"
              className="im-sports-type"
            />
          </td>
        );
      }
      else if(details.type === "actions")
      {
        itemDetails.push(
          <td key={index}>
            <Buttonactions pageAddEditName={pageAddEditName} actionsBtn={actionsBtn} id={data.id}/>
          </td>
        );
      } 
      else if(details.type === "checkbox")
      {
        itemDetails.push(
          <td key={index}>
            <CheckBut actionsBtn={checkBut} id={data.id}/>
          </td>
        );
      }
      else 
        itemDetails.push( <td key={index}>{details.detail}</td> )
      
    })
    //console.log(itemDetails)
    itemRows.push( <tr key={index}>{itemDetails}</tr>)
  })


  useEffect(() => {
    
    const table = $(tableRef.current).DataTable({
      dom: 'rtip',
      columnDefs: [ {
        targets  : 'no-sort',
        orderable: false,
      }]
    })
    return function() {
        console.log("Table destroyed")
        table.destroy()
    }
},[])
 
  
return (
  <div>
    <table id="tb_sport_type" className="table fontReg font-size03 colorGray01" ref={tableRef}>
      <thead>
        <tr>{itemHeaderList}</tr>
      </thead>
      <tbody>{itemRows}</tbody>
    </table>
  </div>
);
}

export default TableRes