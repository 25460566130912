import React, { useState, useEffect } from "react";
import Button3items from "../button/Button3items";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownRegisBy } from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import moment from "moment";
import Swal from "sweetalert2";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { useNavigate } from "react-router-dom";
import { getShop, getDataShopApproveFilter } from "../../api/shop_api";
import { getDataUser } from "../config/configApi-User";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import Loading from "./Loading";
import { select } from "glamor";
function Shopapproveacc() {
  const [DataShopproveacc, setDataShopproveacc] = useState([]);
  const [spanloading, setspanLoading] = useState(false);
  const [inputForm, setInputForm] = useState({
    name: undefined,
    register: undefined,
    approveStatus: "waiting",
  });
  const rowsdata = [];

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const [isLoading, setLoading] = useState(null);

  const getDataFilter = async () => {
    setspanLoading(true);

    let respon;
    let rowsdata = [];
    if (inputForm.name === "" && selectedRegis === "") {
      await setspanLoading(false);
      getDataAppproveacc();
    } else {
      let bodyFilter = {
        inp1: inputForm.name,
        inp2: selectedRegis,
      };

      respon = await getDataShopApproveFilter(bodyFilter);

      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.memberID },
          name: {
            value: objectData.shopName === null ? "-" : objectData.shopName,
            type: "text",
          },
          tel: {
            value: objectData.mobileNo === null ? "-" : objectData.mobileNo,
            type: "text",
          },
          email: {
            value: objectData?.member?.email ? objectData?.member?.email : "-",
            type: "text",
          },
          register: {
            value:
              objectData?.member?.registerType?.registerTypeName === "web"
                ? "app"
                : objectData?.member?.registerType?.registerTypeName,
            type: "text",
          },
          status: {
            // value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            value: objectData.approveStatus == false ? "ไม่ใช้งาน" : "-",
            type: "text",
          },
          updateddate: {
            value: objectData.updatedAt
              ? moment(objectData.updatedAt).format("YYYY-MM-DD")
              : objectData.createAt
              ? moment(objectData.createAt).format("YYYY-MM-DD")
              : "-",
          },
          // updateddate: {
          //   value:
          //     objectData.updatedAt === null
          //       ? "-"
          //       : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

          //   type: "text",
          // },
          action: { value: "1", type: "action" },
        });
      });
      setspanLoading(false);
      setDataShopproveacc(rowsdata);
    }
  };

  const getDataAppproveacc = async () => {
    setspanLoading(true);
    try {
      let data = { approveStatus: "waiting" };
      const respon = await getShop(data);

      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.shopID },
          name: {
            value: objectData.shopName === null ? "-" : objectData.shopName,
            type: "text",
          },
          tel: {
            value: objectData.mobileNo === null ? "-" : objectData.mobileNo,
            type: "text",
          },
          email: {
            value: objectData?.member?.email ? objectData?.member?.email : "-",
            type: "text",
          },
          register: {
            value:
              objectData?.member?.registerType?.registerTypeName === "web"
                ? "app"
                : objectData?.member?.registerType?.registerTypeName,
            type: "text",
          },
          status: {
            // value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            value: objectData.approveStatus == false ? "รออนุมัติ" : "-",
            type: "text",
          },
          updateddate: {
            value: objectData.updatedAt
              ? moment(objectData.updatedAt).format("YYYY-MM-DD")
              : objectData.createAt
              ? moment(objectData.createAt).format("YYYY-MM-DD")
              : "-",
          },
          // updateddate: {
          //   value:
          //     objectData.updatedAt === null
          //       ? "-"
          //       : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

          //   type: "text",
          // },
          action: { value: "1", type: "action" },
        });
      });

      await setspanLoading(false);
      setDataShopproveacc(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getDataAppproveacc();
    // getShop(data);
    // setDataShopproveacc(rowsdata)
  }, []);
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        break;
      case "edit":
        // handle edit button
        break;
      case "view":
        // handle view button
        break;
      case "approve":
        // handle approve button
        navigate(`/Addapproveshop?id=${id}`);
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const handleExport = async (type) => {
    let Header = [
      "No",
      "ชื่อ",
      "เบอร์โทรศัพท์",
      "อีเมล",
      "สมัครโดย",
      "สถานะ",
      "อัปเดตล่าสุด",
    ];

    let Data = DataShopproveacc.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.name.value;
      dt[2] = elemento.tel.value;
      dt[3] = elemento.email.value;
      dt[4] = elemento.register.value;
      dt[5] = elemento.status.value;
      dt[6] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงาน Account ร้านค้า ร้านอาหารที่รออนุมัติ",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const enableButton = {
    view: false,
    edit: false,
    delete: false,
    add: false,
    approve: true,
  };

  const columnsname = [
    "id",
    "name",
    "tel",
    "email",
    "register",
    "status",
    "updateddate",
    "action",
  ];

  const columnsText = [
    "No",
    "ชื่อร้าน",
    "เบอร์โทรศัพท์",
    "อีเมล",
    "สมัครโดย",
    "สถานะ",
    "อัปเดตล่าสุด",
    "อนุมัติ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];
  const data = [
    // {
    //   id: { value: 1, type: "text" },
    //   name: { value: "นางสาวกันยา สุดสมาน", type: "text" },
    //   tel: { value: "0989876789", type: "text" },
    //   email: { value: "mail@mail.com", type: "text" },
    //   register: { value: "App", type: "text" },
    //   status: { value: "ใช้งาน", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];

  const DataDropdownRegis = [
    { value: "", text: "ทั้งหมด" },
    { value: "1", text: "App" },
    { value: "2", text: "Facebook" },
    { value: "3", text: "Google" },
    { value: "4", text: "Apple ID" },
    { value: "5", text: "Line" },
  ];

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [name, setName] = useState("");

  function resetDropdown() {
    setSelecteRegis("");
    setName("");
    inputForm.name = "";
    // selectedRegis("");
    setValuefromparent1("ทั้งหมด");
    getDataAppproveacc();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  const [selectedRegis, setSelecteRegis] = useState("");
  const handleRegisChange = (value) => {
    setSelecteRegis(value);
  };

  return (
    <>
      {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()}

      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"Account ที่รออนุมัติ"} />
      <Menu parentmenu={"shopData"} activemenu={"approveAcc"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-shop.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                Account ที่รออนุมัติ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลร้านค้า ร้านอาหาร / Account ที่รออนุมัติ
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-md-5 col-xl-4 pr-0">
                <input
                  onChange={onChangeInputName}
                  value={name}
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อร้าน"
                />
              </div>
              <div className="col-12 col-md-5 col-xl-3 pr-0 mt-2 mt-md-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สมัครโดย
                  </p>
                  <Dropdown
                    options={DataDropdownRegis}
                    setinput={setinput}
                    inputname={"register"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleRegisChange}
                  />
                </div>
              </div>
              <div className="col-6 col-md-1 pr-0 mt-md-0 mt-2">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-md-none d-xl-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-md-1 pr-0 mt-md-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-md-none d-xl-inline">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการ Account
                  ที่รออนุมัติ
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={DataShopproveacc}
              isSearchEnable={false}
              pageApproveName={"/Addapproveshop"}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Shopapproveacc;
