import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import TableData from "../Tables/TableData";
import Button3items from "../button/Button3items";
import { useNavigate } from "react-router";
import moment from "moment";
import Loading from "../pages/Loading";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import {
  getBanner,
  getBannerById,
  deleteBanner,
  createBanner,
  getBannerFilter,
} from "../../api/banner_api";
import Swal from "sweetalert2";
function Managebanner() {
  const enableButton = { view: true, edit: true, delete: true, cancel: false };
  const [banner, setBanner] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [getDataBannerID, setGetBannerID] = useState([]);
  const [getDataIMG, setGetIMG] = useState([]);
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }

  const columnsName = [
    "id",
    "image",
    "bannerName",
    "bannerStatus",
    "update",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "รูปแบนเนอร์",
    "ชื่อแบนเนอร์",
    "สถานะการใช้งาน",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = ["text", "image", "text", "text", "text", "action"];
  const rowsdata = [
    // {
    //   id: { value: "1", type: "text" },
    //   image: { value: "assets/images/Krit.png", type: "image" },
    //   bannerName: { value: "พาสาวเที่ยว", type: "text" },
    //   bannerStatus: { value: "ใช้งาน", type: "text" },
    //   update: { value: "20-02-2023", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];
  const getBannerID = async (Id) => {
    let getData = [];
    const respon = await getBannerById(Id);
    getData.push({
      active: respon.data.data.active === 1 ? "ใช้งาน" : "ไม่ใช้งาน",
      bannerID:
        respon.data.data.bannerID === null ? "-" : respon.data.data.bannerID,
      bannerLink:
        respon.data.data.bannerLink === null
          ? "-"
          : respon.data.data.bannerLink,
      bannerName:
        respon.data.data.bannerName === null
          ? "-"
          : respon.data.data.bannerName,
      createdAt:
        respon.data.data.createdAt === null ? "-" : respon.data.data.createdAt,
      deletedAt:
        respon.data.data.deletedAt === null ? "-" : respon.data.data.deletedAt,
      image: respon.data.data.image === null ? "-" : respon.data.data.image,
      updatedAt:
        moment(new Date(respon.data.data.updatedAt)).format("YYYY-MM-DD") ||
        moment(new Date(respon.data.data.createdAt)).format("YYYY-MM-DD"),
    });

    setGetBannerID(getData[0]);
    setGetIMG(getData[0].image);
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/AddeditmanageBanner`);
        break;
      case "edit":
        // handle edit button
        navigate(`/AddeditmanageBanner?id=${id}`);
        break;
      case "view":
        // handle view button

        // viewModal(id);
        getBannerID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const deleteByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteBanner(id);
            if (response.data.status === "success") {
              getDataBanner();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const getDataBanner = async () => {
    setLoading(true);
    // {

    // },
    const respon = await getBanner();
    respon.data.data.forEach((objectData, i) => {
      return rowsdata.push({
        //
        // id: { value: "1", type: "text" },
        // image: { value: "assets/images/Krit.png", type: "image" },
        // bannerName: { value: "พาสาวเที่ยว", type: "text" },
        // bannerStatus: { value: "ใช้งาน", type: "text" },
        // update: { value: "20-02-2023", type: "text" },
        // action: { value: "1", type: "action" },
        //
        id: {
          value: i + 1,
          dataID: objectData.bannerID,
          type: "text",
        },
        image: { value: objectData.image, type: "image" },
        bannerName: { value: objectData.bannerName, type: "text" },
        bannerStatus: {
          value: objectData.active === 1 ? "ใช้งาน" : "ไม่ใช้งาน",
          type: "action",
        },
        update: {
          value: moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
          type: "text",
        },
        action: {
          value: 1,
          type: "action",
        },
      });
    });
    await setLoading(false);
    setBanner(rowsdata);
  };
  useEffect(() => {
    // if (id) {
    getDataBanner();
    // }
  }, []);
  const handleExport = async (type) => {
    let Header = ["ลำดับที่", "ชื่อแบนเนอร์", "สถานะการใช้งาน", "อัปเดตล่าสุด"];

    let Data = banner.map((elemento, i) => {
      const dt = Object.values(4);
      dt[0] = elemento.id.value;
      dt[1] = elemento.bannerName.value;
      dt[2] = elemento.bannerStatus.value;
      dt[3] = elemento.update.value;
      return dt;
    });

    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานแบนเนอร์ผู้สนับสนุน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [inputForm, setInputForm] = useState({
    inp1: undefined,
  });

  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.inp1 = undefined;
    getDataBanner();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };

  const getDataFilter = async () => {
    setLoading(true);
    let respon;
    let rowsdata = [];

    if (inputForm.inp1 === undefined) {
      getDataBanner();
    } else {
      respon = await getBannerFilter(inputForm);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: {
            value: i + 1,
            dataID: objectData.bannerID,
            type: "text",
          },
          image: { value: objectData.image, type: "image" },
          bannerName: { value: objectData.bannerName, type: "text" },
          bannerStatus: {
            value: objectData.active === 1 ? "ใช้งาน" : "ไม่ใช้งาน",
            type: "action",
          },
          // createdAt:
          // respon.data.data.createdAt === null ? "-" : respon.data.data.updatedAt,
          update: {
            value:
              moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD") ||
              moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: {
            value: 1,
            type: "action",
          },
        });
      });
      await setLoading(false);
      setBanner(rowsdata);
    }
  };

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"จัดการแบนเนอร์ผู้สนับสนุน"} />
      <Menu parentmenu={"advertise"} activemenu={"manageBanner"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-pr.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประชาสัมพันธ์
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ประชาสัมพันธ์ / จัดการแบนเนอร์ผู้สนับสนุน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center">
              <Button3items
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName={"/AddeditmanageBanner"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-6 col-sm-4 col-xl-3 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูลแบนเนอร์ผู้สนับสนุน"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-sm-none d-lg-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-2 col-xl-1 pr-0 mt-sm-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-sm-none d-lg-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการข้อมูลสถานที่ท่องเที่ยว
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/AddeditmanageBanner"
              actionsBtn={enableButton}
              columnsname={columnsName}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={banner}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                id="exampleModalLabel"
              >
                ดูรายละเอียด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูล
                </p>
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  ชื่อแบนเนอร์ : {getDataBannerID.bannerName}
                </p>
              </div>
              <div className="col-12 ml-2 mb-3">
                <span className="fontReg font-size03 colorGray01 d-flex">
                  รูปภาพ :{" "}
                </span>
                <img
                  src={getDataIMG}
                  alt="Type Sport"
                  className="im-sports-type-dt"
                />
              </div>
              <div className="col-12 ml-2">
                <p className="fontReg font-size03 colorGray01">
                  สถานะ : {getDataBannerID.active}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Managebanner;
