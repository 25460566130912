import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
// const dataToken = sessionStorage.getItem("token");
import Swal from "sweetalert2";
const dataToken = localStorage.getItem("token");
const URL = process.env.REACT_APP_BASE_URL;

const instace = axios.create({
  // baseURL: "http://172.21.220.158:3000",
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://127.0.0.1:3000",
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const verifyImport = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(`/member/verifyimport`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const submitImportData = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(`/member/importdata`, body, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    // toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
    Swal.fire({
      title: "กรุณาตรวจสอบข้อมูล ไม่สามารถทำรายการได้",
      // text: error.response.data.message,
      icon: "error",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
      showCancelButton: true,
    });
  }
};

export const importAthleteHistory = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.post(
      URL + `/member/importathletehistory`,
      body,
      { headers: headers }
    );

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const geteAthlete = async (query = {}) => {
  try {
    const memberType = { memberType: 1 };
    //example of query
    const params = { ...memberType, query };

    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/member`, {
      headers: headers,
      params: params,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const geteAthleteSearch = async (id) => {
  try {
    const memberType = { memberType: 1, affiliation: id };

    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/member`, {
      headers: headers,
      params: memberType,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getAthleteById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/member/${id}`, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const createAthlete = async (body) => {
  try {
    const headers = {
      // "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    };

    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("firstname", body.firstname);
    formData.append("lastname", body.lastname);
    formData.append("idcard", body.idcard);
    formData.append("gender", body.gender);
    formData.append("affliation", body.affliation);
    formData.append("email", body.email);
    formData.append("mobileNo", body.mobileNo);
    formData.append("sport", JSON.stringify(body.sport));
    formData.append("file", body.img);
    formData.append("active", body.active);
    const data = JSON.stringify(formData);
    const response = await instace.post(`/member`, formData, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    // toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
    Swal.fire({
      title: "กรุณาตรวจสอบข้อมูล ไม่สามารถทำรายการได้",
      // text: error.response.data.message,
      icon: "error",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
      showCancelButton: true,
    });
  }
};

export const updateAthlete = async (id, body) => {
  console.log("body data : ", body.file);
  try {
    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("firstname", body.firstname);
    formData.append("lastname", body.lastname);
    formData.append("idcard", body.idCard);
    formData.append("gender", body.gender);
    formData.append("affliation", body.affliation);
    formData.append("email", body.email);
    formData.append("mobileNo", body.mobileNo);
    formData.append("file", body.file);
    formData.append("active", body.active);
    formData.append("sport", JSON.stringify(body.sport));

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: dataToken,
    };

    const response = await instace.put(URL + `/member/${id}`, formData, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    // toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
    Swal.fire({
      title: "กรุณาตรวจสอบข้อมูล ไม่สามารถทำรายการได้",
      // text: error.response.data.message,
      icon: "error",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
      showCancelButton: true,
    });
  }
};

export const deleteAthlete = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.delete(URL + `/member/${id}`, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getMatchStatAthlete = async (query = {}) => {
  try {
    //example of query
    // const query = {
    //     name: 'John',
    //     type: 'Match'
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/matchstat/Athlete`, {
      headers: headers,
      params: query,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const getMatchStatAthleteById = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    const response = await instace.get(URL + `/matchstat/Athlete/` + id, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
export const deleteMatchStatAthlete = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    //  const response = await instace.delete(URL + `/matchstat/Athlete/${id}`, {
    const response = await instace.delete(URL + `/matchstat/${id}`, {
      headers: headers,
    });

    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getDataAthleteFilter = async (data) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/member",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    params: {
      memberType: 1,
      name: data.name === "" ? "" : data.name,
      affiliation:
        data.affiliation === "ทั้งหมด" || data.affiliation === ""
          ? ""
          : data.affiliation,
      gender:
        data.gender === "ทั้งหมด" || data.gender === "" ? "" : data.gender,
      sportType:
        data.sportType === "ทั้งหมด" || data.sportType === ""
          ? ""
          : data.sportType,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};

export const getDataMatchStatAthleteFilter = async (data) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/matchstat/Athlete",
    headers: {
      Authorization: dataToken,
    },
    // ค่อยส่งตอนคลิกค้นหา

    params: {
      memberType: 1,
      keyword: data.name === "" ? "" : data.name,
    },
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};
