import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { font } from "./font";
function testPdf(props) {
  const doc = new jsPDF({
    orientation: "pt",
    unit: "px",
    format: "a4",
    putOnlyUsedFonts: false,
  });
  // 'THSarabunNew-normal.ttf', 'THSarabunNew', 'normal'

  doc.addFileToVFS("THSarabun-normal.ttf", font);
  doc.addFont("THSarabun-normal.ttf", "THSarabun", "normal");
  doc.setFont("THSarabun");
  doc.setFontSize(20);
  var width = doc.internal.pageSize.getWidth();
  var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
  for (let i = 0; i < pageCount; i++) {
    doc.setPage(i);
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
    doc.setFontSize(12);
    // doc.text("หน้าท่ี " + pageCurrent + "/" + pageCount, 420, 20, {
    //   align: "right",
    // });
    // doc.text(props.date, 420, 30, {
    //   align: "right",
    // });
  }

  autoTable(doc, {
    theme: "grid",
    head: [props.header],
    // theme: "striped",
    showHead: "everyPage",
    margin: { top: 70 },
    headerStyles: {
      fillColor: [135, 206, 250],
      textColor: [0, 0, 0],
      halign: "center",
    },
    styles: {
      // fillColor: [128, 128, 128],
      font: "THSarabun", // <-- place name of your font here
      fontStyle: "normal",
      fontSize: 13,
      halign: "center",
    },
    body: [
      ...props.body,
      // ["David", "david@example.com", "Sweden"],
      // ["Castille", "castille@example.com", "Spain"],
      // ...
    ],
    didDrawPage: function (data) {
      console.log("data review : ", data);
      // if (data.cell.raw !== undefined) {
      //   // Set font weight to bold for all cells
      //   data.cell.styles.fontStyle = "bold";
      // }
      // Header
      doc.text(props.data, width / 2, 40, { align: "center" });

      doc.text("การแข่งขันกีฬาเยาวชนแห่งชาติ ครั้งท่ี 38", width / 2, 50, {
        align: "center",
      });
      doc.text("วันที่ 25 มีนาคม 2566 ถึง 03 เมษายน 2566", width / 2, 60, {
        align: "center",
      });
      // doc.setTextColor(40);
      // doc.text("หน้าท่ี", data.settings.margin.left, 22);
      const pageCount = doc.internal.getNumberOfPages();
      const currentPage = data.pageNumber;
      const pageText = `หน้าท่ี ${currentPage}`;
      doc.setFontSize(12);
      // doc.text(pageText, 420, 30, {
      //   align: "right",
      // });
      // doc.text(props.date, 420, 30, {
      //   align: "right",
      // });
      doc.text(pageText, 420, 20, {
        align: "right",
      });
      doc.text(props.date, 420, 30, {
        align: "right",
      });
      // doc.text(props.date, 420, 30, {
      //   align: "right",
      // });
      // Footer
      doc.setFontSize(14);
      var str = "หมายเหตุ :";
      var dtuser = "ผู้พิมพ์เอกสาร :  " + props.user + "  " + props.position;

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      // doc.text(str, data.settings.margin.left, pageHeight - 30);
      doc.text(dtuser, data.settings.margin.left, pageHeight - 20);
    },
  });
  doc.save(props.data + ".pdf");
}
export default testPdf;
