import axios from "axios";

const dataToken = localStorage.getItem("token");
// sessionStorage.getItem("token");
const getDataUser = async () => {
  var data = JSON.stringify({
    sportName: "test",
  });
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/user/profile",
    headers: {
      Authorization: dataToken,
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem(
          "permission",
          JSON.stringify(response.data.data.permissionBackoffice)
        );
        return response;
      }
    })
    .catch((error) => {
      window.location.reload();
      console.log("error");
    });
};

export { getDataUser };
