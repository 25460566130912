import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Timepicker.css";

function Timepicker(props) {
  const { setTimePicker, inputname } = props;
  const [startTime, setStartTime] = useState(null);

  const onSelected = (date) => {
    setStartTime(date);
    setTimePicker(inputname, date);
  };

  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input-time"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      onFocus={(e) => {
        e.target.blur();
      }}
      // placeholder="--:--"
      placeholder={props.dataTime !== null ? props.dataTime : "--:--"}
    ></input>
  ));
  return (
    <DatePicker
      className="inp-data01 fontReg font-size03 colorGray01"
      customInput={<CustomInput />}
      selected={startTime}
      onChange={(date) => onSelected(date)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="HH:mm น."
      timeFormat="HH:mm น."
    />
  );
}

export default Timepicker;
