import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Button3items from "../button/Button3items";
import Calendarpicker from "../calendars/Calendarpicker";
import Dropdown from "../dropdowns/Dropdown";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import { getCampaign } from "../../api/campaign_api";
import {
  DataDropdownStoreType,
  DataDropdownCampaign,
} from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import {
  getPromotionHistory,
  getPromotionFilterHistory,
} from "../../api/promotion_api";
import moment from "moment/moment";
import Loading from "./Loading";

function Shoppromotionhis() {
  const DataDropdownShopBy = [
    { value: "", text: "ทั้งหมด" },
    { value: "1", text: "ร้านค้า" },
    { value: "2", text: "ร้านอาหาร" },
    { value: "3", text: "ร้านค้า/ร้านอาหาร" },
  ];
  const [typeCamp, setTypeCamp] = useState([]);
  const [typeShop, setTypeShop] = useState([]);
  const [spanloading, setspanLoading] = useState(false);
  const [inputForm, setInputForm] = useState({
    shopName: "", //ชื่อร้าน
    shopType: "", //ประเภทร้านค้า
    campaignName: "", //แคมเปญ
    dateTime: "", //Calendarpicker
    storeType: "",
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const getCampaignDt = async () => {
    const respon = await getCampaign();
    let dataType = [{ value: "", text: "ทั้งหมด" }];
    respon.data.data.forEach((item, i) => {
      return dataType.push({
        value: item.campaignID,
        text: item.campaignName,
      });
    });
    getFilterdropdown(dataType);
  };
  const getFilterdropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setTypeCamp(result);
  };

  const handleExport = async (type) => {
    let Header = [
      "No",
      "ชื่อผู้ใช้",
      "ชื่อร้าน",
      "ประเภทร้านค้า",
      "เเคมเปญ",
      "อัปเดตล่าสุด",
    ];
    let Data = rowsdata.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.userName.value;
      dt[2] = elemento.storeName.value;
      dt[3] = elemento.storeType.value;
      dt[4] = elemento.campaignName.value;
      dt[5] = elemento.updateddate.value;
      return dt;
    });

    const date = new Date();
    const dateTime = moment(date).format("DD/MM/YY HH:mm");

    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานประวัติการใช้โปรโมชัน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [inputDate, setInputDate] = useState("");
  const [name, setName] = useState("");
  const [selectedShop, setSelecteShop] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.shopName = "";
    inputForm.shopType = "";
    inputForm.campaignName = "";
    inputForm.dateTime = "";
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    setInputDate("");
    setInputForm(inputForm.shopName);
    setInputForm(inputForm.shopType);
    setInputForm(inputForm.campaignName);
    setInputForm(inputForm.dateTime);
    setSelecteShop("ทั้งหมด");
    getHis();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValueDate(value) {
    setInputDate(value);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("storeName", e.target.value);
      setName(e.target.value);
    }
  };

  const [rowsdata, setRowdata] = useState([]);
  const handleShopChange = (value) => {
    setSelecteShop(value);
  };
  async function getHis() {
    try {
      setspanLoading(true);
      const respon = await getPromotionHistory();
      if (respon.status === 200) {
        setspanLoading(false);
        const his = respon.data.data;
        const data = [];
        // "promotionID",
        // "promotionName",
        for (let index = 0; index < his.length; index++) {
          const element = his[index];
          const dateTime = moment(element.updatedAt).format("DD/MM/YY HH:mm");
          const rowdata = {
            id: { value: index + 1, type: "text" },
            userName: {
              value: element.firstname + " " + element.lastname,
              type: "text",
            },
            storeName: { value: element.shopName, type: "text" },
            storeType: { value: element.shopType, type: "text" },
            promotionID: { value: element.promotionID, type: "text" },
            promotionName: { value: element.promotionDetail, type: "text" },
            campaignName: { value: element.campaignName, type: "text" },
            updateddate: {
              value:
                element.updatedAt === null
                  ? moment(new Date(element.createdAt)).format("YYYY-MM-DD")
                  : moment(new Date(element.updatedAt)).format("YYYY-MM-DD"),
              type: "text",
            },
          };

          data.push(rowdata);
        }
        await setspanLoading(false);
        setRowdata(data);
        // let dataType = [{ value: "", text: "ทั้งหมด" }];
        // respon.data.data.forEach((item, i) => {
        //   return dataType.push({
        //     value: 1,
        //     text: item.shopType,
        //   });
        // });
        // getFilterdropdownShop(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  }
  console.log("inputForm.storeName :", inputForm.storeName);
  async function getHisFilter() {
    const campaignName = inputForm.campaignName;
    let dataCampaignName = typeCamp.filter((data, i) => {
      return campaignName === data.value;
    });
    console.log("typeCamp : ", typeCamp);
    console.log("dataCampaignName : ", dataCampaignName);
    console.log("selectedShop: ", selectedShop);
    console.log("inputForm.storeName :", inputForm.storeName);
    console.log("selectedShop : ", selectedShop);
    if (
      inputForm.storeName === undefined &&
      inputForm.campaignName === undefined &&
      (selectedShop === "ทั้งหมด" || selectedShop === "") &&
      dataCampaignName.length < 1
    ) {
      getHis();
    } else {
      setspanLoading(true);
      try {
        const query = {
          // dateTime: dateTime,

          campaignId:
            dataCampaignName.length > 0 && dataCampaignName[0].value !== ""
              ? dataCampaignName[0].value
              : null,

          shopType:
            selectedShop === "ทั้งหมด" || selectedShop === ""
              ? null
              : selectedShop,
          shopName: inputForm.storeName || null,
          dateTime: inputForm.dateTime
            ? moment(new Date(inputForm.dateTime)).format("YYYY-MM-DD")
            : "",
        };

        const respon = await getPromotionFilterHistory(query);
        if (respon.status === 200) {
          setspanLoading(false);
          const his = respon.data.data;
          const data = [];
          for (let index = 0; index < his.length; index++) {
            const element = his[index];
            const dateTime = moment(element.updatedAt).format("DD/MM/YY HH:mm");
            const rowdata = {
              id: { value: index + 1, type: "text" },
              userName: {
                value: element.firstname + " " + element.lastname,
                type: "text",
              },
              storeName: { value: element.shopName, type: "text" },
              storeType: { value: element.shopType, type: "text" },
              promotionID: { value: element.promotionID, type: "text" },
              promotionName: { value: element.promotionDetail, type: "text" },
              campaignName: { value: element.campaignName, type: "text" },
              updateddate: {
                value:
                  element.updatedAt === null
                    ? moment(new Date(element.createdAt)).format("YYYY-MM-DD")
                    : moment(new Date(element.updatedAt)).format("YYYY-MM-DD"),
                type: "text",
              },
            };

            data.push(rowdata);
          }
          await setspanLoading(false);
          setRowdata(data);
          // let dataType = [{ value: "", text: "ทั้งหมด" }];
          // respon.data.data.forEach((item, i) => {
          //   return dataType.push({
          //     value: 1,
          //     text: item.shopType,
          //   });
          // });
          // getFilterdropdownShop(dataType);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  const getFilterdropdownShop = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setTypeShop(result);
  };
  const columnsname = [
    "id",
    "userName",
    "storeName",
    "storeType",
    "promotionID",
    "promotionName",
    "campaignName",
    "updateddate",
  ];
  const columnsText = [
    "No",
    "ชื่อผู้ใช้",
    "ชื่อร้าน",
    "ประเภทร้านค้า",
    "รหัสโปรโมชัน",
    "ชื่อโปรโมชัน",
    "แคมเปญ",
    "อัปเดตล่าสุด",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
  ];
  // const rowsdata = [
  //                   {
  //                       "id":{ value: 1, type: "text" },
  //                       "storeName":{ value: "ร้านน้องน้ำ", type: "text" },
  //                       "storeType":{ value: "ร้านอาหาร", type: "text" },
  //                       "campaignName":{ value: "แคมเปญ01", type: "text" },
  //                       "updateddate":{ value: "7/5/2022", type: "text" },
  //                   },
  //
  // ]
  useEffect(() => {
    getHis();
    getCampaignDt();
  }, []);
  return (
    <>
      {/* {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )} */}
      {(() => {
        if (spanloading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ประวัติการใช้โปรโมชัน"} />
      <Menu parentmenu={"shopData"} activemenu={"promotionHist"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-shop.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประวัติการใช้โปรโมชัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลร้านค้า ร้านอาหาร / ประวัติการใช้โปรโมชัน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อร้าน"
                  value={name}
                  inputname={"shopName"}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-12 col-lg-4 pr-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ประเภทร้านค้า
                  </p>
                  <Dropdown
                    options={DataDropdownShopBy}
                    setinput={setinput}
                    inputname={"shopType"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    // setinput={setinput}
                    // inputname={"shopType"}
                    // ChangesetValuefromparent={ChangesetValuefromparent1}
                    // valuefromparent={valuefromparent1}
                    onChange={handleShopChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-4 pr-0 mt-lg-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">แคมเปญ</p>
                  <Dropdown
                    options={typeCamp}
                    setinput={setinput}
                    inputname={"campaignName"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                  />
                </div>
              </div>
              {/* <div className="col-12 col-lg-3 pr-0 ">
                <div className="row justify-content-start">
                  {" "}
                  <div className="col-6 col-lg-6 pr-0">
                    <button className="btn-blue01-v1" onClick={getHisFilter}>
                      <img
                        src="assets/icons/ic-search.png"
                        alt="ExportFiles Icon"
                        className="ic-search"
                      />
                      <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                        ค้นหา
                      </span>
                    </button>
                  </div>
                  <div className="col-6 col-lg-6 pr-0 ">
                    <button className="btn-white01" onClick={resetDropdown}>
                      <img
                        src="assets/icons/ic-reset.png"
                        alt="ExportFiles Icon"
                        className="ic-search"
                      />
                      <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                        ล้างค่า
                      </span>
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-0 mt-lg-3 mt-2">
                <Calendarpicker
                  dataDate={null}
                  setDatePicker={setinput}
                  inputname={"dateTime"}
                  ChangesetValueDate={ChangesetValueDate}
                  inputDate={inputDate}
                />
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-3">
                <button className="btn-blue01" onClick={getHisFilter}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-3">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการประวัติการใช้โปรโมชัน
                </p>
              </div>
            </div>
            <TableData
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowsdata}
              isSearchEnable={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Shoppromotionhis;
