import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
const Swal = require("sweetalert2");
function UploadIMG(props) {
  //
  const [dataImages, setDtImages] = useState([]);
  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/tiff",
      "image/bmp",
    ];
    if (validTypes.indexOf(file.file.type) === -1) {
      return false;
    }
    return true;
  };
  // ---------

  const [errorMessage, setErrorMessage] = useState("");
  const [images = props.image, setImages] = useState();
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    for (let i = 0; i < imageList.length; i++) {
      if (imageList[i].file.type !== undefined) {
        if (
          imageList[i].file.type !== "image/jpeg" &&
          imageList[i].file.type !== "image/jpg" &&
          imageList[i].file.type !== "image/png"
        ) {
          Swal.fire({
            title:
              imageList[i].file.name +
              " รองรับไฟล์ PNG, JPG ขนาดภาพไม่เกิน 3MB",
            icon: "error",
            confirmButtonText: "ยืนยัน",
          });

          const newData = [...imageList];
          newData.pop();
          setImages(newData);
        } else if (imageList[i].file.size > 1024 * 1024 * 3) {
          Swal.fire({
            title: imageList[i].file.name + " ขนาดภาพไม่เกิน 3MB ",
            icon: "error",
            confirmButtonText: "ยืนยัน",
          });
        } else {
          // if (imageList[i].file.size <= 1024 * 1024 * 3) {

          setImages(imageList);
        }
      }
    }
  };
  // const onChange = (imageList, addUpdateIndex) => {

  //   const validTypes = ["image/jpeg", "image/jpg", "image/png"];
  //   let errormsg = "";
  //   let fileData = [];
  //   for (let i = 0; i < imageList.length; i++) {

  //     if (
  //       validateFile(imageList[i]) === true &&
  //       imageList[i].file.size <= 1024 * 1024 * 3
  //     ) {

  //       setImages(imageList);
  //     } else {

  //       if (imageList[i].file.size > 1024 * 1024 * 3) {
  //
  //         Swal.fire({
  //           title: imageList[i].file.name + " ขนาดภาพไม่เกิน 3MB ",
  //           // text: imageList[i].file.name + " ขนาดภาพไม่เกิน 3MB ",
  //           icon: "error",
  //           confirmButtonText: "ยืนยัน",
  //         });
  //       } else {

  //         if (imageList !== undefined) {
  //           for (let i = 0; i < imageList.length; i++) {

  //             if (imageList[i].file !== "") {
  //               if (
  //                 imageList[i].file.type === validTypes[0] ||
  //                 imageList[i].file.type === validTypes[1] ||
  //                 imageList[i].file.type === validTypes[2]
  //               ) {
  //               } else {
  //                 Swal.fire({
  //                   title:
  //                     imageList[i].file.name +
  //                     " รองรับไฟล์ PNG, JPG ขนาดภาพไม่เกิน 3MB",
  //                   icon: "error",
  //                   confirmButtonText: "ยืนยัน",
  //                 });
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }

  //     if (errormsg !== "") {
  //       Swal.fire({
  //         title: errormsg,
  //         // text: errormsg,
  //         icon: "error",
  //         confirmButtonText: "ยืนยัน",
  //       });
  //     }
  //   }
  // };

  const removeFileImg = (data, index) => {
    let prData = images;
    let dataImgfl = prData
      .filter((data, i) => {
        return i !== index;
      })
      .map((finalResult, j) => {
        return {
          ...finalResult,
          id: j + 1,
        };
      });

    setImages(dataImgfl);
  };
  useEffect(() => {
    props.onSelectedImages(images);
  }, [images, props.onSelectedImages]);
  return (
    <section className="upload fontReg font-size03">
      <span className="remark-dt fontReg font-size03 colorGray02">
        รองรับไฟล์ PNG, JPG ขนาดภาพไม่เกิน 3MB
      </span>
      <div className="row w-100">
        <div className={`col-12 col-md-7 d-flex `}>
          <div className="dropzone card-upload">
            <p className="mb-0">ลากและวางไฟล์ที่นี่หรือ</p>

            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="src"
            >
              {({ onImageUpload, isDragging, dragProps }) => (
                <div className="upload__image-wrapper">
                  <button
                    disabled={images.length >= props.limit}
                    className="btn-upload"
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    เรียกดูไฟล์
                  </button>
                  &nbsp;
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
        <div className="col-12 col-md-5 mt-4 mt-md-0">
          <aside className="thumbsContainer">
            {images.map((data, index) => {
              // if (data && data.src !== null && data.src !== "") {
              return (
                <div className={index < 5 ? "boxthumb" : "d-none"} key={index}>
                  <button
                    className="remove-img text-white"
                    onClick={() => removeFileImg(data, index)}
                  >
                    x
                  </button>
                  <span className="countimg">
                    {/* {data} */}
                    {/* {index + 1 + "/" + props.limit} */}
                  </span>
                  {/* {data && data.src !== null && data.src !== "" ? ( */}
                  <img
                    src={data ? data.src : ""}
                    className="img"
                    alt="attractionImg"
                  />
                  {/* ) : (
                    <></> */}
                  {/* )} */}
                </div>
              );
              // }
            })}
          </aside>
        </div>
      </div>
    </section>
  );
}
export default UploadIMG;
