import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function ChartDoughnut({ chartData }) {
  return (
    <Doughnut
    className="m-auto chartUsersApp" 
    data={chartData}
        options={{
          responsive: true,
          cutout: 90,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              yAlign: "top",
            }
          }
        }}
    />
  )
}

export default ChartDoughnut