import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import {
  getGuestList,
  getGuestById,
  deleteGuest,
  getDataPersonFilter,
  getGuestListPerson,
} from "../../api/guest_api";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownRegisBy } from "../dataMockup/DataMockDropdown";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import Button3items from "../button/Button3items";
import Loading from "./Loading";
function Dataguest() {
  const navigate = useNavigate();
  const DataDropdownRegisBy = [
    { value: "", text: "ทั้งหมด" },
    { value: "1", text: "App" },
    { value: "2", text: "Facebook" },
    { value: "3", text: "Google" },
    { value: "4", text: "Apple ID" },
    { value: "5", text: "Line" },
  ];
  const enableButton = {
    view: true,
    edit: false,
    delete: true,
    add: false,
    approve: false,
  };

  const columnsname = [
    "id",
    "name",
    "tel",
    "email",
    "register",
    // "status",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อ",
    "เบอร์โทรศัพท์",
    "อีเมล",
    "สมัครโดย",
    // "สถานะ",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    // "text",
    "text",
    "text",
    "action",
  ];
  const rowsdata = [
    // {
    // id: { value: 1, type: "text" },
    // name: { value: "นางสาวกันยา สุดสมาน", type: "text" },
    // tel: { value: "0989876789", type: "text" },
    // email: { value: "mail@mail.com", type: "text" },
    // register: { value: "App", type: "text" },
    // status: { value: "ใช้งาน", type: "text" },
    // updateddate: { value: "7/5/2022", type: "text" },
    // action: { value: "1", type: "action" },
    // },
  ];

  const getGuestDropDown = async () => {
    try {
      const response = await getDataGuest();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.affliationID,
            text: item.affliationName,
            subType: [],
          });
        });

        setGuestDropdown(dataType);
        // const dropdown = response.data.data
        // const Guest = dropdown.map(item => {
        //   const Gst = {
        //     value: item.affliationID,
        //     text: item.affliationName,
        //     subType: []
        //   }
        //   return Gst
        // })
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [DataGuest, setDataGuest] = useState([]);
  const [dataGuestID, setDataGuestID] = useState([]);
  const [spanloding, setspanLoding] = useState(false);

  useEffect(() => {
    getDataGuest();
    // getGuestDropDown();
  }, []);

  const getDataGuest = async () => {
    setspanLoding(true);
    try {
      const respon = await getGuestListPerson();
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: {
            value: i + 1,
            type: "text",
            dataID: objectData.memberID,
          },
          firstname: objectData.firstname,
          name: {
            value:
              objectData.firstname === null && objectData.lastname === null
                ? "-"
                : objectData.firstname + "  " + objectData.lastname,
            type: "text",
          },
          tel: {
            value:
              objectData.mobileNo === null
                ? "-"
                : `${objectData.mobileNo.slice(
                    0,
                    3
                  )}-${objectData.mobileNo.slice(
                    3,
                    6
                  )}-${objectData.mobileNo.slice(6, 10)}
                `,
            type: "text",
          },
          email: {
            value:
              objectData.email === null || objectData.email === ""
                ? "-"
                : objectData.email,
            type: "text",
          },
          register: {
            // value: "ขาด",
            // value: "-",
            value:
              objectData.registerType === null ? "-" : objectData.registerType,
            type: "text",
          },
          // status: {
          //   // value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
          //   value: "-",
          //   active: true,

          //   type: "text",
          // },

          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: {
            value: "1",
            type: "action",
          },
        });
      });
      let dataType = [{ value: "", text: "ทั้งหมด" }];
      respon.data.data.forEach((item, i) => {
        return dataType.push({
          value: item.affliationID,
          text: item.affliationName,
          subType: [],
        });
      });

      setGuestDropdown(dataType);
      await setspanLoding(false);
      setDataGuest(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getGuestID = async (Id) => {
    let getData = [];
    const respon = await getGuestById(Id);

    getData.push({
      memberID:
        respon.data.data.memberID === null ? "-" : respon.data.data.memberID,
      id: respon.data.data.idcard === null ? "-" : respon.data.data.idcard,
      title: respon.data.data.title !== null ? respon.data.data.title : "-",
      firstname:
        respon.data.data.firstname !== null ? respon.data.data.firstname : "-",
      lastname:
        respon.data.data.lastname === null || respon.data.data.lastname === ""
          ? "-"
          : respon.data.data.lastname,
      tel:
        respon.data.data.mobileNo === null || respon.data.data.mobileNo === ""
          ? "-"
          : `${respon.data.data.mobileNo.slice(
              0,
              3
            )}-${respon.data.data.mobileNo.slice(
              3,
              6
            )}-${respon.data.data.mobileNo.slice(6, 10)}
  `,
      email:
        respon.data.data.email === null || respon.data.data.email === ""
          ? "-"
          : respon.data.data.email,
      register:
        respon.data.data.registerType === null
          ? "-"
          : respon.data.data.registerType,
      status: "-",
      updateddate:
        respon.data.data.updatedAt === null
          ? moment(new Date(respon.data.data.createdAt)).format("YYYY-MM-DD")
          : moment(new Date(respon.data.data.updatedAt)).format("YYYY-MM-DD"),
    });

    setDataGuestID(getData[0]);
  };
  const deleteGuestByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteGuest(id);

            if (response.data.status === "success") {
              getDataGuest();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleExport = async (type) => {
    let Header = [
      "No",
      "ชื่อ",
      "เบอร์โทรศัพท์",
      "อีเมล",
      "สมัครโดย",
      // "สถานะ",
      "อัปเดตล่าสุด",
    ];

    let Data = DataGuest.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.name.value;
      dt[2] = elemento.tel.value;
      dt[3] = elemento.email.value;
      dt[4] = elemento.register.value;
      // dt[5] = "elemento.status.value";
      dt[5] = elemento.updateddate.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานบุคคลทั่วไป",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        // navigate(`/Addeditguide`)
        break;
      case "edit":
        // handle edit button
        navigate(`/Addeditaffiliation?id=${id}`);
        // navigate(`/AddeditathleteAmt?id=${id}`)
        break;
      case "view":
        // handle view button

        getGuestID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button
        deleteGuestByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const [guestDropdown, setGuestDropdown] = useState([]);
  const [inputForm, setInputForm] = useState({
    name: "",
    register: "",
  });

  const getDataFilter = async () => {
    setspanLoding(true);
    let respon;
    let rowsdata = [];

    let DropdownForm = {
      memberType: 4,
      name: inputForm.name,
      register: selectedValue1,
    };
    respon = await getDataPersonFilter(DropdownForm);

    respon.data.data.forEach((objectData, i) => {
      return rowsdata.push({
        id: {
          value: i + 1,
          type: "text",
          dataID: objectData.memberID,
        },
        firstname: objectData.firstname,
        name: {
          value:
            objectData.firstname === null && objectData.lastname === null
              ? "-"
              : objectData.firstname + "  " + objectData.lastname,
          type: "text",
        },
        tel: {
          value:
            objectData.mobileNo === null
              ? "-"
              : `${objectData.mobileNo.slice(0, 3)}-${objectData.mobileNo.slice(
                  3,
                  6
                )}-${objectData.mobileNo.slice(6, 10)}
                `,
          type: "text",
        },
        email: {
          value:
            objectData.email === null || objectData.email === ""
              ? "-"
              : objectData.email,
          type: "text",
        },
        register: {
          value:
            objectData.registerType === null ? "-" : objectData.registerType,
          type: "text",
        },
        status: {
          // value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
          value: "-",
          active: true,

          type: "text",
        },

        updateddate: {
          value:
            objectData.updatedAt === null
              ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
              : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),

          type: "text",
        },
        action: {
          value: "1",
          type: "action",
        },
      });
    });
    await setspanLoding(false);
    setDataGuest(rowsdata);
    // }
  };

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [selectedValue1, setSelectedValue1] = useState("");
  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.name = "";
    setSelectedValue1("");
    setValuefromparent1("ทั้งหมด");
    getDataGuest();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("name", e.target.value);
      setName(e.target.value);
    }
  };

  const handleRegisChange = (value) => {
    setSelectedValue1(value);
  };

  return (
    <>
      {spanloding === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"บุคคลทั่วไป"} />
      <Menu parentmenu={"guestData"} activemenu={"guest"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-guest.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                บุคคลทั่วไป
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลบุคคลทั่วไป / บุคคลทั่วไป
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              {/* <Buttonexport onExportClicked={handleExport} /> */}
              <Button3items
                // navigateToAddEdit={navigateToAddEdit}
                // pageAddEditName={"/Addeditcoach"}
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-md-5 col-xl-4 pr-0">
                <input
                  onChange={onChangeInputName}
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อจริง นามสกุล"
                  value={name}
                />
              </div>
              <div className="col-12 col-md-5 col-xl-3 pr-0 mt-2 mt-md-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สมัครโดย
                  </p>
                  <Dropdown
                    options={DataDropdownRegisBy}
                    setinput={setinput}
                    inputname={"register"}
                    id="register"
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleRegisChange}
                  />
                </div>
              </div>
              <div className="col-6 col-md-1 pr-0 mt-md-0 mt-2">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-md-none d-xl-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-md-1 pr-0 mt-md-0 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-md-none d-xl-inline">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการบุคคลทั่วไป
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={DataGuest}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
              data={DataGuest}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-12">
                    <p className="fontReg font-weight-bold font-size03 colorGray01">
                      <span className="border-left01"></span>ข้อมูลบุคคลทั่วไป
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ลำดับ: {dataGuestID.memberID}
                    </p>
                  </div>
                  {/* <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      คำนำหน้า: {dataGuestID.title}
                    </p>
                  </div> */}
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      ชื่อจริง: {dataGuestID.firstname}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      นามสกุล: {dataGuestID.lastname}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      เบอร์โทรศัพท์: {dataGuestID.tel}
                    </p>
                  </div>
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      อีเมล:{dataGuestID.email}
                    </p>
                  </div>
                  {/* <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สถานะ: {dataGuestID.status}
                    </p>
                  </div> */}
                  <div className="col-12 ml-2">
                    <p className="fontReg font-size03 colorGray01">
                      สมัครผ่าน: {dataGuestID.register}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dataguest;
