import React, { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import { getDataUser } from "../config/configApi-User";
import DataExcel from "../uploads/SendDataexcel";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import jsPDF from "jspdf";
import { DataMockChartUsersApp } from "../dataMockup/DataMockChartUsersApp";
import ChartDoughnut from "../charts/ChartDoughnut";
import { font } from "./font";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Loading from "../pages/Loading";
const pdfConverter = require("jspdf");

function ExportPDF(props) {
  console.log("props.dataArena ", props.dataArena);
  const chartRef = useRef(null);

  //
  const [userDataDash, setUserDataDash] = useState({});
  const [setEx, setClickEx] = useState(1);
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  useEffect(() => {
    // div2PDF(null);
    // getData();
  }, []);

  //  DataMockChartUsersApp.map((data) => data.quantity),
  let testDtDashApp = ["ปริมาณผู้ใช้งานแอปพลิเคชัน"];
  let cDataDashApp = {
    labels: props.dataUserApp.map(
      (data) => data.age + ": " + data.quantity + " คน"
    ),
    data: props.dataUserApp,
    datasets: [
      {
        label: "Label",
        data: props.dataUserApp.map((data) => data.quantity),
        backgroundColor: [
          "#33B5B3",
          "#FFBA58",
          "#657BB1",
          "#6790FF",
          "#C298FF",
          "#FF90AB",
          "#90CD8D",
          "#5C1B6C",
          "#dde410",
        ],
      },
    ],
  };
  let cDataDashsport = {
    labels: props.dataDashSport.map((data) => data.rank),
    datasets: [
      {
        label: "Label",
        data: props.dataDashSport.map((data) => data.quantity),
        backgroundColor: ["#6790FF", "#657BB1", "#0698FF"],
      },
    ],
  };
  let cDataDashFood = {
    labels: [
      "ร้านอาหาร" + " : " + props.dataDashFood.totalAllAmount + " ร้าน",
      "ร้านค้า : " + props.dataDashFood.totalFoodAmount + " ร้าน",
      "ร้านค้าและร้านอาหาร : " + props.dataDashFood.totalStoreAmount + " ร้าน",
    ],
    data: [
      {
        label: "ร้านอาหาร",
        amount: props.dataDashFood.totalFoodAmount,
        point: " ร้าน",
      },
      {
        label: "ร้านค้า",
        amount: props.dataDashFood.totalStoreAmount,
        point: " ร้าน",
      },
      {
        label: "ร้านค้าและร้านอาหาร",
        amount: props.dataDashFood.totalAllAmount,
        point: " ร้าน",
      },
    ],
    sumData: {
      amount: props.sumCampa,
    },
    datasets: [
      {
        label: "Label",
        data: [
          props.dataDashFood.totalAllAmount,
          props.dataDashFood.totalFoodAmount,
          props.dataDashFood.totalStoreAmount,
        ],
        backgroundColor: ["#90CD8D", "#33B5B3", "#0698FF"],
      },
    ],
  };

  let cDataBarFood = {
    data: props.dataCalGH.food,
    labels: props.dataCalGH.food
      ? props.dataCalGH.food.map((data) => data.label)
      : [],
    datasets: [
      {
        label: "วันที่ล่าสุด : " + props.dataTime,
        data: props.dataCalGH.food
          ? props.dataCalGH.food.map((data) => data.value)
          : [],
        backgroundColor: ["#06BBFF"],
      },
    ],
  };
  let cDataBarStore = {
    // labels: props.dataCalGH.store.map(
    //   (data) => data.label + ": " + data.value + " คน"
    // ),
    data: props.dataCalGH.store,

    labels: props.dataCalGH.store
      ? props.dataCalGH.store.map((data) => data.label)
      : [],
    datasets: [
      {
        label: "วันที่ล่าสุด : " + props.dataTime,
        data: props.dataCalGH.store
          ? props.dataCalGH.store.map((data) => data.value)
          : [],
        // data: props.dataUserApp.map((data) => data.quantity),
        backgroundColor: ["#06BBFF"],
      },
    ],
  };
  // dataCalGH
  const getData = () => {
    setUserDataDash(cDataDashApp);
    // for (let i = 0; i < dtApp.length; i++) {}
  };

  let cData = {
    labels: [
      "น้อยกว่า 15 ปี : 14 คน",
      "15 - 19 ปี:46 คน",
      "น้อยกว่า 15 ปี : 14 คน",
      "15 - 19 ปี:46 คน",
      "น้อยกว่า 15 ปี : 14 คน",
      "15 - 19 ปี:46 คน",
    ],
    datasets: [
      {
        label: "Label",
        data: [100, 150, 123, 170, 162],
        backgroundColor: [
          "#33B5B3",
          "#FFBA58",
          "#657BB1",
          "#6790FF",
          "#C298FF",
          "#FF90AB",
          "#90CD8D",
          "#5C1B6C",
          "#dde410",
        ],
      },
    ],
  };

  const exportExcel = async (
    dtApp,
    dtSport,
    dtFoodStore,
    dtArena,
    dtBarStore,
    dtBarFood
  ) => {
    console.log("dtBarStore preview : ", dtBarStore);
    console.log("dtBarFood preview : ", dtBarFood);
    // console.log("dtArena preview data : ", dtArena);
    // console.log("dtFoodStore preview : ", dtFoodStore);
    let Header = [
      "ลำดับที่",
      "ช่วงอายุ",
      "จำนวน",
      // "ผู้ประสานงาน",
      // "เบอร์โทรผู้ประสานงาน",
      // "สถานะ",
    ];
    let DataApp = dtApp.data.map((elemento, i) => {
      const dt = Object.values(3);
      dt[0] = i + 1;
      dt[1] = elemento.age;
      dt[2] = elemento.quantity;
      return dt;
    });
    let DataSport = dtSport.labels.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = "อันดับที่ " + (i + 1);
      dt[1] = elemento;
      return dt;
    });
    let amountStFd = dtFoodStore.sumData.amount;
    let DataStore = dtFoodStore.data.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = i + 1;
      dt[1] = elemento.label;
      dt[2] = elemento.amount + elemento.point;
      return dt;
    });
    let DataBarStore = dtBarStore.data.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = i + 1;
      dt[1] = elemento.label;
      dt[2] = elemento.value;
      return dt;
    });
    let DataBarFood = dtBarFood.data.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = i + 1;
      dt[1] = elemento.label;
      dt[2] = elemento.value;
      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    const respon = await getDataUser();
    let dataSend = {
      body: [
        DataApp,
        DataSport,
        DataStore,
        amountStFd,
        DataBarStore,
        DataBarFood,
        dtArena,
      ],
      // [{ app: DataApp }, { sport: DataSport }],
      data: "รายงาน Dashboard",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };
    DataExcel(dataSend);
    // switch (type) {
    //   case "excel":
    //     DataExcel(dataSend);
    //     break;
    //   case "pdf":
    //     DataPdf(dataSend);
    //     break;
    // }
  };
  const div2PDF = async (e) => {
    await setClickEx(1);
    // Swal.fire({
    //   title: "คุณต้องการที่จะบันทึกข้อมูล",
    //   icon: "warning",
    //   showCancelButton: true,
    //   timer: 3000,
    //   confirmButtonText: "ยืนยัน",
    //   cancelButtonText: "ยกเลิก",
    //   reverseButtons: true,
    // }).then(async (result) => {
    // if (result.isConfirmed) {
    const but = e.target;
    let input = window.document.getElementsByClassName("div2PDF")[0];
    const pdf = new pdfConverter("l", "pt");
    var width = pdf.internal.pageSize.getWidth();
    pdf.text("การแข่งขันกีฬาเยาวชนแห่งชาติ ครั้งท่ี 38", width / 2, 50, {
      align: "center",
    });

    html2canvas(input).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      pdf.addImage(img, "png", 40, 20, 800, 500);
      const table = document.getElementById("html-table");
      pdf.addFileToVFS("THSarabun-normal.ttf", font);
      pdf.addFont("THSarabun-normal.ttf", "THSarabun", "normal");
      pdf.setFont("THSarabun");
      pdf.setFontSize(20);
      pdf.autoTable({
        theme: "grid",
        headerStyles: {
          // fillColor: [128, 128, 128],
          fillColor: [135, 206, 250],
          textColor: [0, 0, 0],
          halign: "center",
        },
        styles: {
          // fillColor: [128, 128, 128],
          font: "THSarabun", // <-- place name of your font here
          fontStyle: "normal",
          fontSize: 13,
          halign: "center",
        },
        html: table,
        startY: 550,
      });
      setLoading(true);
      pdf.save("รายงาน Dashboard.pdf");
      setClickEx(1);
      // Swal.fire({
      //   title: "คุณต้องการที่จะบันทึกข้อมูล Dashboard ใช่หรือไม่",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "ยืนยัน",
      //   cancelButtonText: "ยกเลิก",
      //   reverseButtons: true,
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     setLoading(false);
      //     pdf.save("chartExport.pdf");
      //     setClickEx(0);
      //   } else {
      //     setClickEx(0);
      //   }
      // });
    });
    // } else {
    //   setClickEx(0);
    // }
    // });
  };
  function useScreenshot() {
    const [screenshot, setScreenshot] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
      if (ref.current) {
        html2canvas(ref.current).then((canvas) => {
          const dataURL = canvas.toDataURL();
          setScreenshot(dataURL);
        });
      }
    }, [ref]);

    return [ref, screenshot];
  }
  //
  const [ref, screenshot] = useScreenshot();

  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(null);
  const handleShow = () => setShow(true);
  return (
    <div>
      {/* {(() => {
        if (isLoading === true) {
          return <Loading />;
        }
      })()} */}
      {/* <div> */}
      {/* <div className=" row"> */}

      {/* </div> */}
      <div className="row">
        <div className="col-7"> </div>
        <div className="col-5 d-flex justify-content-end align-items-center">
          {" "}
          <button
            className="btn-expFile mg-ex mr-3"
            onClick={(e) =>
              exportExcel(
                cDataDashApp,
                cDataDashsport,
                cDataDashFood,
                props.dataArena,
                cDataBarStore,
                cDataBarFood
              )
            }
          >
            <img
              src="assets/icons/ic-files.png"
              alt="ExportFiles Icon"
              className="ic-files"
            />{" "}
            <span className="fontReg font-size03 colorGray01 d-none d-lg-inline-block">
              {" "}
              excel
            </span>
          </button>
          <button className="btn-expFile mg-ex" onClick={(e) => div2PDF(e)}>
            <img
              src="assets/icons/ic-files.png"
              alt="ExportFiles Icon"
              className="ic-files"
            />{" "}
            <span className="fontReg font-size03 colorGray01 d-none d-lg-inline-block">
              {" "}
              pdf
            </span>
          </button>
        </div>
      </div>

      <div className={setEx === 1 ? "div2PDF row posit-ab" : "d-none posit-ab"}>
        <div className={setEx === 1 ? "col-12" : "d-none"}>
          <p> Dashboard / Dashboard หลัก</p>
        </div>
        <div className={setEx === 1 ? "col-4" : "d-none"}>
          <p>ปริมาณผู้ใช้งานแอปพลิเคชัน</p>
          <p>จำนวนทั้งหมด : {props.sumData} คน</p>
          <Doughnut
            data={cDataDashApp}
            height={200}
            width={10}
            options={{
              title: {
                display: true,
                text: "Chart to PDF Demo",
                fontSize: 32,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
        <div className={setEx === 1 ? "col-4" : "d-none"}>
          <p>ผลโหวตกีฬาที่สนใจ</p>
          {/* <p>อันดับผลโหวต </p> */}
          {props.dataDashSport.map((data, i) => (
            <label>
              &nbsp;อันดับ {i + 1}: {data.rank} &nbsp;
            </label>
          ))}
          {/* <p>อันดับ 1 : {props.dataDashSport[0]} </p> */}
          <Doughnut
            data={cDataDashsport}
            height={200}
            width={10}
            options={{
              title: {
                display: true,
                text: "Chart to PDF Demo",
                fontSize: 32,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
        <div className={setEx === 1 ? "col-4" : "d-none"}>
          <p>ปริมาณผู้เข้าร่วมแคมเปญ</p>
          <p>จำนวนทั้งหมด : {props.sumCampa} ร้าน</p>
          <Doughnut
            data={cDataDashFood}
            height={200}
            width={10}
            options={{
              title: {
                display: true,
                text: "Chart to PDF Demo",
                fontSize: 32,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
        <div className={setEx === 1 ? "col-6" : "d-none"}>
          <p>ผู้ใช้โปรโมชันร้านค้า</p>
          <Bar
            data={cDataBarStore}
            options={{
              title: {
                display: true,
                text: "Chart to PDF Demo",
                fontSize: 32,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
            height={200}
          />
        </div>
        <div className={setEx === 1 ? "col-6" : "d-none"}>
          <p>ผู้ใช้โปรโมชันร้านอาหาร</p>
          <Bar
            data={cDataBarFood}
            options={{
              title: {
                display: true,
                text: "Chart to PDF Demo",
                fontSize: 32,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
            height={200}
          />
        </div>
        <div className="row" ref={ref}>
          <table id="html-table" className="d-none">
            <thead>
              <tr>
                <th className="head-table-data">ชื่อสนามกีฬาย่อย</th>
                <th className="head-table-data">จำนวนผู้เข้าใช้</th>
                <th className="head-table-data">อัปเดตล่าสุด</th>
              </tr>
            </thead>
            <tbody>
              {props.dataArena.map((data, i) => (
                <tr>
                  <td>{data.arenaName}</td>
                  <td>{data.userCount}</td>
                  <td>{data.lastUpdate || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ExportPDF;
