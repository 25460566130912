import axios from "axios";
import { toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";

// const dataToken = sessionStorage.getItem("token");
// const URL = process.env.REACT_APP_BASE_URL
const dataToken = localStorage.getItem("token");

const instace = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  baseURL: process.env.REACT_APP_BASE_URL,
});

const toastErrorConfig = {
  position: "top-right",
  autoClose: 125000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    background: "#ed5565 !important",
  }),
};

export const getDeletedMemberList = async (query = {}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })

    // membertype id list
    // 1 = athletes
    // 2 = coaches
    // 3 = staff
    // 4 = guest
    // 5 = shop

    query["deleted"] = 1;

    const response = await instace.get("/member", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getDeletedStaffList = async (query = {}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })

    query["deleted"] = 1;

    const response = await instace.get("/staff", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getBusbooking = async (query) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })

    const response = await instace.get("/busbooking", {
      headers: headers,
      params: query,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getBusbookingByID = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    if (id) {
      const response = await instace.get(`/busbooking/${id}`, {
        headers: headers,
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    } else {
      const response = await instace.get(`/busbooking`, {
        headers: headers,
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    }
    // const response = await instace.get(`/busbooking/${id}`, {
    //   headers: headers,
    // });
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const CreateBusbooking = async (formData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.post(`/busbooking`, formData, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const UpdateBusbooking = async (id, formData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.put(`/busbooking/${id}`, formData, {
      headers: headers,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};

export const getBusbookingFilter = async (data) => {
  const config = {
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/busbooking",
    headers: {
      Authorization: dataToken,
    },
    params: data,
  };
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", {
        position: "top-right",
        autoClose: 125000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
          background: "#ed5565 !important",
        }),
      });
    });
};

export const CancleBusbooking = async (id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: dataToken,
    };
    // const response = await instace.post(URL + ``, body , { headers: headers })
    const response = await instace.put(
      `/busbooking/${id}` + "/cancel",
      {},
      {
        headers: headers,
      }
    );
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    toast.error("อยู่ระหว่างการตรวจสอบข้อมูล !", toastErrorConfig);
  }
};
