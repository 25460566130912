import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
import { useNavigate } from "react-router-dom";
import "../pages/Mastersporttype.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import UploadIMG from "../uploads/UploadIMG";
import { createAdmin } from "../../api/admin_api";
import {
  getAdmin,
  getAdminById,
  removeAdmin,
  updateAdmin,
} from "../../api/admin_api";
import { getBackofficePermissionList } from "../../api/backoffice_permission_api";
import Swal from "sweetalert2";
function AddAdmin() {
  const navigate = useNavigate();
  const [nameAdmin, setnameAdmin] = useState();
  const [surAdmin, setsurAdmin] = useState();
  const [mailAdmin, setmailAdmin] = useState();
  const [telAdmin, settelAdmin] = useState(null);
  const [imgAdmin, setImgAdmin] = useState();
  const [permissionId, setPermissionId] = useState();
  const [getDataimage, setDataImage] = useState([]);
  const [dataView, setDataView] = useState([]);
  const [dataName, setDataName] = useState([]);
  const [dataLastName, setDataLastName] = useState([]);
  const [dataEmail, setDataEmail] = useState([]);
  const [dataPermis, setDataPermis] = useState([]);
  const [dataPermisOp, setDataPermisOp] = useState([]);
  const [setSelected, setSelectedValue] = useState(["กรุณาเลือกสิทธิ์"]);
  const [setDataper, setDataperVa] = useState([]);
  const handleSelectedImages = (images) => {
    setDataImage(images);
  };
  function navigateToBack(page) {
    navigate(page);
  }
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/adminList");
      }
    });
  }
  useEffect(() => {
    getPermission();
    if (id) {
      getAdminDtById();
    }
  }, []);
  const handleDropdownChange = (value) => {
    setSelectedValue(value);
  };
  let id = new URLSearchParams(window.location.search).get("id");
  const getAdminDtById = async () => {
    const respon = await getAdminById(id);
    let dataph = [];
    let obDt = respon.data.data;

    dataph.push({
      firstname: obDt.firstname,
      lastanme: obDt.lastanme,
      img: obDt.img,
      email: obDt.email,
      permisionAccount: obDt.permisionAccount.permissionName,
      mobileNo: obDt.mobileNo,
    });
    setmailAdmin(dataph[0].email);
    // -------
    settelAdmin(dataph[0].mobileNo);
    setDataName(dataph[0].firstname);
    setDataLastName(dataph[0].lastanme);
    setDataEmail(dataph[0].email);
    setDataPermis(dataph[0].permisionAccount);
    setImgAdmin(dataph[0].img);
    setnameAdmin(dataph[0].firstname);
    setsurAdmin(dataph[0].lastanme);
    // permisionAccount
    setSelectedValue(obDt.permisionAccount.permissionAccountId);
    setDataperVa(obDt.permisionAccount.permissionName);

    // setDataView(dataph[0]);
  };
  // handleEmail

  const getPermission = async () => {
    const res = await getBackofficePermissionList();
    let getData = [{ value: "", text: "ทั้งหมด" }];
    res.data.data.forEach((objectData, i) => {
      return getData.push({
        value: objectData.permissionAccountId,
        text: objectData.permissionName,
      });
    });
    setDataPermisOp(getData);
  };

  const createDataAdmin = async () => {
    if (
      nameAdmin === "" ||
      nameAdmin === null ||
      nameAdmin === undefined ||
      surAdmin === "" ||
      surAdmin === null ||
      surAdmin === undefined ||
      mailAdmin === "" ||
      mailAdmin === null ||
      mailAdmin === undefined ||
      telAdmin === "" ||
      telAdmin === null ||
      telAdmin === undefined ||
      setSelected[0] === "กรุณาเลือกสิทธิ์"
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      if (!id) {
        try {
          const data = {
            firstname: nameAdmin,
            lastname: surAdmin,
            email: mailAdmin,
            permissionAccountId: parseInt(setSelected),
            mobileNo: telAdmin,
          };
          Swal.fire({
            title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              (async () => {
                const respon = await createAdmin(data);

                if (respon.data.status === "success") {
                  Swal.fire({
                    title: "บันทึกข้อมูลสำเร็จ",
                    icon: "success",
                    confirmButtonText: "ยืนยัน",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/adminList");
                    }
                  });
                } else {
                  // Swal.fire({
                  //   title: "เกิดข้อผิดพลาด",
                  //   icon: "error",
                  //   showCancelButton: true,
                  //   confirmButtonText: "ยืนยัน",
                  //   cancelButtonText: `ยกเลิก`,
                  //   reverseButtons: true,
                  // }).then((result) => {
                  //   navigate("/adminList");
                  // });
                }
              })();
            }
          });

          // if (response.status === 200) {
          //   navigate(`/adminList`);
          // } else {
          //   alert(`Something went wrong`);
          // }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const data = {
            firstname: nameAdmin,
            lastname: surAdmin,
            email: mailAdmin,
            permissionAccountId: parseInt(setSelected),
            mobileNo: telAdmin,
          };
          // console.log("dtat", data);
          Swal.fire({
            title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              (async () => {
                const respon = await updateAdmin(id, data);
                if (respon.data.status === "success") {
                  Swal.fire({
                    title: "แก้ไขข้อมูลสำเร็จ",
                    icon: "success",
                    confirmButtonText: "ยืนยัน",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/adminList");
                    }
                  });
                } else {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: `ยกเลิก`,
                    reverseButtons: true,
                  }).then((result) => {
                    navigate("/adminList");
                  });
                }
              })();
            }
          });

          // if (response.status === 200) {
          //   navigate(`/adminList`);
          // } else {
          //   alert(`Something went wrong`);
          // }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numericInput = inputValue.replace(/\D/g, ""); // Regular expression to allow only digits
    // setInputValue(numericInput);
    settelAdmin(numericInput);
  };
  return (
    <>
      <Header parentmenu={"ตั้งค่าแอดมิน"} />
      <Menu parentmenu={"adminSetting"} activemenu={"adminList"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-settingAdmin.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ตั้งค่าแอดมิน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ตั้งค่าแอดมิน / รายชื่อ Admin เพิ่มข้อมูล
              </font>
            </div>
          </div>
          <Col className="card-white">
            <Row className="mx-1">
              <Col xs={12} md={12} lg={12}>
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01 px-1"> รายละเอียดแอดมิน</span>
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                ชื่อ
              </Col>
              <Col lg={10} md={10} xs={8}>
                <input
                  // value={dataName}
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setnameAdmin(e.target.value);
                  }}
                  value={nameAdmin}
                />
              </Col>
            </Row>
            <Row className="mt-4 ">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                นามสกุล
              </Col>
              <Col lg={10} md={10} xs={8}>
                <input
                  // value={dataLastName}
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setsurAdmin(e.target.value);
                  }}
                  value={surAdmin}
                />
              </Col>
            </Row>
            <Row className="mt-4 ">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                อีเมล
              </Col>
              <Col lg={10} md={10} xs={8}>
                {/*  */}
                <input
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setmailAdmin(e.target.value);
                  }}
                  // value={dataEmail}
                  value={mailAdmin}
                />
                {/* <input
                  // value={dataEmail}
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setmailAdmin(e.target.value);
                    // handleEmail(e.target.value);
                  }}
                /> */}
              </Col>
            </Row>
            <Row className="mt-4 ">
              <Col lg={2} md={2} xs={4} className="text-header text-end">
                เบอร์โทรศัพท์
              </Col>
              <Col lg={10} md={10} xs={8}>
                {/*  */}
                <input
                  maxLength={10}
                  className="inp-data01 fontReg font-size03"
                  value={telAdmin}
                  onChange={handleInputChange}
                  // onChange={(e) => {
                  //   settelAdmin(e.target.value);
                  // }}
                  // value={dataEmail}
                />
                {/* <input
                  // value={dataEmail}
                  className="inp-data01 fontReg font-size03"
                  onChange={(e) => {
                    setmailAdmin(e.target.value);
                    // handleEmail(e.target.value);
                  }}
                /> */}
              </Col>
            </Row>
            <Row className="mt-4 mx-1">
              <Col lg={2}></Col>
              <Col lg={10} className="drpdw-status">
                <p className="fontReg font-size03 colorGray02 mb-1">
                  Permission
                </p>
                <Dropdown
                  options={dataPermisOp}
                  onChange={handleDropdownChange}
                  // setDataper
                  valuefromparent={
                    setDataper.length > 0 ? setDataper : "กรุณาเลือกสิทธิ์"
                  }
                  // valuefromparent={
                  //   setDataper? setSelected[0] === "กรุณาเลือกสิทธิ์"
                  //     ? setSelected
                  //     : setDataper
                  // }
                />
              </Col>
            </Row>
            {/* <Row className="mt-5">
              <Col xs={4} md={2} lg={2} className="text-input text-end">
                รูปภาพ
              </Col>
              <Col xs={8} md={10} lg={8}>
                <UploadIMG
                  limit={1}
                  value={imgAdmin}
                  onSelectedImages={handleSelectedImages}
                />
              </Col>
            </Row> */}
          </Col>
          <Row className="my-5">
            <Col xs={2}></Col>
            <Col className="text-center" xs={4} md={4} lg={4}>
              <button className="btn-blue02" onClick={createDataAdmin}>
                บันทึก
              </button>
            </Col>
            <Col className="text-center" xs={4} md={4} lg={4}>
              <button
                className="btn-cancle"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddAdmin;
