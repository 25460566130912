import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import "./Addeditsporttype.css";
import UploadIMG from "../uploads/UploadIMG";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import ImportExcel from "../uploads/ExcelRender";
import { verifyImport, submitImportData } from "../../api/sport_api";

import {
  updateSport,
  getSportById,
  createSport,
  // createSubSport,
  // updateSubSport,
} from "../../api/sport_api";
import { updateSubSport, createSubSport } from "../../api/subsport_api";
import UploadIMGV2 from "../uploads/UploadIMGv2";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import UploadFileXLS from "../uploads/UploadFileXLS";
import { active } from "glamor";
import { result } from "lodash";
import { async } from "q";
function Addeditsporttype() {
  const [isLoading, setLoading] = useState(null);
  const [groupShow, setGroupShow] = useState(false);
  let id = new URLSearchParams(window.location.search).get("id");
  let [sportTypeList, setsportTypeList] = useState([{ sportType: "" }]);
  const [getDataimage, setDataImage] = useState([]);
  const [sportName, setDataSportName] = useState([]);
  let [checked, setChecked] = useState(false);
  // setCheckedSub
  const [checkedSub, setCheckedSub] = useState(null);
  const handleSportChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...sportTypeList];
    list[index][name] = value;
    console.log(list);
    setsportTypeList(list);
  };
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }
  const handleSportRemove = (index) => {
    const list = [...sportTypeList];
    list.splice(index, 1);
    setsportTypeList(list);
  };

  const handleSportAdd = () => {
    // { id: id, arenaType: "", subArenaName: "", subArenaGate: "" },
    setsportTypeList([...sportTypeList, { sportType: "" }]);
  };
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/sportType");
      }
    });
  }
  const handleSelectedImages = (images) => {
    setDataImage(images);
    //  getDataimage[0].file
    if (images) {
    }
  };

  // *********************** after ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);

  const handleImport = async (rows) => {
    // console.log("rows data : ", rows);
    // console.log("rows.length - 1 preview : ", rows.length - 1);
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);
    // console.log("rows.slice(1) preview  ", rows.slice(1));
    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          sportName: item[0],
          active: item[1],
        });
      }
    });
    await setImportData(row);
    // console.log("row data preview : ", row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          importData: data,
        };
        const response = await verifyImport(dataToVerify);
        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      if (error === 0) {
        if (failedArr.length > 0) {
          Swal.fire({
            title:
              "ตรวจสอบข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง ",
            icon: "error",
            confirmButtonText: "ยืนยัน",
          });
        } else {
          Swal.fire({
            title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "success",
            confirmButtonText: "ยืนยัน",
          });
          
        }

        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
        // Swal.fire({
        //   title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
        //   icon: "success",
        //   confirmButtonText: "ตกลง",
        // });
        // failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importSport = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              // memberType: 2,
              importData: data,
            };
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      if (error === true) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/sportType");
          }
        });
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/sportType");
          }
        });
      }
    } catch (error) {
      // Swal.fire({
      //   title: "เกิดข้อผิดพลาด",
      //   icon: "error",
      //   showCancelButton: true,
      //   confirmButtonText: "ยืนยัน",
      //   cancelButtonText: `ยกเลิก`,
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     navigateToBack("/sportType");
      //   }
      // });
    }
  };
  const postUpsportType = async () => {
    if (id !== null) {
      const filtered = sportTypeList.filter(
        (person) => person.sportType === ""
      );
      let arrSp = [];
      for (let i = 0; i < sportTypeList.length; i++) {
        arrSp.push(sportTypeList[i].sportType);
      }
      const formData = new FormData();
      let body = {
        sportName: null,
        subsport: null,
        active: checked === true || checked === 1 ? true : false,
        // activeSub: null,
        files: null,
      };
      if (sportName === null || sportName === "") {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        if (filtered.length > 0) {
          Swal.fire({
            title: "กรุณากรอกข้อมูลให้ครบถ้วน",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: `ยกเลิก`,
            reverseButtons: true,
          }).then((result) => {});
        } else {
          var dataImg = null;

          if (getDataimage[0]) {
            dataImg = getDataimage[0].file;

            body.sportName = sportName;
            body.subsport = arrSp;

            body.files = dataImg;

            await Swal.fire({
              title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "ยืนยัน",
              cancelButtonText: "ยกเลิก",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                // formData.append("subsport", body.subsport);
                (async () => {
                  const respon = await updateSport(id, body);

                  if (respon.data.status === "success") {
                    await Swal.fire({
                      title: "แก้ไขข้อมูลสำเร็จ",
                      icon: "success",
                      confirmButtonText: "ตกลง",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setLoading(1);
                        navigateToBack("/sportType");
                      }
                    });
                  } else {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      icon: "error",
                      showCancelButton: true,
                      confirmButtonText: "ยืนยัน",
                      cancelButtonText: `ยกเลิก`,
                      reverseButtons: true,
                    }).then((result) => {
                      navigateToBack("/sportType");
                    });
                  }
                })();
              }
            });
          } else {
            Swal.fire({
              title: "กรุณากรอกข้อมูลให้ครบถ้วน",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "ยืนยัน",
              cancelButtonText: `ยกเลิก`,
              reverseButtons: true,
            }).then((result) => {});
            dataImg = "";
          }
        }
      }
    } else {
      if (
        sportName.length < 1 ||
        sportName === null ||
        sportName === "" ||
        getDataimage[0] === undefined
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
      } else {
        // bodySend.sportName = sportName;
        const formData = new FormData();
        console.log("sportName test : ", sportName);
        let body = {
          sportName: sportName,
          file: getDataimage[0].file,
          active: checked === true || checked === 1 ? true : false,
        };

        await Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              //  return promise;
              const respon = await createSport(body);

              if (respon.data.status === "success") {
                await Swal.fire({
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoading(1);
                    navigateToBack("/sportType");
                  }
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: `ยกเลิก`,
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigateToBack("/sportType");
                  }
                });
              }
            })();
          }
        });
        // }
      }
    }
  };
  const getSportId = async (ID) => {
    let getData = [];
    try {
      const respon = await getSportById(ID);
      getData.push({
        createdAt: respon.data.data.createdAt,
        deletedAt: respon.data.data.deletedAt,
        deletedStatus: respon.data.data.deletedStatus,
        sportID: respon.data.data.sportID,
        sportName: respon.data.data.sportName,
        updatedAt: respon.data.data.updatedAt,
        active: respon.data.data.active,
        // active: respon.data.data.ac[]tive,
        subsport: respon.data.data.subsport,
        image: [
          {
            id: 0,
            status: false,
            src:
              respon.data.data.sportImage === null ||
              respon.data.data.sportImage === ""
                ? "assets/images/img-notimg.png"
                : respon.data.data.sportImage,
            file: "",
          },
        ],
      });
      let pushSpty = getData[0].subsport;
      let arrData = [];
      for (let i = 0; i < pushSpty.length; i++) {
        arrData.push({
          sportType: pushSpty[i].subSportName,
        });
      }
      setDataSportName(getData[0].sportName);

      if (
        getData[0].image[0].src ===
        "https://nw-smartsports.verkoffice.com/appnull"
      ) {
        setDataImage(0);
      } else {
        setDataImage(getData[0].image);
      }

      setChecked(getData[0].active);
      setsportTypeList(arrData);
    } catch (error) {
      console.log(error.message);
    }
  };
  // handleChangeSportName
  const handleChangeSportName = async (event) => {
    setDataSportName(null);
    if (event) {
      setDataSportName(event.target.value);
    }
  };
  useEffect(() => {
    if (id) {
      getSportId(id);
    }
  }, []);
  return (
    <>
      {/* {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()} */}
      <Header parentmenu={"ชนิดกีฬา"} />
      <Menu parentmenu={"masterData"} activemenu={"sportType"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ชนิดกีฬา
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / ชนิดกีฬา
              </font>
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0">
                <input
                  onChange={handleChangeSportName}
                  value={sportName}
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อกีฬา"
                />
              </div>

              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-5 mt-lg-0">
                {(() => {
                  if (getDataimage.length > 0) {
                    return (
                      <UploadIMGV2
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      ></UploadIMGV2>
                    );
                  } else {
                    return (
                      <UploadIMG
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      />
                    );
                  }
                })()}
                {/* <UploadIMG
                  limit={1}
                  onSelectedImages={handleSelectedImages}
                  image={getDataimage}
                /> */}
              </div>
            </div>
            <div className="row ml-0">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  defaultChecked={checked}
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  onClick={(event) => postUpsportType()}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>

          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/001-masterdata-sportType_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                {/* <UploadFileXLS limit={1} onChange={handleFileChange}/> */}
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                {/* <span className='mx-2'>{verifySuccess}/{totalRow}</span> */}
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0 ? true : false}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Addeditsporttype;
