import * as React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../CSS/theamColor.css";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import UploadIMG from "../uploads/UploadIMGv2";
import Buttonexport from "../button/Buttonexports";
import { getDataTheam, CreateTheme } from "../../api/theam_api";
import Swal from "sweetalert2";

function Thememanagecolor() {
  const [colorBut, setcolorBut] = useState("#0698FF");
  const [colorBack, setcolorBack] = useState("#0698FF");
  const [colorPro, setcolorPro] = useState("#0698FF");
  const [colorMenu, setcolorMenu] = useState("#0698FF");
  const [colorBarMenu, setcolorBarMenu] = useState("#0698FF");
  const [imgLogo, setImgLogo] = useState([]);
  const [imgMain, setImgMain] = useState([]);
  const [colorText, setColorText] = useState("#0698FF");
  const navigate = useNavigate();

  const colorPicked = useRef();
  const colorPickedBack = useRef();
  const colorPickedPro = useRef();
  const colorPickedMain = useRef();
  const colorPickedBar = useRef();
  const colorPickedText = useRef();

  const showSetColorBut = (event) => {
    if (event.target.id == "Button") {
      document.getElementById("colorPicked").style.display = "block";
      document.getElementById("colorPickedBack").style.display = "none";
      document.getElementById("colorPickedPro").style.display = "none";
      document.getElementById("colorPickedMain").style.display = "none";
      document.getElementById("colorPickedBar").style.display = "none";
      document.getElementById("colorPickedText").style.display = "none";
    } else if (event.target.id == "BackGround") {
      document.getElementById("colorPickedBack").style.display = "block";
      document.getElementById("colorPicked").style.display = "none";
      document.getElementById("colorPickedPro").style.display = "none";
      document.getElementById("colorPickedMain").style.display = "none";
      document.getElementById("colorPickedBar").style.display = "none";
      document.getElementById("colorPickedText").style.display = "none";
    } else if (event.target.id == "Profile") {
      document.getElementById("colorPickedPro").style.display = "block";
      document.getElementById("colorPicked").style.display = "none";
      document.getElementById("colorPickedBack").style.display = "none";
      document.getElementById("colorPickedMain").style.display = "none";
      document.getElementById("colorPickedBar").style.display = "none";
      document.getElementById("colorPickedText").style.display = "none";
    } else if (event.target.id == "Main") {
      document.getElementById("colorPickedMain").style.display = "block";
      document.getElementById("colorPicked").style.display = "none";
      document.getElementById("colorPickedBack").style.display = "none";
      document.getElementById("colorPickedPro").style.display = "none";
      document.getElementById("colorPickedBar").style.display = "none";
      document.getElementById("colorPickedText").style.display = "none";
    } else if (event.target.id == "BarMenu") {
      document.getElementById("colorPickedBar").style.display = "block";
      document.getElementById("colorPicked").style.display = "none";
      document.getElementById("colorPickedBack").style.display = "none";
      document.getElementById("colorPickedPro").style.display = "none";
      document.getElementById("colorPickedMain").style.display = "none";
      document.getElementById("colorPickedText").style.display = "none";
    } else if (event.target.id == "Text") {
      document.getElementById("colorPickedText").style.display = "block";
      document.getElementById("colorPicked").style.display = "none";
      document.getElementById("colorPickedBack").style.display = "none";
      document.getElementById("colorPickedPro").style.display = "none";
      document.getElementById("colorPickedMain").style.display = "none";
      document.getElementById("colorPickedBar").style.display = "none";
    }
  };
  const hideColorBut = () => {
    document.getElementById("colorPicked").style.display = "none";
    document.getElementById("colorPickedBack").style.display = "none";
    document.getElementById("colorPickedPro").style.display = "none";
    document.getElementById("colorPickedMain").style.display = "none";
    document.getElementById("colorPickedBar").style.display = "none";
    document.getElementById("colorPickedText").style.display = "none";
  };

  const pickedBut = (color) => {
    setcolorBut(color.hex, color);
  };

  const pickedBackApp = (color) => {
    setcolorBack(color.hex, color);
  };

  const pickedProfile = (color) => {
    setcolorPro(color.hex, color);
  };

  const pickedMain = (color) => {
    setcolorMenu(color.hex, color);
  };

  const pickedBar = (color) => {
    setcolorBarMenu(color.hex, color);
  };

  const pickedText = (color) => {
    setColorText(color.hex, color);
  };

  const getList = async () => {
    let themeData = [];
    try {
      const reasponse = await getDataTheam();

      themeData.push({
        colorBut: reasponse.data.data.theme.buttonColor,
        colorBack: reasponse.data.data.theme.appBackgroundColor,
        colorPro: reasponse.data.data.theme.profileBackgroundColor,
        colorMenu: reasponse.data.data.theme.mainmenuBackgroundColor,
        colorBarMenu: reasponse.data.data.theme.menubarColor,
        colorText: reasponse.data.data.theme.splashscreenColor,
        imgLogo: [
          {
            id: 0,
            status: false,
            src:
              reasponse.data.data.theme.appLogo === null ||
              reasponse.data.data.theme.appLogo === ""
                ? "assets/images/img-notimg.png"
                : reasponse.data.data.theme.appLogo,
            file: "null",
          },
        ],
        imgMain: [
          {
            id: 0,
            status: false,
            src:
              reasponse.data.data.theme.appBanner === null ||
              reasponse.data.data.theme.appBanner === ""
                ? "assets/images/img-notimg.png"
                : reasponse.data.data.theme.appBanner,
            file: "null",
          },
        ],
      });
      setcolorBut(themeData[0].colorBut);
      setcolorBack(themeData[0].colorBack);
      setcolorPro(themeData[0].colorPro);
      setcolorMenu(themeData[0].colorMenu);
      setcolorBarMenu(themeData[0].colorBarMenu);
      setColorText(themeData[0].colorText);
      setImgLogo(themeData[0].imgLogo);
      setImgMain(themeData[0].imgMain);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSelectedImages = (images) => {
    setImgLogo(images);

    // console.log(" cd : ", images); // do something with the selected images
  };
  const handleSelectedImagesv2 = (images) => {
    // console.log("a : ", images[0].file);
    setImgMain(images);
    // console.log(" ab : ", images); // do something with the selected images
  };
  useEffect(() => {
    getList();
  }, []);

  const CreateThemeData = async () => {
    const swalWithBootstrapButtons = Swal.mixin();
    if (imgLogo[0] === undefined || imgMain[0] === undefined) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      const body = {
        buttonColor: colorBut,
        appBackgroundColor: colorBack,
        profileBackgroundColor: colorPro,
        mainmenuBackgroundColor: colorMenu,
        menubarColor: colorBarMenu,
        splashscreenColor: colorText,
        appLogo: imgLogo[0].file,
        appBanner: imgMain[0].file,
      };

      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        (async () => {
          if (result.isConfirmed) {
            const response = await CreateTheme(body);
            if (response.data.status === "success") {
              Swal.fire({
                title: "บันทึกข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  getList();
                }
              });
            }
          } else {
            getList();
          }
        })();
      });
    }
  };

  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // navigateToBack("/home");

        navigateToBack("/manageThemeColor");
      }
    });
  }

  return (
    <>
      <Header parentmenu={"จัดการรูปแบบสี"} />
      <Menu parentmenu={"manageTheme"} activemenu={"manageThemeColor"} />
      <div className="content-wrapper">
        <div className="container-fluid">
          <Container fluid={true}>
            <div className="row mb-4">
              <div className="col-7 mt-2">
                <img
                  src="assets/icons/im-theme.png"
                  alt="Home Icon"
                  className="im-tiltle img-circle"
                />
                <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                  เมนูจัดการ Theme
                </font>
                <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                  | เมนูจัดการ Theme
                </font>
              </div>
              {/* <div className="col-5 d-flex justify-content-end align-items-center">
                <Buttonexport />
              </div> */}
            </div>
            <Col className="card-white">
              <Row className="mx-1">
                <Col xs={12} md={12} lg={12}>
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01 px-1"> จัดการ Theme</span>
                  </p>
                </Col>
              </Row>
              <Row className="mx-3 mt-4" onClick={showSetColorBut}>
                <Col xs={3} md={3} lg={2} className="text-input">
                  สีปุ่ม
                </Col>
                <Col xs={2} md={1} lg={1} className="text-center">
                  <div
                    className="preColor"
                    style={{ backgroundColor: colorBut }}
                  ></div>
                </Col>
                <Col xs={7} md={7} lg={6}>
                  <button
                    onClick={showSetColorBut}
                    className="text-area"
                    id="Button"
                  >
                    <span>{colorBut}</span>
                  </button>
                  <div
                    id="colorPicked"
                    className="colorPicked"
                    ref={colorPicked}
                  >
                    <button onClick={hideColorBut} className="btn-UploadColor">
                      เลือกสี
                    </button>
                    <SketchPicker
                      onChange={pickedBut}
                      color={colorBut}
                      id="butPicked"
                      className="color-picked"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mx-3 mt-4" onClick={showSetColorBut}>
                <Col xs={3} md={3} lg={2} className="text-input">
                  สีพื้นหลังแอปพลิเคชัน
                </Col>
                <Col xs={2} md={1} lg={1} className="text-center">
                  <div
                    className="preColor"
                    style={{ backgroundColor: colorBack }}
                  ></div>
                </Col>
                <Col xs={7} md={7} lg={6}>
                  <button
                    onClick={showSetColorBut}
                    className="text-area"
                    id="BackGround"
                  >
                    <span>{colorBack}</span>
                  </button>
                  <div
                    id="colorPickedBack"
                    className="colorPickedBack"
                    ref={colorPickedBack}
                  >
                    <button onClick={hideColorBut} className="btn-UploadColor">
                      เลือกสี
                    </button>
                    <SketchPicker
                      onChange={pickedBackApp}
                      color={colorBack}
                      id="butPicked"
                      className="color-picked"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mx-3 mt-4" onClick={showSetColorBut}>
                <Col xs={3} md={3} lg={2} className="text-input">
                  สีพื้นหลังโปรไฟล์
                </Col>
                <Col xs={2} md={1} lg={1} className="text-center">
                  <div
                    className="preColor"
                    style={{ backgroundColor: colorPro }}
                  ></div>
                </Col>
                <Col xs={7} md={7} lg={6}>
                  <button
                    onClick={showSetColorBut}
                    className="text-area"
                    id="Profile"
                  >
                    <span>{colorPro}</span>
                  </button>
                  <div
                    id="colorPickedPro"
                    className="colorPickedPro"
                    ref={colorPickedPro}
                  >
                    <button onClick={hideColorBut} className="btn-UploadColor">
                      เลือกสี
                    </button>
                    <SketchPicker
                      onChange={pickedProfile}
                      color={colorPro}
                      id="butPicked"
                      className="color-picked"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mx-3 mt-4" onClick={showSetColorBut}>
                <Col xs={3} md={3} lg={2} className="text-input">
                  สีพื้นหลังเมนูหลัก
                </Col>
                <Col xs={2} md={1} lg={1} className="text-center">
                  <div
                    className="preColor"
                    style={{ backgroundColor: colorMenu }}
                  ></div>
                </Col>
                <Col xs={7} md={7} lg={6}>
                  <button
                    onClick={showSetColorBut}
                    className="text-area"
                    id="Main"
                  >
                    <span>{colorMenu}</span>
                  </button>
                  <div
                    id="colorPickedMain"
                    className="colorPickedMain"
                    ref={colorPickedMain}
                  >
                    <button onClick={hideColorBut} className="btn-UploadColor">
                      เลือกสี
                    </button>
                    <SketchPicker
                      onChange={pickedMain}
                      color={colorMenu}
                      id="butPicked"
                      className="color-picked"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mx-3 mt-4" onClick={showSetColorBut}>
                <Col xs={3} md={3} lg={2} className="text-input">
                  สีแถบเมนู
                </Col>
                <Col xs={2} md={1} lg={1} className="text-center">
                  <div
                    className="preColor"
                    style={{ backgroundColor: colorBarMenu }}
                  ></div>
                </Col>
                <Col xs={7} md={7} lg={6}>
                  <button
                    onClick={showSetColorBut}
                    className="text-area"
                    id="BarMenu"
                  >
                    <span>{colorBarMenu}</span>
                  </button>
                  <div
                    id="colorPickedBar"
                    className="colorPickedBar"
                    ref={colorPickedBar}
                  >
                    <button onClick={hideColorBut} className="btn-UploadColor">
                      เลือกสี
                    </button>
                    <SketchPicker
                      onChange={pickedBar}
                      color={colorBarMenu}
                      id="butPicked"
                      className="color-picked"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mx-3 mt-5">
                <Col xs={4} md={4} lg={3} className="text-input">
                  รูปโลโก้
                </Col>
                <Col xs={8} md={8} lg={8}>
                  <UploadIMG
                    limit={1}
                    onSelectedImages={handleSelectedImages}
                    image={imgLogo}
                  ></UploadIMG>
                </Col>
              </Row>
              <Row className="mx-3 mt-5">
                <Col xs={4} md={4} lg={3} className="text-input">
                  รูปพื้นหลังหน้าแรก
                </Col>
                <Col xs={8} md={8} lg={8}>
                  <UploadIMG
                    limit={1}
                    onSelectedImages={handleSelectedImagesv2}
                    image={imgMain}
                  ></UploadIMG>
                </Col>
              </Row>
              <Row className="mx-3 mt-4">
                <Col xs={3} md={3} lg={2} className="text-input">
                  สีพื้นหลังข้อความหน้าแรก
                </Col>
                <Col xs={2} md={1} lg={1} className="text-center">
                  <div
                    className="preColor"
                    style={{ backgroundColor: colorText }}
                  ></div>
                </Col>
                <Col xs={7} md={7} lg={6}>
                  <button
                    onClick={showSetColorBut}
                    className="text-area"
                    id="Text"
                  >
                    <span>{colorText}</span>
                  </button>
                  <div
                    id="colorPickedText"
                    className="colorPickedText"
                    ref={colorPickedText}
                  >
                    <button onClick={hideColorBut} className="btn-UploadColor">
                      เลือกสี
                    </button>
                    <SketchPicker
                      onChange={pickedText}
                      color={colorText}
                      id="butPicked"
                      className="color-picked"
                    />
                  </div>
                </Col>
              </Row>
              {/* <Row className='my-5'>
              <Col xs={2}></Col>
              <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-blue02' onClick={CreateThemeData}>บันทึก</button></Col>
              <Col className='text-center' xs={4} md={4} lg={4}><button className='btn-cancle' >ยกเลิก</button></Col>
              <Col xs={2}></Col>
            </Row> */}
              <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
                <div className="col-3 col-sm-2 col-xl-1">
                  <button
                    className="btn-cancel"
                    onClick={() => {
                      cancelData();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="col-3 col-sm-2 col-xl-1">
                  <button className="btn-save" onClick={CreateThemeData}>
                    บันทึก
                  </button>
                </div>
              </div>
            </Col>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Thememanagecolor;
