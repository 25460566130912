import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Buttonexport from "../button/Buttonexports";
import Dropdown from "../dropdowns/Dropdown";
import Button3items from "../button/Button3items";
import { useNavigate } from "react-router-dom";
import {
  DataDropdownStoreType,
  DataDropdownCampaign,
} from "../dataMockup/DataMockDropdown";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import TableData from "../Tables/TableData";
import { getPromotion, getPromotionFilter } from "../../api/promotion_api";
import { getCampaign } from "../../api/campaign_api";
import Loading from "./Loading";
function Shopapprpromotion() {
  const DataDropdownShopBy = [
    { value: "", text: "ทั้งหมด" },
    { value: "1", text: "ร้านค้า" },
    { value: "2", text: "ร้านอาหาร" },
    { value: "3", text: "ร้านค้า/ร้านอาหาร" },
  ];
  const [spanloading, setspanLoading] = useState(false);
  const [inputForm, setInputForm] = useState({
    shopName: undefined, //ชื่อร้าน
    shopType: undefined, //ประเภทร้านค้า
    campaignName: undefined, //แคมเปญ
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const enableButton = {
    view: false,
    edit: false,
    delete: false,
    add: false,
    approve: true,
  };

  const columnsname = [
    "id",
    "storeName",
    "promotionType",
    "promotionID",
    "promotionName",
    "campaignName",

    "status",
    "amount",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "No",
    "ชื่อร้าน",
    "ประเภทร้าน",
    "promotionID",
    "ชื่อโปรโมชัน",
    "แคมเปญ",
    "สถานะ",
    "จำนวนสิทธิ์",
    "อัปเดตล่าสุด",
    "อนุมัติ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];
  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   storeName: { value: "ร้านน้องน้ำ", type: "text" },
    //   promotionID: { value: "PO001", type: "text" },
    //   promotionName: { value: "โปรโมชัน01", type: "text" },
    //   campaignName: { value: "แคมเปญ01", type: "text" },
    //   status: { value: "รออนุมัติ", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];
  const navigate = useNavigate();
  const [dataPromotion, setDataPromotion] = useState([]);
  const [dataCampaign, setDataCampaign] = useState([]);
  const handleExport = async (type) => {
    let Header = [
      "No",
      "ชื่อร้าน",
      "promotionID",
      "ชื่อโปรโมชัน",
      "เเคมเปญ",
      "สถานะ",
      "อัปเดตล่าสุด",
    ];

    let Data = dataPromotion.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.storeName.value;
      dt[2] = elemento.promotionID.value;
      dt[3] = elemento.promotionName.value;
      dt[4] = elemento.campaignName.value;
      dt[5] = elemento.status.value;
      dt[6] = elemento.updateddate.value;
      return dt;
    });

    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานโปรโมชันที่รออนุมัติ",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  useEffect(() => {
    getDataPromotion();
    getCampaginDropDown();
  }, []);

  const getDataPromotion = async () => {
    setspanLoading(true);
    try {
      let data = { promotionStatus: "waiting" };
      const respon = await getPromotion(data);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.promotionID },
          storeName: {
            value:
              objectData.joinCampaign.shop.shopName === null
                ? "-"
                : objectData.joinCampaign.shop.shopName,
            type: "text",
          },
          promotionType: {
            value: objectData.shopType,
            type: "text",
          },
          promotionID: {
            value:
              objectData.promotionID === null ? "-" : objectData.promotionID,
            type: "text",
          },
          promotionName: {
            value:
              objectData.promotionDetail === null ||
              objectData.promotionDetail === ""
                ? "-"
                : objectData.promotionDetail,
            type: "text",
          },
          campaignName: {
            value:
              objectData.joinCampaign.campaign.campaignName === null
                ? "-"
                : objectData.joinCampaign.campaign.campaignName,
            type: "text",
          },
          status: {
            // value: objectData.promotionStatus !== true ? "รออนุมัติ" : "ใช้งาน",
            value:
              objectData?.joinCampaign?.campaign?.activeStatus !== true
                ? "ไม่ใช้งาน"
                : "ใช้งาน",

            // active: objectData.active,
            type: "text",
          },
          amount: {
            value: objectData.promotionAmount,
            type: "text",
          },
          updateddate: {
            value:
              objectData.createdAt === null
                ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      // const res = await getCampaign();
      // let arrData = [];
      // res.data.data.forEach((element, i) => {
      //   return arrData.push({
      //     value: element.campaignID,
      //     text: element.campaignName === null ? "-" : element.campaignName,
      //   });
      // });
      // setDataCampaign(arrData);
      await setspanLoading(false);
      setDataPromotion(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getDataFilter = async () => {
    setspanLoading(true);
    if (
      inputForm.shopName !== "" ||
      selectedShop !== "" ||
      selectedCampagin !== ""
    ) {
      let bodyFilter = {
        promotionStatus: "waiting",
        inp1: inputForm.shopName,
        inp2: selectedShop,
        inp3: selectedCampagin,
      };
      const response = await getPromotionFilter(bodyFilter);

      response.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          id: { value: i + 1, type: "text", dataID: objectData.promotionID },
          storeName: {
            value:
              objectData.joinCampaign.shop.shopName === null
                ? "-"
                : objectData.joinCampaign.shop.shopName,
            type: "text",
          },
          promotionType: {
            value: objectData.shopType,
            type: "text",
          },

          promotionID: {
            value:
              objectData.promotionID === null ? "-" : objectData.promotionID,
            type: "text",
          },
          promotionName: {
            value:
              objectData.promotionDetail === null ||
              objectData.promotionDetail === ""
                ? "-"
                : objectData.promotionDetail,
            type: "text",
          },
          campaignName: {
            value:
              objectData.joinCampaign.campaign.campaignName === null
                ? "-"
                : objectData.joinCampaign.campaign.campaignName,
            type: "text",
          },
          status: {
            // value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
            value:
              objectData?.joinCampaign?.campaign?.activeStatus !== true
                ? "ไม่ใช้งาน"
                : "ใช้งาน",

            // active: objectData.active,
            type: "text",
          },
          amount: {
            value: objectData.promotionAmount,
            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt !== null
                ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),

            type: "text",
          },
          action: { value: "1", type: "action" },
        });
      });
      await setspanLoading(false);
      setDataPromotion(rowsdata);
    } else {
      getDataPromotion();
    }
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addapprovepromotion`);
        break;
      case "edit":
        // handle edit button
        navigate(`/Addapprovepromotion?id=${id}`);
        // navigate(`/Addeditathlete/?id=${id}`)
        break;
      case "view":
        // handle view button

        break;
      case "approve":
        // handle approve button
        navigate(`/Addapprovepromotion?id=${id}`);
        break;
      case "cancel":
        // handle approve button
        break;
      case "delete":
        // handle delete button

        break;
      default:
        console.log("Invalid button type");
    }
  };

  const [valuefromparent1, setValuefromparent1] = useState("ทั้งหมด");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [name, setName] = useState("");

  function resetDropdown() {
    setName("");
    inputForm.shopName = "";
    setSelecteCampagin("");
    setSelecteShop("");
    setValuefromparent1("ทั้งหมด");
    setValuefromparent2("ทั้งหมด");
    getDataPromotion();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent1(text) {
    setValuefromparent1(text);
  }
  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("shopName", e.target.value);
      setName(e.target.value);
    }
  };

  const getCampaginDropDown = async () => {
    try {
      const response = await getCampaign();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.campaignID,
            text: item.campaignName,
            subType: [],
          });
        });

        setDataCampaign(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedShop, setSelecteShop] = useState("");
  const handleShopChange = (value) => {
    setSelecteShop(value);
  };

  const [selectedCampagin, setSelecteCampagin] = useState("");
  const handleCampaginChange = (value) => {
    setSelecteCampagin(value);
  };

  return (
    <>
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"โปรโมชัน ที่รออนุมัติ"} />
      <Menu parentmenu={"shopData"} activemenu={"approvePromotion"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-shop.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                โปรโมชัน ที่รออนุมัติ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลร้านค้า ร้านอาหาร / โปรโมชัน ที่รออนุมัติ
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <Button3items onExportClicked={handleExport} />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อร้าน"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-12 col-lg-3 pr-0 mt-2 mt-lg-0">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ประเภทร้านค้า
                  </p>
                  <Dropdown
                    options={DataDropdownShopBy}
                    setinput={setinput}
                    inputname={"shopType"}
                    ChangesetValuefromparent={ChangesetValuefromparent1}
                    valuefromparent={valuefromparent1}
                    onChange={handleShopChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-3 pr-0 mt-lg-0 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">แคมเปญ</p>
                  <Dropdown
                    options={dataCampaign}
                    setinput={setinput}
                    inputname={"campaignName"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                    onChange={handleCampaginChange}
                  />
                </div>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-blue01" onClick={(e) => getDataFilter()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline-block">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-2 mt-lg-0">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline-block">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการ
                  โปรโมชันที่รออนุมัติ
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataPromotion}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
              // pageApproveName={"/Addapprovepromotion"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Shopapprpromotion;
