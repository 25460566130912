import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableData from "../Tables/TableData";
import Footer from "../template/Footer";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Swal from "sweetalert2";

function AddcompetresultsTeams() {
  const enableButton = {
    view: false,
    edit: false,
    delete: false,
    add: false,
    approve: false,
  };
  const [inputForm, setInputForm] = useState({
    inp1: "กีฑา", //ชนิดกีฬา
    inp2: "หญิงเดี่ยว", //ประเภทการแข่งขัน
    inp3: "สนามกีฬา 1", //สนามกีฬา
    inp4: "หญิงเดี่ยว4x100เมตร", //ชื่อรายการแข่งขัน
    inp5: "รอบชิงชนะเลิศ", //รอบการแข่งขัน
    inp6: "เดี่ยว", //ประเภท
    inp7: "20/05/2023", //วันที่แข่ง
    inp8: "1:30 PM", //เวลาที่แข่ง
    inp9: "กรุงเทพมหานคร", //สังกัด
    inp10: "", //คะแนน
    inp11: "จันทบุรี", //สังกัด
    inp12: "", //คะแนน
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/competitionResults");
      }
    });
  }

  const columnsname = ["id", "name", "affiliation", "updateddate"];
  const columnsText = ["No", "ชื่อ", "สังกัด", "อัปเดตล่าสุด"];
  const columnsType = ["text", "text", "text", "text"];
  const rowsdata = [
    {
      id: { value: "1", type: "text" },
      name: { value: "นางสาวเทควันโด ดีงาม", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
    },
    {
      id: { value: "2", type: "text" },
      name: { value: "นายบาสบอล สุดเขต", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
    },
    {
      id: { value: "3", type: "text" },
      name: { value: "นายนาวา แสนดี", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
    },
    {
      id: { value: "4", type: "text" },
      name: { value: "นายแข็งแกร่ง ย่านเทพ", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
    },
    {
      id: { value: "5", type: "text" },
      name: { value: "นางสาวขวัญดี มีศรี", type: "text" },
      affiliation: { value: "กรุงเทพมหานคร", type: "text" },
      updateddate: { value: "7/5/2022", type: "text" },
    },
  ];
  return (
    <>
      <Header parentmenu={"ผลการแข่งขัน"} />
      <Menu parentmenu={"competitionData"} activemenu={"competitionResults"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-12 mt-2">
              <img
                src="assets/icons/im-competition.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ผลการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการแข่งขัน / ผลการแข่งขัน
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูลการแข่งขัน
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <input
                  id="inputSportType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp1}
                  onChange={(e) => setinput("inp1", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <input
                  id="inputSportSubType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp2}
                  onChange={(e) => setinput("inp2", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <input
                  id="inputArena"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp3}
                  onChange={(e) => setinput("inp3", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputSportType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp4}
                  onChange={(e) => setinput("inp4", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputSportSubType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp5}
                  onChange={(e) => setinput("inp5", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputArena"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp6}
                  onChange={(e) => setinput("inp6", e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputSportType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp7}
                  onChange={(e) => setinput("inp7", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputSportSubType"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp8}
                  onChange={(e) => setinput("inp8", e.target.value)}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการนักกีฬา
                </p>
              </div>
            </div>
            <div className="row mb-4 d-flex justify-content-between">
              <div className="col-12 col-md-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputAffiliation"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp9}
                  onChange={(e) => setinput("inp9", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-md-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputScore"
                  className="inp-data01 fontReg font-size03"
                  placeholder="คะแนน"
                  value={inputForm.inp10}
                  onChange={(e) => setinput("inp10", e.target.value)}
                />
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowsdata}
              isSearchEnable={false}
            />
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  รายการนักกีฬา
                </p>
              </div>
            </div>
            <div className="row mb-4 d-flex justify-content-between">
              <div className="col-12 col-md-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputAffiliation"
                  className="inp-data01 fontReg font-size03"
                  value={inputForm.inp11}
                  onChange={(e) => setinput("inp11", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-md-4 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <input
                  id="inputScore"
                  className="inp-data01 fontReg font-size03"
                  placeholder="คะแนน"
                  value={inputForm.inp12}
                  onChange={(e) => setinput("inp12", e.target.value)}
                />
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={rowsdata}
              isSearchEnable={false}
            />
          </div>
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button className="btn-save">บันทึก</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddcompetresultsTeams;
