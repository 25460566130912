import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import UploadIMG from "../uploads/UploadIMG";
// test Uploadpic-Test
// import UploadIMG from "../uploads/Uploadpic-Test";
// test-up
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import UploadIMGV2 from "../uploads/UploadIMGv2";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { verifyImport, submitImportData } from "../../api/arena_api";
import ImportExcel from "../uploads/ExcelRender";
import Loading from "../pages/Loading";
import {
  dataObject,
  getDataAreaByID,
  postDataUpdateArea,
  postCreateSubarena,
  postCreateArea,
} from "../config/configApi-Area";
import { async } from "q";
import { result } from "lodash";
function Addeditarena(props) {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [groupShow, setGroupShow] = useState(false);
  let id = new URLSearchParams(window.location.search).get("id");
  const [arenaTypeList, setarenaTypeList] = useState([
    {
      id: id,
      arenaType: "",
      subArenaName: "",
      subArenaGate: "",
    },
  ]);
  const [getDataarea, setDataarea] = useState([]);
  // subArea
  const [getDatasubArea, setDatasubArea] = useState([]);
  const [getDataimage, setDataImage] = useState([]);
  const [getDataimageChk, setDataImageChk] = useState([]);
  let [areaName, setAreaName] = useState(null);
  let [step, setStep] = useState(1);
  let [areaLat, setAreaLat] = useState(null);
  let [areaLong, setAreaLong] = useState(null);
  let [areaDesc, setAreaDesc] = useState(null);
  let [areaOther, setAreaOther] = useState(null);
  let [img2, setImg2] = useState([]);
  let [image, setImage] = useState([]);
  let dtChecked = null;
  let [checked, setChecked] = useState(false);
  const [isFirstLoadingPage, setIsFirstLoadingPagee] = useState(false);

  const handleArenaChange = (e, index) => {
    setarenaTypeList(null);
    const { name, value } = e.target;
    const list = [...arenaTypeList];
    list[index][name] = value;
    setarenaTypeList(list);
  };
  function navigateToBack(page) {
    navigate(page);
  }
  const handleArenaRemove = (index) => {
    const list = [...arenaTypeList];
    list.splice(index, 1);
    setarenaTypeList(list);
  };
  const swalWithBootstrapButtons = Swal.mixin();
  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/arena");
      }
    });
  }
  const handleArenaAdd = () => {
    setarenaTypeList([
      ...arenaTypeList,
      { id: id, arenaType: "", subArenaName: "", subArenaGate: "" },
    ]);
  };

  const handleSelectedImages = (images) => {
    setDataImage(images);
  };
  useEffect(() => {
    if (id !== null) {
      getAreaID(id);
    }
  }, []);
  const getAreaID = async (Id) => {
    let getData = [];
    const respon = await getDataAreaByID(Id);
    getData.push({
      arenaID: respon.data.data.arenaID,
      arenaName: respon.data.data.arenaName,
      active: respon.data.data.active,
      createdAt: respon.data.data.createdAt,
      deletedAt: respon.data.data.deletedAt,
      description:
        respon.data.data.description === null ||
        respon.data.data.description === ""
          ? ""
          : respon.data.data.description,
      image: [
        {
          id: 0,
          status: false,
          src:
            respon.data.data.img0 === null || respon.data.data.img0 === ""
              ? ""
              : respon.data.data.img0,
          file:
            respon.data.data.img0 === null || respon.data.data.img0 === ""
              ? ""
              : "",
        },
        {
          id: 1,
          status: false,
          src:
            respon.data.data.img1 === null || respon.data.data.img1 === ""
              ? ""
              : respon.data.data.img1,
          file:
            respon.data.data.img1 === null || respon.data.data.img1 === ""
              ? ""
              : "",
        },
        {
          id: 2,
          status: false,
          src:
            respon.data.data.img2 === null || respon.data.data.img2 === ""
              ? ""
              : respon.data.data.img2,
          file:
            respon.data.data.img2 === null || respon.data.data.img2 === ""
              ? ""
              : "",
        },
        {
          id: 3,
          status: false,
          src:
            respon.data.data.img3 === null || respon.data.data.img3 === ""
              ? ""
              : respon.data.data.img3,
          file:
            respon.data.data.img3 === null || respon.data.data.img3 === ""
              ? ""
              : "",
        },
        {
          id: 4,
          status: false,
          src:
            respon.data.data.img4 === null || respon.data.data.img4 === ""
              ? ""
              : respon.data.data.img4,
          file:
            respon.data.data.img4 === null || respon.data.data.img4 === ""
              ? ""
              : "",
        },
      ],
      latitude:
        respon.data.data.latitude === null ? "" : respon.data.data.latitude,

      longitude:
        respon.data.data.longitude === null ? "" : respon.data.data.longitude,
      subArea: respon.data.data.subArea,

      updatedAt: respon.data.data.updatedAt,
    });
    dtChecked = getData[0].active;
    setChecked(dtChecked);
    setAreaOther(respon.data.data.remark);
    const filteredDataArray = getData[0].image.filter((data) => {
      return data.src !== "";
    });
    setDataImageChk(filteredDataArray);
    setDataImage(filteredDataArray);
    // setDataImage(getData[0].image);
    setDataarea(getData[0]);
    setDatasubArea(getData[0].subArea);

    let arrSubarea = [
      {
        id: id,
        arenaType: "",
        subArenaName: "",
        subArenaGate: "",
      },
    ];
    let Arrsub = getData[0].subArea;
    for (let i = 0; i < Arrsub.length; i++) {
      arrSubarea.push({
        id: Arrsub[i].subArenaID,
        arenaType: Arrsub[i].subArenaName,
        subArenaName: Arrsub[i].subArenaName,
        subArenaGate: Arrsub[i].gate,
      });
    }
    setarenaTypeList(arrSubarea);
    setAreaName(getData[0].arenaName);
    setAreaLat(getData[0].latitude);
    setAreaLong(getData[0].longitude);
    setAreaDesc(getData[0].description);
    // setAreaOther(null);
  };

  const [dataUrl, setDataUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [dataImgUpdate, setDataImgUpdate] = useState([]);
  // console.log("dataUrl data: ", dataUrl);
  console.log("imageFile data: ", imageFile);
  // test
  console.log("dataImgUpdate data : ", dataImgUpdate);
  // --------
  const postAreaedit = async () => {
    if (id !== null) {
      const filtered = arenaTypeList.filter(
        (person) => person.arenaType === ""
      );
      let body = {
        arenaName: null,
        description: null,
        latitude: null,
        longitude: null,
        active: null,
        // หมายเหตุ
        remark: null,
        // รูป
        files: null,
      };

      if (
        areaName === null ||
        areaName === "" ||
        areaLat === "" ||
        areaLat === null ||
        areaLong === "" ||
        areaLong === null ||
        areaDesc === null ||
        areaDesc === ""
        // ||
        // areaOther === null ||
        // areaOther === ""
      ) {
        swalWithBootstrapButtons.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
      } else {
        const formData = new FormData();
        body.arenaName = areaName;
        body.description = areaDesc;
        body.latitude = areaLat;
        body.longitude = areaLong;
        body.remark = areaOther;
        // test

        // body.files = getDataimage[0].file;
        console.log("getDataimage data check : ", getDataimage);
        console.log("getDataimageChk data check : ", getDataimageChk);
        const fileImg = [];
        let filteredData;
        for (let i = 0; i < getDataimage.length; i++) {
          // test
          fileImg.push(
            getDataimage[i].file ? getDataimage[i].file : getDataimage[i].src
          );
          console.log("fileImg result : ", fileImg);
          if (getDataimage[i].file) {
            // fileImg.push(
            //   getDataimage[i].file ? getDataimage[i].file : getDataimage[i].src
            // );
          } else {
            // test-------
            const convertedData = await Promise.all(
              getDataimage.map(async (item) => {
                try {
                  const imageUrl = item.src;
                  const response = await fetch(imageUrl);

                  if (!response.ok) {
                    throw new Error(`Failed to fetch image ${item.id}`);
                  }

                  const blob = await response.blob();

                  // Convert the Blob to a File object
                  const fileName = `image_${item.id}.jpg`;
                  const fileType = "image/jpeg";
                  const file = new File([blob], fileName, { type: fileType });

                  // Convert Blob to a data URL
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);

                  return new Promise((resolve) => {
                    reader.onloadend = () => {
                      const dataURL = reader.result;
                      resolve({ id: item.id, src: dataURL, file });
                    };
                  });
                } catch (error) {
                  console.error(
                    `Error fetching and converting image ${item.id}:`,
                    error
                  );
                  return null;
                }
              })
            );
            // Filter out any failed image conversions (where the result is null)
            filteredData = convertedData.filter(Boolean);
            setDataImgUpdate(filteredData);
            // ---------
            // test
            // try {
            //   const imageUrl = getDataimage[i].src;
            //   const response = await fetch(imageUrl);
            //   if (!response.ok) {
            //     throw new Error("Failed to fetch image");
            //   }
            //   const blob = await response.blob();
            //   const reader = new FileReader();
            //   const fileName = "image" + getDataimage[i].id + ".jpg";
            //   const fileType = "image/jpeg";
            //   let file = null;
            //   console.log("");
            //   let dataArr = [];
            //   reader.onloadend = () => {
            //     const dataURL = reader.result;
            //     setDataUrl(dataURL);
            //     file = new File(
            //       [blob],
            //       fileName,
            //       { type: fileType },
            //       { file: dataURL }
            //     );
            //     dataArr.push(file);
            //     setImageFile(file);
            //   };
            //   console.log("dataArr data : ", dataArr);
            //   reader.readAsDataURL(blob);
            // } catch (error) {
            //   console.error("Error fetching and converting image:", error);
            // }
          }
          //
          console.log("getDataimage[i] : ", getDataimage[i]);
          // if (getDataimage[i].file !== "") {

          // }
        }
        // dataImgUpdate
        body.files = filteredData;
        body.active = checked;

        for (let i = 0; i < arenaTypeList.length; i++) {
          if (arenaTypeList[i].subArenaName === "") {
            arenaTypeList[i].subArenaName = arenaTypeList[i].arenaType;
            arenaTypeList[i].id = parseFloat(arenaTypeList[i].id);
          }
        }
        // let mapData = arenaTypeList.map((element, i) => {
        //   return {
        //     id: element.id,
        //     subArenaName: element.arenaType,
        //   };
        // });
        Swal.fire({
          title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              setLoading(true);
              const respon = await postDataUpdateArea(id, body);
              // = null;

              await setLoading(false);
              if (respon) {
                if (respon.data.status === "success") {
                  Swal.fire({
                    title: "แก้ไขข้อมูลสำเร็จ",
                    icon: "success",
                    confirmButtonText: "ยืนยัน",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/arena");
                    }
                  });
                }
              } else {
                Swal.fire({
                  title: "รองรับไฟล์ PNG, JPG ขนาดภาพไม่เกิน 3MB",
                  icon: "error",
                  confirmButtonText: "ยืนยัน",
                });
              }
            })();
          }
        });
      }
      // }
    } else {
      if (
        areaName === null ||
        areaName === "" ||
        areaLat === "" ||
        areaLat === null ||
        areaLong === "" ||
        areaLong === null ||
        areaDesc === null ||
        areaDesc === ""
        // areaOther === null ||
        // areaOther === ""
      ) {
        swalWithBootstrapButtons.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
      } else {
        let body = {
          arenaName: null,
          description: null,
          latitude: null,
          longitude: null,
          active: null,
          files: null,
        };
        const formData = new FormData();
        body.arenaName = areaName;
        body.description = areaDesc;
        body.latitude = areaLat;
        body.longitude = areaLong;
        body.remark = areaOther;
        // body.Other = areaOther;
        body.files = img2;
        if (checked === null) {
          body.active = false;
        } else {
          body.active = checked;
        }

        // formData.append("arenaName", body.arenaName);
        // formData.append("active", body.active);
        // formData.append("description", body.description);
        // formData.append("latitude", body.latitude);
        // formData.append("longitude", body.longitude);
        // formData.append("files", body.files);
        const fileeee = [];
        for (let i = 0; i < getDataimage.length; i++) {
          fileeee.push(getDataimage[i].file);
        }
        body.files = fileeee;
        let mapData = arenaTypeList
          .map((element, i) => {
            return {
              id: null,
              subArenaName: element.subArenaName,
            };
          })
          .filter((item) => {
            return item.subArenaName !== "";
          });
        Swal.fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              setLoading(true);
              const respon = await postCreateArea(body);
              await setLoading(false);
              if (respon) {
                if (respon.data.status === "success") {
                  Swal.fire({
                    title: "บันทึกข้อมูลสำเร็จ",
                    icon: "success",
                    confirmButtonText: "ยืนยัน",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/arena");
                    }
                  });
                }
              } else {
                Swal.fire({
                  title: "รองรับไฟล์ PNG, JPG ขนาดภาพไม่เกิน 3MB",
                  icon: "error",
                  confirmButtonText: "ยืนยัน",
                });
              }
            })();
          }
        });
      }
    }
  };
  const handleChangeArea = async (event) => {
    setAreaName(null);
    if (event) {
      setAreaName(event.target.value);
    }
  };

  const handleChangeDesc = async (event) => {
    setAreaDesc(null);
    if (event) {
      setAreaDesc(event.target.value);
    }
  };
  const handleChangeLat = async (event) => {
    setAreaLat(null);
    if (event) {
      setAreaLat(event.target.value);
    }
  };
  const handleChangeLong = async (event) => {
    setAreaLong(null);

    if (event) {
      setAreaLong(event.target.value);
    }
  };
  const handleChangeOther = async (event) => {
    setAreaOther(null);
    if (event) {
      setAreaOther(event.target.value);
    }
  };

  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);

  const handleImport = async (rows) => {
    // console.log("rows data : ", rows);
    // console.log("rows.length - 1 preview : ", rows.length - 1);
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setTotalRow(rows.length - 1);
    // console.log("rows.slice(1) preview  ", rows.slice(1));
    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          arenaName: item[0],
          active: item[1],
          remark: item[2],
          latitude: item[3],
          longitude: item[4],
        });
      }
    });

    await setImportData(row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          importData: data,
        };
        const response = await verifyImport(dataToVerify);
        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      if (error === 0) {
        Swal.fire({
          title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
          icon: "success",
          confirmButtonText: "ตกลง",
        });
        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importSport = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;
        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              // memberType: 2,
              importData: data,
            };
            // const response = null;
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      if (error === true) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/arena");
          }
        });
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/arena");
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/arena");
        }
      });
    }
  };

  return (
    <>
      {(() => {
        if (isLoading === true) {
          return <Loading />;
        }
        // else if (isLoading === 2) {
        //   return <ToastWarning />;
        // } else if (isLoading === 3) {
        //   return <ToastError />;
        // }
      })()}
      <Header parentmenu={"สนามกีฬา"} />
      <Menu parentmenu={"masterData"} activemenu={"arena"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                สนามกีฬา
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / สนามกีฬา
              </font>
            </div>
            {id ? (
              <></>
            ) : (
              <>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    !groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(true)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลหลายรายการ
                    </span>
                  </button>
                </div>
                <div
                  className={`col-4 col-sm-3 justify-content-end align-items-center ${
                    groupShow ? "d-flex" : "d-none"
                  } `}
                >
                  <button
                    className="btn-blue03 mr-2 mr-xl-3 w-100"
                    onClick={() => setGroupShow(false)}
                  >
                    <img
                      src="assets/icons/ic-add.png"
                      alt="ExportFiles Icon"
                      className="ic-add"
                    />
                    <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                      เพิ่มข้อมูลรายการเดียว
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row justify-content-start mb-0 mb-lg-3">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0">
                <div className="row">
                  <div className="col-12 mb-0 mb-lg-3">
                    <input
                      onChange={handleChangeArea}
                      value={areaName}
                      className="inp-data01 fontReg font-size03"
                      placeholder="ชื่อสนามกีฬา"
                    />
                  </div>
                  <div className="col-12 mt-2 mt-lg-0">
                    <input
                      className="inp-data01 fontReg font-size03"
                      placeholder="ละติจูด"
                      value={areaLat}
                      onChange={handleChangeLat}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 pr-2 pr-lg-0 mt-2 mt-lg-0">
                <textarea
                  id="messages"
                  type="text"
                  name="messages"
                  className="inp-area"
                  rows="6"
                  // maxlength="1000"
                  placeholder="รายละเอียดสนาม"
                  defaultValue={areaDesc}
                  onChange={handleChangeDesc}
                />
              </div>
            </div>
            <div className="row justify-content-start mb-3 mb-md-5">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ลองจิจูด"
                  value={areaLong}
                  onChange={handleChangeLong}
                />
              </div>
              <div className="col-12 col-lg-7 pr-2 pr-lg-0 mt-2 mt-lg-0">
                <input
                  onChange={handleChangeOther}
                  value={areaOther}
                  className="inp-data01 fontReg font-size03"
                  placeholder="หมายเหตุ"
                />
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 pr-2 pr-lg-0 mt-5 mt-lg-0">
                {(() => {
                  if (id && getDataimage.length > 0) {
                    return (
                      <UploadIMGV2
                        limit={5}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      ></UploadIMGV2>
                    );
                  } else {
                    return (
                      <UploadIMG
                        limit={5}
                        onSelectedImages={handleSelectedImages}
                        image={getDataimage}
                      />
                    );
                  }
                })()}
              </div>
            </div>
            {/* <div className="row ml-0">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  defaultChecked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <span className="checkmark" />
              </label>
            </div> */}
            <div className="row ml-0 mt-3">
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                ใช้งาน
                <input
                  type="checkbox"
                  checked={checked}
                  value={checked}
                  onChange={() => setChecked((prev) => !prev)}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  onClick={(event) => postAreaedit()}
                  className="btn-save"
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/003-masterdata-areana_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                {/* <UploadFileXLS limit={1} onChange={handleFileChange}/> */}
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                {/* <span className='mx-2'>{verifySuccess}/{totalRow}</span> */}
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0 ? true : false}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>{" "}
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditarena;
