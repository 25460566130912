import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import iconBanner from "./img/im-boxing.png";
const defaultOptions = {
  name: "Export",
  data: null,
  header: null,
  tableOptions: {
    name: "TableExport",
    ref: "A1",
    // style: {
    //   theme: "TableStyleLight1",
    //   showRowStripes: true,
    // },
    columns: [
      { name: "ลำดับที่", filterButton: true },
      { name: "ชื่อสนาม", filterButton: true },
      { name: "จำนวนสนาม ", filterButton: true },
      { name: "สถานะ", filterButton: true },
      { name: "อัปเดตล่าสุด", filterButton: true },
      // { name: "", filterButton: false },
      // { name: "อัปเดตล่าสุด", filterButton: true },
    ],
  },
};

export const useExcelExport = (props) => {
  const generate = (data, workbookOptions, tableOptions) => {
    const configuration = { ...defaultOptions.tableOptions, ...tableOptions };

    let dataBody;
    let cols = [
      // { name: "ลำดับที่", filterButton: true },
      // { name: "ชื่อสนาม", filterButton: true },
      // { name: "จำนวนสนาม ", filterButton: true },
      // { name: "สถานะ", filterButton: true },
      // { name: "อัปเดตล่าสุด", filterButton: true },
    ];
    // console.log("data[0].body preview : ", data[0].body);
    if (data[0].body.length > 0) {
      // console.log("data[0] ", data[0]);
      dataBody = [...data[0].body];
      for (let i = 0; i < data[0].header.length; i++) {
        cols.push({
          name: data[0].header[i],
          //สำหรับ filter
          filterButton: false,
        });
      }
    }
    // console.log("dataBody preview : ", dataBody);
    var workbook = new Workbook();
    var sheet = workbook.addWorksheet(workbookOptions.name);
    var col = sheet.getColumn(1);
    col.outlineLevel = 1;

    // // Set the background color of the cell
    // cell.fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "3b4752a8" }, // This is red color as an example, you can replace with your desired color code
    // };
    if (workbookOptions.name === "รายงาน Dashboard") {
      // test 3
      const desiredStyle = {
        font: {
          name: "TH SarabunPSK",
          size: 16,
          bold: true,
        },
        alignment: {
          vertical: "middle",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        },
      };
      let arrDataApp = [];
      let dtArrApp = data[0].body[0];
      let amountSum = [];

      for (let i = 0; i < dtArrApp.length; i++) {
        amountSum.push(dtArrApp[i][2]);
        arrDataApp.push({
          id: dtArrApp[i][0],
          value: dtArrApp[i][1],
          amount: dtArrApp[i][2] + " คน",
        });
      }
      const newSum = amountSum.reduce((acc, current) => acc + current, 0);
      sheet.mergeCells("A1", "C1");
      sheet.getCell("C1").value = "รายงานปริมาณผู้ใช้งานแอปพลิเคชัน";
      sheet.getRow(2).values = ["ลำดับ", "ช่วงอายุ", "จำนวน"];

      sheet.columns = [
        { key: "id", width: 15 },
        { key: "value", width: 60 },
        { key: "amount", width: 15 },
      ];

      arrDataApp.forEach(function (item, index) {
        sheet.addRow({
          id: item.id,
          value: item.value,
          amount: item.amount,
        });
      });

      // sheet.columns = [
      //   { key: "score", width: 15 },
      //   { key: "name", width: 40 },
      // ];
      sheet.mergeCells("A12", "B12");
      sheet.getCell("B12").value = "จำนวนทั้งหมด ";
      sheet.getCell("C12").value = newSum + " คน";
      sheet.mergeCells("A14", "B14");
      sheet.getCell("B14").value = "รายงานผลโหวตกีฬาที่สนใจ";
      sheet.getRow(15).values = ["ลำดับ", "กีฬา"];

      let dtArrSport = data[0].body[1];
      dtArrSport.forEach(function (item, index) {
        sheet.addRow({
          id: item[0],
          value: item[1],
        });
      });
      sheet.mergeCells("A20", "C20");
      sheet.getCell("C20").value = "รายงานปริมาณผู้เข้าร่วมแคมเปญ";
      sheet.getRow(21).values = ["ลำดับ", "แยกตามประเภทร้าน", "จำนวน"];
      let dtArrStore = data[0].body[2];

      dtArrStore.forEach(function (item, index) {
        sheet.addRow({
          id: item[0],
          value: item[1],
          amount: item[2],
        });
      });
      sheet.mergeCells("A25", "B25");
      sheet.getCell("B25").value = "จำนวนทั้งหมด ";
      sheet.getCell("C25").value = data[0].body[3] + " ร้าน";
      // ----------
      sheet.mergeCells("A27", "C27");
      sheet.getCell("C27").value = "รายงานปริมาณผู้ใช้โปรโมชันร้านค้า";
      sheet.getRow(28).values = ["ลำดับ", "เดือน", "จำนวน"];
      let dtArrBarStore = data[0].body[4];
      let arrBarStore = [];
      for (let i = 0; i < dtArrBarStore.length; i++) {
        arrBarStore.push(dtArrBarStore[i][2]);
      }
      const SumBarStore = arrBarStore.reduce(
        (acc, current) => acc + current,
        0
      );
      dtArrBarStore.forEach(function (item, index) {
        sheet.addRow({
          id: item[0],
          value: item[1],
          amount: item[2] > 0 ? item[2] + " ร้านค้า" : "-",
        });
      });
      sheet.mergeCells("A41", "B41");
      sheet.getCell("B41").value = "จำนวนทั้งหมด ";
      sheet.getCell("C41").value = SumBarStore + " ร้าน";
      // ------------------------------------

      sheet.mergeCells("A43", "C43");
      sheet.getCell("C43").value = "รายงานปริมาณผู้ใช้โปรโมชันร้านอาหาร";
      sheet.getRow(44).values = ["ลำดับ", "เดือน", "จำนวน"];
      let dtArrFoodStore = data[0].body[5];
      let arrBarFood = [];
      for (let i = 0; i < dtArrFoodStore.length; i++) {
        arrBarFood.push(dtArrFoodStore[i][2]);
      }
      const SumBarFood = arrBarFood.reduce((acc, current) => acc + current, 0);
      dtArrFoodStore.forEach(function (item, index) {
        sheet.addRow({
          id: item[0],
          value: item[1],
          amount: item[2] > 0 ? item[2] + " ร้านค้า" : "-",
        });
      });
      sheet.mergeCells("A57", "B57");
      sheet.getCell("B57").value = "จำนวนทั้งหมด ";
      sheet.getCell("C57").value = SumBarFood + " ร้าน";
      // -----------------------------------
      sheet.mergeCells("A59", "C59");
      sheet.getCell("C59").value = "รายงานปริมาณผู้เข้าใช้สนามกีฬา";
      sheet.getRow(60).values = ["ลำดับ", "สถานที่", "จำนวน"];
      let dtArrArena = data[0].body[6];
      console.log("dtArrArena : ", dtArrArena);
      dtArrArena.forEach(function (item, i) {
        sheet.addRow({
          id: i + 1,
          value: item.arenaName ? item.arenaName : "-",
          amount: item.userCount > 0 ? item.userCount + " คน" : "-",
        });
      });
      // -----------------------------------
      sheet.eachRow((row, rowNumber) => {
        row.eachCell((cell, colNumber) => {
          cell.style = desiredStyle;
        });
      });
    }
    // sheet.getCell("B3").fill = {
    //   type: "pattern",
    //   pattern: "darkVertical",
    //   fgColor: { argb: "9FBFDF" },
    //   bgColor: { argb: "9FBFDF" },
    // };
    else {
      const desiredStyle = {
        font: {
          name: "TH SarabunPSK",
          size: 16,
          bold: true,
        },
        alignment: {
          vertical: "middle",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        },
      };

      // console.log("data : ", data);
      let dtHeader = data[0].header;
      let dtBody = data[0].body;
      let dtHeaderRow = [];
      let HeaderRowSheet = [];
      for (let i = 0; i < dtHeader.length; i++) {
        dtHeaderRow.push(dtHeader[i]);
        HeaderRowSheet.push({
          key: "index" + (i + 1),
          width: i == 0 ? 10 : 30,
          // text: dtHeader[i],
        });
      }
      // console.log("HeaderRowSheet preview test : ", HeaderRowSheet);
      sheet.columns = HeaderRowSheet;
      // sheet.mergeCells("A1", "C1");
      // sheet.getCell("C1").value = workbookOptions.name;
      sheet.getRow(1).values = dtHeaderRow;

      // console.log("dtHeader before preview : ", dtHeader);
      // console.log("dtHeaderRow after preview : ", dtHeaderRow);
      // console.log("dtBody preview : ", dtBody);

      const dataRowHead = [
        {
          key: "index1",
          width: 30,
        },
        {
          key: "index2",
          width: 30,
        },
        {
          key: "index3",
          width: 30,
        },
        {
          key: "index4",
          width: 30,
        },
      ];
      // test
      // Add headers

      const headerRow = sheet.addRow(HeaderRowSheet.map((head) => head.key));
      headerRow.eachCell((cell, colNumber) => {
        cell.width = HeaderRowSheet[colNumber - 1].width;
      });

      // delete
      // sheet.spliceRows(4, 1);
      // Add data rows
      Object.values(dtBody).forEach((dataRow) => {
        sheet.addRow(dataRow);
      });
      const rowIndexToDelete = 2;

      // Delete the row
      sheet.spliceRows(rowIndexToDelete, 1);
      // console.log("  Object.values preview : ", Object.values);
      // ----------------
      // const transformedData = Object.values(dtBody).map((item) => {
      //   const obj = {};
      //   HeaderRowSheet.forEach((head, index) => {
      //     obj[head.key] = item[index];
      //   });
      //   return obj;
      // });
      // sheet.addRow(transformedData);
      // console.log("transformedData result pre : ", transformedData);
      sheet.eachRow((row, rowNumber) => {
        row.eachCell((cell, colNumber) => {
          cell.style = desiredStyle;
        });
      });
      // transformedData.forEach(function (item, index) {
      //   console.log("item : ", item.index1);
      // sheet.addRow({
      // item.index1: item[0],
      // value: item[1],
      // amount: item[2],
      // });
      // });
      // sheet.addTable({
      //   name: configuration.name,
      //   ref: configuration.ref,
      //   headerRow: configuration.headerRow,
      //   totalsRow: configuration.totalsRow,
      //   style: {
      //     theme: "TableStyleMedium7",
      //     showRowStripes: true,
      //     font: {
      //       sz: 20,
      //     },
      //   },
      //   columns: cols,
      //   rows: dataBody,
      // });
    }
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `${workbookOptions.name}.xlsx`);
    });
  };

  return {
    generate,
  };
};
