import React, { useState, useEffect } from "react";
import moment from "moment";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import ButtonAdd from "../button/ButtonAdd";
import TableData from "../Tables/TableData";
import { useNavigate } from "react-router-dom";
import { getManual, deleteManual, getManualId } from "../../api/manual_api";
import Swal from "sweetalert2";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import Loading from "./Loading";
function Guide() {
  const [dataManual, setDataManual] = useState([]);
  const [spanloading, setSpanLoading] = useState(false);
  const [isLoading, setLoading] = useState(null);
  const [mannual, setMannul] = useState([]);
  const navigate = useNavigate();
  function navigateToAddEdit(page) {
    navigate(page);
  }
  useEffect(() => {
    getlist();
  }, []);
  const getlist = async () => {
    // setSpanLoading(true);
    let data = {
      status: "success",
      message: "get manual success",
      data: [
        {
          manualName: "testmanual2",
          manualPath:
            "https://nw-smartsports.verkoffice.com/app/uploads/file-1678269983925.xlsx",
          createdAt: "2023-03-08T17:06:23.000Z",
          deletedAt: null,
        },
        {
          manualName: "testmanual2",
          manualPath:
            "https://nw-smartsports.verkoffice.com/app/uploads/file-1678269931429.xlsx",
          createdAt: null,
          deletedAt: null,
        },
        {
          manualName: "testmanual",
          manualPath:
            "https://nw-smartsports.verkoffice.com/app/uploads/file-1678268285136.xlsx",
          createdAt: null,
          deletedAt: null,
        },
      ],
    };
    try {
      const response = await getManual();

      if (response) {
        response.data.data.map((objectData, i) => {
          return rowsdata.push({
            //
            id: { value: i + 1, dataID: objectData.manualID, type: "text" },
            guideName: { value: objectData.manualName, type: "text" },
            updateddate: {
              value:
                // moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD") ||
                moment(new Date(objectData.createdAt)).format("YYYY-MM-DD"),

              type: "text",
            },
            action: { value: "1", type: "action" },
            //

            // updateddate: {
            //   value: moment(new Date(objectData.createdAt)).format(
            //     "DD-MM-YYYY"
            //   ),
            //   type: "text",
          });
        });
        // await setSpanLoading(false);
        setDataManual(rowsdata);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getId = async (id) => {
    try {
      const response = await getManualId(id);
      if (response.status === 200) {
        const data = response.data.data;
        window.open(data.manualPath, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleButtonClicked = (buttonType, id) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/Addeditguide`);
        break;
      case "edit":
        // handle edit button
        // navigate(`/AddeditathleteAmt?id=${id}`)
        break;
      case "view":
        getId(id);
        // handle view button
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        deleteManualByID(id);
        // handle delete button
        break;
      default:
        console.log("Invalid button type");
    }
  };
  const deleteManualByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteManual(id);
            if (response.data.status === "success") {
              setLoading(1);
              getlist();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleExport = async (type) => {};

  const enableButton = {
    view: true,
    edit: false,
    delete: true,
    add: false,
    approve: false,
  };

  const columnsname = ["id", "guideName", "updateddate", "action"];
  const columnsText = ["No", "ชื่อคู่มือ", "อัปเดตล่าสุด", "เครื่องมือ"];
  const columnsType = ["text", "text", "text", "action"];
  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   guideName: { value: "คู่มือการใช้งาน001", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];

  return (
    <>
      {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()}
      {spanloading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <></>
      )}
      <Header parentmenu={"คู่มือการใช้งาน"} />
      <Menu activemenu={"guide"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-guide.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                คู่มือการใช้งาน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | คู่มือการใช้งาน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              <ButtonAdd
                navigateToAddEdit={navigateToAddEdit}
                pageAddEditName="/Addeditguide"
                onExportClicked={handleExport}
              />
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการคู่มือใช้งาน
                </p>
              </div>
            </div>
            <TableData
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataManual}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Guide;
