const DataMocShop = {
    datas:[
      {id:1,
        row:[{detail:"1",type:"text"}, {detail:"อร่อยแน่ๆ",type:"text"} ,{detail:"1",type:"checkbox"}, {detail:"1",type:"actions",}]
      },
      {id:2,
        row:[{detail:"2",type:"text"}, {detail:"ขายดี๊ดี",type:"text"} ,{detail:"1",type:"checkbox"}, {detail:"1",type:"actions"}]
      },
      {id:3,
        row:[{detail:"3",type:"text"}, {detail:"อร่อยจัง",type:"text"} ,{detail:"1",type:"checkbox"}, {detail:"1",type:"actions"}]
      },
      {id:4,
        row:[{detail:"4",type:"text"}, {detail:"ขายยังไงให้ขาดทุน",type:"text"} ,{detail:"1",type:"checkbox"}, {detail:"1",type:"actions"}]
      },
      
    ],
    columns:[
      { title: "ลำดับที่"},
      { title: "ชื่อร้านค้า"},
      { title: "ร้านค้าที่แนะนำ"},
      { title: "เครื่องมือ"},
    ]
  };

export default DataMocShop;