import React from 'react'
import { Col } from 'react-bootstrap';

function CheckBut(props) {
  const {navigateToAddEdit,pageAddEditName, actionsCheck } = props
  return (
    <>
        <Col className='text-center'>
         {/* <div className="row ml-0 mt-4 "> */}
         <div className={`row ml-0 mt-4  ${actionsCheck} `}>
              <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center">
                <span className="checkmark01" />
                <input type="checkbox"/>
              </label>
        </div>
        </Col>
    </>
  );
}

export default CheckBut