import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
function Menu({ parentmenu, activemenu }) {
  //   const [sideShow,setsideShow] = useState(false)
  const sidebarArr = [
    { isopen: parentmenu === "Dashboard" ? true : false },
    { isopen: parentmenu === "masterData" ? true : false },
    { isopen: parentmenu === "athleteCoach" ? true : false },
    { isopen: parentmenu === "competitionData" ? true : false },
    { isopen: parentmenu === "guestData" ? true : false },
    { isopen: parentmenu === "shopData" ? true : false },
    { isopen: parentmenu === "staffData" ? true : false },
    { isopen: parentmenu === "campaignData" ? true : false },
    { isopen: parentmenu === "advertise" ? true : false },
    { isopen: parentmenu === "shuttlebusData" ? true : false },
    { isopen: parentmenu === "borrowReturn" ? true : false },
    { isopen: parentmenu === "notification" ? true : false },
    { isopen: parentmenu === "policy" ? true : false },
    { isopen: parentmenu === "adminSetting" ? true : false },
    { isopen: parentmenu === "manageTheme" ? true : false },
    { isopen: parentmenu === "LinkWeb" ? true : false },
  ];
  // let dtMenu = {
  //   status: "success",
  //   data: {
  //     staffID: 7192,
  //     firstname: "superadmin",
  //     lastanme: "superadmin",
  //     mobileNo: "0865878544",
  //     email: "adv_05@mail.com",
  //     permissionNameMobile: "เจ้าหน้าที่",
  //     permissionBackoffice: [
  //       { menu: "dashboard_main", enable: false },
  //       { menu: "dashboard_votesport", enable: false },
  //       { menu: "dashboard_arenacheckin", enable: false },
  //       { menu: "dashboard_applicationusage", enable: false },
  //       { menu: "master_sport", enable: false },
  //       { menu: "master_subsport", enable: false },
  //       { menu: "master_arena", enable: false },
  //       { menu: "master_subarena", enable: false },
  //       { menu: "master_affliation", enable: false },
  //       { menu: "master_camp", enable: false },
  //       { menu: "member_coach", enable: false },
  //       { menu: "member_athletes", enable: false },
  //       { menu: "match", enable: false },
  //       { menu: "member_general", enable: false },
  //       { menu: "shop_acc_pending", enable: false },
  //       { menu: "shop_acc", enable: false },
  //       { menu: "shop_promotion_pending", enable: false },
  //       { menu: "shop_promotion_history", enable: false },

  //       // { menu: "shop_promotion_pending", enable: false },
  //       { menu: "promotion", enable: false },
  //       { menu: "staff", enable: false },
  //       { menu: "member_delete", enable: false },
  //       { menu: "campaign_campaign", enable: false },
  //       { menu: "campaign_history", enable: false },
  //       { menu: "pr_supportbanner", enable: false },
  //       { menu: "pr_attraction", enable: false },
  //       { menu: "busbooking", enable: false },
  //       { menu: "sleeppack", enable: false },
  //       { menu: "sleeppack_borrow", enable: false },
  //       { menu: "sleeppack_camp", enable: false },
  //       { menu: "notification", enable: false },
  //       { menu: "privacy", enable: false },
  //       { menu: "policy", enable: false },
  //       { menu: "manual", enable: false },
  //       { menu: "backoffice_permission", enable: false },
  //       { menu: "mobile_permission", enable: false },
  //       { menu: "admin_list", enable: false },
  //       { menu: "theme_color", enable: false },
  //       { menu: "web_view_manage", enable: false },
  //     ],
  //     permissionNameBackoffice: "admin",
  //     permissionMobile: [],
  //     deletedAt: null,
  //     updatedAt: null,
  //     notificationToken: null,
  //     createdAt: "2023-03-01T12:28:36.000Z",
  //   },
  // };
  const [sideopen, setsideopen] = useState(sidebarArr);
  function menuclick(e, index) {
    if (e.target.outerHTML.includes("headermenu")) {
      console.log("b4" + sideopen[index].isopen);
      let currentopen = sideopen;
      currentopen[index].isopen = !sideopen[index].isopen;
      console.log(currentopen[index].isopen);
      setsideopen([...currentopen]);
    }
  }
  useEffect(() => {
    let userAgent = navigator.userAgent;
    let browserName;
    if (
      userAgent.includes("android") ||
      userAgent.includes("iPhone") ||
      userAgent.includes("iPad")
    ) {
      browserName = "mobile";
    } else if (userAgent.includes("Linux")) {
      browserName = "tablet";
    }

    if (
      (browserName === "mobile" || browserName === "tablet") &&
      window.innerWidth < 1334
    ) {
      console.log("yes");
      //setsideShow(false)
      $("#bodyid").removeClass("sidebar-open");
      $("#bodyid").addClass("sidebar-closed sidebar-collapse");
      $("#sidetest").addClass("d-none");
    }
  }, []);

  return (
    <aside className="main-sidebar sidebar-primary elevation-4" id="sidetest">
      {/* Brand Logo */}
      <div className="brand-link">
        <div className="row">
          <div className="col-4">
            <img
              src="assets/images/im-logoNW.png"
              alt="AdminLTE Logo"
              className="brand-image img-circle"
              style={{ opacity: ".8" }}
            />
          </div>
          <div className="col-8">
            <span className="brand-text fontReg font-weight-bold font-size01 line-height01">
              ระบบจัดการข้อมูล
              <br />
              งานกีฬานครสวรรค์
            </span>
          </div>
        </div>
      </div>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar Menu */}
        <nav className="mt-2 fontReg font-size03">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            //data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li
              className={`nav-item  ${
                sideopen[0].isopen ? "menu-is-opening menu-open" : ""
              } `}
              onClick={(event) => menuclick(event, 0)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "Dashboard" ? "active" : ""
                } `}
              >
                <span className="nav-icon icon icon-ic-home headermenu"></span>
                <p className="font-weight-bold headermenu">
                  Dashboard
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[0].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className={`nav nav-treeview`}>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/home"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "main" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Dashboard หลัก</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/sportsvoteDash"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "sportsvoteDash" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ผลโหวตกีฬาที่สนใจ</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/arenaUsage"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "arenaUsage" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ปริมาณผู้เข้าใช้สนาม</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/appUsers"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "appUsers" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>จำนวนผู้ใช้แอปพลิเคชัน</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[1].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 1)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "masterData" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-masterData headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ข้อมูล Master Data
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[1].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className={`nav nav-treeview`}>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/sportType"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "sportType" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ชนิดกีฬา</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/matchType"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "matchType" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ประเภทการแข่งขัน </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/arena"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "arena" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>สนามกีฬา</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/arenaSub"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "arenaSub" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>สนามกีฬาย่อย </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/affiliation"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "affiliation" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>สังกัด</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/athleteAmt"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "athleteAmt" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ที่พักนักกีฬา </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[2].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 2)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "athleteCoach" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-coach headermenu"></span>
                <p className="font-weight-bold">
                  ข้อมูลนักกีฬา และ โค้ช
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[2].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className={`nav nav-treeview`}>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/coach"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "coach" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>โค้ช</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/athlete"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "athlete" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>นักกีฬา </p>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    as={Link}
                    to="/matchStatistics"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "matchStatistics" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>สถิติการแข่งขัน</p>
                  </Link>
                </li> */}
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[3].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 3)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "competitionData" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-competition headermenu"></span>
                <p className="font-weight-bold">
                  ข้อมูลการแข่งขัน
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[3].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className={`nav nav-treeview`}>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/competitionSchedule"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "competitionSchedule" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ตารางการแข่งขัน</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/competitionResults"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "competitionResults" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ผลการแข่งขัน</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/matchStatistics"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "matchStatistics" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>สถิติการแข่งขัน</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[4].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 4)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "guestData" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-guest headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ข้อมูลบุคคลทั่วไป
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[4].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className={`nav nav-treeview`}>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/guest"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "guest" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>บุคคลทั่วไป</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[5].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 5)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "shopData" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-shop headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ข้อมูลร้านค้า ร้านอาหาร
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[5].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/approveAcc"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "approveAcc" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Account ที่รออนุมัติ</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/shopList"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "shopList" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>รายชื่อร้านค้า ร้านอาหาร </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/approvePromotion"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "approvePromotion" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>โปรโมชัน ที่รออนุมัติ </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/promotion"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "promotion" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>รายชื่อโปรโมชัน</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/promotionHist"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "promotionHist" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ประวัติการใช้โปรโมชัน </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[6].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 6)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "staffData" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-staff-info headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ข้อมูลเจ้าหน้าที่
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[6].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className={`nav nav-treeview`}>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/staff"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "staff" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>เจ้าหน้าที่ Application</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`nav-item `}>
              <Link
                as={Link}
                to="/deletedUserAccount"
                className={`nav-link  ${
                  activemenu === "deletedUserAccount" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-del-user"></span>
                <p className="font-weight-bold">บัญชีผู้ใช้ที่ถูกลบ</p>
              </Link>
            </li>
            <li
              className={`nav-item  ${
                sideopen[7].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 7)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "campaignData" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-campaign headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ข้อมูลแคมเปญ
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[7].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/campaign"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "campaign" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>แคมเปญ</p>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    as={Link}
                    to="/approveCampaign"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "approveCampaign" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>อนุมัติการเข้าร่วมแคมเปญ</p>
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/campaignHist"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "campaignHist" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ประวัติการใช้แคมเปญ</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[8].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 8)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "advertise" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-pr headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ประชาสัมพันธ์
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[8].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/Managebanner"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "manageBanner" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>จัดการแบนเนอร์ผู้สนับสนุน</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/recommendAtt"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "recommendAtt" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>จัดการข้อมูลท่องเที่ยวจังหวัด</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[9].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 9)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "shuttlebusData" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-schedule headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ข้อมูลรถรับ-ส่ง
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[9].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/shuttlebusBooking"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "shuttlebusBooking" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ข้อมูลการจองรถรับ-ส่ง</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[10].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 10)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "borrowReturn" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-borrow headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ยืม-คืน ชุดเครื่องนอน
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[10].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/brBedding"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "brBedding" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ข้อมูลเครื่องนอน</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/brTableBedding"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "brTableBedding" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ข้อมูลการยืม-คืน</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/BorrowStation"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "BorrowStation" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ข้อมูลเครื่องนอนที่พัก</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[11].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 11)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "notification" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-notification headermenu"></span>
                <p className="font-weight-bold headermenu">
                  การเเจ้งเตือน
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[11].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/notiList"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "notiList" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>รายการแจ้งเตือน</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item  ${
                sideopen[12].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 12)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "policy" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-policy headermenu"></span>
                <p className="font-weight-bold headermenu">
                  นโยบายและเงื่อนไข
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[12].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/privacy"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "privacy" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>นโยบายความเป็นส่วนตัว</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/termsConditions"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "termsConditions" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>ข้อกำหนดและเงื่อนไข</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`nav-item `}>
              <Link
                as={Link}
                to="/guide"
                className={`nav-link  ${
                  activemenu === "guide" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-guide"></span>
                <p className="font-weight-bold">คู่มือการใช้งาน </p>
              </Link>
            </li>
            <li
              className={`nav-item  ${
                sideopen[13].isopen ? "menu-is-opening menu-open" : ""
              }`}
              onClick={(event) => menuclick(event, 13)}
            >
              <div
                className={`nav-link headermenu ${
                  parentmenu === "adminSetting" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-settingAdmin headermenu"></span>
                <p className="font-weight-bold headermenu">
                  ตั้งค่าแอดมิน Back Office
                  <i
                    className={`right fas fa-angle-left headermenu ${
                      sideopen[13].isopen ? "rotate-90" : "rotate0"
                    }`}
                  />
                </p>
              </div>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/BackOfficePermission"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "BackOfficePermission" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>จัดการ Permission Back Office</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/MobilePermission"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "MobilePermission" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>จัดการ Permission Application</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    as={Link}
                    to="/adminList"
                    className={`nav-link d-flex align-items-center ${
                      activemenu === "adminList" ? "active" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>รายชื่อ Admin Back Office</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`nav-item  ${parentmenu === "manageThemeColor"}`}>
              <Link
                as={Link}
                to="/manageThemeColor"
                className={`nav-link d-flex align-items-center ${
                  activemenu === "manageThemeColor" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-theme headermenu"></span>
                <p className="font-weight-bold">จัดการ Theme Color </p>
              </Link>
            </li>
            <li className={`nav-item  ${parentmenu === "LinkWeb"}`}>
              <Link
                as={Link}
                to="/LinkWebView"
                className={`nav-link  ${
                  parentmenu && activemenu === "LinkWeb" ? "active" : ""
                }`}
              >
                <span className="nav-icon icon icon-ic-link headermenu"></span>
                <p className="font-weight-bold">จัดการ Link Web View</p>
              </Link>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}

export default Menu;
