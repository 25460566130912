import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Dropdown from "../dropdowns/Dropdown";
import { DataDropdownSportType } from "../dataMockup/DataMockDropdown";
import UploadFileXLS from "../uploads/UploadFileXLS";
import UploadXL from "../uploads/UploadXL";
import {
  getSubSport,
  verifyImport,
  submitImportData,
  createSubSport,
  updateSubSport,
  getSubSportById,
} from "../../api/subsport_api";
import { getSport } from "../../api/sport_api";
import ImportExcel from "../uploads/ExcelRender";
import { async } from "q";
import { result } from "lodash";

function Addeditmatchtype() {
  const [inputForm, setInputForm] = useState({
    inp1: "", //ชนิดกีฬา
    inp2: "", //ประเภทการแข่งขัน
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  let [checked, setChecked] = useState(null);
  const [sportTypeList, setsportTypeList] = useState([{ sportType: "" }]);
  const handleSportChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...sportTypeList];
    list[index][name] = value;

    setsportTypeList(list);
  };

  const handleSportRemove = (index) => {
    const list = [...sportTypeList];
    list.splice(index, 1);
    setsportTypeList(list);
  };

  const getDataSubSport = async () => {
    const respon = await getSubSport();
    try {
      // rowsdata.forEach((data, i) => {
      //   return DataDropdownSportType.push({
      //     value: i + 1,
      //     text: data.sportName.value,
      //   });
      // });
      // getFilterdropdownSub(DataDropdownSubSport);
      // getFilterdropdown(DataDropdownSportType);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getFilterdropdownSub = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    // setDataDropSubSport(result);
  };
  const getFilterdropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    // setDataDropsport(result);
  };
  const handleSportAdd = () => {
    setsportTypeList([...sportTypeList, { sportType: "" }]);
  };
  useEffect(() => {
    getDataSubSport();
  }, []);
  const [groupShow, setGroupShow] = useState(false);
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/matchType");
      }
    });
  }

  // *********************** Take Import DATA ************************************

  const id = new URLSearchParams(window.location.search).get("id");

  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [sportDrop, setSportDrop] = useState([]);
  const [selectedSport, setSelectedSport] = useState("กรุณาเลือก");
  const [selectedValue, setSelectedValue] = useState("");
  const [subsportName, setSubsportName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const handleImport = async (rows) => {
    await setImportData(rows);
  };

  const verifyData = async () => {
    try {
      if (selectedValue === "") {
        // alert("กรุณาเลือก ชนิดกีฬา")
        Swal.fire({
          title: "กรุณาเลือก ชนิดกีฬา",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
        return;
      }
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const sportId = selectedValue;
      setTotalRow(importData.length - 1);
      for (let i = 1; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => {
          const sport = {
            supsportName: item[0],
            active: item[1],
          };
          return sport;
        });

        const dataToVerify = {
          sportId: sportId,
          importData: data,
        };
        const response = await verifyImport(dataToVerify);
        if (response) {
          success += response.data.success;
          skip += response.data.skip;
          setVerifySuccess(success);
          setVerifySkip(skip);
        } else {
          error++;
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }
      if (error === 0) {
        // alert("verify data success")
        Swal.fire({
          title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
          icon: "success",
          confirmButtonText: "ตกลง",
        });
        setIsVerify(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const importSport = async () => {
    try {
      setVerifySuccess(0);
      let success = 0;
      let skip = 0;
      let error = 0;
      const sportId = selectedValue;
      const batchSize = 10;
      setTotalRow(importData.length - 1);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          for (let i = 1; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => {
              const sport = {
                supsportName: item[0],
                active: item[1],
              };
              return sport;
            });

            const dataToVerify = {
              sportId: sportId,
              importData: data,
            };
            const response = await submitImportData(dataToVerify);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
            }
          }
        } else {
          return;
        }
      });

      if (error === 0) {
        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsVerify(false);
            navigateToBack("/matchType");
          }
        });
      } else {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToBack("/matchType");
        }
      });
      console.log(error);
    }
  };

  const getSportDropDown = async () => {
    try {
      let sport;
      const response = await getSport();
      if (response.status == 200) {
        const dropdown = response.data.data;

        const subType = dropdown.map((item) => {
          // if (item.active !== false) {
          sport = {
            // value: item.active,
            value: item.sportID,
            text: item.sportName,
            subType: [],
            active: item.active,
          };
          // }
          //
          return sport;
          // }s
        });
        const newData = subType.filter((item) => item.active !== false);
        // item.active !== false?

        setSportDrop(newData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitAdd = async () => {
    try {
      if (!selectedValue) {
        // alert('กรุณาเลือก ชนิดกีฬา')
        Swal.fire({
          title: "กรุณาเลือก ชนิดกีฬา",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
        return;
      }

      if (!subsportName) {
        // alert('กรุณากรอก ประเภทการแข่งขัน')
        Swal.fire({
          title: "กรุณาเลือก ประเภทการแข่งขัน",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
        return;
      }
      const sportId = selectedValue;
      const subname = subsportName;
      // const active = checked === true ? 1 : 0;
      const active = checked === true ? "true" : "false";
      const dataToCreate = {
        sportId: sportId,
        subsportName: subname,
        active: active,
      };
      Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        (async () => {
          if (result.isConfirmed) {
            const response = await createSubSport(dataToCreate);
            if (response.data.status === "success") {
              Swal.fire({
                title: "บันทึกข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToBack("/matchType");
                }
              });
            }
          }
        })();
      });
      // const response = await createSubSport(dataToCreate);
      // if (response && response.status === 200) {
      //   // alert('เพิ่มข้อมูลชนิดกีฬา สำเร็จ')
      //   Swal.fire({
      //     title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
      //     icon: "warning",
      //     showCancelButton: true,
      //     confirmButtonText: "ยืนยัน",
      //     cancelButtonText: "ยกเลิก",
      //     reverseButtons: true,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       Swal.fire({
      //         title: "บันทึกข้อมูลสำเร็จ",
      //         icon: "success",
      //         confirmButtonText: "ตกลง",
      //       }).then((result) => {
      //         if (result.isConfirmed) {
      //           navigateToBack("/matchType");
      //         }
      //       });
      //     }
      //   });
      // } else {
      //   // alert('เกิดข้อผิดพลาด')
      //   Swal.fire({
      //     title: "เกิดข้อผิดพลาด",
      //     icon: "error",
      //     showCancelButton: true,
      //     confirmButtonText: "ยืนยัน",
      //     cancelButtonText: `ยกเลิก`,
      //     reverseButtons: true,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       navigateToBack("/matchType");
      //     }
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubsportById = async (id) => {
    try {
      const response = await getSubSportById(id);
      if (response.status === 200) {
        const subData = response.data.data;
        setSelectedValue(subData.sport.sportID);
        setSelectedSport(subData.sport.sportName);
        setSubsportName(subData.subSportName);
        setChecked(subData.active);
        if (subData.active) {
          subData.active === 1 ? setIsActive(true) : setIsActive(false);
        }
      } else {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
        navigate("/matchType");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const submitEdit = async () => {
    try {
      if (!selectedValue) {
        // alert('กรุณาเลือก ชนิดกีฬา')
        Swal.fire({
          title: "กรุณาเลือก ชนิดกีฬา",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
        return;
      }

      if (!subsportName) {
        // alert('กรุณากรอก ประเภทการแข่งขัน')
        Swal.fire({
          title: "กรุณาเลือก ประเภทการแข่งขัน",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
        return;
      }

      const subsportId = id;
      const sportId = selectedValue;
      const subname = subsportName;
      // const active = checked === true ? 1 : 0;
      const active = checked === true ? "true" : "false";

      const dataToUpdate = {
        sportId: sportId,
        subsportName: subname,
        active: active,
      };
      // const response = await updateSubSport(subsportId, dataToUpdate);
      // if (response && response.status === 200) {
      // alert('เพิ่มข้อมูลชนิดกีฬา สำเร็จ')
      Swal.fire({
        title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        (async () => {
          if (result.isConfirmed) {
            const response = await updateSubSport(subsportId, dataToUpdate);
            if (response.data.status === "success") {
              Swal.fire({
                title: "แก้ไขข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToBack("/matchType");
                }
              });
            }
          }
        })();
      });
      // }
      // else {
      //   Swal.fire({
      //     title: "เกิดข้อผิดพลาด",
      //     icon: "error",
      //     showCancelButton: true,
      //     confirmButtonText: "ยืนยัน",
      //     cancelButtonText: `ยกเลิก`,
      //     reverseButtons: true,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       navigateToBack("/matchType");
      //     }
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
  };

  const handleChangeSubsport = (e) => {
    setSubsportName(e.target.value);
  };

  const handleActiveChange = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    getSportDropDown();
    if (id) {
      getSubsportById(id);
    }
  }, []);
  // *********************** Take Import DATA ************************************

  return (
    <>
      <Header parentmenu={"ประเภทการแข่งขัน"} />
      <Menu parentmenu={"masterData"} activemenu={"matchType"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-masterData.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ประเภทการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูล Master Data / ประเภทการแข่งขัน
              </font>
            </div>
            {/* <div
              className={`col-4 col-sm-3 justify-content-end align-items-center ${
                !groupShow ? "d-flex" : "d-none"
              } `}
            >
              <button
                className="btn-blue03 mr-2 mr-xl-3 w-100"
                onClick={() => setGroupShow(true)}
              >
                <img
                  src="assets/icons/ic-add.png"
                  alt="ExportFiles Icon"
                  className="ic-add"
                />
                <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                  เพิ่มข้อมูลหลายรายการ
                </span>
              </button>
            </div> */}
            <div
              className={`col-4 col-sm-3 justify-content-end align-items-center ${
                groupShow ? "d-flex" : "d-none"
              } `}
            >
              <button
                className="btn-blue03 mr-2 mr-xl-3 w-100"
                onClick={() => setGroupShow(false)}
              >
                <img
                  src="assets/icons/ic-add.png"
                  alt="ExportFiles Icon"
                  className="ic-add"
                />
                <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                  เพิ่มข้อมูลรายการเดียว
                </span>
              </button>
            </div>
          </div>
          <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>{" "}
                  {id ? <>แก้ไขข้อมูล</> : <>เพิ่มข้อมูลทีละรายการ</>}
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg-6">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชนิดกีฬา
                  </p>
                  <Dropdown
                    valuefromparent={selectedSport}
                    options={sportDrop}
                    onChange={handleDropdownChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ประเภทการเเข่งขัน
                </p>
              </div>
            </div>
            <div className="row justify-content-start fontReg font-size03">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0">
                <input
                  onChange={handleChangeSubsport}
                  value={subsportName}
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อประเภทการแข่งขัน"
                />
              </div>
            </div>

            <div className="row justify-content-start fontReg font-size03 mt-3">
              <div className="ml-2 ml-sm-3">
                <label className="checkbox-item01 fontReg font-size03 colorGray01 d-flex align-items-center mb-0">
                  ใช้งาน
                  <input
                    // defaultChecked={checked}
                    type="checkbox"
                    onChange={() => setChecked(!checked)}
                    // defaultChecked={checked}
                    checked={checked}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>

            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  onClick={id ? submitEdit : submitAdd}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg-6">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    ชนิดกีฬา
                  </p>
                  <Dropdown
                    disabled={isVerify}
                    options={sportDrop}
                    onChange={handleDropdownChange}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/002-masterdata-sub-sportType_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                  <span className="mx-2" style={{ alignSelf: "center" }}>
                    {verifySuccess}/{totalRow}
                  </span>
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length == 0}
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importSport}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addeditmatchtype;
