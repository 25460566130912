import React, { useEffect, useState } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import Dropdown from "../dropdowns/Dropdown";
//  DataDropdownArenas,
import {
  DataDropdownCompetitionRound,
  DataDropdownSportType,
} from "../dataMockup/DataMockDropdown";
import Calendarpicker from "../calendars/Calendarpicker";
import TableData from "../Tables/TableData";
import Buttonexport from "../button/Buttonexports";
import Button3items from "../button/Button3items";
import DataPdf from "../uploads/Exportpdf";
import DataExcel from "../uploads/SendDataexcel";
import { getDataUser } from "../config/configApi-User";
import { useNavigate } from "react-router-dom";
import { getSubArena } from "../../api/subarena_api";

import {
  getMatchStat,
  getMatchStatById,
  deleteMatchStat,
  getMatchStatFilter,
} from "../../api/matchstat_api";
import Swal from "sweetalert2";
import moment from "moment";
import Loading from "./Loading";
function CompetitionResults() {
  const [subTypes, setSubTypes] = useState([]);
  let subTypeoption = [];
  useEffect(() => {
    areaDropdown();
    subTypeoption = DataDropdownSportType[0].subType;

    setSubTypes(subTypeoption);
    getDataMatchStat();
  }, []);
  const [DataDropArenas, setDataDropArenas] = useState([]);
  const [subDetialSche, setSubDetialSche] = useState([]);
  const [isLoading, setLoading] = useState(null);
  const [dataMatch, setDataMatch] = useState([]);
  const [dataTypeSche, setDataTypeSche] = useState(null);
  const [inputForm, setInputForm] = useState({
    inp1: undefined, //ชื่อรายการแข่งขัน
    inp2: undefined, //Calendarpicker
    inp3: undefined, //สังกัด
    inp4: undefined, //ชนิดกีฬา
    inp5: undefined, //ประเภทการแข่งขัน
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const areaDropdown = async () => {
    try {
      const response = await getSubArena();
      if (response.status == 200) {
        let dataType = [{ value: "", text: "ทั้งหมด" }];
        response.data.data.forEach((item, i) => {
          return dataType.push({
            value: item.subArenaID,
            text: item.subArenaName,
            subType: [],
          });
        });

        setDataDropArenas(dataType);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setsubtype_data = (data) => {
    setSubTypes(data);
  };

  const navigate = useNavigate();
  const handleButtonClicked = (buttonType, id, type) => {
    switch (buttonType) {
      case "add":
        // handle add button
        navigate(`/AddcompetresultsSolo?id=${id}`);
        break;
      case "edit":
        if (type !== "team") {
          navigate(`/EditcompetresultsSolo?id=${id}`);
        } else {
          navigate(`/EditcompetresultsTeams?id=${id}`);
        }
        // handle edit button
        // navigate(`/EditcompetresultsSolo?id=${id}`);
        break;
      case "view":
        // handle view button
        getMatchStatID(id);
        break;
      case "approve":
        // handle approve button
        break;
      case "cancel":
        // handle cancel button
        break;
      case "delete":
        // handle delete button
        deleteMatchStatByID(id);
        break;
      default:
        console.log("Invalid button type");
    }
  };

  const handleExport = async (type) => {
    let Header = [
      "ลำดับที่",
      "ชื่อรายการแข่งขัน",
      "รอบการแข่งขัน",
      "วันที่แข่งขัน",
      "เวลาที่แข่งขัน",
      "สนามกีฬา",
      "อัปเดตล่าสุด",
    ];
    let Data = dataResultMatch.map((elemento, i) => {
      const dt = Object.values(Header.length);
      dt[0] = elemento.id.value;
      dt[1] = elemento.matchName.value;
      dt[2] = elemento.matchRound.value;
      dt[3] = elemento.matchDay.value;
      dt[4] = elemento.matchTime.value;
      dt[5] = elemento.arena.value;
      dt[6] = elemento.updateddate.value;

      return dt;
    });
    const date = new Date();
    var dateTime = moment(date).format("DD/MM/YY HH:mm");
    // const resultDate = date.toLocaleDateString("th-TH", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // });
    const respon = await getDataUser();
    let dataSend = {
      body: Data,
      data: "รายงานผลการแข่งขัน",
      header: Header,
      date: "วันที่ " + dateTime,
      user: respon.data.data.firstname + " " + respon.data.data.lastanme,
      position: "(" + respon.data.data.permissionNameMobile + ")",
    };

    switch (type) {
      case "excel":
        DataExcel(dataSend);
        break;
      case "pdf":
        DataPdf(dataSend);
        break;
    }
  };

  const enableButton = {
    view: true,
    edit: true,
    delete: false,
    add: false,
    approve: false,
  };

  const columnsname = [
    "id",
    "matchName",
    "matchRound",
    "matchDay",
    "matchTime",
    "arena",
    "updateddate",
    "action",
  ];
  const columnsText = [
    "ลำดับที่",
    "ชื่อรายการแข่งขัน",
    "รอบการแข่งขัน",
    "วันที่แข่งขัน",
    "เวลาที่แข่งขัน",
    "สนามกีฬา",
    "อัปเดตล่าสุด",
    "เครื่องมือ",
  ];
  const columnsType = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "action",
  ];
  const rowsdata = [
    // {
    //   id: { value: 1, type: "text" },
    //   matchName: { value: "หญิงเดี่ยว4x100เมตร", type: "text" },
    //   matchRound: { value: "รอบชิงชนะเลิศ", type: "text" },
    //   matchDay: { value: "20/05/2023", type: "text" },
    //   matchTime: { value: "1:30 PM", type: "text" },
    //   arena: { value: "สนามกีฬา 1", type: "text" },
    //   updateddate: { value: "7/5/2022", type: "text" },
    //   action: { value: "1", type: "action" },
    // },
  ];
  const [dataResultMatch, setDataResultMatch] = useState([]);
  const [name, setName] = useState("");
  const getDataMatchStat = async () => {
    try {
      await setLoading(true);
      const respon = await getMatchStat();
      await setLoading(false);
      respon.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          //

          //
          id: { value: i + 1, type: "text", dataID: objectData.matchID },
          matchName: {
            value: objectData.matchName === null ? "-" : objectData.matchName,
            type: "text",
          },
          // objectData.matchName === "final"
          //       ? "รอบชิงชนะเลิศ"
          //       : objectData.matchName === "final"
          //       ? objectData.matchName
          //       : "-",
          matchRound: {
            value:
              objectData.matchHeat === "final" ||
              objectData.matchHeat === "รอบชิง"
                ? "รอบชิงชนะเลิศ"
                : objectData.matchHeat === "semi" ||
                  objectData.matchHeat === "รอบรอง"
                ? "รอบรองชนะเลิศ"
                : objectData.matchHeat === "qualify" ||
                  objectData.matchHeat === "คัดเลือก"
                ? "รอบคัดเลือก"
                : "-",
            type: "text",
          },
          matchDay: {
            value:
              objectData.startTime === null
                ? "-"
                : moment(new Date(objectData.startTime)).format("DD/MM/YY") +
                  " - " +
                  moment(new Date(objectData.endTime)).format("DD/MM/YY"),
            type: "text",
          },
          matchTime: {
            value:
              objectData.startTime === null
                ? "-"
                : moment(objectData.startTime)
                    // .add(-7, "hours")
                    .format("HH:mm") +
                  " - " +
                  moment(objectData.endTime).format("HH:mm"),

            type: "text",
          },
          arena: {
            value: objectData.arenaName ? objectData.arenaName : "-",
            // objectData.affiliationMatch
            //   ? objectData.affiliationMatch[0].affliationName
            //   : "-",
            type: "text",
          },

          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: {
            value: "1",
            type: "action",
            competitionType: objectData.competitionType,
          },
          // ---
        });
      });
      setDataResultMatch(rowsdata);
    } catch (error) {
      console.log(error.message);
    }
  };
  let type_competitionType;
  const getMatchStatID = async (Id) => {
    let getData = [];
    const respon = await getMatchStatById(Id);
    getData = respon.data.data;
    setDataMatch(getData);
    let gtDt = [];
    type_competitionType = getData.competitionType;
    for (let i = 0; i < getData.affiliationMatch.length; i++) {
      for (let j = 0; j < getData.affiliationMatch[i].athletes.length; j++) {
        gtDt.push({
          affliationName: getData.affiliationMatch[i].affliationName,
          atheleteID: getData.affiliationMatch[i].athletes[j].atheleteID,
          email: getData.affiliationMatch[i].athletes[j].email,
          firstname: getData.affiliationMatch[i].athletes[j].firstname,
          idcard: getData.affiliationMatch[i].athletes[j].idcard,
          lastname: getData.affiliationMatch[i].athletes[j].lastname,
          memberID: getData.affiliationMatch[i].athletes[j].memberID,
          mobileNo: getData.affiliationMatch[i].athletes[j].mobileNo,
          no: getData.affiliationMatch[i].athletes[j].no,
          record: getData.affiliationMatch[i].athletes[j].record,
          remark: getData.affiliationMatch[i].athletes[j].remark,
        });
      }
    }

    // localStorage.setItem("type", type_competitionType);
    // const currenttype = localStorage.getItem("type");

    // setDataTypeSche(currenttype);
    setSubDetialSche(gtDt);
  };
  const SearchData = async () => {
    // setSpanLoader(true);
    // if (inputForm.inp3 === 3) {
    //   inputForm.inp3 = "qualify";
    // } else if (inputForm.inp3 === 2) {
    //   inputForm.inp3 = "semi";
    // } else if (inputForm.inp3 === 1) {
    //   inputForm.inp3 = "final";
    // } else {
    //   inputForm.inp3 = "";
    // }
    let dt = {
      matchName: name || "",
      matchHeat:
        inputForm.inp3 === 1
          ? "final"
          : inputForm.inp3 === 2
          ? "semi"
          : inputForm.inp3 === 3
          ? "qualify"
          : "",
      subArenaId: inputForm.inp4 || "",
      dateTime: inputForm.inp2
        ? moment(new Date(inputForm.inp2)).format("YYYY-MM-DD")
        : "",
    };
    if (
      dt.matchHeat === undefined &&
      dt.keyword === "" &&
      dt.subArenaId === undefined &&
      dt.dateTime === "Invalid date"
    ) {
      getDataMatchStat();
    } else {
      await setLoading(true);
      const response = await getMatchStatFilter(dt);
      await setLoading(false);
      response.data.data.forEach((objectData, i) => {
        return rowsdata.push({
          //

          //
          id: { value: i + 1, type: "text", dataID: objectData.matchID },
          matchName: {
            value: objectData.matchName === null ? "-" : objectData.matchName,
            type: "text",
          },
          matchRound: {
            value:
              objectData.matchHeat === "final"
                ? "รอบชิงชนะเลิศ"
                : objectData.matchHeat === "semi"
                ? "รอบรองชนะเลิศ"
                : objectData.matchHeat === "qualify"
                ? "รอบคัดเลือก"
                : "-",
            type: "text",
          },
          matchDay: {
            value:
              objectData.startTime === null
                ? "-"
                : moment(new Date(objectData.startTime)).format("DD/MM/YY") +
                  " - " +
                  moment(new Date(objectData.endTime)).format("DD/MM/YY"),
            type: "text",
          },
          matchTime: {
            value:
              objectData.startTime === null
                ? "-"
                : moment(objectData.startTime)
                    // .add(-7, "hours")
                    .format("HH:mm") +
                  " - " +
                  moment(objectData.endTime).format("HH:mm"),

            type: "text",
          },
          arena: {
            value: objectData.arenaName ? objectData.arenaName : "-",
            // objectData.affiliationMatch
            //   ? objectData.affiliationMatch[0].affliationName
            //   : "-",
            type: "text",
          },
          updateddate: {
            value:
              objectData.updatedAt === null
                ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
                : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
            type: "text",
          },
          action: { value: "1", type: "action" },
          // ---
        });
      });
      setDataResultMatch(rowsdata);
      // response.data.data.forEach((objectData, i) => {
      //   return rowsdata.push({
      //     //
      //     id: { value: i + 1, type: "text", dataID: objectData.matchID },
      //     matchName: {
      //       value: objectData.matchName === null ? "-" : objectData.matchName,
      //       type: "text",
      //     },
      //     matchRound: {
      //       value: objectData.matchHeat === null ? "-" : objectData.matchHeat,
      //       type: "text",
      //     },
      //     matchDay: {
      //       value:
      //         objectData.startTime === null
      //           ? "-"
      //           : moment(new Date(objectData.startTime)).format("DD/MM/YY") +
      //             " - " +
      //             moment(new Date(objectData.endTime)).format("DD/MM/YY"),
      //       type: "text",
      //     },
      //     matchTime: {
      //       value:
      //         objectData.startTime === null
      //           ? "-"
      //           : moment(new Date(objectData.startTime)).format("HH:mm") +
      //             " - " +
      //             moment(new Date(objectData.endTime)).format("HH:mm"),

      //       type: "text",
      //     },
      //     arena: {
      //       value:
      //         objectData.subArena !== null
      //           ? objectData.subArena.subArenaName
      //           : "-",
      //       type: "text",
      //     },
      //     updateddate: {
      //       value:
      //         objectData.updatedAt === null
      //           ? moment(new Date(objectData.createdAt)).format("YYYY-MM-DD")
      //           : moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD"),
      //       type: "text",
      //     },
      //     action: { value: "1", type: "action" },
      //     //
      //   });
      // });

      // setDataSchedule(rowsdata);
      // response.data.data.forEach((objectData, i) => {
      //   return rowsdata.push({
      //     id: { value: i + 1, type: "text", dataID: objectData.subSportID },
      //     sportType: {
      //       value: objectData.sport === null ? "-" : objectData.sport.sportName,
      //       type: "text",
      //     },
      //     matchType: { value: objectData.subSportName, type: "text" },
      //     status: {
      //       value: objectData.active !== true ? "ไม่ใช้งาน" : "ใช้งาน",
      //       active: objectData.active,
      //       type: "text",
      //     },
      //     updateddate: {
      //       value:
      //         objectData.updatedAt !== null
      //           ? moment(new Date(objectData.updatedAt)).format("YYYY-MM-DD")
      //           : "-",
      //       type: "text",
      //     },
      //     action: { value: "1", type: "action" },
      //   });
      // });
      // setSpanLoader(false);
      // setDataSubSport(rowsdata);
    }
  };
  const deleteMatchStatByID = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin();

      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะลบรายการใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // const response = await deleteAthlete(id);
            const response = await deleteMatchStat(id);
            if (response.data.status === "success") {
              getDataMatchStat();
              swalWithBootstrapButtons.fire({
                title: "ลบรายการสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            } else {
              swalWithBootstrapButtons.fire("เกิดข้อผิดพลาด!", " ", "error");
            }
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const [inputDate, setInputDate] = useState("");
  const [valuefromparent2, setValuefromparent2] = useState("ทั้งหมด");
  const [valuefromparent3, setValuefromparent3] = useState("ทั้งหมด");

  async function resetDropdown() {
    setName("");
    setInputDate("");
    inputForm.inp1 = undefined;
    inputForm.inp2 = undefined;
    inputForm.inp3 = undefined;
    inputForm.inp4 = undefined;
    setValuefromparent2("ทั้งหมด");
    setValuefromparent3("ทั้งหมด");
    getDataMatchStat();
    //เพิ่มคำสั่ง set input ของหน้าหลัก ค่าของ dropdown ตัวนี้ให้เป็นค่าเริ่มต้นด้วย
  }

  function ChangesetValuefromparent2(text) {
    setValuefromparent2(text);
  }
  function ChangesetValuefromparent3(text) {
    setValuefromparent3(text);
  }

  function ChangesetValueDate(value) {
    setInputDate(value);
  }

  const onChangeInputName = (e) => {
    if (e) {
      setinput("inp1", e.target.value);
      setName(e.target.value);
    }
  };

  return (
    <>
      {" "}
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
      })()}
      <Header parentmenu={"ผลการแข่งขัน"} />
      <Menu parentmenu={"competitionData"} activemenu={"competitionResults"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-7 mt-2">
              <img
                src="assets/icons/im-competition.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ผลการแข่งขัน
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลการแข่งขัน / ผลการแข่งขัน
              </font>
            </div>
            <div className="col-5 d-flex justify-content-end align-items-center p-0">
              {/* <Buttonexport onExportClicked={handleExport} /> */}
              <Button3items
                // navigateToAddEdit={navigateToAddEdit}
                // pageAddEditName={"/Addeditcompetschedule"}
                onExportClicked={handleExport}
              />
              {/* <Button3items onExportClicked={handleExport} /> */}
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ค้นหา
                </p>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-4 pr-2 pr-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ค้นหาข้อมูล ชื่อรายการแข่งขัน"
                  value={name}
                  onChange={onChangeInputName}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-lg-0 mt-2">
                <Calendarpicker
                  setDatePicker={setinput}
                  inputname={"inp2"}
                  ChangesetValueDate={ChangesetValueDate}
                  inputDate={inputDate}
                  dataDate={null}
                />
              </div>
              <div className="col-12 col-lg-4 pr-2 pr-lg-0 mt-lg-0 mt-2">
                <div className="drpdw-sportName">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    รอบการแข่งขัน
                  </p>
                  <Dropdown
                    options={DataDropdownCompetitionRound}
                    setinput={setinput}
                    inputname={"inp3"}
                    ChangesetValuefromparent={ChangesetValuefromparent2}
                    valuefromparent={valuefromparent2}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12 col-lg-6 pr-2 pr-lg-0 mt-lg-3 mt-2">
                <div className="drpdw-status">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สนามกีฬาย่อย
                  </p>
                  <Dropdown
                    options={DataDropArenas}
                    func={setsubtype_data}
                    setinput={setinput}
                    inputname={"inp4"}
                    ChangesetValuefromparent={ChangesetValuefromparent3}
                    valuefromparent={valuefromparent3}
                  />
                </div>
              </div>
              <div className="col-6 col-lg-1 pr-0 mt-lg-3 mt-2">
                <button className="btn-blue01" onClick={() => SearchData()}>
                  <img
                    src="assets/icons/ic-search.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorWhite d-inline-block d-lg-none d-xl-inline">
                    ค้นหา
                  </span>
                </button>
              </div>
              <div className="col-6 col-lg-1 pr-2 pr-lg-0 mt-lg-3 mt-2">
                <button className="btn-white01" onClick={resetDropdown}>
                  <img
                    src="assets/icons/ic-reset.png"
                    alt="ExportFiles Icon"
                    className="ic-search"
                  />
                  <span className="fontReg font-size03 colorGray01 d-inline-block d-lg-none d-xl-inline">
                    ล้างค่า
                  </span>
                </button>
              </div>
            </div>
            <div className="row justify-content-start mt-3"></div>
          </div>
          <div className="card-white mt-3">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>รายการผลการแข่งขัน
                </p>
              </div>
            </div>
            <TableData
              pageAddEditName="/EditcompetresultsSolo"
              pageAddName="/AddcompetresultsSolo"
              actionsBtn={enableButton}
              columnsname={columnsname}
              columnsText={columnsText}
              columnsType={columnsType}
              rowsdata={dataResultMatch}
              isSearchEnable={false}
              onButtonClicked={handleButtonClicked}
            />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fontReg font-weight-bold font-size03 colorGray01"
                    id="exampleModalLabel"
                  >
                    ดูรายละเอียด
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                {(() => {
                  if (dataMatch) {
                    return (
                      <div className="modal-body">
                        <div className="col-12">
                          <p className="fontReg font-weight-bold font-size03 colorGray01">
                            <span className="border-left01"></span>
                            ข้อมูลตารางการแข่งขัน
                          </p>
                        </div>
                        <div className="col-12 ml-2">
                          <p className="fontReg font-size03 colorGray01">
                            ชนิดกีฬา: {dataMatch.sportName}
                          </p>
                        </div>
                        <div className="col-12 ml-2">
                          <p className="fontReg font-size03 colorGray01">
                            ประเภทการแข่งขัน:{" "}
                            {dataMatch.competitionType === "team"
                              ? "ทีม"
                              : "เดี่ยว"}
                          </p>
                        </div>
                        <div className="col-12 ml-2">
                          <p className="fontReg font-size03 colorGray01">
                            สนามกีฬา:{" "}
                            {dataMatch.subArena
                              ? dataMatch.subArena.subArenaName
                              : "-"}
                          </p>
                        </div>
                        <div className="col-12 ml-2">
                          <p className="fontReg font-size03 colorGray01">
                            ชื่อรายการแข่งขัน: {dataMatch.matchName}
                          </p>
                        </div>
                        <div className="col-12 ml-2">
                          <p className="fontReg font-size03 colorGray01">
                            รอบการแข่งขัน: {/* qualify */}
                            {dataMatch.matchHeat === "final"
                              ? "รอบชิง"
                              : dataMatch.matchHeat === "semi"
                              ? "รอบรอง"
                              : dataMatch.matchHeat === "qualify"
                              ? "รอบคัดเลือก"
                              : "-"}
                          </p>
                        </div>
                        <div className="col-12 ml-2">
                          <p className="fontReg font-size03 colorGray01">
                            วันที่แข่งขัน:{" "}
                            {moment(new Date(dataMatch.startTime)).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        </div>
                        <div className="col-12 ml-2">
                          <p className="fontReg font-size03 colorGray01">
                            เวลาที่แข่งขัน: (
                            {moment(new Date(dataMatch.startTime)).format(
                              "HH:mm"
                            )}{" "}
                            -{" "}
                            {moment(new Date(dataMatch.endTime)).format(
                              "HH:mm"
                            )}
                            )
                          </p>
                        </div>

                        {(() => {
                          if (subDetialSche.length > 0) {
                            return (
                              <div className="col-12 mt-4">
                                <p className="fontReg font-weight-bold font-size03 colorGray01">
                                  <span className="border-left01"></span>
                                  รายการนักกีฬา
                                </p>
                              </div>
                            );
                          }
                        })()}
                        {subDetialSche.map((data, index) => {
                          return (
                            <>
                              <div className="row fontReg font-size03 colorGray01 mr-0 ml-2">
                                <div className="row w-100 m-0">
                                  <div className="col-12">
                                    ชื่อ: {data.firstname} {data.lastanme}
                                  </div>
                                </div>
                                <div className="row w-100 m-0">
                                  <div className="col-12">
                                    เลขบัตรประชาชน:{data.idcard}
                                  </div>
                                </div>
                                <div className="row w-100 m-0">
                                  <div className="col-12">
                                    สังกัด:{" "}
                                    {data.affliationName
                                      ? data.affliationName
                                      : "-"}
                                  </div>
                                </div>
                                <div className="row w-100 m-0">
                                  <div className="col-12">
                                    ลำดับ: {data.no ? data.no : "-"}
                                  </div>
                                  <div className="col-12">
                                    สถิติ: {data.record ? data.record : "-"}
                                  </div>
                                  <div className="col-12">
                                    หมายเหตุ: {data.remark ? data.remark : "-"}
                                  </div>
                                </div>
                                {/* no */}
                              </div>
                            </>
                          );
                        })}
                        {/* <div className="row fontReg font-size03 colorGray01 mr-0 ml-2">
                          <div className="row w-100 m-0">
                            <div className="col-12">
                              ชื่อ: นางสาวเทควันโด ดีงาม
                            </div>
                          </div>
                          <div className="row w-100 m-0">
                            <div className="col-12">
                              เลขบัตรประชาชน: 1049658696667
                            </div>
                          </div>
                          <div className="row w-100 m-0">
                            <div className="col-12">สังกัด: กรุงเทพมหานคร</div>
                          </div>
                          <div className="row w-100 m-0">
                            <div className="col-12">สถิติ: ทดสอบ</div>
                          </div>
                          <div className="row w-100 m-0">
                            <div className="col-12">หมายเหตุ: เทส12345</div>
                          </div>
                          <div className="row w-100 m-0">
                            <div className="col-12">อันดับ: 1</div>
                          </div>
                        </div> */}
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CompetitionResults;
