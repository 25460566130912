import React, { useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import UploadFileXLS from "../uploads/UploadFileXLS";
import { importData } from "../../api/staff_api";
import { useState } from "react";
import Dropdown from "../dropdowns/Dropdown";
import { getPermissionList } from "../../api/permission_api";
import { createStaff } from "../../api/staff_api";
import Swal from "sweetalert2";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";
import ImportExcel from "../uploads/ExcelRender";
import { verifyImport, submitImportData } from "../../api/staff_api";
import { result } from "lodash";
import Loading from "./Loading";
function AddStaff() {
  const [isLoading, setLoading] = useState(null);
  const [groupShow, setGroupShow] = useState(true);
  const [title, setTitle] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [call, setCall] = useState("");
  const [email, setEmail] = useState("");
  const [permission, setPermission] = useState("");
  const [dataPer, setDataPer] = useState([]);

  let [inputForm, setInputForm] = useState({
    title: 0,
    permission: 0,
  });

  // *********************** Take Import DATA ************************************
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const handleImport = async (rows) => {
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    setFailedRow([]);
    setTotalRow(rows.length - 1);

    const row = [];
    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: index,
          title: item[0],
          firstname: item[1],
          lastname: item[2],
          mobileNo: item[3],
          email: item[4],
          permissionId: item[5],
        });
      }
    });
    await setImportData(row);
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      setTotalRow(importData.length);
      for (let i = 0; i < importData.length; i += batchSize) {
        const batch = importData.slice(i, i + batchSize);
        const data = batch.map((item) => ({ ...item }));
        const dataToVerify = {
          importData: data,
        };
        const response = await verifyImport(dataToVerify);
        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;

          const failedData = response.data.verifyFailed;

          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(success);
          setVerifySkip(skip);
          setVerifyNew(create);
        } else {
          error++;
          // alert("เกิดข้อผิดพลาดกรุณาตรวจสอบไฟล์");
          Swal.fire({
            title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      setFailedRow(failedArr);

      if (error === 0) {
        // alert("verify data success");
        // Swal.fire({
        //   title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
        //   icon: "success",
        //   confirmButtonText: "ตกลง",
        // });
        if (failedArr.length > 0) {
          Swal.fire({
            title:
              "ตรวจสอบข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง ",
            icon: "error",
            confirmButtonText: "ยืนยัน",
          });
        } else {
          Swal.fire({
            title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
            icon: "success",
            confirmButtonText: "ยืนยัน",
          });
        }
        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
        // setIsVerify(false)
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
      console.log(error);
    }
  };

  const importStaff = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);

      await Swal.fire({
        title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        error = result.isConfirmed;

        if (result.isConfirmed) {
          for (let i = 0; i < importData.length; i += batchSize) {
            const batch = importData.slice(i, i + batchSize);
            const data = batch.map((item) => ({ ...item }));
            const dataToVerify = {
              importData: data,
            };
            setLoading(true);
            const response = await submitImportData(dataToVerify);
            setLoading(false);
            if (response) {
              success += response.data.success;
              skip += response.data.skip;
              setVerifySuccess(success);
              setVerifySkip(skip);
            } else {
              error++;
              // alert("เกิดข้อผิดพลาดกรุณาตรวจสอบไฟล์");
              Swal.fire({
                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                icon: "error",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
        } else {
          return;
        }
      });
      console.log("error : ", error);
      if (error === true) {
        // alert("import data success");

        Swal.fire({
          title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/staff");
          }
        });
        setIsVerify(false);
      } else if (error === false) {
        Swal.fire({
          title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/staff");
          }
        });
      }
    } catch (error) {
      // Swal.fire({
      //   title: "เกิดข้อผิดพลาด",
      //   icon: "error",
      //   showCancelButton: true,
      //   confirmButtonText: "ยืนยัน",
      //   cancelButtonText: `ยกเลิก`,
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     navigateToBack("/staff");
      //   }
      // });
      console.log(error);
    }
  };

  // *********************** Take Import DATA ************************************

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const DataDropdownPrefix = [
    { value: 1, text: "นาย" },
    { value: 2, text: "นางสาว" },
    { value: 3, text: "นาง" },
    { value: 4, text: "อื่นๆ" },
  ];

  const getPermission = async () => {
    const perData = await getPermissionList();
    let perType = [{ value: "0", text: "ทั้งหมด" }];

    perData.data.data.forEach((element, i) => {
      return perType.push({
        id: element.permissionAccountId,
        value: element.permissionName,
        text: element === null ? "-" : element.permissionName,
      });
    });
    getFilterDropdown(perType);
  };

  const getFilterDropdown = async (data) => {
    var dataArr = data.map((item) => {
      return [item.text, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    var result = [...maparr.values()]; //converting back to array from mapobject
    setDataPer(result);
  };

  useEffect(() => {
    getPermission();
  }, []);

  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/staff");
      }
    });
  }
  const swalWithBootstrapButtons = Swal.mixin();
  const CreateStaff = async () => {
    let body = {
      title: inputForm.title,
      firstname: firstname,
      lastname: lastname,
      mobileNo: call,
      email: email,
      permisionAccount: {},
    };

    const response = await createStaff(body);
    if (response && response.data.status === "success") {
      swalWithBootstrapButtons
        .fire({
          title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        })
        .then(async (result) => {
          setLoading(1);
          if (result.isConfirmed) {
            swalWithBootstrapButtons
              .fire({
                title: "เพิ่มข้อมูลสำเร็จ!",
                icon: "success",
                confirmButtonText: "ตกลง",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  navigateToBack("/staff");
                }
              });
          }
        });
    } else {
      swalWithBootstrapButtons
        .fire({
          title: "เพิ่มข้อมูลผิดพลาด!",
          icon: "error",
          confirmButtonText: "ตกลง",
        })
        .then((result) => {
          if (result.isConfirmed) {
            navigateToBack("/staff");
          }
        });
    }
  };

  return (
    <>
      {(() => {
        if (isLoading === true) {
          return <Loading />;
        }
      })()}
      {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()}
      <Header parentmenu={"เจ้าหน้าที่"} />
      <Menu parentmenu={"staffData"} activemenu={"staff"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-8 col-sm-9 mt-2">
              <img
                src="assets/icons/im-staff-info.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                ข้อมูลเจ้าหน้าที่ Application
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลเจ้าหน้าที่ Application / เพิ่มข้อมูลเจ้าหน้าที่
              </font>
            </div>
            {/* <div
                className={`col-4 col-sm-3 justify-content-end align-items-center ${
                  !groupShow ? "d-flex" : "d-none"
                } `}
              >
                <button
                  className="btn-blue03 mr-2 mr-xl-3 w-100"
                  onClick={() => setGroupShow(true)}
                >
                  <img
                    src="assets/icons/ic-add.png"
                    alt="ExportFiles Icon"
                    className="ic-add"
                  />
                  <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                    เพิ่มข้อมูลหลายรายการ
                  </span>
                </button>
              </div>
              <div
                className={`col-4 col-sm-3 justify-content-end align-items-center ${
                  groupShow ? "d-flex" : "d-none"
                } `}
              >
                <button
                  className="btn-blue03 mr-2 mr-xl-3 w-100"
                  onClick={() => setGroupShow(false)}
                >
                  <img
                    src="assets/icons/ic-add.png"
                    alt="ExportFiles Icon"
                    className="ic-add"
                  />
                  <span className="fontReg font-size03 colorWhite d-none d-lg-inline-block">
                    เพิ่มข้อมูลรายการเดียว
                  </span>
                </button>
              </div> */}
          </div>
          {/* <div className={`card-white ${!groupShow ? "" : "d-none"} `}>
              <div className="row">
                <div className="col-12">
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    <span className="border-left01"></span>เพิ่มข้อมูลทีละรายการ
                  </p>
                </div>
              </div>    
              <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg">
                <div className="drpdw-status h-100">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    คำนำหน้า
                  </p>
                  <Dropdown
                    setinput={setinput}
                    id="title"
                    options={DataDropdownPrefix}
                    inputname={"title"}
                  />
                </div>
              </div>
              <div className="col-12 col-lg mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="ชื่อจริง"
                  value={firstname}
                  onChange={(e) => {
                    setFirstname(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg mt-2 mt-lg-0">
                <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="นามสกุล"
                  value={lastname}
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                />
              </div>
              </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg">
              <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="เบอร์โทร"
                  value={call}
                  onChange={(e) => {
                    setCall(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg"></div>
            </div>
            <div className="row mb-0 mb-lg-3">
              <div className="col-12 col-lg">
              <input
                  className="inp-data01 fontReg font-size03"
                  placeholder="อีเมล"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg"></div>
            </div>
            <div className="row mb-0 mb-lg-3">
            <div className="col-12 col-lg">
                <div className="drpdw-status h-100">
                  <p className="fontReg font-size03 colorGray02 mb-1">
                    สิทธิ์
                  </p>
                  <Dropdown
                    setinput={setinput}
                    id="permission"
                    options={dataPer}
                    inputname={"permission"}
                  />
                </div>
              </div>
              <div className="col-12 col-lg"></div>
              </div>
              <div className="row mt-4 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button className="btn-save" 
                onClick={CreateStaff}
                >
                  บันทึก
                </button>
              </div>
            </div>
            </div> */}

          <div className={`card-white mt-3  ${groupShow ? "" : "d-none"} `}>
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>
                  เพิ่มข้อมูลทีละหลายรายการ
                </p>
              </div>
            </div>
            <div className="row justify-content-start align-items-center m-0">
              <div className="col-auto">
                <p className="fontReg font-weight-bold font-size03 colorRed mb-0">
                  กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                </p>
              </div>
              <div className="col-auto mt-2 mt-md-0">
                <Link
                  as={Link}
                  to="/files/010-staff-user_import_template.xlsx"
                  target="_blank"
                  className="btn-blue05 fontReg font-size03 colorWhite p-3 d-inline-block h-auto"
                  id="downloadBtn"
                  value="download"
                >
                  <span className="nav-icon icon icon-ic-import mr-2"></span>
                  Download Template Form
                </Link>
              </div>
            </div>
            <div className="row justify-content-start m-0 pt-3">
              <div className="col-12 mb-2 mb-lg-2">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  Upload File
                </p>

                {/* {verifySkip > 0 ? (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    แก้ไข ข้อมูลเดิม {verifySkip} รายการ
                  </p>
                ) : (
                  <></>
                )} */}
                {/* {verifyNew > 0 ? (
                  <p className="fontReg font-weight-bold font-size03 colorGray01">
                    เพิ่มข้อมูลใหม่ {verifyNew} รายการ
                  </p>
                ) : (
                  <></>
                )} */}

                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  ข้อมูลทั้งหมด {verifySuccess}/{totalRow} รายการ
                </p>
                <span className="font-size03 colorGray02 mb-1">
                  รอบรับไฟล์ .xlsx
                </span>
              </div>
              <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                <ImportExcel onImport={handleImport} />
              </div>
              <div className="col-12 col-lg-3">
                <button
                  className="btn-green01 fontReg font-size03 colorWhite"
                  disabled={importData.length < 1 ? true : false}
                  // disabled
                  onClick={verifyData}
                >
                  ตรวจสอบไฟล์
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    cancelData();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="col-3 col-sm-2 col-xl-1">
                <button
                  className="btn-save"
                  disabled={isVerify}
                  onClick={importStaff}
                >
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`card-white mt-3  ${
              failedRow.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: ข้อมูลดังต่อไปนี้อาจไม่ถูกต้อง หรือ ไม่มีอยู่ในระบบ
            </p>

            <p
              style={{ fontSize: `20px`, marginBottom: `30px` }}
              className="fontReg font-weight-bold font-size03 colorRed"
            >
              ข้อมูลที่ไม่ผ่านการตรวจสอบ: {failedRow.length} รายการ
            </p>
            {failedRow.map((item) => (
              <div className="row">
                <div className="col-auto justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    ข้อมูลรายการที่: {item.key + 2}{" "}
                  </span>
                </div>
                <div className="col-9 justify-content-start align-items-center m-0">
                  <span
                    style={{ fontSize: `18px` }}
                    className="fontReg font-weight-bold font-size03 colorRed mb-3 mx-1"
                  >
                    กรุณาตรวจสอบคอลัมน์ {item.result}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddStaff;
