import React, { useState, useEffect } from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import {
  getPromotion,
  getPromotionById,
  deletePromotion,
  approvePromotion,
} from "../../api/promotion_api";
import ToastSuccess from "./../ToastData/toast-success";
import ToastWarning from "./../ToastData/toast-warning";
import ToastError from "./../ToastData/toast-error";

function Addapprovepromotion() {
  const [isLoading, setLoading] = useState(null);
  const [inputForm, setInputForm] = useState({
    inp1: "ร้านน้องน้ำ", //ชื่อร้าน
    inp2: "PO001", //PromotionID
    inp3: "โปรโมชัน01", //ชื่อโปรโมชัน
    inp4: "10", //จำนวนสิทธิ์
    inp5: "แคมเปญ01", //ชื่อแคมเปญ
    inp6: "รายละเอียดแคทเปญ", //รายละเอียดแคทเปญ
    inp7: null, //radio อนุมัติ
    inp8: null, //radio ไม่อนุมัติ
    inp9: "", //comment ไม่อนุมัติ
  });

  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }
  const [getDataPromotionID, setDataPromotionID] = useState([]);
  const [commentShow, setCommentShow] = useState(false);
  let selected = null;
  const navigate = useNavigate();
  function navigateToBack(page) {
    navigate(page);
  }

  function cancelData() {
    Swal.fire({
      title: "คุณต้องการที่จะยกเลิกรายการและย้อนกลับใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToBack("/approvePromotion");
      }
    });
  }
  useEffect(() => {
    // if (id) {
    getPromotionID();
    // }
  }, []);
  const postApprovePromotion = async () => {
    //    if (inputForm.inp17 === null || inputForm.inp17 === "") {
    console.log("inputForm.inp9 : ", inputForm.inp9);
    console.log("inputForm.inp8 : ", inputForm.inp8);
    console.log("commentShow : ", commentShow);
    if (
      inputForm.inp9 === null ||
      (inputForm.inp9 === "" && commentShow === false)
    ) {
      Swal.fire({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {});
    } else {
      let snAppr = null;
      if (commentShow === true) {
        snAppr = "approve";
      } else if (commentShow === false) {
        snAppr = "disapprove";
      } else {
        snAppr = "waiting";
      }
      let body = {
        promotionStatus: commentShow === null ? "-" : snAppr,
        remark: inputForm.inp9 === null ? "" : inputForm.inp9,
      };

      await Swal.fire({
        title: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
        // "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่ ",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            //  return promise;
            const respon = await approvePromotion(
              getDataPromotionID.promotionID,
              body
            );

            if (respon.data.status === "success") {
              setLoading(1);
              navigateToBack("/approvePromotion");
            } else {
              Swal.fire({
                title: "เกิดข้อผิดพลาด",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: `ยกเลิก`,
                reverseButtons: true,
              }).then((result) => {});
            }
          })();
        }
      });
    }
  };
  const getPromotionID = async () => {
    let id = new URLSearchParams(window.location.search).get("id");
    let getData = [];
    let objectData = null;
    const respon = await getPromotionById(id);
    objectData = respon.data.data;
    if (respon.data.data.promotionStatus === "approve") {
      selected = true;
    } else {
      selected = false;
    }

    setCommentShow(selected);
    getData.push({
      id: objectData.promotionID,
      promotionName:
        objectData.promotionDetail === "" ? "-" : objectData.promotionDetail,
      promotionDetail: objectData.promotionDetail,
      promotionID: objectData.promotionID,
      promotionStatus: objectData.promotionStatus,
      promotionVillageType: objectData.promotionVillageType,
      joinCampaign: objectData.joinCampaign.campaign.campaignName,
      campaignName: objectData.joinCampaign.campaign.campaignName,
      shopName: objectData.joinCampaign.shop.shopName,
      shopID: objectData.joinCampaign.shop.shopID,
      detialCampaign: objectData.joinCampaign.campaign.campaignDetail,
      amount: objectData.promotionAmount,
      promotionStatus: objectData.promotionStatus === "approve" ? true : false,
    });

    setDataPromotionID(getData[0]);
  };
  return (
    <>
      {(() => {
        if (isLoading === 1) {
          return <ToastSuccess />;
        } else if (isLoading === 2) {
          return <ToastWarning />;
        } else if (isLoading === 3) {
          return <ToastError />;
        }
      })()}
      <Header parentmenu={"โปรโมชัน ที่รออนุมัติ"} />
      <Menu parentmenu={"shopData"} activemenu={"approvePromotion"} />
      <div className="content-wrapper">
        <div className="container-fluid p-2 p-md-3">
          <div className="row mb-4">
            <div className="col-12 mt-2">
              <img
                src="assets/icons/im-shop.png"
                alt="Home Icon"
                className="im-tiltle img-circle"
              />
              <font className="fontReg font-weight-bold font-size01 colorGray01 mr-3">
                โปรโมชัน ที่รออนุมัติ
              </font>
              <font className="fontLight font-size03 colorGray01 d-none d-md-inline-block">
                | ข้อมูลร้านค้า ร้านอาหาร / โปรโมชัน ที่รออนุมัติ
              </font>
            </div>
          </div>
          <div className="card-white">
            <div className="row">
              <div className="col-12">
                <p className="fontReg font-weight-bold font-size03 colorGray01">
                  <span className="border-left01"></span>ข้อมูลร้านค้า
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 pr-2 pr-sm-0 mt-sm-0 mt-2">
                <p className="fontReg font-weight-bold text-xs colorGray01 mb-1">
                  {" "}
                   ชื่อร้าน
                </p>

                <input
                  id="inputStoreName"
                  className="inp-data01 fontReg font-size03"
                  value={getDataPromotionID.shopName}
                  onChange={(e) => setinput("inp1", e.target.value)}
                  disabled
                />
              </div>

              <div className="col-12 col-sm-6 pr-2 pr-sm-0  ">
                <p className="fontReg font-weight-bold text-xs colorGray01 mb-1">
                  {" "}
                  จำนวนตัวเลข
                </p>
                <input
                  id="inputAmount"
                  className="inp-data01 fontReg font-size03"
                  value={getDataPromotionID.promotionDetail}
                  onChange={(e) => setinput("inp4", e.target.value)}
                  disabled
                />
              </div>
              {/* <div className="col-12 col-sm-6 pr-2 pr-sm-0 mt-sm-0 mt-2">
                 
                <input
                  id="inputPromotionName"
                  className="inp-data01 fontReg font-size03"
                  value={getDataPromotionID.promotionName}
                  onChange={(e) => setinput("inp3", e.target.value)}
                  disabled
                />
              </div> */}
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <p className="fontReg font-weight-bold text-xs colorGray01 mb-1">
                  {" "}
                  จำนวนสิทธิ์
                </p>
                <input
                  id="inputCamp"
                  className="inp-data01 fontReg font-size03"
                  value={getDataPromotionID.amount}
                  onChange={(e) => setinput("inp5", e.target.value)}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 pr-2 pr-sm-0 mt-sm-3 mt-2">
                <p className="fontReg font-weight-bold text-xs colorGray01 mb-1">
                  {" "}
                  ชื่อแคมเปญ
                </p>
                <input
                  id="inputCamp"
                  className="inp-data01 fontReg font-size03"
                  value={getDataPromotionID.campaignName}
                  onChange={(e) => setinput("inp6", e.target.value)}
                  disabled
                />
              </div>
            </div>

            <div className="fontReg font-size03 colorGray01 mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radios"
                  id="radios1"
                  defaultValue="option1"
                  defaultChecked={commentShow === true}
                  onChange={(e) => {
                    setinput("inp6", true);
                    setCommentShow(true);
                  }}
                />
                <label className="form-check-label" htmlFor="radios1">
                  อนุมัติ
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radios"
                  id="radios2"
                  defaultValue="option2"
                  defaultChecked={commentShow === false}
                  onChange={(e) => {
                    setinput("inp7", false);
                    setCommentShow(false);
                  }}
                />
                <label className="form-check-label" htmlFor="radios2">
                  ไม่อนุมัติ
                </label>
              </div>

              <div className="col-12 col-sm-6 p-0 mt-sm-3 mt-2">
                <textarea
                  row="3"
                  id="inputReject"
                  // inputForm.inp7 === false
                  className={`inp-area fontReg font-size03  ${
                    commentShow === false ? "" : "d-none"
                  } `}
                  placeholder="กรุณาใส่เหตุผลในการไม่อนุมัติ"
                  value={inputForm.inp9}
                  onChange={(e) => setinput("inp9", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-4 fontReg font-weight-bold font-size03">
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-cancel"
                onClick={() => {
                  cancelData();
                }}
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-3 col-sm-2 col-xl-1">
              <button
                className="btn-save"
                onClick={(event) => postApprovePromotion()}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addapprovepromotion;
