import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

const Toast = () => {
  const notifyError = () => {
    toast.error("เกิดข้อผิดพลาด กรุณาทำรายการใหม่อีกครั้ง !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  useEffect(() => {
    notifyError();
  }, []);
  return <ToastContainer />;
};

export default Toast;
