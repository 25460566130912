import React, { useEffect, useState } from "react";
import "./Dropdown.css";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
function Dropdown(props) {
  const location = useLocation();
  let id = new URLSearchParams(location.search).get("id");
  const optionsprops = props.options;
  const { setinput, inputname, setissolo, valuefromparent, selectedValue } =
    props;
  //
  const [sortOption, setSortOption] = useState("A-Z");
  // const [data, setData] = useState(["กล้วย", "แอปเปิ้ล", "ชมพู", "นก"]);
  const sortedData = optionsprops.sort((a, b) => {
    if (sortOption === "A-Z") {
      // return a.text.localeCompare(b.text, "th","en", { sensitivity: "base" });
    }
  });

  const [options, setOptions] = useState(sortedData);
  const [selected, setSelected] = useState("ทั้งหมด");
  const [isActive, setIsActive] = useState(false);
  const [isActiveBtn, setIsActiveBtn] = useState(false);
  // console.log("selected : ", selected==="ทั้งหมด");
  // test
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  // --
  const handleSelectMenuClick = (event) => {
    // setIsActiveBtn
    // console.log("test : test ");
    // console.log("isActive : ", isActive);

    //pop up บอกว่า ถ้าเปลี่ยนชนิดกีฬา ข้อมูลประเภทการแข่งขันที่เลือกไว้จะถูกล้างออก
    if (props.isSportType) {
      if (!isActive) {
        setIsActive((current) => !current);
        // Swal.fire({
        //   title:
        //     id === null
        //       ? "ต้องการเพิ่มชนิดกีฬา ใช่หรือไม่"
        //       : "ถ้าเปลี่ยนชนิดกีฬา ข้อมูลประเภทการแข่งขันที่เลือกไว้จะถูกล้างออกคุณต้องการเปลี่ยนใช่หรือไม่",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonText: "ยืนยัน",
        //   cancelButtonText: `ยกเลิก`,
        //   reverseButtons: true,
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     setIsActive((current) => !current);
        //   }
        // });
      } else {
        // console.log("B ");
        setIsActive((current) => !current);
      }
    } else {
      // console.log("selected data : ", selected);
      // console.log("C ");
      // console.log("current pre : ", current);
      setIsActive((current) => !current);
    }
  };
  const handleSelectItemClick = (event) => {
    setSelected(event.target.outerText); //set ค่า ให้ตัวหนังสือใน dropdown
    if (props.ChangesetValuefromparent)
      props.ChangesetValuefromparent(event.target.outerText); //set ค่า ให้เหมือนกับของตัวหนังสือใน dropdown
    if (props.ChangesetValuefromparentMultiple)
      //  event.target.attributes[1].value
      //  props.id
      props.ChangesetValuefromparentMultiple(
        event.target.attributes[1].value,
        event.target.outerText,
        props.id
        //event.target.attributes[1].value
      ); //set ค่า ให้เหมือนกับของตัวหนังสือใน dropdown กรณี Dropdown SportType หลายตัว
    setIsActive((current) => !current);
    if (setinput)
      setinput(inputname, parseInt(event.target.attributes[1].value));
    if (setissolo)
      setissolo(event.target.attributes[1].value, event.target.outerText);

    if (props.func) {
      //ใช้กรณีเดี่ยวหรือทีม dropdown
      let found = options.find((x) => {
        return x.value == event.target.attributes[1].value;
      });
    }

    if (props.onChange) {
      const selected = event.target.attributes[1].value;
      props.onChange(selected);
    }

    if (props.pull_checkbox_data_added) {
      //ใช้กรณีAdd dropdown
      let found = options.find((x) => {
        return x.value == event.target.attributes[1].value;
      });
      //console.log(found.subType)
      props.pull_checkbox_data_added(found.subType, props.id);
    }

    if (props.handleDropdownChangeSub) {
      //กรณีเปลี่ยนค่าของ Add dropdown
      const selected = event.target.outerText;
      props.handleDropdownChangeSub(
        props.id,
        selected,
        event.target.attributes[1].value
      );
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/Addeditcoach") {
      // setSelected("เลือกข้อมูล");
    }
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    if (valuefromparent != undefined) {
      setSelected(valuefromparent); //เปลี่ยนตัวหนังสือที่แสดงใน dropdown
    }
  }, [valuefromparent]);

  //
  const handleSelectMenuMouseEnter = () => {
    setIsActive(true);
  };

  const handleSelectMenuMouseLeave = () => {
    setIsActive(false);
  };
  // console.log("isActive preview data : ", isActive);
  return (
    <>
      {" "}
      <div className="select-menu fontReg font-size02">
        <div className="select" onClick={handleSelectMenuClick}>
          <span>{selected}</span>
          <i className={isActive ? "fas fa-angle-up" : "fas fa-angle-down"} />
        </div>
        <div
          onMouseEnter={handleSelectMenuMouseEnter}
          onMouseLeave={handleSelectMenuMouseLeave}
          className={isActive === true ? "options-list active" : "options-list"}
        >
          {options?.map((option) => (
            <option
              className="option "
              key={option.value}
              value={option.value}
              onClick={handleSelectItemClick}
            >
              {option.text}
            </option>
          ))}
        </div>
      </div>
    </>
  );
}

export default Dropdown;
